import React from "react";
import { Row, Col } from "reactstrap";
type BreadcrumbPropsType = {
    title: string;
    breadcrumbBackgroundColor: string;
};
export default function Breadcrumb({
    title,
    breadcrumbBackgroundColor,
}: BreadcrumbPropsType) {
    return (
        <Row>
            <Col xs="12">
                <div
                    style={{
                        paddingBottom: "$grid-gutter-width",
                        backgroundColor: breadcrumbBackgroundColor,
                        padding: "0",
                        textTransform: "uppercase",
                        fontWeight: 600,
                        height: "45px",
                        marginBottom: "10px",
                        fontSize: "16px !important",
                    }}
                    className="d-flex align-items-center justify-content-between"
                >
                    <h4
                        style={{ marginLeft: "20px" }}
                        className="mb-0 font-size-18"
                    >
                        {title}
                    </h4>
                    <div className="page-title-right"></div>
                    {/* <ShopsNumberLink /> */}
                </div>
            </Col>
        </Row>
    );
}
