import React, { useState } from "react";
import { DynamicTable } from "@maherunlocker/custom-react-table";
import { store } from "../store";
import { useSnapshot } from "valtio";
import Switch from "react-switch";
import classnames from "classnames";
// import { replaceQuotedString } from "@components/Common/Replace";
type TableProps = {
    URL: string;
    setSelectedProducts: React.Dispatch<React.SetStateAction<any>>;
    setSelectedProductsIuuds: React.Dispatch<React.SetStateAction<any>>;

    setSelectedProductsDissocié: React.Dispatch<React.SetStateAction<any>>;
    setSelectedNameProducts: React.Dispatch<React.SetStateAction<any>>;
    setSelectedProductsNamesDissocié: React.Dispatch<React.SetStateAction<any>>;
    setModif: Function;
    setEdit: Function;
};

export default function TableAssociateProducts({
    URL,
    setSelectedProducts,
    setSelectedProductsIuuds,
    setSelectedProductsDissocié,
    setModif,
    setEdit,
    setSelectedNameProducts,
    setSelectedProductsNamesDissocié,
}: TableProps) {
    const [filterActive, setLocalFilterActive] = useState<boolean>(false);
    const [dataIsUpdated, setDataIsUpdated] = React.useState<number | boolean>(
        false
    );
    const { listRef, selectedModule } = useSnapshot(store);

    interface customColumnProps {
        indexOFColumn: number;
        columnName: string;
        customJsx: Function;
    }

    function ModuleColmun({ selectedRow }: any) {
        let moduleNumber;

        if (typeof selectedRow.Module === "number") {
            if (selectedRow.Module < 10) {
                moduleNumber = "0" + selectedRow.Module;
            } else {
                moduleNumber = selectedRow.Module;
            }
        }
        return (
            <React.Fragment>
                {typeof selectedRow.Module === "number" ? (
                    <h6
                        className="hovertext"
                        data-hover={`Ce module appartient à ${moduleNumber} produit(s) parent(s)`}
                    >
                        {moduleNumber}
                    </h6>
                ) : (
                    <p>{selectedRow.Module}</p>
                )}
            </React.Fragment>
        );
    }

    let ReferenceProducts: any = [];
    let iuudsProducts: any = [];
    let NameProducts: any = [];
    let name: any = [];
    let iuudProduct: any = [];
    let nameProduct: any = [];
    let selectedElement: any = {
        ParentRef: "",
        element: [],
    };
    let selectedElementToDissociate: any = [];
    function SelectColmunProduct({ selectedRow }: any) {
        const [checked1, setChecked1] = React.useState(true);
        const {
            selectedProductsToDissociate,
            selectedOneModulelicense,
        } = useSnapshot(store);

        function Associate() {
            if (selectedProductsToDissociate === false) {
                if (
                    listRef
                        .filter((val: any) =>
                            selectedModule.ProduitModule.includes(val)
                        )
                        .includes(selectedRow.Référence) &&
                    checked1
                ) {
                    if (
                        selectedModule.ProduitModule.includes(
                            selectedRow.Référence
                        )
                    ) {
                        selectedElement.element.push({
                            RefParent: selectedModule.Référence,
                            reference: selectedRow.Référence,
                        });
                        selectedElementToDissociate.push(selectedRow.Produit);
                    }
                    setSelectedProductsDissocié(selectedElement.element);
                    setSelectedProductsNamesDissocié(
                        selectedElementToDissociate
                    );
                    setModif(true);
                    setEdit("EditAssociate");
                } else {
                    if (ReferenceProducts.includes(selectedRow.Référence)) {
                        name = ReferenceProducts.filter(
                            (word: any) => word !== selectedRow.Référence
                        );
                        nameProduct = NameProducts.filter(
                            (word1: any) => word1 !== selectedRow.Produit
                        );

                        ReferenceProducts = name;
                        NameProducts = nameProduct;
                    } else {
                        ReferenceProducts.push(selectedRow.Référence);
                        NameProducts.push(selectedRow.Produit);
                    }

                    if (iuudsProducts.includes(selectedRow.Uuid)) {
                        iuudProduct = iuudsProducts.filter(
                            (word: any) => word !== selectedRow.Uuid
                        );

                        iuudsProducts = iuudProduct;
                    } else {
                        iuudsProducts.push(selectedRow.Uuid);
                    }

                    setSelectedProducts(ReferenceProducts);
                    setSelectedProductsIuuds(iuudsProducts);
                    setSelectedNameProducts(NameProducts);
                    setModif(true);
                    setEdit("EditAssociate");
                }
            } else {
                setSelectedProductsDissocié([]);
                setSelectedProducts([]);
                //setSelectedProductsIuuds([]);
                setSelectedProductsNamesDissocié([]);
                setSelectedNameProducts([]);
                if (
                    listRef
                        .filter((val: any) =>
                            selectedModule.ProduitModule.includes(val)
                        )
                        .includes(selectedRow.Référence) &&
                    checked1
                ) {
                    if (
                        selectedModule.ProduitModule.includes(
                            selectedRow.Référence
                        )
                    ) {
                        selectedElement.element.push({
                            RefParent: selectedModule.Référence,
                            reference: selectedRow.Référence,
                        });
                        selectedElementToDissociate.push(selectedRow.Produit);
                    }
                    setSelectedProductsDissocié(selectedElement.element);
                    setSelectedProductsNamesDissocié(
                        selectedElementToDissociate
                    );
                    setModif(true);
                    setEdit("EditAssociate");
                } else {
                    if (ReferenceProducts.includes(selectedRow.Référence)) {
                        name = ReferenceProducts.filter(
                            (word: any) => word !== selectedRow.Référence
                        );
                        nameProduct = NameProducts.filter(
                            (word1: any) => word1 !== selectedRow.Produit
                        );

                        ReferenceProducts = name;
                        NameProducts = nameProduct;
                    } else {
                        ReferenceProducts.push(selectedRow.Référence);
                        NameProducts.push(selectedRow.Produit);
                    }
                    if (iuudsProducts.includes(selectedRow.Uuid)) {
                        iuudProduct = iuudsProducts.filter(
                            (word: any) => word !== selectedRow.Référence
                        );

                        iuudsProducts = iuudProduct;
                    } else {
                        iuudsProducts.push(selectedRow.Uuid);
                    }
                    setSelectedProducts(ReferenceProducts);
                    setSelectedProductsIuuds(iuudsProducts);
                    setSelectedNameProducts(NameProducts);
                    setModif(true);
                    setEdit("EditAssociate");
                }
            }
        }

        return (
            <div
                className="d-flex align-items-center "
                style={{ marginLeft: "25%" }}
            >
                {selectedRow.ProduitParent ? (
                    <div
                        className={`${classnames({
                            hovertext:
                                selectedOneModulelicense.includes(
                                    selectedRow.Uuid
                                ) &&
                                Object.values(listRef).includes(
                                    selectedRow.Référence
                                ),
                        })}`}
                        data-hover={`Vous ne pouvez pas dissocier un produit d'un module ayant des licences actives.`}
                    >
                        <Switch
                            onChange={() => {
                                Associate();
                                setChecked1(!checked1);
                            }}
                            checked={
                                Object.values(listRef).includes(
                                    selectedRow.Référence
                                )
                                    ? checked1
                                    : !checked1
                            }
                            disabled={
                                selectedOneModulelicense.includes(
                                    selectedRow.Uuid
                                ) &&
                                Object.values(listRef).includes(
                                    selectedRow.Référence
                                )
                                    ? true
                                    : false
                            }
                            offColor="#E30613"
                            onColor="#34C38F"
                        />
                    </div>
                ) : (
                    <></>
                )}
            </div>
        );
    }
    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 2,
            columnName: "Module associé",
            customJsx: ModuleColmun,
        },
        {
            indexOFColumn: 5,
            columnName: "Actions",
            customJsx: SelectColmunProduct,
        },
    ];

    function SelectAccountDropdown({ selectedRow }: any) {
        return <div className="w-100"></div>;
    }
    return (
        <DynamicTable
            url={URL}
            actionColumn={SelectAccountDropdown}
            // customJsxSideFilterButton={}
            // canSelect
            customSelect
            canExpand
            // setSelectedRows={setSelectedRows}
            setDataIsUpdated={setDataIsUpdated}
            dataIsUpdated={dataIsUpdated}
            showGlobalFilter
            showFilter
            filterActive={filterActive}
            setLocalFilterActive={setLocalFilterActive}
            arrayOfCustomColumns={arrayOfCustomColumns}
            maxHeight={"calc(100vh - 445px)"}
            minHeight={"calc(100vh - 445px)"}
        />
    );
}
