import { useEffect } from "react";
import NProgress from "nprogress";

import "nprogress/nprogress.css";

type Props = {
    children: JSX.Element;
};
function AuthRoute({ children }: Props): JSX.Element {
    useEffect(() => {
        NProgress.start();
        NProgress.done();
    }, []);

    useEffect(() => {
        verifyTokenAccess();
    });

    async function verifyTokenAccess(): Promise<void> {
        const tmpToken = localStorage.getItem("access_token");
        const myHeaders = new Headers();
        const auth = btoa(
            `${process.env.REACT_APP_CLIENT_ID}:${process.env.REACT_APP_CLIENT_SECRET}`
        );
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", `Basic ${auth}`);

        const urlencoded = new URLSearchParams();
        urlencoded.append("token", tmpToken!);
        urlencoded.append("token_type_hint", "access_token");

        localStorage.setItem("urlencoded", JSON.stringify(urlencoded));
        localStorage.setItem("myHeaders", JSON.stringify(myHeaders));

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: urlencoded,
        };

        await fetch(
            `${process.env.REACT_APP_OPSERVER_URL}/token/introspection`,
            requestOptions
        )
            .then((response) => response.json())
            .then((data) => {
                localStorage.setItem("datatoken", JSON.stringify(data));
                // if (tmpToken === null || data.active !== true) {
                if (tmpToken === null) {
                    window.location.replace("/login");
                }
            })
            // eslint-disable-next-line
            .catch((error) => console.log(error.message));
    }

    return children;
}

export default AuthRoute;
