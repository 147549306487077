import React from "react";
import { useTranslation } from "react-i18next";
import { AvForm } from "availity-reactstrap-validation";
import { DynamicTable } from "@maherunlocker/custom-react-table";
import {
    DisplayIcon,
    DocumentIcon,
    EyeIcon,
    PencilIcon,
    PlusIcon,
    StyledButtonWithIcon,
    StyledIconButton,
    TrashIcon,
    VerticalDotsIcon,
} from "@aureskonnect/react-ui";

import {
    setIsHitoryAllMotifs,
    setSelectedMotif,
    store,
    setSelectedMotifBeforeUpdate,
    setIsAlerteMotifModal,
} from "../store";

import CommonMenu from "../../components/Common/MultipleAction/CommonMenu";
import { useSnapshot } from "valtio";

interface customColumnProps {
    indexOFColumn: number;
    columnName: string;
    customJsx: Function;
}

type TableProps = {
    setIsHidden: React.Dispatch<React.SetStateAction<boolean>>;
    isHidden: boolean;
    urlTable: string;
    isCofirmModalOpened: boolean;
    setIsConfirmModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
    setTypeConfirmation: Function;
    setIsEditAction: React.Dispatch<React.SetStateAction<boolean>>;
    setDataIsUpdated: React.Dispatch<React.SetStateAction<number | boolean>>;
    dataIsUpdated: number | boolean;
    setSelectedRows: React.Dispatch<React.SetStateAction<any>>;
    selectedRows?: any;
    isMotifConfiguration: boolean;
    isHisotyMotifModalOpened?: boolean;
    setIsHisotyMotifModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
    setMotifState: React.Dispatch<React.SetStateAction<boolean>>;
    isMotifState: boolean;
    isEditAction: boolean;
    setEdit: Function;
    setModif: Function;
    setIsAlertupdateModal: Function;
};

function TableListeMotifs(props: TableProps) {
    const { t } = useTranslation();
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const { modif, isAlerteMotifModal, isVisible } = useSnapshot(store);

    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 4,
            columnName: "Actions",
            customJsx: ActionsColmunCompte,
        },
    ];

    function ActionsColmunCompte({ selectedRow }: any) {
        const { modif } = useSnapshot(store);
        return (
            <React.Fragment>
                {props.isMotifState ? (
                    <PencilIcon
                        height={25}
                        width={25}
                        onClick={() => {
                            if (modif === true) {
                                props.setEdit("EditMotif");
                                props.setIsAlertupdateModal(true);
                                props.setIsEditAction(true);
                                props.setSelectedRows(selectedRow);
                                setLocalFilterActive(false);
                            } else {
                                props.setIsEditAction(true);
                                setSelectedMotif(selectedRow);
                                setSelectedMotifBeforeUpdate(selectedRow);
                                setLocalFilterActive(false);
                                props.setIsHidden(true);
                            }
                        }}
                        style={{
                            cursor: "pointer",
                            pointerEvents:
                                selectedRow.new === false ? "none" : "auto",
                            opacity: selectedRow.new === false ? "0.4" : "",
                        }}
                    />
                ) : (
                    <DisplayIcon
                        style={{ cursor: "pointer" }}
                        height={46}
                        width={46}
                        onClick={() => {
                            props.setIsEditAction(true);
                            setSelectedMotif(selectedRow);
                            props.setIsHidden(true);
                            setLocalFilterActive(false);
                        }}
                    />
                )}
            </React.Fragment>
        );
    }

    function SelectAccountDropdown({ selectedRow }: any) {
        if (props.isMotifConfiguration) {
            return (
                <div className="dropdown ml-2  d-none d-sm-block">
                    <VerticalDotsIcon
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        cursor="pointer"
                        height={25}
                        width={25}
                    />
                    <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                        style={{ marginLeft: "-150px" }}
                    >
                        {props.isMotifState ? (
                            <div
                                className="dropdown-item"
                                style={{
                                    fontWeight: "bold",
                                    alignItems: "center",
                                    cursor: "pointer",
                                    pointerEvents:
                                        selectedRow.original.new === false
                                            ? "none"
                                            : "auto",
                                    opacity:
                                        selectedRow.original.new === false
                                            ? "0.4"
                                            : "",
                                }}
                                onClick={() => {
                                    props.setMotifState(true);
                                    setIsAlerteMotifModal(!isAlerteMotifModal);
                                    setSelectedMotif(selectedRow.original);
                                }}
                            >
                                <TrashIcon
                                    height={25}
                                    width={25}
                                    fill="red"
                                    className="mr-2"
                                />
                                {t("Archive motif")}
                            </div>
                        ) : (
                            <div
                                className="dropdown-item"
                                style={{
                                    fontWeight: "bold",
                                    alignItems: "center",
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    props.setMotifState(false);
                                    setIsAlerteMotifModal(!isAlerteMotifModal);
                                    setSelectedMotif(selectedRow.original);
                                }}
                            >
                                <EyeIcon
                                    height={25}
                                    width={25}
                                    className="mr-2"
                                />
                                {t("Enable motif")}
                            </div>
                        )}
                        <div
                            className="dropdown-item"
                            style={{
                                fontWeight: "bold",
                                alignItems: "center",
                                cursor: "pointer",
                                pointerEvents:
                                    selectedRow.original.new === false
                                        ? "none"
                                        : "auto",
                                opacity:
                                    selectedRow.original.new === false
                                        ? "0.4"
                                        : "",
                            }}
                            onClick={() => {
                                setSelectedMotif(selectedRow.original);
                                setIsHitoryAllMotifs(false);
                                props.setIsHisotyMotifModalOpened(
                                    !props.isHisotyMotifModalOpened
                                );
                            }}
                        >
                            <DocumentIcon
                                height={25}
                                width={25}
                                className="mr-2"
                            />
                            {t("Historical")}
                        </div>
                    </div>
                </div>
            );
        }
        return null;
    }

    React.useEffect(() => {
        if (filterActive) {
            props.setIsHidden(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterActive]);
    return (
        <div>
            <DynamicTable
                url={props.urlTable}
                minHeight={`calc(100vh - (500px + ${
                    isVisible ? "-71px" : "0px"
                }))`}
                maxHeight={`calc(100vh - (500px + ${
                    !isVisible ? "100px" : "0px"
                }))`}
                actionColumn={SelectAccountDropdown}
                showFilter
                canSelect
                customSelect
                canResize
                onClick={() => {
                    if (modif === true) {
                        props.setIsAlertupdateModal(true);
                    }
                }}
                setSelectedRows={props.setSelectedRows}
                customJsxSideFilterButton={
                    props.isHidden === true || filterActive === true ? (
                        <StyledIconButton
                            icon="PlusIcon"
                            disabled={filterActive ? true : false}
                            style={{
                                background: "#323333",
                                border: "none",
                                borderColor: "#323333",
                                borderRadius: "6px",
                                display: "flex",
                                padding: "12px 10px",
                            }}
                        >
                            <PlusIcon
                                height={20}
                                width={20}
                                style={{ fill: "#fff" }}
                            />
                        </StyledIconButton>
                    ) : (
                        <div className="d-flex align-items-center mt-2 mx-2 mb-2">
                            <AvForm
                                onSubmit={() => {
                                    setLocalFilterActive(false);
                                    props.setIsEditAction(false);
                                    setSelectedMotif("");

                                    props.setIsHidden(!props.isHidden);
                                }}
                            >
                                <StyledButtonWithIcon
                                    disabled={props.isMotifState ? false : true}
                                    icon="PlusIcon"
                                    iconPosition="left"
                                    rounded
                                    variant="primary"
                                >
                                    {t("Add a motif")}
                                </StyledButtonWithIcon>
                            </AvForm>
                            {props.isMotifConfiguration ? (
                                <CommonMenu
                                    setIsHisotyMotifModalOpened={
                                        props.setIsHisotyMotifModalOpened !==
                                        undefined
                                            ? props.setIsHisotyMotifModalOpened
                                            : () => {}
                                    }
                                    isHisotyMotifModalOpened={
                                        props.isHisotyMotifModalOpened
                                    }
                                    setDataIsUpdated={
                                        props.setDataIsUpdated !== undefined
                                            ? props.setDataIsUpdated
                                            : () => {}
                                    }
                                    dataIsUpdated={props.dataIsUpdated}
                                    selectedRows={props.selectedRows}
                                    setMotifState={props.setMotifState}
                                    isMotifState={props.isMotifState}
                                />
                            ) : null}
                        </div>
                    )
                }
                showGlobalFilter
                filterActive={filterActive}
                setLocalFilterActive={setLocalFilterActive}
                arrayOfCustomColumns={arrayOfCustomColumns}
                setDataIsUpdated={props.setDataIsUpdated}
                dataIsUpdated={props.dataIsUpdated}
            />
            {/* <AlerteModalMotif
                isAlerteModalOpened={isAlerteModalOpened}
                setIsAlerteModalOpened={setIsAlerteModalOpened}
                setDataIsUpdated={props.setDataIsUpdated}
                dataIsUpdated={props.dataIsUpdated}
                selectedRows={props.selectedRows}
                isActiveArchiveMotif={props.isMotifState}
            /> */}
        </div>
    );
}

export default TableListeMotifs;
