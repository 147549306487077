import React, { useState, useEffect } from "react";
import { DynamicTable } from "@maherunlocker/custom-react-table";
import NumericInput from "react-numeric-input";
import Switch from "react-switch";
import { useSnapshot } from "valtio";
import { store } from "../../pages/store";
import classnames from "classnames";
// import { replaceQuotedString } from "@components/Common/Replace";

type TableProps = {
    URL: string;
    setSelectedProducts: React.Dispatch<React.SetStateAction<any>>;
    setSelectedProductsToAssociate: React.Dispatch<React.SetStateAction<any>>;
    setSelectedProductsToDissociate: React.Dispatch<React.SetStateAction<any>>;
    setProduct: React.Dispatch<React.SetStateAction<any>>;
};

export default function TableAssociateProduct({
    URL,
    setSelectedProducts,
    setSelectedProductsToAssociate,
    setSelectedProductsToDissociate,
    setProduct,
}: TableProps) {
    const [filterActive, setLocalFilterActive] = useState<boolean>(false);
    const [dataIsUpdated, setDataIsUpdated] = React.useState<number | boolean>(
        false
    );
    const {
        AssociatedProducts,
        PackUpdated,
        listRefPack,
        isAffectedPack,
        uuidAssociatedProductsToPack,
    } = useSnapshot(store);
    interface customColumnProps {
        indexOFColumn: number;
        columnName: string;
        customJsx: Function;
    }

    function ModuleColmun({ selectedRow }: any) {
        let moduleNumber;

        if (typeof selectedRow.Module === "number") {
            if (selectedRow.Module < 10) {
                moduleNumber = "0" + selectedRow.Module;
            } else {
                moduleNumber = selectedRow.Module;
            }
        }
        return (
            <React.Fragment>
                {typeof selectedRow.Module === "number" ? (
                    <h6
                        className="badge__clz d-flex justify-content-center d-flex align-items-center hovertext"
                        data-hover={`Ce produit contient  ${moduleNumber} module(s) `}
                    >
                        {moduleNumber}
                    </h6>
                ) : (
                    <p>{selectedRow.Module}</p>
                )}
            </React.Fragment>
        );
    }

    let Pack: any = [];
    let Selecteditem: any = [];
    let associate: any = [];
    let Data: any = {};

    function SelectColmunProduct({ selectedRow }: any) {
        const [
            numberProductToAssociate,
            setNumberProductToAssociate,
        ] = React.useState<number>(0);
        const [
            numberAssociatedLicense,
            setNumberAssociatedLicense,
        ] = React.useState<number>(0);

        const [isChecked, setIsChecked] = useState(false);
        const [isAssociated, setIsAssociated] = useState(false);
        function Associate() {
            if (isChecked === false) {
                Selecteditem.push(
                    selectedRow.Module +
                        "," +
                        selectedRow.Référence +
                        "," +
                        selectedRow.Uuid +
                        "," +
                        selectedRow.Descriptif +
                        "|" +
                        selectedRow.parentReference +
                        "," +
                        selectedRow.parentName
                );
            } else {
                associate.push(
                    selectedRow.Module +
                        "," +
                        selectedRow.Référence +
                        "," +
                        selectedRow.Uuid +
                        "," +
                        selectedRow.Descriptif +
                        "|" +
                        selectedRow.parentReference +
                        "," +
                        selectedRow.parentName
                );
            }
            setSelectedProductsToAssociate(
                Selecteditem.filter((word: any) => !associate.includes(word))
            );
            setSelectedProductsToDissociate(
                associate.filter((word: any) => !Selecteditem.includes(word))
            );
        }

        useEffect(() => {
            if (
                AssociatedProducts.length > 0 &&
                selectedRow.Produit !== undefined
            ) {
                AssociatedProducts.forEach((AssociatedProduct: any) => {
                    if (
                        AssociatedProduct.referenceProduct ===
                        selectedRow.Référence
                    ) {
                        setNumberProductToAssociate(AssociatedProduct.Quantity);
                        setNumberAssociatedLicense(AssociatedProduct.Quantity);
                    }
                });
            } else {
                if (AssociatedProducts.length > 0) {
                    for (let i: any = 0; i < listRefPack.length; i++) {
                        let arr: string[] = listRefPack[i].split("|");
                        if (
                            arr[0] === selectedRow.parentReference &&
                            arr[1] === selectedRow.Référence
                        ) {
                            setIsChecked(true);
                            setIsAssociated(true);
                        }
                    }
                }
            }
            // eslint-disable-next-line
        }, []);

        return (
            <div
                className="d-flex align-items-center "
                style={{ marginLeft: "25%" }}
            >
                {selectedRow.Produit !== undefined &&
                selectedRow.Produit !== "" ? (
                    <div
                        className={`${classnames({
                            hovertextassociated: uuidAssociatedProductsToPack.includes(
                                selectedRow.Uuid
                            ),
                        })}`}
                        data-hover={`Attention, vous ne pouvez pas dissocier un produit d'un pack ayant des licences actives.`}
                    >
                        <NumericInput
                            style={{
                                input: {
                                    width: "60px",
                                    height: "30px",
                                    color: "black",
                                },
                            }}
                            min={
                                uuidAssociatedProductsToPack.includes(
                                    selectedRow.Uuid
                                )
                                    ? numberAssociatedLicense
                                    : 0
                            }
                            strict
                            max={100}
                            defaultValue={0}
                            value={numberProductToAssociate}
                            onChange={(selectedValue: any) => {
                                setNumberProductToAssociate(selectedValue);
                                if (Data[selectedRow.Référence] !== undefined) {
                                    Data[
                                        selectedRow.Référence
                                    ].Quantity = selectedValue;
                                } else {
                                    Data[selectedRow.Référence] = {
                                        Name: selectedRow.Produit,
                                        Reference: selectedRow.Référence,
                                        Quantity: selectedValue,
                                        Uuid: selectedRow.Uuid,
                                        Comment: selectedRow.Descriptif,
                                        Module: [],
                                    };
                                }
                                setProduct(Data);
                            }}
                        />
                    </div>
                ) : (
                    <div
                        className={`${classnames({
                            hovertext: isAffectedPack && isAssociated,
                        })}`}
                        data-hover={`vous ne pouvez pas dissocier un module d'un pack ayant des licences actives.`}
                    >
                        <Switch
                            onChange={() => {
                                Associate();
                                setIsChecked(!isChecked);
                            }}
                            checked={isChecked}
                            disabled={
                                isAffectedPack && isAssociated ? true : false
                            }
                            offColor="#E30613"
                            onColor="#34C38F"
                        ></Switch>
                    </div>
                )}
            </div>
        );
    }

    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 2,
            columnName: "Module",
            customJsx: ModuleColmun,
        },
        {
            indexOFColumn: 5,
            columnName: "Actions",
            customJsx: SelectColmunProduct,
        },
    ];

    useEffect(() => {
        if (PackUpdated.length > 0) {
            PackUpdated.forEach((updatedPack: any) => {
                Pack.push(updatedPack);
            });
            setSelectedProducts(Pack);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [PackUpdated]);

    return (
        <DynamicTable
            url={URL}
            maxHeight={"calc(100vh - 445px)"}
            minHeight={"calc(100vh - 445px)"}
            customSelect
            canExpand
            setDataIsUpdated={setDataIsUpdated}
            dataIsUpdated={dataIsUpdated}
            showGlobalFilter
            showFilter
            filterActive={filterActive}
            setLocalFilterActive={setLocalFilterActive}
            arrayOfCustomColumns={arrayOfCustomColumns}
        />
    );
}
