import React from "react";

export default function PeopleIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30.5"
            height="27.551"
            viewBox="0 0 30.5 27.551"
        >
            <g
                id="Groupe_27534"
                data-name="Groupe 27534"
                transform="translate(-1728.5 -600.171)"
            >
                <g
                    id="Groupe_14047"
                    data-name="Groupe 14047"
                    transform="translate(1728.5 600.171)"
                >
                    <path
                        id="Tracé_9851"
                        data-name="Tracé 9851"
                        d="M26.547,33.926a5.329,5.329,0,0,0-.088-7.759.454.454,0,1,0-.616.668A4.425,4.425,0,0,1,20.967,34.1a.341.341,0,0,1-.195-.288,5.334,5.334,0,0,0-2.18-3.944.344.344,0,0,1-.139-.319,4.432,4.432,0,0,1,6-3.579.454.454,0,1,0,.33-.846,5.34,5.34,0,0,0-7.188,4.02.113.113,0,0,1-.155.083,5.33,5.33,0,0,0-3.989,0,.113.113,0,0,1-.154-.083A5.342,5.342,0,0,0,8.05,24.757h0A5.335,5.335,0,0,0,3,28.357a.454.454,0,1,0,.859.295,4.426,4.426,0,0,1,8.578.893.343.343,0,0,1-.137.318,5.335,5.335,0,0,0-2.178,3.945.342.342,0,0,1-.194.287,4.425,4.425,0,0,1-6.3-3.822.454.454,0,0,0-.907.037,5.3,5.3,0,0,0,1.558,3.551A5.14,5.14,0,0,0,0,38.921V46.43a1.794,1.794,0,0,0,1.794,1.794H7.4V50.51A1.794,1.794,0,0,0,9.192,52.3H21.35A1.8,1.8,0,0,0,23.1,50.509V48.224h5.6A1.794,1.794,0,0,0,30.5,46.429V38.921A5.142,5.142,0,0,0,26.547,33.926ZM13.38,30.258a4.432,4.432,0,0,1,6.477,3.532h0a4.37,4.37,0,0,1-.016.907h0a4.426,4.426,0,0,1-1.968,3.17h0v0a4.415,4.415,0,0,1-3.061.678v0s0,0,0,0a4.426,4.426,0,0,1-1.429-8.291ZM21.317,51.4H9.192a.886.886,0,0,1-.886-.886V43a4.182,4.182,0,0,1,.887-2.59.454.454,0,1,0-.717-.558A5.084,5.084,0,0,0,7.4,43v4.314h-5.6a.886.886,0,0,1-.886-.886V38.921A4.224,4.224,0,0,1,5.132,34.7H5.36a5.338,5.338,0,0,0,4.444.429.343.343,0,0,1,.447.248A5.322,5.322,0,0,0,11.3,37.522a.342.342,0,0,1-.178.543,5.1,5.1,0,0,0-1.156.488.454.454,0,1,0,.454.787,4.223,4.223,0,0,1,2.258-.562.343.343,0,0,1,.163.045,5.325,5.325,0,0,0,5.194.007.343.343,0,0,1,.188-.046A4.229,4.229,0,0,1,22.194,43c0,1.516,0,5.993,0,7.508A.892.892,0,0,1,21.317,51.4Zm8.275-4.966a.887.887,0,0,1-.886.886H23.1V43a5.141,5.141,0,0,0-3.954-5,5.322,5.322,0,0,0,1.571-3.025,5.324,5.324,0,0,0,4.8-.28,4.229,4.229,0,0,1,4.069,4.22Z"
                        transform="translate(0 -24.753)"
                        fill="#707070"
                    />
                </g>
            </g>
        </svg>
    );
}
