import React, { useState } from "react";
import { DynamicTable } from "@maherunlocker/custom-react-table";
import { store } from "../store";
import { useSnapshot } from "valtio";
import Switch from "react-switch";
import classnames from "classnames";

type TableProps = {
    URL: string;
    setListModulesSelected: React.Dispatch<React.SetStateAction<any>>;
    setListIuudsModulesSelected: React.Dispatch<React.SetStateAction<any>>;
    setSelectedModulesDissocié: React.Dispatch<React.SetStateAction<any>>;
    setListNameModulesSelected: React.Dispatch<React.SetStateAction<any>>;
    setSelectedModulesNameDissocié: React.Dispatch<React.SetStateAction<any>>;
    setEditProduct: Function;
    setModifProduct: Function;
};

export default function TableAssociateModules({
    URL,
    setListModulesSelected,
    setListIuudsModulesSelected,
    setSelectedModulesDissocié,
    setSelectedModulesNameDissocié,
    setListNameModulesSelected,
    setModifProduct,
    setEditProduct,
}: TableProps) {
    const [filterActive, setLocalFilterActive] = useState<boolean>(false);
    const [dataIsUpdated, setDataIsUpdated] = React.useState<number | boolean>(
        false
    );
    const { listRefModule, SelectedProduct } = useSnapshot(store);

    interface customColumnProps {
        indexOFColumn: number;
        columnName: string;
        customJsx: Function;
    }
    let AssociatedModulesToProducts: any = [];
    let listAssociatedModulesIuuds: any = [];
    let referenceModule: any = [];
    let iuudsModules: any = [];
    let AssociatedModulesToProductscotéProduct: any = [];
    let NameModule: any = [];
    let NameModuletoDissociate: any = [];
    let selectedElement: any = {
        element: [],
    };

    function SelectColmunProduct({ selectedRow }: any) {
        const [checked, setChecked] = React.useState(true);
        const {
            selectedModulesToDissociate,
            isAffected,
            produitLicence,
            selectedProductsLicence,
        } = useSnapshot(store);

        function associateModulesToProduct() {
            if (selectedModulesToDissociate === false) {
                if (
                    Object.values(listRefModule).includes(
                        selectedRow.Référence
                    ) &&
                    checked
                ) {
                    if (
                        Object.values(listRefModule).includes(
                            selectedRow.Référence
                        )
                    ) {
                        selectedElement.element.push({
                            referenceProduit: SelectedProduct.Référence,
                            referenceModule: selectedRow.Référence,
                        });
                        NameModuletoDissociate.push(selectedRow.Module);
                    }
                    setSelectedModulesDissocié(selectedElement.element);
                    setSelectedModulesNameDissocié(NameModuletoDissociate);
                    setModifProduct(true);
                    setEditProduct("EditAssociate");
                } else {
                    if (
                        AssociatedModulesToProducts.includes(
                            selectedRow.Référence
                        )
                    ) {
                        referenceModule = AssociatedModulesToProducts.filter(
                            (word: any) => word !== selectedRow.Référence
                        );
                        AssociatedModulesToProducts = referenceModule;
                        NameModule = AssociatedModulesToProducts.filter(
                            (word: any) => word !== selectedRow.Module
                        );
                        AssociatedModulesToProductscotéProduct = NameModule;
                    } else {
                        AssociatedModulesToProducts.push(selectedRow.Référence);
                        AssociatedModulesToProductscotéProduct.push(
                            selectedRow.Module
                        );
                    }
                    if (listAssociatedModulesIuuds.includes(selectedRow.Uuid)) {
                        iuudsModules = listAssociatedModulesIuuds.filter(
                            (word: any) => word !== selectedRow.Uuid
                        );
                        listAssociatedModulesIuuds = iuudsModules;
                    } else {
                        listAssociatedModulesIuuds.push(selectedRow.Uuid);
                    }

                    setListModulesSelected(AssociatedModulesToProducts);
                    setListIuudsModulesSelected(listAssociatedModulesIuuds);
                    setListNameModulesSelected(
                        AssociatedModulesToProductscotéProduct
                    );
                    setModifProduct(true);
                    setEditProduct("EditAssociate");
                }
            } else {
                setSelectedModulesDissocié([]);
                setListModulesSelected([]);
                setListIuudsModulesSelected([]);
                setListNameModulesSelected([]);
                setSelectedModulesNameDissocié([]);
                if (
                    Object.values(listRefModule).includes(
                        selectedRow.Référence
                    ) &&
                    checked
                ) {
                    if (
                        Object.values(listRefModule).includes(
                            selectedRow.Référence
                        )
                    ) {
                        selectedElement.element.push({
                            referenceProduit: SelectedProduct.Référence,
                            referenceModule: selectedRow.Référence,
                        });
                        NameModuletoDissociate.push(selectedRow.Module);
                    }
                    setSelectedModulesDissocié(selectedElement.element);
                    setSelectedModulesNameDissocié(NameModuletoDissociate);
                    setModifProduct(true);
                    setEditProduct("EditAssociate");
                } else {
                    if (
                        AssociatedModulesToProducts.includes(
                            selectedRow.Référence
                        )
                    ) {
                        referenceModule = AssociatedModulesToProducts.filter(
                            (word: any) => word !== selectedRow.Référence
                        );
                        AssociatedModulesToProducts = referenceModule;
                        NameModule = AssociatedModulesToProducts.filter(
                            (word: any) => word !== selectedRow.Module
                        );
                        AssociatedModulesToProductscotéProduct = NameModule;
                    } else {
                        AssociatedModulesToProducts.push(selectedRow.Référence);
                        AssociatedModulesToProductscotéProduct.push(
                            selectedRow.Module
                        );
                    }

                    if (listAssociatedModulesIuuds.includes(selectedRow.iuud)) {
                        iuudsModules = listAssociatedModulesIuuds.filter(
                            (word: any) => word !== selectedRow.iuud
                        );
                        listAssociatedModulesIuuds = iuudsModules;
                    } else {
                        listAssociatedModulesIuuds.push(selectedRow.iuud);
                    }

                    setListModulesSelected(AssociatedModulesToProducts);
                    setListIuudsModulesSelected(listAssociatedModulesIuuds);
                    setListNameModulesSelected(
                        AssociatedModulesToProductscotéProduct
                    );
                    setModifProduct(true);
                    setEditProduct("EditAssociate");
                }
            }
        }

        return (
            <div
                className="d-flex align-items-center "
                style={{ marginLeft: "25%" }}
            >
                {!selectedRow.isPrincipalProduct ? (
                    <div
                        className={`${classnames({
                            hovertext:
                                isAffected &&
                                Object.values(listRefModule).includes(
                                    selectedRow.Référence
                                ) &&
                                selectedProductsLicence.includes(
                                    produitLicence + "|" + selectedRow.Uuid
                                ),
                        })}`}
                        data-hover={`Vous ne pouvez pas dissocier un module d'un produit ayant des licences actives.`}
                    >
                        <Switch
                            onChange={() => {
                                setChecked(!checked);
                                associateModulesToProduct();
                            }}
                            checked={
                                Object.values(listRefModule).includes(
                                    selectedRow.Référence
                                )
                                    ? checked
                                    : !checked
                            }
                            disabled={
                                isAffected &&
                                Object.values(listRefModule).includes(
                                    selectedRow.Référence
                                ) &&
                                selectedProductsLicence.includes(
                                    produitLicence + "|" + selectedRow.Uuid
                                )
                                    ? true
                                    : false
                            }
                            offColor="#E30613"
                            onColor="#34C38F"
                        ></Switch>
                    </div>
                ) : (
                    <></>
                )}
            </div>
        );
    }

    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 4,
            columnName: "Actions",
            customJsx: SelectColmunProduct,
        },
    ];

    function SelectAccountDropdown({ selectedRow }: any) {
        return <div className="w-100"></div>;
    }

    return (
        <DynamicTable
            url={URL}
            maxHeight={"calc(100vh - 445px)"}
            minHeight={"calc(100vh - 445px)"}
            actionColumn={SelectAccountDropdown}
            customSelect
            canExpand
            setDataIsUpdated={setDataIsUpdated}
            dataIsUpdated={dataIsUpdated}
            showGlobalFilter
            showFilter
            filterActive={filterActive}
            setLocalFilterActive={setLocalFilterActive}
            arrayOfCustomColumns={arrayOfCustomColumns}
        />
    );
}
