import React, { useState } from "react";
import {
    Card,
    CardBody,
    Col,
    Row,
    CardFooter,
    Container,
    CardHeader,
} from "reactstrap";
import {
    CrossIcon,
    RefreshIcon,
    StyledButton,
    StyledLabel,
    StyledTextInput,
} from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { useSnapshot } from "valtio";

import Breadcurmb from "@components/Common/Breadcrumb/index";
import { showToast } from "@components/Common/ToastShow";
import MessageInfo from "@components/Common/MessageInfo";
import { HistoryMotifsModal } from "./HistoryMotifsModal";
import PageTitle from "@components/Common/PageTitle";
import {
    store,
    setSelectedMotif,
    setModif,
    setIsAlertupdateModal,
    setEdit,
} from "../store";
import TableListeMotifs from "./TableListeMotifs";
import { AvForm } from "availity-reactstrap-validation";
import AlerteUpdateModalMotif from "./AlerteUpdateModalMotif";

export default function Motifs() {
    const { t } = useTranslation();
    const {
        isAlertupdateModal,
        edit,
        selectedMotif,
        SelectedMotifBeforeUpdate,
        modif,
        isVisible,
    } = useSnapshot(store);
    const uidEntity = localStorage.getItem("Uid_Entitie_User");
    const [isMotifState, setMotifState] = React.useState<boolean>(true);
    const [, setTypeConfirmation] = React.useState<Number>(0);
    const [isEditAction, setIsEditAction] = React.useState<boolean>(false);
    const [dataIsUpdated, setDataIsUpdated] = React.useState<number | boolean>(
        false
    );
    const [
        isHisotyMotifModalOpened,
        setIsHisotyMotifModalOpened,
    ] = React.useState<boolean>(false);
    const [isCofirmModalOpened, setIsConfirmModalOpened] = React.useState<
        boolean
    >(false);
    const [isHidden, setIsHidden] = useState(false);
    const [description, setDescription] = useState("");
    const [optionsMotifsData, setoptionsMotifsData] = React.useState([]);
    const [motifValue, setMotifValue] = React.useState<string>("");
    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
    const [isEmpty, setIsEmpty] = React.useState<boolean>(false);
    const [isReset, setIsReset] = React.useState<boolean>(false);
    const [isResetDes, setIsResetDes] = React.useState<boolean>(false);
    const [inputValue, setInputValue] = React.useState<boolean>(false);
    const [counter, setCounter] = React.useState<number>(2000);

    let typeMotif = 0;
    let urlListMotifTable = "";
    let titleBreadcurmb = "";

    if (isMotifState) {
        titleBreadcurmb = t("CONFIGURATION / MOTIF ACTIVATED");
    } else {
        titleBreadcurmb = t("CONFIGURATION / ARCHIVED MOTIF");
    }

    urlListMotifTable = `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/listMotifs?nTypeMotif=${typeMotif}&uidEntitie=${uidEntity}&isArchivedMotifs=${isMotifState}`;

    function saveHistoryChanges(oldData: any, newData: any) {
        fetch(`${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/HistoryChanges`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                oldData: oldData,
                newData: newData,
                uidUpdatedData: uidEntity,
                typeHistory: "3",
            }),
        })
            .then((response) => {
                response.json().then((data) => {
                    setTimeout(function () {
                        setIsHidden(!isHidden);
                    }, 2000);
                    return data.error;
                });
            })
            .catch((e: any) => {
                return false;
            });
    }

    function handleEditMotifClick() {
        const requestOptions = {
            body: JSON.stringify({
                data: {
                    [selectedMotif.iuud]: {
                        type: 0,
                        archiver: false,
                        keyMotif: selectedMotif.iuud,
                        description: selectedMotif.Descriptif,
                        name: selectedMotif.Motif,
                    },
                },
                uidEntitie: uidEntity,
            }),
            method: "PUT",
            headers: { "Content-Type": "application/json" },
        };
        const exist = optionsMotifsData.filter(
            (word: any) => word === motifValue
        );
        if (selectedMotif.Motif === "") {
            setIsEmpty(true);
            showToast(false, `${t("The 'Motif' field must be filled in")}`);
        } else {
            if (isReset === true || isResetDes === true) {
                setIsHidden(!isHidden);
            } else {
                if (selectedMotif.Motif === SelectedMotifBeforeUpdate.Motif) {
                    fetch(
                        `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/updateMotif`,
                        requestOptions
                    )
                        .then((response) => {
                            response.json().then((data) => {
                                if (!data.error) {
                                    showToast(
                                        true,
                                        `${t("The Motif")} "${
                                            selectedMotif.Motif
                                        }" ${t(
                                            "has been changed successfully."
                                        )}`
                                    );
                                    setDataIsUpdated(!dataIsUpdated);
                                    setIsHidden(!isHidden);
                                    setModif(false);
                                    setMotifValue("");
                                    setDescription("");
                                    setEdit("");
                                    const oldMotif = SelectedMotifBeforeUpdate;
                                    const newMotif = {
                                        Motif: selectedMotif.Motif,
                                        Descriptif: selectedMotif.Descriptif,
                                    };
                                    saveHistoryChanges(oldMotif, newMotif);
                                } else {
                                    showToast(
                                        false,
                                        `${t("The motif is not changed.")}`
                                    );
                                }
                            });
                        })
                        .catch((e: any) => {
                            console.log({ error: e.message });
                        });
                } else {
                    if (exist.length < 1) {
                        fetch(
                            `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/updateMotif`,
                            requestOptions
                        )
                            .then((response) => {
                                response.json().then((data) => {
                                    if (!data.error) {
                                        showToast(
                                            true,
                                            `${t("The Motif")} "${
                                                selectedMotif.Motif
                                            }" ${t(
                                                "has been changed successfully."
                                            )}`
                                        );
                                        setIsHidden(!isHidden);
                                        setModif(false);
                                        setEdit("");
                                        setMotifValue("");
                                        setDescription("");
                                        setDataIsUpdated(!dataIsUpdated);
                                        const oldMotif = SelectedMotifBeforeUpdate;
                                        const newMotif = {
                                            Motif: selectedMotif.Motif,
                                            Descriptif:
                                                selectedMotif.Descriptif,
                                        };
                                        saveHistoryChanges(oldMotif, newMotif);
                                    } else {
                                        showToast(
                                            false,
                                            `${t("The motif is not changed.")}`
                                        );
                                    }
                                });
                            })
                            .catch((e: any) => {
                                console.log({ error: e.message });
                            });
                    } else {
                        showToast(
                            false,
                            `${t(
                                "The pattern already exists. Please enter another"
                            )}`
                        );
                    }
                }
            }
        }
    }

    function handleAddMotifClick() {
        if (motifValue.trim().length !== 0) {
            const exist = optionsMotifsData.filter(
                (word: any) => word === motifValue
            );
            const Motifuuid = uuidv4();
            if (exist.length < 1) {
                fetch(
                    `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/addMotif`,
                    {
                        method: "PUT",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({
                            uidEntitie: uidEntity,
                            nameMotif: motifValue,
                            typeMotif: typeMotif,
                            archiver: false,
                            description: description,
                            uuidMotif: Motifuuid,
                        }),
                    }
                )
                    .then((response) => {
                        response.json().then((data) => {
                            if (!data.error) {
                                showToast(
                                    true,
                                    `${t("The Motif")} "${motifValue}" ${t(
                                        "was successfully added."
                                    )}`
                                );
                                setMotifValue("");
                                setSelectedMotif("");
                                setDataIsUpdated(!dataIsUpdated);
                                setIsHidden(!isHidden);
                                setModif(false);
                                setEdit("");
                                setDescription("");
                                setIsEmpty(false);
                                const newData = {
                                    uuidMotif: Motifuuid,
                                };
                                saveHistoryChanges({}, newData);
                            } else {
                                showToast(
                                    false,
                                    `${t("Oops!, motif is not added")}`
                                );
                            }
                        });
                    })
                    .catch((e: any) => {
                        console.log({ error: e.message });
                    });
            } else {
                showToast(
                    false,
                    `${t("The pattern already exists. Please enter another")}`
                );
            }
        } else {
            showToast(false, `${t("The 'Motif' field must be filled in")}`);
            setIsEmpty(true);
        }
    }

    async function handleListMotifClick() {
        try {
            await fetch(
                `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/listTitleMotifs?uidEntitie=${uidEntity}&number=${typeMotif}`,
                {
                    method: "post",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        uidEntitie: uidEntity,
                        number: JSON.stringify(typeMotif),
                    }),
                }
            )
                .then((response) => response.json())
                .then((data) => {
                    setoptionsMotifsData(data);
                });
        } catch (error) {
            console.log(error);
        }
    }

    React.useEffect(() => {
        handleListMotifClick();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataIsUpdated]);

    return (
        <React.Fragment>
            <PageTitle title="MOTIFS" />

            <div className="">
                <Container
                    fluid
                    style={{
                        marginTop: "-15px",
                    }}
                >
                    <Row>
                        <Col>
                            <Breadcurmb
                                title={titleBreadcurmb}
                                breadcrumbBackgroundColor={""}
                            />
                        </Col>
                        <MessageInfo
                            message={t(
                                "Reasons used during archive and block operations are added and configured in this topic."
                            )}
                        />
                    </Row>

                    <CardBody
                        style={{
                            padding: "1px",
                        }}
                    >
                        <div
                            className="row "
                            style={{
                                display: "grid",
                                gridTemplateColumns: isHidden
                                    ? "2fr 1fr"
                                    : "auto",
                                gap: "4px",
                            }}
                        >
                            <TableListeMotifs
                                setIsHidden={setIsHidden}
                                isHidden={isHidden}
                                urlTable={urlListMotifTable}
                                isCofirmModalOpened={isCofirmModalOpened}
                                setIsConfirmModalOpened={
                                    setIsConfirmModalOpened
                                }
                                setTypeConfirmation={setTypeConfirmation}
                                setIsEditAction={setIsEditAction}
                                isEditAction={isEditAction}
                                setDataIsUpdated={setDataIsUpdated}
                                dataIsUpdated={dataIsUpdated}
                                setSelectedRows={setSelectedRows}
                                selectedRows={selectedRows}
                                isMotifConfiguration={true}
                                isHisotyMotifModalOpened={
                                    isHisotyMotifModalOpened
                                }
                                setEdit={setEdit}
                                setModif={setModif}
                                setIsHisotyMotifModalOpened={
                                    setIsHisotyMotifModalOpened
                                }
                                setMotifState={setMotifState}
                                isMotifState={isMotifState}
                                setIsAlertupdateModal={setIsAlertupdateModal}
                            />

                            {!isHidden ? null : (
                                <Card className="border ml-2">
                                    <CardHeader
                                        className="Headercard  d-flex justify-content-between pb-2"
                                        style={{
                                            height: "11.7%",
                                            backgroundColor: "white",
                                            border:
                                                "1px solid rgba(0,0,0,.125)",
                                        }}
                                    >
                                        <div className=" d-flex align-items-center justify-content-center">
                                            <h4
                                                className="m-0 pl-2"
                                                style={{
                                                    font:
                                                        "normal normal 600 20px/27px Segoe UI",
                                                }}
                                            >
                                                {isEditAction
                                                    ? !isMotifState
                                                        ? `${t(
                                                              "View the motif"
                                                          )}`
                                                        : `${t(
                                                              "Edit the motif"
                                                          )}`
                                                    : `${t("Add the motif")}`}
                                            </h4>
                                        </div>

                                        <div className="d-flex align-items-center ">
                                            {isMotifState ? (
                                                <RefreshIcon
                                                    cursor="pointer"
                                                    height={20}
                                                    width={20}
                                                    onClick={() => {
                                                        if (isEditAction) {
                                                            setIsReset(true);
                                                            setIsResetDes(true);
                                                        } else {
                                                            setDescription("");
                                                            setMotifValue("");
                                                        }
                                                        setModif(false);
                                                    }}
                                                />
                                            ) : (
                                                <></>
                                            )}

                                            <div className=" bd-highlight align-items-center ml-4">
                                                <CrossIcon
                                                    cursor="pointer"
                                                    height={17}
                                                    width={17}
                                                    onClick={() => {
                                                        if (modif === true) {
                                                            setIsAlertupdateModal(
                                                                true
                                                            );
                                                            setIsHidden(
                                                                isHidden
                                                            );
                                                        } else {
                                                            setInputValue(
                                                                false
                                                            );
                                                            setIsHidden(
                                                                !isHidden
                                                            );
                                                            setDescription("");
                                                            setMotifValue("");
                                                            setIsEmpty(false);
                                                            setIsReset(false);
                                                            setIsResetDes(
                                                                false
                                                            );
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </CardHeader>
                                    <CardBody
                                        style={{
                                            background: "#FFFFFF",
                                            border:
                                                "1px solid rgba(0,0,0,.125)",
                                            marginTop: "4px",
                                            overflow: "auto",
                                            maxHeight: `calc(100vh - (500px + ${
                                                isVisible ? "-71px" : "0px"
                                            }))`,
                                        }}
                                    >
                                        <AvForm>
                                            <StyledLabel>Motif*</StyledLabel>
                                            <StyledTextInput
                                                style={{
                                                    pointerEvents: !isMotifState
                                                        ? "none"
                                                        : "auto",
                                                    opacity: !isMotifState
                                                        ? "0.9"
                                                        : "",
                                                    border: isEmpty
                                                        ? "1px solid red"
                                                        : "1px solid #ced4da",
                                                }}
                                                id="example-inputddddd"
                                                name="example-inputddddd"
                                                placeholder="Saisir"
                                                type="text"
                                                autoFocus={
                                                    !isMotifState ? false : true
                                                }
                                                onChange={(e: any) => {
                                                    setMotifValue(
                                                        e.target.value.trim()
                                                    );
                                                    setIsResetDes(false);
                                                    setIsEmpty(false);
                                                    if (selectedMotif !== "") {
                                                        setSelectedMotif({
                                                            ...selectedMotif,
                                                            Motif:
                                                                e?.target.value,
                                                        });
                                                        setModif(true);
                                                    }
                                                }}
                                                value={
                                                    selectedMotif === ""
                                                        ? motifValue
                                                        : isResetDes
                                                        ? SelectedMotifBeforeUpdate?.Motif
                                                        : selectedMotif?.Motif
                                                }
                                            />
                                            {isEmpty ? (
                                                <div
                                                    style={{
                                                        color: "red",
                                                    }}
                                                >
                                                    Ce champ est obligatoire
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </AvForm>

                                        <AvForm>
                                            <StyledLabel htmlFor="example-input">
                                                {t("Description")}
                                            </StyledLabel>
                                            <StyledTextInput
                                                style={{
                                                    pointerEvents: !isMotifState
                                                        ? "none"
                                                        : "auto",
                                                    opacity: !isMotifState
                                                        ? "0.8"
                                                        : "",
                                                }}
                                                id="example-input"
                                                name="example-input"
                                                placeholder="Saisir"
                                                value={
                                                    selectedMotif === ""
                                                        ? description
                                                        : isReset
                                                        ? SelectedMotifBeforeUpdate?.Descriptif
                                                        : selectedMotif?.Descriptif
                                                }
                                                onChange={(e: any) => {
                                                    setDescription(
                                                        e.target.value
                                                    );
                                                    setInputValue(true);
                                                    setIsReset(false);
                                                    setCounter(
                                                        2000 -
                                                            e.target.value
                                                                .length
                                                    );
                                                    if (selectedMotif !== "") {
                                                        setSelectedMotif({
                                                            ...selectedMotif,
                                                            Descriptif:
                                                                e?.target.value,
                                                        });
                                                        setModif(true);
                                                    }
                                                }}
                                                type="textarea"
                                                maxlength={2000}
                                            />
                                            <div className="d-flex justify-content-end ">
                                                {isEditAction ? (
                                                    isReset ? (
                                                        <small>
                                                            Nombre de caractère
                                                            maximal :
                                                            {2000 -
                                                                SelectedMotifBeforeUpdate
                                                                    .Descriptif
                                                                    .length}
                                                            /2000
                                                        </small>
                                                    ) : selectedMotif.Descriptif
                                                          .length === 0 ? (
                                                        <small>
                                                            Nombre de caractère
                                                            maximal : 2000
                                                        </small>
                                                    ) : (
                                                        <small>
                                                            Nombre de caractère
                                                            maximal :
                                                            {2000 -
                                                                selectedMotif
                                                                    .Descriptif
                                                                    .length}
                                                            /2000
                                                        </small>
                                                    )
                                                ) : inputValue ? (
                                                    <small>
                                                        Nombre de caractère
                                                        maximal :{counter}
                                                        /2000
                                                    </small>
                                                ) : (
                                                    <small>
                                                        Nombre de caractère
                                                        maximal : 2000
                                                    </small>
                                                )}
                                            </div>
                                        </AvForm>
                                    </CardBody>
                                    <CardFooter
                                        className="Footercard p-0"
                                        style={{
                                            background: "#FFFFFF",
                                        }}
                                    >
                                        <div className=" d-flex flex-wrap d-flex flex-row-reverse bd-highlight ">
                                            <div className="p-2 bd-highlight">
                                                <StyledButton
                                                    disabled={
                                                        !isMotifState
                                                            ? true
                                                            : false
                                                    }
                                                    rounded
                                                    variant="primary"
                                                    outline={false}
                                                    size="sm"
                                                    style={{
                                                        color: "white",
                                                        padding: "0px",
                                                        width: "150px",
                                                        height: "45px",
                                                    }}
                                                    onClick={() => {
                                                        if (isEditAction) {
                                                            if (
                                                                SelectedMotifBeforeUpdate.Descriptif ===
                                                                    selectedMotif.Descriptif &&
                                                                SelectedMotifBeforeUpdate.Motif ===
                                                                    selectedMotif.Motif
                                                            ) {
                                                                setIsHidden(
                                                                    !isHidden
                                                                );
                                                            } else {
                                                                handleEditMotifClick();
                                                            }
                                                        } else {
                                                            handleAddMotifClick();
                                                        }
                                                    }}
                                                >
                                                    {t("Validate")}
                                                </StyledButton>
                                            </div>
                                            <div className="p-2 bd-highlight">
                                                <StyledButton
                                                    disabled={false}
                                                    rounded
                                                    variant="light"
                                                    outline={true}
                                                    size="sm"
                                                    style={{
                                                        background: "white",
                                                        color: "black",
                                                        padding: "0px",
                                                        width: "150px",
                                                        height: "45px",
                                                    }}
                                                    onClick={() => {
                                                        if (modif === true) {
                                                            setIsAlertupdateModal(
                                                                true
                                                            );
                                                            setIsHidden(
                                                                isHidden
                                                            );
                                                        } else {
                                                            setIsHidden(
                                                                !isHidden
                                                            );
                                                            setDescription("");
                                                            setMotifValue("");
                                                            setIsEmpty(false);
                                                            setIsReset(false);
                                                            setIsResetDes(
                                                                false
                                                            );
                                                        }
                                                    }}
                                                >
                                                    {t("Cancel")}
                                                </StyledButton>
                                            </div>
                                        </div>
                                    </CardFooter>
                                </Card>
                            )}
                        </div>
                    </CardBody>
                </Container>
            </div>

            <HistoryMotifsModal
                selectedRows={selectedRows}
                isModalOpened={isHisotyMotifModalOpened}
                setIsModalOpened={setIsHisotyMotifModalOpened}
            />
            <AlerteUpdateModalMotif
                isAlertupdateModal={isAlertupdateModal}
                setIsAlertupdateModal={setIsAlertupdateModal}
                setModif={setModif}
                setIsHidden={setIsHidden}
                isHidden={isHidden}
                setEdit={setEdit}
                edit={edit}
                selectedRows={selectedRows}
                setDescription={setDescription}
                setMotifValue={setMotifValue}
                setIsEmpty={setIsEmpty}
            />
        </React.Fragment>
    );
}
