import React from "react";
import {
    StyledButton,
    StyledModal,
    CrossIcon,
    StyledIconButton,
    StyledLabel,
} from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { useSnapshot } from "valtio";
import { store } from "../store";

import { showToast } from "@components/Common/ToastShow";

import "../Overview/index.css";
type AlerteModaltype = {
    isAlertProfilUsed: boolean;
    setIsAlertProfilUsed: Function;
    selectedOptions: any[];
    setDataIsUpdated: React.Dispatch<React.SetStateAction<number | boolean>>;
    setIsEmpty: React.Dispatch<React.SetStateAction<boolean>>;
    setDesignation: React.Dispatch<React.SetStateAction<string>>;
    setDescription: React.Dispatch<React.SetStateAction<string>>;
    setBalise: React.Dispatch<React.SetStateAction<any[]>>;
    setIsHidden: React.Dispatch<React.SetStateAction<boolean>>;
    isHidden: boolean;
    setIsEmptyType: React.Dispatch<React.SetStateAction<boolean>>;
    toggle: () => void;
};

export default function AlertProfileUsed({
    isAlertProfilUsed,
    setIsAlertProfilUsed,
    selectedOptions,
    setDataIsUpdated,
    setIsEmpty,
    setDescription,
    setDesignation,
    setBalise,
    setIsHidden,
    isHidden,
    setIsEmptyType,
    toggle,
}: AlerteModaltype) {
    const { t } = useTranslation();
    const uidEntity = localStorage.getItem("Uid_Entitie_User");
    const {
        SelectedProfilBeforeUpdate,
        SelectedProfil,
        usedProfile,
        editActionProfile,
        updatedAutorisation,
    } = useSnapshot(store);

    async function updateProfileEntity(array: any, profilEntityUpdated: any) {
        fetch(
            `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/updateProfileEntity`,
            {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    uuidEntity: array,
                    dataProfile: profilEntityUpdated,
                }),
            }
        )
            .then((response) => {
                response.json().then((data) => {
                    if (data.error === false) {
                    }
                });
            })
            .catch((e: any) => {
                console.log({ error: e.message });
            });
    }

    function saveHistoryChanges() {
        let oldData;
        let newData;
        if (editActionProfile) {
            oldData = {
                type: SelectedProfil.TypeProfil,
                profil: SelectedProfil.Designation,
                uidProfil: SelectedProfil.UidProfil,
                autorisation: false,
            };
            newData = {
                type: SelectedProfil.TypeProfil,
                profil: SelectedProfil.Designation,
                uidProfil: SelectedProfil.UidProfil,
                autorisation: true,
            };
        } else {
            oldData = {
                type: SelectedProfilBeforeUpdate.TypeProfil,
                description: SelectedProfilBeforeUpdate.Descriptif,
                designation: SelectedProfilBeforeUpdate.Designation,
                balise: SelectedProfilBeforeUpdate.Balise,
                uidProfil: SelectedProfilBeforeUpdate.UidProfil,
            };
            newData = {
                type: SelectedProfil.TypeProfil,
                description: SelectedProfil.Descriptif,
                designation: SelectedProfil.Designation,
                balise: selectedOptions.join(","),
                uidProfil: SelectedProfilBeforeUpdate.UidProfil,
            };
        }
        fetch(`${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/HistoryChanges`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                oldData: oldData,
                newData: newData,
                uidUpdatedData: uidEntity,
                typeHistory: "4",
            }),
        })
            .then((response) => {
                response.json().then((data) => {
                    return data.error;
                });
            })
            .catch((e: any) => {
                return false;
            });
    }
    async function handleUpdateProfil() {
        let arrayUuidEntity: any = [];
        arrayUuidEntity.push(uidEntity);
        let array: any = [];
        let dataProfileEntity: any = [];
        let profilUpdated = {
            type: SelectedProfil.TypeProfil,
            description: SelectedProfil.Descriptif,
            designation: SelectedProfil.Designation,
            autorisation: editActionProfile
                ? updatedAutorisation
                : SelectedProfil.Autorisations,
            uidEntity: arrayUuidEntity,
            uidProfil: SelectedProfil.UidProfil,
            balise: selectedOptions.join(","),
        };
        usedProfile.forEach((el: any) => {
            const elementUuid = el.split("|");

            if (SelectedProfil.UidProfil === elementUuid[0]) {
                array.push(elementUuid[1]);
            }
        });

        let profilEntityUpdated = {
            uid: SelectedProfil.UidProfil,
            name: SelectedProfil.Designation,
        };
        dataProfileEntity.push(profilEntityUpdated);

        fetch(`${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/updateProfile`, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                dataProfil: profilUpdated,
            }),
        })
            .then((response) => {
                response.json().then((data) => {
                    if (data.error === false) {
                        updateProfileEntity(array, dataProfileEntity);
                        setIsEmptyType(false);
                        setIsEmpty(false);
                        saveHistoryChanges();
                        setDesignation("");
                        setDescription("");
                        setBalise([]);
                        setIsAlertProfilUsed(!isAlertProfilUsed);
                        showToast(
                            true,
                            `${t(
                                "The profile has been modified successfully."
                            )}`
                        );
                        if (editActionProfile) {
                            toggle();
                        } else {
                            setIsHidden(!isHidden);
                        }

                        setDataIsUpdated(true);
                    }
                });
            })
            .catch((e: any) => {
                return false;
            });
    }
    return (
        <div>
            <StyledModal
                className="updateAlerte"
                centered
                isOpen={isAlertProfilUsed}
            >
                <ModalHeader className="d-flex align-items-center">
                    <div
                        className="d-flex align-items-center justify-content-between "
                        style={{
                            height: "30px",
                            display: "grid",
                            gridTemplateColumns: "1.8fr 0.2fr",
                            gridTemplateRows: "1fr",
                            gap: "65px",
                        }}
                    >
                        <p className="title-text-style">CONFIRMATION</p>
                        <StyledIconButton
                            icon="CrossIcon"
                            onClick={() => {
                                if (editActionProfile) {
                                    toggle();
                                    setIsAlertProfilUsed(!isAlertProfilUsed);
                                } else {
                                    setIsHidden(!isHidden);
                                    setIsAlertProfilUsed(!isAlertProfilUsed);
                                }
                            }}
                        >
                            <CrossIcon
                                cursor="pointer"
                                height={10}
                                width={10}
                            />
                        </StyledIconButton>
                    </div>
                </ModalHeader>

                <ModalBody>
                    <div
                        style={{
                            marginLeft: "30px",
                            marginTop: "20px",
                            marginBottom: "20px",
                        }}
                    >
                        <StyledLabel>
                            Attention ! Ce profil est utilisé par un ou
                            plusieurs administrateurs de comptes clients. <br />
                            Voulez-vous valider les modifications apportées ?
                        </StyledLabel>
                    </div>
                </ModalBody>

                <ModalFooter
                    className="d-flex justify-content-end"
                    style={{
                        background: "#FFFFFF",
                    }}
                >
                    <div
                        className="d-flex justify-content-end pr-5"
                        style={{ gap: "30px" }}
                    >
                        <div className="col-4">
                            <StyledButton
                                disabled={false}
                                rounded
                                variant="light"
                                outline={true}
                                size="sm"
                                style={{
                                    background: "white",
                                    color: "black",
                                    padding: "0px",
                                    width: "150px",
                                    height: "45px",
                                }}
                                onClick={() => {
                                    if (editActionProfile) {
                                        setIsAlertProfilUsed(
                                            !isAlertProfilUsed
                                        );
                                        toggle();
                                    } else {
                                        setIsHidden(!isHidden);
                                        setIsAlertProfilUsed(
                                            !isAlertProfilUsed
                                        );
                                    }
                                }}
                            >
                                Annuler
                            </StyledButton>
                        </div>
                        <div className="col-4">
                            <StyledButton
                                disabled={false}
                                rounded
                                variant="primary"
                                outline={false}
                                size="sm"
                                style={{
                                    color: "white",
                                    padding: "0px",
                                    width: "150px",
                                    height: "45px",
                                }}
                                onClick={() => {
                                    handleUpdateProfil();
                                    setIsAlertProfilUsed(!isAlertProfilUsed);
                                }}
                            >
                                Valider
                            </StyledButton>
                        </div>
                    </div>
                </ModalFooter>
            </StyledModal>
        </div>
    );
}
