import React from "react";
import {
    StyledButton,
    StyledModal,
    CrossIcon,
    StyledIconButton,
    StyledLabel,
} from "@aureskonnect/react-ui";
import { ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { showToast } from "@components/Common/ToastShow";
import { useSnapshot } from "valtio";
import { store } from "../store";

import "../Overview/index.css";

type AlerteModaltype = {
    setIsAlerteTypeGestionnaireDeComptesModalOpened: Function;
    setDataIsUpdated: React.Dispatch<React.SetStateAction<number | boolean>>;
    dataIsUpdated: number | boolean;
    archiveActiveTypeGestionnaireDeComptes: boolean;
    selectedRows: any;
    ListTypeGestionnaireDeComptes: any;
    setListSelectedtypeGestionnaireDeComptes: React.Dispatch<
        React.SetStateAction<never[]>
    >;
};

export default function AlertTypeGestionnaireDeComptesModal({
    setIsAlerteTypeGestionnaireDeComptesModalOpened,
    ListTypeGestionnaireDeComptes,
    setDataIsUpdated,
    dataIsUpdated,
    archiveActiveTypeGestionnaireDeComptes,
    setListSelectedtypeGestionnaireDeComptes,
    selectedRows,
}: AlerteModaltype) {
    const {
        isAlerteTypeGestionnaireDeComptesModalOpened,
        selectedTypeGestionnaireDeComptes,
    } = useSnapshot(store);

    const uuidEntity = localStorage.getItem("Uid_Entitie_User");
    function saveHistoryChanges(oldData: any, newData: any) {
        fetch(`${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/HistoryChanges`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                oldData: oldData,
                newData: newData,
                uidUpdatedData: uuidEntity,
                typeHistory: "6",
            }),
        })
            .then((response) => {
                response.json().then((data) => {
                    return data.error;
                });
            })
            .catch((e: any) => {
                return false;
            });
    }
    function handlearchiveActiveTypeGestionnaireDeComptes() {
        if (ListTypeGestionnaireDeComptes.length > 1) {
            fetch(
                `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/archiveActiveTypeGestionnaireDeComptes?listuuidTypeGestionnaireDeComptes=${ListTypeGestionnaireDeComptes}&action=${archiveActiveTypeGestionnaireDeComptes}`,
                {
                    method: "PUT",
                    headers: { "Content-Type": "application/json" },
                }
            )
                .then((response) => {
                    response.json().then((data) => {
                        if (data === true) {
                            showToast(
                                true,
                                archiveActiveTypeGestionnaireDeComptes
                                    ? " Les types du gestionnaires de comptes sont archivés avec succès !"
                                    : " Les types du gestionnaires de comptes sont activés avec succès !"
                            );
                            setTimeout(() => {
                                setDataIsUpdated(!dataIsUpdated);
                            }, 2000);
                            selectedRows.forEach((element: any) => {
                                const old_module = {
                                    descriptif: element.Descriptif,
                                    designation: element.Designation,
                                    uuid: element.uuid,
                                    uuidEntity: uuidEntity,
                                    isArchived: !element.archived
                                        ? "Statut activé"
                                        : "Statut archivé",
                                };
                                const new_module = {
                                    descriptif: element.Descriptif,
                                    designation: element.Designation,
                                    uuid: element.uuid,
                                    uuidEntity: uuidEntity,
                                    isArchived: archiveActiveTypeGestionnaireDeComptes
                                        ? "Statut archivé"
                                        : "Statut activé",
                                };
                                saveHistoryChanges(old_module, new_module);
                            });
                            setListSelectedtypeGestionnaireDeComptes([]);
                        }
                    });
                })
                .catch((e: any) => {
                    console.log({ error: e.message });
                });
        } else {
            const old_module = {
                descriptif: selectedTypeGestionnaireDeComptes.Descriptif,
                designation: selectedTypeGestionnaireDeComptes.Designation,
                uuid: selectedTypeGestionnaireDeComptes.uuid,
                uuidEntity: uuidEntity,
                isArchived: !selectedTypeGestionnaireDeComptes.archived
                    ? "Statut activé"
                    : "Statut archivé",
            };
            const new_module = {
                descriptif: selectedTypeGestionnaireDeComptes.Descriptif,
                designation: selectedTypeGestionnaireDeComptes.Designation,
                uuid: selectedTypeGestionnaireDeComptes.uuid,
                uuidEntity: uuidEntity,
                isArchived: archiveActiveTypeGestionnaireDeComptes
                    ? "Statut archivé"
                    : "Statut activé",
            };
            fetch(
                `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/archiveActiveTypeGestionnaireDeComptes?listuuidTypeGestionnaireDeComptes=${selectedTypeGestionnaireDeComptes.uuid}&action=${archiveActiveTypeGestionnaireDeComptes}`,
                {
                    method: "PUT",
                    headers: { "Content-Type": "application/json" },
                }
            )
                .then((response) => {
                    response.json().then((data) => {
                        if (data === true) {
                            saveHistoryChanges(old_module, new_module);
                            showToast(
                                true,
                                archiveActiveTypeGestionnaireDeComptes
                                    ? " Le type du gestionnaire de compte a été archivé avec succès !"
                                    : " Le type du gestionnaire de compte a été activé avec succès !"
                            );
                            setTimeout(() => {
                                setDataIsUpdated(!dataIsUpdated);
                            }, 2000);
                        }
                    });
                })
                .catch((e: any) => {
                    console.log({ error: e.message });
                });
        }
    }

    return (
        <div>
            <StyledModal
                className=""
                centered
                isOpen={isAlerteTypeGestionnaireDeComptesModalOpened}
            >
                <ModalHeader className="d-flex align-items-center">
                    <div
                        className="d-flex align-items-center justify-content-between "
                        style={{
                            height: "20px",
                            width: "200px",
                            display: "grid",
                            gridTemplateColumns: "1.8fr 0.2fr",
                            gridTemplateRows: "1fr",
                            gap: "300px",
                        }}
                    >
                        <p className="title-text-style">CONFIRMATION</p>
                        <StyledIconButton
                            icon="CrossIcon"
                            onClick={() => {
                                setIsAlerteTypeGestionnaireDeComptesModalOpened(
                                    !isAlerteTypeGestionnaireDeComptesModalOpened
                                );
                                setListSelectedtypeGestionnaireDeComptes([]);
                            }}
                        >
                            <CrossIcon
                                cursor="pointer"
                                height={10}
                                width={10}
                            />
                        </StyledIconButton>
                    </div>
                </ModalHeader>

                <ModalBody>
                    <div
                        style={{
                            marginLeft: "10px",
                            marginTop: "20px",
                            marginBottom: "20px",
                        }}
                    >
                        <StyledLabel>
                            {archiveActiveTypeGestionnaireDeComptes
                                ? `Voulez-vous confirmez l'archivage ${
                                      ListTypeGestionnaireDeComptes.length > 1
                                          ? `des types gestionnaires de comptes sélectionnés`
                                          : `du type gestionnaire de comptes " ${selectedTypeGestionnaireDeComptes.Designation} "`
                                  } ?`
                                : `Voulez-vous confirmez l'activation ${
                                      ListTypeGestionnaireDeComptes.length > 1
                                          ? `des types gestionnaires de comptes sélectionnés`
                                          : `du type gestionnaire de comptes " ${selectedTypeGestionnaireDeComptes.Designation} "`
                                  } ?`}
                        </StyledLabel>
                    </div>
                </ModalBody>
                <ModalFooter
                    className="card-footer d-flex align-items-center justify-content-end"
                    style={{
                        background: "#FFFFFF",
                        height: "fit-content",
                    }}
                >
                    <StyledButton
                        disabled={false}
                        rounded
                        variant="light"
                        outline={true}
                        size="sm"
                        style={{
                            background: "white",
                            color: "black",
                            padding: "0px",
                            width: "150px",
                            height: "45px",
                        }}
                        onClick={() => {
                            setIsAlerteTypeGestionnaireDeComptesModalOpened(
                                !isAlerteTypeGestionnaireDeComptesModalOpened
                            );
                            setListSelectedtypeGestionnaireDeComptes([]);
                        }}
                    >
                        Annuler
                    </StyledButton>

                    <StyledButton
                        className="ml-2"
                        disabled={false}
                        rounded
                        variant="primary"
                        outline={false}
                        size="sm"
                        style={{
                            color: "white",
                            padding: "0px",
                            width: "150px",
                            height: "45px",
                        }}
                        onClick={() => {
                            handlearchiveActiveTypeGestionnaireDeComptes();
                            setIsAlerteTypeGestionnaireDeComptesModalOpened(
                                !isAlerteTypeGestionnaireDeComptesModalOpened
                            );
                        }}
                    >
                        Valider
                    </StyledButton>
                </ModalFooter>
            </StyledModal>
        </div>
    );
}
