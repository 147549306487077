import React from "react";
import { DynamicTable } from "@maherunlocker/custom-react-table";
import {
    DocumentIcon,
    PencilIcon,
    PlusIcon,
    StyledIconButton,
    TrashIcon,
    EyeIcon,
    DisassociateIcon,
    AssociateIcon,
    DisplayIcon,
    StyledButtonWithIcon,
    VerticalDotsIcon,
} from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";

import { AvForm } from "availity-reactstrap-validation";

import {
    setIsHitoryAllProducts,
    setSelectedProduct,
    store,
    setSelectedModule,
    setSelectedModuleBeforeUpdate,
    setIsAlerteModuleModalOpened,
    setIsAlerteDissociateModalOpened,
    setModif,
    setListRef,
} from "../store";
import "../index.css";
import ModulesActionsMenu from "./ModulesActionsMenu";
import AssociateProductsToModuleModal from "./AssociateProductsToModuleModal";
import { showToast } from "@components/Common/ToastShow";

interface customColumnProps {
    indexOFColumn: number;
    columnName: string;
    customJsx: Function;
}

type TableProps = {
    setIsHiddenCard: React.Dispatch<React.SetStateAction<boolean>>;
    isHiddenCard: boolean;
    urlTable: string;
    setIsEditAction: React.Dispatch<React.SetStateAction<boolean>>;
    setDataIsUpdated: React.Dispatch<React.SetStateAction<number | boolean>>;
    dataIsUpdated: number | boolean;
    setSelectedRows: React.Dispatch<React.SetStateAction<any>>;
    selectedRows: any;
    isHisotyModuleModalOpened?: boolean;
    setIsHisotyModuleModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
    setModuleState: React.Dispatch<React.SetStateAction<boolean>>;
    isModuleState: boolean;
    isAlertupdateModal: boolean;
    isEditAction: boolean;
    setIsAlertupdateModal: Function;
    setEdit: Function;
    setIsAssociateProductModalOpen: Function;
    isAssociateProductModalOpen: boolean;
};

function TableListModules(props: TableProps) {
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const {
        isAlerteModuleModalOpened,
        isAlerteDissociateModalOpened,
        selectedModule,
        modif,
        isVisible,
    } = useSnapshot(store);
    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 2,
            columnName: "Produit(s) Parent",
            customJsx: ModuleColmun,
        },
        {
            indexOFColumn: 5,
            columnName: "Actions",
            customJsx: ActionsColmunCompte,
        },
    ];

    function ActionsColmunCompte({ selectedRow, isHidden }: any) {
        const {
            modif,
            selectedProductsLicence,
        } = useSnapshot(store);
        let tableProductsRef: any = [];
        return (
            <div>
                <div className="w-100">
                    {selectedRow.isPrincipalProduct ? (
                        <div
                            className="row "
                            style={{
                                display: "grid",
                                gridTemplateColumns: "1fr 1fr auto",
                                gap: "20px",
                                marginLeft: "30%",
                            }}
                        >
                            <div
                                className="d-flex align-items-center "
                                style={{ cursor: "pointer" }}
                            ></div>
                            <div className="d-flex align-items-center ">
                                <div
                                    className="w-100 hovertext"
                                    data-hover={`Dissocier ce produit de ce module `}
                                    style={{
                                        cursor: "pointer",

                                        pointerEvents: !props.isModuleState
                                            ? "none"
                                            : "auto",
                                        opacity: !props.isModuleState
                                            ? "0.4"
                                            : "",
                                    }}
                                >
                                    <DisassociateIcon
                                        height={46}
                                        width={46}
                                        onClick={() => {
                                            if (
                                                selectedProductsLicence.includes(
                                                    selectedRow.Uuid +
                                                        "|" +
                                                        selectedRow.uuidParent
                                                )
                                            ) {
                                                showToast(
                                                    false,
                                                    `Attention, vous ne pouvez pas dissocier un produit d'un module ayant des licences actives.`
                                                );
                                            } else {
                                                if (modif === true) {
                                                    props.setIsAlertupdateModal(
                                                        true
                                                    );
                                                    props.setSelectedRows(
                                                        selectedRow
                                                    );
                                                    props.setEdit("dissociate");
                                                    setIsAlerteDissociateModalOpened(
                                                        !isAlerteDissociateModalOpened
                                                    );
                                                    setModif(false);
                                                } else {
                                                    props.setIsHiddenCard(
                                                        isHidden
                                                    );
                                                    setIsAlerteDissociateModalOpened(
                                                        !isAlerteDissociateModalOpened
                                                    );
                                                    setSelectedModule(
                                                        selectedRow
                                                    );
                                                }
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div
                            className="row "
                            style={{
                                display: "grid",
                                gridTemplateColumns: "1fr 1fr auto",
                                gap: "20px",
                                marginLeft: "30%",
                            }}
                        >
                            <div
                                className="d-flex align-items-center "
                                style={{ cursor: "pointer" }}
                            >
                                {!props.isModuleState ? (
                                    <DisplayIcon
                                        height={46}
                                        width={46}
                                        onClick={() => {
                                            props.setIsHiddenCard(!isHidden);
                                            setSelectedModule(selectedRow);
                                            setSelectedModuleBeforeUpdate(
                                                selectedRow
                                            );
                                            props.setIsEditAction(true);
                                            setLocalFilterActive(false);
                                        }}
                                    />
                                ) : (
                                    <PencilIcon
                                        height={25}
                                        width={25}
                                        id="EditBtton"
                                        onClick={() => {
                                            if (modif === true) {
                                                props.setIsAlertupdateModal(
                                                    true
                                                );
                                                props.setEdit("Edit");
                                                props.setIsEditAction(true);
                                                props.setSelectedRows(
                                                    selectedRow
                                                );
                                                setLocalFilterActive(false);
                                            } else {
                                                props.setIsHiddenCard(
                                                    !isHidden
                                                );
                                                setSelectedModule(selectedRow);
                                                setSelectedModuleBeforeUpdate(
                                                    selectedRow
                                                );
                                                props.setIsEditAction(true);
                                                setLocalFilterActive(false);
                                                props.setIsAssociateProductModalOpen(
                                                    props.isAssociateProductModalOpen
                                                );
                                            }
                                        }}
                                    />
                                )}
                            </div>
                            <div className="d-flex align-items-center ">
                                <div
                                    className="w-100 hovertext"
                                    data-hover={`Associer ce module à un/des produit(s) parent(s)`}
                                    style={{
                                        cursor: "pointer",

                                        pointerEvents: !props.isModuleState
                                            ? "none"
                                            : "auto",
                                        opacity: !props.isModuleState
                                            ? "0.4"
                                            : "",
                                    }}
                                >
                                    <AssociateIcon
                                        height={46}
                                        width={46}
                                        onClick={() => {
                                            // if (
                                            //     selectedOneModulelicense.includes(
                                            //         selectedRow.Uuid
                                            //     )
                                            // ) {
                                            //     setIsAffectedModule(true);
                                            // }
                                            Object.keys(
                                                selectedRow.subRows
                                            ).forEach((key) => {
                                                tableProductsRef.push(
                                                    selectedRow.subRows[key]
                                                        .Référence
                                                );
                                            });
                                            setListRef(tableProductsRef);

                                            if (modif === true) {
                                                props.setIsAlertupdateModal(
                                                    true
                                                );
                                                props.setSelectedRows(
                                                    selectedRow
                                                );
                                                props.setEdit("Associate");
                                                props.setIsAssociateProductModalOpen(
                                                    !props.isAssociateProductModalOpen
                                                );
                                                setModif(false);
                                            } else {
                                                props.setIsHiddenCard(isHidden);
                                                props.setIsAssociateProductModalOpen(
                                                    !props.isAssociateProductModalOpen
                                                );

                                                setSelectedModule(selectedRow);
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <AssociateProductsToModuleModal
                    isModalOpened={props.isAssociateProductModalOpen}
                    setIsModalOpened={props.setIsAssociateProductModalOpen}
                    setDataIsUpdated={props.setDataIsUpdated}
                    dataIsUpdated={props.dataIsUpdated}
                    selectedModule={selectedModule}
                />
            </div>
        );
    }
    function ModuleColmun({ selectedRow }: any) {
        let ParentProductNumber;

        if (typeof selectedRow.ProduitParent === "number") {
            if (selectedRow.ProduitParent < 10) {
                ParentProductNumber = "0" + selectedRow.ProduitParent;
            } else {
                ParentProductNumber = selectedRow.ProduitParent;
            }
        }
        return (
            <React.Fragment>
                {typeof selectedRow.ProduitParent === "number" ? (
                    <div
                        className=" hovertext"
                        data-hover={`Ce module appartient à ${ParentProductNumber} produit(s) parent(s)`}
                    >
                        {ParentProductNumber}
                    </div>
                ) : (
                    <div>{selectedRow.ProduitParent}</div>
                )}
            </React.Fragment>
        );
    }

    function SelectAccountDropdown({ selectedRow }: any) {
        const { selectedOneModulelicense } = useSnapshot(store);
        if (!selectedRow.original.isPrincipalProduct) {
            return (
                <div className="dropdown ml-2  d-none d-sm-block">
                    <VerticalDotsIcon
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        cursor="pointer"
                        height={25}
                        width={25}
                    />
                    <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                        style={{ marginLeft: "-150px" }}
                    >
                        {props.isModuleState ? (
                            <div
                                className="dropdown-item"
                                style={{
                                    fontWeight: "bold",
                                    alignItems: "center",
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    if (
                                        selectedOneModulelicense.includes(
                                            selectedRow.original.Uuid
                                        )
                                    ) {
                                        showToast(
                                            false,
                                            `Attention, vous ne pouvez pas archiver un module ayant des licences actives.`
                                        );
                                    } else {
                                        if (
                                            selectedRow.original.ProduitParent >
                                            0
                                        ) {
                                            showToast(
                                                false,
                                                `Vous ne pouvez pas archiver un module associé à un ou plusieurs produits / packs`
                                            );
                                        } else {
                                            setIsAlerteModuleModalOpened(
                                                !isAlerteModuleModalOpened
                                            );
                                            setSelectedModule(
                                                selectedRow.original
                                            );
                                            props.setModuleState(true);
                                        }
                                    }
                                }}
                            >
                                <TrashIcon
                                    height={25}
                                    width={25}
                                    className="mr-2"
                                    style={{ fill: "red" }}
                                />
                                Archiver module
                            </div>
                        ) : (
                            <div
                                className="dropdown-item"
                                style={{
                                    fontWeight: "bold",
                                    alignItems: "center",
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    setSelectedModule(selectedRow.original);
                                    props.setModuleState(false);
                                    setIsAlerteModuleModalOpened(
                                        !isAlerteModuleModalOpened
                                    );
                                }}
                            >
                                <EyeIcon
                                    height={25}
                                    width={25}
                                    className="mr-2"
                                />
                                Activer module
                            </div>
                        )}
                        <div
                            className="dropdown-item"
                            style={{
                                fontWeight: "bold",
                                alignItems: "center",
                                cursor: "pointer",
                            }}
                            onClick={() => {
                                setSelectedProduct(selectedRow.original);
                                setIsHitoryAllProducts(false);
                                props.setIsHisotyModuleModalOpened(
                                    !props.isHisotyModuleModalOpened
                                );
                            }}
                        >
                            <DocumentIcon
                                height={25}
                                width={25}
                                className="mr-2"
                            />
                            Historique
                        </div>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }

    React.useEffect(() => {
        if (filterActive) {
            props.setIsHiddenCard(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterActive]);

    return (
        <div>
            <DynamicTable
                url={props.urlTable}
                minHeight={`calc(100vh - (500px + ${
                    isVisible ? "-71px" : "0px"
                }))`}
                maxHeight={`calc(100vh - (500px + ${
                    !isVisible ? "100px" : "0px"
                }))`}
                actionColumn={SelectAccountDropdown}
                showFilter
                canResize
                customJsxSideFilterButton={
                    props.isHiddenCard === true || filterActive === true ? (
                        <StyledIconButton
                            icon="PlusIcon"
                            disabled={filterActive ? true : false}
                            style={{
                                background: "#323333",
                                border: "none",
                                borderColor: "#323333",
                                borderRadius: "6px",
                                display: "flex",
                                padding: "12px 10px",
                            }}
                        >
                            <PlusIcon
                                height={20}
                                width={20}
                                style={{ fill: "#fff" }}
                            />
                        </StyledIconButton>
                    ) : (
                        <div className="d-flex align-items-center mt-2 mx-2 mb-2">
                            <AvForm
                                onSubmit={() => {
                                    setLocalFilterActive(false);
                                    props.setIsEditAction(false);
                                    setSelectedModule("");
                                    props.setIsHiddenCard(!props.isHiddenCard);
                                }}
                            >
                                <StyledButtonWithIcon
                                    disabled={
                                        props.isModuleState ? false : true
                                    }
                                    icon="PlusIcon"
                                    iconPosition="left"
                                    rounded
                                    variant="primary"
                                >
                                    Ajouter un module
                                </StyledButtonWithIcon>
                            </AvForm>

                            <ModulesActionsMenu
                                setIsHisotyModuleModalOpened={
                                    props.setIsHisotyModuleModalOpened !==
                                    undefined
                                        ? props.setIsHisotyModuleModalOpened
                                        : () => {}
                                }
                                isHisotyModuleModalOpened={
                                    props.isHisotyModuleModalOpened
                                }
                                setDataIsUpdated={
                                    props.setDataIsUpdated !== undefined
                                        ? props.setDataIsUpdated
                                        : () => {}
                                }
                                dataIsUpdated={props.dataIsUpdated}
                                selectedRows={props.selectedRows}
                                setModuleState={props.setModuleState}
                                isModuleState={props.isModuleState}
                            />
                        </div>
                    )
                }
                onClick={() => {
                    if (modif === true) {
                        props.setIsAlertupdateModal(true);
                    }
                }}
                canMovedCheckboxLeftOnExpand
                canSelect
                customSelect
                canExpand
                setSelectedRows={props.setSelectedRows}
                showGlobalFilter
                filterActive={filterActive}
                setLocalFilterActive={setLocalFilterActive}
                arrayOfCustomColumns={arrayOfCustomColumns}
                setDataIsUpdated={props.setDataIsUpdated}
                dataIsUpdated={props.dataIsUpdated}
            />
        </div>
    );
}

export default TableListModules;
