import React from "react";
import {
    StyledModal,
    StyledButton,
    StyledLabel,
    StyledIconButton,
    CrossIcon,
} from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { store } from "../store";
// import TableProfilHistory from "./TableProfilHistory";
import TablePackHistory from "../../pages/Packs/TablePackHistory";
import "../index.css";

type ListHistoryPackModalType = {
    isModalOpened: boolean;
    setIsModalOpened: Function;
    selectedRows: any;
};

export function HistoryPackModal({
    isModalOpened,
    setIsModalOpened,
    selectedRows,
}: ListHistoryPackModalType) {
    const { t } = useTranslation();
    const { SelectedPack, AllPackHistory, refHistoryPack } = useSnapshot(store);
    const colorBreadcrumb = "#E5E4E4AB";

    let url = "";
    if (AllPackHistory) {
        url = `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/HistoryOnePack?reference=${refHistoryPack}`;
    } else {
        url = `${
            process.env.REACT_APP_CONSOLE_ADMIN_ACCESS
        }/HistoryOnePack?reference=${
            SelectedPack.Référence === undefined ? "" : SelectedPack.Référence
        }`;
    }
    return (
        <React.Fragment>
            <StyledModal className="history-modal-style" isOpen={isModalOpened}>
                <ModalHeader className="d-flex align-items-center">
                    <div
                        className="d-flex align-items-center justify-content-between"
                        style={{
                            height: "35px",
                            display: "grid",
                            gridTemplateColumns: "1.8fr 0.2fr",
                            gridTemplateRows: "1fr",
                            gap: "1210px",
                        }}
                    >
                        <p className="title-modal-style">
                            {t("HISTORIQUE PACK(S)")}
                        </p>
                        <StyledIconButton icon="CrossIcon">
                            <CrossIcon
                                cursor="pointer"
                                height={10}
                                width={10}
                                onClick={() => {
                                    setIsModalOpened(!isModalOpened);
                                }}
                            />
                        </StyledIconButton>
                    </div>
                </ModalHeader>
                <ModalBody>
                    {AllPackHistory ? (
                        <></>
                    ) : (
                        <div
                            style={{
                                display: "grid",
                                gridTemplateColumns: "1fr 1fr 1fr 1fr auto",
                                gap: "20px",
                                background: colorBreadcrumb,
                                height: "56px",
                                marginBottom: "5px",
                                alignItems: "center",
                            }}
                        >
                            <StyledLabel
                                style={{
                                    marginLeft: "24px",
                                    fontWeight: "bold",
                                    fontSize: "20px",
                                }}
                            >
                                Pack : {SelectedPack.Pack}
                            </StyledLabel>
                            <StyledLabel
                                style={{
                                    marginLeft: "24px",
                                    fontWeight: "bold",
                                    fontSize: "20px",
                                }}
                            >
                                Référence : {SelectedPack.Référence}
                            </StyledLabel>
                        </div>
                    )}
                    <TablePackHistory url={url} />
                </ModalBody>

                <ModalFooter className="d-flex justify-content-end">
                    <div className="d-flex align-items-center">
                        <div className="ml-3">
                            <StyledButton
                                disabled={false}
                                rounded
                                variant="light"
                                outline={true}
                                size="sm"
                                style={{
                                    background: "black",
                                    color: "white",
                                    padding: "0px",
                                    width: "150px",
                                    height: "45px",
                                }}
                                onClick={() => setIsModalOpened(!isModalOpened)}
                            >
                                {t("Close")}
                            </StyledButton>
                        </div>
                    </div>
                </ModalFooter>
            </StyledModal>
        </React.Fragment>
    );
}
