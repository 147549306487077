import React, { useState } from "react";
import {
    Card,
    CardBody,
    Col,
    CardFooter,
    Container,
    Row,
    CardHeader,
} from "reactstrap";
import {
    CrossIcon,
    RefreshIcon,
    StyledButton,
    StyledLabel,
    StyledTextInput,
} from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { AvForm } from "availity-reactstrap-validation";

import {
    store,
    setSelectedPack,
    setIsAlertePackModalOpened,
    setSelectedProductsLicence,
    setIsActiveLicenseAlertPackOpen,
} from "../store";
import Breadcurmb from "@components/Common/Breadcrumb/index";
import MessageInfo from "@components/Common/MessageInfo";
import { showToast } from "@components/Common/ToastShow";
import TablePack from "@components/Table/TablePack";
import { SelectProductsPacksModal } from "./SelectProductsPacksModal";
import AlertePackModal from "../../pages/Packs/AlertePackModal";
import PageTitle from "@components/Common/PageTitle";
import ActiveLicenseAlertPack from "./ActiveLicenseAlertPack";
import { HistoryPackModal } from "@pages/History/HistoryPackModal";

export default function Packs() {
    const { t } = useTranslation();
    const uidEntity = localStorage.getItem("Uid_Entitie_User");
    const uidLoggedEntity = localStorage.getItem("Uid_Entitie_User");
    const [isHidden, setIsHidden] = useState(false);
    const [updateAction, setUpdateAction] = useState(false);
    const [dataIsUpdated, setDataIsUpdated] = React.useState<number | boolean>(
        false
    );
    const [designation, setDesignation] = useState("");
    const [reference, setReference] = useState("");
    const [commentaire, setCommentaire] = useState("");
    const [
        isProductsPackModalIsOppen,
        setIsProductsPackModalIsOppen,
    ] = useState<boolean>(false);
    const [newPack, setNewPack] = useState<any>();
    const [isPackState, setIsPackState] = React.useState<boolean>(true);
    const [isEmptyDes, setIsEmptyDes] = React.useState<boolean>(false);
    const [isEmptyRef, setIsEmptyRef] = React.useState<boolean>(false);
    const [referencesPackList, setReferencesPackList] = React.useState<any[]>(
        []
    );
    const [inputValue, setInputValue] = React.useState<boolean>(false);
    const [counter, setCounter] = React.useState<number>(2000);
    const [isReset, setIsReset] = React.useState<boolean>(false);
    const [isResetDes, setIsResetDes] = React.useState<boolean>(false);
    const [selectedRows, setSelectedRows] = useState<any>();
    const [isHistoryPackModelOpen, setIsHistoryPackModelOpen] = useState<
        boolean
    >(false);
    const {
        SelectedPack,
        SelectedPackBeforeUpdate,
        isAlertePackModalOpened,
        isVisible,
        selectedProductsLicence,
        isActiveLicenseAlertPackOpen,
        functionAssociatePack,
    } = useSnapshot(store);

    let titleBreadcurmb = "";
    if (isPackState) {
        titleBreadcurmb = t("CONFIGURATION / PACK(S) ACTIVÉ(S)");
    } else {
        titleBreadcurmb = t("CONFIGURATION / PACK(S) ARCHIVÉ(S)");
    }

    function saveHistoryChanges(oldData: any, newData: any, type: any) {
        fetch(`${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/HistoryChanges`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                oldData: oldData,
                newData: newData,
                uidUpdatedData: uidEntity,
                typeHistory: type,
            }),
        })
            .then((response) => {
                response.json().then((data) => {
                    setTimeout(function () {}, 2000);
                    return data.error;
                });
            })
            .catch((e: any) => {
                return false;
            });
    }

    function updatePack() {
        if (SelectedPack.Pack === "") {
            showToast(false, `Le champ 'Désignation' doit être rempli`);
            setIsEmptyDes(true);
        } else if (SelectedPack.Référence === "") {
            showToast(false, `Le champ 'Référence' doit être rempli`);
            setIsEmptyRef(true);
        } else {
            if (isReset === true || isResetDes === true) {
                setIsHidden(!isHidden);
            } else {
                if (
                    SelectedPack.Pack === SelectedPackBeforeUpdate.Pack &&
                    SelectedPack.Descriptif ===
                        SelectedPackBeforeUpdate.Descriptif
                ) {
                    setIsHidden(!isHidden);
                } else {
                    let newData = {
                        designation: SelectedPack.Pack,
                        reference: SelectedPack.Référence,
                        commentaire: SelectedPack.Descriptif,
                    };
                    let oldData = {
                        designation: SelectedPackBeforeUpdate.Pack,
                        reference: SelectedPackBeforeUpdate.Référence,
                        commentaire: SelectedPackBeforeUpdate.Descriptif,
                    };
                    fetch(
                        `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/updatePack`,
                        {
                            method: "PUT",
                            headers: { "Content-Type": "application/json" },
                            body: JSON.stringify({
                                uidPack: SelectedPack.uid,
                                designationPack: SelectedPack.Pack,
                                referencePack: SelectedPack.Référence,
                                commentairePack: SelectedPack.Descriptif,
                            }),
                        }
                    )
                        .then((response) => {
                            if (
                                response.status === 200 &&
                                response.ok === true
                            ) {
                                setIsHidden(!isHidden);
                                setDataIsUpdated(!dataIsUpdated);
                                saveHistoryChanges(oldData, newData, 5);
                                setIsEmptyDes(false);
                                setIsEmptyRef(false);
                                setInputValue(false);
                                showToast(
                                    true,
                                    `Modification Pack effectuée avec succès!`
                                );
                            } else if (response.status === 500) {
                                showToast(
                                    false,
                                    `Ouups! erreur lors de la modification du pack, veuillez réessayer SVP`
                                );
                            }
                        })
                        .catch((e: any) => {
                            console.log({ error: e.message });
                        });
                }
            }
        }
    }

    const handleValidateBtnClick = () => {
        if (updateAction) {
            if (
                selectedProductsLicence.includes(SelectedPackBeforeUpdate.uid)
            ) {
                setIsActiveLicenseAlertPackOpen(!isActiveLicenseAlertPackOpen);
            } else {
                updatePack();
            }
        } else {
            if (designation === "") {
                showToast(false, `Le champ 'Désignation' doit être rempli`);
                setIsEmptyDes(true);
            } else if (reference === "") {
                showToast(false, `Le champ 'Référence' doit être rempli`);
                setIsEmptyRef(true);
            } else {
                if (referencesPackList.includes(reference)) {
                    showToast(
                        false,
                        "Le réference existe déjà. Veuillez saisir un autre"
                    );
                } else {
                    setNewPack({
                        pack: designation,
                        reference: reference,
                        isArchived: false,
                        commentaire: commentaire,
                        uidEntity: uidLoggedEntity,
                        products: [],
                    });
                    setInputValue(false);
                    setIsEmptyDes(false);
                    setIsEmptyRef(false);
                    setIsProductsPackModalIsOppen(!isProductsPackModalIsOppen);
                }
            }
        }
    };

    React.useEffect(() => {
        fetch(
            `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/referencesPackList?uidEntity=${uidEntity}`
        )
            .then((response) => {
                response.json().then((data) => {
                    setReferencesPackList(data);
                });
            })
            .catch((e: any) => {
                console.log({ error: e.message });
                return referencesPackList;
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    React.useEffect(() => {
        fetch(
            `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/isAffectedProductAccount`,
            {
                method: "get",
                headers: { "Content-Type": "application/json" },
            }
        )
            .then((response) => {
                response.json().then((data) => {
                    let array: any = [];
                    data.forEach((el: any) => {
                        const elementUuid = el.split("|");
                        array.push(elementUuid[0]);
                    });
                    setSelectedProductsLicence(array);
                });
            })
            .catch((e: any) => {
                console.log({ error: e.message });
                return selectedProductsLicence;
            });
        // eslint-disable-next-line
    }, [dataIsUpdated]);

    return (
        <React.Fragment>
            <PageTitle title="PACKS" />
            <div className="">
                <Container fluid style={{ marginTop: "-15px" }}>
                    <Row>
                        <Col>
                            <Breadcurmb
                                title={titleBreadcurmb}
                                breadcrumbBackgroundColor={""}
                            />
                        </Col>
                        <MessageInfo
                            message={t(
                                "Le pack sert à grouper différents produits et modules"
                            )}
                        />
                    </Row>
                    <CardBody
                        style={{
                            padding: "1px",
                        }}
                    >
                        <div
                            className="row "
                            style={{
                                display: "grid",
                                gridTemplateColumns: isHidden
                                    ? "2fr 1fr"
                                    : "auto",
                                gap: "4px",
                            }}
                        >
                            <TablePack
                                URL={`${
                                    process.env.REACT_APP_CONSOLE_ADMIN_ACCESS
                                }/packs?uid=${uidEntity}&isArchivedPacks=${!isPackState}`}
                                setIsHidden={setIsHidden}
                                isHidden={isHidden}
                                setUpdateAction={setUpdateAction}
                                setDataIsUpdated={setDataIsUpdated}
                                dataIsUpdated={dataIsUpdated}
                                setDesignation={setDesignation}
                                setReference={setReference}
                                setCommentaire={setCommentaire}
                                setIsPackState={setIsPackState}
                                isPackState={isPackState}
                                selectedRows={selectedRows}
                                setSelectedRows={setSelectedRows}
                                setIsHistoryPackModelOpen={
                                    setIsHistoryPackModelOpen
                                }
                                isHistoryPackModelOpen={isHistoryPackModelOpen}
                            ></TablePack>

                            {!isHidden ? null : (
                                <Card className="border ml-2">
                                    <CardHeader
                                        className="Headercard  d-flex justify-content-between pb-2"
                                        style={{
                                            height: "11.7%",
                                            backgroundColor: "white",
                                            border:
                                                "1px solid rgba(0,0,0,.125)",
                                        }}
                                    >
                                        <div className=" d-flex align-items-center justify-content-center">
                                            <h4
                                                className="m-0 pl-2"
                                                style={{
                                                    font:
                                                        "normal normal 600 20px/27px Segoe UI",
                                                }}
                                            >
                                                {updateAction
                                                    ? !isPackState
                                                        ? "Consulter le pack"
                                                        : "Modifier le pack"
                                                    : "Ajouter un pack"}
                                            </h4>
                                        </div>

                                        <div className="d-flex align-items-center ">
                                            {!isPackState ? (
                                                <RefreshIcon
                                                    cursor="pointer"
                                                    height={20}
                                                    width={20}
                                                    onClick={() => {
                                                        if (updateAction) {
                                                            setIsReset(true);
                                                            setIsResetDes(true);
                                                        } else {
                                                            setDesignation("");
                                                            setCommentaire("");
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                <></>
                                            )}

                                            <div className=" bd-highlight align-items-center ml-4">
                                                <CrossIcon
                                                    cursor="pointer"
                                                    height={17}
                                                    width={17}
                                                    onClick={() => {
                                                        setIsHidden(!isHidden);
                                                        setIsEmptyDes(false);
                                                        setIsEmptyRef(false);
                                                        setInputValue(false);
                                                        setIsReset(false);
                                                        setIsResetDes(false);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </CardHeader>
                                    <CardBody
                                        style={{
                                            background: "#FFFFFF",
                                            border:
                                                "1px solid rgba(0,0,0,.125)",
                                            marginTop: "4px",
                                            overflow: "auto",
                                            maxHeight: `calc(100vh - (500px + ${
                                                isVisible ? "-71px" : "0px"
                                            }))`,
                                        }}
                                    >
                                        <AvForm>
                                            <StyledLabel>
                                                Désignation*
                                            </StyledLabel>
                                            <StyledTextInput
                                                style={{
                                                    pointerEvents: !isPackState
                                                        ? "none"
                                                        : "auto",
                                                    opacity: !isPackState
                                                        ? "0.8"
                                                        : "",
                                                    border: isEmptyDes
                                                        ? "1px solid red"
                                                        : "1px solid #ced4da",
                                                }}
                                                className="form-control"
                                                id="inputProfilDesignation"
                                                name="ProfilDesignation"
                                                placeholder="Saisir"
                                                type="text"
                                                autoFocus={
                                                    !isPackState ? false : true
                                                }
                                                onChange={(e: any) => {
                                                    setDesignation(
                                                        e.target.value
                                                    );
                                                    setIsResetDes(false);
                                                    setIsEmptyDes(false);
                                                    setIsEmptyRef(false);
                                                    if (SelectedPack !== "") {
                                                        setSelectedPack({
                                                            ...SelectedPack,
                                                            Pack:
                                                                e?.target.value,
                                                        });
                                                    }
                                                }}
                                                value={
                                                    SelectedPack === ""
                                                        ? designation
                                                        : isResetDes
                                                        ? SelectedPackBeforeUpdate?.Pack
                                                        : SelectedPack?.Pack
                                                }
                                            />
                                            {isEmptyDes ? (
                                                <div style={{ color: "red" }}>
                                                    Ce champ est obligatoire
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </AvForm>
                                        <AvForm>
                                            <StyledLabel>
                                                Référence*
                                            </StyledLabel>
                                            <StyledTextInput
                                                style={{
                                                    pointerEvents: updateAction
                                                        ? "none"
                                                        : "auto",
                                                    opacity: updateAction
                                                        ? "0.8"
                                                        : "",
                                                    border:
                                                        isEmptyDes === false &&
                                                        isEmptyRef
                                                            ? "1px solid red"
                                                            : "1px solid #ced4da",
                                                }}
                                                className="form-control"
                                                id="inputPackReference"
                                                name="PackReference"
                                                placeholder="Saisir"
                                                type="text"
                                                onChange={(e: any) => {
                                                    setReference(
                                                        e.target.value.trim()
                                                    );
                                                    setIsEmptyRef(false);
                                                    if (SelectedPack !== "") {
                                                        setSelectedPack({
                                                            ...SelectedPack,
                                                            Référence:
                                                                e?.target.value,
                                                        });
                                                    }
                                                }}
                                                value={
                                                    SelectedPack === ""
                                                        ? reference
                                                        : SelectedPack?.Référence
                                                }
                                            />
                                            {isEmptyDes === false &&
                                            isEmptyRef ? (
                                                <div style={{ color: "red" }}>
                                                    Ce champ est obligatoire
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </AvForm>
                                        <AvForm>
                                            <StyledLabel>
                                                Descriptif
                                            </StyledLabel>
                                            <StyledTextInput
                                                style={{
                                                    pointerEvents: !isPackState
                                                        ? "none"
                                                        : "auto",
                                                    opacity: !isPackState
                                                        ? "0.8"
                                                        : "",
                                                }}
                                                className="form-control"
                                                id="inputPackCommentaire"
                                                name="PackCommentaire"
                                                placeholder="Saisir"
                                                onChange={(e: any) => {
                                                    setCommentaire(
                                                        e.target.value
                                                    );
                                                    setCounter(
                                                        2000 -
                                                            e.target.value
                                                                .length
                                                    );
                                                    setIsReset(false);
                                                    setInputValue(true);
                                                    if (SelectedPack !== "") {
                                                        setSelectedPack({
                                                            ...SelectedPack,
                                                            Descriptif:
                                                                e?.target.value,
                                                        });
                                                    }
                                                }}
                                                value={
                                                    SelectedPack === ""
                                                        ? commentaire
                                                        : isReset
                                                        ? SelectedPackBeforeUpdate?.Descriptif
                                                        : SelectedPack?.Descriptif
                                                }
                                                maxlength={2000}
                                                type="textarea"
                                            />
                                            <div className="d-flex justify-content-end ">
                                                {updateAction ? (
                                                    isReset ? (
                                                        <small>
                                                            Nombre de caractère
                                                            maximal :
                                                            {2000 -
                                                                SelectedPackBeforeUpdate
                                                                    .Descriptif
                                                                    .length}
                                                            /2000
                                                        </small>
                                                    ) : SelectedPack.Descriptif
                                                          .length === 0 ? (
                                                        <small>
                                                            Nombre de caractère
                                                            maximal : 2000
                                                        </small>
                                                    ) : (
                                                        <small>
                                                            Nombre de caractère
                                                            maximal :
                                                            {2000 -
                                                                SelectedPack
                                                                    .Descriptif
                                                                    .length}
                                                            /2000
                                                        </small>
                                                    )
                                                ) : inputValue ? (
                                                    <small>
                                                        Nombre de caractère
                                                        maximal :{counter}
                                                        /2000
                                                    </small>
                                                ) : (
                                                    <small>
                                                        Nombre de caractère
                                                        maximal : 2000
                                                    </small>
                                                )}
                                            </div>
                                        </AvForm>
                                    </CardBody>
                                    <CardFooter
                                        className="Footercard p-0"
                                        style={{
                                            background: "#FFFFFF",
                                        }}
                                    >
                                        <div className=" d-flex flex-wrap d-flex flex-row-reverse bd-highlight ">
                                            <div className="p-2 bd-highlight">
                                                <StyledButton
                                                    rounded
                                                    variant="primary"
                                                    disabled={
                                                        updateAction &&
                                                        !isPackState
                                                            ? true
                                                            : false
                                                    }
                                                    onClick={() => {
                                                        handleValidateBtnClick();
                                                    }}
                                                >
                                                    {t("Valider")}
                                                </StyledButton>
                                            </div>
                                            <div className="p-2 bd-highlight ">
                                                <StyledButton
                                                    rounded
                                                    outline
                                                    variant="light"
                                                    onClick={() => {
                                                        setSelectedPack("");
                                                        setIsHidden(!isHidden);
                                                        setIsEmptyDes(false);
                                                        setIsEmptyRef(false);
                                                        setInputValue(false);
                                                        setIsReset(false);
                                                        setIsResetDes(false);
                                                    }}
                                                >
                                                    {t("Cancel")}
                                                </StyledButton>
                                            </div>
                                        </div>
                                    </CardFooter>

                                    <SelectProductsPacksModal
                                        isModalOpened={
                                            isProductsPackModalIsOppen
                                        }
                                        setIsModalOpened={
                                            setIsProductsPackModalIsOppen
                                        }
                                        newPack={newPack}
                                        setDataIsUpdated={setDataIsUpdated}
                                        dataIsUpdated={dataIsUpdated}
                                        setIsHidden={setIsHidden}
                                    />
                                </Card>
                            )}
                        </div>
                        <AlertePackModal
                            isAlertePackModalOpened={isAlertePackModalOpened}
                            setIsAlertePackModalOpened={
                                setIsAlertePackModalOpened
                            }
                            setDataIsUpdated={setDataIsUpdated}
                            dataIsUpdated={dataIsUpdated}
                        />
                    </CardBody>
                </Container>
            </div>

            <HistoryPackModal
                isModalOpened={isHistoryPackModelOpen}
                setIsModalOpened={setIsHistoryPackModelOpen}
                selectedRows={selectedRows}
            />
            <ActiveLicenseAlertPack
                isEditAction={updateAction}
                setIsHiddenCard={setIsHidden}
                isHiddenCard={isHidden}
                setIsEmptyDes={setIsEmptyDes}
                setDataIsUpdated={setDataIsUpdated}
                dataIsUpdated={dataIsUpdated}
                functionAssociatePack={functionAssociatePack}
            />
        </React.Fragment>
    );
}
