import React from "react";
import { CheckboxIcon } from "@aureskonnect/react-ui";
import "./index.css";
import { toast } from "react-toastify";

function errorToast(message: string): void {
    toast.error(message, {
        className: "responsive-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 10000,
        theme: "colored",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
    });
}

function SuccessToast(message: string): JSX.Element {
    return (
        <>
            {toast.success(message, {
                className: "responsive-toast",
                position: toast.POSITION.TOP_CENTER,
                autoClose: 10000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                icon: <CheckboxIcon height={25} width={25} fill="white" />,
            })}
        </>
    );
}

export function showToast(success: Boolean, message: string) {
    // success
    //     ? toast.success(Message, {
    //           className: "responsive-toast",

    //           position: toast.POSITION.TOP_CENTER,

    //           theme: "colored",

    //           icon: <CheckboxIcon height={25} width={25} fill="white" />,
    //       })
    //     : toast.error(Message, {
    //           className: "responsive-toast",

    //           position: toast.POSITION.TOP_CENTER,

    //           theme: "colored",

    //           icon: <CrossIcon height={25} width={25} fill="white" />,
    //       });

    if (success) {
        SuccessToast(message);
    } else {
        errorToast(message);
    }
}
