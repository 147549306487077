import {
    HeaderWrapper,
    MenuBurgerIcon,
    StyledIconButton,
    StyledH1,
} from "@aureskonnect/react-ui";

import LanguageDropdown from "@components/CommonForBoth/TopbarDropdown/LanguageDropdown";
import { Tooltip } from "@mui/material";
import React from "react";
import { setCollapsed, store } from "./store";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import { Dropdown, DropdownToggle } from "reactstrap";
import HomeSvgIcon from "@components/Common/SvgIcons/HomeSvgIcon";

import ProfileMenu from "@components/CommonForBoth/TopbarDropdown/ProfileMenu";

type HeaderPropsType = {
    handleSideBarCollapse: any;
    brand: string;
    showBrand: boolean;
    // eslint-disable-next-line react/require-default-props
    customCss?: React.CSSProperties;
};
type BrandPropsType = {
    brand: string;
    // redirectionLink: string;
};
function Brand({ brand }: BrandPropsType) {
    return (
        <div
            // onClick={() => navigate(redirectionLink)}
            style={{
                cursor: "pointer",
            }}
            role="button"
            tabIndex={0}
        >
            {/* <div className="navbar-brand-box d-none d-sm-flex">
                <StyledH1 className="text-white d-flex justify-content-center brand__clz mb-0 p-2">
                    {brand}
                </StyledH1>
            </div> */}

            <div className="navbar-brand-box d-block d-sm-none">
                <StyledH1 className="text-white d-flex justify-content-center brand__clz mb-0 p-2">
                    {brand.slice(0, 2)}
                </StyledH1>
            </div>
        </div>
    );
}

// function logout() {
//     const redirectUriLogout = `/session/end?post_logout_redirect_uri=${process.env.REACT_APP_REDIRECT_URL_LOGOUT}&client_id=${process.env.REACT_APP_CLIENT_ID}`;
//     cache.clear();
//     localStorage.clear();
//     window.open(process.env.REACT_APP_OPSERVER_URL + redirectUriLogout);
// }

export default function Header({
    handleSideBarCollapse,
    brand,
    showBrand,
}: HeaderPropsType): JSX.Element {
    const { t } = useTranslation();
    const [menu, setMenu] = React.useState<boolean>(false);
    const [isMobile, setIsMobile] = React.useState<boolean>(false);

    const { collapsed, isMobileBurgerButtonClicked, oneShop } = useSnapshot(
        store
    );

    React.useEffect(() => {
        if (window.innerWidth < 767) {
            setIsMobile(true);
        }

        const handleResize = () => {
            if (window.innerWidth < 767) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    React.useEffect(() => {
        if (isMobile === true) {
            setCollapsed(true);
        }
    }, [isMobile, collapsed, isMobileBurgerButtonClicked]);
    React.useEffect(() => {}, [oneShop]);

    return (
        <HeaderWrapper
            className="header_wrapper__clz"
            style={{
                gridArea: "header",
                backgroundColor: "#ffffff",
            }}
        >
            <div className="navbar-header">
                <div className="d-flex align-items-center">
                    <Brand
                        brand={brand}
                        // redirectionLink="/Gestioncomptes/Comptesclients"
                    />

                    <StyledIconButton
                        className="m-0 border-none__clz"
                        icon="CloudIcon"
                    >
                        <MenuBurgerIcon
                            height={20}
                            width={20}
                            onClick={handleSideBarCollapse}
                        />
                    </StyledIconButton>
                </div>
                <div className="d-flex align-items-center">
                    <LanguageDropdown />

                    <Tooltip
                        title={(oneShop
                            ? t("Single store display")
                            : t("Multi-store display")
                        ).toString()}
                    >
                        <div>
                            <StyledIconButton
                                className="m-2 border-none__clz"
                                icon="ShopIcon"
                                onClick={(e: any) => {
                                    window.location.replace(
                                        `${process.env.REACT_APP_URL_HOME}`
                                    );
                                }}
                            >
                                <HomeSvgIcon
                                    onClick={(e: any) => {
                                        window.location.replace(
                                            `${process.env.REACT_APP_URL_HOME}`
                                        );
                                    }}
                                    height={25}
                                    width={25}
                                />
                            </StyledIconButton>
                        </div>
                    </Tooltip>

                    <Dropdown
                        isOpen={menu}
                        toggle={() => setMenu(!menu)}
                        className="d-inline-block"
                    >
                        <DropdownToggle
                            className="btn header-item waves-effect"
                            id="page-header-user-dropdown"
                            tag="button"
                        >
                            <ProfileMenu />
                            {/* <Avatar
                                className="mr-0"
                                name="John Doe"
                                round
                                size="55"
                            /> */}
                        </DropdownToggle>
                    </Dropdown>
                </div>
            </div>
        </HeaderWrapper>
    );
}
