import React, { useEffect } from "react";
import { ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import Select from "react-select";
import { useSnapshot } from "valtio";
import { store, setSelectedAccount } from "../store";
import {
    StyledSelectInput,
    StyledButton,
    StyledModal,
    CrossIcon,
    StyledIconButton,
} from "@aureskonnect/react-ui";

type AdvancedInformationType = {
    isAdvancedInformationModalOpened: boolean;
    setIsAdvancedInformationModalOpened: React.Dispatch<
        React.SetStateAction<boolean>
    >;
    schemaFranchise: any;
    uuidFranchise: any;
    uuidStore: any;
    idStore: any;
    setSchemaFranchise: React.Dispatch<React.SetStateAction<any>>;
    setUuidFranchise: React.Dispatch<React.SetStateAction<any>>;
    setUuidStore: React.Dispatch<React.SetStateAction<any>>;
    setIdStore: React.Dispatch<React.SetStateAction<any>>;
    ListeSchemaFranchise: any;
    setFranchiseName: any;
    setStoreName: any;
    typeAccount: any;
    franchiseName: any;
    StoreName: any;
    filtreTableAccount: any;
};

export function AdvancedInformationModal({
    isAdvancedInformationModalOpened,
    setIsAdvancedInformationModalOpened,
    schemaFranchise,
    uuidFranchise,
    uuidStore,
    idStore,
    setSchemaFranchise,
    setUuidFranchise,
    setUuidStore,
    setIdStore,
    ListeSchemaFranchise,
    setFranchiseName,
    setStoreName,
    typeAccount,
    franchiseName,
    StoreName,
    filtreTableAccount,
}: AdvancedInformationType) {
    const [uidStoreList, setUidStoreList] = React.useState<any>([]);
    //  { label: "Boutique 1", value: "boutique01", id: 2 },{ label: "Boutique 2", value: "boutique02", id: 3 }
    const { SelectedAccount } = useSnapshot(store);

    async function handleAddAdvancedInformation() {
        if (SelectedAccount !== "") {
            setSelectedAccount({
                ...SelectedAccount,
                SchemaFranchise:
                    schemaFranchise !== ""
                        ? schemaFranchise
                        : SelectedAccount.SchemaFranchise,
                UidFranchise:
                    uuidFranchise !== ""
                        ? uuidFranchise
                        : SelectedAccount.UidFranchise,
                FranchiseName:
                    franchiseName !== ""
                        ? franchiseName
                        : SelectedAccount.FranchiseName,
                UidStore:
                    uuidStore !== "" ? uuidStore : SelectedAccount.UidStore,
                IdStore: idStore !== "" ? idStore : SelectedAccount.IdStore,
                StoreName:
                    StoreName !== "" ? StoreName : SelectedAccount.StoreName,
            });
        }
    }
    function getBoutiquesFranchise(schema: any) {
        fetch(
            `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/getBoutiquesFranchise?schemaFranchise=${schema}`,
            {
                method: "GET",
                headers: { "Content-Type": "application/json" },
            }
        )
            .then((response: any) => response.json())
            .then((response: any) => {
                if (response.length > 0) {
                    setUidStoreList(
                        response.map((boutique: any) => {
                            return {
                                label: boutique.NameBoutique,
                                value: boutique.uidBoutique,
                                id: boutique.idBoutique,
                            };
                        })
                    );
                }
            })
            .catch((error) => {
                console.error("There was an error!", error);
            });
    }

    useEffect(() => {
        getBoutiquesFranchise(schemaFranchise);
    }, [schemaFranchise]);
    return (
        <div>
            <StyledModal
                className="advanced-information-modal-style"
                centered
                isOpen={isAdvancedInformationModalOpened}
            >
                <ModalHeader className="d-flex align-items-center">
                    <div
                        className="d-flex align-items-center justify-content-between "
                        style={{
                            height: "35px",
                            display: "grid",
                            gridTemplateColumns: "1.8fr 0.2fr",
                            gridTemplateRows: "1fr",
                            gap: "15px",
                        }}
                    >
                        <p className="title-text-style">
                            INFORMATIONS AVANCEES
                        </p>

                        <StyledIconButton icon="CrossIcon">
                            <CrossIcon
                                cursor="pointer"
                                height={10}
                                width={10}
                                onClick={() => {
                                    setIsAdvancedInformationModalOpened(
                                        !isAdvancedInformationModalOpened
                                    );
                                }}
                            />
                        </StyledIconButton>
                    </div>
                </ModalHeader>

                <ModalBody>
                    <fieldset
                        className="border p-3 title-legend.style"
                        style={{ width: "400px", height: "350px" }}
                    >
                        <div
                            style={{
                                marginLeft: "65px",
                            }}
                        >
                            <div
                                className="row "
                                style={{
                                    display: "grid",
                                    gridTemplateColumns: "2.0fr",
                                    gridTemplateRows: "1fr 1fr",
                                    marginTop: "10px",
                                    width: "280px",
                                }}
                            >
                                <div
                                    style={{
                                        position: "relative",
                                    }}
                                >
                                    <div className="text-legend-style">
                                        Schéma Franchise
                                    </div>
                                </div>
                                <StyledSelectInput
                                    isDisabled={
                                        filtreTableAccount === "Archivé"
                                            ? true
                                            : false
                                    }
                                    name="schemafranchise"
                                    id="schemafranchise"
                                    options={ListeSchemaFranchise}
                                    onChange={(e: any) => {
                                        setSchemaFranchise(e.value);
                                        setFranchiseName(e.label);
                                        if (SelectedAccount === "") {
                                            if (typeAccount !== "Franchise") {
                                                setUuidFranchise(e.uid);
                                                // setFranchiseName(e.label);
                                            }
                                        } else {
                                            if (
                                                SelectedAccount.AccountType !==
                                                "Franchise"
                                            ) {
                                                setUuidFranchise(e.uid);
                                                // setFranchiseName(e.label);
                                            }
                                        }
                                    }}
                                    defaultValue={
                                        SelectedAccount === ""
                                            ? ""
                                            : {
                                                  label:
                                                      SelectedAccount.FranchiseName,
                                                  value:
                                                      SelectedAccount.SchemaFranchise,
                                                  uid:
                                                      SelectedAccount.UidFranchise,
                                              }
                                    }
                                    placeholder={"Sélectionner"}
                                />
                            </div>

                            <div
                                className="row "
                                style={{
                                    display: "grid",
                                    gridTemplateColumns: "2.5fr",
                                    gridTemplateRows: "1fr 1fr",
                                    marginTop: "10px",
                                    pointerEvents:
                                        filtreTableAccount === "Archivé"
                                            ? "none"
                                            : "auto",
                                    opacity:
                                        filtreTableAccount === "Archivé"
                                            ? "0.8"
                                            : "",
                                }}
                            >
                                <div className="text-legend-style">
                                    UID Franchise
                                </div>
                                <input
                                    style={{
                                        top: "429px",
                                        left: "875px",
                                        width: "280px",
                                        height: "35px",
                                        border: "1px solid #E3E3E3",
                                        borderRadius: "6px",
                                        opacity: "1",
                                    }}
                                    value={
                                        uuidFranchise === ""
                                            ? SelectedAccount === ""
                                                ? uuidFranchise
                                                : SelectedAccount.UidFranchise
                                            : uuidFranchise
                                    }
                                    onChange={(e: any) => {
                                        setUuidFranchise(e.target.value);
                                    }}
                                    type="string"
                                    id="UidFranchise"
                                    name="UidFranchise"
                                />
                            </div>
                            <div
                                className="row "
                                style={{
                                    display: "grid",
                                    gridTemplateColumns: "2.5fr",
                                    gridTemplateRows: "1fr 1fr",
                                    marginTop: "10px",
                                }}
                            >
                                <div className="text-legend-style">
                                    UID Boutique
                                </div>

                                <Select
                                    className="select-style"
                                    menuPlacement="auto"
                                    menuPosition="fixed"
                                    isClearable
                                    isSearchable
                                    isDisabled={
                                        filtreTableAccount === "Archivé"
                                            ? true
                                            : SelectedAccount !== ""
                                            ? SelectedAccount.AccountType ===
                                              "Franchise"
                                                ? true
                                                : false
                                            : typeAccount !== "Franchise"
                                            ? false
                                            : true
                                    }
                                    name="uidStoreSelect"
                                    options={uidStoreList}
                                    placeholder={"Sélectionner"}
                                    onChange={(e: any) => {
                                        setUuidStore(e.value);
                                        setIdStore(e.id);
                                        setStoreName(e.label);
                                    }}
                                    defaultValue={
                                        SelectedAccount === ""
                                            ? ""
                                            : {
                                                  label:
                                                      SelectedAccount.StoreName,
                                                  value:
                                                      SelectedAccount.UidStore,
                                                  id: SelectedAccount.IdStore,
                                              }
                                    }
                                />
                            </div>
                        </div>
                        <div
                            className="row "
                            style={{
                                display: "grid",
                                gridTemplateColumns: "2.5fr",
                                gridTemplateRows: "1fr 1fr",
                                marginTop: "10px",
                                marginLeft: "50px",
                            }}
                        >
                            <div className="text-legend-style">ID Boutique</div>
                            <input
                                style={{
                                    top: "429px",
                                    left: "875px",
                                    width: "280px",
                                    height: "35px",
                                    border: "1px solid #E3E3E3",
                                    borderRadius: "6px",
                                    // opacity: "1",
                                    opacity:
                                        SelectedAccount !== ""
                                            ? SelectedAccount.AccountType ===
                                              "Franchise"
                                                ? "0.8"
                                                : "1"
                                            : typeAccount !== "Franchise"
                                            ? "1"
                                            : "0.8",
                                }}
                                disabled={
                                    filtreTableAccount === "Archivé"
                                        ? true
                                        : SelectedAccount !== ""
                                        ? SelectedAccount.AccountType ===
                                          "Franchise"
                                            ? true
                                            : false
                                        : typeAccount !== "Franchise"
                                        ? false
                                        : true
                                }
                                value={
                                    idStore === ""
                                        ? SelectedAccount === ""
                                            ? ""
                                            : SelectedAccount.IdStore
                                        : idStore
                                }
                                onChange={(e: any) => {
                                    setIdStore(e.target.value);
                                }}
                                type="string"
                                id="UidStore"
                                name="UidStore"
                            />
                        </div>
                    </fieldset>
                </ModalBody>

                <ModalFooter
                    className="d-flex justify-content-end"
                    style={{
                        background: "#FFFFFF",
                    }}
                >
                    <div
                        className="d-flex align-items-center"
                        style={{ gap: "30px", marginRight: "-60px" }}
                    >
                        <div className="col-4">
                            <StyledButton
                                disabled={false}
                                rounded
                                variant="light"
                                outline={true}
                                size="sm"
                                style={{
                                    background: "white",
                                    color: "#323333",
                                    padding: "0px",
                                    width: "150px",
                                    height: "45px",
                                }}
                                onClick={() => {
                                    setIsAdvancedInformationModalOpened(
                                        !isAdvancedInformationModalOpened
                                    );
                                }}
                            >
                                Annuler
                            </StyledButton>
                        </div>
                        <div className="col-4">
                            <StyledButton
                                disabled={false}
                                rounded
                                variant="primary"
                                outline={false}
                                size="sm"
                                style={{
                                    backgroundColor: "#323333",
                                    color: "white",
                                    padding: "0px",
                                    width: "150px",
                                    height: "45px",
                                }}
                                onClick={() => {
                                    handleAddAdvancedInformation();
                                    setIsAdvancedInformationModalOpened(
                                        !isAdvancedInformationModalOpened
                                    );
                                }}
                            >
                                Valider
                            </StyledButton>
                        </div>
                    </div>
                </ModalFooter>
            </StyledModal>
        </div>
    );
}
