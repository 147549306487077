import React from "react";
import {
    StyledButton,
    StyledModal,
    CrossIcon,
    StyledIconButton,
    StyledTextInput,
} from "@aureskonnect/react-ui";
import { ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import "../Overview/index.css";

type AlerteModaltype = {
    isAlertSendCertificateLicenceWithMailOpened: boolean;
    setIsAlertSendCertificateLicenceWithMailOpened: Function;
};

export default function AlertSendCertificateLicenceWithMail({
    isAlertSendCertificateLicenceWithMailOpened,
    setIsAlertSendCertificateLicenceWithMailOpened,
}: AlerteModaltype) {
    return (
        <div>
            <StyledModal
                className="updateAlerte"
                centered
                isOpen={isAlertSendCertificateLicenceWithMailOpened}
            >
                <ModalHeader className="d-flex align-items-center">
                    <div
                        className="d-flex align-items-center justify-content-between "
                        style={{
                            height: "30px",
                            display: "grid",
                            gridTemplateColumns: "1.8fr 0.2fr",
                            gridTemplateRows: "1fr",
                            gap: "65px",
                        }}
                    >
                        <p className="title-text-style">
                            ENVOI DU CERTIFICAT PAR E-MAIL
                        </p>
                        <StyledIconButton
                            icon="CrossIcon"
                            onClick={() => {
                                setIsAlertSendCertificateLicenceWithMailOpened(
                                    !isAlertSendCertificateLicenceWithMailOpened
                                );
                            }}
                        >
                            <CrossIcon
                                cursor="pointer"
                                height={10}
                                width={10}
                            />
                        </StyledIconButton>
                    </div>
                </ModalHeader>

                <ModalBody>
                    <fieldset
                        className="border p-3 title-legend.style"
                        style={{ marginTop: "20px" }}
                    >
                        <legend style={{ fontSize: "15px" }}>
                            Confirmer l'adresse e-mail
                        </legend>
                        Un e-mail contenant le certificat de licence sera envoyé
                        à l'adresse sivante :
                        <div className="pt-4">
                            <AvForm>
                                <StyledTextInput
                                    id="example-inputddddd"
                                    name="example-inputddddd"
                                    type="text"
                                    onChange={(e: any) => {}}
                                    value={"adresse@exemple.com"}
                                    // autoFocus
                                />
                            </AvForm>
                        </div>
                    </fieldset>
                </ModalBody>

                <ModalFooter
                    className="d-flex justify-content-end"
                    style={{
                        background: "#FFFFFF",
                    }}
                >
                    <div
                        className="d-flex justify-content-end pr-5"
                        style={{ gap: "30px" }}
                    >
                        <div className="col-4">
                            <StyledButton
                                disabled={false}
                                rounded
                                variant="light"
                                outline={true}
                                size="sm"
                                style={{
                                    background: "white",
                                    color: "black",
                                    padding: "0px",
                                    width: "150px",
                                    height: "45px",
                                }}
                                onClick={() => {
                                    setIsAlertSendCertificateLicenceWithMailOpened(
                                        !isAlertSendCertificateLicenceWithMailOpened
                                    );
                                }}
                            >
                                Annuler
                            </StyledButton>
                        </div>
                        <div className="col-4">
                            <StyledButton
                                disabled={false}
                                rounded
                                variant="primary"
                                outline={false}
                                size="sm"
                                style={{
                                    color: "white",
                                    padding: "0px",
                                    width: "150px",
                                    height: "45px",
                                }}
                                onClick={() => {
                                    setIsAlertSendCertificateLicenceWithMailOpened(
                                        !isAlertSendCertificateLicenceWithMailOpened
                                    );
                                }}
                            >
                                Valider
                            </StyledButton>
                        </div>
                    </div>
                </ModalFooter>
            </StyledModal>
        </div>
    );
}
