import { DynamicTable } from "@maherunlocker/custom-react-table";
import React from "react";
import { store } from "@pages/store";
import { useSnapshot } from "valtio";

const actionColumn = () => {
    return null;
};

function TableClientHistory() {
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const { SelectedAccount } = useSnapshot(store);

    return (
        <DynamicTable
            url={`${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/HistoryEntity?uid=${SelectedAccount.Id}`}
            maxHeight={"calc(100vh - 445px)"}
            minHeight={"calc(100vh - 445px)"}
            //optionnal props
            showFilter
            actionColumn={actionColumn}
            //canGroupBy
            // canSort
            // canResize
            //canExpand
            // canSelect
            showGlobalFilter
            // showFilterbyColumn
            // showColumnIcon
            // canDeleteOrDuplicate
            filterActive={filterActive}
            setLocalFilterActive={setLocalFilterActive}
            // arrayOfCustomColumns={arrayOfCustomColumns}
        />
    );
}

export default TableClientHistory;
