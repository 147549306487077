import React from "react";
import { AvForm } from "availity-reactstrap-validation";

import { DynamicTable } from "@maherunlocker/custom-react-table";
import {
    DocumentIcon,
    PencilIcon,
    PlusIcon,
    StyledIconButton,
    TrashIcon,
    EyeIcon,
    AssociateIcon,
    DisassociateIcon,
    DisplayIcon,
    StyledButtonWithIcon,
    VerticalDotsIcon,
} from "@aureskonnect/react-ui";
import { IoIosCheckmark } from "react-icons/io";
import { useSnapshot } from "valtio";

import {
    setIsHitoryAllProducts,
    setSelectedProduct,
    store,
    setSelectedProductBeforeUpdate,
    setlistRefModule,
    setModifProduct,
    setIsAffected,
    setProduitLicence,
} from "../store";

import ProductsActionMenu from "./ProductsActionsMenu";
import "../index.css";
import AlerteModal from "./AlerteModal";
import { showToast } from "@components/Common/ToastShow";

interface customColumnProps {
    indexOFColumn: number;
    columnName: string;
    customJsx: Function;
}

type TableProps = {
    setIsHiddenCard: React.Dispatch<React.SetStateAction<boolean>>;
    isHiddenCard: boolean;
    urlTable: string;
    setIsEditAction: React.Dispatch<React.SetStateAction<boolean>>;
    setDataIsUpdated: React.Dispatch<React.SetStateAction<number | boolean>>;
    dataIsUpdated: number | boolean;
    setSelectedRows: React.Dispatch<React.SetStateAction<any>>;
    selectedRows: any;
    isHisotyProductModalOpened?: boolean;
    setIsHisotyProductModalOpened: React.Dispatch<
        React.SetStateAction<boolean>
    >;
    setProductState: React.Dispatch<React.SetStateAction<boolean>>;
    isProductState: boolean;
    isAlertupdateProductModal: boolean;
    setIsAlertupdateProductModal: Function;
    setEditProduct: Function;
    setIsAssociateModuleModalOpen: Function;
    isAssociateModuleModalOpen: boolean;
    setIsAlerteDissociateModuleModalOpened: Function;
    isAlerteDissociateModuleModalOpened: boolean;
};

function TableListProducts(props: TableProps) {
    const [isAlerteModalOpened, setIsAlerteModalOpened] = React.useState(false);
    const [typeAlert, setTypeAlert] = React.useState<string>("");
    const { SelectedProduct, isVisible } = useSnapshot(store);

    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);

    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 2,
            columnName: "Module",
            customJsx: ModuleColmun,
        },
        {
            indexOFColumn: 3,
            columnName: "Type du produit",
            customJsx: TypeProduit,
        },
        {
            indexOFColumn: 5,
            columnName: "Support de vente",
            customJsx: StatusColmunNoteAnomalieCompte,
        },
        {
            indexOFColumn: 7,
            columnName: "Actions",
            customJsx: ActionsColmunCompte,
        },
    ];

    function TypeProduit({ selectedRow }: any) {
        return (
            <React.Fragment>
                <div>{selectedRow.Typeduproduit}</div>
            </React.Fragment>
        );
    }

    function StatusColmunNoteAnomalieCompte({ selectedRow }: any) {
        const isSalesSupport = selectedRow.SalesSupport;
        if (isSalesSupport) {
            return (
                <div className="center" style={{ width: "160px" }}>
                    <IoIosCheckmark
                        style={{
                            fill: " #363636",
                            height: "48px",
                            width: "46px",
                        }}
                    />
                </div>
            );
        } else {
            return <div className="center" style={{ width: "160px" }}></div>;
        }
    }

    function ActionsColmunCompte({ selectedRow }: any) {
        const {
            modifProduct,
            selectedProductsLicence,
            selectedProductsAssociatedToPack,
            selectedModuleProductsLicence,
        } = useSnapshot(store);
        let tableModuleRef: any = [];

        return (
            <React.Fragment>
                <div className="w-100">
                    {!selectedRow.isPrincipalProduct ? (
                        <div
                            className="row "
                            style={{
                                display: "grid",
                                gridTemplateColumns: "1fr 1fr auto",
                                gap: "20px",
                                marginLeft: "30%",
                            }}
                        >
                            <div
                                className="d-flex align-items-center "
                                style={{ cursor: "pointer" }}
                            ></div>
                            <div className="d-flex align-items-center ">
                                <div
                                    className="w-100 hovertext"
                                    data-hover={`Dissocier ce module de ce produit `}
                                    style={{
                                        cursor: "pointer",

                                        pointerEvents: !props.isProductState
                                            ? "none"
                                            : "auto",
                                        opacity: !props.isProductState
                                            ? "0.4"
                                            : "",
                                    }}
                                >
                                    <DisassociateIcon
                                        height={46}
                                        width={46}
                                        onClick={() => {
                                            if (
                                                selectedProductsLicence.includes(
                                                    selectedRow.uuidParent +
                                                        "|" +
                                                        selectedRow.Uuid
                                                )
                                            ) {
                                                showToast(
                                                    false,
                                                    `Attention, vous ne pouvez pas dissocier un module d'un produit ayant des licences actives.`
                                                );
                                            } else {
                                                if (
                                                    selectedProductsAssociatedToPack.includes(
                                                        selectedRow.uuidParent +
                                                            "|" +
                                                            selectedRow.Uuid
                                                    )
                                                ) {
                                                    showToast(
                                                        false,
                                                        `Vous ne pouvez pas dissocier ce module de ce produit.Il faut le dissocier du pack avant.`
                                                    );
                                                } else {
                                                    if (modifProduct === true) {
                                                        props.setIsAlertupdateProductModal(
                                                            true
                                                        );
                                                        props.setSelectedRows(
                                                            selectedRow
                                                        );
                                                        props.setEditProduct(
                                                            "dissociate"
                                                        );
                                                        props.setIsAlerteDissociateModuleModalOpened(
                                                            !props.isAlerteDissociateModuleModalOpened
                                                        );
                                                        setModifProduct(false);
                                                    } else {
                                                        setSelectedProduct(
                                                            selectedRow
                                                        );
                                                        setTypeAlert(
                                                            "Dissociation"
                                                        );
                                                        props.setIsAlerteDissociateModuleModalOpened(
                                                            !props.isAlerteDissociateModuleModalOpened
                                                        );
                                                    }
                                                }
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div
                            className="row "
                            style={{
                                display: "grid",
                                gridTemplateColumns: "1fr 1fr auto",
                                gap: "20px",
                                marginLeft: "30%",
                            }}
                        >
                            <div
                                className="d-flex align-items-center "
                                style={{ cursor: "pointer" }}
                            >
                                {!props.isProductState ? (
                                    <DisplayIcon
                                        height={46}
                                        width={46}
                                        onClick={() => {
                                            props.setIsEditAction(true);
                                            setSelectedProduct(selectedRow);

                                            props.setIsHiddenCard(
                                                !props.isHiddenCard
                                            );
                                            setLocalFilterActive(false);
                                        }}
                                    />
                                ) : (
                                    <PencilIcon
                                        height={25}
                                        width={25}
                                        id="EditBtton"
                                        onClick={() => {
                                            if (modifProduct === true) {
                                                props.setIsAlertupdateProductModal(
                                                    true
                                                );
                                                props.setEditProduct("Edit");
                                                props.setIsEditAction(true);
                                                props.setSelectedRows(
                                                    selectedRow
                                                );
                                                setLocalFilterActive(false);
                                            } else {
                                                props.setIsEditAction(true);
                                                setSelectedProductBeforeUpdate(
                                                    selectedRow
                                                );
                                                setSelectedProduct(selectedRow);
                                                props.setIsHiddenCard(
                                                    !props.isHiddenCard
                                                );
                                                setLocalFilterActive(false);
                                                props.setIsAssociateModuleModalOpen(
                                                    props.isAssociateModuleModalOpen
                                                );
                                            }
                                        }}
                                    />
                                )}
                            </div>
                            <div className="d-flex align-items-center ">
                                <div
                                    className="w-100 hovertext"
                                    data-hover={`Associer ce produit à un/des module(s) `}
                                    style={{
                                        cursor: "pointer",
                                        pointerEvents: !props.isProductState
                                            ? "none"
                                            : "auto",
                                        opacity: !props.isProductState
                                            ? "0.4"
                                            : "",
                                    }}
                                >
                                    <AssociateIcon
                                        height={46}
                                        width={46}
                                        onClick={() => {
                                            if (
                                                selectedModuleProductsLicence.includes(
                                                    selectedRow.Uuid
                                                )
                                            ) {
                                                setIsAffected(true);
                                                setProduitLicence(
                                                    selectedRow.Uuid
                                                );
                                            }
                                            Object.keys(
                                                selectedRow.subRows
                                            ).forEach((key) => {
                                                tableModuleRef.push(
                                                    selectedRow.subRows[key]
                                                        .Référence
                                                );
                                            });
                                            setlistRefModule(tableModuleRef);
                                            if (modifProduct === true) {
                                                props.setIsAlertupdateProductModal(
                                                    true
                                                );
                                                props.setSelectedRows(
                                                    selectedRow
                                                );
                                                props.setEditProduct(
                                                    "Associate"
                                                );
                                                props.setIsAssociateModuleModalOpen(
                                                    !props.isAssociateModuleModalOpen
                                                );
                                                setModifProduct(false);
                                            } else {
                                                props.setIsAssociateModuleModalOpen(
                                                    !props.isAssociateModuleModalOpen
                                                );
                                                setSelectedProduct(selectedRow);
                                                props.setIsHiddenCard(
                                                    props.isHiddenCard
                                                );
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </React.Fragment>
        );
    }

    function ModuleColmun({ selectedRow }: any) {
        let moduleNumber;

        if (typeof selectedRow.Module === "number") {
            if (selectedRow.Module < 10) {
                moduleNumber = "0" + selectedRow.Module;
            } else {
                moduleNumber = selectedRow.Module;
            }
        }
        return (
            <React.Fragment>
                {typeof selectedRow.Module === "number" ? (
                    <div
                        className="hovertext"
                        data-hover={`Ce produit contient  ${moduleNumber} module(s) `}
                    >
                        {moduleNumber}
                    </div>
                ) : (
                    <div>{selectedRow.Module}</div>
                )}
            </React.Fragment>
        );
    }

    function SelectAccountDropdown({ selectedRow }: any) {
        const {
            selectedOneProductAssociatedToPack,
            selectedModuleProductsLicence,
        } = useSnapshot(store);
        if (selectedRow.original.isPrincipalProduct) {
            return (
                <React.Fragment>
                    <div className="dropdown ml-2  d-none d-sm-block">
                        <VerticalDotsIcon
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            cursor="pointer"
                            height={25}
                            width={25}
                        />
                        <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton"
                            style={{ marginLeft: "-150px" }}
                        >
                            {props.isProductState ? (
                                <div
                                    className="dropdown-item"
                                    style={{
                                        fontWeight: "bold",
                                        alignItems: "center",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => {
                                        if (
                                            selectedModuleProductsLicence.includes(
                                                selectedRow.original.Uuid
                                            )
                                        ) {
                                            showToast(
                                                false,
                                                `Attention, vous ne pouvez pas archiver un produit ayant des licences actives.`
                                            );
                                        } else {
                                            if (
                                                selectedRow.original.subRows
                                                    .length > 0
                                            ) {
                                                showToast(
                                                    false,
                                                    `Vous ne pouvez pas archiver un produit contenant un/ou plusieurs modules et/ou associé à un pack.`
                                                );
                                            } else {
                                                if (
                                                    selectedOneProductAssociatedToPack.includes(
                                                        selectedRow.original
                                                            .Uuid
                                                    )
                                                ) {
                                                    showToast(
                                                        false,
                                                        `Vous ne pouvez pas archiver un produit contenant un/ou plusieurs modules et/ou associé à un pack.`
                                                    );
                                                } else {
                                                    props.setProductState(true);
                                                    setSelectedProduct(
                                                        selectedRow.original
                                                    );
                                                    setTypeAlert("");
                                                    setIsAlerteModalOpened(
                                                        !isAlerteModalOpened
                                                    );
                                                }
                                            }
                                        }
                                    }}
                                >
                                    <TrashIcon
                                        height={25}
                                        width={25}
                                        className="mr-2"
                                        style={{ fill: "red" }}
                                    />
                                    Archiver produit
                                </div>
                            ) : (
                                <div
                                    className="dropdown-item"
                                    style={{
                                        fontWeight: "bold",
                                        alignItems: "center",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => {
                                        props.setProductState(false);
                                        setSelectedProduct(
                                            selectedRow.original
                                        );
                                        setTypeAlert("");
                                        setIsAlerteModalOpened(
                                            !isAlerteModalOpened
                                        );
                                    }}
                                >
                                    <EyeIcon
                                        height={25}
                                        width={25}
                                        className="mr-2"
                                    />
                                    Activer produit
                                </div>
                            )}
                            <div
                                className="dropdown-item"
                                style={{
                                    fontWeight: "bold",
                                    alignItems: "center",
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    props.setIsHisotyProductModalOpened(
                                        !props.isHisotyProductModalOpened
                                    );
                                    setSelectedProduct(selectedRow.original);
                                    setIsHitoryAllProducts(false);
                                }}
                            >
                                <DocumentIcon
                                    height={25}
                                    width={25}
                                    className="mr-2"
                                />
                                Historique
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            );
        } else {
            return null;
        }
    }

    React.useEffect(() => {
        if (filterActive) {
            props.setIsHiddenCard(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterActive]);

    return (
        <div>
            <DynamicTable
                url={props.urlTable}
                minHeight={`calc(100vh - (500px + ${
                    isVisible ? "-71px" : "0px"
                }))`}
                maxHeight={`calc(100vh - (500px + ${
                    !isVisible ? "100px" : "0px"
                }))`}
                actionColumn={SelectAccountDropdown}
                showFilter
                canResize
                customJsxSideFilterButton={
                    props.isHiddenCard === true || filterActive === true ? (
                        <StyledIconButton
                            icon="PlusIcon"
                            disabled={filterActive ? true : false}
                            style={{
                                background: "#323333",
                                border: "none",
                                borderColor: "#323333",
                                borderRadius: "6px",
                                display: "flex",
                                padding: "12px 10px",
                            }}
                        >
                            <PlusIcon
                                height={20}
                                width={20}
                                style={{ fill: "#fff" }}
                            />
                        </StyledIconButton>
                    ) : (
                        <div className="d-flex align-items-center mt-2 mx-2 mb-2">
                            <AvForm
                                onSubmit={() => {
                                    setLocalFilterActive(false);
                                    props.setIsEditAction(false);
                                    setSelectedProduct("");
                                    props.setIsHiddenCard(!props.isHiddenCard);
                                }}
                            >
                                <StyledButtonWithIcon
                                    disabled={
                                        props.isProductState ? false : true
                                    }
                                    icon="PlusIcon"
                                    iconPosition="left"
                                    rounded
                                    variant="primary"
                                >
                                    Ajouter un produit
                                </StyledButtonWithIcon>
                            </AvForm>

                            <ProductsActionMenu
                                setIsHisotyProductModalOpened={
                                    props.setIsHisotyProductModalOpened !==
                                    undefined
                                        ? props.setIsHisotyProductModalOpened
                                        : () => {}
                                }
                                isHisotyProductModalOpened={
                                    props.isHisotyProductModalOpened
                                }
                                setDataIsUpdated={
                                    props.setDataIsUpdated !== undefined
                                        ? props.setDataIsUpdated
                                        : () => {}
                                }
                                dataIsUpdated={props.dataIsUpdated}
                                selectedRows={props.selectedRows}
                                isProductState={props.isProductState}
                                setProductState={props.setProductState}
                            />
                        </div>
                    )
                }
                canMovedCheckboxLeftOnExpand
                canSelect
                customSelect
                canExpand
                setSelectedRows={props.setSelectedRows}
                showGlobalFilter
                filterActive={filterActive}
                setLocalFilterActive={setLocalFilterActive}
                arrayOfCustomColumns={arrayOfCustomColumns}
                setDataIsUpdated={props.setDataIsUpdated}
                dataIsUpdated={props.dataIsUpdated}
            />

            <AlerteModal
                isAlerteModalOpened={isAlerteModalOpened}
                setIsAlerteModalOpened={setIsAlerteModalOpened}
                setDataIsUpdated={props.setDataIsUpdated}
                dataIsUpdated={props.dataIsUpdated}
                type={typeAlert}
                selectedProduct={SelectedProduct}
                archiveActiveProduct={props.isProductState}
                selectedRows={props.selectedRows}
            />
        </div>
    );
}

export default TableListProducts;
