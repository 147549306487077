import React from "react";
import { StyledModal, StyledLabel, StyledButton } from "@aureskonnect/react-ui";
import { ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";

import moment from "moment";

import TableConsultLicences from "./TableConsultLicences";
import { setIsHistoryLicences, store } from "../store";

type ListHistoryModalType = {
    setIsAlertConsultLicencesModal: Function;
    isAlertConsultLicencesModal: boolean;
    setIsClick: React.Dispatch<React.SetStateAction<boolean>>;
    isClick: boolean;
};

export function ConsultLicencesModal({
    setIsAlertConsultLicencesModal,
    isAlertConsultLicencesModal,
    setIsClick,
    isClick,
}: ListHistoryModalType) {
    const { t } = useTranslation();
    const colorBreadcrumb = "#E5E4E4AB";
    const { SelectedAccount } = useSnapshot(store);

    const [certificateCle, setCertificateCle] = React.useState<string>("");
    const [dateActivation, setDateActivation] = React.useState<string>("");
    const [dateExpiration, setDateExpiration] = React.useState<any>();

    // const getInformationCertificate = React.useCallback(
    //     async function () {
    //         fetch(
    //             `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/consultHistoryCertificateLicenses?uuidEntity=${SelectedAccount.Id}`,
    //             {}
    //         )
    //             .then((response) => {
    //                 response.json().then((data) => {
    //                     if (data !== "{}") {
    //                         data.data.forEach((certificateLicense: any) => {
    //                             if (certificateLicense.Etat === "Activée") {
    //                                 setCertificateCle(certificateLicense.Clé);
    //                                 setDateActivation(
    //                                     certificateLicense.DateDactivation
    //                                 );
    //                                 var new_date;
    //                                 if (certificateLicense.ModeleType === 1) {
    //                                     // modèle One Shot + 6 months
    //                                     new_date = moment(
    //                                         certificateLicense.DateDactivation,
    //                                         "DD/MM/YYYY"
    //                                     ).add("M", 6);
    //                                 } else {
    //                                     // modèle SAAS + 45d
    //                                     new_date = moment(
    //                                         certificateLicense.DateDactivation,
    //                                         "DD/MM/YYYY"
    //                                     ).add("d", 45);
    //                                 }
    //                                 var day = new_date.format("DD");
    //                                 var month = new_date.format("MM");
    //                                 var year = new_date.format("YYYY");
    //                                 const newDateExpiration =
    //                                     day + "/" + month + "/" + year;
    //                                 setDateExpiration(newDateExpiration);
    //                             }
    //                         });
    //                     }
    //                 });
    //             })
    //             .catch((e: any) => {
    //                 console.log({ error: e.message });
    //             });
    //     },
    //     [SelectedAccount.Id]
    // );

    // getInformationCertificate();

    React.useEffect(() => {
        fetch(
            `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/consultHistoryCertificateLicenses?uuidEntity=${SelectedAccount.Id}`,
            {}
        )
            .then((response) => {
                response.json().then((data) => {
                    if (data !== "{}") {
                        data.data.forEach((certificateLicense: any) => {
                            if (certificateLicense.Etat === "Activée") {
                                setCertificateCle(certificateLicense.Clé);
                                setDateActivation(
                                    certificateLicense.DateDactivation
                                );
                                var new_date;
                                if (certificateLicense.ModeleType === 1) {
                                    // modèle One Shot + 6 months
                                    new_date = moment(
                                        certificateLicense.DateDactivation,
                                        "DD/MM/YYYY"
                                    ).add("M", 6);
                                } else {
                                    // modèle SAAS + 45d
                                    new_date = moment(
                                        certificateLicense.DateDactivation,
                                        "DD/MM/YYYY"
                                    ).add("d", 45);
                                }
                                var day = new_date.format("DD");
                                var month = new_date.format("MM");
                                var year = new_date.format("YYYY");
                                const newDateExpiration =
                                    day + "/" + month + "/" + year;
                                setDateExpiration(newDateExpiration);
                            }
                        });
                    }
                });
            })
            .catch((e: any) => {
                console.log({ error: e.message });
            });
    }, [SelectedAccount.Id]);
    return (
        <React.Fragment>
            <StyledModal
                className="history-modal-style"
                isOpen={isAlertConsultLicencesModal}
            >
                <ModalHeader
                    toggle={() => {
                        setIsAlertConsultLicencesModal(
                            !isAlertConsultLicencesModal
                        );
                    }}
                >
                    CERTIFICAT DE LICENCE:{""} {SelectedAccount.commercialname}
                </ModalHeader>
                <ModalBody>
                    <div
                        style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr 1fr 1fr auto",
                            gap: "20px",
                            background: colorBreadcrumb,
                            height: "56px",
                            marginBottom: "5px",
                            alignItems: "center",
                        }}
                    >
                        <div className="d-flex flex-row bd-highlight mb-3 mt-2">
                            <div
                                className="p-2 bd-highlight mt-1"
                                style={{ width: "max-content" }}
                            >
                                <span
                                    style={{
                                        textAlign: "left",
                                        font:
                                            " normal normal bold 20px/14px Segoe UI",
                                        color: "#2B2828",
                                        fontWeight: 600,
                                    }}
                                >
                                    Clé:
                                </span>
                            </div>
                            <div className="p-2 bd-highlight">
                                <StyledLabel
                                    style={{
                                        fontWeight: "normal",
                                        width: "max-content",
                                        marginLeft: "-10px",
                                    }}
                                >
                                    {certificateCle}
                                </StyledLabel>
                            </div>
                        </div>
                        <div className="d-flex justify-content-end flex-row bd-highlight mb-3 mt-2">
                            <div
                                className="p-2 bd-highlight mt-1"
                                style={{ width: "max-content" }}
                            >
                                <span
                                    style={{
                                        textAlign: "left",
                                        font:
                                            " normal normal bold 20px/14px Segoe UI",
                                        color: "#2B2828",
                                        fontWeight: 600,
                                    }}
                                >
                                    Date d'activation :
                                </span>
                            </div>
                            <div className="p-2 bd-highlight">
                                <StyledLabel
                                    style={{
                                        fontWeight: "normal",
                                        width: "max-content",
                                    }}
                                >
                                    {dateActivation}
                                </StyledLabel>
                            </div>
                            <div
                                className="p-2 bd-highlight mt-1"
                                style={{ width: "max-content" }}
                            >
                                <span
                                    style={{
                                        textAlign: "left",
                                        font:
                                            " normal normal bold 20px/14px Segoe UI",
                                        color: "#2B2828",
                                        fontWeight: 600,
                                    }}
                                >
                                    Date d'expiration :
                                </span>
                            </div>
                            <div className="p-2 bd-highlight">
                                <StyledLabel
                                    style={{
                                        fontWeight: "normal",
                                        width: "max-content",
                                    }}
                                >
                                    {dateExpiration}
                                </StyledLabel>
                            </div>
                        </div>
                    </div>
                    <div>
                        <TableConsultLicences />
                    </div>
                </ModalBody>
                <ModalFooter className="d-flex justify-content-end">
                    <div className="d-flex align-items-center">
                        <div className="ml-3">
                            <StyledButton
                                disabled={false}
                                rounded
                                variant="light"
                                outline={true}
                                size="sm"
                                style={{
                                    background: "black",

                                    color: "white",

                                    padding: "0px",

                                    width: "150px",

                                    height: "45px",
                                }}
                                onClick={() => {
                                    if (isClick === false) {
                                        setIsClick(true);
                                        setIsAlertConsultLicencesModal(
                                            !isAlertConsultLicencesModal
                                        );
                                        setIsHistoryLicences(false);
                                    }
                                }}
                            >
                                {t("Close")}
                            </StyledButton>
                        </div>
                    </div>
                </ModalFooter>
            </StyledModal>
        </React.Fragment>
    );
}
