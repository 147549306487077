import React, { useState, useEffect } from "react";
import { useSnapshot } from "valtio";
import {
    PortraitIcon,
    TrashIcon,
    EyeIcon,
    DocumentIcon,
    VerticalDotsIcon,
} from "@aureskonnect/react-ui";
import {
    setIsAlerteModalOpened,
    setAlerteMessagePack,
    setAllPackHistory,
    setArchiveActivePacks,
    store,
    setRefHistoryPack,
    setIsAssociateModuleProductToPackModalOpen,
} from "../../pages/store";
import { showToast } from "@components/Common/ToastShow";
import { useTranslation } from "react-i18next";
import AlerteModal from "@pages/Products/AlerteModal";
import AssociateProductsToPackModal from "./AssociateProductsToPackModal";

type MenuActionProps = {
    setEtatPack: React.Dispatch<React.SetStateAction<boolean>>;
    setDataIsUpdated: React.Dispatch<React.SetStateAction<number | boolean>>;
    dataIsUpdated: boolean;
    selectedRows: any;
    setSelectedRows: React.Dispatch<React.SetStateAction<any[]>>;
    etatPack: boolean;
    isHistoryPackModelOpen: boolean;
    setIsHistoryPackModelOpen: React.Dispatch<React.SetStateAction<boolean>>;
    isAlerteModalOpened: boolean;
};

export default function MenuActionPack({
    setEtatPack,
    setDataIsUpdated,
    dataIsUpdated,
    selectedRows,
    isHistoryPackModelOpen,
    setIsHistoryPackModelOpen,
    isAlerteModalOpened,
    etatPack,
}: MenuActionProps) {
    const [countPack, setCountPack] = useState();
    const [listProduitsPack, setListProduitsPack] = useState([]);
    const {
        archiveActivePacks,
        alerteMessagePack,
        archiveActiveProduct,
        isAssociateModuleProductToPackModalOpen,
    } = useSnapshot(store);
    const { t } = useTranslation();
    const uidEntity = localStorage.getItem("Uid_Entitie_User");

    const getNombresPacks = () => {
        fetch(
            `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/getCountPack?uidEntity=${uidEntity}`
        )
            .then((response) => {
                response.json().then((data) => {
                    if (etatPack) {
                        setCountPack(data.Archive);
                    } else {
                        setCountPack(data.Active);
                    }
                });
            })

            .catch((e: any) => {
                console.log({ error: e.message });
            });
    };

    function showHistory() {
        let ReferencesPacks: any = [];

        for (let i = 0; i < selectedRows.length; i++) {
            if (selectedRows[i].isPack === true) {
                ReferencesPacks.push(selectedRows[i].Référence);
            }
        }

        setRefHistoryPack(ReferencesPacks);
        if (ReferencesPacks.length < 2) {
            showToast(
                false,
                "Attention, c'est une action multiple, vous devez sélectionner au moins deux éléments."
            );
            setTimeout(() => {}, 2000);
        } else {
            setAllPackHistory(true);
            setIsHistoryPackModelOpen(!isHistoryPackModelOpen);
        }
    }
    let listSelected: any = [];
    function handleArchiveActivePacks() {
        for (let i = 0; i < selectedRows.length; i++) {
            if (selectedRows[i].isPack === true) {
                listSelected.push(selectedRows[i].uid);
            }
        }
        setListProduitsPack(listSelected);
        if (listSelected.length < 2) {
            showToast(
                false,
                "Attention, c'est une action multiple, vous devez sélectionner au moins deux éléments."
            );
        } else {
            setAlerteMessagePack(
                "Voulez vous confirmer l'archivage de ces packs ?"
            );
            setIsAlerteModalOpened(!isAlerteModalOpened);
        }
    }

    useEffect(() => {
        getNombresPacks();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRows]);

    return (
        <div>
            <div className="dropdown ml-2  d-none d-sm-block">
                <VerticalDotsIcon
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    cursor="pointer"
                    height={25}
                    width={25}
                />
                <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                    style={{
                        marginLeft: "-150px",
                    }}
                >
                    {etatPack ? (
                        <div
                            className="dropdown-item"
                            role="button"
                            tabIndex={0}
                            onClick={() => {
                                setEtatPack(false);
                            }}
                            style={{
                                cursor: "pointer",
                                pointerEvents:
                                    countPack === "0" ? "none" : "auto",
                                opacity: countPack === "0" ? "0.4" : "",
                            }}
                        >
                            <PortraitIcon
                                className="mr-2"
                                height={25}
                                width={25}
                            />

                            {t(`Pack(s) archivé(s) (${countPack})`)}
                        </div>
                    ) : (
                        <div
                            className="dropdown-item"
                            role="button"
                            tabIndex={0}
                            onClick={() => {
                                setEtatPack(true);
                            }}
                            style={{
                                cursor: "pointer",
                            }}
                        >
                            <PortraitIcon
                                className="mr-2"
                                height={25}
                                width={25}
                            />
                            {t(`Pack(s) activé(s) (${countPack})`)}
                        </div>
                    )}
                    {etatPack ? (
                        <div
                            className="dropdown-item"
                            style={{
                                fontWeight: "bold",
                                alignItems: "center",
                            }}
                            onClick={() => {
                                handleArchiveActivePacks();
                                setArchiveActivePacks(true);
                            }}
                        >
                            <TrashIcon
                                className="mr-2"
                                height={25}
                                width={25}
                                id="ArchiveButton"
                                fill="red"
                            />
                            Archiver pack(s)
                        </div>
                    ) : (
                        <div
                            className="dropdown-item"
                            style={{
                                fontWeight: "bold",
                                alignItems: "center",
                                cursor: "pointer",
                            }}
                            onClick={() => {
                                handleArchiveActivePacks();
                                setArchiveActivePacks(false);
                                setAlerteMessagePack(
                                    "Voulez vous confirmer l'activation du pack selectionné ?"
                                );
                            }}
                        >
                            <EyeIcon
                                className="mr-2"
                                height={25}
                                width={25}
                                id="ActiveButton"
                            />
                            Activer pack(s)
                        </div>
                    )}
                    <div
                        className="dropdown-item"
                        style={{
                            fontWeight: "bold",
                            alignItems: "center",
                        }}
                        onClick={() => {
                            showHistory();
                        }}
                    >
                        <DocumentIcon
                            className="mr-2"
                            id="HistoryButton"
                            height={25}
                            width={25}
                        />
                        Historique
                    </div>
                </div>
            </div>
            <AlerteModal
                isAlerteModalOpened={isAlerteModalOpened}
                setIsAlerteModalOpened={setIsAlerteModalOpened}
                setDataIsUpdated={setDataIsUpdated}
                dataIsUpdated={dataIsUpdated}
                ProductsNames={[]}
                archiveActiveProduct={archiveActiveProduct}
                archiveActivePacks={archiveActivePacks} //archive ==> true
                type={"Pack"}
                AlerteMessage={alerteMessagePack}
                selectedRows={selectedRows}
                listProduitsPack={listProduitsPack}
            />
            <AssociateProductsToPackModal
                isModalOpened={isAssociateModuleProductToPackModalOpen}
                setIsModalOpened={setIsAssociateModuleProductToPackModalOpen}
                setDataIsUpdated={setDataIsUpdated}
                dataIsUpdated={dataIsUpdated}
            />
        </div>
    );
}
