import React from "react";
import {
    StyledButton,
    StyledModal,
    CrossIcon,
    StyledIconButton,
    StyledLabel,
} from "@aureskonnect/react-ui";
import { ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { useSnapshot } from "valtio";
import { setEdit, setModif, store } from "../store";
import { showToast } from "@components/Common/ToastShow";

import "../Overview/index.css";

type AlerteModaltype = {
    setIsAlerteDissociateModalOpened: Function;
    setDataIsUpdated: React.Dispatch<React.SetStateAction<number | boolean>>;
    dataIsUpdated: number | boolean;
};

export default function AlerteDissociateModal({
    setIsAlerteDissociateModalOpened,
    setDataIsUpdated,
    dataIsUpdated,
}: AlerteModaltype) {
    const { isAlerteDissociateModalOpened } = useSnapshot(store);
    const { selectedModule } = useSnapshot(store);
    const uidEntity = localStorage.getItem("Uid_Entitie_User");
    const [isClick, setIsClick] = React.useState(false);

    function saveHistoryChanges(oldData: any, newData: any) {
        fetch(`${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/HistoryChanges`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                oldData: oldData,
                newData: newData,
                uidUpdatedData: uidEntity,
                typeHistory: "2",
            }),
        })
            .then((response) => {
                response.json().then((data) => {
                    return data.error;
                });
            })
            .catch((e: any) => {
                return false;
            });
    }

    function dissociationProducts() {
        const old_module = {
            name: selectedModule.parentName,
            reference: selectedModule.parentReference,
            isPrincipalProduct: selectedModule.isPrincipalProduct,
            modules: selectedModule.ProduitParent,
        };

        const new_module = {
            name: selectedModule.parentName,
            reference: selectedModule.parentReference,
            isPrincipalProduct: selectedModule.isPrincipalProduct,
            modules: selectedModule.ProduitParent,
            associate: false,
        };
        const old_product = {
            name: selectedModule.ProduitParent,
            reference: selectedModule.Référence,
            modules: selectedModule.parentName,
        };
        const new_poduct = {
            name: selectedModule.ProduitParent,
            reference: selectedModule.Référence,
            modules: selectedModule.parentName,
            dissociate: true,
        };

        fetch(
            `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/dissociateModulefromProduct`,
            {
                method: "put",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    uid: selectedModule.parentReference,
                    referenceProduit: selectedModule.Référence,
                }),
            }
        )
            .then((response) => {
                if (response.status === 200 && response.ok === true) {
                    showToast(
                        true,
                        `La disssociation a été effectuée avec succès.`
                    );
                    setTimeout(() => {
                        setDataIsUpdated(!dataIsUpdated);
                    }, 2000);
                    setIsClick(true);
                    saveHistoryChanges(old_module, new_module);
                    saveHistoryChanges(old_product, new_poduct);
                } else if (response.status === 500) {
                    showToast(
                        false,
                        `Ouups! erreur lors de l'dissociation du module, veuillez réessayer SVP`
                    );
                }
            })
            .catch((e: any) => {
                console.log({ error: e.message });
            });
    }
    React.useEffect(() => {
        setIsClick(false);
    }, [dataIsUpdated]);

    return (
        <div>
            <StyledModal
                className=""
                centered
                isOpen={isAlerteDissociateModalOpened}
            >
                <ModalHeader className="d-flex align-items-center">
                    <div
                        className="d-flex align-items-center justify-content-between "
                        style={{
                            height: "20px",
                            width: "200px",
                            display: "grid",
                            gridTemplateColumns: "1.8fr 0.2fr",
                            gridTemplateRows: "1fr",
                            gap: "300px",
                        }}
                    >
                        <p className="title-text-style">CONFIRMATION</p>
                        <StyledIconButton
                            icon="CrossIcon"
                            onClick={() => {
                                setIsAlerteDissociateModalOpened(
                                    !isAlerteDissociateModalOpened
                                );
                                setModif(false);
                                setEdit("");
                            }}
                        >
                            <CrossIcon
                                cursor="pointer"
                                height={10}
                                width={10}
                            />
                        </StyledIconButton>
                    </div>
                </ModalHeader>

                <ModalBody>
                    <div
                        style={{
                            marginLeft: "20px",
                            marginTop: "20px",
                            marginBottom: "20px",
                        }}
                    >
                        <StyledLabel>
                            Voulez-vous confirmer la dissociation du produit "
                            {selectedModule.ProduitParent}" du module "
                            {selectedModule.parentName}" ?
                        </StyledLabel>
                    </div>
                </ModalBody>
                <ModalFooter
                    className="card-footer d-flex align-items-center justify-content-end"
                    style={{
                        background: "#FFFFFF",
                        height: "fit-content",
                    }}
                >
                    <StyledButton
                        disabled={false}
                        rounded
                        variant="light"
                        outline={true}
                        size="sm"
                        style={{
                            background: "white",
                            color: "black",
                            padding: "0px",
                            width: "150px",
                            height: "45px",
                        }}
                        onClick={() => {
                            setIsAlerteDissociateModalOpened(
                                !isAlerteDissociateModalOpened
                            );
                            setModif(false);
                            setEdit("");
                        }}
                    >
                        Annuler
                    </StyledButton>
                    <StyledButton
                        className="ml-2"
                        disabled={false}
                        rounded
                        variant="primary"
                        outline={false}
                        size="sm"
                        style={{
                            color: "white",
                            padding: "0px",
                            width: "150px",
                            height: "45px",
                        }}
                        onClick={() => {
                            if (isClick === false) {
                                setIsAlerteDissociateModalOpened(
                                    !isAlerteDissociateModalOpened
                                );
                                dissociationProducts();
                            }
                        }}
                    >
                        Valider
                    </StyledButton>
                </ModalFooter>
            </StyledModal>
        </div>
    );
}
