import React from "react";
import {
    CrossIcon,
    StyledIconButton,
    StyledModal,
    StyledLabel,
    StyledButton,
} from "@aureskonnect/react-ui";
import { ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";

import TableProductsHistory from "./TableProductsHistory";

import { store } from "../store";
import "../Motifs/index.css";

type ListHistoryModalType = {
    isModalOpened: boolean;
    setIsModalOpened: Function;
    selectedRows: any;
    refHistoryProduct: any;
};

export function HistoryProductsModal(props: ListHistoryModalType) {
    const { t } = useTranslation();
    const storeShot = useSnapshot(store);
    const colorBreadcrumb = "#E5E4E4AB";
    const SelectedProduct = storeShot.SelectedProduct;
    const isHitoryAllProducts = storeShot.isHitoryAllProducts;
    let productName = "";
    if (SelectedProduct.isPrincipalProduct === true) {
        productName = SelectedProduct.Produit;
    }
    return (
        <React.Fragment>
            <StyledModal
                className="history-modal-style"
                isOpen={props.isModalOpened}
            >
                <ModalHeader className="d-flex align-items-center">
                    <div
                        className="d-flex align-items-center justify-content-between"
                        style={{
                            height: "35px",
                            display: "grid",
                            gridTemplateColumns: "1.8fr 0.2fr",
                            gridTemplateRows: "1fr",
                            gap: "1210px",
                        }}
                    >
                        <span className="title-modal-style">
                            {t("HISTORIQUE PRODUIT(S)")}
                        </span>

                        <StyledIconButton icon="CrossIcon">
                            <CrossIcon
                                cursor="pointer"
                                height={10}
                                width={10}
                                onClick={() => {
                                    props.setIsModalOpened(
                                        !props.isModalOpened
                                    );
                                }}
                            />
                        </StyledIconButton>
                    </div>
                </ModalHeader>
                <ModalBody>
                    {isHitoryAllProducts ? null : (
                        <div
                            style={{
                                display: "grid",
                                gridTemplateColumns: "1fr 1fr 1fr 1fr auto",
                                gap: "20px",
                                background: colorBreadcrumb,
                                height: "56px",
                                marginBottom: "5px",
                                alignItems: "center",
                            }}
                        >
                            <div className="d-flex flex-row bd-highlight mb-3">
                                <div className="p-2 bd-highlight">
                                    <div className="d-flex flex-row bd-highlight mb-3">
                                        <div
                                            className="p-2 bd-highlight mt-1"
                                            style={{ width: "max-content" }}
                                        >
                                            <span
                                                style={{
                                                    textAlign: "left",
                                                    font:
                                                        " normal normal bold 20px/14px Segoe UI",
                                                    color: "#2B2828",
                                                    fontWeight: 600,
                                                }}
                                            >
                                                Produit :
                                            </span>
                                        </div>
                                        <div className="p-2 bd-highlight">
                                            <StyledLabel
                                                style={{
                                                    fontWeight: "normal",
                                                    width: "max-content",
                                                }}
                                            >
                                                {productName}
                                            </StyledLabel>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-2 bd-highlight ml-4">
                                    <div className="d-flex flex-row bd-highlight mb-3">
                                        <div
                                            className="p-2 bd-highlight mt-1"
                                            style={{ width: "max-content" }}
                                        >
                                            <span
                                                style={{
                                                    textAlign: "left",
                                                    font:
                                                        " normal normal bold 20px/14px Segoe UI",
                                                    color: "#2B2828",
                                                    fontWeight: 600,
                                                }}
                                            >
                                                Référence :
                                            </span>
                                        </div>
                                        <div className="p-2 bd-highlight">
                                            <StyledLabel
                                                style={{
                                                    fontWeight: "normal",
                                                    width: "max-content",
                                                }}
                                            >
                                                {SelectedProduct.Référence}
                                            </StyledLabel>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <div>
                        <TableProductsHistory
                            Reference={SelectedProduct.Référence}
                            selectedRows={props.selectedRows}
                            refHistoryProduct={props.refHistoryProduct}
                        />
                    </div>
                </ModalBody>
                <ModalFooter className="d-flex justify-content-end">
                    <div className="d-flex align-items-center">
                        <div className="ml-3">
                            <StyledButton
                                disabled={false}
                                rounded
                                variant="light"
                                outline={true}
                                size="sm"
                                style={{
                                    background: "black",

                                    color: "white",

                                    padding: "0px",

                                    width: "150px",

                                    height: "45px",
                                }}
                                onClick={() =>
                                    props.setIsModalOpened(!props.isModalOpened)
                                }
                            >
                                {t("Close")}
                            </StyledButton>
                        </div>
                    </div>
                </ModalFooter>
            </StyledModal>
        </React.Fragment>
    );
}
