import React from "react";
import {
    StyledButton,
    StyledModal,
    CrossIcon,
    StyledIconButton,
    StyledLabel,
} from "@aureskonnect/react-ui";
import { ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { showToast } from "@components/Common/ToastShow";
import { useSnapshot } from "valtio";
import { store, setIsAlerteModuleModalOpened } from "../store";

import "../Overview/index.css";

type AlerteModaltype = {
    setIsAlerteModuleModalOpened: Function;
    setDataIsUpdated: React.Dispatch<React.SetStateAction<number | boolean>>;
    dataIsUpdated: number | boolean;
    ProductsNames: any;
    archiveActiveModule: boolean;
    selectedRows: any;
    setListProduit: React.Dispatch<React.SetStateAction<never[]>>;
};

export default function AlerteModalModule({
    setDataIsUpdated,
    dataIsUpdated,
    ProductsNames,
    archiveActiveModule,
    selectedRows,
    setListProduit,
}: AlerteModaltype) {
    const uidEntity = localStorage.getItem("Uid_Entitie_User");
    const { isAlerteModuleModalOpened } = useSnapshot(store);
    const { selectedModule } = useSnapshot(store);
    function saveHistoryChanges(oldData: any, newData: any, type: any) {
        fetch(`${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/HistoryChanges`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                oldData: oldData,
                newData: newData,
                uidUpdatedData: uidEntity,
                typeHistory: type,
            }),
        })
            .then((response) => {
                response.json().then((data) => {
                    setTimeout(function () {}, 2000);
                    return data.error;
                });
            })
            .catch((e: any) => {
                return false;
            });
    }

    function handleArchiveActiveModules() {
        if (ProductsNames.length > 1) {
            fetch(
                `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/archiveActiveModule`,
                {
                    method: "PUT",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        listReferencesProducts: ProductsNames,
                        action: archiveActiveModule,
                    }),
                }
            )
                .then((response) => {
                    response.json().then((data) => {
                        if (data.error === false) {
                            showToast(
                                true,
                                archiveActiveModule
                                    ? " les modules ont été archivés avec succès."
                                    : " les modules sont activés avec succès."
                            );
                            setTimeout(() => {
                                setDataIsUpdated(!dataIsUpdated);
                            }, 2000);
                            selectedRows.forEach((element: any) => {
                                const old_module = {
                                    name: element.Module,
                                    comment: element.Descriptif,
                                    reference: element.Référence,
                                    salesSupport: element.SalesSupport,
                                    isArchived: !element.isArchived
                                        ? "Module activé"
                                        : "Module archivé",
                                    isPrincipalProduct:
                                        element.isPrincipalProduct,
                                };
                                const new_module = {
                                    name: element.Module,
                                    comment: element.Descriptif,
                                    reference: element.Référence,
                                    salesSupport: element.SalesSupport,
                                    isArchived: archiveActiveModule
                                        ? "Module archivé"
                                        : "Module activé",
                                    isPrincipalProduct:
                                        element.isPrincipalProduct,
                                };
                                saveHistoryChanges(old_module, new_module, 2);
                            });
                            setListProduit([]);
                        }
                    });
                })
                .catch((e: any) => {
                    console.log({ error: e.message });
                });
        } else {
            const old_module = {
                name: selectedModule.Module,
                comment: selectedModule.Descriptif,
                reference: selectedModule.Référence,
                salesSupport: selectedModule.SalesSupport,
                isArchived: !selectedModule.isArchived
                    ? "Module activé"
                    : "Module archivé",
                isPrincipalProduct: selectedModule.isPrincipalProduct,
            };
            const new_module = {
                name: selectedModule.Module,
                comment: selectedModule.Descriptif,
                reference: selectedModule.Référence,
                salesSupport: selectedModule.SalesSupport,
                isArchived: archiveActiveModule
                    ? "Module archivé"
                    : "Module activé",
                isPrincipalProduct: selectedModule.isPrincipalProduct,
            };
            fetch(
                `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/archiveActiveModule`,
                {
                    method: "PUT",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        listReferencesProducts: selectedModule.Référence,
                        action: archiveActiveModule,
                    }),
                }
            )
                .then((response) => {
                    response.json().then((data) => {
                        if (data.error === false) {
                            showToast(
                                true,
                                archiveActiveModule
                                    ? `le module ${selectedModule.Module} a été archivé avec succès.`
                                    : `le module ${selectedModule.Module} a été activé avec succès.`
                            );
                            setTimeout(() => {
                                setDataIsUpdated(!dataIsUpdated);
                            }, 2000);
                            saveHistoryChanges(old_module, new_module, 2);
                        }
                    });
                })
                .catch((e: any) => {
                    console.log({ error: e.message });
                });
        }
    }
    return (
        <div>
            <StyledModal
                className=""
                centered
                isOpen={isAlerteModuleModalOpened}
            >
                <ModalHeader className="d-flex align-items-center">
                    <div
                        className="d-flex align-items-center justify-content-between "
                        style={{
                            height: "20px",
                            width: "200px",
                            display: "grid",
                            gridTemplateColumns: "1.8fr 0.2fr",
                            gridTemplateRows: "1fr",
                            gap: "300px",
                        }}
                    >
                        <p className="title-text-style">CONFIRMATION</p>
                        <StyledIconButton
                            icon="CrossIcon"
                            onClick={() => {
                                setListProduit([]);
                                setIsAlerteModuleModalOpened(
                                    !isAlerteModuleModalOpened
                                );
                            }}
                        >
                            <CrossIcon
                                cursor="pointer"
                                height={10}
                                width={10}
                            />
                        </StyledIconButton>
                    </div>
                </ModalHeader>

                <ModalBody>
                    <div
                        style={{
                            marginLeft: "10px",
                            marginTop: "20px",
                            marginBottom: "20px",
                        }}
                    >
                        <StyledLabel>
                            {archiveActiveModule
                                ? `Voulez-vous confirmer l'archivage   ${
                                      ProductsNames.length > 1
                                          ? `de ces modules `
                                          : `du module " ${selectedModule.Module}" `
                                  } ?`
                                : `Voulez-vous confirmer l'activation ${
                                      ProductsNames.length > 1
                                          ? `de ces modules `
                                          : `du module " ${selectedModule.Module} " `
                                  }?`}
                        </StyledLabel>
                    </div>
                </ModalBody>
                <ModalFooter
                    className="card-footer d-flex align-items-center justify-content-end"
                    style={{
                        background: "#FFFFFF",
                        height: "fit-content",
                    }}
                >
                    <StyledButton
                        disabled={false}
                        rounded
                        variant="light"
                        outline={true}
                        size="sm"
                        style={{
                            background: "white",
                            color: "black",
                            padding: "0px",
                            width: "150px",
                            height: "45px",
                        }}
                        onClick={() => {
                            setListProduit([]);
                            setIsAlerteModuleModalOpened(
                                !isAlerteModuleModalOpened
                            );
                        }}
                    >
                        Annuler
                    </StyledButton>

                    <StyledButton
                        className="ml-2"
                        disabled={false}
                        rounded
                        variant="primary"
                        outline={false}
                        size="sm"
                        style={{
                            color: "white",
                            padding: "0px",
                            width: "150px",
                            height: "45px",
                        }}
                        onClick={() => {
                            handleArchiveActiveModules();

                            setIsAlerteModuleModalOpened(
                                !isAlerteModuleModalOpened
                            );
                        }}
                    >
                        Valider
                    </StyledButton>
                </ModalFooter>
            </StyledModal>
        </div>
    );
}
