import React from "react";
import {
    StyledButton,
    StyledModal,
    CrossIcon,
    StyledIconButton,
} from "@aureskonnect/react-ui";
import Switch from "react-switch";
import { ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import {} from "../store";

import {} from "@components/Common/ToastShow";

import "../Overview/index.css";

type AlerteModaltype = {
    isModifyMultipleAuthorizationModalOpen: boolean;
    setIsModifyMultipleAuthorizationModalOpen: Function;
};

export default function AlertModifyMultipleAuthorization({
    isModifyMultipleAuthorizationModalOpen,
    setIsModifyMultipleAuthorizationModalOpen,
}: AlerteModaltype) {
    const [ischeckedVisibility, setIsCheckedVisibility] = React.useState(false);
    const [ischeckedConsult, setIsCheckedConsult] = React.useState(false);
    const [ischeckedEditColmun, setIsCheckedEditColmun] = React.useState(false);
    return (
        <div>
            <StyledModal
                className="updateAlerte"
                centered
                isOpen={isModifyMultipleAuthorizationModalOpen}
            >
                <ModalHeader className="d-flex align-items-center">
                    <div
                        className="d-flex align-items-center justify-content-between "
                        style={{
                            height: "30px",
                            display: "grid",
                            gridTemplateColumns: "1.8fr 0.2fr",
                            gridTemplateRows: "1fr",
                            gap: "65px",
                        }}
                    >
                        <p className="title-text-style">MODIFICATION GROUPÉE</p>
                        <StyledIconButton
                            icon="CrossIcon"
                            onClick={() => {
                                setIsModifyMultipleAuthorizationModalOpen(
                                    !isModifyMultipleAuthorizationModalOpen
                                );
                            }}
                        >
                            <CrossIcon
                                cursor="pointer"
                                height={10}
                                width={10}
                            />
                        </StyledIconButton>
                    </div>
                </ModalHeader>

                <ModalBody>
                    <fieldset
                        className="border p-3 title-legend.style"
                        style={{ marginTop: "20px" }}
                    >
                        <legend
                            className="w-auto"
                            style={{
                                fontFamily: "Segoe UI",
                            }}
                        >
                            Gérer les autorisations
                        </legend>

                        <div className="d-flex flex-row justify-content-between ">
                            <div className="p-2">
                                <div className="d-flex flex-column ">
                                    <div className="p-2">Visibilité</div>
                                    <div className="p-2 pt-4">Consultation</div>
                                    <div className="p-2 pt-4">Modification</div>
                                </div>
                            </div>
                            <div className="p-2">
                                <div className="d-flex flex-column justify-content-md-end">
                                    <div className="p-2">
                                        <Switch
                                            onChange={() => {
                                                setIsCheckedVisibility(
                                                    !ischeckedVisibility
                                                );
                                            }}
                                            checked={ischeckedVisibility}
                                            offColor="#E30613"
                                            onColor="#34C38F"
                                        />
                                    </div>
                                    <div className="p-2">
                                        <Switch
                                            onChange={() => {
                                                setIsCheckedConsult(
                                                    !ischeckedConsult
                                                );
                                            }}
                                            checked={ischeckedConsult}
                                            offColor="#E30613"
                                            onColor="#34C38F"
                                        />
                                    </div>
                                    <div className="p-2">
                                        <Switch
                                            onChange={() => {
                                                setIsCheckedEditColmun(
                                                    !ischeckedEditColmun
                                                );
                                            }}
                                            checked={ischeckedEditColmun}
                                            offColor="#E30613"
                                            onColor="#34C38F"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </ModalBody>

                <ModalFooter
                    className="d-flex justify-content-end"
                    style={{
                        background: "#FFFFFF",
                    }}
                >
                    <div
                        className="d-flex justify-content-end pr-5"
                        style={{ gap: "30px" }}
                    >
                        <div className="col-4">
                            <StyledButton
                                disabled={false}
                                rounded
                                variant="light"
                                outline={true}
                                size="sm"
                                style={{
                                    background: "white",
                                    color: "black",
                                    padding: "0px",
                                    width: "150px",
                                    height: "45px",
                                }}
                                onClick={() => {
                                    setIsModifyMultipleAuthorizationModalOpen(
                                        !isModifyMultipleAuthorizationModalOpen
                                    );
                                }}
                            >
                                Annuler
                            </StyledButton>
                        </div>
                        <div className="col-4">
                            <StyledButton
                                disabled={false}
                                rounded
                                variant="primary"
                                outline={false}
                                size="sm"
                                style={{
                                    color: "white",
                                    padding: "0px",
                                    width: "150px",
                                    height: "45px",
                                }}
                                onClick={() => {}}
                            >
                                Valider
                            </StyledButton>
                        </div>
                    </div>
                </ModalFooter>
            </StyledModal>
        </div>
    );
}
