import { DisplayIcon } from "@aureskonnect/react-ui";
import { DynamicTable } from "@maherunlocker/custom-react-table";
import {
    setIsAlertConsultLicencesModal,
    setIsHistoryLicences,
    setSelectedAccount,
    store,
} from "@pages/store";
import { useSnapshot } from "valtio";
import React from "react";

const Nodropdown = () => {
    return null;
};
interface customColumnProps {
    indexOFColumn: number;
    columnName: string;
    customJsx: Function;
}

export default function TableHistoryLicenceModal() {
    const { isAlertConsultLicencesModal, selectedEntity } = useSnapshot(store);
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 6,
            columnName: "Action",
            customJsx: DisplayColmunLicence,
        },
    ];
    function DisplayColmunLicence({ selectedRow }: any) {
        return (
            <div
                className="row "
                style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr auto",
                    gap: "20px",
                    marginLeft: "15%",
                }}
            >
                <div
                    className="d-flex align-items-center "
                    style={{
                        cursor: "pointer",
                    }}
                >
                    <DisplayIcon
                        height={46}
                        width={46}
                        onClick={() => {
                            setIsHistoryLicences(true);
                            setSelectedAccount(selectedRow);
                            setIsAlertConsultLicencesModal(
                                !isAlertConsultLicencesModal
                            );
                        }}
                    />
                </div>
            </div>
        );
    }

    return (
        <React.Fragment>
            <DynamicTable
                canSort
                url={`${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/consultHistoryCertificateLicenses?uuidEntity=${selectedEntity.Id}`}
                // url={`${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/ConsultHistoryLicences?infoLicences=""`}
                maxHeight={"calc(100vh - 445px)"}
                minHeight={"calc(100vh - 445px)"}
                canSelect
                customSelect
                showFilter
                actionColumn={Nodropdown}
                arrayOfCustomColumns={arrayOfCustomColumns}
                showGlobalFilter
                filterActive={filterActive}
                setLocalFilterActive={setLocalFilterActive}
            />
        </React.Fragment>
    );
}
