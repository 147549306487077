import { DynamicTable } from "@maherunlocker/custom-react-table";
import React from "react";
import { useSnapshot } from "valtio";

import { store } from "../store";
type CommonType = {
    selectedRows: any;
};
const Nodropdown = () => {
    return null;
};

function TableMotifsHistory({ selectedRows }: CommonType) {
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const storeShot = useSnapshot(store);
    const isHitoryAllMotifs = storeShot.isHitoryAllMotifs;
    const { selectedMotif } = useSnapshot(store);
  
    let allName: any = [];
    selectedRows.forEach((element: any) => {
        let data = element.iuud + "|" + element.Motif;
        allName.push(data);
    });
    let url = "";
    if (isHitoryAllMotifs) {
        url = `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/historyOneMotif?nameMotif=${allName}`;
    } else {
        url = `${
            process.env.REACT_APP_CONSOLE_ADMIN_ACCESS
        }/historyOneMotif?nameMotif=${
            selectedMotif.iuud + "|" + selectedMotif.Motif
        }`;
    }
    return (
        <React.Fragment>
            <DynamicTable
                url={url}
                maxHeight={"calc(100vh - 445px)"}
                minHeight={"calc(100vh - 445px)"}
                showFilter
                canSort
                actionColumn={Nodropdown}
                showGlobalFilter
                filterActive={filterActive}
                setLocalFilterActive={setLocalFilterActive}
            />
        </React.Fragment>
    );
}

export default TableMotifsHistory;
