import { DynamicTable } from "@maherunlocker/custom-react-table";
import React from "react";
const Nodropdown = () => {
    return null;
};

function TablePackHistory(props: any) {
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);

    return (
        <React.Fragment>
            <DynamicTable
                url={props.url}
                maxHeight={"calc(100vh - 445px)"}
                minHeight={"calc(100vh - 445px)"}
                showFilter
                actionColumn={Nodropdown}
                canSort
                showGlobalFilter
                filterActive={filterActive}
                setLocalFilterActive={setLocalFilterActive}
            />
        </React.Fragment>
    );
}

export default TablePackHistory;
