import { proxy, subscribe } from "valtio";

type StoreType = {
    dataProject: dataProjectType[]

}
export const storeAuthorisation = proxy<StoreType>({

    dataProject: [
        {
            "Module(s) / Sous module(s)": "etk Catalogue",
            id: "",
            Visible: false,
            Consult: false,
            Modifie: false,
            subRows: [
                {
                    "Module(s) / Sous module(s)": "etk Catalogue",
                    id: "",
                    Visible: false,
                    Consult: false,
                    Modifie: false,
                }
            ]
        }]

});



subscribe(storeAuthorisation, () => {
    localStorage.setItem(
        "dataProject",
        JSON.stringify(storeAuthorisation.dataProject)
    );
});
