import React from "react";
import {
    DynamicTable,
    customColumnProps,
} from "@maherunlocker/custom-react-table";
import { AvForm } from "availity-reactstrap-validation";
import {
    StyledButtonWithIcon,
    StyledIconButton,
    PlusIcon,
    PencilIcon,
    ExclamationIcon,
} from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import DotIcon from "@assets/images/svg-icons/DotIcon.svg";
import anomalyIcon from "@assets/images/svg-icons/anomalyIcon.svg";
import AddLicenceIcon from "@assets/images/svg-icons/AddLicenceIcon.svg";
import ActivateIcon from "@assets/images/svg-icons/ActivateIcon.svg";
import ConsultIcon from "@assets/images/svg-icons/ConsultIcon.svg";
import HistoryIcon from "@assets/images/svg-icons/HistoryIcon.svg";
import BloqIcon from "@assets/images/svg-icons/BloqIcon.svg";
import DebloqLicenceIcon from "@assets/images/svg-icons/DebloqLicenceIcon.svg";
import DeleteIcon from "@assets/images/svg-icons/DeleteIcon.svg";
import {
    store,
    setIsHidden,
    setSelectedAccount,
    setSelectedLicences,
    setSelectedModuleProductLicences,
    setSelectedEntity,
    setIsUpdateAccount,
    setIsAlertConsultLicencesModal,
    setSelectedTypeEconomicModel,
    setSelectedModuleToUpdate,
    setSelectedExistLicenceDemo,
    setIsHistoryLicences,
    setKeysLicenseBeforeUpdate,
    setLicenseBeforeUpdate,
    setLicenceToDebloq,
} from "../../pages/store";
import { HistoryModal } from "@pages/History/HistoryModal";
import { LicensesModal } from "@pages/Licenses/LicensesModal";
import { IoIosCheckmark } from "react-icons/io";
import { MotifModal } from "@pages/Motifs/MotifLicensesModal";
import { ConfigurationBlocageModal } from "@pages/Overview/ConfigurationBlocageModal";
import { decryptWord } from "@pages/index";
import moment from "moment";

type tableAccountsType = {
    url: string;
    btnValiderhidden: boolean;
    filtreTab: string;
    setDataIsUpdated: React.Dispatch<React.SetStateAction<any>>;
    dataIsUpdated: any;
};

function SelectColmunEtatCompte({ selectedRow }: any) {
    let demoPlanified = false;
    // eslint-disable-next-line
    let BlocagePlanified = false;
    let configDemo = [];
    if (
        selectedRow.limiteCollection !== undefined &&
        selectedRow.limiteCollection.duration !== undefined
    ) {
        configDemo = selectedRow.limiteCollection.duration.split("|");
        if (configDemo[0] > moment().format("DD/MM/YYYY")) {
            demoPlanified = true;
        }
    }
    if (
        selectedRow.settingBlocage !== undefined &&
        selectedRow.settingBlocage.planification !== undefined
    ) {
        if (
            selectedRow.settingBlocage.planification.substr(0, 8) >
                moment().format("DD/MM/YYYY") &&
            selectedRow.settingBlocage.planification.substr(8, 4) >
                moment().format("HHmm")
        ) {
            // eslint-disable-next-line
            BlocagePlanified = true;
        }
    }

    return (
        <div>
            {selectedRow.Etats === "Activé" ? (
                <span
                    className="badge badge-pill badge-success"
                    style={{
                        paddingTop: "8px",
                        paddingBottom: "9px",
                        fontSize: demoPlanified ? "12px" : "13px",
                        width: demoPlanified ? "150px" : "150px",
                    }}
                >
                    {demoPlanified
                        ? `Activation le ${configDemo[0]}`
                        : selectedRow.Etats}
                </span>
            ) : (
                <span
                    className="badge badge-pill badge-danger"
                    style={{
                        paddingTop: "8px",
                        paddingBottom: "9px",
                        fontSize: "13px",
                        width: "100px",
                    }}
                >
                    {selectedRow.Etats}
                </span>
            )}
        </div>
    );
}
function CommercialNameColmun({ selectedRow }: any) {
    let BlocagePlanified = false;
    let BlocageMessage = "";
    if (
        selectedRow.settingBlocage !== undefined &&
        selectedRow.settingBlocage.planification !== undefined
    ) {
        if (
            selectedRow.settingBlocage.planification.substr(0, 8) >
                moment().format("DD/MM/YYYY") &&
            selectedRow.settingBlocage.planification.substr(8, 4) >
                moment().format("HHmm")
        ) {
            BlocagePlanified = true;
            BlocageMessage = ` Blocage le ${selectedRow.settingBlocage.planification.substr(
                6,
                2
            )}/${selectedRow.settingBlocage.planification.substr(
                4,
                2
            )}/${selectedRow.settingBlocage.planification.substr(
                0,
                4
            )} à ${selectedRow.settingBlocage.planification.substr(
                8,
                2
            )}:${selectedRow.settingBlocage.planification.substr(10, 2)}`;
        }
    }
    return BlocagePlanified ? (
        <div
            className="row"
            style={{
                display: "grid",
                gridTemplateColumns: BlocagePlanified ? "0.75fr 5fr" : "auto",
                gridTemplateRows: "1fr",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <div className="hoverTable" Table-hover={BlocageMessage}>
                <ExclamationIcon height={25} width={25} fill="red" />
            </div>
            <div style={{ marginLeft: "20px", padding: "0px" }}>
                {selectedRow.commercialname}
            </div>
        </div>
    ) : (
        <div style={{ width: "140px" }}>{selectedRow.commercialname}</div>
    );
}

function StatusColmunNoteAnomalieCompte({ selectedRow }: any) {
    const noteCompteEntitie = selectedRow.NoteAnomalieEtat;

    if (noteCompteEntitie) {
        return (
            <div className="center" style={{ width: "160px" }}>
                <IoIosCheckmark
                    style={{
                        fill: " #FC2F03",
                        height: "48px",
                        width: "46px",
                    }}
                />
            </div>
        );
    } else {
        return <div className="center" style={{ width: "160px" }}></div>;
    }
}

function StatusColmunNoteDemoCompte({ selectedRow }: any) {
    const noteCompteEntitie = selectedRow.NoteDemoEtat;
    if (noteCompteEntitie) {
        return (
            <div className="center" style={{ width: "160px" }}>
                <IoIosCheckmark
                    style={{
                        fill: " #000000",
                        height: "48px",
                        width: "46px",
                    }}
                />
            </div>
        );
    } else {
        return <div className="center" style={{ width: "160px" }}></div>;
    }
}

function ActiveLicenceColmun({ selectedRow }: any) {
    let sumActiveLicence;
    let array: any = [];
    let array2: any = [];
    if (selectedRow.infoLicence !== "") {
        const LicenceSelected = JSON.parse(
            decryptWord(selectedRow.infoLicence)
        );
        Object.keys(LicenceSelected).forEach((el: any) => {
            const elementUuid = el.split("|");
            if (!array2.includes(elementUuid[1])) {
                array2.push(elementUuid[1]);
                array.push(LicenceSelected[el].actived);
                sumActiveLicence = array.reduce((a: any, b: any) => {
                    return a + b;
                });
            }
        });
    } else {
        sumActiveLicence = 0;
    }

    return <div>{sumActiveLicence}</div>;
}
function CommercialLicenceColmun({ selectedRow }: any) {
    let sumCommercialLicence: any;
    let array: any = [];
    let array2: any = [];
    if (selectedRow.infoLicence !== "") {
        const LicenceSelected = JSON.parse(
            decryptWord(selectedRow.infoLicence)
        );

        Object.keys(LicenceSelected).forEach((el: any) => {
            const elementUuid = el.split("|");
            if (!array2.includes(elementUuid[1])) {
                array2.push(elementUuid[1]);
                if (
                    LicenceSelected[el].demoConfiguration.isActivated === false
                ) {
                    array.push(LicenceSelected[el].actived);
                    sumCommercialLicence = array.reduce((a: any, b: any) => {
                        return a + b;
                    });
                } else {
                    array.push(0);
                    sumCommercialLicence = array.reduce((a: any, b: any) => {
                        return a + b;
                    });
                }
            }
        });
    } else {
        sumCommercialLicence = 0;
    }

    return <div>{sumCommercialLicence}</div>;
}
function DémoLicenceColmun({ selectedRow }: any) {
    let sumDémoLicence;
    let array: any = [];
    let array2: any = [];

    if (selectedRow.infoLicence !== "") {
        const LicenceSelected = JSON.parse(
            decryptWord(selectedRow.infoLicence)
        );

        Object.keys(LicenceSelected).forEach((el: any) => {
            const elementUuid = el.split("|");
            if (!array2.includes(elementUuid[1])) {
                array2.push(elementUuid[1]);
                if (
                    LicenceSelected[el].demoConfiguration.isActivated === true
                ) {
                    array.push(LicenceSelected[el].actived);
                    sumDémoLicence = array.reduce((a: any, b: any) => {
                        return a + b;
                    });
                } else {
                    array.push(0);
                    sumDémoLicence = array.reduce((a: any, b: any) => {
                        return a + b;
                    });
                }
            }
        });
    } else {
        sumDémoLicence = 0;
    }

    return <div>{sumDémoLicence}</div>;
}

export default function TableAccounts({
    url,
    btnValiderhidden,
    filtreTab,
    setDataIsUpdated,
    dataIsUpdated,
}: tableAccountsType) {
    const {
        isHidden,
        isVisible,
        isAlertConsultLicencesModal,
        filtreTableAccount,
    } = useSnapshot(store);
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    let Data: any = {};
    let DataDemo: any = {};
    let DataSelectedModuleProduct: any = {};
    let DatatypeEconomicModel: any = {};
    let arraydataTodebloq: any = [];
    let arraydataTodebloq2: any = [];
    function EditColmunCompte({ selectedRow }: any) {
        return (
            <div
                className="row "
                style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr auto",
                    gap: "20px",
                    marginLeft: "15%",
                }}
            >
                <div
                    className="d-flex align-items-center "
                    style={{
                        cursor: "pointer",
                        pointerEvents:
                            selectedRow.infoLicence === "" ? "none" : "auto",
                        opacity: selectedRow.infoLicence === "" ? "0.4" : "",
                    }}
                    onClick={() => {
                        setIsHistoryLicences(false);
                        setSelectedAccount(selectedRow);
                        setIsAlertConsultLicencesModal(
                            !isAlertConsultLicencesModal
                        );
                    }}
                >
                    <img
                        src={AddLicenceIcon}
                        role="button"
                        id="AjouterLicenceButton"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        alt=""
                    />
                </div>
                <div
                    className="d-flex align-items-center mb-2"
                    style={{ cursor: "pointer" }}
                >
                    <div>
                        {filtreTableAccount !== "Archivé" ? (
                            <PencilIcon
                                height={25}
                                width={25}
                                id="EditBtton"
                                onClick={() => {
                                    setIsHidden(!isHidden);
                                    setSelectedAccount(selectedRow);
                                    setIsUpdateAccount(true);
                                }}
                            />
                        ) : (
                            <div
                                className="mt-2"
                                onClick={() => {
                                    setIsHidden(!isHidden);
                                    setSelectedAccount(selectedRow);
                                    setIsUpdateAccount(true);
                                }}
                            >
                                <img
                                    src={ConsultIcon}
                                    role="button"
                                    id="AjouterLicenceButton"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    alt=""
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
    function MotifColmun({ selectedRow }: any) {
        let motif = "";
        filtreTableAccount === "Archivé" || filtreTableAccount === "Bloqué"
            ? (motif = selectedRow.motifEtatCompte)
            : selectedRow.motifAnomalie === ""
            ? (motif = selectedRow.motifAnomalieSysteme)
            : (motif = selectedRow.motifAnomalie);

        return <div>{motif}</div>;
    }
    const arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 0,
            columnName: "Nom commercial",
            customJsx: CommercialNameColmun,
            filterName: "commercialname",
            canFilter: true,
        },
        {
            indexOFColumn: 6,
            columnName: "Etat compte",
            customJsx: SelectColmunEtatCompte,
        },
        {
            indexOFColumn: 7,
            columnName: "En anomalie",
            customJsx: StatusColmunNoteAnomalieCompte,
        },
        {
            indexOFColumn: 8,
            columnName: "Démo",
            customJsx: StatusColmunNoteDemoCompte,
        },
        {
            indexOFColumn: 9,
            columnName: "Total Licences",
            customJsx: ActiveLicenceColmun,
        },
        {
            indexOFColumn: 10,
            columnName: "Nb. licence(s) commerciale(s)",
            customJsx: CommercialLicenceColmun,
        },
        {
            indexOFColumn: 11,
            columnName: "Nb. licence(s) démo(s)",
            customJsx: DémoLicenceColmun,
        },

        // filtreTableAccount !== "Activé"?
        {
            indexOFColumn: 12,
            columnName:
                filtreTableAccount === "Activé" ||
                filtreTableAccount === "Anomalie"
                    ? "Motif d'anomalie"
                    : filtreTableAccount === "Archivé"
                    ? "Motif d'archivage"
                    : "Motif de blocage",
            customJsx: MotifColmun,
        },
        {
            indexOFColumn: 13,
            columnName: "Actions",
            customJsx: EditColmunCompte,
        },
    ];
    const arrayOfCustomColumnsActiveAccount: customColumnProps[] = [
        {
            indexOFColumn: 0,
            columnName: "Nom commercial",
            customJsx: CommercialNameColmun,
            filterName: "commercialname",
            canFilter: true,
        },
        {
            indexOFColumn: 6,
            columnName: "Etat compte",
            customJsx: SelectColmunEtatCompte,
        },
        {
            indexOFColumn: 7,
            columnName: "En anomalie",
            customJsx: StatusColmunNoteAnomalieCompte,
        },
        {
            indexOFColumn: 8,
            columnName: "Démo",
            customJsx: StatusColmunNoteDemoCompte,
        },
        {
            indexOFColumn: 9,
            columnName: "Total Licences",
            customJsx: ActiveLicenceColmun,
        },
        {
            indexOFColumn: 10,
            columnName: "Nb. licence(s) commerciale(s)",
            customJsx: CommercialLicenceColmun,
        },
        {
            indexOFColumn: 11,
            columnName: "Nb. licence(s) démo(s)",
            customJsx: DémoLicenceColmun,
        },
        {
            indexOFColumn: 14,
            columnName: "Actions",
            customJsx: EditColmunCompte,
        },
    ];
    function SelectAccountDropdown({ selectedRow }: any) {
        const [hiddenAnomalyIcon] = React.useState<boolean>(false);
        const [isHistoryModalOpened, setIsHistoryModalOpened] = React.useState<
            boolean
        >(false);
        const [isLicenseModalOpened, setIsLicenseModalOpened] = React.useState<
            boolean
        >(false);
        const [dataEntitie, setdataEntitie] = React.useState();
        const [statusLicence, setStatusLicence] = React.useState<string>("");
        const [isMotifModalOpened, setIsMotifModalOpened] = React.useState<
            boolean
        >(false);
        const [
            isConfigurationBlocageModalOpened,
            setIsConfigurationBlocageModalOpened,
        ] = React.useState<boolean>(false);
        const [typeMotif] = React.useState<Number>(0);
        const [actionMotifModal, setActionMotifModal] = React.useState<string>(
            ""
        );
        const [updatedLicence, setUpdatedLicence] = React.useState<any>();
        return filtreTab === "Archivé" ||
            filtreTab === "Bloqué" ||
            filtreTab === "Anomalie" ? (
            <div className="w-100">
                <div className="dropdown">
                    <img
                        src={DotIcon}
                        role="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        alt="parametrage"
                    />
                    <div
                        className="dropdown-menu"
                        aria-labelledby="dropdown"
                        style={{ marginLeft: "-150px" }}
                    >
                        {filtreTab === "Anomalie" ? (
                            <div
                                className="dropdown-item"
                                style={{
                                    fontWeight: "bold",
                                    alignItems: "center",
                                }}
                                onClick={() => {
                                    setSelectedAccount(selectedRow.original);
                                    setIsConfigurationBlocageModalOpened(
                                        !isConfigurationBlocageModalOpened
                                    );
                                }}
                            >
                                <img
                                    src={BloqIcon}
                                    role="button"
                                    id="BloqCompteButton"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    alt=""
                                />
                                Bloquer compte
                            </div>
                        ) : (
                            <div
                                className="dropdown-item"
                                style={{
                                    fontWeight: "bold",
                                    alignItems: "center",
                                }}
                                onClick={() => {
                                    setSelectedAccount(selectedRow.original);
                                    if (filtreTableAccount === "Bloqué") {
                                        setActionMotifModal(
                                            "ActivateBlockedAccount"
                                        );
                                    } else {
                                        setActionMotifModal("Activation");
                                    }
                                    setIsMotifModalOpened(!isMotifModalOpened);
                                }}
                            >
                                <img
                                    src={ActivateIcon}
                                    role="button"
                                    id="ActiverCompteArchivé"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    alt=""
                                />
                                <span style={{ marginRight: "5px" }}></span>
                                Activer le compte
                            </div>
                        )}
                        {filtreTab === "Bloqué" || filtreTab === "Anomalie" ? (
                            <div
                                className="dropdown-item"
                                style={{
                                    fontWeight: "bold",
                                    alignItems: "center",
                                }}
                                onClick={() => {
                                    setActionMotifModal("Archivage");
                                    setSelectedAccount(selectedRow.original);
                                    setIsMotifModalOpened(!isMotifModalOpened);
                                }}
                            >
                                <img
                                    src={DeleteIcon}
                                    role="button"
                                    id="ArchiveCompteButton"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    alt=""
                                    style={{ marginLeft: "0px" }}
                                />
                                Archiver compte
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
                <MotifModal
                    setMotif={() => {}}
                    isMotifModalOpened={isMotifModalOpened}
                    setIsMotifModalOpened={setIsMotifModalOpened}
                    typeMotif={typeMotif}
                    setMotifAnnomalie={() => {}}
                    Action={actionMotifModal}
                    setDataIsUpdated={setDataIsUpdated}
                    dataIsUpdated={dataIsUpdated}
                    selectedRows={[]}
                    etatProfil=""
                    setIsConfigurationBlocageModalOpened={
                        setIsConfigurationBlocageModalOpened
                    }
                    isConfigurationBlocageModalOpened={
                        isConfigurationBlocageModalOpened
                    }
                    setAnomalie={() => {}}
                />
                <ConfigurationBlocageModal
                    isConfigurationBlocageModalOpened={
                        isConfigurationBlocageModalOpened
                    }
                    setIsConfigurationBlocageModalOpened={
                        setIsConfigurationBlocageModalOpened
                    }
                    setDataIsUpdated={setDataIsUpdated}
                    dataIsUpdated={dataIsUpdated}
                />
            </div>
        ) : (
            <div className="w-100">
                <div className="dropdown">
                    <img
                        src={DotIcon}
                        role="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        alt="parametrage"
                    />
                    <div
                        className="dropdown-menu"
                        aria-labelledby="dropdown"
                        style={{ marginLeft: "-150px", cursor: "pointer" }}
                    >
                        <div
                            className="dropdown-item"
                            style={{
                                fontWeight: "bold",
                                alignItems: "center",
                                cursor: "pointer",
                            }}
                            onClick={() => {
                                setdataEntitie(selectedRow.original);
                                setSelectedEntity(selectedRow.original);
                                setIsLicenseModalOpened(!isLicenseModalOpened);
                                setStatusLicence("add");
                                if (selectedRow.original.infoLicence !== "") {
                                    const LicenceSelected = JSON.parse(
                                        decryptWord(
                                            selectedRow.original.infoLicence
                                        )
                                    );

                                    setUpdatedLicence(LicenceSelected);
                                    let arr: any = [];
                                    setKeysLicenseBeforeUpdate(
                                        Object.keys(LicenceSelected)
                                    );
                                    setLicenseBeforeUpdate(LicenceSelected);
                                    Object.keys(LicenceSelected).forEach(
                                        (el: any) => {
                                            // let data :any ={}

                                            const elementUuid = el.split("|");
                                            Data[elementUuid[1]] = {
                                                actived:
                                                    LicenceSelected[el].actived,
                                                blocked:
                                                    LicenceSelected[el].blocked,
                                            };
                                            LicenceSelected[
                                                el
                                            ].managementRules.forEach(
                                                (el1: any) => {
                                                    DatatypeEconomicModel[
                                                        elementUuid[1]
                                                    ] = {
                                                        typeeconomicModel:
                                                            LicenceSelected[el]
                                                                .managementRules,
                                                    };
                                                }
                                            );
                                            arr.push(el);
                                            if (
                                                LicenceSelected[el].modules !==
                                                undefined
                                            ) {
                                                DataSelectedModuleProduct[
                                                    elementUuid[1]
                                                ] = {
                                                    modules:
                                                        LicenceSelected[el]
                                                            .modules[
                                                            "modulesUuid"
                                                        ],
                                                };
                                            }
                                            DataDemo[elementUuid[1]] = {
                                                isDemo:
                                                    LicenceSelected[el]
                                                        .demoConfiguration[
                                                        "isActivated"
                                                    ],
                                            };
                                        }
                                    );
                                    setSelectedModuleProductLicences(arr);
                                    setSelectedLicences(Data);
                                    setSelectedTypeEconomicModel(
                                        DatatypeEconomicModel
                                    );
                                    setSelectedModuleToUpdate(
                                        DataSelectedModuleProduct
                                    );
                                    setSelectedExistLicenceDemo(DataDemo);
                                } else {
                                    setSelectedLicences({});
                                    setSelectedExistLicenceDemo({});
                                    setUpdatedLicence({});
                                    setSelectedTypeEconomicModel({});
                                    setKeysLicenseBeforeUpdate([]);
                                }
                            }}
                        >
                            <img
                                src={AddLicenceIcon}
                                role="button"
                                id="AjouterLicenceButton"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                alt=""
                            />
                            Ajouter licence(s)
                        </div>
                        <div
                            className="dropdown-item"
                            style={{ fontWeight: "bold", alignItems: "center" }}
                            onClick={() => {
                                setdataEntitie(selectedRow.original);
                                setStatusLicence("bloq");
                                setSelectedEntity(selectedRow.original);
                                setIsLicenseModalOpened(!isLicenseModalOpened);

                                if (selectedRow.original.infoLicence !== "") {
                                    const LicenceSelected = JSON.parse(
                                        decryptWord(
                                            selectedRow.original.infoLicence
                                        )
                                    );

                                    setUpdatedLicence(LicenceSelected);
                                    setLicenseBeforeUpdate(LicenceSelected);
                                    setKeysLicenseBeforeUpdate(
                                        Object.keys(LicenceSelected)
                                    );

                                    Object.keys(LicenceSelected).forEach(
                                        (el: any) => {
                                            const elementUuid = el.split("|");
                                            Data[elementUuid[1]] = {
                                                actived:
                                                    LicenceSelected[el].actived,
                                                blocked:
                                                    LicenceSelected[el].blocked,
                                            };
                                            LicenceSelected[
                                                el
                                            ].managementRules.forEach(
                                                (el1: any) => {
                                                    DatatypeEconomicModel[
                                                        elementUuid[1]
                                                    ] = {
                                                        typeeconomicModel:
                                                            LicenceSelected[el]
                                                                .managementRules,
                                                    };
                                                }
                                            );
                                            DataDemo[elementUuid[1]] = {
                                                isDemo:
                                                    LicenceSelected[el]
                                                        .demoConfiguration[
                                                        "isActivated"
                                                    ],
                                            };
                                        }
                                    );
                                    setSelectedExistLicenceDemo(DataDemo);
                                    setSelectedLicences(Data);
                                    setSelectedTypeEconomicModel(
                                        DatatypeEconomicModel
                                    );
                                } else {
                                    setLicenseBeforeUpdate({});
                                    setSelectedLicences({});
                                    setUpdatedLicence({});
                                    setSelectedTypeEconomicModel({});
                                    setSelectedExistLicenceDemo({});
                                    setKeysLicenseBeforeUpdate([]);
                                }
                            }}
                        >
                            <img
                                src={BloqIcon}
                                role="button"
                                id="BloqButton"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                alt=""
                            />
                            Bloquer licence(s)
                        </div>
                        <div
                            className="dropdown-item"
                            style={{ fontWeight: "bold", alignItems: "center" }}
                            onClick={() => {
                                setIsLicenseModalOpened(!isLicenseModalOpened);
                                setStatusLicence("debloq");
                                setSelectedEntity(selectedRow.original);
                                setdataEntitie(selectedRow.original);
                                setIsLicenseModalOpened(!isLicenseModalOpened);
                                if (selectedRow.original.infoLicence !== "") {
                                    const LicenceSelected = JSON.parse(
                                        decryptWord(
                                            selectedRow.original.infoLicence
                                        )
                                    );
                                    setLicenseBeforeUpdate(LicenceSelected);
                                    setUpdatedLicence(LicenceSelected);
                                    setLicenseBeforeUpdate(LicenceSelected);
                                    setKeysLicenseBeforeUpdate(
                                        Object.keys(LicenceSelected)
                                    );
                                    Object.keys(LicenceSelected).forEach(
                                        (el: any) => {
                                            const elementUuid = el.split("|");
                                            Data[elementUuid[1]] = {
                                                actived:
                                                    LicenceSelected[el].actived,
                                                blocked:
                                                    LicenceSelected[el].blocked,
                                            };
                                            LicenceSelected[
                                                el
                                            ].managementRules.forEach(
                                                (el1: any) => {
                                                    DatatypeEconomicModel[
                                                        elementUuid[1]
                                                    ] = {
                                                        typeeconomicModel:
                                                            LicenceSelected[el]
                                                                .managementRules,
                                                    };
                                                }
                                            );

                                            DataDemo[elementUuid[1]] = {
                                                isDemo:
                                                    LicenceSelected[el]
                                                        .demoConfiguration[
                                                        "isActivated"
                                                    ],
                                            };
                                            if (
                                                LicenceSelected[el].actived ===
                                                0
                                            ) {
                                                arraydataTodebloq.push(el);
                                            } else {
                                                arraydataTodebloq2.push(el);
                                            }
                                        }
                                    );
                                    let array: any = [];
                                    arraydataTodebloq2.forEach((el2: any) => {
                                        const elementUuid = el2.split("|");
                                        arraydataTodebloq.forEach(
                                            (el3: any) => {
                                                const elementUuid1 = el3.split(
                                                    "|"
                                                );
                                                if (
                                                    elementUuid[0] ===
                                                    elementUuid1[0]
                                                ) {
                                                    array.push(elementUuid1[1]);
                                                }
                                            }
                                        );
                                    });
                                    setLicenceToDebloq(array);
                                    setSelectedLicences(Data);
                                    setSelectedTypeEconomicModel(
                                        DatatypeEconomicModel
                                    );
                                    setSelectedExistLicenceDemo(DataDemo);
                                } else {
                                    setLicenseBeforeUpdate({});
                                    setSelectedLicences({});
                                    setUpdatedLicence({});
                                    setSelectedTypeEconomicModel({});
                                    setSelectedExistLicenceDemo({});
                                    setKeysLicenseBeforeUpdate([]);
                                }
                            }}
                        >
                            <img
                                src={DebloqLicenceIcon}
                                role="button"
                                id="DebloqLicenceButton"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                alt=""
                            />
                            Débloquer licence(s)
                        </div>
                        <div
                            className="dropdown-item"
                            style={{ fontWeight: "bold", alignItems: "center" }}
                            onClick={() => {
                                // setIsHidden(!isHidden);
                                setSelectedAccount(selectedRow.original);
                                setIsConfigurationBlocageModalOpened(
                                    !isConfigurationBlocageModalOpened
                                );
                            }}
                        >
                            <img
                                src={BloqIcon}
                                role="button"
                                id="BloqCompteButton"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                alt=""
                            />
                            Bloquer compte
                        </div>
                        <div
                            className="dropdown-item"
                            style={{ fontWeight: "bold", alignItems: "center" }}
                            onClick={() => {
                                setActionMotifModal("Archivage");
                                setSelectedAccount(selectedRow.original);
                                setIsMotifModalOpened(!isMotifModalOpened);
                            }}
                        >
                            <img
                                src={DeleteIcon}
                                role="button"
                                id="ArchiveCompteButton"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                alt=""
                            />
                            Archiver compte
                        </div>
                        <div
                            className="dropdown-item d-flex" //justify-content-between
                            style={{ fontWeight: "bold", alignItems: "center" }}
                            // onClick={() => navigateToHistoryAccount()}
                            onClick={() => {
                                setSelectedAccount(selectedRow.original);
                                setIsHistoryModalOpened(!isHistoryModalOpened);
                            }}
                        >
                            <img
                                src={HistoryIcon}
                                role="button"
                                id="HistoryButton"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                alt=""
                            />
                            Historique
                            {hiddenAnomalyIcon ? (
                                <img
                                    src={anomalyIcon}
                                    role="button"
                                    id="dropdownMenuButton1"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    alt=""
                                />
                            ) : null}
                        </div>
                    </div>
                </div>
                <HistoryModal
                    isModalOpened={isHistoryModalOpened}
                    setIsModalOpened={setIsHistoryModalOpened}
                />

                <LicensesModal
                    statusLicense={statusLicence}
                    isModalOpened={isLicenseModalOpened}
                    setIsModalOpened={setIsLicenseModalOpened}
                    dataEntitie={dataEntitie}
                    updatedLicence={updatedLicence}
                    setDataIsUpdated={setDataIsUpdated}
                    dataIsUpdated={dataIsUpdated}
                    setStatusLicence={setStatusLicence}
                />

                <MotifModal
                    setMotif={() => {}}
                    isMotifModalOpened={isMotifModalOpened}
                    setIsMotifModalOpened={setIsMotifModalOpened}
                    typeMotif={typeMotif}
                    setMotifAnnomalie={() => {}}
                    Action={actionMotifModal}
                    setDataIsUpdated={setDataIsUpdated}
                    dataIsUpdated={dataIsUpdated}
                    selectedRows={[]}
                    etatProfil=""
                    setIsConfigurationBlocageModalOpened={
                        setIsConfigurationBlocageModalOpened
                    }
                    isConfigurationBlocageModalOpened={
                        isConfigurationBlocageModalOpened
                    }
                    setAnomalie={() => {}}
                />
                <ConfigurationBlocageModal
                    isConfigurationBlocageModalOpened={
                        isConfigurationBlocageModalOpened
                    }
                    setIsConfigurationBlocageModalOpened={
                        setIsConfigurationBlocageModalOpened
                    }
                    setDataIsUpdated={setDataIsUpdated}
                    dataIsUpdated={dataIsUpdated}
                />
            </div>
        );
    }


    React.useEffect(() => {
        if (filterActive) {
            setIsHidden(false);
        }
    }, [filterActive]);

    return (
        <DynamicTable
            url={url}
            actionColumn={SelectAccountDropdown}
            customJsxSideFilterButton={
                isHidden === true || filterActive === true ? (
                    <StyledIconButton
                        icon="PlusIcon"
                        disabled={filterActive ? true : false}
                        style={{
                            background: "#323333",
                            border: "none",
                            borderColor: "#323333",
                            borderRadius: "6px",
                            display: "flex",
                            padding: "12px 10px",
                        }}
                    >
                        <PlusIcon
                            height={20}
                            width={20}
                            style={{ fill: "#fff" }}
                        />
                    </StyledIconButton>
                ) : filtreTableAccount !== "Activé" ? null : (
                    <div className="d-flex align-items-center mt-2 mx-2 mb-2">
                        <AvForm
                            onSubmit={() => {
                                setLocalFilterActive(false);
                                setIsHidden(!isHidden);
                                setSelectedAccount("");
                            }}
                        >
                            <StyledButtonWithIcon
                                disabled={false}
                                hidden={btnValiderhidden}
                                icon="PlusIcon"
                                iconPosition="left"
                                rounded
                                variant="primary"
                            >
                                Ajouter un compte client
                            </StyledButtonWithIcon>
                        </AvForm>
                    </div>
                )
            }
            canSelect
            canResize
            customSelect
            showGlobalFilter
            showFilter
            filterActive={filterActive}
            setLocalFilterActive={setLocalFilterActive}
            arrayOfCustomColumns={
                filtreTableAccount === "Activé"
                    ? arrayOfCustomColumnsActiveAccount
                    : arrayOfCustomColumns
            }
            setDataIsUpdated={setDataIsUpdated}
            dataIsUpdated={dataIsUpdated}
            minHeight={`calc(100vh - (500px + ${isVisible ? "-71px" : "0px"}))`}
            maxHeight={`calc(100vh - (500px + ${
                !isVisible ? "100px" : "0px"
            }))`}
        />
    );
}
