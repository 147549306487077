import React from "react";
import {
    DynamicTable,
    customColumnProps,
} from "@maherunlocker/custom-react-table";
import Switch from "react-switch";
import { setDataProject } from "../store";
import { useTranslation } from "react-i18next";
import { CustomSideFilter } from "../ModalContentWrapper/CustomSideFilter";
import { useSnapshot } from "valtio";
import { store } from "@pages/store";
import "./index.css";

type PropsType = {
    setlistSelectedData: React.Dispatch<React.SetStateAction<any>>;
};

export function ModalContentWrapper({
    setlistSelectedData,
}: PropsType): JSX.Element {
    // const uuidEntity = localStorage.getItem("Uid_Entitie_User");
    const { t } = useTranslation();
    const {
        SelectedProfil,
        editActionProfile,
        autorisationProfileEntityConnected,
        isVisible,
    } = useSnapshot(store);

    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const [isDataUpdated, setIsDataUpdated] = React.useState<boolean | number>(
        false
    );
    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
    const [data, setData] = React.useState<any>({});
    const apiUrl = `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/AccessAutorisation`;
    let object: any = {};
    let Data: any = {};
    function getStructure(data: any, object: any) {
        data.forEach((element: any) => {
            object[element.id] = {
                Id: element.id,
                IdParent: element.idParent,
                Visible: element.Visible,
                Consult: element.Consult,
                Modifie: element.Modifie,
            };
            if (element.subRows !== undefined && element.subRows.length !== 0) {
                getStructure(element.subRows, object);
            }
        });
        return object;
    }

    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 2,
            columnName: t("Visibilité"),
            customJsx: VisibilityColmun,
        },
        {
            indexOFColumn: 3,
            columnName: t("Consultation"),
            customJsx: ConsultColmun,
        },
        {
            indexOFColumn: 4,
            columnName: t("Modification"),
            customJsx: EditColmun,
        },
    ];

    function VisibilityColmun({ selectedRow }: any) {
        const [ischeckedVisibility, setIsCheckedVisibility] = React.useState(
            false
        );

        return (
            <div className="d-flex align-items-center ">
                <Switch
                    onChange={() => {
                        setIsCheckedVisibility(!ischeckedVisibility);
                        if (editActionProfile) {
                            if (
                                SelectedProfil.Autorisations[selectedRow.id]
                                    .Visible === true
                            ) {
                                if (ischeckedVisibility === false) {
                                    Data[selectedRow.id] = {
                                        Id: selectedRow.id,
                                        IdParent: selectedRow.idParent,
                                        Visible: false,
                                        Consult:
                                            Data[selectedRow.id] !== undefined
                                                ? Data[selectedRow.id].Consult
                                                : SelectedProfil.Autorisations[
                                                      selectedRow.id
                                                  ].Consult,
                                        Modifie:
                                            Data[selectedRow.id] !== undefined
                                                ? Data[selectedRow.id].Modifie
                                                : SelectedProfil.Autorisations[
                                                      selectedRow.id
                                                  ].Modifie,
                                    };
                                } else {
                                    Data[selectedRow.id] = {
                                        Id: selectedRow.id,
                                        IdParent: selectedRow.idParent,
                                        Visible: true,
                                        Consult:
                                            Data[selectedRow.id] !== undefined
                                                ? Data[selectedRow.id].Consult
                                                : SelectedProfil.Autorisations[
                                                      selectedRow.id
                                                  ].Consult,
                                        Modifie:
                                            Data[selectedRow.id] !== undefined
                                                ? Data[selectedRow.id].Modifie
                                                : SelectedProfil.Autorisations[
                                                      selectedRow.id
                                                  ].Modifie,
                                    };
                                }
                            } else {
                                if (ischeckedVisibility === false) {
                                    Data[selectedRow.id] = {
                                        Id: selectedRow.id,
                                        IdParent: selectedRow.idParent,
                                        Visible: true,
                                        Consult:
                                            Data[selectedRow.id] !== undefined
                                                ? Data[selectedRow.id].Consult
                                                : SelectedProfil.Autorisations[
                                                      selectedRow.id
                                                  ].Consult,
                                        Modifie:
                                            Data[selectedRow.id] !== undefined
                                                ? Data[selectedRow.id].Modifie
                                                : SelectedProfil.Autorisations[
                                                      selectedRow.id
                                                  ].Modifie,
                                    };
                                } else {
                                    Data[selectedRow.id] = {
                                        Id: selectedRow.id,
                                        IdParent: selectedRow.idParent,
                                        Visible: false,
                                        Consult:
                                            Data[selectedRow.id] !== undefined
                                                ? Data[selectedRow.id].Consult
                                                : SelectedProfil.Autorisations[
                                                      selectedRow.id
                                                  ].Consult,
                                        Modifie:
                                            Data[selectedRow.id] !== undefined
                                                ? Data[selectedRow.id].Modifie
                                                : SelectedProfil.Autorisations[
                                                      selectedRow.id
                                                  ].Modifie,
                                    };
                                }
                            }
                        } else {
                            if (ischeckedVisibility === false) {
                                Data[selectedRow.id] = {
                                    Id: selectedRow.id,
                                    IdParent: selectedRow.idParent,
                                    Visible: true,
                                    Consult:
                                        Data[selectedRow.id] === undefined
                                            ? false
                                            : Data[selectedRow.id].Consult,
                                    Modifie:
                                        Data[selectedRow.id] === undefined
                                            ? false
                                            : Data[selectedRow.id].Modifie,
                                };
                            } else {
                                Data[selectedRow.id] = {
                                    Id: selectedRow.id,
                                    IdParent: selectedRow.idParent,
                                    Visible: false,
                                    Consult:
                                        Data[selectedRow.id] === undefined
                                            ? false
                                            : Data[selectedRow.id].Consult,
                                    Modifie:
                                        Data[selectedRow.id] === undefined
                                            ? false
                                            : Data[selectedRow.id].Modifie,
                                };
                            }
                        }

                        setlistSelectedData(Data);
                    }}
                    checked={
                        editActionProfile
                            ? SelectedProfil.Autorisations[selectedRow.id]
                                  .Visible === true
                                ? !ischeckedVisibility
                                : ischeckedVisibility
                            : ischeckedVisibility
                    }
                    disabled={
                        autorisationProfileEntityConnected[selectedRow.id] !==
                            undefined &&
                        autorisationProfileEntityConnected[selectedRow.id]
                            .Visible === false
                            ? true
                            : false
                    }
                    offColor="#E30613"
                    onColor="#34C38F"
                />
            </div>
        );
    }

    function ConsultColmun({ selectedRow }: any) {
        const [ischeckedConsult, setIsCheckedConsult] = React.useState(false);

        return (
            <div className="d-flex align-items-center">
                <Switch
                    onChange={() => {
                        setIsCheckedConsult(!ischeckedConsult);
                        if (editActionProfile) {
                            if (
                                SelectedProfil.Autorisations[selectedRow.id]
                                    .Consult === true
                            ) {
                                if (ischeckedConsult === false) {
                                    Data[selectedRow.id] = {
                                        Id: selectedRow.id,
                                        IdParent: selectedRow.idParent,
                                        Visible:
                                            Data[selectedRow.id] !== undefined
                                                ? Data[selectedRow.id].Visible
                                                : SelectedProfil.Autorisations[
                                                      selectedRow.id
                                                  ].Visible,
                                        Consult: false,

                                        Modifie:
                                            Data[selectedRow.id] !== undefined
                                                ? Data[selectedRow.id].Modifie
                                                : SelectedProfil.Autorisations[
                                                      selectedRow.id
                                                  ].Modifie,
                                    };
                                } else {
                                    Data[selectedRow.id] = {
                                        Id: selectedRow.id,
                                        IdParent: selectedRow.idParent,
                                        Visible:
                                            Data[selectedRow.id] !== undefined
                                                ? Data[selectedRow.id].Visible
                                                : SelectedProfil.Autorisations[
                                                      selectedRow.id
                                                  ].Visible,
                                        Consult: true,
                                        Modifie:
                                            Data[selectedRow.id] !== undefined
                                                ? Data[selectedRow.id].Modifie
                                                : SelectedProfil.Autorisations[
                                                      selectedRow.id
                                                  ].Modifie,
                                    };
                                }
                            } else {
                                if (ischeckedConsult === false) {
                                    Data[selectedRow.id] = {
                                        Id: selectedRow.id,
                                        IdParent: selectedRow.idParent,
                                        Visible:
                                            Data[selectedRow.id] !== undefined
                                                ? Data[selectedRow.id].Visible
                                                : SelectedProfil.Autorisations[
                                                      selectedRow.id
                                                  ].Visible,
                                        Consult: true,
                                        Modifie:
                                            Data[selectedRow.id] !== undefined
                                                ? Data[selectedRow.id].Modifie
                                                : SelectedProfil.Autorisations[
                                                      selectedRow.id
                                                  ].Modifie,
                                    };
                                } else {
                                    Data[selectedRow.id] = {
                                        Id: selectedRow.id,
                                        IdParent: selectedRow.idParent,
                                        Visible:
                                            Data[selectedRow.id] !== undefined
                                                ? Data[selectedRow.id].Visible
                                                : SelectedProfil.Autorisations[
                                                      selectedRow.id
                                                  ].Visible,
                                        Consult: false,
                                        Modifie:
                                            Data[selectedRow.id] !== undefined
                                                ? Data[selectedRow.id].Modifie
                                                : SelectedProfil.Autorisations[
                                                      selectedRow.id
                                                  ].Modifie,
                                    };
                                }
                            }
                        } else {
                            if (ischeckedConsult === false) {
                                Data[selectedRow.id] = {
                                    Id: selectedRow.id,
                                    IdParent: selectedRow.idParent,
                                    Visible:
                                        Data[selectedRow.id] === undefined
                                            ? false
                                            : Data[selectedRow.id].Visible,
                                    Consult: true,

                                    Modifie:
                                        Data[selectedRow.id] === undefined
                                            ? false
                                            : Data[selectedRow.id].Modifie,
                                };
                            } else {
                                Data[selectedRow.id] = {
                                    Id: selectedRow.id,
                                    IdParent: selectedRow.idParent,
                                    Visible:
                                        Data[selectedRow.id] === undefined
                                            ? false
                                            : Data[selectedRow.id].Visible,
                                    Consult: false,
                                    Modifie:
                                        Data[selectedRow.id] === undefined
                                            ? false
                                            : Data[selectedRow.id].Modifie,
                                };
                            }
                        }
                        setlistSelectedData(Data);
                    }}
                    checked={
                        editActionProfile
                            ? SelectedProfil.Autorisations[selectedRow.id]
                                  .Consult === true
                                ? !ischeckedConsult
                                : ischeckedConsult
                            : ischeckedConsult
                    }
                    disabled={
                        autorisationProfileEntityConnected[selectedRow.id] !==
                            undefined &&
                        autorisationProfileEntityConnected[selectedRow.id]
                            .Consult === false
                            ? true
                            : false
                    }
                    offColor="#E30613"
                    onColor="#34C38F"
                />
            </div>
        );
    }

    function EditColmun({ selectedRow }: any) {
        const [ischeckedEditColmun, setIsCheckedEditColmun] = React.useState(
            false
        );
        return (
            <div className="d-flex align-items-center ">
                <Switch
                    onChange={() => {
                        setIsCheckedEditColmun(!ischeckedEditColmun);
                        if (editActionProfile) {
                            if (
                                SelectedProfil.Autorisations[selectedRow.id]
                                    .Modifie === true
                            ) {
                                if (ischeckedEditColmun === false) {
                                    Data[selectedRow.id] = {
                                        Id: selectedRow.id,
                                        IdParent: selectedRow.idParent,
                                        Visible:
                                            Data[selectedRow.id] !== undefined
                                                ? Data[selectedRow.id].Visible
                                                : SelectedProfil.Autorisations[
                                                      selectedRow.id
                                                  ].Visible,
                                        Consult:
                                            Data[selectedRow.id] !== undefined
                                                ? Data[selectedRow.id].Consult
                                                : SelectedProfil.Autorisations[
                                                      selectedRow.id
                                                  ].Consult,
                                        Modifie: false,
                                    };
                                } else {
                                    Data[selectedRow.id] = {
                                        Id: selectedRow.id,
                                        IdParent: selectedRow.idParent,
                                        Visible:
                                            Data[selectedRow.id] !== undefined
                                                ? Data[selectedRow.id].Visible
                                                : SelectedProfil.Autorisations[
                                                      selectedRow.id
                                                  ].Visible,
                                        Consult:
                                            Data[selectedRow.id] !== undefined
                                                ? Data[selectedRow.id].Consult
                                                : SelectedProfil.Autorisations[
                                                      selectedRow.id
                                                  ].Consult,
                                        Modifie: true,
                                    };
                                }
                            } else {
                                if (ischeckedEditColmun === false) {
                                    Data[selectedRow.id] = {
                                        Id: selectedRow.id,
                                        IdParent: selectedRow.idParent,
                                        Visible:
                                            Data[selectedRow.id] !== undefined
                                                ? Data[selectedRow.id].Visible
                                                : SelectedProfil.Autorisations[
                                                      selectedRow.id
                                                  ].Visible,
                                        Consult:
                                            Data[selectedRow.id] !== undefined
                                                ? Data[selectedRow.id].Consult
                                                : SelectedProfil.Autorisations[
                                                      selectedRow.id
                                                  ].Consult,
                                        Modifie: true,
                                    };
                                } else {
                                    Data[selectedRow.id] = {
                                        Id: selectedRow.id,
                                        IdParent: selectedRow.idParent,
                                        Visible:
                                            Data[selectedRow.id] !== undefined
                                                ? Data[selectedRow.id].Visible
                                                : SelectedProfil.Autorisations[
                                                      selectedRow.id
                                                  ].Visible,
                                        Consult:
                                            Data[selectedRow.id] !== undefined
                                                ? Data[selectedRow.id].Consult
                                                : SelectedProfil.Autorisations[
                                                      selectedRow.id
                                                  ].Consult,
                                        Modifie: false,
                                    };
                                }
                            }
                        } else {
                            if (ischeckedEditColmun === false) {
                                Data[selectedRow.id] = {
                                    Id: selectedRow.id,
                                    IdParent: selectedRow.idParent,
                                    Visible:
                                        Data[selectedRow.id] === undefined
                                            ? false
                                            : Data[selectedRow.id].Visible,
                                    Consult:
                                        Data[selectedRow.id] === undefined
                                            ? false
                                            : Data[selectedRow.id].Consult,
                                    Modifie: true,
                                };
                            } else {
                                Data[selectedRow.id] = {
                                    Id: selectedRow.id,
                                    IdParent: selectedRow.idParent,
                                    Visible:
                                        Data[selectedRow.id] === undefined
                                            ? false
                                            : Data[selectedRow.id].Visible,
                                    Consult:
                                        Data[selectedRow.id] === undefined
                                            ? false
                                            : Data[selectedRow.id].Consult,
                                    Modifie: false,
                                };
                            }
                        }
                        setlistSelectedData(Data);
                    }}
                    checked={
                        editActionProfile
                            ? SelectedProfil.Autorisations[selectedRow.id]
                                  .Modifie === true
                                ? !ischeckedEditColmun
                                : ischeckedEditColmun
                            : ischeckedEditColmun
                    }
                    disabled={
                        autorisationProfileEntityConnected[selectedRow.id] !==
                            undefined &&
                        autorisationProfileEntityConnected[selectedRow.id]
                            .Modifie === false
                            ? true
                            : false
                    }
                    offColor="#E30613"
                    onColor="#34C38F"
                />
            </div>
        );
    }

    React.useEffect(() => {
        if (data.data !== undefined) {
            getStructure(data.data, object);
            setDataProject(object);
        }
        // eslint-disable-next-line
    }, [data]);
    return (
        <DynamicTable
            minHeight={`calc(100vh - (500px + ${isVisible ? "-71px" : "0px"}))`}
            maxHeight={`calc(100vh - (500px + ${
                !isVisible ? "100px" : "0px"
            }))`}
            url={apiUrl}
            setData={setData}
            canResize={true}
            filterActive={filterActive}
            setLocalFilterActive={setLocalFilterActive}
            customJsxSideFilterButton={
                <CustomSideFilter selectedRows={selectedRows} />
            }
            customSelect={true}
            canSelect={true}
            showGlobalFilter={true}
            canExpand={true}
            showFilter={true}
            arrayOfCustomColumns={arrayOfCustomColumns}
            setDataIsUpdated={setIsDataUpdated}
            dataIsUpdated={isDataUpdated}
            setSelectedRows={setSelectedRows}
            canMovedCheckboxLeftOnExpand
        />
    );
}
