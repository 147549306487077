import React from "react";
import { useTranslation } from "react-i18next";
import {
    ShareIcon,
    VerticalDotsIcon,
    DocumentIcon,
    DownloadIcon,
} from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";

import {
    setisAlertHistoryLicencesModal,
    setIsAlertSendCertificateLicenceWithMailOpened,
    setIsAlertGenerateCertificateModal,
    setIsCommercialLicenceModalOpened,
    setIsConfigurationLicenceModalOpened,
    store,
} from "@pages/store";

import { HistoryLicenceModal } from "./HistoryLicenceModal";
import { ConfigurationDemoModal } from "./ConfigurationDemoModal";
import AlertConfirmCommercialModal from "./AlertConfirmCommercialModal";
import AlertSendCertificateLicenceWithMail from "./AlertSendCertificateLicenceWithMail";
import AlertConfirmGenerateCertificate from "../Licenses/AlertConfirmGenerateCertificate";
import { showToast } from "@components/Common/ToastShow";

import { mutate } from "swr";
import { saveAs } from "file-saver";

type tableLicencesActionsMenuType = {
    statusLicense: string;
};

export default function LicencesActionsMenu({
    statusLicense,
}: tableLicencesActionsMenuType) {
    const {
        selectedEntity,
        isAlertHistoryLicencesModal,
        isConfigurationLicenceModalOpened,
        isCommercialLicenceModalOpened,
        isAlertSendCertificateLicenceWithMailOpened,
        isUpdateInfoCertificate,
        isAlertGenerateCertificateModal,
    } = useSnapshot(store);

    const { t } = useTranslation();

    async function handleUploadingNewFileRequest() {
        const apiUrl = `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/upload`;
        mutate(
            apiUrl,
            await fetch(apiUrl, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    uuidEntity: selectedEntity.Id,
                }),
            })
                .then((response) => response.json())
                .then((data) => {
                    var blob = new Blob([`${data.message.dataCertificate}`], {
                        type: "text/plain;charset=utf-8",
                    });
                    saveAs(blob, `${data.message.nameCertificate}.certif`);

                    if (data.error) {
                        throw Error(data.message);
                    }

                    return showToast(
                        true,
                        "Le téléchargement du certificat a été effectué avec succès"
                    );
                })
                .catch((error) => {
                    return showToast(
                        false,
                        "Erreur de téléchargement du certificat. Veuillez réessayer"
                    );
                })
        );
    }

    if (statusLicense === "add") {
        return (
            <div className="dropdown ml-2  d-none d-sm-block">
                <VerticalDotsIcon
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    cursor="pointer"
                    height={25}
                    width={25}
                />
                <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                >
                    <div
                        className="dropdown-item"
                        role="button"
                        tabIndex={0}
                        style={{
                            cursor: "pointer",
                            pointerEvents:
                                selectedEntity.infoLicence === ""
                                    ? "none"
                                    : "auto",
                            opacity:
                                selectedEntity.infoLicence === "" ? "0.4" : "",
                        }}
                        onClick={() => {
                            setisAlertHistoryLicencesModal(
                                !isAlertHistoryLicencesModal
                            );
                        }}
                    >
                        <DocumentIcon height={25} width={25} className="mr-2" />
                        {t("Historique certificat")}
                    </div>
                    <div
                        className="dropdown-item"
                        role="button"
                        tabIndex={0}
                        onClick={() => {
                            handleUploadingNewFileRequest();
                        }}
                        style={{
                            cursor: "pointer",
                            pointerEvents:
                                selectedEntity.infoLicence === "" ||
                                isUpdateInfoCertificate === true
                                    ? "none"
                                    : "auto",
                            opacity:
                                selectedEntity.infoLicence === "" ||
                                isUpdateInfoCertificate === true
                                    ? "0.4"
                                    : "",
                        }}
                    >
                        <DownloadIcon className="mr-2" height={25} width={25} />
                        Télecharger Le certificat
                    </div>

                    <div
                        className="dropdown-item"
                        role="button"
                        tabIndex={0}
                        style={{
                            cursor: "pointer",
                            pointerEvents:
                                selectedEntity.infoLicence === "" ||
                                isUpdateInfoCertificate === true
                                    ? "none"
                                    : "auto",
                            opacity:
                                selectedEntity.infoLicence === "" ||
                                isUpdateInfoCertificate === true
                                    ? "0.4"
                                    : "",
                        }}
                        onClick={() => {
                            setIsAlertSendCertificateLicenceWithMailOpened(
                                !isAlertSendCertificateLicenceWithMailOpened
                            );
                        }}
                    >
                        <ShareIcon className="mr-2" height={25} width={25} />
                        Envoyer le certificat par mail
                    </div>
                </div>
                <HistoryLicenceModal
                    setisAlertHistoryLicencesModal={
                        setisAlertHistoryLicencesModal
                    }
                    isAlertHistoryLicencesModal={isAlertHistoryLicencesModal}
                />
                <ConfigurationDemoModal
                    isConfigurationModalOpened={
                        isConfigurationLicenceModalOpened
                    }
                    setIsConfigurationModalOpened={
                        setIsConfigurationLicenceModalOpened
                    }
                    setConfigurationDemo={() => {}}
                    ConfigurationBeforeUpdate={""}
                    setConfigurationBeforeUpdate={() => {}}
                    setDemo={() => {}}
                />
                <AlertConfirmCommercialModal
                    isCommercialLicenceModalOpened={
                        isCommercialLicenceModalOpened
                    }
                    setIsCommercialLicenceModalOpened={
                        setIsCommercialLicenceModalOpened
                    }
                />
                <AlertSendCertificateLicenceWithMail
                    isAlertSendCertificateLicenceWithMailOpened={
                        isAlertSendCertificateLicenceWithMailOpened
                    }
                    setIsAlertSendCertificateLicenceWithMailOpened={
                        setIsAlertSendCertificateLicenceWithMailOpened
                    }
                />
                <AlertConfirmGenerateCertificate
                    isAlertGenerateCertificateModal={
                        isAlertGenerateCertificateModal
                    }
                    setIsAlertGenerateCertificateModal={
                        setIsAlertGenerateCertificateModal
                    }
                />
            </div>
        );
    } else {
        return (
            <div>
                <AlertConfirmGenerateCertificate
                    isAlertGenerateCertificateModal={
                        isAlertGenerateCertificateModal
                    }
                    setIsAlertGenerateCertificateModal={
                        setIsAlertGenerateCertificateModal
                    }
                />
            </div>
        );
    }
}
