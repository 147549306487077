import React, { useState, useEffect } from "react";
import { showToast } from "@components/Common/ToastShow";
import AlerteModal from "./AlerteModalProfil";
import {
    DocumentIcon,
    EyeIcon,
    PortraitIcon,
    TrashIcon,
    VerticalDotsIcon,
} from "@aureskonnect/react-ui";
import { setHistoryProfile, setIsHitoryAllProducts } from "@pages/store";

type MenuActionProps = {
    setEtatProfil: React.Dispatch<React.SetStateAction<boolean>>;
    setDataIsUpdated: React.Dispatch<React.SetStateAction<number | boolean>>;
    dataIsUpdated: number | boolean;
    selectedRows: any;
    setSelectedRows: React.Dispatch<React.SetStateAction<any[]>>;
    etatProfil: boolean;
    isHistoryModalOpened: boolean;
    setIsHistoryModalOpened: Function;
    setIsAlerteProfileModalOpened: Function;
    isAlerteProfileModalOpened: boolean;
    setActionMotifModal: React.Dispatch<React.SetStateAction<string>>;
    actionMotifModal: string;
};

export default function MenuActionProfil({
    setEtatProfil,
    setDataIsUpdated,
    selectedRows,
    etatProfil,
    dataIsUpdated,
    isHistoryModalOpened,
    setIsHistoryModalOpened,
    setIsAlerteProfileModalOpened,
    isAlerteProfileModalOpened,
    setActionMotifModal,
    actionMotifModal,
}: MenuActionProps) {
    const [libBtnProfil, setLibBtnProfil] = useState<string>("archivés");
    const [countProfil, setCountProfil] = useState(0);
    const uidEntity = localStorage.getItem("Uid_Entitie_User");

    const getNombresProfils = () => {
        fetch(
            `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/getCountProfil?uidEntity=${uidEntity}`
        )
            .then((response) => {
                response.json().then((data) => {
                    if (libBtnProfil === "archivés") {
                        setCountProfil(Number(data.Archive));
                    } else {
                        setCountProfil(Number(data.Active));
                    }
                });
            })

            .catch((e: any) => {
                console.log({ error: e.message });
            });
    };

    function Toast() {
        setHistoryProfile(selectedRows);
        showToast(
            false,
            "Attention, c'est une action multiple, vous devez sélectionner au moins deux éléments."
        );
    }
    function showHistory() {
        setHistoryProfile(selectedRows);
        if (selectedRows.length < 2) {
            showToast(
                false,
                "Attention, c'est une action multiple, vous devez sélectionner au moins deux éléments."
            );
        } else {
            setIsHitoryAllProducts(true);
            setIsHistoryModalOpened(!isHistoryModalOpened);
        }
    }

    useEffect(() => {
        getNombresProfils();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRows]);

    return (
        <div>
            <div className="dropdown ml-2  d-none d-sm-block">
                <VerticalDotsIcon
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    cursor="pointer"
                    height={25}
                    width={25}
                />
                <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                    style={{
                        marginLeft: "-150px",
                    }}
                >
                    <div
                        className="dropdown-item"
                        style={{
                            fontWeight: "bold",
                            alignItems: "center",
                            cursor: "pointer",
                            pointerEvents:
                                libBtnProfil === "archivés" && countProfil === 0
                                    ? "none"
                                    : "auto",
                            opacity:
                                libBtnProfil === "archivés" && countProfil === 0
                                    ? "0.4"
                                    : "",
                        }}
                        onClick={() => {
                            if (libBtnProfil === "archivés") {
                                setEtatProfil(true);
                                setLibBtnProfil("activés");
                                setDataIsUpdated(true);
                            } else {
                                setEtatProfil(false);
                                setLibBtnProfil("archivés");
                                setDataIsUpdated(true);
                            }
                        }}
                    >
                        <PortraitIcon height={25} width={25} className="mr-2" />
                        Profils {libBtnProfil} ({countProfil})
                    </div>

                    {libBtnProfil === "archivés" ? (
                        <div
                            className="dropdown-item"
                            style={{
                                fontWeight: "bold",
                                alignItems: "center",
                                cursor: "pointer",
                            }}
                            onClick={() => {
                                setActionMotifModal("Archivage Profil");
                                setEtatProfil(false);
                                selectedRows.length < 2
                                    ? Toast()
                                    : setIsAlerteProfileModalOpened(
                                          !isAlerteProfileModalOpened
                                      );
                            }}
                        >
                            <TrashIcon
                                height={25}
                                width={25}
                                fill="red"
                                className="mr-2"
                            />
                            Archiver profil(s)
                        </div>
                    ) : (
                        <div
                            className="dropdown-item"
                            style={{
                                fontWeight: "bold",
                                alignItems: "center",
                                cursor: "pointer",
                            }}
                            onClick={() => {
                                setActionMotifModal("Activation Profil");
                                setEtatProfil(true);
                                selectedRows.length < 2
                                    ? Toast()
                                    : setIsAlerteProfileModalOpened(
                                          !isAlerteProfileModalOpened
                                      );
                            }}
                        >
                            <EyeIcon height={25} width={25} className="mr-2" />
                            Activer profil(s)
                        </div>
                    )}
                    <div
                        className="dropdown-item"
                        style={{
                            fontWeight: "bold",
                            alignItems: "center",
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            showHistory();
                        }}
                    >
                        <DocumentIcon height={25} width={25} className="mr-2" />
                        Historique
                    </div>
                </div>

                <AlerteModal
                    isAlerteModalOpened={isAlerteProfileModalOpened}
                    setIsAlerteModalOpened={setIsAlerteProfileModalOpened}
                    setDataIsUpdated={setDataIsUpdated}
                    dataIsUpdated={dataIsUpdated}
                    selectedRows={selectedRows}
                    actionMotifModal={actionMotifModal}
                    ArchiveActiveProfil={etatProfil}
                />
            </div>
        </div>
    );
}
