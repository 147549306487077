import React from "react";
import {
    CrossIcon,
    StyledButton,
    StyledIconButton,
    StyledLabel,
    StyledModal,
} from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";

import TableMotifsHistory from "./TableMotifsHistory";

import { store } from "../store";
import "../index.css";

type ListHistoryModalType = {
    isModalOpened: boolean;
    setIsModalOpened: Function;
    selectedRows: any;
};

export function HistoryMotifsModal(props: ListHistoryModalType) {
    const { t } = useTranslation();
    const { selectedMotif } = useSnapshot(store);
    const storeShot = useSnapshot(store);
    let motifName = "";
    if (selectedMotif !== undefined) {
        motifName = selectedMotif.Motif;
    }
    const colorBreadcrumb = "#E5E4E4AB";

    return (
        <React.Fragment>
            <StyledModal
                className="history-modal-style"
                isOpen={props.isModalOpened}
            >
                <ModalHeader className="d-flex align-items-center">
                    <div
                        className="d-flex align-items-center justify-content-between"
                        style={{
                            height: "35px",
                            display: "grid",
                            gridTemplateColumns: "1.8fr 0.2fr",
                            gridTemplateRows: "1fr",
                            gap: "1210px",
                        }}
                    >
                        <span className="title-modal-style">
                            {t("HISTORIQUE MOTIF(S)")}
                        </span>
                        <StyledIconButton
                            icon="CrossIcon"
                            onClick={() => {
                                props.setIsModalOpened(!props.isModalOpened);
                            }}
                        >
                            <CrossIcon
                                cursor="pointer"
                                height={10}
                                width={10}
                            />
                        </StyledIconButton>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <div>
                        {storeShot.isHitoryAllMotifs ? null : (
                            <div
                                style={{
                                    display: "grid",
                                    gridTemplateColumns: "1fr 1fr 1fr 1fr auto",
                                    gap: "20px",
                                    background: colorBreadcrumb,
                                    height: "56px",
                                    marginBottom: "5px",
                                    alignItems: "center",
                                }}
                            >
                                <div className="d-flex flex-row bd-highlight mb-3 mt-2">
                                    <div
                                        className="p-2 bd-highlight mt-1"
                                        style={{ width: "max-content" }}
                                    >
                                        <span
                                            style={{
                                                textAlign: "left",
                                                font:
                                                    " normal normal bold 20px/14px Segoe UI",
                                                color: "#2B2828",
                                                fontWeight: 600,
                                            }}
                                        >
                                            Motif :
                                        </span>
                                    </div>
                                    <div className="p-2 bd-highlight">
                                        <StyledLabel
                                            style={{
                                                fontWeight: "normal",
                                                width: "max-content",
                                            }}
                                        >
                                            {motifName}
                                        </StyledLabel>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    <div>
                        <TableMotifsHistory selectedRows={props.selectedRows} />
                    </div>
                </ModalBody>
                <ModalFooter className="d-flex justify-content-end">
                    <div className="d-flex align-items-center">
                        <div className="ml-3">
                            <StyledButton
                                disabled={false}
                                rounded
                                variant="light"
                                outline={true}
                                size="sm"
                                style={{
                                    background: "black",
                                    color: "white",
                                    padding: "0px",
                                    width: "150px",
                                    height: "45px",
                                }}
                                onClick={() =>
                                    props.setIsModalOpened(!props.isModalOpened)
                                }
                            >
                                {t("Close")}
                            </StyledButton>
                        </div>
                    </div>
                </ModalFooter>
            </StyledModal>
        </React.Fragment>
    );
}
