import React, { useEffect } from "react";
import {
    StyledButton,
    StyledModal,
    CrossIcon,
    StyledIconButton,
    StyledSelectInput,
    StyledLabel,
} from "@aureskonnect/react-ui";
import { ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import ReactSwitch from "react-switch";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { CalendarModal } from "./CalendarModal";
import { useSnapshot } from "valtio";
import {
    setAssociatedDemoLicence,
    setIsLicenceDemoConfiguration,
    setSelectedAccount,
    store,
} from "../../pages/store";
import "./index.css";

type ConfigurationDemoModalType = {
    isConfigurationModalOpened: boolean;
    setIsConfigurationModalOpened: Function;
    setConfigurationDemo: React.Dispatch<React.SetStateAction<any>>;
    setConfigurationBeforeUpdate: React.Dispatch<React.SetStateAction<any>>;
    ConfigurationBeforeUpdate: any;
    setDemo: React.Dispatch<React.SetStateAction<any>>;
};

export function ConfigurationDemoModal({
    isConfigurationModalOpened,
    setIsConfigurationModalOpened,
    setConfigurationDemo,
    ConfigurationBeforeUpdate,
    setConfigurationBeforeUpdate,
    setDemo,
}: ConfigurationDemoModalType) {
    const { t } = useTranslation();
    const { SelectedAccount } = useSnapshot(store);
    const [isRenouvelableAuto, setIsRenouvelableAuto] = React.useState<boolean>(
        false
    );

    const [isImprimDemoTicket, setIsImprimDemoTicket] = React.useState<boolean>(
        false
    );
    const [isPlafondEncaissement, setIsPlafondEncaissement] = React.useState<
        boolean
    >(false);

    const [
        plafondEncaissementValue,
        setPlafondEncaissementValue,
    ] = React.useState<string>("");
    const [, setFirstInputValue] = React.useState<string>("1");
    const [currentDate, setCurrentDate] = React.useState(
        moment().format("DD/MM/YYYY")
    );
    const [validityPeriod, setValidityPeriod] = React.useState<string>(
        currentDate
    );

    // const plafondEncValue = plafondEncaissementValue; //+ devise;
    const valueValidite = [
        {
            label: "1",
            value: "1",
        },
        {
            label: "2",
            value: "2",
        },
        {
            label: "3",
            value: "3",
        },
        {
            label: "4",
            value: "4",
        },
        {
            label: "5",
            value: "5",
        },
        {
            label: "6",
            value: "6",
        },
        {
            label: "7",
            value: "7",
        },
        {
            label: "8",
            value: "8",
        },
        {
            label: "9",
            value: "9",
        },
        {
            label: "10",
            value: "10",
        },
        {
            label: "11",
            value: "11",
        },
        {
            label: "12",
            value: "12",
        },
        {
            label: "Personnalisée..",
            value: "Personnalisée..",
        },
    ];
    const [isCalendarModalOpened, setIsCalendarModalOpened] = React.useState<
        boolean
    >(false);
    const [numberDays, setNumberDays] = React.useState<number>(30);
    const [periodLabel, setPeriodLabel] = React.useState<number>(1);
    const [optionValue, setOptionValue] = React.useState<any>({
        label: "1",
        value: "1",
    });
    const {
        selectedLicenceDemo,
        associatedDemoLicence,
        isLicenceDemoConfiguration,
    } = useSnapshot(store);
    let Data: any = {};
    const colorBreadcrumb = "#E5E4E4AB";
    const emptyFields = () => {
        setIsPlafondEncaissement(false);
        setIsImprimDemoTicket(false);
        setIsRenouvelableAuto(false);
        setPlafondEncaissementValue("");
        setFirstInputValue("1");
        setConfigurationBeforeUpdate("");
    };

    function handleAddConfiguration() {
        let configurationDemoAccount: any = {
            limitCollection: {
                active: isPlafondEncaissement,
                budget: plafondEncaissementValue,
            },
            duration: `${currentDate}|${validityPeriod}|${optionValue.value}`,
            automaticallyRenewable: isRenouvelableAuto,
            printsTicketDemo: isImprimDemoTicket,
        };
        setConfigurationDemo(configurationDemoAccount);
        emptyFields();
    }
    async function HandleAddConfigurationLicence() {
        if (selectedLicenceDemo.Type === "Pack") {
            if (selectedLicenceDemo.subRows.length > 0) {
                selectedLicenceDemo.subRows.forEach((el: any) => {
                    Data[el.iuud + "|" + el.uidPack] = {
                        isActivated: true,
                        date:
                            currentDate +
                            "|" +
                            validityPeriod +
                            "|" +
                            optionValue.value,
                    };
                });
            }
        } else {
            Data[selectedLicenceDemo.iuud + "|" + selectedLicenceDemo.iuud] = {
                isActivated: true,
                date:
                    currentDate +
                    "|" +
                    validityPeriod +
                    "|" +
                    optionValue.value,
            };
        }
        setAssociatedDemoLicence({ ...associatedDemoLicence, ...Data });
        setIsLicenceDemoConfiguration(!isLicenceDemoConfiguration);
    }
    function handlePlafondInputOnChangeEvent(
        e: React.ChangeEvent<HTMLInputElement>
    ) {
        setPlafondEncaissementValue(e.target.value);
        if (SelectedAccount !== "") {
            setSelectedAccount({
                ...SelectedAccount,
                limiteCollection: {
                    ...SelectedAccount.limiteCollection,
                    budget: e.target.value,
                },
            });
        }
    }
    function handleCloseDemoModal() {
        if (SelectedAccount === "") {
            setDemo(false);
        }
        // else {
        //     setDemo(false);
        //     setSelectedAccount({
        //         ...SelectedAccount,
        //         NoteDemoEtat: false,
        //     });
        // }
        setIsConfigurationModalOpened(!isConfigurationModalOpened);
        if (isLicenceDemoConfiguration) {
            setIsLicenceDemoConfiguration(!isLicenceDemoConfiguration);
        }
        emptyFields();
    }

    useEffect(() => {
        if (ConfigurationBeforeUpdate !== "") {
            setIsPlafondEncaissement(
                ConfigurationBeforeUpdate.limitCollection.active
            );
            setPlafondEncaissementValue(
                ConfigurationBeforeUpdate.limitCollection.budget
            );
            setValidityPeriod(ConfigurationBeforeUpdate.duration);
            setIsRenouvelableAuto(
                ConfigurationBeforeUpdate.automaticallyRenewable
            );
            setIsImprimDemoTicket(ConfigurationBeforeUpdate.printsTicketDemo);
            let DemoDuration = ConfigurationBeforeUpdate.duration.split("|");
            setValidityPeriod(DemoDuration[1]);
            setCurrentDate(DemoDuration[0]);
            setOptionValue({ label: DemoDuration[2], value: DemoDuration[2] });
        }
    }, [ConfigurationBeforeUpdate]);

    return (
        <div>
            <StyledModal
                className="configuration-modal-style"
                centered
                isOpen={isConfigurationModalOpened}
            >
                <ModalHeader>
                    <div
                        className="d-flex align-items-center justify-content-between "
                        style={{
                            height: "35px",
                            display: "grid",
                            gridTemplateColumns: "1.8fr 0.2fr",
                            gridTemplateRows: "1fr",
                            gap: "365px",
                        }}
                    >
                        <p className="title-text-style">
                            PARAMÉTRAGE
                            {isLicenceDemoConfiguration
                                ? " DE LA LICENCE DEMO"
                                : "DU COMPTE DÉMONSTRATION"}
                        </p>
                        <StyledIconButton icon="CrossIcon">
                            <CrossIcon
                                cursor="pointer"
                                height={10}
                                width={10}
                                onClick={() => {
                                    handleCloseDemoModal();
                                    // setIsConfigurationModalOpened(
                                    //     !isConfigurationModalOpened
                                    // );
                                    // emptyFields();
                                }}
                            />
                        </StyledIconButton>
                    </div>
                </ModalHeader>
                <div
                    style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr 0.4fr  auto",
                        gap: "20px",
                        background: colorBreadcrumb,
                        height: "46px",
                        marginBottom: "5px",
                        alignItems: "center",
                    }}
                >
                    <StyledLabel
                        style={{
                            marginLeft: "24px",
                            fontWeight: "bold",
                            fontSize: "15px",
                        }}
                    >
                        Date d'activation : {currentDate}
                    </StyledLabel>
                    <StyledLabel
                        style={{
                            marginLeft: "24px",
                            fontWeight: "bold",
                            fontSize: "15px",
                        }}
                    >
                        Date d'expiration : {validityPeriod}
                    </StyledLabel>
                </div>
                <ModalBody>
                    <fieldset
                        className="border p-3 title-legend.style"
                        style={{ width: "760px" }}
                    >
                        <legend className="w-auto">
                            Remplissez les champs
                        </legend>
                        <div
                            style={{
                                marginLeft: "15px",
                            }}
                        >
                            <div
                                className="row "
                                style={{
                                    display: "grid",
                                    gridTemplateColumns: "1fr 1fr",
                                    gridTemplateRows: "1fr",
                                    marginTop: "10px",
                                }}
                            >
                                <div
                                    style={{
                                        position: "relative",
                                    }}
                                >
                                    <div className="text-legend-style">
                                        Durée de validité (en Mois)
                                    </div>

                                    <div className="text-hover-legend-style">
                                        Saisir une valeur entre 1 et 12 Mois
                                    </div>
                                </div>
                                <div style={{ marginRight: "150px" }}>
                                    <StyledSelectInput
                                        onChange={(e: any) => {
                                            switch (Number(e.value)) {
                                                case 1:
                                                    setNumberDays(29);
                                                    break;
                                                case 2:
                                                    setNumberDays(59);
                                                    break;
                                                case 3:
                                                    setNumberDays(89);
                                                    break;
                                                case 4:
                                                    setNumberDays(119);
                                                    break;
                                                case 5:
                                                    setNumberDays(149);
                                                    break;
                                                case 6:
                                                    setNumberDays(179);
                                                    break;
                                                case 7:
                                                    setNumberDays(209);
                                                    break;
                                                case 8:
                                                    setNumberDays(239);
                                                    break;
                                                case 9:
                                                    setNumberDays(269);
                                                    break;
                                                case 10:
                                                    setNumberDays(299);
                                                    break;
                                                case 11:
                                                    setNumberDays(329);
                                                    break;
                                                case 12:
                                                    setNumberDays(364);
                                                    break;
                                                default:
                                                    setNumberDays(29);
                                            }
                                            setPeriodLabel(Number(e.value));
                                            setOptionValue({
                                                label: e.value,
                                                value: e.value,
                                            });
                                            setIsCalendarModalOpened(
                                                !isCalendarModalOpened
                                            );
                                        }}
                                        defaultValue={optionValue}
                                        name="dureeValidite"
                                        options={valueValidite}
                                        value={optionValue}
                                    />
                                </div>
                            </div>
                            <div
                                className="row "
                                style={{
                                    display: "grid",
                                    gridTemplateColumns: "2.5fr 0.4fr",
                                    gridTemplateRows: "1fr",
                                    marginTop: "10px",
                                }}
                            >
                                <div className="text-legend-style">
                                    Renouvelable automatiquement
                                </div>
                                <div>
                                    <ReactSwitch
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        handleDiameter={26}
                                        offColor="#f7b4b8"
                                        offHandleColor="#E30613"
                                        checked={isRenouvelableAuto} //{autorisations[index].consult}
                                        onChange={() => {
                                            setIsRenouvelableAuto(
                                                !isRenouvelableAuto
                                            );
                                            if (SelectedAccount !== "") {
                                                setSelectedAccount({
                                                    ...SelectedAccount,
                                                    limiteCollection: {
                                                        ...SelectedAccount.limiteCollection,
                                                        automaticallyRenewable: !isRenouvelableAuto,
                                                    },
                                                });
                                            }
                                        }}
                                        onColor="#c2eddd"
                                        onHandleColor="#34C38F"
                                        width={55}
                                        height={20}
                                    />
                                </div>
                            </div>

                            <div
                                className="row "
                                style={{
                                    display: "grid",
                                    gridTemplateColumns: "2.5fr 0.4fr",
                                    gridTemplateRows: "1fr",
                                    marginTop: "10px",
                                }}
                            >
                                <div
                                    style={{
                                        position: "relative",
                                    }}
                                >
                                    <div className="text-legend-style">
                                        Imprimer l'information "Démo" sur le
                                        ticket de caisse
                                    </div>

                                    <div className="text-hover-legend-style">
                                        Compte Démo. Licence à utilisation non
                                        commerciale
                                    </div>
                                </div>

                                <div>
                                    <ReactSwitch
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        handleDiameter={26}
                                        offColor="#f7b4b8"
                                        offHandleColor="#E30613"
                                        checked={isImprimDemoTicket}
                                        onChange={() => {
                                            setIsImprimDemoTicket(
                                                !isImprimDemoTicket
                                            );
                                            if (SelectedAccount !== "") {
                                                setSelectedAccount({
                                                    ...SelectedAccount,
                                                    limiteCollection: {
                                                        ...SelectedAccount.limiteCollection,
                                                        printsTicketDemo: !isImprimDemoTicket,
                                                    },
                                                });
                                            }
                                        }}
                                        onColor="#c2eddd"
                                        onHandleColor="#34C38F"
                                        width={55}
                                        height={20}
                                    />
                                </div>
                            </div>

                            <div
                                className="row "
                                style={{
                                    display: "grid",
                                    gridTemplateColumns:
                                        "2.5fr 2fr 0.73fr auto",
                                    gridTemplateRows: "1fr",
                                    marginTop: "6px",
                                    placeSelf: "center",
                                }}
                            >
                                <div
                                    className="text-legend-style"
                                    style={{ marginTop: "10px" }}
                                >
                                    {t(
                                        "Plafond d'encaissement autorisé (en €)"
                                    )}
                                </div>

                                <input
                                    style={{
                                        marginLeft: "20px",
                                        width: "166px",
                                        height: "40px",
                                        border: "1px solid #E3E3E3",
                                        borderRadius: "6px",
                                        opacity: "1",
                                    }}
                                    type="string"
                                    value={plafondEncaissementValue} //{plafondEncValue} //+ devise}
                                    id="plafondInput"
                                    name="plafondInput"
                                    min="1"
                                    max="30"
                                    onChange={handlePlafondInputOnChangeEvent}
                                />
                                <div
                                    style={{
                                        marginTop: "8px",
                                        marginLeft: "2px",
                                    }}
                                >
                                    <ReactSwitch
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        handleDiameter={26}
                                        offColor="#f7b4b8"
                                        offHandleColor="#E30613"
                                        checked={isPlafondEncaissement}
                                        onChange={() => {
                                            setIsPlafondEncaissement(
                                                !isPlafondEncaissement
                                            );
                                            if (SelectedAccount !== "") {
                                                setSelectedAccount({
                                                    ...SelectedAccount,
                                                    limiteCollection: {
                                                        ...SelectedAccount.limiteCollection,
                                                        active: !isPlafondEncaissement,
                                                    },
                                                });
                                            }
                                        }}
                                        onColor="#c2eddd"
                                        onHandleColor="#34C38F"
                                        width={55}
                                        height={20}
                                    />
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </ModalBody>

                <ModalFooter
                    className="d-flex justify-content-end"
                    style={{
                        background: "#FFFFFF",
                    }}
                >
                    <div
                        className="row "
                        style={{
                            display: "grid",
                            gridTemplateColumns: "2.5fr 2fr 0.3fr",
                            gridTemplateRows: "1fr",
                            marginTop: "10px",
                        }}
                    >
                        <StyledButton
                            disabled={false}
                            rounded
                            variant="light"
                            outline={true}
                            size="sm"
                            style={{
                                background: "white",
                                color: "black",
                                padding: "0px",
                                width: "150px",
                                height: "45px",
                            }}
                            onClick={() => {
                                handleCloseDemoModal();
                            }}
                        >
                            Annuler
                        </StyledButton>
                        <StyledButton
                            disabled={false}
                            rounded
                            variant="primary"
                            outline={false}
                            size="sm"
                            style={{
                                color: "white",
                                padding: "0px",
                                width: "150px",
                                height: "45px",
                            }}
                            onClick={() => {
                                if (isLicenceDemoConfiguration) {
                                    HandleAddConfigurationLicence();
                                    setIsPlafondEncaissement(false);
                                    setIsImprimDemoTicket(false);
                                    setIsRenouvelableAuto(false);
                                    setPlafondEncaissementValue("");
                                    setFirstInputValue("1");
                                    setConfigurationBeforeUpdate("");
                                    setOptionValue({
                                        label: "1",
                                        value: "1",
                                    });
                                } else {
                                    handleAddConfiguration();
                                }
                                setIsConfigurationModalOpened(
                                    !isConfigurationModalOpened
                                );
                            }}
                        >
                            Valider
                        </StyledButton>
                    </div>
                </ModalFooter>
                <CalendarModal
                    isCalendarModalOpened={isCalendarModalOpened}
                    setIsCalendarModalOpened={setIsCalendarModalOpened}
                    setCurrentDate={setCurrentDate}
                    setValidityPeriod={setValidityPeriod}
                    numberDays={numberDays}
                    periodLabel={periodLabel}
                    setOptionValue={setOptionValue}
                />
            </StyledModal>
        </div>
    );
}
