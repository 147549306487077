import React from "react";
import {
    StyledButton,
    StyledModal,
    CrossIcon,
    StyledIconButton,
    StyledLabel,
} from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { setAssociatedDemoLicenceToUpdate, setIsDemo, store } from "../store";
import { ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import "../Overview/index.css";

type AlerteModaltype = {
    isCommercialLicenceModalOpened: boolean;
    setIsCommercialLicenceModalOpened: Function;
};

export default function AlertConfirmCommercialModal({
    isCommercialLicenceModalOpened,
    setIsCommercialLicenceModalOpened,
}: AlerteModaltype) {
    const {
        selectedLicenceDemo,
        isDemo,
        associatedDemoLicenceToUpdate,
    } = useSnapshot(store);
    let Data: any = {};
    let DataToUpdate: any = {};
    function EditLicenceToCommercial() {
        if (selectedLicenceDemo.Type === "Pack") {
            if (selectedLicenceDemo.subRows.length > 0) {
                selectedLicenceDemo.subRows.forEach((el: any) => {
                    Data[el.iuud + "|" + el.uidPack] = {
                        isActivated: false,
                        date: "",
                    };
                });
            }
        } else {
            Data[selectedLicenceDemo.iuud + "|" + selectedLicenceDemo.iuud] = {
                isActivated: false,
                date: "",
            };
        }
        setAssociatedDemoLicenceToUpdate({
            ...associatedDemoLicenceToUpdate,
            ...Data,
        });
    }
    return (
        <div>
            <StyledModal
                className="updateAlerte"
                centered
                isOpen={isCommercialLicenceModalOpened}
            >
                <ModalHeader className="d-flex align-items-center">
                    <div
                        className="d-flex align-items-center justify-content-between "
                        style={{
                            height: "30px",
                            display: "grid",
                            gridTemplateColumns: "1.8fr 0.2fr",
                            gridTemplateRows: "1fr",
                            gap: "65px",
                        }}
                    >
                        <p className="title-text-style">ALERTE</p>
                        <StyledIconButton
                            icon="CrossIcon"
                            onClick={() => {
                                setIsCommercialLicenceModalOpened(
                                    !isCommercialLicenceModalOpened
                                );
                                setIsDemo(!isDemo);
                            }}
                        >
                            <CrossIcon
                                cursor="pointer"
                                height={10}
                                width={10}
                            />
                        </StyledIconButton>
                    </div>
                </ModalHeader>

                <ModalBody>
                    <div
                        style={{
                            marginLeft: "30px",
                            marginTop: "20px",
                            marginBottom: "20px",
                        }}
                    >
                        <StyledLabel>
                            La licence démo du{" "}
                            {selectedLicenceDemo.Type === "Pack"
                                ? ` pack`
                                : ` produit`}{" "}
                            "{selectedLicenceDemo.Désignation}" sera transformée
                            en une licence commerciale.
                            <br /> Voulez-vous confirmer la modification ?
                        </StyledLabel>
                    </div>
                </ModalBody>

                <ModalFooter
                    className="d-flex justify-content-end"
                    style={{
                        background: "#FFFFFF",
                    }}
                >
                    <div
                        className="d-flex justify-content-end pr-5"
                        style={{ gap: "30px" }}
                    >
                        <div className="col-4">
                            <StyledButton
                                disabled={false}
                                rounded
                                variant="light"
                                outline={true}
                                size="sm"
                                style={{
                                    background: "white",
                                    color: "black",
                                    padding: "0px",
                                    width: "150px",
                                    height: "45px",
                                }}
                                onClick={() => {
                                    setIsCommercialLicenceModalOpened(
                                        !isCommercialLicenceModalOpened
                                    );
                                    if (selectedLicenceDemo.Type === "Pack") {
                                        if (
                                            selectedLicenceDemo.subRows.length >
                                            0
                                        ) {
                                            selectedLicenceDemo.subRows.forEach(
                                                (el: any) => {
                                                    DataToUpdate[el.uidPack] = {
                                                        isDemo: true,
                                                    };
                                                }
                                            );
                                        }
                                    } else {
                                        DataToUpdate[
                                            selectedLicenceDemo.iuud +
                                                "|" +
                                                selectedLicenceDemo.iuud
                                        ] = {
                                            isDemo: true,
                                        };
                                    }
                                    setIsDemo(DataToUpdate);
                                }}
                            >
                                Annuler
                            </StyledButton>
                        </div>
                        <div className="col-4">
                            <StyledButton
                                disabled={false}
                                rounded
                                variant="primary"
                                outline={false}
                                size="sm"
                                style={{
                                    color: "white",
                                    padding: "0px",
                                    width: "150px",
                                    height: "45px",
                                }}
                                onClick={() => {
                                    EditLicenceToCommercial();
                                    setIsCommercialLicenceModalOpened(
                                        !isCommercialLicenceModalOpened
                                    );
                                }}
                            >
                                Valider
                            </StyledButton>
                        </div>
                    </div>
                </ModalFooter>
            </StyledModal>
        </div>
    );
}
