import { EMAIL_VALIDATION_REGEX } from "@constants/index";
import moment from "moment";

export function getRandomInt(max: number): number {
    return Math.floor(Math.random() * Math.floor(max));
}

// @see https://flaviocopes.com/how-to-uppercase-first-letter-javascript/
export function capitalize(s: string) {
    return s?.charAt(0).toUpperCase() + s?.slice(1);
}

export function getLastXDaysInTimestamp(lastXDays: number): string[] {
    let array: number[] = [];
    for (let i = 0; i < lastXDays; i++) {
        array.push(i);
    }
    return array
        .map((day: number) => {
            return Math.trunc(
                moment(
                    new Date(
                        moment().subtract(1, "days").unix() * 1000
                    ).getTime() -
                        86400000 * day
                ).unix()
            );
        })
        .sort()
        .map(String);
}

export const getNestedId = (fn: any) => (o: any) => [
    fn(o),
    ...(o.subRows || []).flatMap(getNestedId(fn)),
];

export const getNestedIdofArray = (data: any) => {
    console.log("🚀 ~ file: general.ts:38 ~ getNestedIdofArray ~ data", data);
    const listUuid: any[] = [];
    data?.forEach((item: any) => {
        const listid = getNestedId((x: any) => x.iuud)(item);
        listUuid.push(...listid);
    });
    return [...listUuid];
};

export function isEmailValid(email: string) {
    return EMAIL_VALIDATION_REGEX.test(email);
}

export function getPasswordStrength(
    result: number
): {
    strength: string;
    color: string;
} {
    switch (result) {
        case 1:
            return { strength: "Weak", color: "danger" };
        case 2:
            return { strength: "Fair", color: "warning" };
        case 3:
            return { strength: "Good", color: "success" };
        case 4:
            return { strength: "Strong", color: "success" };
        default:
            return { strength: "Weak", color: "danger" };
    }
}

export function measurePasswordStrengthAsNumber(password: string): number {
    let passwordStrengthMeter: boolean =
        password.match(/[0-9]/g) !== null &&
        password.match(/[a-z]/g) !== null &&
        password.match(/[A-Z]/g) !== null &&
        password.match(/[^A-Za-z0-9]/g) !== null;

    const EMPTY_PASSWORD = 0;
    const WEAK_PASSWORD = 1;
    const FAIR_PASSWORD = 2;
    const GOOD_PASSWORD = 3;
    const STRONG_PASSWORD = 4;

    if (password.length === 0) {
        return EMPTY_PASSWORD;
    } else if (password.length === 8 && passwordStrengthMeter) {
        return FAIR_PASSWORD;
    } else if (
        password.length > 8 &&
        password.length < 12 &&
        passwordStrengthMeter
    ) {
        return GOOD_PASSWORD;
    } else if (password.length >= 12 && passwordStrengthMeter) {
        return STRONG_PASSWORD;
    } else {
        return WEAK_PASSWORD;
    }
}
