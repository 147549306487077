import { DynamicTable } from "@maherunlocker/custom-react-table";
import React from "react";
import { useSnapshot } from "valtio";

import { store } from "../store";

type TableProductsHistoryType = {
    uuid: any;
    selectedRows: any;
    uuidTypeGestionnaireDeCompteHistory: any;
};
const Nodropdown = () => {
    return null;
};

export default function TableTypeGestionnaireDeComptesHistory(
    props: TableProductsHistoryType
) {
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const { isHitoryAllTypeGestionnaireDeComptes } = useSnapshot(store);

    let url = "";

    if (isHitoryAllTypeGestionnaireDeComptes) {
        url = `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/HistoryTypeGestionnaireDeComptes?uuid=${props.uuidTypeGestionnaireDeCompteHistory}`;
    } else {
        console.log(props.uuid);
        url = `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/HistoryTypeGestionnaireDeComptes?uuid=${props.uuid}`;
    }
    return (
        <React.Fragment>
            <DynamicTable
                canSort
                url={url}
                maxHeight={"calc(100vh - 445px)"}
                minHeight={"calc(100vh - 445px)"}
                showFilter
                actionColumn={Nodropdown}
                showGlobalFilter
                filterActive={filterActive}
                setLocalFilterActive={setLocalFilterActive}
            />
        </React.Fragment>
    );
}
