import { DynamicTable } from "@maherunlocker/custom-react-table";
import React from "react";
// import { useSnapshot } from "valtio";
// import { store } from "../store";
const Nodropdown = () => {
    return null;
};

function TableProfilHistory(props: any) {
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    // const { SelectedProfil } = useSnapshot(store);
    return (
        <React.Fragment>
            <DynamicTable
                url={props.url}
                maxHeight={"calc(100vh - 445px)"}
                minHeight={"calc(100vh - 445px)"}
                showFilter
                // actionColumn={<div></div>}
                //optionnal props
                actionColumn={Nodropdown}
                //canGroupBy
                // canSort
                // canResize
                //canExpand
                // canSelect
                showGlobalFilter
                // showFilterbyColumn
                // showColumnIcon
                // canDeleteOrDuplicate
                filterActive={filterActive}
                setLocalFilterActive={setLocalFilterActive}
                // arrayOfCustomColumns={arrayOfCustomColumns}
            />
        </React.Fragment>
    );
}

export default TableProfilHistory;
