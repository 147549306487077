import React, { useState, useEffect } from "react";
import { Modal, Button } from "reactstrap";
import {
    DateRangePicker,
    // defaultStaticRanges,
    createStaticRanges,
} from "@atefbena/react-date-range";
import i18n from "i18next";
import frLocale from "date-fns/locale/fr";
import enUsLocale from "date-fns/locale/en-US";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useSelector } from "react-redux";
import { useSnapshot } from "valtio";
import { setSelectedAccount, store } from "../../pages/store";

import "@atefbena/react-date-range/dist/styles.css";
import "@atefbena/react-date-range/dist/theme/default.css";

type CalendarModalType = {
    isCalendarModalOpened: boolean;
    setIsCalendarModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
    setCurrentDate: React.Dispatch<React.SetStateAction<any>>;
    setValidityPeriod: React.Dispatch<React.SetStateAction<any>>;
    numberDays: number;
    periodLabel: number;
    setOptionValue: React.Dispatch<React.SetStateAction<any>>;
};

export function CalendarModal({
    isCalendarModalOpened,
    setIsCalendarModalOpened,
    setCurrentDate,
    setValidityPeriod,
    numberDays,
    periodLabel,
    setOptionValue,
}: CalendarModalType) {
    const { t } = useTranslation();
    const { SelectedAccount } = useSnapshot(store);

    const advancedDate = useSelector((state: any) => state.advancedDate);

    console.log("🚀 ~ file: CalendarModal.tsx:39 ~ advancedDate", advancedDate);
    const [state, setState] = useState([
        {
            startDate: moment().toDate(),
            endDate: moment().add(29, "days").toDate(),
            key: "selection",
            label: t("1 Mois"),
        },
    ]);

    function handleOnChangeEvent(item: any) {
        setState([item.selection]);
    }

    let staticRanges: any[] = createStaticRanges([
        {
            label: t("1 Mois"),
            range: () => ({
                startDate: moment().toDate(),
                endDate: moment().add(29, "days").toDate(),
            }),
        },
        {
            label: t("2 Mois"),
            range: () => ({
                startDate: moment().toDate(),
                endDate: moment().add(59, "days").toDate(),
            }),
        },
        {
            label: t("3 Mois"),
            range: () => ({
                startDate: moment().toDate(),
                endDate: moment().add(89, "days").toDate(),
            }),
        },
        {
            label: t("4 Mois"),
            range: () => ({
                startDate: moment().toDate(),
                endDate: moment().add(119, "days").toDate(),
            }),
        },
        {
            label: t("5 Mois"),
            range: () => ({
                startDate: moment().toDate(),
                endDate: moment().add(149, "days").toDate(),
            }),
        },
        {
            label: t("6 Mois"),
            range: () => ({
                startDate: moment().toDate(),
                endDate: moment().add(179, "days").toDate(),
            }),
        },
        {
            label: t("7 Mois"),
            range: () => ({
                startDate: moment().toDate(),
                endDate: moment().add(209, "days").toDate(),
            }),
        },
        {
            label: t("8 Mois"),
            range: () => ({
                startDate: moment().toDate(),
                endDate: moment().add(239, "days").toDate(),
            }),
        },
        {
            label: t("9 Mois"),
            range: () => ({
                startDate: moment().toDate(),
                endDate: moment().add(269, "days").toDate(),
            }),
        },
        {
            label: t("10 Mois"),
            range: () => ({
                startDate: moment().toDate(),
                endDate: moment().add(299, "days").toDate(),
            }),
        },
        {
            label: t("11 Mois"),
            range: () => ({
                startDate: moment().toDate(),
                endDate: moment().add(329, "days").toDate(),
            }),
        },
        {
            label: t("12 Mois"),
            range: () => ({
                startDate: moment().toDate(),
                endDate: moment().add(364, "days").toDate(),
            }),
        },
        {
            label: t("Personalized"),
            range: () => ({
                startDate:
                    // advancedDate.startDate !== ""
                    advancedDate !== undefined
                        ? new Date(Number(advancedDate.startDate) * 1000)
                        : moment().subtract(1, "days").toDate(),
                endDate:
                    // advancedDate.endDate !== ""
                    advancedDate !== undefined
                        ? new Date(Number(advancedDate.endDate) * 1000)
                        : new Date(),
            }),
        },
    ]);

    function numberOfDaysBetweenTwoDates(date1: Date, date2: Date) {
        const msInDay = 24 * 60 * 60 * 1000;
        let daysBetweenDates: number = Math.round(
            Math.abs(Number(date2) - Number(date1)) / msInDay
        );
        return daysBetweenDates;
    }

    function handleValidateButtonOnClickEvent() {
        let daysDifference = numberOfDaysBetweenTwoDates(
            state[0].startDate,
            state[0].endDate
        );
        if (daysDifference !== numberDays) {
            state[0].label = "Personnalisée";
            setOptionValue({
                label: "Personnalisée..",
                value: "Personnalisée..",
            });
        }
        setCurrentDate(moment(state[0].startDate).format("DD/MM/YYYY"));
        setValidityPeriod(moment(state[0].endDate).format("DD/MM/YYYY"));
        if (SelectedAccount !== "") {
            setSelectedAccount({
                ...SelectedAccount,
                limiteCollection: {
                    ...SelectedAccount.limiteCollection,
                    duration: `${moment(state[0].startDate).format(
                        "DD/MM/YYYY"
                    )}|${moment(state[0].endDate).format(
                        "DD/MM/YYYY"
                    )}|${periodLabel}`,
                },
            });
        }

        setIsCalendarModalOpened(!isCalendarModalOpened);
    }

    useEffect(() => {
        setState([
            {
                startDate: moment().toDate(),
                endDate: moment().add(numberDays, "days").toDate(),
                key: "selection",
                label: t(`${periodLabel} Mois`),
            },
        ]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [numberDays, periodLabel]);

    return (
        <div>
            <Modal
                className="configuration-modal-style sm"
                centered
                isOpen={isCalendarModalOpened}
                style={{ width: "580px", height: "512" }}
            >
                <DateRangePicker
                    onChange={handleOnChangeEvent}
                    months={1}
                    ranges={state}
                    direction="horizontal"
                    weekStartsOn={1}
                    locale={i18n.language === "fr" ? frLocale : enUsLocale}
                    dateDisplayFormat={"dd MMM yyyy"}
                    staticRanges={staticRanges}
                    className="input-ranges-display-none__clz"
                />
                <div className="d-flex justify-content-end mb-2">
                    <Button
                        type="button"
                        color="dark"
                        className="ml-3"
                        outline
                        onClick={() =>
                            setIsCalendarModalOpened(!isCalendarModalOpened)
                        }
                    >
                        {t("Cancel")}
                    </Button>
                    <Button
                        type="button"
                        color="dark"
                        onClick={handleValidateButtonOnClickEvent}
                        className="mx-3"
                    >
                        {t("Validate")}
                    </Button>
                </div>
            </Modal>
        </div>
    );
}
