import React from "react";
import {
    StyledButton,
    StyledModal,
    CrossIcon,
    StyledIconButton,
    StyledLabel,
} from "@aureskonnect/react-ui";
import {
    setEdit,
    setIsActiveLicenseAlertModuleOpen,
    setModif,
    setSelectedModule,
} from "../store";
import { ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { useSnapshot } from "valtio";
import { store } from "../store";
import "../Overview/index.css";
import { showToast } from "@components/Common/ToastShow";

type ActiveLicenseAlertType = {
    isEditAction: boolean;
    setIsHiddenCard: React.Dispatch<React.SetStateAction<boolean>>;
    isHiddenCard: boolean;
    setIsEmptyDes: React.Dispatch<React.SetStateAction<boolean>>;
    setDataIsUpdated: React.Dispatch<React.SetStateAction<number | boolean>>;
    dataIsUpdated: number | boolean;
};

export default function ActiveLicenseAlertModule({
    isEditAction,
    setIsHiddenCard,
    isHiddenCard,
    setIsEmptyDes,
    setDataIsUpdated,
    dataIsUpdated,
}: ActiveLicenseAlertType) {
    const {
        isActiveLicenseAlertModuleOpen,
        selectedModuleBeforeUpdate,
        selectedModule,
    } = useSnapshot(store);
    const uidEntity = localStorage.getItem("Uid_Entitie_User");

    function saveHistoryChanges(oldData: any, newData: any) {
        fetch(`${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/HistoryChanges`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                oldData: oldData,
                newData: newData,
                uidUpdatedData: uidEntity,
                typeHistory: "2",
            }),
        })
            .then((response) => {
                response.json().then((data) => {
                    setIsHiddenCard(!isHiddenCard);

                    return data.error;
                });
            })
            .catch((e: any) => {
                return false;
            });
    }

    function updateModule() {
        const old_module = {
            name: selectedModuleBeforeUpdate.Module,
            comment: selectedModuleBeforeUpdate.Descriptif,
            reference: selectedModuleBeforeUpdate.Référence,
            salesSupport: selectedModuleBeforeUpdate.SalesSupport,
            isArchived: selectedModuleBeforeUpdate.isArchived,
            isPrincipalProduct: selectedModuleBeforeUpdate.isPrincipalProduct,
        };
        const new_module = {
            name: selectedModule.Module,
            comment: selectedModule.Descriptif,
            reference: selectedModule.Référence,
            salesSupport: selectedModule.SalesSupport,
            isArchived: selectedModule.isArchived,
            isPrincipalProduct: selectedModule.isPrincipalProduct,
        };
        const requestOptions: any = {
            body: JSON.stringify({
                product: new_module,
                uidEntitie: selectedModule.Référence,
            }),
            method: "PUT",
            headers: { "Content-Type": "application/json" },
        };
        fetch(
            `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/updateProduct`,
            requestOptions
        )
            .then((response) => {
                response.json().then((data) => {
                    if (!data.error) {
                        showToast(
                            true,
                            `Le module ${selectedModule.Module} a été modifié avec succès.`
                        );
                        saveHistoryChanges(old_module, new_module);
                        setIsEmptyDes(false);
                        setModif(false);
                        setEdit("");
                        setDataIsUpdated(!dataIsUpdated);
                        setIsHiddenCard(!isHiddenCard);
                        setSelectedModule("");
                        setIsActiveLicenseAlertModuleOpen(
                            !isActiveLicenseAlertModuleOpen
                        );
                    } else {
                        showToast(
                            false,
                            `Le module ${selectedModule.Module} n'est pas modifié`
                        );
                    }
                });
            })
            .catch((e: any) => {
                console.log({ error: e.message });
            });
    }
    return (
        <div>
            <StyledModal centered isOpen={isActiveLicenseAlertModuleOpen}>
                <ModalHeader className="d-flex align-items-center">
                    <div
                        className="d-flex align-items-center justify-content-between "
                        style={{
                            height: "30px",
                            display: "grid",
                            gridTemplateColumns: "1.8fr 0.2fr",
                            gridTemplateRows: "1fr",
                            gap: "65px",
                        }}
                    >
                        <p className="title-text-style">ALERTE</p>
                        <StyledIconButton
                            icon="CrossIcon"
                            onClick={() => {
                                setIsActiveLicenseAlertModuleOpen(
                                    !isActiveLicenseAlertModuleOpen
                                );
                                setIsHiddenCard(!isHiddenCard);
                            }}
                        >
                            <CrossIcon
                                cursor="pointer"
                                height={10}
                                width={10}
                            />
                        </StyledIconButton>
                    </div>
                </ModalHeader>

                <ModalBody>
                    <div
                        style={{
                            marginLeft: "30px",
                            marginTop: "20px",
                            marginBottom: "20px",
                        }}
                    >
                        <StyledLabel>
                            Attention ! Ce module possède des licences actives.
                            <br />
                            {isEditAction
                                ? ` Voulez-vous valider les modifications apportées ?`
                                : ` Voulez-vous affecter le module "${selectedModule.Module}" pour tous les
                            clients qui possèdent cette licence ?`}
                        </StyledLabel>
                    </div>
                </ModalBody>

                <ModalFooter
                    className="d-flex justify-content-end"
                    style={{
                        background: "#FFFFFF",
                    }}
                >
                    <div
                        className="d-flex justify-content-end pr-5"
                        style={{ gap: "30px" }}
                    >
                        <div className="col-4">
                            <StyledButton
                                disabled={false}
                                rounded
                                variant="light"
                                outline={true}
                                size="sm"
                                style={{
                                    background: "white",
                                    color: "black",
                                    padding: "0px",
                                    width: "150px",
                                    height: "45px",
                                }}
                                onClick={() => {
                                    setIsActiveLicenseAlertModuleOpen(
                                        !isActiveLicenseAlertModuleOpen
                                    );
                                    setIsHiddenCard(!isHiddenCard);
                                }}
                            >
                                Annuler
                            </StyledButton>
                        </div>
                        <div className="col-4">
                            <StyledButton
                                disabled={false}
                                rounded
                                variant="primary"
                                outline={false}
                                size="sm"
                                style={{
                                    color: "white",
                                    padding: "0px",
                                    width: "150px",
                                    height: "45px",
                                }}
                                onClick={() => {
                                    if (isEditAction) {
                                        updateModule();
                                    } else {
                                    }
                                }}
                            >
                                Valider
                            </StyledButton>
                        </div>
                    </div>
                </ModalFooter>
            </StyledModal>
        </div>
    );
}
