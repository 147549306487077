import React from "react";
import { DynamicTable } from "@maherunlocker/custom-react-table";
import { SettingsIcon, StyledSelectInput } from "@aureskonnect/react-ui";
import NumericInput from "react-numeric-input";
import Switch from "react-switch";
import { useSnapshot } from "valtio";

import {
    setIsCommercialLicenceModalOpened,
    setIsConfigurationLicenceModalOpened,
    setIsLicenceDemoConfiguration,
    setSelectedLicenceDemo,
    setIsUpdateInfoCertificate,
    store,
} from "../../pages/store";

import ExclamationPoint from "@assets/images/svg-icons/ExclamationPoint.svg";
// import HoverElement from "./HoverElement";
import LicencesActionsMenu from "@pages/Overview/LicencesActionsMenu";
import classnames from "classnames";

type tableLicencesType = {
    url: string;
    dataEntitie: any;
    selectedRows: any;
    setSelectedRows: React.Dispatch<React.SetStateAction<any>>;
    setlistSelectedLicenses: React.Dispatch<React.SetStateAction<any>>;
    setlistSelectedEconomicModel: React.Dispatch<React.SetStateAction<any>>;
    setListSelectedLicensesToBlocked: React.Dispatch<React.SetStateAction<any>>;
    setListSelectedLicensesToDeBlocked: React.Dispatch<
        React.SetStateAction<any>
    >;
    setSelectedUpdatedEconomicModel: React.Dispatch<React.SetStateAction<any>>;
    setListModuleToUpdateAdd: React.Dispatch<React.SetStateAction<any>>;
    setListModuleToUpdateDelete: React.Dispatch<React.SetStateAction<any>>;
    updatedLicence: any;
    setlistSelectedUpdatedLicenses: any;
    setListModule: any;
    statusLicense: string;
};

interface customColumnProps {
    indexOFColumn: number;
    columnName: string;
    customJsx: Function;
}

export default function LicensesListTable({
    url,
    setSelectedRows,
    setlistSelectedLicenses,
    setlistSelectedEconomicModel,
    updatedLicence,
    setlistSelectedUpdatedLicenses,
    setListModule,
    statusLicense,
    setListSelectedLicensesToBlocked,
    setListSelectedLicensesToDeBlocked,
    setListModuleToUpdateAdd,
    setListModuleToUpdateDelete,
    setSelectedUpdatedEconomicModel,
}: tableLicencesType) {
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const [typeEconomicModel] = React.useState<any>([
        { label: "One Shot", value: "One Shot" },
        { label: "SAAS", value: "SAAS" },
    ]);
    const [dataIsUpdated, setDataIsUpdated] = React.useState<number | boolean>(
        false
    );

    let listSelectedLicenses: any = {};
    let listSelectedLicensesToBlocked: any = {};
    let listSelectedLicensesToDeBlocked: any = {};
    let listEconomicModel: any = {};
    let listEconomicModelToUpdate: any = {};

    let Data: any = {};
    let Data2: any = {};
    let ModuleUuid: any = [];
    let ModuleUuid1: any = [];
    let SelectedModuleToAdd: any = [];
    let SelectedModuleToDelete: any = [];
    let SelectedModuleToAdd1: any = [];
    let SelectedModuleToDelete1: any = [];
    let licenceColumn: String = "";
    let licenceColumnActiveOrBloqued: String = "";
    switch (statusLicense) {
        case "add":
            licenceColumn = "Licences à ajouter";
            licenceColumnActiveOrBloqued = "Nombre de licences actives";
            break;
        case "bloq":
            licenceColumn = "Licences à bloquer";
            licenceColumnActiveOrBloqued = "Nombre de licences actives";
            break;
        case "debloq":
            licenceColumn = "Licences à débloquer";
            licenceColumnActiveOrBloqued = "Nombre de licences bloquées";
            break;
        default:
    }

    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 6,
            columnName: String(licenceColumnActiveOrBloqued),
            customJsx: NbreLicenceActivesColumn,
        },
        {
            indexOFColumn: 7,
            columnName: "Modèle économique",
            customJsx: EconomicModelColumn,
        },
        {
            indexOFColumn: 8,
            columnName: String(licenceColumn),
            customJsx: LicencesAjouterColumn,
        },
        {
            indexOFColumn: 9,
            columnName: "Démo",
            customJsx: DemoColumn,
        },
        {
            indexOFColumn: 10,
            columnName: "Actions",
            customJsx: ActionColumn,
        },
    ];

    function LicencesAjouterColumn({ selectedRow }: any) {
        const [isChecked, setIsChecked] = React.useState(true);
        const [numberLicenceAjoute, setNumberLicenceAjoute] = React.useState<
            number
        >(0);
        const {
            selectedModuleProductLicences,
            selectedLicences,
            selectedModuleToUpdate,
            KeysLicenseBeforeUpdate,
            licenceToDebloq,
        } = useSnapshot(store);

        function addListSelectedLicenses(
            NbreLicencesAjoute: any,
            selectedRow: any
        ) {
            if (selectedRow.Type === "Pack") {
                if (selectedRow.subRows.length > 0) {
                    selectedRow.subRows.forEach((el: any) => {
                        if (Object.keys(updatedLicence).length > 0) {
                            if (
                                updatedLicence[el.iuud + "|" + el.uidPack] !==
                                undefined
                            ) {
                                const num = KeysLicenseBeforeUpdate.includes(
                                    selectedRow.iuud + "|" + selectedRow.uidPack
                                )
                                    ? NbreLicencesAjoute +
                                      updatedLicence[el.iuud + "|" + el.uidPack]
                                          .actived
                                    : NbreLicencesAjoute;
                                Data2[el.iuud + "|" + el.uidPack] = {
                                    actived: num,
                                };
                            } else {
                                if (el.subRows.length > 0) {
                                    let ModuleUuid: any = [];
                                    el.subRows.forEach((elModule: any) => {
                                        if (
                                            el.Référence ===
                                            elModule.parentReference
                                        ) {
                                            ModuleUuid.push(elModule.iuud);
                                        }
                                    });
                                    Data[el.iuud] = {
                                        modulesUuid: ModuleUuid,
                                    };
                                }
                                updatedLicence[el.iuud + "|" + el.uidPack] = {
                                    modules: Data[el.iuud],
                                    blocked: 0,
                                    actived: NbreLicencesAjoute,
                                    demoConfiguration: {
                                        isActivated: false,
                                        date: "",
                                    },
                                    managementRules: [],
                                };
                            }
                            setlistSelectedLicenses(updatedLicence);
                            setlistSelectedUpdatedLicenses(Data2);
                        } else {
                            if (el.subRows.length > 0) {
                                let ModuleUuid: any = [];
                                el.subRows.forEach((elModule: any) => {
                                    if (
                                        el.Référence ===
                                        elModule.parentReference
                                    ) {
                                        ModuleUuid.push(elModule.iuud);
                                    }
                                });
                                Data[el.iuud] = {
                                    modulesUuid: ModuleUuid,
                                };
                            }
                            listSelectedLicenses[el.iuud + "|" + el.uidPack] = {
                                modules: Data[el.iuud],
                                blocked: 0,
                                actived: NbreLicencesAjoute,
                                demoConfiguration: {
                                    isActivated: false,
                                    date: "",
                                },
                                managementRules: [],
                            };
                            setlistSelectedLicenses(listSelectedLicenses);
                        }

                        if (Object.keys(updatedLicence).length < 0) {
                            setlistSelectedLicenses(listSelectedLicenses);
                        }
                    });
                }
            } else {
                if (Object.keys(updatedLicence).length > 0) {
                    if (
                        updatedLicence[
                            selectedRow.iuud + "|" + selectedRow.iuud
                        ] !== undefined
                    ) {
                        const num = KeysLicenseBeforeUpdate.includes(
                            selectedRow.iuud + "|" + selectedRow.iuud
                        )
                            ? NbreLicencesAjoute +
                              updatedLicence[
                                  selectedRow.iuud + "|" + selectedRow.iuud
                              ].actived
                            : NbreLicencesAjoute;
                        Data2[selectedRow.iuud + "|" + selectedRow.iuud] = {
                            actived: num,
                        };
                    } else {
                        if (selectedRow.subRows.length > 0) {
                            let ModuleUuid: any = [];
                            selectedRow.subRows.forEach((el: any) => {
                                if (
                                    selectedRow.Référence === el.parentReference
                                ) {
                                    ModuleUuid.push(el.iuud);
                                }
                            });
                            Data[selectedRow.iuud] = {
                                modulesUuid: ModuleUuid,
                            };
                        }
                        updatedLicence[
                            selectedRow.iuud + "|" + selectedRow.iuud
                        ] = {
                            modules:
                                selectedRow.subRows.length > 0
                                    ? Data[selectedRow.iuud]
                                    : { modulesUuid: [] },
                            blocked: 0,
                            actived: NbreLicencesAjoute,
                            demoConfiguration: {
                                isActivated: false,
                                date: "",
                            },
                            managementRules: [],
                        };
                    }

                    setlistSelectedLicenses(updatedLicence);
                    setlistSelectedUpdatedLicenses(Data2);
                } else {
                    if (selectedRow.subRows.length > 0) {
                        let ModuleUuid: any = [];
                        selectedRow.subRows.forEach((el: any) => {
                            if (selectedRow.Référence === el.parentReference) {
                                ModuleUuid.push(el.iuud);
                            }
                        });
                        Data[selectedRow.iuud] = {
                            modulesUuid: ModuleUuid,
                        };
                    }
                    listSelectedLicenses[
                        selectedRow.iuud + "|" + selectedRow.iuud
                    ] = {
                        modules:
                            selectedRow.subRows.length > 0
                                ? Data[selectedRow.iuud]
                                : { modulesUuid: [] },
                        blocked: 0,
                        actived: NbreLicencesAjoute,
                        demoConfiguration: {
                            isActivated: false,
                            date: "",
                        },
                        managementRules: [],
                    };
                    setlistSelectedLicenses(listSelectedLicenses);
                }
                if (Object.keys(updatedLicence).length < 0) {
                    setlistSelectedLicenses(listSelectedLicenses);
                }
            }
        }
        function BlockLicences(numberLicenceToBlocked: any, selectedRow: any) {
            if (selectedRow.Type === "Pack") {
                if (selectedRow.subRows.length > 0) {
                    selectedRow.subRows.forEach((el: any) => {
                        listSelectedLicensesToBlocked[
                            el.iuud + "|" + el.uidPack
                        ] = {
                            blocked: numberLicenceToBlocked,
                        };
                    });
                }
            } else {
                listSelectedLicensesToBlocked[
                    selectedRow.iuud + "|" + selectedRow.iuud
                ] = {
                    blocked: numberLicenceToBlocked,
                };
            }
            setListSelectedLicensesToBlocked(listSelectedLicensesToBlocked);
        }
        function DeblockLicences(
            numberLicenceToBlocked: any,
            selectedRow: any
        ) {
            if (selectedRow.Type === "Pack") {
                if (selectedRow.subRows.length > 0) {
                    selectedRow.subRows.forEach((el: any) => {
                        listSelectedLicensesToDeBlocked[
                            el.iuud + "|" + el.uidPack
                        ] = {
                            blocked: numberLicenceToBlocked,
                        };
                    });
                }
            } else {
                listSelectedLicensesToDeBlocked[
                    selectedRow.iuud + "|" + selectedRow.iuud
                ] = {
                    blocked: numberLicenceToBlocked,
                };
            }
            setListSelectedLicensesToDeBlocked(listSelectedLicensesToDeBlocked);
        }

        return (
            <div style={{ justifyContent: "center" }}>
                {selectedRow.Type !== "Module" ? (
                    selectedRow.isPack === false ? null : (
                        <div
                            className={`${classnames({
                                hovertext:
                                    (licenceColumn === "Licences à ajouter" &&
                                        ((Object.keys(
                                            selectedLicences
                                        ).includes(selectedRow.iuud) &&
                                            selectedLicences[selectedRow.iuud]
                                                .blocked > 0) ||
                                            (licenceColumn ===
                                                "Licences à ajouter" &&
                                                Object.keys(
                                                    selectedLicences
                                                ).includes(selectedRow.uid) &&
                                                selectedLicences[
                                                    selectedRow.uid
                                                ].blocked > 0))) ||
                                    (licenceColumn === "Licences à débloquer" &&
                                        Object.keys(selectedLicences).includes(
                                            selectedRow.iuud
                                        ) &&
                                        selectedLicences[selectedRow.iuud]
                                            .blocked === 0) ||
                                    (licenceColumn === "Licences à débloquer" &&
                                        licenceToDebloq.includes(
                                            selectedRow.iuud
                                        )) ||
                                    (licenceColumn === "Licences à débloquer" &&
                                        licenceToDebloq.includes(
                                            selectedRow.uid
                                        )) ||
                                    (licenceColumn === "Licences à bloquer" &&
                                        Object.keys(selectedLicences).includes(
                                            selectedRow.iuud
                                        ) &&
                                        selectedLicences[selectedRow.iuud]
                                            .actived === 0),
                            })}`}
                            data-hover={
                                licenceColumn === "Licences à ajouter" &&
                                ((Object.keys(selectedLicences).includes(
                                    selectedRow.iuud
                                ) &&
                                    selectedLicences[selectedRow.iuud].blocked >
                                        0) ||
                                    (licenceColumn === "Licences à ajouter" &&
                                        Object.keys(selectedLicences).includes(
                                            selectedRow.uid
                                        ) &&
                                        selectedLicences[selectedRow.uid]
                                            .blocked > 0))
                                    ? `Il est impossible d'ajouter des licences à un produit/pack qui contient des licences bloquées`
                                    : (licenceColumn ===
                                          "Licences à débloquer" &&
                                          Object.keys(
                                              selectedLicences
                                          ).includes(selectedRow.uid) &&
                                          selectedLicences[selectedRow.uid]
                                              .blocked === 0) ||
                                      (licenceColumn ===
                                          "Licences à débloquer" &&
                                          Object.keys(
                                              selectedLicences
                                          ).includes(selectedRow.iuud) &&
                                          selectedLicences[selectedRow.iuud]
                                              .blocked === 0)
                                    ? ` Il est impossible de débloquer des licences des produit/pack qui ne contient pas des licences bloquées`
                                    : licenceToDebloq.includes(selectedRow.iuud)
                                    ? `Il est impossible de débloquer des licences d'un produit/pack si ce même produit/pack possède des licences actives.`
                                    : ` Il est impossible de bloquer des licences des produit/pack qui ne contient pas des licences actives`
                            }
                        >
                            <NumericInput
                                style={{
                                    input: {
                                        color: "black",
                                        width: "75px",
                                        height: "37px",
                                    },
                                }}
                                min={0}
                                max={
                                    selectedRow.Type === "Pack"
                                        ? selectedLicences[selectedRow.uid] !==
                                              undefined &&
                                          licenceColumn === "Licences à bloquer"
                                            ? selectedLicences[selectedRow.uid]
                                                  .actived
                                            : selectedLicences[
                                                  selectedRow.iuud
                                              ] !== undefined &&
                                              licenceColumn ===
                                                  "Licences à débloquer"
                                            ? selectedLicences[selectedRow.uid]
                                                  .blocked
                                            : 100
                                        : selectedLicences[selectedRow.iuud] !==
                                              undefined &&
                                          licenceColumn === "Licences à bloquer"
                                        ? selectedLicences[selectedRow.iuud]
                                              .actived
                                        : selectedLicences[selectedRow.iuud] !==
                                              undefined &&
                                          licenceColumn ===
                                              "Licences à débloquer"
                                        ? selectedLicences[selectedRow.iuud]
                                              .blocked
                                        : 100
                                }
                                defaultValue={0}
                                value={numberLicenceAjoute}
                                onChange={(selectedValue: any) => {
                                    setIsUpdateInfoCertificate(true);
                                    if (
                                        licenceColumn === "Licences à ajouter"
                                    ) {
                                        setNumberLicenceAjoute(selectedValue);
                                        addListSelectedLicenses(
                                            selectedValue,
                                            selectedRow
                                        );
                                    } else if (
                                        licenceColumn === "Licences à bloquer"
                                    ) {
                                        setNumberLicenceAjoute(selectedValue);
                                        BlockLicences(
                                            selectedValue,
                                            selectedRow
                                        );
                                    } else {
                                        setNumberLicenceAjoute(selectedValue);
                                        DeblockLicences(
                                            selectedValue,
                                            selectedRow
                                        );
                                    }
                                }}
                                strict
                                disabled={
                                    selectedRow.Type === "Pack"
                                        ? selectedLicences[selectedRow.uid] !==
                                              undefined &&
                                          licenceColumn === "Licences à bloquer"
                                            ? selectedLicences[selectedRow.uid]
                                                  .actived === 0
                                                ? true
                                                : false
                                            : selectedLicences[
                                                  selectedRow.uid
                                              ] !== undefined &&
                                              licenceColumn ===
                                                  "Licences à débloquer"
                                            ? selectedLicences[selectedRow.uid]
                                                  .blocked === 0
                                                ? true
                                                : licenceToDebloq.includes(
                                                      selectedRow.uid
                                                  )
                                                ? true
                                                : false
                                            : licenceColumn ===
                                              "Licences à ajouter"
                                            ? Object.keys(
                                                  selectedLicences
                                              ).includes(selectedRow.uid) &&
                                              selectedLicences[selectedRow.uid]
                                                  .blocked !== 0
                                                ? true
                                                : false
                                            : true
                                        : selectedLicences[selectedRow.iuud] !==
                                              undefined &&
                                          licenceColumn === "Licences à bloquer"
                                        ? selectedLicences[selectedRow.iuud]
                                              .actived === 0
                                            ? true
                                            : false
                                        : selectedLicences[selectedRow.iuud] !==
                                              undefined &&
                                          licenceColumn ===
                                              "Licences à débloquer"
                                        ? selectedLicences[selectedRow.iuud]
                                              .blocked === 0
                                            ? true
                                            : licenceToDebloq.includes(
                                                  selectedRow.iuud
                                              )
                                            ? true
                                            : false
                                        : licenceColumn === "Licences à ajouter"
                                        ? Object.keys(
                                              selectedLicences
                                          ).includes(selectedRow.iuud) &&
                                          selectedLicences[selectedRow.iuud]
                                              .blocked !== 0
                                            ? true
                                            : false
                                        : true
                                }
                            />
                        </div>
                    )
                ) : selectedRow.isPack === false ? null : (
                    <div>
                        <Switch
                            onChange={() => {
                                if (Object.keys(updatedLicence).length > 0) {
                                    if (
                                        Object.keys(
                                            selectedModuleToUpdate
                                        ).includes(
                                            selectedRow.UuidParentProduct
                                        ) &&
                                        selectedModuleToUpdate[
                                            selectedRow.UuidParentProduct
                                        ]["modules"].includes(selectedRow.iuud)
                                    ) {
                                        if (
                                            SelectedModuleToDelete1.includes(
                                                selectedRow.UuidParentProduct +
                                                    "|" +
                                                    selectedRow.UuidParentProduct +
                                                    "," +
                                                    selectedRow.iuud
                                            )
                                        ) {
                                            SelectedModuleToDelete = SelectedModuleToDelete1.filter(
                                                (word: any) =>
                                                    word !==
                                                    selectedRow.UuidParentProduct +
                                                        "|" +
                                                        selectedRow.UuidParentProduct +
                                                        "," +
                                                        selectedRow.iuud
                                            );
                                            SelectedModuleToDelete1 = SelectedModuleToDelete;
                                        } else {
                                            SelectedModuleToDelete1.push(
                                                selectedRow.UuidParentProduct +
                                                    "|" +
                                                    selectedRow.UuidParentProduct +
                                                    "," +
                                                    selectedRow.iuud
                                            );
                                        }
                                    } else {
                                        if (
                                            SelectedModuleToAdd1.includes(
                                                selectedRow.UuidParentProduct +
                                                    "|" +
                                                    selectedRow.UuidParentProduct +
                                                    "," +
                                                    selectedRow.iuud
                                            )
                                        ) {
                                            SelectedModuleToAdd = SelectedModuleToAdd1.filter(
                                                (word: any) =>
                                                    word !==
                                                    selectedRow.UuidParentProduct +
                                                        "|" +
                                                        selectedRow.UuidParentProduct +
                                                        "," +
                                                        selectedRow.iuud
                                            );
                                            SelectedModuleToAdd1 = SelectedModuleToAdd;
                                        } else {
                                            SelectedModuleToAdd1.push(
                                                selectedRow.UuidParentProduct +
                                                    "|" +
                                                    selectedRow.UuidParentProduct +
                                                    "," +
                                                    selectedRow.iuud
                                            );
                                        }
                                    }
                                    setListModuleToUpdateAdd(
                                        SelectedModuleToAdd1
                                    );
                                    setListModuleToUpdateDelete(
                                        SelectedModuleToDelete1
                                    );
                                    setlistSelectedLicenses(updatedLicence);
                                } else {
                                    if (
                                        ModuleUuid1.includes(selectedRow.iuud)
                                    ) {
                                        ModuleUuid = ModuleUuid1.filter(
                                            (word: any) =>
                                                word !== selectedRow.iuud
                                        );
                                        ModuleUuid1 = ModuleUuid;
                                    } else {
                                        ModuleUuid1.push(selectedRow.iuud);
                                    }
                                    Data[
                                        selectedRow.UuidParentProduct +
                                            "|" +
                                            selectedRow.UuidParentProduct
                                    ] = {
                                        modulesUuid: ModuleUuid1,
                                    };
                                    setListModule(Data);
                                }
                                setIsChecked(!isChecked);
                            }}
                            offColor="#E30613"
                            onColor="#34C38F"
                            checked={
                                updatedLicence !== undefined &&
                                selectedModuleProductLicences !== undefined &&
                                updatedLicence[
                                    selectedRow.UuidParentProduct +
                                        "|" +
                                        selectedRow.UuidParentProduct
                                ] !== undefined &&
                                selectedModuleProductLicences.includes(
                                    selectedRow.UuidParentProduct +
                                        "|" +
                                        selectedRow.UuidParentProduct
                                )
                                    ? updatedLicence !== undefined &&
                                      updatedLicence[
                                          selectedRow.UuidParentProduct +
                                              "|" +
                                              selectedRow.UuidParentProduct
                                      ].modules.modulesUuid.includes(
                                          selectedRow.iuud
                                      )
                                        ? isChecked
                                        : !isChecked
                                    : isChecked
                            }
                            disabled={
                                selectedRow.Type === "Pack"
                                    ? selectedLicences[selectedRow.uid] !==
                                          undefined &&
                                      licenceColumn === "Licences à bloquer"
                                        ? false
                                        : selectedLicences[selectedRow.iuud] !==
                                              undefined &&
                                          licenceColumn ===
                                              "Licences à débloquer"
                                        ? false
                                        : licenceColumn === "Licences à ajouter"
                                        ? false
                                        : true
                                    : selectedLicences[selectedRow.iuud] !==
                                          undefined &&
                                      licenceColumn === "Licences à bloquer"
                                    ? false
                                    : selectedLicences[selectedRow.iuud] !==
                                          undefined &&
                                      licenceColumn === "Licences à débloquer"
                                    ? false
                                    : licenceColumn === "Licences à ajouter"
                                    ? false
                                    : true
                            }
                        ></Switch>
                    </div>
                )}
            </div>
        );
    }

    function NbreLicenceActivesColumn({ selectedRow }: any) {
        const { selectedLicences } = useSnapshot(store);

        let numberLicenceActive;
        if (statusLicense === "add" || statusLicense === "bloq") {
            if (selectedRow.Type === "Pack") {
                if (Object.keys(selectedLicences).includes(selectedRow.uid)) {
                    if (selectedLicences[selectedRow.uid].actived < 10) {
                        numberLicenceActive =
                            "0" + selectedLicences[selectedRow.uid].actived;
                    } else {
                        numberLicenceActive =
                            selectedLicences[selectedRow.uid].actived;
                    }
                } else {
                    numberLicenceActive = "00";
                }
            } else {
                if (
                    Object.keys(selectedLicences).includes(selectedRow.iuud) &&
                    selectedRow.isAssociated === false
                ) {
                    if (selectedLicences[selectedRow.iuud].actived < 10) {
                        numberLicenceActive =
                            "0" + selectedLicences[selectedRow.iuud].actived;
                    } else {
                        numberLicenceActive =
                            selectedLicences[selectedRow.iuud].actived;
                    }
                } else {
                    numberLicenceActive = "00";
                }
            }
        } else {
            if (selectedRow.Type === "Pack") {
                if (Object.keys(selectedLicences).includes(selectedRow.uid)) {
                    if (selectedLicences[selectedRow.uid].blocked < 10) {
                        numberLicenceActive =
                            "0" + selectedLicences[selectedRow.uid].blocked;
                    } else {
                        numberLicenceActive =
                            selectedLicences[selectedRow.uid].blocked;
                    }
                } else {
                    numberLicenceActive = "00";
                }
            } else {
                if (
                    Object.keys(selectedLicences).includes(selectedRow.iuud) &&
                    selectedRow.isAssociated === false
                ) {
                    if (selectedLicences[selectedRow.iuud].blocked < 10) {
                        numberLicenceActive =
                            "0" + selectedLicences[selectedRow.iuud].blocked;
                    } else {
                        numberLicenceActive =
                            selectedLicences[selectedRow.iuud].blocked;
                    }
                } else {
                    numberLicenceActive = "00";
                }
            }
        }
        return (
            <div>
                {(selectedRow.Type !== "Module" &&
                    selectedRow.productPack === false) ||
                selectedRow.isPack === true ? (
                    <div
                        className="row"
                        style={{
                            display: "grid",
                            gridTemplateColumns: "2fr 1fr ",
                        }}
                    >
                        <div>
                            {(licenceColumn === "Licences à ajouter" ||
                                licenceColumn === "Licences à bloquer") &&
                            ((Object.keys(selectedLicences).includes(
                                selectedRow.iuud
                            ) &&
                                selectedLicences[selectedRow.iuud].blocked >
                                    0) ||
                                (licenceColumn === "Licences à ajouter" &&
                                    Object.keys(selectedLicences).includes(
                                        selectedRow.uid
                                    ) &&
                                    selectedLicences[selectedRow.uid].blocked >
                                        0)) ? (
                                //                 ||
                                // (licenceColumn === "Licences à débloquer" &&
                                //     ((Object.keys(selectedLicences).includes(
                                //         selectedRow.uid
                                //     ) &&
                                //         selectedLicences[selectedRow.uid]
                                //             .blocked === 0) ||
                                //         (Object.keys(selectedLicences).includes(
                                //             selectedRow.iuud
                                //         ) &&
                                //             selectedLicences[selectedRow.iuud]
                                //                 .blocked === 0)))
                                <div
                                    className="hoverTable"
                                    Table-hover={` Activée(s): ${
                                        selectedRow.Type === "Pack"
                                            ? selectedLicences[
                                                  selectedRow.uid
                                              ] !== undefined &&
                                              selectedLicences[selectedRow.uid]
                                                  .actived
                                            : selectedLicences[
                                                  selectedRow.iuud
                                              ] !== undefined &&
                                              selectedLicences[selectedRow.iuud]
                                                  .actived
                                    } \n Activation le :${"03/02/2023"} \n
                                 Bloquée(s): ${
                                     selectedRow.Type === "Pack"
                                         ? selectedLicences[selectedRow.uid] !==
                                               undefined &&
                                           selectedLicences[selectedRow.uid]
                                               .blocked
                                         : selectedLicences[
                                               selectedRow.iuud
                                           ] !== undefined &&
                                           selectedLicences[selectedRow.iuud]
                                               .blocked
                                 }
                                `}
                                >
                                    <img
                                        style={{ marginLeft: "50px" }}
                                        src={ExclamationPoint}
                                        id="dropdownMenuButton1"
                                        alt="Exclamation"
                                    />
                                </div>
                            ) : null}
                        </div>
                        <div style={{ marginRight: "30px" }}>
                            {numberLicenceActive}
                        </div>
                    </div>
                ) : null}
            </div>
        );
    }
    function EconomicModelColumn({ selectedRow }: any) {
        const { selectedTypeEconomicModel, selectedLicences } = useSnapshot(
            store
        );
        function AddEconomicModel(selectedRow: any, value: any) {
            if (selectedRow.Type === "Pack") {
                if (selectedRow.subRows.length > 0) {
                    selectedRow.subRows.forEach((el: any) => {
                        if (Object.keys(updatedLicence).length > 0) {
                            listEconomicModelToUpdate[
                                el.iuud + "|" + el.uidPack
                            ] = {
                                EconomicModel: value,
                            };
                            setSelectedUpdatedEconomicModel(
                                listEconomicModelToUpdate
                            );
                            setlistSelectedLicenses(updatedLicence);
                        } else {
                            listEconomicModel[el.iuud + "|" + el.uidPack] = {
                                EconomicModel: value,
                            };
                            setlistSelectedEconomicModel(listEconomicModel);
                        }
                    });
                }
                if (Object.keys(updatedLicence).length < 0) {
                    setlistSelectedEconomicModel(listEconomicModel);
                }
            } else {
                if (Object.keys(updatedLicence).length > 0) {
                    listEconomicModelToUpdate[
                        selectedRow.iuud + "|" + selectedRow.iuud
                    ] = {
                        EconomicModel: value,
                    };
                    setSelectedUpdatedEconomicModel(listEconomicModelToUpdate);
                } else {
                    listEconomicModel[
                        selectedRow.iuud + "|" + selectedRow.iuud
                    ] = {
                        EconomicModel: value,
                    };
                    setlistSelectedEconomicModel(listEconomicModel);
                }
                if (Object.keys(updatedLicence).length < 0) {
                    setlistSelectedEconomicModel(listEconomicModel);
                }
            }
        }

        return (
            <>
                {statusLicense === "add" ? (
                    <div>
                        {(selectedRow.Type !== "Module" &&
                            selectedRow.productPack === false) ||
                        selectedRow.isPack === true ? (
                            <>
                                <div
                                    style={{ width: "150px" }}
                                    className={`${classnames({
                                        hovertext:
                                            licenceColumn ===
                                                "Licences à ajouter" &&
                                            ((Object.keys(
                                                selectedLicences
                                            ).includes(selectedRow.iuud) &&
                                                selectedLicences[
                                                    selectedRow.iuud
                                                ].blocked > 0) ||
                                                (licenceColumn ===
                                                    "Licences à ajouter" &&
                                                    Object.keys(
                                                        selectedLicences
                                                    ).includes(
                                                        selectedRow.uid
                                                    ) &&
                                                    selectedLicences[
                                                        selectedRow.uid
                                                    ].blocked > 0)),
                                    })}`}
                                    data-hover={`Il est impossible de modifier le modele économique d'un produit/pack qui contient des licences bloquées`}
                                >
                                    <StyledSelectInput
                                        menuPlacement="auto"
                                        menuPosition="fixed"
                                        name="savetypeeconomicModalinput"
                                        options={typeEconomicModel}
                                        defaultValue={
                                            selectedTypeEconomicModel[
                                                selectedRow.iuud
                                            ] !== undefined &&
                                            Object.keys(
                                                selectedTypeEconomicModel
                                            ).includes(selectedRow.iuud)
                                                ? {
                                                      label:
                                                          selectedTypeEconomicModel[
                                                              selectedRow.iuud
                                                          ][
                                                              "typeeconomicModel"
                                                          ][0]["EconomicModel"],
                                                      value:
                                                          selectedTypeEconomicModel[
                                                              selectedRow.iuud
                                                          ][
                                                              "typeeconomicModel"
                                                          ][0]["EconomicModel"],
                                                  }
                                                : ""
                                        }
                                        placeholder={"Selectionner"}
                                        onChange={(e: any) => {
                                            setIsUpdateInfoCertificate(true);
                                            if (
                                                licenceColumn ===
                                                "Licences à ajouter"
                                            ) {
                                                AddEconomicModel(
                                                    selectedRow,
                                                    e.value
                                                );
                                            }
                                        }}
                                        isDisabled={
                                            licenceColumn ===
                                                "Licences à ajouter" &&
                                            ((Object.keys(
                                                selectedLicences
                                            ).includes(selectedRow.iuud) &&
                                                selectedLicences[
                                                    selectedRow.iuud
                                                ].blocked > 0) ||
                                                (licenceColumn ===
                                                    "Licences à ajouter" &&
                                                    Object.keys(
                                                        selectedLicences
                                                    ).includes(
                                                        selectedRow.uid
                                                    ) &&
                                                    selectedLicences[
                                                        selectedRow.uid
                                                    ].blocked > 0))
                                                ? true
                                                : false
                                        }
                                    />
                                </div>
                            </>
                        ) : null}
                    </div>
                ) : (
                    <div>
                        {selectedTypeEconomicModel[selectedRow.iuud] !==
                            undefined &&
                        Object.keys(selectedTypeEconomicModel).includes(
                            selectedRow.iuud
                        )
                            ? selectedTypeEconomicModel[selectedRow.iuud][
                                  "typeeconomicModel"
                              ][0]["EconomicModel"]
                            : ""}
                    </div>
                )}
            </>
        );
    }

    function DemoColumn({ selectedRow }: any) {
        const [isChecked, setIsChecked] = React.useState(false);
        const {
            selectedLicences,
            isConfigurationLicenceModalOpened,
            selectedExistLicenceDemo,
            isCommercialLicenceModalOpened,
            isLicenceDemoConfiguration,
            isDemo,
            selectedEntity,
        } = useSnapshot(store);

        return (
            <>
                {statusLicense === "add" ? (
                    <div className="d-flex justify-content-between">
                        {(selectedRow.Type !== "Module" &&
                            selectedRow.productPack === false) ||
                        selectedRow.isPack === true ? (
                            <div
                                className={`${classnames({
                                    hovertext:
                                        (selectedLicences[selectedRow.uid] !==
                                            undefined &&
                                            selectedLicences[selectedRow.uid]
                                                .actived > 0 &&
                                            selectedExistLicenceDemo[
                                                selectedRow.uid
                                            ] !== undefined &&
                                            selectedExistLicenceDemo[
                                                selectedRow.uid
                                            ].isDemo === false) ||
                                        (selectedLicences[selectedRow.iuud] !==
                                            undefined &&
                                            selectedLicences[selectedRow.iuud]
                                                .actived > 0 &&
                                            selectedExistLicenceDemo[
                                                selectedRow.iuud
                                            ] !== undefined &&
                                            selectedExistLicenceDemo[
                                                selectedRow.iuud
                                            ]["isDemo"] === false),
                                })}`}
                                data-hover={`Il est impossible de configurer des licences demo à un produit/ pack qui contient des licences commerciale`}
                            >
                                <input
                                    type="checkbox"
                                    onChange={() => {
                                        setIsChecked(!isChecked);
                                        setIsUpdateInfoCertificate(true);
                                        if (statusLicense === "add") {
                                            if (
                                                Object.keys(updatedLicence)
                                                    .length > 0
                                            ) {
                                                if (
                                                    selectedRow.Type === "Pack"
                                                ) {
                                                    if (
                                                        Object.keys(
                                                            selectedExistLicenceDemo
                                                        ).includes(
                                                            selectedRow.uid
                                                        ) &&
                                                        selectedExistLicenceDemo[
                                                            selectedRow.uid
                                                        ].isDemo === true
                                                    ) {
                                                        setSelectedLicenceDemo(
                                                            selectedRow
                                                        );
                                                        setIsCommercialLicenceModalOpened(
                                                            !isCommercialLicenceModalOpened
                                                        );
                                                    } else {
                                                        setSelectedLicenceDemo(
                                                            selectedRow
                                                        );
                                                        setIsConfigurationLicenceModalOpened(
                                                            !isConfigurationLicenceModalOpened
                                                        );
                                                    }
                                                } else {
                                                    if (
                                                        Object.keys(
                                                            selectedExistLicenceDemo
                                                        ).includes(
                                                            selectedRow.iuud
                                                        ) &&
                                                        selectedExistLicenceDemo[
                                                            selectedRow.iuud
                                                        ]["isDemo"] === true
                                                    ) {
                                                        setSelectedLicenceDemo(
                                                            selectedRow
                                                        );
                                                        setIsCommercialLicenceModalOpened(
                                                            !isCommercialLicenceModalOpened
                                                        );
                                                    } else {
                                                        setSelectedLicenceDemo(
                                                            selectedRow
                                                        );
                                                        setIsConfigurationLicenceModalOpened(
                                                            !isConfigurationLicenceModalOpened
                                                        );
                                                    }
                                                }
                                                setlistSelectedLicenses(
                                                    updatedLicence
                                                );
                                            } else {
                                                setSelectedLicenceDemo(
                                                    selectedRow
                                                );
                                                setIsConfigurationLicenceModalOpened(
                                                    !isConfigurationLicenceModalOpened
                                                );
                                            }
                                        }
                                        setIsLicenceDemoConfiguration(
                                            !isLicenceDemoConfiguration
                                        );
                                    }}
                                    checked={
                                        selectedEntity.note === "Anomalie|" ||
                                        selectedEntity.note === "|"
                                            ? selectedRow.Type === "Pack"
                                                ? Object.keys(
                                                      selectedExistLicenceDemo
                                                  ).includes(selectedRow.uid) &&
                                                  selectedExistLicenceDemo[
                                                      selectedRow.uid
                                                  ] !== undefined &&
                                                  selectedExistLicenceDemo[
                                                      selectedRow.uid
                                                  ].isDemo === true
                                                    ? Object.keys(
                                                          isDemo
                                                      ).includes(
                                                          selectedRow.uid
                                                      )
                                                        ? isDemo[
                                                              selectedRow.uid
                                                          ]["isDemo"] === true
                                                            ? isChecked
                                                            : !isChecked
                                                        : !isChecked
                                                    : isChecked
                                                : Object.keys(
                                                      selectedExistLicenceDemo
                                                  ).includes(
                                                      selectedRow.iuud
                                                  ) &&
                                                  selectedExistLicenceDemo[
                                                      selectedRow.iuud
                                                  ] !== undefined &&
                                                  selectedExistLicenceDemo[
                                                      selectedRow.iuud
                                                  ]["isDemo"] === true
                                                ? Object.keys(isDemo).includes(
                                                      selectedRow.iuud
                                                  )
                                                    ? isDemo[selectedRow.iuud][
                                                          "isDemo"
                                                      ] === true
                                                        ? isChecked
                                                        : !isChecked
                                                    : !isChecked
                                                : isChecked
                                            : !isChecked
                                    }
                                    disabled={
                                        selectedEntity.note === "Anomalie|" ||
                                        selectedEntity.note === "|"
                                            ? selectedRow.Type === "Pack"
                                                ? selectedLicences[
                                                      selectedRow.uid
                                                  ] !== undefined &&
                                                  selectedLicences[
                                                      selectedRow.uid
                                                  ].actived > 0 &&
                                                  selectedExistLicenceDemo[
                                                      selectedRow.uid
                                                  ] !== undefined &&
                                                  selectedExistLicenceDemo[
                                                      selectedRow.uid
                                                  ].isDemo === false
                                                    ? true
                                                    : false
                                                : selectedLicences[
                                                      selectedRow.iuud
                                                  ] !== undefined &&
                                                  selectedLicences[
                                                      selectedRow.iuud
                                                  ].actived > 0 &&
                                                  selectedExistLicenceDemo[
                                                      selectedRow.iuud
                                                  ] !== undefined &&
                                                  selectedExistLicenceDemo[
                                                      selectedRow.iuud
                                                  ]["isDemo"] === false
                                                ? true
                                                : false
                                            : true
                                    }
                                />
                            </div>
                        ) : null}
                    </div>
                ) : (
                    <div>
                        {selectedExistLicenceDemo[selectedRow.uid] !==
                            undefined ||
                        selectedExistLicenceDemo[selectedRow.iuud] !== undefined
                            ? selectedRow.Type === "Pack"
                                ? selectedExistLicenceDemo[selectedRow.uid][
                                      "isDemo"
                                  ] === false
                                    ? "non"
                                    : "oui"
                                : selectedExistLicenceDemo[selectedRow.iuud][
                                      "isDemo"
                                  ] === false
                                ? "non"
                                : "oui"
                            : ""}
                    </div>
                )}
            </>
        );
    }
    function ActionColumn({ selectedRow }: any) {
        const { selectedLicences, selectedExistLicenceDemo } = useSnapshot(
            store
        );
        return (
            <>
                <div className="d-flex justify-content-between">
                    {(selectedRow.Type !== "Module" &&
                        selectedRow.productPack === false) ||
                    selectedRow.isPack === true ? (
                        <div
                            style={{
                                pointerEvents:
                                    selectedRow.Type === "Pack"
                                        ? licenceColumn !== "Licences à ajouter"
                                            ? "none"
                                            : selectedLicences[
                                                  selectedRow.uid
                                              ] !== undefined &&
                                              selectedLicences[selectedRow.uid]
                                                  .actived > 0 &&
                                              selectedExistLicenceDemo[
                                                  selectedRow.uid
                                              ] !== undefined &&
                                              selectedExistLicenceDemo[
                                                  selectedRow.uid
                                              ].isDemo === false
                                            ? "none"
                                            : "auto"
                                        : licenceColumn !== "Licences à ajouter"
                                        ? "none"
                                        : selectedLicences[selectedRow.iuud] !==
                                              undefined &&
                                          selectedLicences[selectedRow.iuud]
                                              .actived > 0 &&
                                          selectedExistLicenceDemo[
                                              selectedRow.iuud
                                          ] !== undefined &&
                                          selectedExistLicenceDemo[
                                              selectedRow.iuud
                                          ]["isDemo"] === false
                                        ? "none"
                                        : "auto",

                                opacity:
                                    selectedRow.Type === "Pack"
                                        ? licenceColumn !== "Licences à ajouter"
                                            ? "0.8"
                                            : selectedLicences[
                                                  selectedRow.uid
                                              ] !== undefined &&
                                              selectedLicences[selectedRow.uid]
                                                  .actived > 0 &&
                                              selectedExistLicenceDemo[
                                                  selectedRow.uid
                                              ] !== undefined &&
                                              selectedExistLicenceDemo[
                                                  selectedRow.uid
                                              ].isDemo === false
                                            ? "0.8"
                                            : ""
                                        : licenceColumn !== "Licences à ajouter"
                                        ? "0.8"
                                        : selectedLicences[selectedRow.iuud] !==
                                              undefined &&
                                          selectedLicences[selectedRow.iuud]
                                              .actived > 0 &&
                                          selectedExistLicenceDemo[
                                              selectedRow.iuud
                                          ] !== undefined &&
                                          selectedExistLicenceDemo[
                                              selectedRow.iuud
                                          ]["isDemo"] === false
                                        ? "0.8"
                                        : "",
                                fill:
                                    selectedRow.Type === "Pack"
                                        ? licenceColumn !== "Licences à ajouter"
                                            ? "gray"
                                            : selectedLicences[
                                                  selectedRow.uid
                                              ] !== undefined &&
                                              selectedLicences[selectedRow.uid]
                                                  .actived > 0 &&
                                              selectedExistLicenceDemo[
                                                  selectedRow.uid
                                              ] !== undefined &&
                                              selectedExistLicenceDemo[
                                                  selectedRow.uid
                                              ].isDemo === false
                                            ? "gray"
                                            : ""
                                        : licenceColumn !== "Licences à ajouter"
                                        ? "gray"
                                        : selectedLicences[selectedRow.iuud] !==
                                              undefined &&
                                          selectedLicences[selectedRow.iuud]
                                              .actived > 0 &&
                                          selectedExistLicenceDemo[
                                              selectedRow.iuud
                                          ] !== undefined &&
                                          selectedExistLicenceDemo[
                                              selectedRow.iuud
                                          ]["isDemo"] === false
                                        ? "gray"
                                        : "",
                            }}
                        >
                            <SettingsIcon height={25} width={25} />
                        </div>
                    ) : null}
                </div>
            </>
        );
    }

    function SelectAccountDropdown({ selectedRow }: any) {
        if (!selectedRow.original.isPrincipalProduct) {
            return <div className="w-100"></div>;
        } else {
            return null;
        }
    }

    React.useEffect(() => {
        const abortController = new AbortController();
        return () => {
            setIsUpdateInfoCertificate(false);
            abortController.abort();
        };
    }, []);

    return (
        <div>
            <DynamicTable
                maxHeight={"calc(100vh - 250px)"}
                minHeight={"calc(100vh - 250)"}
                customJsxSideFilterButton={
                    <LicencesActionsMenu statusLicense={statusLicense} />
                }
                url={url}
                actionColumn={SelectAccountDropdown}
                showFilter
                canSelect
                customSelect
                canExpand
                showGlobalFilter
                filterActive={filterActive}
                setSelectedRows={setSelectedRows}
                setLocalFilterActive={setLocalFilterActive}
                arrayOfCustomColumns={arrayOfCustomColumns}
                setDataIsUpdated={setDataIsUpdated}
                dataIsUpdated={dataIsUpdated}
            />
        </div>
    );
}
