import React from "react";
import {
    StyledModal,
    StyledButton,
    StyledLabel,
    StyledIconButton,
    CrossIcon,
} from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { store } from "../store";

import "../index.css";
import TableProfilHistory from "./TableProfilHistory";

type ListHistoryProfilModalType = {
    isModalOpened: boolean;
    setIsModalOpened: Function;
};

export function HistoryProfilModal({
    isModalOpened,
    setIsModalOpened,
}: ListHistoryProfilModalType) {
    const { t } = useTranslation();
    const { historyProfile, SelectedProfil, isHitoryAllProducts } = useSnapshot(
        store
    );
    let UidProfil: any = [];
    let Designation: any = [];
    let Type: any = [];
    historyProfile.forEach((element: any) => {
        UidProfil.push(element.UidProfil);

        Designation.push(element.Designation);
        Type.push(element.TypeProfil);
    });

    let url = "";
    if (isHitoryAllProducts) {
        url = `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/HistoryOneProfil?uidProfil=${UidProfil}&nameProfil=${Designation}&typeProfil=${Type}`;
    } else {
        url = `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/HistoryOneProfil?uidProfil=${SelectedProfil.UidProfil}&nameProfil=${SelectedProfil.Designation}&typeProfil=${SelectedProfil.TypeProfil}`;
    }

    return (
        <React.Fragment>
            <StyledModal className="custom-modal-style" isOpen={isModalOpened}>
                <ModalHeader>
                    <div
                        className="d-flex align-items-center justify-content-between"
                        style={{
                            height: "35px",
                            display: "grid",
                            gridTemplateColumns: "1.8fr 0.2fr",
                            gridTemplateRows: "1fr",
                            gap: "1411px",
                        }}
                    >
                        <span className="title-modal-style">
                            {t("HISTORIQUE PROFIL(S)")}
                        </span>
                        <StyledIconButton
                            icon="CrossIcon"
                            onClick={() => {
                                setIsModalOpened(!isModalOpened);
                            }}
                        >
                            <CrossIcon
                                cursor="pointer"
                                height={10}
                                width={10}
                            />
                        </StyledIconButton>
                    </div>{" "}
                </ModalHeader>

                <ModalBody>
                    {isHitoryAllProducts ? null : (
                        <div
                            style={{
                                display: "grid",
                                gridTemplateColumns: "1fr 1fr 1fr 1fr auto",
                                gap: "20px",
                                background: "#E5E4E4AB",
                                height: "56px",
                                marginBottom: "5px",
                                alignItems: "center",
                            }}
                        >
                            <StyledLabel
                                style={{
                                    marginLeft: "24px",
                                    fontWeight: "bold",
                                    fontSize: "20px",
                                }}
                            >
                                Désignation : {SelectedProfil.Designation}
                            </StyledLabel>
                            <StyledLabel
                                style={{
                                    marginLeft: "24px",
                                    fontWeight: "bold",
                                    fontSize: "20px",
                                }}
                            >
                                Type : {SelectedProfil.TypeProfil}
                            </StyledLabel>
                        </div>
                    )}

                    <TableProfilHistory url={url} />
                </ModalBody>

                <ModalFooter className="d-flex justify-content-end">
                    <div className="d-flex align-items-center">
                        <div className="ml-3">
                            <StyledButton
                                disabled={false}
                                rounded
                                variant="light"
                                outline={true}
                                size="sm"
                                style={{
                                    background: "black",
                                    color: "white",
                                    padding: "0px",
                                    width: "150px",
                                    height: "45px",
                                }}
                                onClick={() => setIsModalOpened(!isModalOpened)}
                            >
                                {t("Close")}
                            </StyledButton>
                        </div>
                    </div>
                </ModalFooter>
            </StyledModal>
        </React.Fragment>
    );
}
