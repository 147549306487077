import React from "react";
import { useTranslation } from "react-i18next";
import {
    Card,
    CardBody,
    Col,
    Row,
    CardFooter,
    Container,
    CardHeader,
} from "reactstrap";
import {
    CrossIcon,
    RefreshIcon,
    StyledButton,
    StyledLabel,
    StyledTextInput,
} from "@aureskonnect/react-ui";
import { AvForm } from "availity-reactstrap-validation";
import { useSnapshot } from "valtio";
import { v4 as uuidv4 } from "uuid";

import {
    setSelectedTypeGestionnaireDeComptes,
    setModifTypeGestionnaireDeComptes,
    setEditTypeGestionnaireDeComptes,
    setIsAlertupdateTypeGestionnaireDeCompteModal,
    store,
    setUsedaccountManagerTypeWidhoutEntity,
    setUsedaccountManagerType,
    setIsAlertAccountManagerTypeUsed,
} from "../store";
import { showToast } from "@components/Common/ToastShow";
import Breadcurmb from "@components/Common/Breadcrumb/index";
import MessageInfo from "@components/Common/MessageInfo";
import TableListTypeGestionnaireDeComptes from "./TableListTypeGestionnaireDeComptes";
import { HistoryTypeGestionnaireDeComptes } from "./HistoryTypeGestionnaireDeComptes";
import AlertUpdateTypeGestionnaireDeCompteModal from "./AlertUpdateTypeGestionnaireDeCompteModal";
import PageTitle from "@components/Common/PageTitle";
import AlertAccountManagerTypeUsed from "./AlertAccountManagerTypeUsed";

export default function TypeGestionnaireDeComptes() {
    const { t } = useTranslation();
    const uuidEntity = localStorage.getItem("Uid_Entitie_User");

    const [designation, setDesignation] = React.useState("");
    const [descriptif, setDescriptif] = React.useState("");
    const [isHidden, setIsHidden] = React.useState(false);
    const [isEditAction, setIsEditAction] = React.useState<boolean>(false);
    const [dataIsUpdated, setDataIsUpdated] = React.useState<number | boolean>(
        false
    );
    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
    const [
        isTypeGetstionnaireDeComptesState,
        setIsTypeGetstionnaireDeComptesState,
    ] = React.useState<boolean>(true);
    const [isEmptyDes, setIsEmptyDes] = React.useState<boolean>(false);
    const [
        listDesignationTypeGestionnaireDeComptes,
        setListDesignationTypeGestionnaireDeComptes,
    ] = React.useState([]);
    const [
        isHisotyTypeGestionnaireDeComptesModalOpened,
        setIsHisotyTypeGestionnaireDeComptesModalOpened,
    ] = React.useState<boolean>(false);
    const [counter, setCounter] = React.useState<number>(2000);
    const [inputValue, setInputValue] = React.useState<boolean>(false);
    const [isReset, setIsReset] = React.useState<boolean>(false);
    const [isResetDes, setIsResetDes] = React.useState<boolean>(false);
    const {
        selectedTypeGestionnaireDeComptes,
        selectedTypeGestionnaireDeComptesBeforeUpdate,
        isAlertupdateTypeGestionnaireDeCompteModal,
        editTypeGestionnaireDeComptes,
        modifTypeGestionnaireDeComptes,
        uuidTypeGestionnaireDeCompteHistory,
        isVisible,
        usedaccountManagerType,
        usedaccountManagerTypeWidhoutEntity,
        isAlertAccountManagerTypeUsed,
    } = useSnapshot(store);

    let titleBreadcurmb = "";
    let urlTableListeTypeGestionnaireDeComptes = "";
    if (isTypeGetstionnaireDeComptesState) {
        titleBreadcurmb = t(
            "CONFIGURATION / TYPE ACCOUNT MANAGER ENABLED"
        );
    } else {
        titleBreadcurmb = t("CONFIGURATION / TYPE ACCOUNT MANAGER ARCHIVED(S)");
    }
    urlTableListeTypeGestionnaireDeComptes = `${
        process.env.REACT_APP_CONSOLE_ADMIN_ACCESS
    }/listTypeGestionnaireDeComptes?archived=${!isTypeGetstionnaireDeComptesState}&uuidEntity=${uuidEntity}`;

    const handleClick = (event: any) => {
        if (isEditAction) {
            if (isReset === true) {
                setIsHidden(!isHidden);
            } else {
                if (
                    selectedTypeGestionnaireDeComptes.Descriptif ===
                        selectedTypeGestionnaireDeComptesBeforeUpdate.Descriptif &&
                    selectedTypeGestionnaireDeComptes.Designation ===
                        selectedTypeGestionnaireDeComptesBeforeUpdate.Designation
                ) {
                    setIsHidden(!isHidden);
                } else {
                    if (
                        selectedTypeGestionnaireDeComptes.Designation.trim()
                            .length === 0
                    ) {
                        showToast(
                            false,
                            `${t("Le champ 'Désignation' doit être rempli")}`
                        );
                        setIsEmptyDes(true);
                    } else {
                        if (
                            usedaccountManagerTypeWidhoutEntity.includes(
                                selectedTypeGestionnaireDeComptes.uuid
                            )
                        ) {
                            setIsAlertAccountManagerTypeUsed(
                                !isAlertAccountManagerTypeUsed
                            );
                        } else {
                            UpdateTypeGestionnaireDeComptes();
                        }
                    }
                }
            }
        } else {
            event.preventDefault();
            if (designation.trim().length === 0) {
                showToast(
                    false,
                    `${t("Le champ 'Désignation' doit être rempli")}`
                );
                setIsEmptyDes(true);
            } else {
                AddTypeGestionnaireDeComptes();
            }
        }
    };

    function saveHistoryChanges(oldData: any, newData: any) {
        fetch(`${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/HistoryChanges`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                oldData: oldData,
                newData: newData,
                uidUpdatedData: uuidEntity,
                typeHistory: "6",
            }),
        })
            .then((response) => {
                response.json().then((data) => {
                    setTimeout(function () {
                        setIsHidden(!isHidden);
                    }, 2000);
                    return data.error;
                });
            })
            .catch((e: any) => {
                return false;
            });
    }
    
    function AddTypeGestionnaireDeComptes() {
        const exist = listDesignationTypeGestionnaireDeComptes.filter(
            (word: any) => word === designation
        );
        let newTypeGestionnaireDeComptes = {
            designation: designation,
            descriptif: descriptif,
            uuidEntity: uuidEntity,
            nouveau: true,
            archived: false,
            uuid: uuidv4(),
        };
        if (exist.length < 1) {
            fetch(
                `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/addTypeGestionnaireDeComptes`,
                {
                    method: "PUT",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        uuid: newTypeGestionnaireDeComptes.uuid,
                        TypeGestionnaireDeComptes: JSON.stringify(
                            newTypeGestionnaireDeComptes
                        ),
                    }),
                }
            )
                .then((response) => {
                    response.json().then((data) => {
                        if (!data.error) {
                            saveHistoryChanges(
                                {},
                                newTypeGestionnaireDeComptes
                            );
                            showToast(
                                true,
                                `${t(
                                    "Account Manager Type"
                                )} ' ${designation} ' ${t(
                                    "was successfully added."
                                )}`
                            );
                            setDescriptif("");
                            setDesignation("");
                            setDataIsUpdated(!dataIsUpdated);
                            setIsHidden(!isHidden);
                            setModifTypeGestionnaireDeComptes(false);
                            setIsEmptyDes(false);
                        } else {
                            showToast(
                                false,
                                `${t(
                                    "Oops!, Account Manager Type is not added."
                                )}`
                            );
                        }
                    });
                })
                .catch((e: any) => {
                    console.log({ error: e.message });
                });
        } else {
            showToast(
                false,
                `${t("The designation already exists. Please enter another")}`
            );
        }
    }

    async function UpdateTypeGestionnaireDeComptes() {
        const exist = listDesignationTypeGestionnaireDeComptes.filter(
            (word: any) => word === designation
        );
        if (
            selectedTypeGestionnaireDeComptesBeforeUpdate.Designation ===
            selectedTypeGestionnaireDeComptes.Designation
        ) {
            const old_module = {
                descriptif:
                    selectedTypeGestionnaireDeComptesBeforeUpdate.Descriptif,
                designation:
                    selectedTypeGestionnaireDeComptesBeforeUpdate.Designation,
                uuid: selectedTypeGestionnaireDeComptesBeforeUpdate.uuid,
                uuidEntity: uuidEntity,
            };
            const new_module = {
                descriptif: selectedTypeGestionnaireDeComptes.Descriptif,
                designation: selectedTypeGestionnaireDeComptes.Designation,
                uuid: selectedTypeGestionnaireDeComptes.uuid,
                uuidEntity: uuidEntity,
            };

            let TypeGestionnaireDeComptesUpdated = {
                descriptif: selectedTypeGestionnaireDeComptes.Descriptif,
                designation: selectedTypeGestionnaireDeComptes.Designation,
                uuid: selectedTypeGestionnaireDeComptes.uuid,
                uuidEntity: uuidEntity,
            };
            fetch(
                `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/updateTypeGestionnaireDeComptes`,
                {
                    method: "PUT",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        dataTypeGestionnaireDeComptes: TypeGestionnaireDeComptesUpdated,
                    }),
                }
            )
                .then((response) => {
                    response.json().then((data) => {
                        if (data.error === false) {
                            setEditTypeGestionnaireDeComptes("");
                            setDescriptif("");
                            setDesignation("");
                            setModifTypeGestionnaireDeComptes(false);
                            setSelectedTypeGestionnaireDeComptes("");
                            showToast(
                                true,
                                `${t(
                                    "Account Manager Type changed successfully."
                                )}`
                            );
                            setIsHidden(!isHidden);
                            setDataIsUpdated(true);
                            saveHistoryChanges(old_module, new_module);
                        }
                    });
                })
                .catch((e: any) => {
                    return false;
                });
        } else {
            if (exist.length < 1) {
                const old_module = {
                    descriptif:
                        selectedTypeGestionnaireDeComptesBeforeUpdate.Descriptif,
                    designation:
                        selectedTypeGestionnaireDeComptesBeforeUpdate.Designation,
                    uuid: selectedTypeGestionnaireDeComptesBeforeUpdate.uuid,
                    uuidEntity: uuidEntity,
                };
                const new_module = {
                    descriptif: selectedTypeGestionnaireDeComptes.Descriptif,
                    designation: selectedTypeGestionnaireDeComptes.Designation,
                    uuid: selectedTypeGestionnaireDeComptes.uuid,
                    uuidEntity: uuidEntity,
                };

                let TypeGestionnaireDeComptesUpdated = {
                    descriptif: selectedTypeGestionnaireDeComptes.Descriptif,
                    designation: selectedTypeGestionnaireDeComptes.Designation,
                    uuid: selectedTypeGestionnaireDeComptes.uuid,
                    uuidEntity: uuidEntity,
                };
                fetch(
                    `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/updateTypeGestionnaireDeComptes`,
                    {
                        method: "PUT",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({
                            dataTypeGestionnaireDeComptes: TypeGestionnaireDeComptesUpdated,
                        }),
                    }
                )
                    .then((response) => {
                        response.json().then((data) => {
                            if (data.error === false) {
                                setEditTypeGestionnaireDeComptes("");
                                setDescriptif("");
                                setDesignation("");
                                setModifTypeGestionnaireDeComptes(false);
                                setSelectedTypeGestionnaireDeComptes("");
                                showToast(
                                    true,
                                    `${t(
                                        "Account Manager Type changed successfully."
                                    )}`
                                );
                                setIsHidden(!isHidden);
                                setDataIsUpdated(true);
                                saveHistoryChanges(old_module, new_module);
                            }
                        });
                    })
                    .catch((e: any) => {
                        return false;
                    });
            } else {
                showToast(
                    false,
                    `${t(
                        "The designation already exists. Please enter another"
                    )}`
                );
            }
        }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    async function handleListDesignationTypeGestionnaireDeComptes() {
        try {
            await fetch(
                `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/listDesignationTypeGestionnaireDeComptes`,
                {
                    method: "post",
                    headers: { "Content-Type": "application/json" },
                }
            )
                .then((response) => response.json())
                .then((data) => {
                    setListDesignationTypeGestionnaireDeComptes(data);
                });
        } catch (error) {
            console.log(error);
        }
    }
    React.useEffect(() => {
        handleListDesignationTypeGestionnaireDeComptes();
        // eslint-disable-next-line
    }, [dataIsUpdated]);

    React.useEffect(() => {
        fetch(
            `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/accountManagerTypeUsed`,
            {
                method: "get",
                headers: { "Content-Type": "application/json" },
            }
        )
            .then((response) => {
                response.json().then((data) => {
                    if (data === false) {
                    } else {
                        let array: any = [];
                        data.forEach((el: any) => {
                            const elementUuid = el.split("|");
                            array.push(elementUuid[0]);
                        });
                        setUsedaccountManagerTypeWidhoutEntity(array);
                        setUsedaccountManagerType(data);
                    }
                });
                return {
                    usedaccountManagerType,
                    usedaccountManagerTypeWidhoutEntity,
                };
            })
            .catch((e: any) => {
                console.log({ error: e.message });
            });

        // eslint-disable-next-line
    }, [dataIsUpdated]);

    return (
        <React.Fragment>
            <PageTitle title="TYPE GESTIONNAIRE DE COMPTES" />
            <div className="">
                <Container fluid style={{ marginTop: "-15px" }}>
                    <Row>
                        <Col>
                            <Breadcurmb
                                title={titleBreadcurmb}
                                breadcrumbBackgroundColor={""}
                            />
                        </Col>
                        <MessageInfo
                            message={t(
                                "Account manager types such as Franchise, Reseller, Group, etc... are configured to be used when adding a customer account"
                            )}
                        />
                    </Row>

                    <CardBody
                        style={{
                            padding: "1px",
                        }}
                    ></CardBody>
                    <div
                        className="row "
                        style={{
                            display: "grid",
                            gridTemplateColumns: isHidden ? "2fr 1fr" : "auto",
                            gap: "4px",
                        }}
                    >
                        <TableListTypeGestionnaireDeComptes
                            setIsHidden={setIsHidden}
                            isHidden={isHidden}
                            urlTable={urlTableListeTypeGestionnaireDeComptes}
                            setIsEditAction={setIsEditAction}
                            isEditAction={isEditAction}
                            setDataIsUpdated={setDataIsUpdated}
                            dataIsUpdated={dataIsUpdated}
                            setSelectedRows={setSelectedRows}
                            selectedRows={selectedRows}
                            setIsTypeGetstionnaireDeComptesState={
                                setIsTypeGetstionnaireDeComptesState
                            }
                            isTypeGetstionnaireDeComptesState={
                                isTypeGetstionnaireDeComptesState
                            }
                            isHisotyTypeGestionnaireDeComptesModalOpened={
                                isHisotyTypeGestionnaireDeComptesModalOpened
                            }
                            setIsHisotyTypeGestionnaireDeComptesModalOpened={
                                setIsHisotyTypeGestionnaireDeComptesModalOpened
                            }
                            setEditTypeGestionnaireDeComptes={
                                setEditTypeGestionnaireDeComptes
                            }
                            setIsAlertupdateTypeGestionnaireDeCompteModal={
                                setIsAlertupdateTypeGestionnaireDeCompteModal
                            }
                        />

                        {!isHidden ? null : (
                            <Card className="border ml-2">
                                <CardHeader
                                    className="Headercard  d-flex justify-content-between pb-2"
                                    style={{
                                        height: "11.7%",
                                        backgroundColor: "white",
                                        border: "1px solid rgba(0,0,0,.125)",
                                    }}
                                >
                                    <div className=" d-flex align-items-center justify-content-center">
                                        <h4
                                            className="m-0 pl-2"
                                            style={{
                                                font:
                                                    "normal normal 600 20px/27px Segoe UI",
                                            }}
                                        >
                                            {isEditAction
                                                ? isTypeGetstionnaireDeComptesState
                                                    ? `${t(
                                                          "Change account manager type"
                                                      )}`
                                                    : `${t(
                                                          "View account manager type"
                                                      )}`
                                                : `${t(
                                                      "Add an account manager type"
                                                  )}`}
                                        </h4>
                                    </div>

                                    <div className="d-flex align-items-center ">
                                        {isTypeGetstionnaireDeComptesState ? (
                                            <RefreshIcon
                                                cursor="pointer"
                                                height={20}
                                                width={20}
                                                onClick={() => {
                                                    if (isEditAction) {
                                                        setIsReset(true);
                                                        setIsResetDes(true);
                                                    } else {
                                                        setDescriptif("");
                                                        setDesignation("");
                                                    }
                                                    setModifTypeGestionnaireDeComptes(
                                                        false
                                                    );
                                                }}
                                            />
                                        ) : (
                                            <></>
                                        )}

                                        <div className=" bd-highlight align-items-center ml-4">
                                            <CrossIcon
                                                cursor="pointer"
                                                height={17}
                                                width={17}
                                                onClick={() => {
                                                    if (
                                                        modifTypeGestionnaireDeComptes ===
                                                        true
                                                    ) {
                                                        setIsAlertupdateTypeGestionnaireDeCompteModal(
                                                            true
                                                        );
                                                        setIsHidden(isHidden);
                                                    } else {
                                                        setModifTypeGestionnaireDeComptes(
                                                            false
                                                        );
                                                        setEditTypeGestionnaireDeComptes(
                                                            ""
                                                        );
                                                        setInputValue(false);
                                                        setIsHidden(!isHidden);
                                                        setDescriptif("");
                                                        setDesignation("");
                                                        setIsEmptyDes(false);
                                                        setIsReset(false);
                                                        setIsResetDes(false);
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardBody
                                    style={{
                                        background: "#FFFFFF",
                                        border: "1px solid rgba(0,0,0,.125)",
                                        marginTop: "4px",
                                        overflow: "auto",
                                        maxHeight: `calc(100vh - (500px + ${
                                            isVisible ? "-71px" : "0px"
                                        }))`,
                                    }}
                                >
                                    <AvForm>
                                        <StyledLabel htmlFor="example-input">
                                            {t("Designation*")}
                                        </StyledLabel>
                                        <StyledTextInput
                                            style={{
                                                pointerEvents: !isTypeGetstionnaireDeComptesState
                                                    ? "none"
                                                    : "auto",
                                                opacity: !isTypeGetstionnaireDeComptesState
                                                    ? "0.8"
                                                    : "",
                                                border: isEmptyDes
                                                    ? "1px solid red"
                                                    : "1px solid #ced4da",
                                            }}
                                            id="example-input"
                                            name="example-input"
                                            placeholder="Saisir"
                                            autoFocus={
                                                !isTypeGetstionnaireDeComptesState
                                                    ? false
                                                    : true
                                            }
                                            onChange={(e: any) => {
                                                setDesignation(
                                                    e.target.value.trim()
                                                );
                                                setIsEmptyDes(false);
                                                // setIsReset(false);
                                                setIsResetDes(false);
                                                if (
                                                    selectedTypeGestionnaireDeComptes !==
                                                    ""
                                                ) {
                                                    setSelectedTypeGestionnaireDeComptes(
                                                        {
                                                            ...selectedTypeGestionnaireDeComptes,
                                                            Designation:
                                                                e?.target.value,
                                                        }
                                                    );
                                                    setModifTypeGestionnaireDeComptes(
                                                        true
                                                    );
                                                }
                                            }}
                                            value={
                                                selectedTypeGestionnaireDeComptes ===
                                                ""
                                                    ? designation
                                                    : isResetDes
                                                    ? selectedTypeGestionnaireDeComptesBeforeUpdate?.Designation
                                                    : selectedTypeGestionnaireDeComptes?.Designation
                                            }
                                        />
                                        {isEmptyDes ? (
                                            <div style={{ color: "red" }}>
                                                Ce champ est obligatoire
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </AvForm>

                                    <AvForm>
                                        <StyledLabel htmlFor="example-input">
                                            {t("Description")}
                                        </StyledLabel>
                                        <StyledTextInput
                                            style={{
                                                pointerEvents: !isTypeGetstionnaireDeComptesState
                                                    ? "none"
                                                    : "auto",
                                                opacity: !isTypeGetstionnaireDeComptesState
                                                    ? "0.8"
                                                    : "",
                                            }}
                                            id="example-input"
                                            name="example-input"
                                            placeholder="Saisir"
                                            maxlength={2000}
                                            onChange={(e: any) => {
                                                setDescriptif(
                                                    e.target.value.trim()
                                                );
                                                setInputValue(true);
                                                setIsReset(false);
                                                setCounter(
                                                    2000 - e.target.value.length
                                                );
                                                if (
                                                    selectedTypeGestionnaireDeComptes !==
                                                    ""
                                                ) {
                                                    setSelectedTypeGestionnaireDeComptes(
                                                        {
                                                            ...selectedTypeGestionnaireDeComptes,
                                                            Descriptif:
                                                                e?.target.value,
                                                        }
                                                    );
                                                    setModifTypeGestionnaireDeComptes(
                                                        true
                                                    );
                                                }
                                            }}
                                            value={
                                                selectedTypeGestionnaireDeComptes ===
                                                ""
                                                    ? descriptif
                                                    : isReset
                                                    ? selectedTypeGestionnaireDeComptesBeforeUpdate?.Descriptif
                                                    : selectedTypeGestionnaireDeComptes?.Descriptif
                                            }
                                            type="textarea"
                                        />
                                        <div className="d-flex justify-content-end ">
                                            {isEditAction ? (
                                                isReset ? (
                                                    <small>
                                                        Nombre de caractère
                                                        maximal :
                                                        {2000 -
                                                            selectedTypeGestionnaireDeComptesBeforeUpdate
                                                                .Descriptif
                                                                .length}
                                                        /2000
                                                    </small>
                                                ) : selectedTypeGestionnaireDeComptes
                                                      .Descriptif.length ===
                                                  0 ? (
                                                    <small>
                                                        Nombre de caractère
                                                        maximal : 2000
                                                    </small>
                                                ) : (
                                                    <small>
                                                        Nombre de caractère
                                                        maximal :
                                                        {2000 -
                                                            selectedTypeGestionnaireDeComptes
                                                                .Descriptif
                                                                .length}
                                                        /2000
                                                    </small>
                                                )
                                            ) : inputValue ? (
                                                <small>
                                                    Nombre de caractère maximal
                                                    :{counter}
                                                    /2000
                                                </small>
                                            ) : (
                                                <small>
                                                    Nombre de caractère maximal
                                                    : 2000
                                                </small>
                                            )}
                                        </div>
                                    </AvForm>
                                </CardBody>
                                <CardFooter
                                    className="Footercard p-0"
                                    style={{
                                        background: "#FFFFFF",
                                    }}
                                >
                                    <div className=" d-flex flex-wrap d-flex flex-row-reverse bd-highlight ">
                                        <div className="p-2 bd-highlight">
                                            <StyledButton
                                                disabled={
                                                    !isTypeGetstionnaireDeComptesState
                                                        ? true
                                                        : false
                                                }
                                                rounded
                                                variant="primary"
                                                outline={false}
                                                size="sm"
                                                style={{
                                                    color: "white",
                                                    padding: "0px",
                                                    width: "150px",
                                                    height: "45px",
                                                }}
                                                onClick={(e: any) => {
                                                    handleClick(e);
                                                }}
                                            >
                                                {t("Validate")}
                                            </StyledButton>
                                        </div>
                                        <div className="p-2 bd-highlight">
                                            <StyledButton
                                                disabled={false}
                                                rounded
                                                variant="light"
                                                outline={true}
                                                size="sm"
                                                style={{
                                                    background: "white",
                                                    color: "black",
                                                    padding: "0px",
                                                    width: "150px",
                                                    height: "45px",
                                                }}
                                                onClick={() => {
                                                    if (
                                                        modifTypeGestionnaireDeComptes ===
                                                        true
                                                    ) {
                                                        setIsAlertupdateTypeGestionnaireDeCompteModal(
                                                            true
                                                        );
                                                        setIsHidden(isHidden);
                                                    } else {
                                                        setModifTypeGestionnaireDeComptes(
                                                            false
                                                        );
                                                        setEditTypeGestionnaireDeComptes(
                                                            ""
                                                        );
                                                        setInputValue(false);
                                                        setIsHidden(!isHidden);
                                                        setDescriptif("");
                                                        setDesignation("");
                                                        setIsEmptyDes(false);
                                                        setIsReset(false);
                                                        setIsResetDes(false);
                                                    }
                                                }}
                                            >
                                                {t("Cancel")}
                                            </StyledButton>
                                        </div>
                                    </div>
                                </CardFooter>
                            </Card>
                        )}
                    </div>
                </Container>
            </div>
            <HistoryTypeGestionnaireDeComptes
                isHisotyTypeGestionnaireDeComptesModalOpened={
                    isHisotyTypeGestionnaireDeComptesModalOpened
                }
                setIsHisotyTypeGestionnaireDeComptesModalOpened={
                    setIsHisotyTypeGestionnaireDeComptesModalOpened
                }
                selectedRows={selectedRows}
                uuidTypeGestionnaireDeCompteHistory={
                    uuidTypeGestionnaireDeCompteHistory
                }
            />
            <AlertUpdateTypeGestionnaireDeCompteModal
                isAlertupdateTypeGestionnaireDeCompteModal={
                    isAlertupdateTypeGestionnaireDeCompteModal
                }
                setIsAlertupdateTypeGestionnaireDeCompteModal={
                    setIsAlertupdateTypeGestionnaireDeCompteModal
                }
                setModifTypeGestionnaireDeComptes={
                    setModifTypeGestionnaireDeComptes
                }
                setIsHidden={setIsHidden}
                isHidden={isHidden}
                setEditTypeGestionnaireDeComptes={
                    setEditTypeGestionnaireDeComptes
                }
                editTypeGestionnaireDeComptes={editTypeGestionnaireDeComptes}
                selectedRows={selectedRows}
                setDescriptif={setDescriptif}
                setDesignation={setDesignation}
                setIsEmptyDes={setIsEmptyDes}
            />
            <AlertAccountManagerTypeUsed
                isAlertAccountManagerTypeUsed={isAlertAccountManagerTypeUsed}
                setIsAlertAccountManagerTypeUsed={
                    setIsAlertAccountManagerTypeUsed
                }
                listDesignationTypeGestionnaireDeComptes={
                    listDesignationTypeGestionnaireDeComptes
                }
                designation={designation}
                setDescriptif={setDescriptif}
                setDesignation={setDesignation}
                setIsHidden={setIsHidden}
                isHidden={isHidden}
                setDataIsUpdated={setDataIsUpdated}
            />
        </React.Fragment>
    );
}
