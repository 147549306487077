import React, { useState } from "react";
import {
    Card,
    CardBody,
    CardFooter,
    Col,
    Row,
    Container,
    CardHeader,
} from "reactstrap";
import {
    CrossIcon,
    RefreshIcon,
    StyledButton,
    StyledLabel,
    StyledSelectInput,
    StyledTextInput,
} from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { AvForm } from "availity-reactstrap-validation";

import Breadcurmb from "@components/Common/Breadcrumb/index";
import NoOptionsMessage from "../Motifs/NoOptionsMessage";
import { showToast } from "@components/Common/ToastShow";
import MessageInfo from "@components/Common/MessageInfo";
import {
    store,
    setSelectedProfil,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setUsedProfile,
    setIsAlertProfilUsed,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setUsedProfileWidhoutEntity,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setAutorisationProfileEntityConnected,
    setIsModifyMultipleAuthorizationModalOpen,
} from "../store";
import PageTitle from "@components/Common/PageTitle";
import MultiCustomSelect from "@components/Common/StyledComponent/MultiCustomSelect";
import {
    clearMultiCustomSelectionData,
    clearData,
} from "@components/Common/StyledComponent/store";
import { ModalWrapper } from "@pages/Authorizations/ModalWrapper";
import TableListProfil from "./TableListProfiles";
import { HistoryProfilModal } from "@pages/History/HistoryProfilModal";
import AlertProfileUsed from "./AlertProfileUsed";
import AlertModifyMultipleAuthorization from "./AlertModifyMultipleAuthorization";
// type initialState = {
//     isHidden: boolean;
//     designation: string;
//     balise: string;
//     description: string;
//     etatProfil: string;
//     profilesData: any;
//     isEditAction: boolean;
//     selectedOptions: any;
//     optionsCompte: any;
// };

export default function Configurations() {
    const uidEntity = localStorage.getItem("Uid_Entitie_User");
    const [isHidden, setIsHidden] = useState(false);
    const [designation, setDesignation] = useState("");
    // eslint-disable-next-line
    const [balise, setBalise] = useState("");
    const [description, setDescription] = useState("");
    const { t } = useTranslation();
    const [typeDuCompteOptions] = React.useState<any>([
        { label: "Boutique", value: "Boutique" },
    ]);
    const [optionsBalise] = useState([
        "Administratif",
        "Financier",
        "R&D",
        "Production",
    ]);
    const [etatProfil, setEtatProfil] = React.useState<boolean>(false);
    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
    const [dataIsUpdated, setDataIsUpdated] = React.useState<number | boolean>(
        false
    );
    const [profilesData, setProfilesData] = React.useState([]);
    const [isEditAction, setIsEditAction] = React.useState<boolean>(false);
    const [selectedOptions, setSelectedOptions] = React.useState<any[]>([]);
    const [typeProfil, setTypeProfil] = useState("");
    const [modalAutorisationOpen, setModalAutorisationOpen] = React.useState(
        false
    );
    const [isReset, setIsReset] = React.useState<boolean>(false);
    // eslint-disable-next-line
    const [isResetTypeProfil, setIsResetTypeProfil] = React.useState<boolean>(
        false
    );
    // eslint-disable-next-line
    const [isResetBalise, setIsResetBalise] = React.useState<boolean>(false);
    const [inputValue, setInputValue] = React.useState<boolean>(false);
    const [counter, setCounter] = React.useState<number>(2000);
    const [isResetDes, setIsResetDes] = React.useState<boolean>(false);
    const [
        isHisotyProfilModalOpened,
        setIsHisotyProfilModalOpened,
    ] = React.useState<boolean>(false);
    const [isEmpty, setIsEmpty] = React.useState<boolean>(false);
    const [isEmptyType, setIsEmptyType] = React.useState<boolean>(false);

    const {
        SelectedProfilBeforeUpdate,
        SelectedProfil,
        isVisible,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        usedProfile,
        isAlertProfilUsed,
        usedProfileWidhoutEntity,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        autorisationProfileEntityConnected,
        isModifyMultipleAuthorizationModalOpen,
    } = useSnapshot(store);

    // const initialState: initialState = {
    //     isHidden: false,
    //     designation: "",
    //     balise: "",
    //     description: "",
    //     etatProfil: "Activé",
    //     profilesData: [],
    //     isEditAction: false,
    //     selectedOptions: [],
    //     optionsCompte: [],
    // };
    // const [ProfilState, setProfilState] = React.useState(initialState);

    function saveHistoryChanges() {
        const oldData = {
            type: SelectedProfilBeforeUpdate.TypeProfil,
            description: SelectedProfilBeforeUpdate.Descriptif,
            designation: SelectedProfilBeforeUpdate.Designation,
            balise: SelectedProfilBeforeUpdate.Balise,
            uidProfil: SelectedProfilBeforeUpdate.UidProfil,
        };
        const newData = {
            type: SelectedProfil.TypeProfil,
            description: SelectedProfil.Descriptif,
            designation: SelectedProfil.Designation,
            balise: selectedOptions.join(","),
            uidProfil: SelectedProfilBeforeUpdate.UidProfil,
        };
        fetch(`${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/HistoryChanges`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                oldData: oldData,
                newData: newData,
                uidUpdatedData: uidEntity,
                typeHistory: "4",
            }),
        })
            .then((response) => {
                response.json().then((data) => {
                    return data.error;
                });
            })
            .catch((e: any) => {
                return false;
            });
    }

    async function handleUpdateProfil() {
        let arrayUuidEntity: any = [];
        // const uidEntity = localStorage.getItem("Uid_Entitie_User");
        arrayUuidEntity.push(uidEntity);
        let profilUpdated = {
            type: SelectedProfil.TypeProfil,
            description: SelectedProfil.Descriptif,
            designation: SelectedProfil.Designation,
            autorisation: SelectedProfil.Autorisations,
            uidEntity: arrayUuidEntity,
            uidProfil: SelectedProfil.UidProfil,
            balise: selectedOptions.join(","),
        };
        fetch(`${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/updateProfile`, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                dataProfil: profilUpdated,
            }),
        })
            .then((response) => {
                response.json().then((data) => {
                    if (data.error === false) {
                        setSelectedOptions([]);
                        setIsEmptyType(false);
                        setIsEmpty(false);
                        saveHistoryChanges();
                        setDesignation("");
                        setDescription("");
                        setBalise("");
                        showToast(
                            true,
                            `${t(
                                "The profile has been modified successfully."
                            )}`
                        );
                        setIsHidden(!isHidden);
                        setDataIsUpdated(true);
                    }
                });
            })
            .catch((e: any) => {
                return false;
            });
    }

    async function handleListProfiles() {
        try {
            await fetch(
                `${
                    process.env.REACT_APP_CONSOLE_ADMIN_ACCESS
                }/listProfiles?uidEntity=${uidEntity}&etatProfil=${
                    etatProfil ? "Archivé" : "Activé"
                }`,
                {
                    method: "post",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        uidEntity: uidEntity,
                        etatProfil: etatProfil,
                    }),
                }
            )
                .then((response) => response.json())
                .then((data) => {
                    setProfilesData(data.data);
                });
        } catch (error) {
            console.log(error);
        }
    }

    const profileList: any = [];
    profilesData.forEach((element: any) => {
        profileList.push(element.Designation + "|" + element.TypeProfil);
    });

    const handleClick = (event: any) => {
        event.preventDefault();
        if (SelectedProfil === "") {
            if (designation.trim().length === 0) {
                setIsEmpty(true);
                showToast(
                    false,
                    `${t("The 'Designation' field must be filled in.")}`
                );
                setModalAutorisationOpen(false);
            } else if (typeProfil.trim().length === 0) {
                setIsEmptyType(true);
                showToast(
                    false,
                    `${t("The 'profile type' field must be filled in.")}`
                );
                setModalAutorisationOpen(false);
            } else {
                if (profileList.includes(designation + "|" + typeProfil)) {
                    showToast(
                        false,
                        `${
                            t(
                                "This designation already exists in the account type "
                            ) + typeProfil
                        }`
                    );
                } else {
                    setModalAutorisationOpen(!modalAutorisationOpen);
                }
            }
        } else {
            if (
                SelectedProfilBeforeUpdate.Designation ===
                    SelectedProfil.Designation &&
                SelectedProfilBeforeUpdate.TypeProfil ===
                    SelectedProfil.TypeProfil &&
                SelectedProfilBeforeUpdate.Balise ===
                    selectedOptions.join(",") &&
                SelectedProfilBeforeUpdate.Descriptif ===
                    SelectedProfil.Descriptif
            ) {
                setIsHidden(!isHidden);
            } else {
                if (SelectedProfil.Designation === "") {
                    setIsEmpty(true);
                    showToast(
                        false,
                        `${t("The 'Designation' field must be filled in.")}`
                    );
                } else {
                    if (
                        (SelectedProfilBeforeUpdate.Designation !==
                            SelectedProfil.Designation ||
                            SelectedProfilBeforeUpdate.TypeProfil !==
                                SelectedProfil.TypeProfil) &&
                        profileList.includes(
                            SelectedProfil.Designation +
                                "|" +
                                SelectedProfil.TypeProfil
                        )
                    ) {
                        showToast(
                            false,
                            `${t(
                                t(
                                    "This designation already exists in the account type "
                                ) + typeProfil
                            )}`
                        );
                    } else {
                        if (
                            usedProfileWidhoutEntity.includes(
                                SelectedProfil.UidProfil
                            )
                        ) {
                            setIsAlertProfilUsed(!isAlertProfilUsed);
                        } else {
                            handleUpdateProfil();
                        }
                    }
                }
            }
        }
    };
    async function handleTypeDuCompte() {
        try {
            await fetch(
                `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/listTypeGestionnaireDeComptes?archived=false`,
                {
                    method: "get",
                    headers: { "Content-Type": "application/json" },
                }
            )
                .then((response) => response.json())
                .then((data) => {
                    data.data.forEach((element: any) => {
                        typeDuCompteOptions.push({
                            label: element.Designation,
                            value: element.Designation,
                        });
                    });
                });
        } catch (error) {
            console.log(error);
        }
    }
    const toggle = () => setModalAutorisationOpen(!modalAutorisationOpen);
    React.useEffect(() => {
        handleListProfiles();
        handleTypeDuCompte();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        SelectedProfil !== ""
            ? setSelectedOptions(SelectedProfil.Balise.split(","))
            : setSelectedOptions([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [SelectedProfil]);
    React.useEffect(() => {
        fetch(`${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/profileUsed`, {
            method: "get",
            headers: { "Content-Type": "application/json" },
        })
            .then((response) => {
                response.json().then((data) => {
                    if (data === false) {
                    } else {
                        let array: any = [];
                        data.forEach((el: any) => {
                            const elementUuid = el.split("|");
                            array.push(elementUuid[0]);
                        });
                        setUsedProfileWidhoutEntity(array);
                        setUsedProfile(data);
                    }
                });
                return { usedProfile, usedProfileWidhoutEntity };
            })
            .catch((e: any) => {
                console.log({ error: e.message });
            });

        fetch(
            `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/getAutorisationProfileEntity?uuidEntity=${uidEntity}`,
            {
                method: "get",
                headers: { "Content-Type": "application/json" },
            }
        )
            .then((response) => {
                response.json().then((data) => {
                    if (data === false) {
                    } else {
                        setAutorisationProfileEntityConnected(data);
                    }
                });
                return { autorisationProfileEntityConnected };
            })
            .catch((e: any) => {
                console.log({ error: e.message });
            });

        // eslint-disable-next-line
    }, [dataIsUpdated]);

    let urlProfileList: string = "";
    const isProd = process.env.REACT_APP_PROD;

    if (!isProd) {
        urlProfileList = `${
            process.env.REACT_APP_CONSOLE_ADMIN_ACCESS
        }/profilesList?uidEntity=${uidEntity}&etatProfil=${
            etatProfil ? "Archivé" : "Activé"
        }`;
    } else {
        urlProfileList = `${
            process.env.REACT_APP_CONSOLE_ADMIN_ACCESS
        }/profilesListProd?uidEntity=${uidEntity}&etatProfil=${
            etatProfil ? "Archivé" : "Activé"
        }`;
    }
    return (
        <React.Fragment>
            <PageTitle title="PROFIL" />

            <div className="">
                <Container fluid style={{ marginTop: "-15px" }}>
                    <Row>
                        <Col>
                            {!etatProfil ? (
                                <Breadcurmb
                                    title={`${t(
                                        "CONFIGURATION / PROFILE(S) ENABLED"
                                    )}`}
                                    breadcrumbBackgroundColor={""}
                                />
                            ) : (
                                <Breadcurmb
                                    title={`${t(
                                        "CONFIGURATION / PROFILE(S) ARCHIVED"
                                    )}`}
                                    breadcrumbBackgroundColor={""}
                                />
                            )}
                        </Col>
                        <MessageInfo
                            message={`${t(
                                "This part allows you to configure the profiles"
                            )}`}
                        />
                    </Row>

                    <CardBody
                        style={{
                            padding: "1px",
                        }}
                    >
                        <div
                            className="row"
                            style={{
                                display: "grid",
                                gridTemplateColumns: isHidden
                                    ? "2fr 1fr"
                                    : "auto",
                                gap: "4px",
                            }}
                        >
                            <TableListProfil
                                setIsHidden={setIsHidden}
                                isHidden={isHidden}
                                urlTable={urlProfileList}
                                setIsEditAction={setIsEditAction}
                                isEditAction={isEditAction}
                                setDataIsUpdated={setDataIsUpdated}
                                dataIsUpdated={dataIsUpdated}
                                setSelectedRows={setSelectedRows}
                                selectedRows={selectedRows}
                                isHisotyProfilModalOpened={
                                    isHisotyProfilModalOpened
                                }
                                setIsHisotyProfilModalOpened={
                                    setIsHisotyProfilModalOpened
                                }
                                setEtatProfil={setEtatProfil}
                                etatProfil={etatProfil}
                                toggle={toggle}
                            />

                            {!isHidden ? null : (
                                <Card className="border ml-2">
                                    <CardHeader
                                        className="Headercard  d-flex justify-content-between pb-2"
                                        style={{
                                            height: "11.7%",
                                            backgroundColor: "white",
                                            border:
                                                "1px solid rgba(0,0,0,.125)",
                                        }}
                                    >
                                        <div className=" d-flex align-items-center justify-content-center">
                                            <h4
                                                className="m-0 pl-2"
                                                style={{
                                                    font:
                                                        "normal normal 600 20px/27px Segoe UI",
                                                }}
                                            >
                                                {isEditAction
                                                    ? etatProfil
                                                        ? `${t("View Profile")}`
                                                        : `${t(
                                                              "modify the profile"
                                                          )}`
                                                    : `${t("Add profile")}`}
                                            </h4>
                                        </div>

                                        <div className="d-flex align-items-center ">
                                            {!etatProfil ? (
                                                <RefreshIcon
                                                    cursor="pointer"
                                                    height={20}
                                                    width={20}
                                                    onClick={() => {
                                                        if (isEditAction) {
                                                            setIsReset(true);
                                                            setIsResetTypeProfil(
                                                                true
                                                            );
                                                            setIsResetBalise(
                                                                true
                                                            );
                                                            setIsResetDes(true);
                                                            setInputValue(
                                                                false
                                                            );
                                                            setIsEmptyType(
                                                                false
                                                            );
                                                            setIsEmpty(false);
                                                        } else {
                                                            setDesignation("");
                                                            setDescription("");
                                                            setBalise("");
                                                            setSelectedOptions(
                                                                []
                                                            );
                                                        }
                                                        // setModif(false);
                                                    }}
                                                />
                                            ) : (
                                                <></>
                                            )}
                                            <div className=" bd-highlight align-items-center ml-4">
                                                <CrossIcon
                                                    cursor="pointer"
                                                    height={17}
                                                    width={17}
                                                    onClick={() => {
                                                        setIsHidden(!isHidden);
                                                        clearMultiCustomSelectionData();
                                                        clearData();
                                                        setSelectedOptions([]);
                                                        setDesignation("");
                                                        setDescription("");
                                                        setBalise("");
                                                        setTypeProfil("");
                                                        setInputValue(false);
                                                        setIsEmptyType(false);
                                                        setIsEmpty(false);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </CardHeader>

                                    <CardBody
                                        style={{
                                            background: "#FFFFFF",
                                            border:
                                                "1px solid rgba(0,0,0,.125)",
                                            marginTop: "3px",
                                            overflow: "auto",
                                            maxHeight: `calc(100vh - (500px + ${
                                                isVisible ? "-71px" : "0px"
                                            }))`,
                                        }}
                                    >
                                        <AvForm>
                                            <StyledLabel>
                                                {t("Name*")}
                                            </StyledLabel>
                                            <StyledTextInput
                                                style={{
                                                    pointerEvents: etatProfil
                                                        ? "none"
                                                        : "auto",

                                                    opacity: etatProfil
                                                        ? "0.8"
                                                        : "",
                                                    border: isEmpty
                                                        ? "1px solid red"
                                                        : "1px solid #ced4da",
                                                }}
                                                id="example-inputddddd"
                                                name="example-inputddddd"
                                                placeholder="Saisir"
                                                type="text"
                                                onChange={(e: any) => {
                                                    if (SelectedProfil !== "") {
                                                        setSelectedProfil({
                                                            ...SelectedProfil,
                                                            Designation:
                                                                e?.target.value,
                                                        });
                                                    }
                                                    setIsEmpty(false);
                                                    setIsReset(false);
                                                    setDesignation(
                                                        e.target.value.trim()
                                                    );
                                                }}
                                                value={
                                                    SelectedProfil === ""
                                                        ? designation
                                                        : isReset
                                                        ? SelectedProfilBeforeUpdate?.Designation
                                                        : SelectedProfil?.Designation
                                                }
                                                autoFocus={
                                                    etatProfil ? false : true
                                                }
                                            />
                                            {isEmpty ? (
                                                <div
                                                    style={{
                                                        color: "red",
                                                    }}
                                                >
                                                    Ce champ est obligatoire
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </AvForm>
                                        <AvForm>
                                            <StyledLabel>
                                                {t("Account type*")}
                                            </StyledLabel>
                                            <div
                                                style={{
                                                    pointerEvents: etatProfil
                                                        ? "none"
                                                        : "auto",

                                                    opacity: etatProfil
                                                        ? "0.8"
                                                        : "",
                                                    border: isEmptyType
                                                        ? "1px solid red"
                                                        : "",
                                                    borderRadius: isEmptyType
                                                        ? "4px"
                                                        : "0px",
                                                }}
                                            >
                                                <StyledSelectInput
                                                    options={
                                                        typeDuCompteOptions
                                                    }
                                                    menuPlacement="auto"
                                                    menuPosition="fixed"
                                                    isSearchable
                                                    onChange={(e: any) => {
                                                        setTypeProfil(e.value);
                                                        setIsResetTypeProfil(
                                                            false
                                                        );
                                                        setIsEmptyType(false);
                                                        if (
                                                            SelectedProfil !==
                                                            ""
                                                        ) {
                                                            setSelectedProfil({
                                                                ...SelectedProfil,
                                                                TypeProfil:
                                                                    e?.value,
                                                            });
                                                        }
                                                    }}
                                                    defaultValue={
                                                        SelectedProfil === ""
                                                            ? ""
                                                            : // : isResetTypeProfil
                                                              // ? {
                                                              //       label:
                                                              //           SelectedProfilBeforeUpdate?.TypeProfil,
                                                              //       value:
                                                              //           SelectedProfilBeforeUpdate?.TypeProfil,
                                                              //   }
                                                              {
                                                                  label:
                                                                      SelectedProfil?.TypeProfil,
                                                                  value:
                                                                      SelectedProfil?.TypeProfil,
                                                              }
                                                    }
                                                    placeholder={"Selectionner"}
                                                    components={{
                                                        NoOptionsMessage,
                                                    }}
                                                    name="saveprofilinput"
                                                />
                                            </div>
                                            {isEmptyType ? (
                                                <div
                                                    style={{
                                                        color: "red",
                                                    }}
                                                >
                                                    Ce champ est obligatoire
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </AvForm>
                                        {/* <AvForm>
                                            <StyledLabel>
                                                {t("Tag")}
                                            </StyledLabel>
                                            <div
                                                style={{
                                                    pointerEvents: etatProfil
                                                        ? "none"
                                                        : "auto",

                                                    opacity: etatProfil
                                                        ? "0.8"
                                                        : "",
                                                }}
                                            >
                                                <MultiCustomSelect
                                                    onDelete={() => {}}
                                                    result={selectedOptions}
                                                    setResult={
                                                        setSelectedOptions
                                                    }
                                                    data={optionsBalise}
                                                    onChange={(e: any) => {
                                                        setIsResetBalise(false);
                                                    }}
                                                    className={""}
                                                    placeholder={`${t(
                                                        "Select"
                                                    )}`}
                                                    value={t("Choose")}
                                                    name="Balise"
                                                />
                                            </div>
                                        </AvForm> */}
                                        <AvForm>
                                            <StyledLabel>
                                                {t("Description")}
                                            </StyledLabel>
                                            <StyledTextInput
                                                style={{
                                                    pointerEvents: etatProfil
                                                        ? "none"
                                                        : "auto",

                                                    opacity: etatProfil
                                                        ? "0.8"
                                                        : "",
                                                }}
                                                id="example-input"
                                                name="example-input"
                                                placeholder="Saisir"
                                                type="textarea"
                                                onChange={(e: any) => {
                                                    if (SelectedProfil !== "") {
                                                        setSelectedProfil({
                                                            ...SelectedProfil,
                                                            Descriptif:
                                                                e?.target.value,
                                                        });
                                                    }
                                                    setCounter(
                                                        2000 -
                                                            e.target.value
                                                                .length
                                                    );
                                                    setIsResetDes(false);
                                                    setInputValue(true);
                                                    setDescription(
                                                        e.target.value
                                                    );
                                                }}
                                                value={
                                                    SelectedProfil === ""
                                                        ? description
                                                        : isResetDes
                                                        ? SelectedProfilBeforeUpdate?.Descriptif
                                                        : SelectedProfil?.Descriptif
                                                }
                                                maxlength={2000}
                                            />
                                            <div className="d-flex justify-content-end ">
                                                {isEditAction ? (
                                                    isReset ? (
                                                        <small>
                                                            Nombre de caractère
                                                            maximal :
                                                            {2000 -
                                                                SelectedProfilBeforeUpdate
                                                                    .Descriptif
                                                                    .length}
                                                            /2000
                                                        </small>
                                                    ) : SelectedProfil
                                                          .Descriptif.length ===
                                                      0 ? (
                                                        <small>
                                                            Nombre de caractère
                                                            maximal : 2000
                                                        </small>
                                                    ) : (
                                                        <small>
                                                            Nombre de caractère
                                                            maximal :
                                                            {2000 -
                                                                SelectedProfil
                                                                    .Descriptif
                                                                    .length}
                                                            /2000
                                                        </small>
                                                    )
                                                ) : inputValue ? (
                                                    <small>
                                                        Nombre de caractère
                                                        maximal :{counter}
                                                        /2000
                                                    </small>
                                                ) : (
                                                    <small>
                                                        Nombre de caractère
                                                        maximal : 2000
                                                    </small>
                                                )}
                                            </div>
                                        </AvForm>
                                    </CardBody>

                                    <CardFooter
                                        className="Footercard p-0"
                                        style={{
                                            background: "#FFFFFF",
                                        }}
                                    >
                                        <div className=" d-flex flex-wrap d-flex flex-row-reverse bd-highlight ">
                                            <div className="p-2 bd-highlight">
                                                <StyledButton
                                                    disabled={
                                                        etatProfil
                                                            ? true
                                                            : false
                                                    }
                                                    rounded
                                                    variant="primary"
                                                    onClick={(e: any) => {
                                                        handleClick(e);
                                                    }}
                                                >
                                                    {t("Validate")}
                                                </StyledButton>
                                            </div>
                                            <div className="p-2 bd-highlight ">
                                                <StyledButton
                                                    rounded
                                                    outline
                                                    variant="light"
                                                    onClick={() => {
                                                        setIsHidden(!isHidden);
                                                        setSelectedOptions([]);
                                                        setDesignation("");
                                                        setDescription("");
                                                        setBalise("");
                                                        clearData();
                                                        clearMultiCustomSelectionData();
                                                        setTypeProfil("");
                                                        setInputValue(false);
                                                        setIsEmptyType(false);
                                                        setIsEmpty(false);
                                                    }}
                                                >
                                                    {t("Cancel")}
                                                </StyledButton>
                                            </div>
                                        </div>
                                    </CardFooter>
                                </Card>
                            )}
                        </div>
                    </CardBody>
                </Container>
            </div>
            <ModalWrapper
                toggle={toggle}
                modal={modalAutorisationOpen}
                setModal={setModalAutorisationOpen}
                designation={designation}
                typeProfil={typeProfil}
                description={description}
                setDataProfilIsUpdated={setDataIsUpdated}
                dataIsUpdated={dataIsUpdated}
                setIsHidden={setIsHidden}
                isHidden={isHidden}
                setDesignation={setDesignation}
                setDescription={setDescription}
                balise={selectedOptions}
                setBalise={setSelectedOptions}
                setTypeProfil={setTypeProfil}
            />
            <HistoryProfilModal
                isModalOpened={isHisotyProfilModalOpened}
                setIsModalOpened={setIsHisotyProfilModalOpened}
            />
            <AlertModifyMultipleAuthorization
                isModifyMultipleAuthorizationModalOpen={
                    isModifyMultipleAuthorizationModalOpen
                }
                setIsModifyMultipleAuthorizationModalOpen={
                    setIsModifyMultipleAuthorizationModalOpen
                }
            />
            <AlertProfileUsed
                isAlertProfilUsed={isAlertProfilUsed}
                setIsAlertProfilUsed={setIsAlertProfilUsed}
                selectedOptions={selectedOptions}
                setDataIsUpdated={setDataIsUpdated}
                setIsEmpty={setIsEmpty}
                setDesignation={setDesignation}
                setDescription={setDescription}
                setBalise={setSelectedOptions}
                setIsHidden={setIsHidden}
                isHidden={isHidden}
                setIsEmptyType={setIsEmptyType}
                toggle={toggle}
            />
        </React.Fragment>
    );
}
