import React from "react";
import { useTranslation } from "react-i18next";
import {
    StyledModal,
    StyledH2,
    StyledLabel,
    StyledButton,
} from "@aureskonnect/react-ui";
import moment from "moment";
import { registerLocale } from "react-datepicker";
import { Input, Label, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import DatePicker from "react-datepicker";
import { fr } from "date-fns/locale";

import "./index.css";
import "react-datepicker/dist/react-datepicker.css";

type DateHeureCalendarModalType = {
    isDateHeureCalendarModalOpened: boolean;
    setIsDateHeureCalendarModalOpened: React.Dispatch<
        React.SetStateAction<boolean>
    >;
    setFullDate: React.Dispatch<React.SetStateAction<string>>;
    setDateHeure: React.Dispatch<React.SetStateAction<string>>;
};
export function DateHeureCalendarModal({
    isDateHeureCalendarModalOpened,
    setIsDateHeureCalendarModalOpened,
    setFullDate,
    setDateHeure,
}: DateHeureCalendarModalType) {
    registerLocale("fr", fr);
    const { t } = useTranslation();
    const [selectedDate, setSelectedDate] = React.useState<Date>(new Date());
    const [time, setTime] = React.useState<Date>(new Date());

    function handleClickValidatePlanification() {
        let DateHeureMinutes =
            selectedDate.getDate() +
            "/" +
            (selectedDate.getMonth() + 1) +
            "/" +
            +selectedDate.getFullYear() +
            " à " +
            time.getHours() +
            ":" +
            time.getMinutes();
        // let Date = ``selectedDate.getDate();
        // setDateHeure(format(selectedDate, "yyyyMMddHHmm"));
        let day = ("0" + selectedDate.getDate()).slice(-2);
        let month = ("0" + (selectedDate.getMonth() + 1)).slice(-2);
        setDateHeure(
            `${selectedDate.getFullYear()}${month}${day}${time.getHours()}${time.getMinutes()}`
        );
        setFullDate(DateHeureMinutes);
    }

    return (
        <React.Fragment>
            <StyledModal
                isOpen={isDateHeureCalendarModalOpened}
                centered
                className="style-modal__clz"
            >
                <ModalHeader
                    className="text-capitalize"
                    toggle={() => {
                        setIsDateHeureCalendarModalOpened(
                            !isDateHeureCalendarModalOpened
                        );
                    }}
                >
                    <StyledH2>{t("planning")}</StyledH2>
                </ModalHeader>
                <ModalBody className="card_cl__clz">
                    <fieldset className="fieldsetInterne__clz">
                        <legend
                            style={{ marginLeft: "10px" }}
                            className="w-auto"
                        >
                            <StyledLabel>
                                Aujourd'hui{""}
                                {moment().format("DD/MM/YY")}
                            </StyledLabel>
                        </legend>
                        <div className="d-flex justify-content-center">
                            <DatePicker
                                wrapperClassName="date_picker full-width"
                                selected={selectedDate}
                                onChange={(dates: Date) => {
                                    setSelectedDate(dates);
                                }}
                                inline={true}
                                locale="fr"
                            />
                        </div>
                    </fieldset>
                    <fieldset className="fieldsetInterne__clz">
                        <legend
                            style={{ marginLeft: "10px" }}
                            className="w-auto"
                        >
                            <StyledLabel>{t("Publication Time")}</StyledLabel>
                        </legend>
                        <div className="px-5 mb-3">
                            <Label check>
                                <Input
                                    type="radio"
                                    name="radio1"
                                    defaultChecked={true}
                                    onCheckColor={"#6F763F"}
                                />
                                <span
                                    className="ml-1"
                                    style={{
                                        fontSize: "15px",
                                    }}
                                >
                                    {t("Well-defined time")}
                                </span>
                            </Label>

                            <DatePicker
                                className="react-datepicker__clz"
                                selected={time}
                                onChange={(date: Date) => {
                                    setTime(date);
                                }}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Time"
                                timeFormat="HH:mm"
                                dateFormat="HH:mm"
                            />
                        </div>
                    </fieldset>
                </ModalBody>
                <ModalFooter
                    className="d-flex justify-content-end"
                    style={{
                        background: "#FFFFFF",
                    }}
                >
                    <div
                        className="d-flex align-items-center"
                        style={{ gap: "30px" }}
                    >
                        <div className="col-4">
                            <StyledButton
                                disabled={false}
                                rounded
                                variant="light"
                                outline={true}
                                size="sm"
                                style={{
                                    background: "white",
                                    color: "black",
                                    padding: "0px",
                                    width: "150px",
                                    height: "45px",
                                }}
                                onClick={() => {
                                    setIsDateHeureCalendarModalOpened(
                                        !isDateHeureCalendarModalOpened
                                    );
                                }}
                            >
                                Annuler
                            </StyledButton>
                        </div>
                        <div className="col-4">
                            <StyledButton
                                disabled={false}
                                rounded
                                variant="primary"
                                outline={false}
                                size="sm"
                                style={{
                                    color: "white",
                                    padding: "0px",
                                    width: "150px",
                                    height: "45px",
                                }}
                                onClick={() => {
                                    handleClickValidatePlanification();
                                    setIsDateHeureCalendarModalOpened(
                                        !isDateHeureCalendarModalOpened
                                    );
                                }}
                            >
                                Valider
                            </StyledButton>
                        </div>
                    </div>
                </ModalFooter>
            </StyledModal>
        </React.Fragment>
    );
}
