import React from "react";
import {
    StyledButton,
    StyledModal,
    CrossIcon,
    StyledIconButton,
    StyledLabel,
} from "@aureskonnect/react-ui";
import { setSelectedTypeGestionnaireDeComptes } from "../store";
import { ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import "../Overview/index.css";

type AlerteModaltype = {
    isAlertupdateTypeGestionnaireDeCompteModal: boolean;
    setIsAlertupdateTypeGestionnaireDeCompteModal: Function;
    setModifTypeGestionnaireDeComptes: Function;
    setEditTypeGestionnaireDeComptes: Function;
    setIsHidden: React.Dispatch<React.SetStateAction<boolean>>;
    isHidden: boolean;
    editTypeGestionnaireDeComptes: string;
    selectedRows: any;
    setDesignation: React.Dispatch<React.SetStateAction<string>>;
    setDescriptif: React.Dispatch<React.SetStateAction<string>>;
    setIsEmptyDes: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function AlertUpdateTypeGestionnaireDeCompteModal({
    isAlertupdateTypeGestionnaireDeCompteModal,
    setIsAlertupdateTypeGestionnaireDeCompteModal,
    setModifTypeGestionnaireDeComptes,
    setIsHidden,
    isHidden,
    setEditTypeGestionnaireDeComptes,
    editTypeGestionnaireDeComptes,
    selectedRows,
    setDesignation,
    setDescriptif,
    setIsEmptyDes,
}: AlerteModaltype) {
    return (
        <div>
            <StyledModal
                className="updateAlerte"
                centered
                isOpen={isAlertupdateTypeGestionnaireDeCompteModal}
            >
                <ModalHeader className="d-flex align-items-center">
                    <div
                        className="d-flex align-items-center justify-content-between "
                        style={{
                            height: "30px",
                            display: "grid",
                            gridTemplateColumns: "1.8fr 0.2fr",
                            gridTemplateRows: "1fr",
                            gap: "65px",
                        }}
                    >
                        <p className="title-text-style">CONFIRMATION</p>
                        <StyledIconButton
                            icon="CrossIcon"
                            onClick={() => {
                                if (
                                    editTypeGestionnaireDeComptes ===
                                    "EditTypeCompte"
                                ) {
                                    setIsAlertupdateTypeGestionnaireDeCompteModal(
                                        !isAlertupdateTypeGestionnaireDeCompteModal
                                    );
                                    setIsHidden(isHidden);
                                    setModifTypeGestionnaireDeComptes(false);
                                    setEditTypeGestionnaireDeComptes("");
                                    setSelectedTypeGestionnaireDeComptes(
                                        selectedRows
                                    );
                                    setIsEmptyDes(false);
                                } else {
                                    setIsAlertupdateTypeGestionnaireDeCompteModal(
                                        !isAlertupdateTypeGestionnaireDeCompteModal
                                    );
                                    setIsHidden(!isHidden);
                                    setModifTypeGestionnaireDeComptes(false);
                                    setEditTypeGestionnaireDeComptes("");
                                    setDesignation("");
                                    setDescriptif("");
                                    setIsEmptyDes(false);
                                }
                            }}
                        >
                            <CrossIcon
                                cursor="pointer"
                                height={10}
                                width={10}
                            />
                        </StyledIconButton>
                    </div>
                </ModalHeader>

                <ModalBody>
                    <div
                        style={{
                            marginLeft: "30px",
                            marginTop: "20px",
                            marginBottom: "20px",
                        }}
                    >
                        <StyledLabel>
                            Voulez-vous enregistrer les modifications effectuées
                            ?
                        </StyledLabel>
                    </div>
                </ModalBody>

                <ModalFooter
                    className="d-flex justify-content-end"
                    style={{
                        background: "#FFFFFF",
                    }}
                >
                    <div
                        className="d-flex justify-content-end pr-5"
                        style={{ gap: "30px" }}
                    >
                        <div className="col-4">
                            <StyledButton
                                disabled={false}
                                rounded
                                variant="light"
                                outline={true}
                                size="sm"
                                style={{
                                    background: "white",
                                    color: "black",
                                    padding: "0px",
                                    width: "150px",
                                    height: "45px",
                                }}
                                onClick={() => {
                                    if (
                                        editTypeGestionnaireDeComptes ===
                                        "EditTypeCompte"
                                    ) {
                                        setIsAlertupdateTypeGestionnaireDeCompteModal(
                                            !isAlertupdateTypeGestionnaireDeCompteModal
                                        );
                                        setIsHidden(isHidden);
                                        setModifTypeGestionnaireDeComptes(
                                            false
                                        );
                                        setEditTypeGestionnaireDeComptes("");
                                        setSelectedTypeGestionnaireDeComptes(
                                            selectedRows
                                        );
                                        setIsEmptyDes(false);
                                    } else {
                                        setIsAlertupdateTypeGestionnaireDeCompteModal(
                                            !isAlertupdateTypeGestionnaireDeCompteModal
                                        );
                                        setIsHidden(!isHidden);
                                        setModifTypeGestionnaireDeComptes(
                                            false
                                        );
                                        setEditTypeGestionnaireDeComptes("");
                                        setDesignation("");
                                        setDescriptif("");
                                        setIsEmptyDes(false);
                                    }
                                }}
                            >
                                Annuler
                            </StyledButton>
                        </div>
                        <div className="col-4">
                            <StyledButton
                                disabled={false}
                                rounded
                                variant="primary"
                                outline={false}
                                size="sm"
                                style={{
                                    color: "white",
                                    padding: "0px",
                                    width: "150px",
                                    height: "45px",
                                }}
                                onClick={() => {
                                    setIsAlertupdateTypeGestionnaireDeCompteModal(
                                        !isAlertupdateTypeGestionnaireDeCompteModal
                                    );
                                    setModifTypeGestionnaireDeComptes(true);
                                    setIsHidden(isHidden);
                                    setEditTypeGestionnaireDeComptes("");
                                }}
                            >
                                Valider
                            </StyledButton>
                        </div>
                    </div>
                </ModalFooter>
            </StyledModal>
        </div>
    );
}
