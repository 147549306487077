import { createSlice } from "@reduxjs/toolkit";

type VisibilityStateType = {
    defaultVisibility: Record<string, visibilityInfoType>;
};

const initialState: VisibilityStateType = {
    defaultVisibility: {},
};

export const visibilitySlice = createSlice({
    name: "visibility",
    initialState,
    reducers: {
        setDefaultVisibility: (state, action) => {
            state.defaultVisibility = action.payload;
        },
    },
});

export const setDefaultVisibilityAsync = () => (dispatch: Function) => {
    fetch("/fakeData/defaultVisibility.json")
        .then((res) => res.json())
        .then((data) => {
            dispatch(setDefaultVisibility(data));
        });
};

export const { setDefaultVisibility } = visibilitySlice.actions;

export default visibilitySlice.reducer;
