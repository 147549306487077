import React from "react";
import { ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import {
    StyledButton,
    StyledModal,
    CrossIcon,
    StyledIconButton,
    StyledLabel,
} from "@aureskonnect/react-ui";

import { setIsUpdateCertificate } from "@pages/store";

import "../Overview/index.css";

type AlerteModaltype = {
    isAlertGenerateCertificateModal: boolean;
    setIsAlertGenerateCertificateModal: Function;
};

export default function AlertConfirmGenerateCertificate({
    isAlertGenerateCertificateModal,
    setIsAlertGenerateCertificateModal,
}: AlerteModaltype) {
    return (
        <div>
            <StyledModal
                className="updateAlerte"
                centered
                isOpen={isAlertGenerateCertificateModal}
                backdrop={false}
            >
                <ModalHeader className="d-flex align-items-center">
                    <div
                        className="d-flex align-items-center justify-content-between "
                        style={{
                            height: "30px",
                            display: "grid",
                            gridTemplateColumns: "1.8fr 0.2fr",
                            gridTemplateRows: "1fr",
                            gap: "65px",
                        }}
                    >
                        <p className="title-text-style">ALERTE</p>
                        <StyledIconButton
                            icon="CrossIcon"
                            onClick={() => {
                                setIsAlertGenerateCertificateModal(
                                    !isAlertGenerateCertificateModal
                                );
                            }}
                        >
                            <CrossIcon
                                cursor="pointer"
                                height={10}
                                width={10}
                            />
                        </StyledIconButton>
                    </div>
                </ModalHeader>

                <ModalBody>
                    <div
                        style={{
                            marginLeft: "30px",
                            marginTop: "20px",
                            marginBottom: "20px",
                        }}
                    >
                        <StyledLabel>
                            Des modifications ont été détectées. Si vous
                            confirmez, le certificat actuel sera archivé et un
                            nouveau certificat sera généré.
                        </StyledLabel>
                    </div>
                </ModalBody>

                <ModalFooter
                    className="d-flex justify-content-end"
                    style={{
                        background: "#FFFFFF",
                    }}
                >
                    <div
                        className="d-flex justify-content-end pr-5"
                        style={{ gap: "30px" }}
                    >
                        <div className="col-4">
                            <StyledButton
                                disabled={false}
                                rounded
                                variant="light"
                                outline={true}
                                size="sm"
                                style={{
                                    background: "white",
                                    color: "black",
                                    padding: "0px",
                                    width: "150px",
                                    height: "45px",
                                }}
                                onClick={() => {
                                    setIsAlertGenerateCertificateModal(
                                        !isAlertGenerateCertificateModal
                                    );
                                }}
                            >
                                Annuler
                            </StyledButton>
                        </div>
                        <div className="col-4">
                            <StyledButton
                                disabled={false}
                                rounded
                                variant="primary"
                                outline={false}
                                size="sm"
                                style={{
                                    color: "white",
                                    padding: "0px",
                                    width: "150px",
                                    height: "45px",
                                }}
                                onClick={() => {
                                    setIsUpdateCertificate(true);
                                    setIsAlertGenerateCertificateModal(
                                        !isAlertGenerateCertificateModal
                                    );
                                }}
                            >
                                Valider
                            </StyledButton>
                        </div>
                    </div>
                </ModalFooter>
            </StyledModal>
        </div>
    );
}
