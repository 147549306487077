import React from "react";
import {
    CrossIcon,
    StyledIconButton,
    StyledModal,
    StyledLabel,
    StyledButton,
} from "@aureskonnect/react-ui";
import { ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { useTranslation } from "react-i18next";

import TableHistoryLicenceModal from "./TableHistoryLicenceModal";

type ListHistoryModalType = {
    setisAlertHistoryLicencesModal: Function;
    isAlertHistoryLicencesModal: boolean;
};

export function HistoryLicenceModal({
    setisAlertHistoryLicencesModal,
    isAlertHistoryLicencesModal,
}: ListHistoryModalType) {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <StyledModal
                className="history-modal-style"
                isOpen={isAlertHistoryLicencesModal}
            >
                <ModalHeader className="d-flex align-items-center">
                    <div
                        className="d-flex align-items-center justify-content-between"
                        style={{
                            height: "35px",
                            display: "grid",
                            gridTemplateColumns: "1.8fr 0.2fr",
                            gridTemplateRows: "1fr",
                            gap: "1225px",
                        }}
                    >
                        <div
                            className="p-2 bd-highlight mt-1"
                            style={{ width: "max-content" }}
                        >
                            <StyledLabel>HISTORIQUE CERTIFICAT</StyledLabel>
                        </div>
                        <StyledIconButton
                            icon="CrossIcon"
                            onClick={() => {
                                setisAlertHistoryLicencesModal(
                                    !isAlertHistoryLicencesModal
                                );
                            }}
                        >
                            <CrossIcon
                                cursor="pointer"
                                height={10}
                                width={10}
                            />
                        </StyledIconButton>
                    </div>
                </ModalHeader>
                <ModalBody>
                    {/* <div
                        style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr 1fr 1fr auto",
                            gap: "20px",
                            background: colorBreadcrumb,
                            height: "56px",
                            marginBottom: "5px",
                            alignItems: "center",
                        }}
                    >
                        <div className="d-flex flex-row bd-highlight mb-3 mt-2">
                            <div
                                className="p-2 bd-highlight mt-1"
                                style={{ width: "max-content" }}
                            >
                                <span
                                    style={{
                                        textAlign: "left",
                                        font:
                                            " normal normal bold 20px/14px Segoe UI",
                                        color: "#2B2828",
                                        fontWeight: 600,
                                    }}
                                >
                                    Liste des produits :
                                </span>
                            </div>
                            <div className="p-2 bd-highlight">
                                <StyledLabel
                                    style={{
                                        fontWeight: "normal",
                                        width: "max-content",
                                    }}
                                >
                                    {}
                                </StyledLabel>
                            </div>
                        </div>
                    </div> */}
                    <div>
                        <TableHistoryLicenceModal />
                    </div>
                </ModalBody>
                <ModalFooter className="d-flex justify-content-end">
                    <div className="d-flex align-items-center">
                        <div className="ml-3">
                            <StyledButton
                                disabled={false}
                                rounded
                                variant="light"
                                outline={true}
                                size="sm"
                                style={{
                                    background: "black",

                                    color: "white",

                                    padding: "0px",

                                    width: "150px",

                                    height: "45px",
                                }}
                                onClick={() =>
                                    setisAlertHistoryLicencesModal(
                                        !isAlertHistoryLicencesModal
                                    )
                                }
                            >
                                {t("Close")}
                            </StyledButton>
                        </div>
                    </div>
                </ModalFooter>
            </StyledModal>
        </React.Fragment>
    );
}
