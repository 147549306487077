import React, { useState } from "react";

import {
    InboxIcon,
    TrashIcon,
    VerticalDotsIcon,
    EyeIcon,
    DocumentIcon,
} from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import {
    setIsHitoryAllProducts,
    store,
    setIsAlerteDissociateModuleModalOpened,
    setRefHistoryProduct,
    setIsAssociateModuleModalOpen,
} from "@pages/store";
import { showToast } from "@components/Common/ToastShow";
import AlerteModal from "./AlerteModal";
import AlerteDissociateModulesModal from "./AlerteDissociateModulesModal";
import AssociateModulesToProductsModal from "./AssociateModulesToProductsModal";

type ProductsActionMenuType = {
    isHisotyProductModalOpened: boolean | undefined;
    setIsHisotyProductModalOpened: React.Dispatch<
        React.SetStateAction<boolean>
    >;
    setDataIsUpdated: React.Dispatch<React.SetStateAction<number | boolean>>;
    dataIsUpdated: number | boolean;
    selectedRows?: any;
    setProductState: React.Dispatch<React.SetStateAction<boolean>>;
    isProductState: boolean;
};

export default function ProductsActionMenu(props: ProductsActionMenuType) {
    const { t } = useTranslation();
    const { archiveActivePacks } = useSnapshot(store);
    const { alerteMessagePack } = useSnapshot(store);
    const { isAssociateModuleModalOpen } = useSnapshot(store);
    const { SelectedProduct } = useSnapshot(store);
    const uidEntity = localStorage.getItem("Uid_Entitie_User");
    const [nombreProductsActive, setNombreProductsActive] = useState(0);
    const [nombreProductsArchive, setNombreProductsArchive] = useState(0);
    const [isAlerteModalOpened, setIsAlerteModalOpened] = useState(false);
    const [listProduits, setListProduits] = useState([]);

    const numberArchivesProducts = () => {
        fetch(
            `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/numberActiveArchiveProducts?uidEntitie=${uidEntity}&isActivesProducts=true&isPrincipalProduct=true`
        )
            .then((response) => {
                response.json().then((data) => {
                    setNombreProductsArchive(Number(data.count));
                });
            })
            .catch((e: any) => {
                console.log({ error: e.message });
            });
    };

    const numberActivesProducts = () => {
        fetch(
            `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/numberActiveArchiveProducts?uidEntitie=${uidEntity}&isActivesProducts=false&isPrincipalProduct=true`
        )
            .then((response) => {
                response.json().then((data) => {
                    setNombreProductsActive(Number(data.count));
                });
            })
            .catch((e: any) => {
                console.log({ error: e.message });
            });
    };
    let listProducts: any = [];
    let listSubRowsLength: any = [];
    function handleArchiveActiveProducts(action: any) {
        for (let i = 0; i < props.selectedRows.length; i++) {
            if (props.selectedRows[i].isPrincipalProduct) {
                listProducts.push(props.selectedRows[i].Référence);
                listSubRowsLength.push(props.selectedRows[i].subRows.length);
            }
        }
        setListProduits(listProducts);
        if (listProducts.length < 2) {
            showToast(
                false,
                "Attention, c'est une action multiple, vous devez sélectionner au moins deux éléments."
            );
        } else {
            const result = listSubRowsLength.filter(
                (length: any) => length !== 0
            );
            if (result.length > 0) {
                showToast(
                    false,
                    "Vous ne pouvez pas archiver un module associé à un ou plusieurs produits / packs"
                );
            } else {
                setIsAlerteModalOpened(!isAlerteModalOpened);
            }
        }
    }
    function showHistory() {
        let ReferencesProducts: any = [];
        props.selectedRows.forEach((element: any) => {
            if (element.isPrincipalProduct) {
                ReferencesProducts.push(element.Référence);
            }
        });
        setRefHistoryProduct(ReferencesProducts);
        if (ReferencesProducts.length < 2) {
            showToast(
                false,
                "Attention, c'est une action multiple, vous devez sélectionner au moins deux éléments."
            );
            setTimeout(() => {}, 2000);
        } else {
            setIsHitoryAllProducts(true);
            props.setIsHisotyProductModalOpened(
                !props.isHisotyProductModalOpened
            );
        }
    }
    React.useEffect(() => {
        numberActivesProducts();
        numberArchivesProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uidEntity, props.selectedRows]);

    return (
        <>
            <div className="dropdown ml-2  d-none d-sm-block">
                <VerticalDotsIcon
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    cursor="pointer"
                    height={25}
                    width={25}
                />

                <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                >
                    {props.isProductState ? (
                        <div
                            className="dropdown-item"
                            role="button"
                            tabIndex={0}
                            onClick={() => {
                                props.setProductState(false);
                            }}
                            style={{
                                cursor: "pointer",
                                pointerEvents:
                                    nombreProductsArchive === 0
                                        ? "none"
                                        : "auto",
                                opacity:
                                    nombreProductsArchive === 0 ? "0.4" : "",
                            }}
                        >
                            <InboxIcon
                                className="mr-2"
                                height={25}
                                width={25}
                            />

                            {t(
                                `Produit(s) archivé(s) (${nombreProductsArchive})`
                            )}
                        </div>
                    ) : (
                        <div
                            className="dropdown-item"
                            role="button"
                            tabIndex={0}
                            onClick={() => {
                                props.setProductState(true);
                            }}
                            style={{
                                // pointerEvents:
                                //     nombreProductsActive === 0
                                //         ? "none"
                                //         : "auto",
                                // opacity:
                                //     nombreProductsActive === 0 ? "0.4" : "",
                                cursor: "pointer",
                            }}
                        >
                            <InboxIcon
                                className="mr-2"
                                height={25}
                                width={25}
                            />
                            {t(
                                `Produit(s) active(s) (${nombreProductsActive})`
                            )}
                        </div>
                    )}

                    {props.isProductState ? (
                        <div
                            className="dropdown-item"
                            role="button"
                            tabIndex={0}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                props.setProductState(true);
                                handleArchiveActiveProducts(true);
                            }}
                        >
                            <TrashIcon
                                className="mr-2"
                                height={25}
                                width={25}
                                style={{ fill: "red" }}
                            />
                            {t("Archiver produits")}
                        </div>
                    ) : (
                        <div
                            className="dropdown-item"
                            role="button"
                            tabIndex={0}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                props.setProductState(false);
                                handleArchiveActiveProducts(false);
                            }}
                        >
                            <EyeIcon height={25} width={25} className="mr-2" />
                            {t("Activer produits")}
                        </div>
                    )}

                    <div
                        className="dropdown-item"
                        role="button"
                        tabIndex={0}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            showHistory();
                        }}
                    >
                        <DocumentIcon height={25} width={25} className="mr-2" />
                        {t("Historiques")}
                    </div>
                </div>
            </div>
            <AlerteModal
                isAlerteModalOpened={isAlerteModalOpened}
                setIsAlerteModalOpened={setIsAlerteModalOpened}
                setDataIsUpdated={props.setDataIsUpdated}
                dataIsUpdated={props.dataIsUpdated}
                ProductsNames={listProduits}
                archiveActiveProduct={props.isProductState}
                archiveActivePacks={archiveActivePacks}
                type={""}
                AlerteMessage={alerteMessagePack}
                selectedRows={props.selectedRows}
                listProduitsPack={[]}
            />
            <AlerteDissociateModulesModal
                setIsAlerteDissociateModuleModalOpened={
                    setIsAlerteDissociateModuleModalOpened
                }
                setDataIsUpdated={props.setDataIsUpdated}
                dataIsUpdated={props.dataIsUpdated}
            />
            <AssociateModulesToProductsModal
                isModalOpened={isAssociateModuleModalOpen}
                setIsModalOpened={setIsAssociateModuleModalOpen}
                setDataIsUpdated={props.setDataIsUpdated}
                dataIsUpdated={props.dataIsUpdated}
                product={SelectedProduct}
            />
        </>
    );
}
