import React from "react";
import {
    StyledButton,
    StyledModal,
    CrossIcon,
    StyledIconButton,
    StyledLabel,
} from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { useSnapshot } from "valtio";
import {
    setEditTypeGestionnaireDeComptes,
    setModifTypeGestionnaireDeComptes,
    setSelectedTypeGestionnaireDeComptes,
    store,
} from "../store";

import { showToast } from "@components/Common/ToastShow";

import "../Overview/index.css";
type AlerteModaltype = {
    isAlertAccountManagerTypeUsed: boolean;
    setIsAlertAccountManagerTypeUsed: Function;
    listDesignationTypeGestionnaireDeComptes: never[];
    designation: string;
    setDescriptif: React.Dispatch<React.SetStateAction<string>>;
    setDesignation: React.Dispatch<React.SetStateAction<string>>;
    setIsHidden: React.Dispatch<React.SetStateAction<boolean>>;
    isHidden: boolean;
    setDataIsUpdated: React.Dispatch<React.SetStateAction<number | boolean>>;
};

export default function AlertAccountManagerTypeUsed({
    isAlertAccountManagerTypeUsed,
    setIsAlertAccountManagerTypeUsed,
    listDesignationTypeGestionnaireDeComptes,
    designation,
    setDescriptif,
    setDesignation,
    setIsHidden,
    isHidden,
    setDataIsUpdated,
}: AlerteModaltype) {
    const { t } = useTranslation();
    const uuidEntity = localStorage.getItem("Uid_Entitie_User");
    const {
        selectedTypeGestionnaireDeComptesBeforeUpdate,
        selectedTypeGestionnaireDeComptes,
        usedaccountManagerType,
    } = useSnapshot(store);

    async function updateAccountManagerTypeEntity(
        array: any,
        dataAccountManagerTypeEntity: any
    ) {
        fetch(
            `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/updateAccountManagerTypeEntity`,
            {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    uuidEntity: array,
                    dataAccountManagerTypeEntity: dataAccountManagerTypeEntity,
                }),
            }
        )
            .then((response) => {
                response.json().then((data) => {
                    if (data.error === false) {
                    }
                });
            })
            .catch((e: any) => {
                console.log({ error: e.message });
            });
    }

    function saveHistoryChanges(oldData: any, newData: any) {
        fetch(`${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/HistoryChanges`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                oldData: oldData,
                newData: newData,
                uidUpdatedData: uuidEntity,
                typeHistory: "6",
            }),
        })
            .then((response) => {
                response.json().then((data) => {
                    setTimeout(function () {
                        setIsHidden(!isHidden);
                    }, 2000);
                    return data.error;
                });
            })
            .catch((e: any) => {
                return false;
            });
    }

    async function UpdateTypeGestionnaireDeComptes() {
        let array: any = [];

        usedaccountManagerType.forEach((el: any) => {
            const elementUuid = el.split("|");

            if (selectedTypeGestionnaireDeComptes.uuid === elementUuid[0]) {
                array.push(elementUuid[1]);
            }
        });

        const exist = listDesignationTypeGestionnaireDeComptes.filter(
            (word: any) => word === designation
        );
        if (
            selectedTypeGestionnaireDeComptesBeforeUpdate.Designation ===
            selectedTypeGestionnaireDeComptes.Designation
        ) {
            const old_module = {
                descriptif:
                    selectedTypeGestionnaireDeComptesBeforeUpdate.Descriptif,
                designation:
                    selectedTypeGestionnaireDeComptesBeforeUpdate.Designation,
                uuid: selectedTypeGestionnaireDeComptesBeforeUpdate.uuid,
                uuidEntity: uuidEntity,
            };
            const new_module = {
                descriptif: selectedTypeGestionnaireDeComptes.Descriptif,
                designation: selectedTypeGestionnaireDeComptes.Designation,
                uuid: selectedTypeGestionnaireDeComptes.uuid,
                uuidEntity: uuidEntity,
            };

            let TypeGestionnaireDeComptesUpdated = {
                descriptif: selectedTypeGestionnaireDeComptes.Descriptif,
                designation: selectedTypeGestionnaireDeComptes.Designation,
                uuid: selectedTypeGestionnaireDeComptes.uuid,
                uuidEntity: uuidEntity,
            };
            fetch(
                `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/updateTypeGestionnaireDeComptes`,
                {
                    method: "PUT",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        dataTypeGestionnaireDeComptes: TypeGestionnaireDeComptesUpdated,
                    }),
                }
            )
                .then((response) => {
                    response.json().then((data) => {
                        if (data.error === false) {
                            updateAccountManagerTypeEntity(
                                array,
                                selectedTypeGestionnaireDeComptes.Designation
                            );
                            setIsAlertAccountManagerTypeUsed(
                                !isAlertAccountManagerTypeUsed
                            );
                            setEditTypeGestionnaireDeComptes("");
                            setDescriptif("");
                            setDesignation("");
                            setModifTypeGestionnaireDeComptes(false);
                            setSelectedTypeGestionnaireDeComptes("");
                            showToast(
                                true,
                                `${t(
                                    "Account Manager Type changed successfully."
                                )}`
                            );
                            setIsHidden(!isHidden);
                            setDataIsUpdated(true);
                            saveHistoryChanges(old_module, new_module);
                        }
                    });
                })
                .catch((e: any) => {
                    return false;
                });
        } else {
            if (exist.length < 1) {
                const old_module = {
                    descriptif:
                        selectedTypeGestionnaireDeComptesBeforeUpdate.Descriptif,
                    designation:
                        selectedTypeGestionnaireDeComptesBeforeUpdate.Designation,
                    uuid: selectedTypeGestionnaireDeComptesBeforeUpdate.uuid,
                    uuidEntity: uuidEntity,
                };
                const new_module = {
                    descriptif: selectedTypeGestionnaireDeComptes.Descriptif,
                    designation: selectedTypeGestionnaireDeComptes.Designation,
                    uuid: selectedTypeGestionnaireDeComptes.uuid,
                    uuidEntity: uuidEntity,
                };

                let TypeGestionnaireDeComptesUpdated = {
                    descriptif: selectedTypeGestionnaireDeComptes.Descriptif,
                    designation: selectedTypeGestionnaireDeComptes.Designation,
                    uuid: selectedTypeGestionnaireDeComptes.uuid,
                    uuidEntity: uuidEntity,
                };
                fetch(
                    `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/updateTypeGestionnaireDeComptes`,
                    {
                        method: "PUT",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({
                            dataTypeGestionnaireDeComptes: TypeGestionnaireDeComptesUpdated,
                        }),
                    }
                )
                    .then((response) => {
                        response.json().then((data) => {
                            if (data.error === false) {
                                updateAccountManagerTypeEntity(
                                    array,
                                    selectedTypeGestionnaireDeComptes.Designation
                                );
                                setIsAlertAccountManagerTypeUsed(
                                    !isAlertAccountManagerTypeUsed
                                );
                                setEditTypeGestionnaireDeComptes("");
                                setDescriptif("");
                                setDesignation("");
                                setModifTypeGestionnaireDeComptes(false);
                                setSelectedTypeGestionnaireDeComptes("");
                                showToast(
                                    true,
                                    `${t(
                                        "Account Manager Type changed successfully."
                                    )}`
                                );
                                setIsHidden(!isHidden);
                                setDataIsUpdated(true);
                                saveHistoryChanges(old_module, new_module);
                            }
                        });
                    })
                    .catch((e: any) => {
                        return false;
                    });
            } else {
                showToast(
                    false,
                    `${t(
                        "The designation already exists. Please enter another"
                    )}`
                );
            }
        }
    }

    return (
        <div>
            <StyledModal
                className="updateAlerte"
                centered
                isOpen={isAlertAccountManagerTypeUsed}
            >
                <ModalHeader className="d-flex align-items-center">
                    <div
                        className="d-flex align-items-center justify-content-between "
                        style={{
                            height: "30px",
                            display: "grid",
                            gridTemplateColumns: "1.8fr 0.2fr",
                            gridTemplateRows: "1fr",
                            gap: "65px",
                        }}
                    >
                        <p className="title-text-style">CONFIRMATION</p>
                        <StyledIconButton
                            icon="CrossIcon"
                            onClick={() => {
                                setIsAlertAccountManagerTypeUsed(
                                    !isAlertAccountManagerTypeUsed
                                );
                            }}
                        >
                            <CrossIcon
                                cursor="pointer"
                                height={10}
                                width={10}
                            />
                        </StyledIconButton>
                    </div>
                </ModalHeader>

                <ModalBody>
                    <div
                        style={{
                            marginLeft: "30px",
                            marginTop: "20px",
                            marginBottom: "20px",
                        }}
                    >
                        <StyledLabel>
                            Attention ! Ce type de gestionnaire de comptes est
                            utilisé par un ou plusieurs comptes clients.
                            <br />
                            Voulez-vous valider les modifications apportées ?
                        </StyledLabel>
                    </div>
                </ModalBody>

                <ModalFooter
                    className="d-flex justify-content-end"
                    style={{
                        background: "#FFFFFF",
                    }}
                >
                    <div
                        className="d-flex justify-content-end pr-5"
                        style={{ gap: "30px" }}
                    >
                        <div className="col-4">
                            <StyledButton
                                disabled={false}
                                rounded
                                variant="light"
                                outline={true}
                                size="sm"
                                style={{
                                    background: "white",
                                    color: "black",
                                    padding: "0px",
                                    width: "150px",
                                    height: "45px",
                                }}
                                onClick={() => {
                                    setIsAlertAccountManagerTypeUsed(
                                        !isAlertAccountManagerTypeUsed
                                    );
                                }}
                            >
                                Annuler
                            </StyledButton>
                        </div>
                        <div className="col-4">
                            <StyledButton
                                disabled={false}
                                rounded
                                variant="primary"
                                outline={false}
                                size="sm"
                                style={{
                                    color: "white",
                                    padding: "0px",
                                    width: "150px",
                                    height: "45px",
                                }}
                                onClick={() => {
                                    UpdateTypeGestionnaireDeComptes();
                                }}
                            >
                                Valider
                            </StyledButton>
                        </div>
                    </div>
                </ModalFooter>
            </StyledModal>
        </div>
    );
}
