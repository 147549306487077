import React from "react";
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";
import { cache } from "swr";
import Avatar from "react-avatar";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";

export default function ProfileMenu() {
    const { t } = useTranslation();
    const [menu, setMenu] = React.useState<boolean>(false);
    const [name, setName] = React.useState<string>("");
    const [userFirstName, setUserFirstName] = React.useState<any>("");
    const [userLastName, setUserLastName] = React.useState<any>("");
    const tokenUserTmp: any = localStorage.getItem("access_token_id");
    const authWithSso: any = process.env.REACT_APP_WITH_SSO;

    const navigate = useNavigate();

    const cryptDecryptDataUser = React.useCallback(
        async (wordToDecrypt: string, stateSetter: typeof Function) => {
            return await fetch(
                `${process.env.REACT_APP_ADMIN_ACCESS}/decryptWord`,
                {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    body: `wordToDecrypted=${wordToDecrypt}`,
                    method: "POST",
                }
            )
                .then((result) => result.text())
                .then((response: any) => {
                    stateSetter(response);
                    return response;
                })
                .catch((error) => {
                    stateSetter("");
                    return "";
                });
        },
        []
    );

    React.useEffect(() => {
        if (tokenUserTmp != null) {
            let dataUserConnected: any = jwt_decode(tokenUserTmp);

            setUserFirstName(async () => {
                const firstName = await cryptDecryptDataUser(
                    dataUserConnected.sub.firstname,
                    setUserFirstName as typeof Function
                );
                return firstName;
            });
            setUserLastName(async () => {
                const lastName = await cryptDecryptDataUser(
                    dataUserConnected.sub.name,
                    setUserLastName as typeof Function
                );
                return lastName;
            });
        } else {
            setUserFirstName(async () => {
                const fisrtNameLocalStorage: any = localStorage.getItem(
                    "firstName"
                );
                const firstName = await cryptDecryptDataUser(
                    fisrtNameLocalStorage,
                    setUserFirstName as typeof Function
                );

                return firstName;
            });
            setUserLastName(async () => {
                const lastNameLocalStorage: any = localStorage.getItem(
                    "lastName"
                );
                const lastName = await cryptDecryptDataUser(
                    lastNameLocalStorage,
                    setUserLastName as typeof Function
                );

                return lastName;
            });
        }
    }, [tokenUserTmp, cryptDecryptDataUser]);

    React.useEffect(() => {
        if (userFirstName !== "" && userLastName !== "") {
            const fullName = `${userFirstName} ${userLastName}`;
            setName(fullName);
        } else if (localStorage.getItem("authUser")) {
            if (
                process.env.REACT_APP_DEFAULTAUTH === "fake" ||
                process.env.REACT_APP_DEFAULTAUTH === "jwt"
            ) {
                const obj = JSON.parse(localStorage.getItem("authUser")!);
                setName(
                    `${
                        obj.firstname
                            ? `${obj.firstname} ${obj.lastname}`
                            : obj.username
                    }`
                );
            }
        }
    }, [userFirstName, userLastName]);

    function logoutWithSso(): any {
        const redirectUriLogout = `/session/end?post_logout_redirect_uri=${process.env.REACT_APP_REDIRECT_URL_LOGOUT}&client_id=${process.env.REACT_APP_CLIENT_ID}`;
        cache.clear();
        localStorage.clear();
        window.open(process.env.REACT_APP_OPSERVER_URL + redirectUriLogout);
        //history.push(process.env.REACT_APP_OPSERVER + "/session/end");
    }

    function logout(): any {
        cache.clear();
        localStorage.clear();
        navigate("/");
    }

    return (
        <React.Fragment>
            <Dropdown
                isOpen={menu}
                toggle={() => setMenu(!menu)}
                className="d-inline-block"
            >
                <DropdownToggle
                    className="btn header-item waves-effect"
                    id="page-header-user-dropdown"
                    tag="button"
                >
                    <Avatar name={name} size="40" round />

                    {/* <span className="d-none d-xl-inline-block ml-2 mr-1">
                        {name}
                    </span> */}

                    <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                </DropdownToggle>

                <DropdownMenu right>
                    <Link className="d-none dropdown-item" to="/profile">
                        <i className="bx bx-user font-size-16 align-middle mr-1"></i>
                        {t("Profile")}
                    </Link>
                    <DropdownItem className="d-none" tag="a" href="#">
                        <i className="bx bx-wrench font-size-17 align-middle mr-1"></i>
                        {t("Settings")}
                    </DropdownItem>
                    <DropdownItem
                        className="d-none"
                        tag="a"
                        href="auth-lock-screen"
                    >
                        <i className="bx bx-lock-open font-size-16 align-middle mr-1"></i>
                        {t("Lock screen")}
                    </DropdownItem>
                    <div className="d-none dropdown-divider"></div>
                    <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            if (authWithSso === 1) {
                                logoutWithSso();
                            } else {
                                logout();
                            }
                        }}
                        className="dropdown-item"
                    >
                        <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>
                        <span>{t("Logout")}</span>
                    </div>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
}
