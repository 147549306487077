import React from "react";
import {
    StyledButton,
    StyledModal,
    CrossIcon,
    StyledIconButton,
    StyledLabel,
} from "@aureskonnect/react-ui";
import {
    setEditProduct,
    setSelectedProduct,
    setSelectedProductBeforeUpdate,
    setSelectedModulesToDissociate,
} from "../store";
import { ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import "../Overview/index.css";

type AlerteModaltype = {
    isAlertupdateProductModal: boolean;
    setIsAlertupdateProductModal: Function;
    setIsHiddenCard: React.Dispatch<React.SetStateAction<boolean>>;
    isHiddenCard: boolean;
    setModifProduct: Function;
    editProduct: string;
    selectedRows: any;
    setIsEditAction: React.Dispatch<React.SetStateAction<boolean>>;
    setEditProduct: Function;
    setIsAssociateModuleModalOpen: Function;
    isAssociateModuleModalOpen: boolean;
    setIsAlerteDissociateModuleModalOpened: Function;
    isAlerteDissociateModuleModalOpened: boolean;
    setIsEmptyDes: React.Dispatch<React.SetStateAction<boolean>>;
    setIsEmptyRef: React.Dispatch<React.SetStateAction<boolean>>;
    setIsEmptyTypeProuct: React.Dispatch<React.SetStateAction<boolean>>;
    setIsSalesSupport: React.Dispatch<
        React.SetStateAction<boolean | undefined>
    >;
};

export default function AlerteUpdateProductModal({
    isAlertupdateProductModal,
    editProduct,
    setIsAlertupdateProductModal,
    selectedRows,
    setIsEditAction,
    setIsHiddenCard,
    isHiddenCard,
    setModifProduct,
    isAssociateModuleModalOpen,
    setIsAssociateModuleModalOpen,
    setIsAlerteDissociateModuleModalOpened,
    isAlerteDissociateModuleModalOpened,
    setIsEmptyDes,
    setIsEmptyRef,
    setIsEmptyTypeProuct,
    setIsSalesSupport,
}: AlerteModaltype) {
    return (
        <div>
            <StyledModal
                className="updateAlerte"
                centered
                isOpen={isAlertupdateProductModal}
            >
                <ModalHeader className="d-flex align-items-center">
                    <div
                        className="d-flex align-items-center justify-content-between "
                        style={{
                            height: "30px",
                            display: "grid",
                            gridTemplateColumns: "1.8fr 0.2fr",
                            gridTemplateRows: "1fr",
                            gap: "65px",
                        }}
                    >
                        <p className="title-text-style">CONFIRMATION</p>
                        <StyledIconButton
                            icon="CrossIcon"
                            onClick={() => {
                                if (editProduct === "Edit") {
                                    setIsAlertupdateProductModal(
                                        !isAlertupdateProductModal
                                    );
                                    setSelectedProduct(selectedRows);
                                    setSelectedProductBeforeUpdate(
                                        selectedRows
                                    );
                                    setIsEditAction(true);
                                    setModifProduct(false);
                                    setEditProduct("");
                                    setIsAssociateModuleModalOpen(false);
                                    setIsEmptyDes(false);
                                    setIsEmptyRef(false);
                                    setIsSalesSupport(false);
                                } else if (editProduct === "Associate") {
                                    setIsAlertupdateProductModal(
                                        !isAlertupdateProductModal
                                    );
                                    setIsEditAction(true);
                                    setSelectedProduct(selectedRows);
                                    setIsHiddenCard(!isHiddenCard);
                                    setIsAssociateModuleModalOpen(
                                        isAssociateModuleModalOpen
                                    );
                                    setModifProduct(false);
                                    setEditProduct("");
                                } else if (editProduct === "dissociate") {
                                    setIsAlerteDissociateModuleModalOpened(
                                        isAlerteDissociateModuleModalOpened
                                    );
                                    setIsEditAction(true);
                                    setIsAlertupdateProductModal(
                                        !isAlertupdateProductModal
                                    );
                                    setSelectedProduct(selectedRows);
                                    setIsHiddenCard(!isHiddenCard);
                                    setModifProduct(false);
                                    setEditProduct("");
                                } else if (editProduct === "EditAssociate") {
                                    setIsHiddenCard(isHiddenCard);
                                    setIsAlertupdateProductModal(
                                        !isAlertupdateProductModal
                                    );
                                    setIsAssociateModuleModalOpen(
                                        !isAssociateModuleModalOpen
                                    );
                                    setModifProduct(false);
                                    setEditProduct("");
                                    setIsEditAction(true);
                                    setSelectedModulesToDissociate(true);
                                } else {
                                    setIsAlertupdateProductModal(
                                        !isAlertupdateProductModal
                                    );
                                    setIsHiddenCard(!isHiddenCard);
                                    setModifProduct(false);
                                    setEditProduct("");
                                    setSelectedProduct("");
                                    setIsEditAction(true);
                                    setIsEmptyDes(false);
                                    setIsEmptyRef(false);
                                    setIsEmptyTypeProuct(false);
                                    setIsSalesSupport(false);
                                }
                            }}
                        >
                            <CrossIcon
                                cursor="pointer"
                                height={10}
                                width={10}
                            />
                        </StyledIconButton>
                    </div>
                </ModalHeader>

                <ModalBody>
                    <div
                        style={{
                            marginLeft: "30px",
                            marginTop: "20px",
                            marginBottom: "20px",
                        }}
                    >
                        <StyledLabel>
                            Voulez-vous enregistrer les modifications effectuées
                            ?
                        </StyledLabel>
                    </div>
                </ModalBody>

                <ModalFooter
                    className="d-flex justify-content-end"
                    style={{
                        background: "#FFFFFF",
                    }}
                >
                    <div
                        className="d-flex justify-content-end pr-5"
                        style={{ gap: "30px" }}
                    >
                        <div className="col-4">
                            <StyledButton
                                disabled={false}
                                rounded
                                variant="light"
                                outline={true}
                                size="sm"
                                style={{
                                    background: "white",
                                    color: "black",
                                    padding: "0px",
                                    width: "150px",
                                    height: "45px",
                                }}
                                onClick={() => {
                                    if (editProduct === "Edit") {
                                        setIsAlertupdateProductModal(
                                            !isAlertupdateProductModal
                                        );
                                        setSelectedProduct(selectedRows);
                                        setSelectedProductBeforeUpdate(
                                            selectedRows
                                        );
                                        setIsEditAction(true);
                                        setModifProduct(false);
                                        setEditProduct("");
                                        setIsAssociateModuleModalOpen(false);
                                        setIsEmptyDes(false);
                                        setIsEmptyRef(false);
                                        setIsSalesSupport(false);
                                    } else if (editProduct === "Associate") {
                                        setIsAlertupdateProductModal(
                                            !isAlertupdateProductModal
                                        );
                                        setIsEditAction(true);
                                        setSelectedProduct(selectedRows);
                                        setIsHiddenCard(!isHiddenCard);
                                        setIsAssociateModuleModalOpen(
                                            isAssociateModuleModalOpen
                                        );
                                        setModifProduct(false);
                                        setEditProduct("");
                                    } else if (editProduct === "dissociate") {
                                        setIsAlerteDissociateModuleModalOpened(
                                            isAlerteDissociateModuleModalOpened
                                        );
                                        setIsEditAction(true);
                                        setIsAlertupdateProductModal(
                                            !isAlertupdateProductModal
                                        );
                                        setSelectedProduct(selectedRows);
                                        setIsHiddenCard(!isHiddenCard);
                                        setModifProduct(false);
                                        setEditProduct("");
                                    } else if (
                                        editProduct === "EditAssociate"
                                    ) {
                                        setIsHiddenCard(isHiddenCard);
                                        setIsAlertupdateProductModal(
                                            !isAlertupdateProductModal
                                        );
                                        setIsAssociateModuleModalOpen(
                                            !isAssociateModuleModalOpen
                                        );
                                        setModifProduct(false);
                                        setEditProduct("");
                                        setIsEditAction(true);
                                        setSelectedModulesToDissociate(true);
                                    } else {
                                        setIsAlertupdateProductModal(
                                            !isAlertupdateProductModal
                                        );
                                        setIsHiddenCard(!isHiddenCard);
                                        setModifProduct(false);
                                        setEditProduct("");
                                        setSelectedProduct("");
                                        setIsEditAction(true);
                                        setIsEmptyDes(false);
                                        setIsEmptyRef(false);
                                        setIsEmptyTypeProuct(false);
                                        setIsSalesSupport(false);
                                    }
                                }}
                            >
                                Annuler
                            </StyledButton>
                        </div>
                        <div className="col-4">
                            <StyledButton
                                disabled={false}
                                rounded
                                variant="primary"
                                outline={false}
                                size="sm"
                                style={{
                                    color: "white",
                                    padding: "0px",
                                    width: "150px",
                                    height: "45px",
                                }}
                                onClick={() => {
                                    if (editProduct === "EditAssociate") {
                                        setIsAlertupdateProductModal(
                                            !isAlertupdateProductModal
                                        );
                                        setModifProduct(true);
                                    } else {
                                        setEditProduct("");
                                        setIsHiddenCard(isHiddenCard);
                                        setIsAlertupdateProductModal(
                                            !isAlertupdateProductModal
                                        );
                                        setIsAssociateModuleModalOpen(
                                            isAssociateModuleModalOpen
                                        );
                                        setModifProduct(true);
                                    }
                                }}
                            >
                                Valider
                            </StyledButton>
                        </div>
                    </div>
                </ModalFooter>
            </StyledModal>
        </div>
    );
}
