import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
    CrossIcon,
    StyledIconButton,
    StyledModal,
    StyledButton,
    StyledStepButton,
    StyledLabel,
    StyledTextInput,
    StyledSelectInput,
} from "@aureskonnect/react-ui";
import { ModalBody, ModalHeader, CardFooter, CardBody } from "reactstrap";
import userAdd from "../../assets/images/svg-icons/userAdd.svg";
import MultiCustomSelect from "@components/Common/StyledComponent/MultiCustomSelect";
import { AvForm } from "availity-reactstrap-validation";
import { clearMultiCustomSelectionData } from "@components/Common/StyledComponent/store";
import { showToast } from "../../components/Common/ToastShow";
import { ModalContentWrapper } from "@pages/Authorizations/ModalContentWrapper";
import { useSnapshot } from "valtio";
import { storeAuthorisation } from "@pages/Authorizations/store";
import NoOptionsMessage from "../Motifs/NoOptionsMessage";
import { store, setSelectedAccount } from "../store";
import "../Motifs/index.css";

type ProfilAdministrateurModalType = {
    isModalOpened: boolean;
    setIsModalOpened: Function;
    setProfil: Function;
    setProfilName: Function;
};

export function ProfilAdministrateurModal({
    isModalOpened,
    setIsModalOpened,
    setProfil,
    setProfilName,
}: ProfilAdministrateurModalType) {
    const colorBreadcrumb = "#E5E4E4AB";
    const { t } = useTranslation();
    const { SelectedAccount } = useSnapshot(store);
    const uidEntity = localStorage.getItem("Uid_Entitie_User");
    const { dataProject } = useSnapshot(storeAuthorisation);
    const [typeDuCompteOptions] = React.useState<any>([
        { label: "Boutique", value: "Boutique" },
    ]);
    // const [optionsCompte, setOptionsCompte] = React.useState([]);
    // const [selectedOptions, setSelectedOptions] = React.useState<any[]>([]);
    const [selectedOptionsBalise, setSelectedOptionsBalise] = React.useState<
        any[]
    >([]);
    const [optionsBalise] = useState([
        "Administratif",
        "Financier",
        "R&D",
        "Production",
    ]);
    const [designation, setDesignation] = useState("");
    const [description, setDescription] = useState("");
    const [uidProfil] = useState<string>(
        "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
            var r = (Math.random() * 16) | 0,
                v = c === "x" ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        })
    );

    const [stepBtn1, setStepBtn1] = useState<any>("in progress");
    const [stepBtn2, setStepBtn2] = useState<any>("waiting");
    const [isEmptyDesignation, setIsEmptyDesignation] = React.useState<boolean>(
        false
    );
    const [isEmptyType, setIsEmptyType] = React.useState<boolean>(false);
    const [typeProfil, setTypeProfil] = useState("");
    const [listSelectedData, setlistSelectedData] = React.useState<any>({});
    let Data: any = {};

    function saveHistoryChanges(newProfil: any, uidProfil: string) {
        const oldData = {};
        const newData = {
            profil: newProfil.designation,
            etat: newProfil.etat,
            type: newProfil.Type,
            // motif: newProfil.motif,
            description: newProfil.description,
            designation: newProfil.designation,
            uidProfil: uidProfil,
        };
        fetch(`${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/HistoryChanges`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                oldData: oldData,
                newData: newData,
                uidUpdatedData: uidEntity,
                typeHistory: "4",
            }),
        })
            .then((response) => {
                response.json().then((data) => {
                    return data.error;
                });
            })
            .catch((e: any) => {
                return false;
            });
    }

    function handleValidateButtonClickEvent(
        designationAuto: string,
        typeProfil: any,
        description: string,
        balise: any
    ) {
        const keys = Object.keys(listSelectedData);
        Object.values(listSelectedData).forEach((Element: any) => {
            if (keys.includes(Element.IdParent)) {
                if (Element.IdParent === Element.Id) {
                    if (
                        listSelectedData[Element.IdParent].Visible === true &&
                        listSelectedData[Element.Id].Visible === true
                    ) {
                        if (
                            listSelectedData[Element.Id].Consult === false ||
                            listSelectedData[Element.IdParent].Modifie === false
                        ) {
                            listSelectedData[Element.Id].Modifie = false;
                        }
                        Data[Element.Id] = listSelectedData[Element.Id];
                    }
                } else {
                    if (
                        listSelectedData[Element.IdParent].Visible === true &&
                        listSelectedData[Element.IdParent].Consult === true &&
                        listSelectedData[Element.Id].Visible === true
                    ) {
                        if (
                            listSelectedData[Element.Id].Consult === false ||
                            listSelectedData[Element.IdParent].Modifie === false
                        ) {
                            listSelectedData[Element.Id].Modifie = false;
                        }
                        Data[Element.Id] = listSelectedData[Element.Id];
                    }
                }
            }
        });
        const copyData = JSON.parse(JSON.stringify(dataProject));
        Object.keys(copyData).forEach((el: any) => {
            Object.keys(Data).forEach((elData: any) => {
                if (el === elData) {
                    copyData[el] = Data[elData];
                }
            });
        });
        const newProfil = {
            etat: "Activé",
            motif: "",
            type: typeProfil,
            description: description,
            designation: designationAuto,
            autorisation: copyData,
            uidEntity: uidEntity,
            balise: balise.join(","),
        };
        fetch(`${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/addNewProfil`, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                dataProfil: newProfil,
                uidProfil: uidProfil,
            }),
        })
            .then((response) => {
                response.json().then((data) => {
                    if (!data.error) {
                        saveHistoryChanges(newProfil, uidProfil);
                        setProfil(uidProfil);
                        setProfilName(designationAuto);
                        if (SelectedAccount !== "") {
                            setSelectedAccount({
                                ...SelectedAccount,
                                Nature: uidProfil,
                                userProfilName: designationAuto,
                                userProfil: uidProfil,
                            });
                            // setSelectedAccount({
                            //     ...SelectedAccount,
                            //     userProfilName: designationAuto,
                            // });

                            // setSelectedAccount({
                            //     ...SelectedAccount,
                            //     userProfil: uidProfil,
                            // });
                        }
                        showToast(true, "Le profil a été ajouté avec succès.");
                        setIsModalOpened(!isModalOpened);
                    } else {
                        showToast(
                            false,
                            "Ouups!, une erreur est servenue lors de la création du profil."
                        );
                    }
                });
            })
            .catch((e: any) => {
                console.log({ error: e.message });
            });
    }

    async function handleTypeDuCompte() {
        try {
            await fetch(
                `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/listTypeGestionnaireDeComptes?uuidEntity=${uidEntity}&archived=false`,
                {
                    method: "get",
                    headers: { "Content-Type": "application/json" },
                }
            )
                .then((response) => response.json())
                .then((data) => {
                    data.data.forEach((element: any) => {
                        typeDuCompteOptions.push({
                            label: element.Designation,
                            value: element.Designation,
                        });
                    });
                });
        } catch (error) {
            console.log(error);
        }
    }

    function InitialiseData() {
        clearMultiCustomSelectionData();
        setSelectedOptionsBalise([]);
        setTypeProfil("");
        setDesignation("");
        setDescription("");
        setIsEmptyType(false);
        setIsEmptyDesignation(false);
    }

    React.useEffect(() => {
        handleTypeDuCompte();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <React.Fragment>
            <StyledModal
                className="custom-modal-style"
                toggle={true}
                isOpen={isModalOpened!}
            >
                <ModalHeader className="d-flex align-items-center">
                    <div
                        className="d-flex align-items-center justify-content-between"
                        style={{
                            height: "35px",
                            display: "grid",
                            gridTemplateColumns: "2.8fr 0.2fr",
                            gridTemplateRows: "1fr",
                            gap: "1260px",
                        }}
                    >
                        <p
                            className="title-modal-style"
                            style={{ width: "max-content" }}
                        >
                            NOUVEAU PROFIL ADMINISTRATEUR DU COMPTE
                        </p>
                        <StyledIconButton
                            icon="CrossIcon"
                            onClick={() => {
                                setIsModalOpened(!isModalOpened);
                                InitialiseData();
                            }}
                        >
                            <CrossIcon
                                cursor="pointer"
                                height={10}
                                width={10}
                            />
                        </StyledIconButton>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <div
                        className="d-flex align-items-center justify-content-between"
                        style={{
                            height: "25px",
                            display: "grid",
                            gridTemplateColumns: "2fr 2fr auto",
                            gridTemplateRows: "1fr",
                            gap: "30px",
                            marginTop: "10px",
                        }}
                    >
                        <StyledStepButton
                            color="white"
                            rounded
                            step={stepBtn1}
                            stepDetail="Ajouter profil"
                            stepNumber={1}
                            stepTitle="Étape"
                            style={{
                                width: "350px",
                                height: "25px",
                                marginLeft: "20px",
                            }}
                        />
                        <StyledStepButton
                            color="white"
                            rounded
                            step={stepBtn2}
                            stepDetail="Paramétrage autorisations"
                            stepNumber={2}
                            stepTitle="Étape"
                            style={{
                                width: "350px",
                                height: "25px",
                                marginRight: "920px",
                            }}
                        />
                    </div>
                    {stepBtn1 === "in progress" ? (
                        <fieldset
                            className="border p-3 title-legend.style"
                            style={{ marginTop: "20px" }}
                        >
                            <legend className="w-auto">
                                Remplir les champs
                            </legend>
                            <div
                                className="d-flex align-items-center justify-content-between"
                                style={{
                                    height: "580px",
                                    display: "grid",
                                    gridTemplateColumns: "2fr 2fr auto",
                                    gridTemplateRows: "1fr",
                                    gap: "30px",
                                }}
                            >
                                <div
                                    className="d-flex align-items-center justify-content-between"
                                    style={{
                                        background: colorBreadcrumb,
                                        borderRadius: "25px",
                                        height: "560px",
                                        width: "400px",
                                        marginTop: "-20px",
                                    }}
                                >
                                    <img
                                        src={userAdd}
                                        role="button"
                                        id="dropdownMenuButton1"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                        alt="parametrage"
                                        style={{
                                            height: "300px",
                                            width: "200px",
                                            marginLeft: "25%",
                                        }}
                                    />
                                </div>
                                <CardBody
                                    style={{
                                        height: "100%",
                                        background: "#FFFFFF",
                                        padding: "10px",
                                        marginTop: "0px",
                                    }}
                                >
                                    <AvForm>
                                        <StyledLabel>Désignation*</StyledLabel>
                                        <StyledTextInput
                                            id="example-inputddddd"
                                            name="example-inputddddd"
                                            style={{
                                                border: isEmptyDesignation
                                                    ? "1px solid red"
                                                    : "1px solid #ced4da",
                                            }}
                                            placeholder="Saisir"
                                            type="text"
                                            onChange={(e: any) => {
                                                setIsEmptyDesignation(false);
                                                setDesignation(e.target.value);
                                            }}
                                            value={designation}
                                            autoFocus
                                        />
                                        {isEmptyDesignation ? (
                                            <div
                                                style={{
                                                    color: "red",
                                                }}
                                            >
                                                Ce champ est obligatoire
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </AvForm>
                                    <AvForm>
                                        <StyledLabel>
                                            Type du compte*
                                        </StyledLabel>
                                        <StyledSelectInput
                                            options={typeDuCompteOptions}
                                            menuPlacement="auto"
                                            menuPosition="fixed"
                                            isSearchable
                                            onChange={(e: any) => {
                                                setTypeProfil(e.value);
                                            }}
                                            placeholder={"Selectionner"}
                                            components={{
                                                NoOptionsMessage,
                                            }}
                                            name="TypeComptes"
                                        />
                                        {isEmptyType ? (
                                            <div
                                                style={{
                                                    color: "red",
                                                }}
                                            >
                                                Ce champ est obligatoire
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </AvForm>
                                    <AvForm style={{ marginTop: "12px" }}>
                                        <StyledLabel>Balise</StyledLabel>
                                        <MultiCustomSelect
                                            style={{}}
                                            onDelete={() => {}}
                                            result={selectedOptionsBalise}
                                            setResult={setSelectedOptionsBalise}
                                            data={optionsBalise}
                                            onChange={(e: any) => {}}
                                            className={""}
                                            placeholder={`${t("Select")}…`}
                                            value={t("Choose")}
                                            name="Balise"
                                        />
                                    </AvForm>
                                    <AvForm style={{ marginTop: "12px" }}>
                                        <StyledLabel>Descriptif</StyledLabel>
                                        <StyledTextInput
                                            style={{ height: "200px" }}
                                            id="example-input"
                                            name="example-input"
                                            placeholder="Saisir"
                                            type="textarea"
                                            onChange={(e: any) => {
                                                setDescription(e.target.value);
                                            }}
                                            value={description}
                                        />
                                    </AvForm>
                                </CardBody>
                            </div>
                        </fieldset>
                    ) : (
                        <div style={{ marginTop: "20px" }}>
                            <ModalContentWrapper
                                setlistSelectedData={setlistSelectedData}
                            />
                        </div>
                    )}
                </ModalBody>
                <CardFooter
                    className="d-flex justify-content-end"
                    style={{
                        background: "#FFFFFF",
                    }}
                >
                    <div
                        className="row"
                        style={{
                            gap: "10px",
                            marginRight: "0px",
                        }}
                    >
                        <div>
                            <StyledButton
                                disabled={false}
                                rounded
                                variant="light"
                                outline={true}
                                size="sm"
                                style={{
                                    background: "white",
                                    color: "black",
                                    padding: "0px",
                                    width: "150px",
                                    height: "45px",
                                }}
                                onClick={() => {
                                    if (stepBtn1 === "in progress") {
                                        setIsModalOpened(!isModalOpened);
                                        InitialiseData();
                                    } else {
                                        setStepBtn1("in progress");
                                        setStepBtn2("waiting");
                                    }
                                }}
                            >
                                {stepBtn1 === "in progress"
                                    ? "Annuler"
                                    : "Retour"}
                            </StyledButton>
                        </div>
                        <div>
                            <StyledButton
                                disabled={false}
                                rounded
                                variant="primary"
                                outline={false}
                                size="sm"
                                style={{
                                    color: "white",
                                    padding: "0px",
                                    width: "150px",
                                    height: "45px",
                                }}
                                onClick={() => {
                                    if (stepBtn1 === "in progress") {
                                        //controle sur les champs
                                        if (designation === "") {
                                            showToast(
                                                false,
                                                "Veuillez remplir le champ Désignation."
                                            );
                                            setIsEmptyDesignation(true);
                                        } else if (typeProfil === "") {
                                            showToast(
                                                false,
                                                "Veuillez choisir  un type de compte."
                                            );
                                            setIsEmptyType(true);
                                        } else {
                                            setStepBtn1("valid");
                                            setStepBtn2("in progress");
                                        }
                                    } else {
                                        handleValidateButtonClickEvent(
                                            designation,
                                            typeProfil,
                                            description,
                                            selectedOptionsBalise
                                        );
                                    }
                                }}
                            >
                                {stepBtn1 === "in progress"
                                    ? "Etape suivante"
                                    : "Valider"}
                            </StyledButton>
                        </div>
                    </div>
                </CardFooter>
            </StyledModal>
        </React.Fragment>
    );
}
