import React from "react";
import {
    StyledButton,
    StyledModal,
    CrossIcon,
    StyledIconButton,
    StyledLabel,
} from "@aureskonnect/react-ui";
import { ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { showToast } from "@components/Common/ToastShow";
import { useSnapshot } from "valtio";
import { store } from "../store";

import "../Overview/index.css";

type AlerteModaltype = {
    isAlerteModalOpened: boolean;
    setIsAlerteModalOpened: Function;
    setDataIsUpdated: React.Dispatch<React.SetStateAction<number | boolean>>;
    dataIsUpdated: number | boolean;
    ProductsNames?: any; //string;
    listProduitsPack?: any; //string;
    archiveActivePacks?: boolean;
    archiveActiveProduct: boolean;
    type?: string;
    AlerteMessage?: string;
    selectedRows: any;
    selectedProduct?: any;
};

export default function AlerteModal({
    isAlerteModalOpened,
    setIsAlerteModalOpened,
    setDataIsUpdated,
    dataIsUpdated,
    ProductsNames,
    archiveActiveProduct,
    archiveActivePacks,
    type,
    selectedRows,
    selectedProduct,
    listProduitsPack,
}: AlerteModaltype) {
    const uidEntity = localStorage.getItem("Uid_Entitie_User");
    const { SelectedPack } = useSnapshot(store);
    const { alerteMessagePack } = useSnapshot(store);

    function saveHistoryChanges(oldData: any, newData: any, type: any) {
        fetch(`${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/HistoryChanges`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                oldData: oldData,
                newData: newData,
                uidUpdatedData: uidEntity,
                typeHistory: type,
            }),
        })
            .then((response) => {
                response.json().then((data) => {
                    setTimeout(function () {}, 2000);
                    return data.error;
                });
            })
            .catch((e: any) => {
                return false;
            });
    }

    function handleArchiveActiveProducts() {
        if (ProductsNames) {
            fetch(
                `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/archiveActiveProducts`,
                {
                    method: "PUT",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        uidEntitie: uidEntity,
                        listReferencesProducts: ProductsNames,
                        action: archiveActiveProduct,
                    }),
                }
            )
                .then((response) => {
                    response.json().then((data) => {
                        if (data.error === false) {
                            showToast(
                                true,
                                archiveActiveProduct
                                    ? " les produits ont été archivés avec succès."
                                    : " les produits ont été activés avec succès."
                            );
                            selectedRows.forEach((element: any) => {
                                const oldData = {
                                    name: element.Produit,
                                    comment: element.Description,
                                    reference: element.Référence,
                                    salesSupport: element.SalesSupport,
                                    isArchived: !element.isArchived
                                        ? "Produit activé"
                                        : "Produit archivé",
                                    isPrincipalProduct:
                                        element.isPrincipalProduct,
                                };
                                const newData = {
                                    name: element.Produit,
                                    comment: element.Description,
                                    reference: element.Référence,
                                    salesSupport: element.SalesSupport,
                                    isArchived: archiveActiveProduct
                                        ? "Produit archivé"
                                        : "Produit activé",
                                    isPrincipalProduct:
                                        element.isPrincipalProduct,
                                };
                                saveHistoryChanges(oldData, newData, 2);
                            });

                            setTimeout(() => {
                                setDataIsUpdated(!dataIsUpdated);
                            }, 2000);
                        }
                    });
                })
                .catch((e: any) => {
                    console.log({ error: e.message });
                });
        } else {
            fetch(
                `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/archiveActiveProducts`,
                {
                    method: "PUT",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        uidEntitie: uidEntity,
                        listReferencesProducts: selectedProduct.Référence,
                        action: archiveActiveProduct,
                    }),
                }
            )
                .then((response) => {
                    response.json().then((data) => {
                        if (data.error === false) {
                            showToast(
                                true,
                                archiveActiveProduct
                                    ? "le produit a été archivé avec succès."
                                    : "le produit a été activé avec succès."
                            );
                            saveHistoryChanges(
                                {
                                    name: selectedProduct.Produit,
                                    comment: selectedProduct.Description,
                                    reference: selectedProduct.Référence,
                                    salesSupport: selectedProduct.SalesSupport,
                                    isArchived: !selectedProduct.isArchived
                                        ? "Produit activé"
                                        : "Produit archivé",
                                    isPrincipalProduct:
                                        selectedProduct.isPrincipalProduct,
                                },
                                {
                                    name: selectedProduct.Produit,
                                    comment: selectedProduct.Description,
                                    reference: selectedProduct.Référence,
                                    salesSupport: selectedProduct.SalesSupport,
                                    isArchived: archiveActiveProduct
                                        ? "Produit archivé"
                                        : "Produit activé",
                                    isPrincipalProduct:
                                        selectedProduct.isPrincipalProduct,
                                },
                                2
                            );
                            setTimeout(() => {
                                setDataIsUpdated(!dataIsUpdated);
                            }, 2000);
                        }
                    });
                })
                .catch((e: any) => {
                    console.log({ error: e.message });
                });
        }
    }

    function ArchiveActivePack() {
        if (listProduitsPack !== undefined && listProduitsPack.length > 0) {
            fetch(
                `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/archiveActivePack?uidPack=${listProduitsPack}&isArchived=${archiveActivePacks}`,
                {
                    method: "PUT",
                    headers: { "Content-Type": "application/json" },
                }
            )
                .then((response) => {
                    response.json().then((data) => {
                        if (!data.error) {
                            showToast(
                                true,
                                archiveActivePacks
                                    ? "Les Packs ont été archivé avec succès."
                                    : "Les Packs ont été activé  avec succès."
                            );
                            selectedRows.forEach((element: any) => {
                                const oldData = {
                                    designation: element.Pack,
                                    reference: element.Référence,
                                    isArchived: !archiveActivePacks,
                                };
                                const newData = {
                                    designation: element.Pack,
                                    reference: element.Référence,
                                    isArchived: archiveActivePacks,
                                };
                                saveHistoryChanges(oldData, newData, 5);
                            });

                            setTimeout(() => {
                                setDataIsUpdated(!dataIsUpdated);
                            }, 1000);
                        } else {
                            showToast(
                                false,
                                archiveActivePacks
                                    ? "Ouups!, erreur lors de l'archivage du pack,Veuillez réessayer svp"
                                    : "Ouups!, erreur lors de l'activation du pack,Veuillez réessayer svp"
                            );
                        }
                    });
                })
                .catch((error) => {
                    console.error("There was an error!", error);
                    showToast(false, error);
                });
        } else {
            fetch(
                `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/archiveActivePack?uidPack=${SelectedPack.uid}&isArchived=${archiveActivePacks}`,
                {
                    method: "PUT",
                    headers: { "Content-Type": "application/json" },
                }
            )
                .then((response) => {
                    response.json().then((data) => {
                        if (!data.error) {
                            showToast(
                                true,
                                archiveActivePacks
                                    ? "le pack a été archivé avec succès."
                                    : "le pack a été activé  avec succès."
                            );
                            saveHistoryChanges(
                                {
                                    designation: SelectedPack.Pack,
                                    reference: SelectedPack.Référence,
                                    isArchived: !archiveActivePacks,
                                },
                                {
                                    designation: SelectedPack.Pack,
                                    reference: SelectedPack.Référence,
                                    isArchived: archiveActivePacks,
                                },
                                5
                            );
                            setTimeout(() => {
                                setDataIsUpdated(!dataIsUpdated);
                            }, 1000);
                        } else {
                            showToast(
                                false,
                                archiveActivePacks
                                    ? "Ouups!, erreur lors de l'archivage du pack,Veuillez réessayer svp"
                                    : "Ouups!, erreur lors de l'activation du pack,Veuillez réessayer svp"
                            );
                        }
                    });
                })
                .catch((error) => {
                    console.error("There was an error!", error);
                    showToast(false, error);
                });
        }
    }

    // function AssociateModulesToProduct() {
    //     fetch(
    //         `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/associateModulesToProduct`,
    //         {
    //             method: "PUT",
    //             headers: { "Content-Type": "application/json" },
    //             body: JSON.stringify({
    //                 referenceProduct: selectedRows,
    //                 referenceParentProduct: selectedProduct.Référence,
    //             }),
    //         }
    //     )
    //         .then((response) => {
    //             if (response.status === 200 && response.ok === true) {
    //                 showToast(
    //                     true,
    //                     `l'association a été effectué avec succès.`
    //                 );
    //                 setTimeout(() => {
    //                     setDataIsUpdated(!dataIsUpdated);
    //                 }, 2000);
    //             } else if (response.status === 500) {
    //                 showToast(
    //                     false,
    //                     `Ouups! erreur lors de l'association au produit, veuillez réessayer SVP`
    //                 );
    //             }
    //         })
    //         .catch((e: any) => {
    //             console.log({ error: e.message });
    //         });
    // }

    let msgAlert = "";
    // let productsListNames = "";

    switch (type) {
        case "": {
            let productName = "";

            if (ProductsNames !== undefined && ProductsNames.length > 1) {
                // productsListNames = " : ";
                ProductsNames.forEach((element: any) => {
                    // eslint-disable-next-line no-useless-concat
                    // productsListNames += `"${element}"` + " , ";
                });

                // productsListNames = productsListNames.slice(0, -2);
            } else if (selectedProduct !== undefined) {
                productName = selectedProduct.Produit;
            }

            if (archiveActiveProduct) {
                msgAlert = `Voulez-vous confirmer l'archivage ${
                    ProductsNames !== undefined && ProductsNames.length > 1
                        ? ` de ces produits`
                        : ` du produit "${productName}"`
                }  ?`;
            } else {
                msgAlert = `Voulez-vous confirmer l'activation ${
                    ProductsNames !== undefined && ProductsNames.length > 1
                        ? `de ces produits`
                        : ` du produit "${productName}"`
                } ?`;
            }
            break;
        }

        case "Pack": {
            msgAlert = alerteMessagePack;
            break;
        }

        case "Association": {
            msgAlert =
                "Attention ce produit possède des licences actives. Voulez-vous affecter ce module à tous les clients qui possèdent cette licence ?";
            break;
        }

        case "Dissociation": {
            msgAlert = `Voulez-vous confirmer la dissociation du module ${selectedProduct.Module} du produit ${selectedProduct.parentName} ?`;
            break;
        }
    }

    return (
        <div>
            <StyledModal className="" centered isOpen={isAlerteModalOpened}>
                <ModalHeader className="d-flex align-items-center">
                    <div
                        className="d-flex align-items-center justify-content-between "
                        style={{
                            height: "20px",
                            width: "200px",
                            display: "grid",
                            gridTemplateColumns: "1.8fr 0.2fr",
                            gridTemplateRows: "1fr",
                            gap: "300px",
                        }}
                    >
                        <p className="title-text-style">CONFIRMATION</p>
                        <StyledIconButton
                            icon="CrossIcon"
                            onClick={() => {
                                setIsAlerteModalOpened(!isAlerteModalOpened);
                            }}
                        >
                            <CrossIcon
                                cursor="pointer"
                                height={10}
                                width={10}
                            />
                        </StyledIconButton>
                    </div>
                </ModalHeader>

                <ModalBody>
                    <div
                        style={{
                            marginLeft: "20px",
                            marginTop: "20px",
                            marginBottom: "20px",
                        }}
                    >
                        <StyledLabel>{msgAlert}</StyledLabel>
                    </div>
                </ModalBody>
                <ModalFooter
                    className="card-footer d-flex align-items-center justify-content-end"
                    style={{
                        background: "#FFFFFF",
                        height: "fit-content",
                    }}
                >
                    <StyledButton
                        disabled={false}
                        rounded
                        variant="light"
                        outline={true}
                        size="sm"
                        style={{
                            background: "white",
                            color: "black",
                            padding: "0px",
                            width: "150px",
                            height: "45px",
                        }}
                        onClick={() => {
                            setIsAlerteModalOpened(!isAlerteModalOpened);
                        }}
                    >
                        Annuler
                    </StyledButton>

                    <StyledButton
                        className="ml-2"
                        disabled={false}
                        rounded
                        variant="primary"
                        outline={false}
                        size="sm"
                        style={{
                            color: "white",
                            padding: "0px",
                            width: "150px",
                            height: "45px",
                        }}
                        onClick={() => {
                            if (type === "Pack") {
                                ArchiveActivePack();
                            } else {
                                if (type === "Association") {
                                    // AssociateModulesToProduct();
                                } else {
                                    handleArchiveActiveProducts();
                                }
                            }
                            setIsAlerteModalOpened(!isAlerteModalOpened);
                        }}
                    >
                        Valider
                    </StyledButton>
                </ModalFooter>
            </StyledModal>
        </div>
    );
}
