import React from "react";
import {
    StyledButton,
    StyledModal,
    CrossIcon,
    StyledIconButton,
    StyledLabel,
} from "@aureskonnect/react-ui";
import { setSelectedMotif } from "../store";
import { ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import "../Overview/index.css";

type AlerteModaltype = {
    isAlertupdateModal: boolean;
    setIsAlertupdateModal: Function;
    setModif: Function;
    setEdit: Function;
    setIsHidden: React.Dispatch<React.SetStateAction<boolean>>;
    isHidden: boolean;
    edit: string;
    selectedRows: any;
    setDescription: React.Dispatch<React.SetStateAction<string>>;
    setMotifValue: React.Dispatch<React.SetStateAction<string>>;
    setIsEmpty: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function AlerteUpdateModalMotif({
    isAlertupdateModal,
    setIsAlertupdateModal,
    setModif,
    setIsHidden,
    isHidden,
    setEdit,
    edit,
    selectedRows,
    setDescription,
    setMotifValue,
    setIsEmpty,
}: AlerteModaltype) {
    return (
        <div>
            <StyledModal
                className="updateAlerte"
                centered
                isOpen={isAlertupdateModal}
            >
                <ModalHeader className="d-flex align-items-center">
                    <div
                        className="d-flex align-items-center justify-content-between "
                        style={{
                            height: "30px",
                            display: "grid",
                            gridTemplateColumns: "1.8fr 0.2fr",
                            gridTemplateRows: "1fr",
                            gap: "65px",
                        }}
                    >
                        <p className="title-text-style">CONFIRMATION</p>
                        <StyledIconButton
                            icon="CrossIcon"
                            onClick={() => {
                                if (edit === "EditMotif") {
                                    setIsAlertupdateModal(!isAlertupdateModal);
                                    setEdit("");
                                    setIsHidden(isHidden);
                                    setModif(false);
                                    setSelectedMotif(selectedRows);
                                    setIsEmpty(false);
                                } else {
                                    setIsAlertupdateModal(!isAlertupdateModal);
                                    setIsHidden(!isHidden);
                                    setModif(false);
                                    setEdit("");
                                    setDescription("");
                                    setMotifValue("");
                                    setIsEmpty(false);
                                }
                            }}
                        >
                            <CrossIcon
                                cursor="pointer"
                                height={10}
                                width={10}
                            />
                        </StyledIconButton>
                    </div>
                </ModalHeader>

                <ModalBody>
                    <div
                        style={{
                            marginLeft: "30px",
                            marginTop: "20px",
                            marginBottom: "20px",
                        }}
                    >
                        <StyledLabel>
                            Voulez-vous enregistrer les modifications effectuées
                            ?
                        </StyledLabel>
                    </div>
                </ModalBody>

                <ModalFooter
                    className="d-flex justify-content-end"
                    style={{
                        background: "#FFFFFF",
                    }}
                >
                    <div
                        className="d-flex justify-content-end pr-5"
                        style={{ gap: "30px" }}
                    >
                        <div className="col-4">
                            <StyledButton
                                disabled={false}
                                rounded
                                variant="light"
                                outline={true}
                                size="sm"
                                style={{
                                    background: "white",
                                    color: "black",
                                    padding: "0px",
                                    width: "150px",
                                    height: "45px",
                                }}
                                onClick={() => {
                                    if (edit === "EditMotif") {
                                        setIsAlertupdateModal(
                                            !isAlertupdateModal
                                        );
                                        setEdit("");
                                        setIsHidden(isHidden);
                                        setModif(false);
                                        setSelectedMotif(selectedRows);
                                        setIsEmpty(false);
                                    } else {
                                        setIsAlertupdateModal(
                                            !isAlertupdateModal
                                        );
                                        setIsHidden(!isHidden);
                                        setModif(false);
                                        setEdit("");
                                        setDescription("");
                                        setMotifValue("");
                                        setIsEmpty(false);
                                    }
                                }}
                            >
                                Annuler
                            </StyledButton>
                        </div>
                        <div className="col-4">
                            <StyledButton
                                disabled={false}
                                rounded
                                variant="primary"
                                outline={false}
                                size="sm"
                                style={{
                                    color: "white",
                                    padding: "0px",
                                    width: "150px",
                                    height: "45px",
                                }}
                                onClick={() => {
                                    setIsAlertupdateModal(!isAlertupdateModal);
                                    setModif(true);
                                    setIsHidden(isHidden);
                                    setEdit("");
                                }}
                            >
                                Valider
                            </StyledButton>
                        </div>
                    </div>
                </ModalFooter>
            </StyledModal>
        </div>
    );
}
