import React from "react";
import {
    StyledButton,
    StyledModal,
    CrossIcon,
    StyledIconButton,
    StyledLabel,
} from "@aureskonnect/react-ui";
import {
    setSelectedModule,
    setSelectedModuleBeforeUpdate,
    setSelectedProductsToDissociate,
} from "../store";
import { ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import "../Overview/index.css";

type AlerteModaltype = {
    isAlertupdateModal: boolean;
    setIsAlertupdateModal: Function;
    setIsHiddenCard: React.Dispatch<React.SetStateAction<boolean>>;
    isHiddenCard: boolean;
    setModif: Function;
    edit: string;
    selectedRows: any;
    setIsEditAction: React.Dispatch<React.SetStateAction<boolean>>;
    setEdit: Function;
    setIsAssociateProductModalOpen: Function;
    isAssociateProductModalOpen: boolean;
    setIsAlerteDissociateModalOpened: Function;
    isAlerteDissociateModalOpened: boolean;
    setIsEmptyDes: React.Dispatch<React.SetStateAction<boolean>>;
    setIsEmptyRef: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function AlerteUpdateModale({
    isAlertupdateModal,
    setIsAlertupdateModal,
    setIsHiddenCard,
    isHiddenCard,
    setModif,
    edit,
    selectedRows,
    setIsEditAction,
    setEdit,
    isAssociateProductModalOpen,
    setIsAssociateProductModalOpen,
    setIsAlerteDissociateModalOpened,
    isAlerteDissociateModalOpened,
    setIsEmptyDes,
    setIsEmptyRef,
}: AlerteModaltype) {
    return (
        <div>
            <StyledModal
                className="updateAlerte"
                centered
                isOpen={isAlertupdateModal}
            >
                <ModalHeader className="d-flex align-items-center">
                    <div
                        className="d-flex align-items-center justify-content-between "
                        style={{
                            height: "30px",
                            display: "grid",
                            gridTemplateColumns: "1.8fr 0.2fr",
                            gridTemplateRows: "1fr",
                            gap: "65px",
                        }}
                    >
                        <p className="title-text-style">CONFIRMATION</p>
                        <StyledIconButton
                            icon="CrossIcon"
                            onClick={() => {
                                if (edit === "Edit") {
                                    setIsAlertupdateModal(!isAlertupdateModal);
                                    setSelectedModule(selectedRows);
                                    setSelectedModuleBeforeUpdate(selectedRows);
                                    setIsEditAction(true);
                                    setModif(false);
                                    setEdit("");
                                    setIsAssociateProductModalOpen(false);
                                    setIsEmptyDes(false);
                                    setIsEmptyRef(false);
                                } else if (edit === "Associate") {
                                    setIsAlertupdateModal(!isAlertupdateModal);
                                    setIsEditAction(true);
                                    setSelectedModule(selectedRows);
                                    setIsHiddenCard(!isHiddenCard);
                                    setIsAssociateProductModalOpen(
                                        isAssociateProductModalOpen
                                    );
                                    setEdit("");
                                    setModif(false);
                                } else if (edit === "dissociate") {
                                    setIsAlerteDissociateModalOpened(
                                        isAlerteDissociateModalOpened
                                    );
                                    setIsEditAction(true);
                                    setIsAlertupdateModal(!isAlertupdateModal);
                                    setSelectedModule(selectedRows);
                                    setIsHiddenCard(!isHiddenCard);
                                    setEdit("");
                                    setModif(false);
                                } else if (edit === "EditAssociate") {
                                    setIsHiddenCard(isHiddenCard);
                                    setIsAlertupdateModal(!isAlertupdateModal);
                                    setIsAssociateProductModalOpen(
                                        !isAssociateProductModalOpen
                                    );
                                    setEdit("");
                                    setModif(false);
                                    setIsEditAction(true);
                                    setSelectedProductsToDissociate(true);
                                } else {
                                    setIsAlertupdateModal(!isAlertupdateModal);
                                    setIsHiddenCard(!isHiddenCard);
                                    setModif(false);
                                    setEdit("");
                                    setSelectedModule("");
                                    setIsEditAction(true);
                                    setIsEmptyDes(false);
                                    setIsEmptyRef(false);
                                }
                            }}
                        >
                            <CrossIcon
                                cursor="pointer"
                                height={10}
                                width={10}
                            />
                        </StyledIconButton>
                    </div>
                </ModalHeader>

                <ModalBody>
                    <div
                        style={{
                            marginLeft: "30px",
                            marginTop: "20px",
                            marginBottom: "20px",
                        }}
                    >
                        <StyledLabel>
                            Voulez-vous enregistrer les modifications effectuées
                            ?
                        </StyledLabel>
                    </div>
                </ModalBody>

                <ModalFooter
                    className="d-flex justify-content-end"
                    style={{
                        background: "#FFFFFF",
                    }}
                >
                    <div
                        className="d-flex justify-content-end pr-5"
                        style={{ gap: "30px" }}
                    >
                        <div className="col-4">
                            <StyledButton
                                disabled={false}
                                rounded
                                variant="light"
                                outline={true}
                                size="sm"
                                style={{
                                    background: "white",
                                    color: "black",
                                    padding: "0px",
                                    width: "150px",
                                    height: "45px",
                                }}
                                onClick={() => {
                                    if (edit === "Edit") {
                                        setIsAlertupdateModal(
                                            !isAlertupdateModal
                                        );
                                        setSelectedModule(selectedRows);
                                        setSelectedModuleBeforeUpdate(
                                            selectedRows
                                        );
                                        setIsEditAction(true);
                                        setModif(false);
                                        setEdit("");
                                        setIsAssociateProductModalOpen(false);
                                        setIsEmptyDes(false);
                                        setIsEmptyRef(false);
                                    } else if (edit === "Associate") {
                                        setIsAlertupdateModal(
                                            !isAlertupdateModal
                                        );
                                        setIsEditAction(true);
                                        setSelectedModule(selectedRows);
                                        setIsHiddenCard(!isHiddenCard);
                                        setIsAssociateProductModalOpen(
                                            isAssociateProductModalOpen
                                        );
                                        setEdit("");
                                        setModif(false);
                                    } else if (edit === "dissociate") {
                                        setIsAlerteDissociateModalOpened(
                                            isAlerteDissociateModalOpened
                                        );
                                        setIsEditAction(true);
                                        setIsAlertupdateModal(
                                            !isAlertupdateModal
                                        );
                                        setSelectedModule(selectedRows);
                                        setIsHiddenCard(!isHiddenCard);
                                        setEdit("");
                                        setModif(false);
                                    } else if (edit === "EditAssociate") {
                                        setIsHiddenCard(isHiddenCard);
                                        setIsAlertupdateModal(
                                            !isAlertupdateModal
                                        );
                                        setIsAssociateProductModalOpen(
                                            !isAssociateProductModalOpen
                                        );
                                        setEdit("");
                                        setModif(false);
                                        setIsEditAction(true);
                                        setSelectedProductsToDissociate(true);
                                    } else {
                                        setIsAlertupdateModal(
                                            !isAlertupdateModal
                                        );
                                        setIsHiddenCard(!isHiddenCard);
                                        setModif(false);
                                        setEdit("");
                                        setSelectedModule("");
                                        setIsEditAction(true);
                                        setIsEmptyDes(false);
                                        setIsEmptyRef(false);
                                    }
                                }}
                            >
                                Annuler
                            </StyledButton>
                        </div>
                        <div className="col-4">
                            <StyledButton
                                disabled={false}
                                rounded
                                variant="primary"
                                outline={false}
                                size="sm"
                                style={{
                                    color: "white",
                                    padding: "0px",
                                    width: "150px",
                                    height: "45px",
                                }}
                                onClick={() => {
                                    if (edit === "EditAssociate") {
                                        setIsAlertupdateModal(
                                            !isAlertupdateModal
                                        );
                                        setModif(true);
                                    } else {
                                        setEdit("");
                                        setIsHiddenCard(isHiddenCard);
                                        setIsAlertupdateModal(
                                            !isAlertupdateModal
                                        );
                                        setIsAssociateProductModalOpen(
                                            isAssociateProductModalOpen
                                        );
                                        setModif(true);
                                    }
                                }}
                            >
                                Valider
                            </StyledButton>
                        </div>
                    </div>
                </ModalFooter>
            </StyledModal>
        </div>
    );
}
