import React from "react";
import { useTranslation } from "react-i18next";
import {
    StyledButton,
    StyledModal,
    CrossIcon,
    StyledIconButton,
    StyledLabel,
} from "@aureskonnect/react-ui";
import { ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { showToast } from "@components/Common/ToastShow";
import { useSnapshot } from "valtio";
import { store } from "@pages/store";
import "../Overview/index.css";

type AlerteModaltype = {
    isAlerteModalOpened: boolean;
    setIsAlerteModalOpened: Function;
    setDataIsUpdated: React.Dispatch<React.SetStateAction<number | boolean>>;
    dataIsUpdated: number | boolean;
    selectedRows: any;
    isActiveArchiveMotif: boolean;
    listSelectedMotifs: any;
    setListSelectedMotifs: React.Dispatch<React.SetStateAction<never[]>>;
};
export default function AlerteModalMotif({
    isAlerteModalOpened,
    setIsAlerteModalOpened,
    setDataIsUpdated,
    dataIsUpdated,
    selectedRows,
    isActiveArchiveMotif,
    listSelectedMotifs,
    setListSelectedMotifs,
}: AlerteModaltype) {
    const { t } = useTranslation();
    const uidEntity = localStorage.getItem("Uid_Entitie_User");
    const { selectedMotif } = useSnapshot(store);

    function handleArchiveActiveOneMotif() {
        fetch(
            `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/archiveActiveOneMotif`,
            {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    uidEntitie: uidEntity,
                    nameMotif: selectedMotif.iuud,
                    Action: isActiveArchiveMotif,
                }),
            }
        )
            .then((response) => {
                response.json().then((data) => {
                    if (data.error === false) {
                        if (isActiveArchiveMotif) {
                            showToast(
                                true,
                                `${t("The Motif")} "${selectedMotif.Motif}" ${t(
                                    "is successfully archived."
                                )}`
                            );
                        } else {
                            showToast(
                                true,
                                `${t("The Motif")} "${selectedMotif.Motif}" ${t(
                                    "is successfully activated."
                                )}`
                            );
                        }
                        const oldData = {
                            iuud: selectedMotif.iuud,
                            Motif: selectedMotif.Motif,
                            Description: selectedMotif.Descriptif,
                            archiver: `${!isActiveArchiveMotif}`,
                        };
                        const newData = {
                            iuud: selectedMotif.iuud,
                            Motif: selectedMotif.Motif,
                            Descriptif: selectedMotif.Descriptif,
                            archiver: `${isActiveArchiveMotif}`,
                        };
                        fetch(
                            `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/HistoryChanges`,
                            {
                                method: "POST",
                                headers: { "Content-Type": "application/json" },
                                body: JSON.stringify({
                                    oldData: oldData,
                                    newData: newData,
                                    uidUpdatedData: uidEntity,
                                    typeHistory: "3",
                                }),
                            }
                        )
                            .then((response) => {
                                response.json().then((data) => {
                                    setDataIsUpdated(!dataIsUpdated);
                                    return data.error;
                                });
                            })
                            .catch((e: any) => {
                                return false;
                            });
                    }
                });
            })
            .catch((e: any) => {
                console.log({ error: e.message });
            });
    }
    function saveHistoryChanges(oldData: any, newData: any) {
        fetch(`${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/HistoryChanges`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                oldData: oldData,
                newData: newData,
                uidUpdatedData: uidEntity,
                typeHistory: "3",
            }),
        })
            .then((response) => {
                response.json().then((data) => {
                    setDataIsUpdated(dataIsUpdated);
                    return data.error;
                });
            })
            .catch((e: any) => {
                return false;
            });
    }
    function handleArchiveMotif() {
        fetch(
            `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/setActivesArchivesMotifs`,
            {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    uidEntitie: uidEntity,
                    namesMotifs: listSelectedMotifs,
                    isActiveArchiveMotif: false,
                }),
            }
        )
            .then((response) => {
                response.json().then((data) => {
                    if (data.error === false) {
                        showToast(
                            true,
                            `${t(
                                "The designs have been successfully archived."
                            )}`
                        );
                        setTimeout(() => {
                            setDataIsUpdated(!dataIsUpdated);
                        }, 2000);
                        selectedRows.forEach((element: any) => {
                            const oldData = {
                                iuud: element.iuud,
                                Motif: element.Motif,
                                Description: element.Descriptif,
                                archiver: "false",
                            };
                            const newData = {
                                iuud: element.iuud,
                                Motif: element.Motif,
                                Description: element.Descriptif,
                                archiver: "true",
                            };
                            saveHistoryChanges(oldData, newData);
                        });
                        setListSelectedMotifs([]);
                    }
                });
            })
            .catch((e: any) => {
                console.log({ error: e.message });
            });
    }

    function handleActiveMotifs() {
        fetch(
            `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/setActivesArchivesMotifs`,
            {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    uidEntitie: uidEntity,
                    namesMotifs: listSelectedMotifs,
                    isActiveArchiveMotif: true,
                }),
            }
        )
            .then((response) => {
                response.json().then((data) => {
                    if (data.error === false) {
                        showToast(
                            true,
                            `${t("Motif have been activated successfully.")}`
                        );
                        setTimeout(() => {
                            setDataIsUpdated(!dataIsUpdated);
                        }, 2000);
                        selectedRows.forEach((element: any) => {
                            const oldData = {
                                iuud: element.iuud,
                                Motif: element.Motif,
                                Description: element.Description,
                                archiver: "true",
                            };
                            const newData = {
                                iuud: element.iuud,
                                Motif: element.Motif,
                                Description: element.Description,
                                archiver: "false",
                            };
                            saveHistoryChanges(oldData, newData);
                        });
                        setListSelectedMotifs([]);
                    }
                });
            })
            .catch((e: any) => {
                console.log({ error: e.message });
            });
    }

    return (
        <div>
            <StyledModal className="" centered isOpen={isAlerteModalOpened}>
                <ModalHeader className="d-flex align-items-center">
                    <div
                        className="d-flex align-items-center justify-content-between "
                        style={{
                            height: "20px",
                            width: "200px",
                            display: "grid",
                            gridTemplateColumns: "1.8fr 0.2fr",
                            gridTemplateRows: "1fr",
                            gap: "300px",
                        }}
                    >
                        <p className="title-text-style">CONFIRMATION</p>
                        <StyledIconButton
                            icon="CrossIcon"
                            onClick={() => {
                                setIsAlerteModalOpened(!isAlerteModalOpened);
                                setListSelectedMotifs([]);
                            }}
                        >
                            <CrossIcon
                                cursor="pointer"
                                height={10}
                                width={10}
                            />
                        </StyledIconButton>
                    </div>
                </ModalHeader>

                <ModalBody>
                    <div
                        style={{
                            marginLeft: "10px",
                            marginTop: "20px",
                            marginBottom: "20px",
                        }}
                    >
                        <StyledLabel>
                            {isActiveArchiveMotif
                                ? `${t("Do you want to confirm archiving")} ${
                                      listSelectedMotifs.length > 1
                                          ? `${t("selected patterns")}`
                                          : `${t("of the pattern")} "${
                                                selectedMotif.Motif
                                            }"`
                                  } ?`
                                : `${t(
                                      "Do you want to confirm the activation"
                                  )} ${
                                      listSelectedMotifs.length > 1
                                          ? `${t("selected patterns")}`
                                          : `${t("of the pattern")} "${
                                                selectedMotif.Motif
                                            }"`
                                  } ?`}
                        </StyledLabel>
                    </div>
                </ModalBody>
                <ModalFooter
                    className="card-footer d-flex align-items-center justify-content-end"
                    style={{
                        background: "#FFFFFF",
                        height: "fit-content",
                    }}
                >
                    <StyledButton
                        disabled={false}
                        rounded
                        variant="light"
                        outline={true}
                        size="sm"
                        style={{
                            background: "white",
                            color: "black",
                            padding: "0px",
                            width: "150px",
                            height: "45px",
                        }}
                        onClick={() => {
                            setIsAlerteModalOpened(!isAlerteModalOpened);
                            setListSelectedMotifs([]);
                        }}
                    >
                        {t("Cancel")}
                    </StyledButton>

                    <StyledButton
                        className="ml-2"
                        disabled={false}
                        rounded
                        variant="primary"
                        outline={false}
                        size="sm"
                        style={{
                            color: "white",
                            padding: "0px",
                            width: "150px",
                            height: "45px",
                        }}
                        onClick={() => {
                            if (listSelectedMotifs.length >= 2) {
                                if (isActiveArchiveMotif) {
                                    handleArchiveMotif();
                                } else {
                                    handleActiveMotifs();
                                }
                            } else {
                                handleArchiveActiveOneMotif();
                            }
                            setIsAlerteModalOpened(!isAlerteModalOpened);
                        }}
                    >
                        {t("Validate")}
                    </StyledButton>
                </ModalFooter>
            </StyledModal>
        </div>
    );
}
