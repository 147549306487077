import { proxy } from "valtio";
type StoreType = {
    selectedData: any;
};
export const storeData = proxy<StoreType>({
    selectedData: {},
});
export function setSelectedData(selectedData: any, name: string): any {
    if (storeData.selectedData[name] !== undefined) {
        storeData.selectedData[name].push(selectedData);
    } else {
        storeData.selectedData[name] = [selectedData];
    }
}
export function setRemoveSelectedData(selectedData: any, name: string): any {
    storeData.selectedData[name] = storeData.selectedData[name].filter(
        (el: any) => el !== selectedData
    );
}
export function clearMultiCustomSelectionData(): any {
    storeData.selectedData = {};
}
export function clearData(): any {
    storeData.selectedData = {};
}

export default storeData;