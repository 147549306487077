import React from "react";
import {
    StyledButton,
    StyledModal,
    CrossIcon,
    StyledIconButton,
    StyledLabel,
} from "@aureskonnect/react-ui";
import { ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { showToast } from "@components/Common/ToastShow";
import { useSnapshot } from "valtio";
import { store } from "../store";

import "../Overview/index.css";
// import { replaceQuotedString } from "@components/Common/Replace";

type AlertePackModaltype = {
    isAlertePackModalOpened: boolean;
    setIsAlertePackModalOpened: Function;
    setDataIsUpdated: React.Dispatch<React.SetStateAction<number | boolean>>;
    dataIsUpdated: number | boolean;
};

export default function AlertePackModal({
    isAlertePackModalOpened,
    setIsAlertePackModalOpened,
    setDataIsUpdated,
    dataIsUpdated,
}: AlertePackModaltype) {
    const uidEntity = localStorage.getItem("Uid_Entitie_User");
    const { SelectedPack } = useSnapshot(store);

    function saveHistoryChanges(oldData: any, newData: any, type: any) {
        // ajouter historique de modification
        fetch(`${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/HistoryChanges`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                oldData: oldData,
                newData: newData,
                uidUpdatedData: uidEntity,
                typeHistory: type,
            }),
        })
            .then((response) => {
                response.json().then((data) => {
                    setTimeout(function () {
                        // setIsHiddenCard(!isHiddenCard);
                    }, 2000);
                    return data.error;
                });
            })
            .catch((e: any) => {
                return false;
            });
    }

    function dissociationProductsPack() {
        if (SelectedPack !== "") {
            let type: string = "";
            let produitRef: string = "";
            let ModuleRef: string = "";
            if (SelectedPack.Produit === "") {
                type = "Module";
                produitRef = SelectedPack.parentReference;
                ModuleRef = SelectedPack.Référence;
            } else {
                type = "Produit";
                produitRef = SelectedPack.Référence;
            }
            fetch(
                `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/dissociateFromPack?uidPack=${SelectedPack.uidPack}&referenceProduit=${produitRef}&referenceModule=${ModuleRef}&type=${type}`,
                {
                    method: "Get",
                    headers: { "Content-Type": "application/json" },
                }
            )
                .then((response) => {
                    if (response.status === 200 && response.ok === true) {
                        saveHistoryChanges(
                            {
                                referenceElement:
                                    type === "Produit" ? produitRef : ModuleRef,
                                designation: SelectedPack.designationPack,
                                dissociate: false,
                                designationElement:
                                    type === "Produit"
                                        ? SelectedPack.Produit
                                        : SelectedPack.Module,
                                reference: SelectedPack.referencePack,
                                type: type,
                            },
                            {
                                referenceElement:
                                    type === "Produit" ? produitRef : ModuleRef,
                                designation: SelectedPack.designationPack,

                                dissociate: true,
                                designationElement:
                                    type === "Produit"
                                        ? SelectedPack.Produit
                                        : SelectedPack.Module,
                                reference: SelectedPack.referencePack,
                                type: type,
                            },
                            5
                        );
                        showToast(
                            true,
                            type === "Produit"
                                ? `Produit dissocié  avec succès!`
                                : `Module dissocié  avec succès!`
                        );
                        setIsAlertePackModalOpened(!isAlertePackModalOpened);
                        setTimeout(() => {
                            setDataIsUpdated(!dataIsUpdated);
                        }, 2000);
                    } else if (response.status === 500) {
                        showToast(
                            false,
                            `Ouups! erreur lors de la dissociation, veuillez réessayer SVP`
                        );
                    }
                })
                .catch((e: any) => {
                    console.log({ error: e.message });
                });
        }
    }
    return (
        <div>
            <StyledModal className="" centered isOpen={isAlertePackModalOpened}>
                <ModalHeader className="d-flex align-items-center">
                    <div
                        className="d-flex align-items-center justify-content-between "
                        style={{
                            height: "20px",
                            width: "200px",
                            display: "grid",
                            gridTemplateColumns: "1.8fr 0.2fr",
                            gridTemplateRows: "1fr",
                            gap: "300px",
                        }}
                    >
                        <p className="title-text-style">CONFIRMATION</p>
                        <StyledIconButton icon="CrossIcon">
                            <CrossIcon
                                cursor="pointer"
                                height={10}
                                width={10}
                                onClick={() => {
                                    setIsAlertePackModalOpened(
                                        !isAlertePackModalOpened
                                    );
                                }}
                            />
                        </StyledIconButton>
                    </div>
                </ModalHeader>

                <ModalBody>
                    <div
                        style={{
                            marginLeft: "20px",
                            marginTop: "20px",
                            marginBottom: "20px",
                        }}
                    >
                        <StyledLabel>
                            {SelectedPack !== "" &&
                            SelectedPack.subRows !== undefined &&
                            SelectedPack.subRows.length > 0
                                ? "Attention! la dissociation de ce produit engendre la dissociation de ses modules.voulez vous confirmer la dissociation de ce produit ?"
                                : "Voulez vous confirmer la dissociation ?"}
                        </StyledLabel>
                    </div>
                </ModalBody>
                <ModalFooter
                    className="card-footer d-flex align-items-center justify-content-end"
                    style={{
                        background: "#FFFFFF",
                        height: "fit-content",
                    }}
                >
                    <StyledButton
                        disabled={false}
                        rounded
                        variant="light"
                        outline={true}
                        size="sm"
                        style={{
                            background: "white",
                            color: "black",
                            padding: "0px",
                            width: "150px",
                            height: "45px",
                        }}
                        onClick={() => {
                            setIsAlertePackModalOpened(
                                !isAlertePackModalOpened
                            );
                        }}
                    >
                        Annuler
                    </StyledButton>

                    <StyledButton
                        className="ml-2"
                        disabled={false}
                        rounded
                        variant="primary"
                        outline={false}
                        size="sm"
                        style={{
                            color: "white",
                            padding: "0px",
                            width: "150px",
                            height: "45px",
                        }}
                        onClick={() => {
                            dissociationProductsPack();
                            setIsAlertePackModalOpened(
                                !isAlertePackModalOpened
                            );
                        }}
                    >
                        Valider
                    </StyledButton>
                </ModalFooter>
            </StyledModal>
        </div>
    );
}
