import React from "react";
import {
    ModalBody,
    ModalHeader,
    ModalFooter,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
} from "reactstrap";
import {
    StyledModal,
    StyledButton,
    StyledIconButton,
    CrossIcon,
    StyledLabel,
    StyledTextInput,
    RefreshIcon,
} from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { AvForm } from "availity-reactstrap-validation";
import TableListeMotifs from "@pages/Motifs/TableListeMotifs";
import { showToast } from "@components/Common/ToastShow";
import {
    setSelectedMotif,
    store,
    setEdit,
    setIsAlertupdateModal,
    setModif,
    setSelectedAccount,
} from "@pages/store";
import { ConfirmModal } from "./ConfirmModal";
import { v4 as uuidv4 } from "uuid";

import "../index.css";

type MotifLicensesModalType = {
    setMotif: React.Dispatch<React.SetStateAction<string>>;
    isMotifModalOpened: boolean;
    setIsMotifModalOpened: Function;
    typeMotif: Number;
    setMotifAnnomalie: React.Dispatch<React.SetStateAction<string>>;
    Action?: string;
    setDataIsUpdated: React.Dispatch<React.SetStateAction<number | boolean>>;
    dataIsUpdated: number | boolean;
    selectedRows?: any;
    etatProfil?: string;
    setIsConfigurationBlocageModalOpened: React.Dispatch<
        React.SetStateAction<any>
    >;
    isConfigurationBlocageModalOpened: any;
    setAnomalie: React.Dispatch<React.SetStateAction<boolean>>;
    // setIsEditAction: React.Dispatch<React.SetStateAction<boolean>>;
    // isEditAction: boolean;
};

export function MotifModal({
    setMotif,
    isMotifModalOpened,
    setIsMotifModalOpened,
    typeMotif,
    setMotifAnnomalie,
    Action,
    setDataIsUpdated,
    dataIsUpdated,
    selectedRows,
    setIsConfigurationBlocageModalOpened,
    isConfigurationBlocageModalOpened,
    setAnomalie,
}: MotifLicensesModalType) {
    const { selectedMotif, SelectedMotifBeforeUpdate } = useSnapshot(store);
    const { t } = useTranslation();
    const [isMotifState, setMotifState] = React.useState<boolean>(true);
    const [isHidden, setIsHidden] = React.useState(false);
    // eslint-disable-next-line
    const [optionsMotifsData, setoptionsMotifsData] = React.useState([]);
    const [motifValue, setMotifValue] = React.useState<string>("");
    const [description, setDescription] = React.useState<string>("");
    // eslint-disable-next-line
    const [titleMotif, setTitleMotif] = React.useState<string>("");

    const [isCofirmModalOpened, setIsConfirmModalOpened] = React.useState<
        boolean
    >(false);

    const uidEntity = localStorage.getItem("Uid_Entitie_User");
    const urlListMotifTable = `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/listMotifs?nTypeMotif=${typeMotif}&uidEntitie=${uidEntity}&isArchivedMotifs=true`;
    // const urlListMotifTable = `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/listMotifs?nTypeMotif=${typeMotif}&uidEntitie=${uidEntity}&isArchivedMotifs=true`;

    const [typeConfirmation, setTypeConfirmation] = React.useState<Number>(0);
    const [isEditAction, setIsEditAction] = React.useState<boolean>(false);
    const { SelectedAccount } = useSnapshot(store);
    const { SelectedProfil } = useSnapshot(store);
    const { configurationBloqueAccount } = useSnapshot(store);
    const [selectedRow, setSelectedRow] = React.useState<any>();
    const [
        isHisotyMotifModalOpened,
        setIsHisotyMotifModalOpened,
    ] = React.useState<boolean>(false);
    const [isEmpty, setIsEmpty] = React.useState<boolean>(false);
    const [inputValue, setInputValue] = React.useState<boolean>(false);
    const [counter, setCounter] = React.useState<number>(2000);
    const [isReset, setIsReset] = React.useState<boolean>(false);
    const [isResetDes, setIsResetDes] = React.useState<boolean>(false);

    function handleEditMotifClick() {
        setTypeConfirmation(1);
        setIsConfirmModalOpened(!isCofirmModalOpened);
    }

    function handleAddMotifClick() {
        if (motifValue.trim().length !== 0) {
            const exist = optionsMotifsData.filter(
                (word: any) => word === motifValue
            );
            const Motifuuid = uuidv4();
            if (exist.length < 1) {
                fetch(
                    `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/addMotif`,
                    {
                        method: "PUT",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({
                            uidEntitie: uidEntity,
                            nameMotif: motifValue,
                            typeMotif: typeMotif,
                            archiver: false,
                            description: description,
                            uuidMotif: Motifuuid,
                        }),
                    }
                )
                    .then((response) => {
                        response.json().then((data) => {
                            if (!data.error) {
                                showToast(
                                    true,
                                    `Le motif "${motifValue}" à été ajouté avec succès. `
                                );
                                setMotifValue("");
                                setSelectedMotif("");
                                setDataIsUpdated(!dataIsUpdated);
                                setIsHidden(!isHidden);
                                // setModif(false);
                                setEdit("");
                                setDescription("");
                                setIsEmpty(false);
                                const newData = {
                                    uuidMotif: Motifuuid,
                                };
                                saveHistoryChanges({}, newData);
                            } else {
                                showToast(
                                    false,
                                    "Ouups!, Le motif n'est pas ajouté"
                                );
                            }
                        });
                    })
                    .catch((e: any) => {
                        console.log({ error: e.message });
                    });
            } else {
                showToast(
                    false,
                    "Le motif existe déjà. Veuillez saisir un autre"
                );
            }
        } else {
            showToast(false, `Le champ 'Motif' doit être rempli`);
            setIsEmpty(true);
        }
    }
    // function handleAddMotifClick() {
    //     let newMotif: Record<string, any> = {};
    //     setTitleMotif(Object.values(motifValue)[0]);

    //     newMotif[motifValue] = {
    //         type: typeMotif,
    //         archiver: false,
    //         description: description,
    //     };

    //     fetch(
    //         `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/addMotif?uidEntitie=${uidEntity}&newMotif=${newMotif}`,
    //         {
    //             method: "PUT",
    //             headers: { "Content-Type": "application/json" },
    //             body: JSON.stringify({
    //                 uidEntitie: uidEntity,
    //                 newMotif: JSON.stringify(newMotif),
    //             }),
    //         }
    //     )
    //         .then((response) => {
    //             response.json().then((data) => {
    //                 if (!data.error) {
    //                     showToast(true, "Le motif a été ajouté");
    //                     setDataIsUpdated(!dataIsUpdated);

    //                     setTimeout(function () {
    //                         setIsHidden(!isHidden);
    //                     }, 2000);
    //                 } else {
    //                     showToast(false, "Ouups!, Le motif ne pas ajouté");
    //                 }
    //             });
    //         })
    //         .catch((e: any) => {
    //             console.log({ error: e.message });
    //         });
    //     setMotifValue("");
    //     setDescription("");
    //     setSelectedMotif("");
    // }

    // function handleSavedMotifsSelect(option: any) {
    //     setArraySavedMotifsSelect(option);
    // }

    // const onInputChange = (
    //     inputValue: string,
    //     { action, prevInputValue }: InputActionMeta
    // ) => {
    //     switch (action) {
    //         case "input-change":
    //             setMotifValue(inputValue);
    //             return inputValue;
    //         case "menu-close":
    //             setMotifValue(prevInputValue);
    //             return prevInputValue;
    //         case "set-value":
    //             setMotifValue(inputValue);
    //             return inputValue;
    //         default:
    //             setMotifValue(prevInputValue);
    //             return prevInputValue;
    //     }
    // };

    // const onChange = (option: any, { action }: any) => {
    //     let MotifSelected = "";
    //     if (option !== null) {
    //         MotifSelected = Object.values(option)[0] as string;
    //     }
    //     setMotif(option);
    //     setMotifValue(MotifSelected);
    //     handleSavedMotifsSelect(option);
    //     if (action === "clear") {
    //         setArraySavedMotifsSelect([]);
    //     }
    // };

    function updateEtatCompte(motif: string, etat: string) {
        let oldMotif = SelectedAccount.statusMotif.split("|");
        motif = `${oldMotif[0]}|${oldMotif[1]}|${motif}`;
        fetch(
            `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/setEtatAccount?etatAccount=${etat}&motif=${motif}&uidEntity=${SelectedAccount.Id}`,
            {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
            }
        )
            .then(async (response) => {
                if (response.status === 200) {
                    showToast(true, `Le compte a été ${etat} avec succès"`);
                    saveHistoryChangesNew(
                        {
                            designation: SelectedAccount.commercialname,
                            uid: SelectedAccount.Id,
                            etat:
                                etat === "Archivé"
                                    ? "Activé"
                                    : SelectedAccount.Etats, //"Archivé",
                        },
                        {
                            designation: SelectedAccount.commercialname,
                            uid: SelectedAccount.Id,
                            etat: etat,
                        },
                        1
                    );
                    setTimeout(() => {
                        // window.location.reload();
                        setDataIsUpdated(true);

                        setIsMotifModalOpened(!isMotifModalOpened);
                    }, 2000);
                } else {
                    showToast(false, "Erreur, Veuillez réessayer svp !");
                }
            })
            .catch((error) => {
                console.error("There was an error!", error);
            });
    }
    function ActivateBlockedAccount(motif: string) {
        let oldMotif = SelectedAccount.statusMotif.split("|");
        motif = `${oldMotif[0]}|${oldMotif[1]}|${motif}`;
        console.log(
            "🚀 ~ file: MotifLicensesModal.tsx:302 ~ ActivateBlockedAccount ~ motif",
            motif
        );
        fetch(
            `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/ActivateBlockedAccount?etatAccount=Activé&motif=${motif}&uidEntity=${SelectedAccount.Id}`,
            {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
            }
        )
            .then(async (response) => {
                console.log(
                    "🚀 ~ file: MotifLicensesModal.tsx:313 ~ .then ~ response",
                    response
                );
                if (response.status === 200) {
                    showToast(true, `Le compte a été activé avec succès"`);
                    saveHistoryChangesNew(
                        {
                            designation: SelectedAccount.commercialname,
                            uid: SelectedAccount.Id,
                            etat: "Bloqué",
                        },
                        {
                            designation: SelectedAccount.commercialname,
                            uid: SelectedAccount.Id,
                            etat: "Activé",
                        },
                        1
                    );
                    setTimeout(() => {
                        setDataIsUpdated(true);
                        setIsMotifModalOpened(!isMotifModalOpened);
                    }, 2000);
                } else {
                    showToast(false, "Erreur, Veuillez réessayer svp !");
                }
            })
            .catch((error) => {
                console.error("There was an error!", error);
            });
    }
    function BloquerCompte(motifBlocage: string) {
        let configurationBlocage = JSON.stringify(configurationBloqueAccount);
        let oldMotif = SelectedAccount.statusMotif.split("|");
        motifBlocage = `${oldMotif[0]}|${oldMotif[1]}|${motifBlocage}`;
        fetch(
            `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/bloqueAccount?configurationBlocage=${configurationBlocage}&motifBlocage=${motifBlocage}&uidEntity=${SelectedAccount.Id}`,
            {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
            }
        )
            .then(async (response) => {
                if (response.status === 200) {
                    setIsConfigurationBlocageModalOpened(
                        !isConfigurationBlocageModalOpened
                    );
                    saveHistoryChangesNew(
                        {
                            designation: SelectedAccount.commercialname,
                            uid: SelectedAccount.Id,
                            etat: "Activé",
                        },
                        {
                            designation: SelectedAccount.commercialname,
                            uid: SelectedAccount.Id,
                            etat: "Bloqué",
                        },
                        1
                    );
                    showToast(true, `Le compte a été bloqué avec succès"`);
                    setTimeout(() => {
                        setDataIsUpdated(true);
                        setIsMotifModalOpened(!isMotifModalOpened);
                    }, 2000);
                } else {
                    showToast(
                        false,
                        "Erreur lors du blocage du compte, Veuillez réessayer svp !"
                    );
                }
            })
            .catch((error) => {
                console.error("There was an error!", error);
            });
    }

    let profilUid: any = [];

    function ArchiverActiverProfil(motif: string, etat: string) {
        selectedRows.forEach((element: any) => {
            profilUid.push(element.UidProfil);
        });
        fetch(
            `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/updateEtatProfil?uidProfil=${profilUid}&Action=${etat}&motif=${motif}`,
            {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
            }
        )
            .then(async (response) => {
                if (response.status === 200) {
                    saveHistoryChanges(motif, etat);
                    showToast(true, `Les profil ont été ${etat} avec succès"`);
                    setTimeout(() => {
                        setDataIsUpdated(true);
                        setIsMotifModalOpened(!isMotifModalOpened);
                    }, 2000);
                } else {
                    showToast(false, "Erreur, Veuillez réessayer svp !");
                }
            })
            .catch((error) => {
                console.error("There was an error!", error);
            });
    }

    function ArchiverActiverSelectedProfil(motif: string, etat: string) {
        fetch(
            `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/updateEtatSelectedProfil?uidProfil=${SelectedProfil.UidProfil}&Action=${etat}&motif=${motif}`,
            {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
            }
        )
            .then(async (response) => {
                if (response.status === 200) {
                    saveHistoryChanges(motif, etat);
                    showToast(true, `Le profil a été ${etat} avec succès"`);
                    setTimeout(() => {
                        setDataIsUpdated(true);
                        setIsMotifModalOpened(!isMotifModalOpened);
                    }, 2000);
                } else {
                    showToast(false, "Erreur, Veuillez réessayer svp !");
                }
            })
            .catch((error) => {
                console.error("There was an error!", error);
            });
    }

    function saveHistoryChangesNew(oldData: any, newData: any, type: any) {
        fetch(`${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/HistoryChanges`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                oldData: oldData,
                newData: newData,
                uidUpdatedData: uidEntity,
                typeHistory: type,
            }),
        })
            .then((response) => {
                response.json().then((data) => {
                    return data.error;
                });
            })
            .catch((e: any) => {
                return false;
            });
    }

    function saveHistoryChanges(motif: any, etat: any) {
        const oldData = {
            profil: SelectedProfil.Designation,
            etat: SelectedProfil.EtatProfil,
            type: SelectedProfil.Type,
            motif: SelectedProfil.MotifProfil,
            description: SelectedProfil.Description,
            designation: SelectedProfil.Designation,
            uidProfil: SelectedProfil.UidProfil,
        };
        const newData = {
            profil: SelectedProfil.Designation,
            etat: etat,
            type: SelectedProfil.Type,
            motif: motif,
            description: SelectedProfil.Description,
            designation: SelectedProfil.Designation,
            uidProfil: SelectedProfil.UidProfil,
        };

        fetch(`${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/HistoryChanges`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                oldData: oldData,
                newData: newData,
                uidUpdatedData: uidEntity,
                typeHistory: "4",
            }),
        })
            .then((response) => {
                response.json().then((data) => {
                    return data.error;
                });
            })
            .catch((e: any) => {
                return false;
            });
    }
    function handleCancelCloseMotifModal() {
        if (Action === "CompteAnomalie") {
            setAnomalie(false);
            if (SelectedAccount !== "") {
                setSelectedAccount({
                    ...SelectedAccount,
                    NoteAnomalieEtat: false,
                });
            }
            setIsMotifModalOpened(!isMotifModalOpened);
        } else {
            setIsMotifModalOpened(!isMotifModalOpened);
        }
    }
    // function listTitleMotif(){
    //     fetch(
    //         `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/listTitleMotifs?uidEntitie=${uidEntity}&nTypeMotif=${typeMotif}`,
    //         {
    //             method: "POST",
    //             headers: { "Content-Type": "application/json" },
    //         }
    //     )
    //         .then((response) => {
    //             response.json().then((data) => {
    //                 setoptionsMotifsData(data);
    //             });
    //         })
    //         .catch((e: any) => {
    //             console.log({ error: e.message });
    //         });
    // }
    // React.useEffect(() => {
    //     listTitleMotif()
    // }, []);
    // React.useEffect(() => {
    //     if (storeShot.selectedMotif === "") {
    //         setTitleMotif("");
    //         setMotifValue("");
    //         setDescription("");
    //     } else {
    //         setTitleMotif(storeShot.selectedMotif.values.Motif);
    //         setMotifValue(storeShot.selectedMotif.values.Motif);
    //         setDescription(storeShot.selectedMotif.values.Description);
    //     }
    // }, [storeShot]);

    return (
        <>
            <StyledModal
                className="custom-modal-style"
                isOpen={isMotifModalOpened}
                style={{
                    marginTop: "100px",
                }}
            >
                <ModalHeader className="d-flex align-items-center">
                    <div
                        className="d-flex align-items-center justify-content-between"
                        style={{
                            height: "35px",
                            display: "grid",
                            gridTemplateColumns: "1.8fr 0.2fr",
                            gridTemplateRows: "1fr",
                            gap: "1460px",
                        }}
                    >
                        <p
                            className="title-modal-style"
                            style={{ width: "max-content" }}
                        >
                            SÉLECTION DU MOTIF
                        </p>
                        <StyledIconButton
                            icon="CrossIcon"
                            onClick={() => {
                                handleCancelCloseMotifModal();
                            }}
                        >
                            <CrossIcon
                                cursor="pointer"
                                height={10}
                                width={10}
                            />
                        </StyledIconButton>
                    </div>
                </ModalHeader>

                <ModalBody>
                    <div
                        className="row "
                        style={{
                            display: "grid",
                            gridTemplateColumns: isHidden ? "2fr 1fr" : "auto",
                            gap: "4px",
                        }}
                    >
                        <TableListeMotifs
                            setIsHidden={setIsHidden}
                            isHidden={isHidden}
                            setModif={setModif}
                            urlTable={urlListMotifTable}
                            isCofirmModalOpened={isCofirmModalOpened}
                            setIsConfirmModalOpened={setIsConfirmModalOpened}
                            setTypeConfirmation={setTypeConfirmation}
                            setIsEditAction={setIsEditAction}
                            setDataIsUpdated={setDataIsUpdated}
                            dataIsUpdated={dataIsUpdated}
                            setSelectedRows={setSelectedRow}
                            selectedRows={selectedRow}
                            isMotifConfiguration={false}
                            isHisotyMotifModalOpened={isHisotyMotifModalOpened}
                            setIsHisotyMotifModalOpened={
                                setIsHisotyMotifModalOpened
                            }
                            setMotifState={setMotifState}
                            isMotifState={isMotifState}
                            isEditAction={isEditAction}
                            setEdit={setEdit}
                            setIsAlertupdateModal={setIsAlertupdateModal}
                        />
                        {!isHidden ? null : (
                            <Card className="border ml-2">
                                <CardHeader
                                    className="Headercard  d-flex justify-content-between pb-2"
                                    style={{
                                        height: "11.7%",
                                        backgroundColor: "white",
                                        border: "1px solid rgba(0,0,0,.125)",
                                    }}
                                >
                                    <div className=" d-flex align-items-center justify-content-center">
                                        <h4
                                            className="m-0 pl-2"
                                            style={{
                                                font:
                                                    "normal normal 600 20px/27px Segoe UI",
                                            }}
                                        >
                                            {isEditAction
                                                ? "Modifier le motif"
                                                : "Ajouter un motif"}
                                        </h4>
                                    </div>

                                    <div className="d-flex align-items-center ">
                                        <RefreshIcon
                                            cursor="pointer"
                                            height={20}
                                            width={20}
                                            onClick={() => {
                                                if (isEditAction) {
                                                    setIsReset(true);
                                                    setIsResetDes(true);
                                                } else {
                                                    setDescription("");
                                                    setMotifValue("");
                                                }
                                                // setModif(false);
                                            }}
                                        />
                                        <div className=" bd-highlight align-items-center ml-4">
                                            <CrossIcon
                                                cursor="pointer"
                                                height={17}
                                                width={17}
                                                onClick={() => {
                                                    if (isEditAction === true) {
                                                        setIsAlertupdateModal(
                                                            true
                                                        );
                                                        setIsHidden(!isHidden);
                                                    } else {
                                                        setIsHidden(!isHidden);
                                                        setDescription("");
                                                        setMotifValue("");
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardBody
                                    style={{
                                        background: "#FFFFFF",
                                        border: "1px solid rgba(0,0,0,.125)",
                                        marginTop: "4px",
                                        overflow: "auto",
                                        maxHeight: `calc(100vh - (500px + ${
                                            isHidden ? "-71px" : "0px"
                                        }))`,
                                    }}
                                >
                                    <AvForm>
                                        <StyledLabel>Motif*</StyledLabel>
                                        <StyledTextInput
                                            style={{
                                                pointerEvents: !isMotifState
                                                    ? "none"
                                                    : "auto",
                                                opacity: !isMotifState
                                                    ? "0.9"
                                                    : "",
                                                border: isEmpty
                                                    ? "1px solid red"
                                                    : "1px solid #ced4da",
                                            }}
                                            id="savemotifinput"
                                            name="savemotifinput"
                                            placeholder="Saisir"
                                            type="text"
                                            autoFocus={
                                                !isMotifState ? false : true
                                            }
                                            onChange={(e: any) => {
                                                setMotifValue(
                                                    e.target.value.trim()
                                                );
                                                setIsResetDes(false);
                                                setIsEmpty(false);
                                                if (selectedMotif !== "") {
                                                    setSelectedMotif({
                                                        ...selectedMotif,
                                                        Motif: e?.target.value,
                                                    });
                                                    // setModif(true);
                                                }
                                            }}
                                            value={
                                                selectedMotif === ""
                                                    ? motifValue
                                                    : isResetDes
                                                    ? SelectedMotifBeforeUpdate?.Motif
                                                    : selectedMotif?.Motif
                                            }
                                        />
                                        {isEmpty ? (
                                            <div
                                                style={{
                                                    color: "red",
                                                }}
                                            >
                                                Ce champ est obligatoire
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </AvForm>

                                    <AvForm>
                                        <StyledLabel htmlFor="example-input">
                                            Descriptif
                                        </StyledLabel>
                                        <StyledTextInput
                                            style={{
                                                pointerEvents: !isMotifState
                                                    ? "none"
                                                    : "auto",
                                                opacity: !isMotifState
                                                    ? "0.8"
                                                    : "",
                                            }}
                                            id="example-input"
                                            name="example-input"
                                            placeholder="Saisir"
                                            value={
                                                selectedMotif === ""
                                                    ? description
                                                    : isReset
                                                    ? SelectedMotifBeforeUpdate?.Descriptif
                                                    : selectedMotif?.Descriptif
                                            }
                                            onChange={(e: any) => {
                                                setDescription(e.target.value);
                                                setInputValue(true);
                                                setIsReset(false);
                                                setCounter(
                                                    2000 - e.target.value.length
                                                );
                                                if (selectedMotif !== "") {
                                                    setSelectedMotif({
                                                        ...selectedMotif,
                                                        Descriptif:
                                                            e?.target.value,
                                                    });
                                                    // setModif(true);
                                                }
                                            }}
                                            type="textarea"
                                        />
                                        <div className="d-flex justify-content-end ">
                                            {isEditAction ? (
                                                <small>
                                                    Nombre de caractère maximal
                                                    :
                                                    {2000 -
                                                        selectedMotif.Descriptif
                                                            .length}
                                                    /2000
                                                </small>
                                            ) : inputValue ? (
                                                <small>
                                                    Nombre de caractère maximal
                                                    :{counter}
                                                    /2000
                                                </small>
                                            ) : (
                                                <small>
                                                    Nombre de caractère maximal
                                                    : 2000
                                                </small>
                                            )}
                                        </div>
                                    </AvForm>
                                </CardBody>
                                <CardFooter
                                    className="Footercard p-0"
                                    style={{
                                        background: "#FFFFFF",
                                    }}
                                >
                                    <div className=" d-flex flex-wrap d-flex flex-row-reverse bd-highlight ">
                                        <div className="p-2 bd-highlight">
                                            <StyledButton
                                                disabled={
                                                    !isMotifState ? true : false
                                                }
                                                rounded
                                                variant="primary"
                                                outline={false}
                                                size="sm"
                                                style={{
                                                    color: "white",
                                                    padding: "0px",
                                                    width: "150px",
                                                    height: "45px",
                                                }}
                                                onClick={() => {
                                                    if (isEditAction) {
                                                        handleEditMotifClick();
                                                    } else {
                                                        handleAddMotifClick();
                                                    }
                                                }}
                                            >
                                                Valider
                                            </StyledButton>
                                        </div>
                                        <div className="p-2 bd-highlight">
                                            <StyledButton
                                                disabled={false}
                                                rounded
                                                variant="light"
                                                outline={true}
                                                size="sm"
                                                style={{
                                                    background: "white",
                                                    color: "black",
                                                    padding: "0px",
                                                    width: "150px",
                                                    height: "45px",
                                                }}
                                                onClick={() => {
                                                    // if (isEditAction === true) {
                                                    //     setIsAlertupdateModal(
                                                    //         true
                                                    //     );
                                                    //     setIsHidden(!isHidden);
                                                    // } else {
                                                    setIsHidden(!isHidden);
                                                    setDescription("");
                                                    setMotifValue("");
                                                    setIsEmpty(false);
                                                    // }
                                                }}
                                            >
                                                Annuler
                                            </StyledButton>
                                        </div>
                                    </div>
                                </CardFooter>
                            </Card>
                        )}

                        <ConfirmModal
                            isCofirmModalOpened={isCofirmModalOpened}
                            setIsConfirmModalOpened={setIsConfirmModalOpened}
                            // setIsConfirmed={setIsConfirmed}
                            typeConfirmation={typeConfirmation}
                            titleMotif={titleMotif}
                            descriptionMotif={description}
                            setDataIsUpdated={setDataIsUpdated}
                            dataIsUpdated={dataIsUpdated}
                            setIsHidden={setIsHidden}
                            isHidden={isHidden}
                        />
                    </div>
                </ModalBody>

                {isHidden ? null : (
                    <ModalFooter className="d-flex justify-content-end">
                        <div className="d-flex align-items-center">
                            <div>
                                <StyledButton
                                    rounded
                                    outline
                                    variant="light"
                                    onClick={() => {
                                        handleCancelCloseMotifModal();
                                    }}
                                >
                                    {t("Cancel")}
                                </StyledButton>
                            </div>
                            <div className="ml-3">
                                <StyledButton
                                    rounded
                                    variant="primary"
                                    onClick={() => {
                                        if (selectedRow.length <= 0) {
                                            showToast(
                                                false,
                                                "Veuillez choisir un motif SVP !"
                                            );
                                        } else if (selectedRow.length > 1) {
                                            showToast(
                                                false,
                                                "Veuillez choisir un seul motif SVP !"
                                            );
                                        } else {
                                            setIsMotifModalOpened(
                                                !isMotifModalOpened
                                            );
                                            setMotif(selectedMotif);
                                            setMotifAnnomalie(
                                                selectedRow.Description
                                            );
                                            if (Action === "Archivage") {
                                                updateEtatCompte(
                                                    selectedRow[0].Motif,
                                                    "Archivé"
                                                );
                                            } else if (
                                                Action === "CompteAnomalie"
                                            ) {
                                                setMotifAnnomalie(
                                                    selectedRow[0].Motif
                                                );
                                                if (SelectedAccount !== "") {
                                                    setSelectedAccount({
                                                        ...SelectedAccount,
                                                        motifAnomalie:
                                                            selectedRow[0]
                                                                .Motif,
                                                    });
                                                }
                                            } else if (
                                                Action === "Activation"
                                            ) {
                                                updateEtatCompte(
                                                    selectedRow[0].Motif,
                                                    "Activé"
                                                );
                                            } else if (
                                                Action ===
                                                "ActivateBlockedAccount"
                                            ) {
                                                ActivateBlockedAccount(
                                                    selectedRow[0].Motif
                                                );
                                            } else if (Action === "Blocage") {
                                                BloquerCompte(
                                                    selectedRow[0].Motif
                                                );
                                            } else if (
                                                Action === "Archivage Profil"
                                            ) {
                                                if (selectedRows.length < 1) {
                                                    ArchiverActiverSelectedProfil(
                                                        selectedRow.Motif,
                                                        "Archivé"
                                                    );
                                                } else {
                                                    ArchiverActiverProfil(
                                                        selectedRow.Motif,
                                                        "Archivé"
                                                    );
                                                }
                                            } else if (
                                                Action === "Activation Profil"
                                            ) {
                                                if (selectedRows.length < 1) {
                                                    ArchiverActiverSelectedProfil(
                                                        selectedRow.Motif,
                                                        "Activé"
                                                    );
                                                } else {
                                                    ArchiverActiverProfil(
                                                        selectedRow.Motif,
                                                        "Activé"
                                                    );
                                                }
                                            }
                                        }
                                    }}
                                >
                                    {t("Validate")}
                                </StyledButton>
                            </div>
                        </div>
                    </ModalFooter>
                )}
            </StyledModal>
        </>
    );
}
