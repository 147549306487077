import React, { useState } from "react";

import {
    InboxIcon,
    TrashIcon,
    VerticalDotsIcon,
    EyeIcon,
    DocumentIcon,
} from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import {
    setIsHitoryAllProducts,
    store,
    setIsAlerteModuleModalOpened,
    setIsAlerteDissociateModalOpened,
    setRefHistory,
    setIsAssociateProductModalOpen,
} from "@pages/store";
import { showToast } from "@components/Common/ToastShow";
import AlerteModalModule from "./AlerteModalModule";
import AlerteDissociateModal from "./AlerteDissociateModal";
import AssociateProductsToModuleModal from "./AssociateProductsToModuleModal";

type ProductsActionMenuType = {
    isHisotyModuleModalOpened: boolean | undefined;
    setIsHisotyModuleModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
    setDataIsUpdated: React.Dispatch<React.SetStateAction<number | boolean>>;
    dataIsUpdated: number | boolean;
    selectedRows?: any;
    setModuleState: React.Dispatch<React.SetStateAction<boolean>>;
    isModuleState: boolean;
};

export default function ModulesActionsMenu(props: ProductsActionMenuType) {
    const { t } = useTranslation();
    const uidEntity = localStorage.getItem("Uid_Entitie_User");
    const [nombreModuleArchive, setNombreModuleArchive] = useState(0);
    const [nombreModuleActive, setNombreModuleActive] = useState(0);
    const [listProduits, setListProduits] = useState([]);
    const { isAlerteModuleModalOpened } = useSnapshot(store);
    const { selectedModule } = useSnapshot(store);
    const { isAssociateProductModalOpen } = useSnapshot(store);
    const numberArchivesProducts = () => {
        fetch(
            `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/numberActiveArchiveProducts?uidEntitie=${uidEntity}&isActivesProducts=true&isPrincipalProduct=false`
        )
            .then((response) => {
                response.json().then((data) => {
                    setNombreModuleArchive(Number(data.count));
                });
            })
            .catch((e: any) => {
                console.log({ error: e.message });
            });
    };

    const numberActivesProducts = () => {
        fetch(
            `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/numberActiveArchiveProducts?uidEntitie=${uidEntity}&isActivesProducts=false&isPrincipalProduct=false`
        )
            .then((response) => {
                response.json().then((data) => {
                    setNombreModuleActive(Number(data.count));
                });
            })
            .catch((e: any) => {
                console.log({ error: e.message });
            });
    };
    let listProducts: any = [];
    let listSubRowsLength: any = [];
    function handleArchiveActiveProducts() {
        for (let i = 0; i < props.selectedRows.length; i++) {
            if (!props.selectedRows[i].isPrincipalProduct) {
                listSubRowsLength.push(props.selectedRows[i].subRows.length);
                listProducts.push(props.selectedRows[i].Référence);
            }
        }
        setListProduits(listProducts);
        if (listProducts.length < 2) {
            showToast(
                false,
                "Attention, c'est une action multiple, vous devez sélectionner au moins deux éléments."
            );
        } else {
            const result = listSubRowsLength.filter(
                (length: any) => length !== 0
            );
            if (result.length > 0) {
                showToast(
                    false,
                    "Vous ne pouvez pas archiver un module associé à un ou plusieurs produits / packs"
                );
            } else {
                setIsAlerteModuleModalOpened(!isAlerteModuleModalOpened);
            }
        }
    }
    function showHistory() {
        let ReferencesProducts: any = [];
        props.selectedRows.forEach((element: any) => {
            if (!element.isPrincipalProduct) {
                ReferencesProducts.push(element.Référence);
            }
        });
        setRefHistory(ReferencesProducts);
        if (ReferencesProducts.length < 2) {
            showToast(
                false,
                "Attention, c'est une action multiple, vous devez sélectionner au moins deux éléments."
            );
            setTimeout(() => {}, 2000);
        } else {
            setIsHitoryAllProducts(true);
            props.setIsHisotyModuleModalOpened(
                !props.isHisotyModuleModalOpened
            );
        }
    }
    React.useEffect(() => {
        numberActivesProducts();
        numberArchivesProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uidEntity, props.selectedRows]);

    return (
        <>
            <div className="dropdown ml-2  d-none d-sm-block">
                <VerticalDotsIcon
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    cursor="pointer"
                    height={25}
                    width={25}
                />

                <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                >
                    {props.isModuleState ? (
                        <div
                            className="dropdown-item"
                            role="button"
                            tabIndex={0}
                            onClick={() => {
                                props.setModuleState(false);
                            }}
                            style={{
                                cursor: "pointer",
                                pointerEvents:
                                    nombreModuleArchive === 0 ? "none" : "auto",
                                opacity: nombreModuleArchive === 0 ? "0.4" : "",
                            }}
                        >
                            <InboxIcon
                                className="mr-2"
                                height={25}
                                width={25}
                            />

                            {t(
                                `Modules(s) archivé(s) (${nombreModuleArchive})`
                            )}
                        </div>
                    ) : (
                        <div
                            className="dropdown-item"
                            role="button"
                            tabIndex={0}
                            onClick={() => {
                                props.setModuleState(true);
                            }}
                            style={{
                                // pointerEvents:
                                //     nombreModuleActive === 0 ? "none" : "auto",
                                // opacity: nombreModuleActive === 0 ? "0.4" : "",
                                cursor: "pointer",
                            }}
                        >
                            <InboxIcon
                                className="mr-2"
                                height={25}
                                width={25}
                            />
                            {t(`Module(s) activé(s) (${nombreModuleActive})`)}
                        </div>
                    )}

                    {props.isModuleState ? (
                        <div
                            className="dropdown-item"
                            role="button"
                            tabIndex={0}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                props.setModuleState(true);
                                handleArchiveActiveProducts();
                            }}
                        >
                            <TrashIcon
                                className="mr-2"
                                height={25}
                                width={25}
                                style={{ fill: "red" }}
                            />
                            {t("Archiver module(s)")}
                        </div>
                    ) : (
                        <div
                            className="dropdown-item"
                            role="button"
                            tabIndex={0}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                props.setModuleState(false);
                                handleArchiveActiveProducts();
                            }}
                        >
                            <EyeIcon height={25} width={25} className="mr-2" />
                            {t("Activer module(s)")}
                        </div>
                    )}

                    <div
                        className="dropdown-item"
                        role="button"
                        tabIndex={0}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            showHistory();
                        }}
                    >
                        <DocumentIcon height={25} width={25} className="mr-2" />
                        {t("Historiques")}
                    </div>
                </div>
            </div>
            <AlerteModalModule
                setIsAlerteModuleModalOpened={setIsAlerteModuleModalOpened}
                setDataIsUpdated={props.setDataIsUpdated}
                dataIsUpdated={props.dataIsUpdated}
                ProductsNames={listProduits}
                archiveActiveModule={props.isModuleState}
                selectedRows={props.selectedRows}
                setListProduit={setListProduits}
            />
            <AlerteDissociateModal
                setIsAlerteDissociateModalOpened={
                    setIsAlerteDissociateModalOpened
                }
                setDataIsUpdated={props.setDataIsUpdated}
                dataIsUpdated={props.dataIsUpdated}
            />
            <AssociateProductsToModuleModal
                isModalOpened={isAssociateProductModalOpen}
                setIsModalOpened={setIsAssociateProductModalOpen}
                setDataIsUpdated={props.setDataIsUpdated}
                dataIsUpdated={props.dataIsUpdated}
                selectedModule={selectedModule}
            />
        </>
    );
}
