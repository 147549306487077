import React from "react";
import { ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { StyledButton, StyledLabel, StyledModal } from "@aureskonnect/react-ui";
import { v4 as uuidv4 } from "uuid";

import "./index.css";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { storeAuthorisation } from "../store";
import {
    clearData,
    clearMultiCustomSelectionData,
} from "@components/Common/StyledComponent/store";
import {
    setEditActionProfile,
    setIsAlertProfilUsed,
    setSelectedProfil,
    setUpdatedAutorisation,
    store,
} from "@pages/store";
import { showToast } from "@components/Common/ToastShow";
import { ModalContentWrapper } from "../ModalContentWrapper";

type PropsType = {
    modal: boolean;
    toggle: () => void;
    setModal: any;
    designation: string;
    typeProfil: string;
    description: string;
    setDataProfilIsUpdated: React.Dispatch<React.SetStateAction<any>>;
    setIsHidden: React.Dispatch<React.SetStateAction<any>>;
    isHidden: boolean;
    setDesignation: React.Dispatch<React.SetStateAction<string>>;
    setDescription: React.Dispatch<React.SetStateAction<string>>;
    balise: any[];
    setBalise: React.Dispatch<React.SetStateAction<any[]>>;
    setTypeProfil: React.Dispatch<React.SetStateAction<string>>;
    dataIsUpdated: number | boolean;
};

export function ModalWrapper({
    modal,
    toggle,
    designation,
    typeProfil,
    description,
    setDataProfilIsUpdated,
    setIsHidden,
    isHidden,
    setDesignation,
    setDescription,
    balise,
    setBalise,
    dataIsUpdated,
}: PropsType): JSX.Element {
    const { t } = useTranslation();
    const { dataProject } = useSnapshot(storeAuthorisation);
    const {
        SelectedProfil,
        editActionProfile,
        SelectedProfilBeforeUpdate,
        usedProfileWidhoutEntity,
        isAlertProfilUsed,
    } = useSnapshot(store);
    const [listSelectedData, setlistSelectedData] = React.useState<any>({});
    const [finallyResult, setFinallyResult] = React.useState<any>(true);

    let Data: any = {};
    let DataModifie: any = {};
    let arrayUuidEntity: any = [];
    const uidEntity = localStorage.getItem("Uid_Entitie_User");
    async function handleUpdateProfil() {
        const keys = Object.keys(listSelectedData);
        const copyData = JSON.parse(
            JSON.stringify(SelectedProfil.Autorisations)
        );
        Object.values(listSelectedData).forEach((Element: any) => {
            if (keys.includes(Element.IdParent)) {
                if (Element.IdParent === Element.Id) {
                    if (
                        listSelectedData[Element.IdParent].Visible === true &&
                        listSelectedData[Element.Id].Visible === true
                    ) {
                        if (
                            listSelectedData[Element.Id].Consult === false ||
                            listSelectedData[Element.IdParent].Modifie === false
                        ) {
                            listSelectedData[Element.Id].Modifie = false;
                        }
                        DataModifie[Element.Id] = listSelectedData[Element.Id];
                    } else if (listSelectedData[Element.Id].Visible === false) {
                        listSelectedData[Element.Id].Consult = false;
                        listSelectedData[Element.Id].Modifie = false;
                        DataModifie[Element.Id] = listSelectedData[Element.Id];
                    } else if (
                        listSelectedData[Element.IdParent].Modifie === false
                    ) {
                        listSelectedData[Element.Id].Modifie = false;
                        DataModifie[Element.Id] = listSelectedData[Element.Id];
                    }
                } else {
                    if (
                        listSelectedData[Element.IdParent].Visible === true &&
                        listSelectedData[Element.IdParent].Consult === true &&
                        listSelectedData[Element.Id].Visible === true
                    ) {
                        if (
                            listSelectedData[Element.Id].Consult === false ||
                            listSelectedData[Element.IdParent].Modifie === false
                        ) {
                            listSelectedData[Element.Id].Modifie = false;
                        }
                        DataModifie[Element.Id] = listSelectedData[Element.Id];
                    }
                }
            } else {
                if (listSelectedData[Element.Id].Visible === true) {
                    if (listSelectedData[Element.Id].Consult === false) {
                        listSelectedData[Element.Id].Modifie = false;
                    }
                } else {
                    listSelectedData[Element.Id].Consult = false;
                    listSelectedData[Element.Id].Modifie = false;
                }
                DataModifie[Element.Id] = listSelectedData[Element.Id];
            }
        });
        const keys1 = Object.keys(DataModifie);
        Object.values(copyData).forEach((Element1: any) => {
            if (keys1.includes(Element1.Id)) {
                copyData[Element1.Id] = DataModifie[Element1.Id];
            }
            if (Element1.IdParent === Element1.Id) {
            } else {
                if (
                    copyData[Element1.IdParent].Visible === true &&
                    copyData[Element1.IdParent].Consult === false
                ) {
                    copyData[Element1.Id].Visible = false;
                    copyData[Element1.Id].Consult = false;
                    copyData[Element1.Id].Modifie = false;
                } else if (copyData[Element1.IdParent].Visible === false) {
                    copyData[Element1.Id].Visible = false;
                    copyData[Element1.Id].Consult = false;
                    copyData[Element1.Id].Modifie = false;
                } else if (copyData[Element1.IdParent].Modifie === false) {
                    copyData[Element1.Id].Modifie = false;
                }
            }
        });
        setUpdatedAutorisation(copyData);

        if (
            JSON.stringify(copyData) ===
            JSON.stringify(SelectedProfilBeforeUpdate.Autorisations)
        ) {
            toggle();
        } else {
            if (usedProfileWidhoutEntity.includes(SelectedProfil.UidProfil)) {
                setIsAlertProfilUsed(!isAlertProfilUsed);
            } else {
                arrayUuidEntity.push(uidEntity);
                let profilUpdated = {
                    type: SelectedProfil.TypeProfil,
                    description: SelectedProfil.Descriptif,
                    designation: SelectedProfil.Designation,
                    autorisation: copyData,
                    uidEntity: arrayUuidEntity,
                    uidProfil: SelectedProfil.UidProfil,
                    balise: SelectedProfil.Balise,
                };
                setFinallyResult(false);

                fetch(
                    `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/updateProfile`,
                    {
                        method: "PUT",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({
                            dataProfil: profilUpdated,
                        }),
                    }
                )
                    .then((response) => {
                        response.json().then((data) => {
                            if (data.error === false) {
                                setEditActionProfile(false);
                                setDataProfilIsUpdated(!dataIsUpdated);
                                setSelectedProfil("");
                                setDesignation("");
                                setDescription("");
                                toggle();
                                showToast(
                                    true,
                                    `${t(
                                        "Profile permissions changed successfully."
                                    )}`
                                );
                                const oldData = {
                                    type: SelectedProfil.TypeProfil,
                                    profil: SelectedProfil.Designation,
                                    uidProfil: SelectedProfil.UidProfil,
                                    autorisation: false,
                                };
                                const newData = {
                                    type: SelectedProfil.TypeProfil,
                                    profil: SelectedProfil.Designation,
                                    uidProfil: SelectedProfil.UidProfil,
                                    autorisation: true,
                                };
                                fetch(
                                    `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/HistoryChanges`,
                                    {
                                        method: "POST",
                                        headers: {
                                            "Content-Type": "application/json",
                                        },
                                        body: JSON.stringify({
                                            oldData: oldData,
                                            newData: newData,
                                            uidUpdatedData: uidEntity,
                                            typeHistory: "4",
                                        }),
                                    }
                                )
                                    .then((response) => {
                                        response.json().then((data) => {
                                            return data.error;
                                        });
                                    })
                                    .catch((e: any) => {
                                        return false;
                                    });
                            }
                        });
                    })
                    .catch((e: any) => {
                        return false;
                    })
                    .finally(() => {
                        setFinallyResult(true);
                    });
            }
        }
    }
    function saveHistoryChanges(newProfil: any, uidProfil: string) {
        const oldData = {};
        const newData = {
            profil: newProfil.designation,
            etat: newProfil.etat,
            type: newProfil.Type,
            description: newProfil.description,
            designation: newProfil.designation,
            uidProfil: uidProfil,
        };

        fetch(`${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/HistoryChanges`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                oldData: oldData,
                newData: newData,
                uidUpdatedData: uidEntity,
                typeHistory: "4",
            }),
        })
            .then((response) => {
                response.json().then((data) => {
                    return data.error;
                });
            })
            .catch((e: any) => {
                return false;
            });
    }
    function handleValidateButtonClickEvent(
        designation: string,
        typeProfil: string,
        description: string,
        balise: any
    ) {
        const keys = Object.keys(listSelectedData);
        Object.values(listSelectedData).forEach((Element: any) => {
            if (keys.includes(Element.IdParent)) {
                if (Element.IdParent === Element.Id) {
                    if (
                        listSelectedData[Element.IdParent].Visible === true &&
                        listSelectedData[Element.Id].Visible === true
                    ) {
                        if (
                            listSelectedData[Element.Id].Consult === false ||
                            listSelectedData[Element.IdParent].Modifie === false
                        ) {
                            listSelectedData[Element.Id].Modifie = false;
                        }
                        Data[Element.Id] = listSelectedData[Element.Id];
                    }
                } else {
                    if (
                        listSelectedData[Element.IdParent].Visible === true &&
                        listSelectedData[Element.IdParent].Consult === true &&
                        listSelectedData[Element.Id].Visible === true
                    ) {
                        if (
                            listSelectedData[Element.Id].Consult === false ||
                            listSelectedData[Element.IdParent].Modifie === false
                        ) {
                            listSelectedData[Element.Id].Modifie = false;
                        }
                        Data[Element.Id] = listSelectedData[Element.Id];
                    }
                }
            }
        });
        const copyData = JSON.parse(JSON.stringify(dataProject));
        Object.keys(copyData).forEach((el: any) => {
            Object.keys(Data).forEach((elData: any) => {
                if (el === elData) {
                    copyData[el] = Data[elData];
                }
            });
        });
        const uidEntity = localStorage.getItem("Uid_Entitie_User");
        arrayUuidEntity.push(uidEntity);
        const Profiluuid = uuidv4();
        const newProfil = {
            etat: "Activé",
            motif: "",
            type: typeProfil,
            description: description,
            designation: designation,
            autorisation: copyData,
            uidEntity: arrayUuidEntity, //uidEntity,
            balise: balise.join(","),
        };
        setFinallyResult(false);
        fetch(`${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/addNewProfil`, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                dataProfil: newProfil,
                uidProfil: Profiluuid,
            }),
        })
            .then((response) => {
                response.json().then((data) => {
                    if (!data.error) {
                        clearData();
                        clearMultiCustomSelectionData();
                        setBalise([]);
                        setDataProfilIsUpdated(!dataIsUpdated);
                        setIsHidden(!isHidden);
                        setDesignation("");
                        setDescription("");
                        toggle();
                        saveHistoryChanges(newProfil, Profiluuid);
                        showToast(
                            true,
                            `${t("The profil is successfully added.")!}`
                        );
                    } else {
                        showToast(true, `${t("There's an error.")}!`);
                    }
                });
            })
            .catch((e: any) => {
                console.log({ error: e.message });
            })
            .finally(() => {
                setFinallyResult(true);
            });
    }

    return (
        <StyledModal
            className="modal-style"
            isOpen={modal}
            // centered
            // backdrop="static"
            // fade={false}
            // scrollable
            // style={{
            //     marginTop: "12px",
            //     maxWidth: "1350px",
            //     maxHeight: "100px",
            //     borderRadius: "10px",
            // }}
        >
            <ModalHeader className="text-uppercase cursor__clz" toggle={toggle}>
                <div className="d-flex flex-row bd-highlight">
                    <div
                        className="bd-highlight"
                        style={{ width: "max-content" }}
                    >
                        <span
                            style={{
                                textAlign: "left",
                                font: " normal normal bold 20px/14px Segoe UI",
                                color: "#2B2828",
                                fontWeight: 600,
                            }}
                        >
                            {t("PROFILE PERMISSIONS")} :
                        </span>
                    </div>
                    <div className=" ml-2 bd-highlight">
                        <StyledLabel
                            style={{
                                fontWeight: 600,
                                width: "max-content",
                            }}
                        >
                            {editActionProfile
                                ? SelectedProfil.Designation
                                : designation}
                        </StyledLabel>
                    </div>
                </div>
            </ModalHeader>
            <ModalBody className="p-0">
                <ModalContentWrapper
                    setlistSelectedData={setlistSelectedData}
                />
            </ModalBody>
            <ModalFooter>
                <StyledButton
                    disabled={false}
                    rounded
                    variant="light"
                    outline={true}
                    size="sm"
                    style={{
                        background: "white",
                        color: "black",
                        padding: "0px",
                        width: "150px",
                        height: "45px",
                    }}
                    onClick={() => {
                        // setDataProfilIsUpdated(!dataIsUpdated);
                        // setBalise([]);
                        // setDesignation("");
                        // setDescription("");
                        // clearData();
                        // clearMultiCustomSelectionData();
                        // setTypeProfil("");
                        setEditActionProfile(false);
                        toggle();
                    }}
                >
                    {t("Cancel")}
                </StyledButton>
                <StyledButton
                    rounded
                    variant="primary"
                    outline={false}
                    disabled={!finallyResult}
                    size="sm"
                    style={{
                        color: "white",
                        padding: "0px",
                        width: "150px",
                        height: "45px",
                    }}
                    onClick={() => {
                        if (editActionProfile) {
                            handleUpdateProfil();
                        } else {
                            handleValidateButtonClickEvent(
                                designation,
                                typeProfil,
                                description,
                                balise
                            );
                        }
                    }}
                >
                    {t("Valider")}
                </StyledButton>
            </ModalFooter>
        </StyledModal>
    );
}
