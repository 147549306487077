import React, { useState } from "react";
import { CrossIcon } from "@aureskonnect/react-ui";
import classNames from "classnames";
import { setIsVisible } from "../../../pages/store";

import "./index.css";
import MessageSvgIcon from "../SvgIcons/MessageSvgIcon";

type MessageInfoType = {
    message: string;
};

export default function MessageInfo({ message }: MessageInfoType) {
    const [isMessageClosed, setIsMessageClosed] = useState<boolean>(false);

    return (
        <div
            className={classNames("align-items-center div-notification__clz", {
                "d-none": isMessageClosed,
                "d-flex": !isMessageClosed,
            })}
            style={{ height: "71px", minHeight: "71px", maxHeight: "71px" }}
        >
            <div className="flex-grow-1">
                <div className="d-flex align-items-center">
                    {/* <div
                        className="d-flex justify-content-center d-flex align-items-center"
                        style={{
                            backgroundColor: "black",
                            width: "71px",
                            height: "71px",
                        }}
                    >
                        <span
                            className="iconify"
                            data-icon="bx:message-rounded-error"
                            style={{ color: "white" }}
                            data-width="32"
                        />
                    </div> */}
                    <div
                        className="d-flex justify-content-center d-flex align-items-center"
                        style={{
                            backgroundColor: "black",
                            width: "71px",
                            height: "71px",
                        }}
                    >
                        <MessageSvgIcon height={30} width={30} fill="white" />
                    </div>
                    <div
                        className="px-3 d-flex justify-content-center d-flex align-items-center"
                        style={{
                            color: "rgba(0,0,0,.85)",
                            fontWeight: 700,
                            fontSize: "15px",
                            width: "fit-content",
                            fontFamily: "Segoe UI",
                        }}
                    >
                        {message}.
                    </div>
                </div>
            </div>
            <div
                className="pr-2"
                style={{ cursor: "pointer", marginRight: "10px" }}
            >
                <CrossIcon
                    className="icon-close-notification__clz"
                    height={17}
                    width={17}
                    onClick={() => {
                        setIsVisible(true);
                        setIsMessageClosed(!isMessageClosed);
                    }}
                />
            </div>
        </div>
        // <Card
        //     id="headercompo"
        //     className=" my-1"
        //     style={{ height: "71px", minHeight: "71px", maxHeight: "71px" }}
        // >
        //     <div className="d-flex justify-content-md-between">
        //         <div
        //             className="d-flex justify-content-start "
        //             style={{ height: "71px" }}
        //         >
        //             <div
        //                 className="d-flex justify-content-center d-flex align-items-center"
        //                 style={{
        //                     backgroundColor: "black",
        //                     width: "71px",
        //                 }}
        //             >
        //                 <span
        //                     className="iconify"
        //                     data-icon="bx:message-rounded-error"
        //                     style={{ color: "white" }}
        //                     data-width="32"
        //                 />
        //             </div>
        //             <div className=" px-3 d-flex justify-content-center d-flex align-items-center">
        //                 <h6> {message} </h6>
        //             </div>
        //         </div>
        //         <div
        //             className="pr-2"
        //             style={{ cursor: "pointer", marginRight: "10px" }}
        //         >
        //             <CrossIcon
        //                 className="icon-close-notification__clz"
        //                 height={13}
        //                 width={13}
        //                 onClick={() => setIsMessageClosed(!isMessageClosed)}
        //             />
        //         </div>
        //     </div>
        // </Card>
    );
}
