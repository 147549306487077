import React from "react";

import { useRoutes } from "react-router-dom";

import AuthLayout from "@components/AuthLayout";

// import { useTranslation } from "react-i18next";
import Gestioncomptes from "@pages/Overview/Gestioncomptes";
import Modules from "@pages/Modules/Modules";
import Products from "@pages/Products/Products";
import Packs from "@pages/Packs/Packs";
import Configurations from "@pages/Overview/Configurations";
import Motifs from "@pages/Motifs/Motifs";
import TypeGestionnaireDeComptes from "@pages/TypeGestionnaireDeComptes";
// eslint-disable-next-line
import AuthRoute from "./middleware/AuthRoute";
// import { Login } from "@pages/AuthenticationSso/Login";
// import { Callback } from "@pages/AuthenticationSso/Callback";
import AuthRouterConsole from "./middleware/AuthRouterConsole";
import Login from "@pages/Authentication/Login";
export default function Router() {
    const authWithSso = process.env.authWithSso;
    return useRoutes([
        {
            element: authWithSso ? (
                <AuthRoute>
                    <AuthLayout />
                </AuthRoute>
            ) : (
                <AuthRouterConsole>
                    <AuthLayout />
                </AuthRouterConsole>
            ),

            children: [
                {
                    path: "Gestioncomptes",
                    children: [
                        {
                            path: "Comptesclients",
                            element: <Gestioncomptes />,
                        },
                        // {
                        //     path: "Demandesenattentes",
                        //     element: <Gestioncomptes />,
                        // },
                        {
                            path: "Comptesenanomalie",
                            element: <Gestioncomptes />,
                        },
                        {
                            path: "Comptesarchives",
                            element: <Gestioncomptes />,
                        },
                        {
                            path: "Comptesbloques",
                            element: <Gestioncomptes />,
                        },
                    ],
                },

                {
                    path: "configurations",
                    children: [
                        {
                            path: "TypeGestionnaireDeComptes",
                            element: <TypeGestionnaireDeComptes />,
                        },
                        {
                            path: "module",
                            element: <Modules />,
                        },
                        {
                            path: "product",
                            element: <Products />,
                        },
                        { path: "pack", element: <Packs /> },
                        { path: "profile", element: <Configurations /> },
                        { path: "motif", element: <Motifs /> },
                    ],
                },

                // { path: "profile", element: <Configurations /> },
                // { path: "History", element: <History /> },

                // {
                //     path: "/",
                //     element: <Navigate to="Gestioncomptes/Comptesclients" />,
                // },
            ],
        },
        // {
        //     element: <Login />,
        //     path: "Login",
        //     children: [
        //         {
        //             path: "Gestioncomptes",
        //             children: [
        //                 {
        //                     path: "Comptesclients",
        //                     element: <Gestioncomptes />,
        //                 },
        //                 // {
        //                 //     path: "Demandesenattentes",
        //                 //     element: <Gestioncomptes />,
        //                 // },
        //                 {
        //                     path: "Comptesenanomalie",
        //                     element: <Gestioncomptes />,
        //                 },
        //                 {
        //                     path: "Comptesarchives",
        //                     element: <Gestioncomptes />,
        //                 },
        //                 {
        //                     path: "Comptesbloques",
        //                     element: <Gestioncomptes />,
        //                 },
        //             ],
        //         },

        //         {
        //             path: "configurations",
        //             children: [
        //                 {
        //                     path: "TypeGestionnaireDeComptes",
        //                     element: <TypeGestionnaireDeComptes />,
        //                 },
        //                 {
        //                     path: "module",
        //                     element: <Modules />,
        //                 },
        //                 {
        //                     path: "product",
        //                     element: <Products />,
        //                 },
        //                 { path: "pack", element: <Packs /> },
        //                 { path: "profile", element: <Configurations /> },
        //                 { path: "motif", element: <Motifs /> },
        //             ],
        //         },

        //         // { path: "profile", element: <Configurations /> },
        //         // { path: "History", element: <History /> },
        //     ],
        // },
        {
            path: "/",
            element: <Login />,
        },
        // {
        //     element: <Login />,
        //     path: "login",
        // },
        // {
        //     element: <Callback />,
        //     path: "/Callback",
        // },
    ]);
}
