import React from "react";
import {
    CrossIcon,
    StyledIconButton,
    StyledModal,
    StyledLabel,
    StyledButton,
} from "@aureskonnect/react-ui";
import { ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";

import { store } from "../store";
import TableTypeGestionnaireDeComptesHistory from "./TableTypeGestionnaireDeComptesHistory";

type ListHistoryModalType = {
    isHisotyTypeGestionnaireDeComptesModalOpened: boolean;
    setIsHisotyTypeGestionnaireDeComptesModalOpened: React.Dispatch<
        React.SetStateAction<boolean>
    >;
    selectedRows: any;
    uuidTypeGestionnaireDeCompteHistory: any;
};

export function HistoryTypeGestionnaireDeComptes(props: ListHistoryModalType) {
    const { t } = useTranslation();
    const { selectedTypeGestionnaireDeComptes } = useSnapshot(store);
    const { isHitoryAllTypeGestionnaireDeComptes } = useSnapshot(store);
    const colorBreadcrumb = "#E5E4E4AB";

    return (
        <React.Fragment>
            <StyledModal
                className="history-modal-style"
                isOpen={props.isHisotyTypeGestionnaireDeComptesModalOpened}
            >
                <ModalHeader className="d-flex align-items-center">
                    <div
                        className="d-flex align-items-center justify-content-between"
                        style={{
                            height: "35px",
                            display: "grid",
                            gridTemplateColumns: "1.8fr 0.2fr",
                            gridTemplateRows: "1fr",
                            gap: "1014px",
                        }}
                    >
                        <StyledLabel>
                            {t("HISTORIQUE TYPE(S) GESTIONNAIRE(S) DE COMPTES")}
                        </StyledLabel>

                        <StyledIconButton
                            icon="CrossIcon"
                            onClick={() => {
                                props.setIsHisotyTypeGestionnaireDeComptesModalOpened(
                                    !props.isHisotyTypeGestionnaireDeComptesModalOpened
                                );
                            }}
                        >
                            <CrossIcon
                                cursor="pointer"
                                height={10}
                                width={10}
                            />
                        </StyledIconButton>
                    </div>
                </ModalHeader>
                <ModalBody>
                    {isHitoryAllTypeGestionnaireDeComptes ? null : (
                        <div
                            style={{
                                display: "grid",
                                gridTemplateColumns: "1fr 1fr 1fr 1fr auto",
                                gap: "20px",
                                background: colorBreadcrumb,
                                height: "56px",
                                marginBottom: "5px",
                                alignItems: "center",
                            }}
                        >
                            <div className="d-flex flex-row bd-highlight mb-3 mt-2">
                                <div
                                    className="p-2 bd-highlight mt-1"
                                    style={{ width: "max-content" }}
                                >
                                    <span
                                        style={{
                                            textAlign: "left",
                                            font:
                                                " normal normal bold 20px/14px Segoe UI",
                                            color: "#2B2828",
                                            fontWeight: 600,
                                        }}
                                    >
                                        Désignation :
                                    </span>
                                </div>
                                <div className="p-2 bd-highlight">
                                    <StyledLabel
                                        style={{
                                            fontWeight: "normal",
                                            width: "max-content",
                                        }}
                                    >
                                        {
                                            selectedTypeGestionnaireDeComptes.Designation
                                        }
                                    </StyledLabel>
                                </div>
                            </div>
                        </div>
                    )}

                    <div>
                        <TableTypeGestionnaireDeComptesHistory
                            uuid={selectedTypeGestionnaireDeComptes.uuid}
                            selectedRows={props.selectedRows}
                            uuidTypeGestionnaireDeCompteHistory={
                                props.uuidTypeGestionnaireDeCompteHistory
                            }
                        />
                    </div>
                </ModalBody>
                <ModalFooter className="d-flex justify-content-end">
                    <div className="d-flex align-items-center">
                        <div className="ml-3">
                            <StyledButton
                                disabled={false}
                                rounded
                                variant="light"
                                outline={true}
                                size="sm"
                                style={{
                                    background: "black",

                                    color: "white",

                                    padding: "0px",

                                    width: "150px",

                                    height: "45px",
                                }}
                                onClick={() =>
                                    props.setIsHisotyTypeGestionnaireDeComptesModalOpened(
                                        !props.isHisotyTypeGestionnaireDeComptesModalOpened
                                    )
                                }
                            >
                                {t("Close")}
                            </StyledButton>
                        </div>
                    </div>
                </ModalFooter>
            </StyledModal>
        </React.Fragment>
    );
}
