import React from "react";
import {
    StyledModal,
    StyledButton,
    //Tabs,
    StyledIconButton,
    CrossIcon,
} from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useTranslation } from "react-i18next";
import PageTitle from "@components/Common/PageTitle";

import "../index.css";
import TableClientHistory from "./TableClientHistory";

type ListHistoryModalType = {
    isModalOpened: boolean;
    setIsModalOpened: Function;
};

export function HistoryModal({
    isModalOpened,
    setIsModalOpened,
}: ListHistoryModalType) {
    const { t } = useTranslation();
    return (
        <React.Fragment>
            <StyledModal
                className="custom-modal-style"
                toggle={true}
                isOpen={isModalOpened}
            >
                <ModalHeader className="d-flex align-items-center">
                    <div
                        className="d-flex align-items-center justify-content-between"
                        style={{
                            height: "35px",
                            display: "grid",
                            gridTemplateColumns: "2.8fr 0.2fr",
                            gridTemplateRows: "1fr",
                            gap: "1400px",
                        }}
                    >
                        <p
                            className="title-modal-style"
                            style={{ width: "max-content" }}
                        >
                            HISTORIQUE COMPTE CLIENT
                        </p>
                        <StyledIconButton
                            icon="CrossIcon"
                            onClick={() => {
                                setIsModalOpened(!isModalOpened);
                            }}
                        >
                            <CrossIcon
                                cursor="pointer"
                                height={10}
                                width={10}
                            />
                        </StyledIconButton>
                    </div>
                </ModalHeader>
                {/* <ModalHeader>
                    <StyledH2 className="text-uppercase">
                        {t("History page")}
                    </StyledH2>
                </ModalHeader> */}

                <ModalBody>
                    <PageTitle title={"Historique"} />
                    <TableClientHistory />
                    {/* <Tabs
                        tabs={[
                            {
                                content: (
                                    <div>
                                        <TableClientHistory />
                                    </div>
                                ),
                                title: (
                                    <div
                                        className="d-flex justify-content-between"
                                        style={{
                                            gap: "10px",
                                            fontSize: "16px",
                                            color: "black",
                                        }}
                                    >
                                        {"Compte client"}
                                    </div>
                                ),
                            },
                            {
                                content: (
                                    <div>
                                        <TableProductsHistory />
                                    </div>
                                ),
                                title: (
                                    <div
                                        className="d-flex justify-content-between"
                                        style={{
                                            gap: "10px",
                                            fontSize: "16px",
                                            color: "black",
                                        }}
                                    >
                                        {"Licences"}
                                    </div>
                                ),
                            },
                        ]}
                    /> */}
                </ModalBody>

                <ModalFooter className="d-flex justify-content-end">
                    <div className="d-flex align-items-center">
                        <div className="ml-3">
                            <StyledButton
                                rounded
                                variant="secondary"
                                onClick={() => setIsModalOpened(!isModalOpened)}
                            >
                                {t("Return")}
                            </StyledButton>
                        </div>
                    </div>
                </ModalFooter>
            </StyledModal>
        </React.Fragment>
    );
}
