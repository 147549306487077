import React from "react";
import {
    StyledButton,
    StyledModal,
    CrossIcon,
    StyledIconButton,
    StyledParagraph,
} from "@aureskonnect/react-ui";
import { ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { useSnapshot } from "valtio";

import { showToast } from "@components/Common/ToastShow";
import { store, setSelectedAccount } from "../store";

type ModalType = {
    isCofirmationModalOpened: boolean;
    setIsConfirmationModalOpened: Function;
    typeConfirmation: Number;
    setAnomalie: Function;
    setMotifAnnomalie: Function;
    setDemo: Function;
    setConfigurationDemo: Function;
};

export function ConfirmationModal({
    isCofirmationModalOpened,
    setIsConfirmationModalOpened,
    typeConfirmation,
    setAnomalie,
    setMotifAnnomalie,
    setDemo,
    setConfigurationDemo,
}: ModalType) {
    const { SelectedAccount } = useSnapshot(store);
    let textConfirmation;
    let titleTypeConfirmation;
    switch (typeConfirmation) {
        case 1:
            textConfirmation = `Voulez-vous confirmer la désactivation de l'état "en anomalie" du compte ?`;
            titleTypeConfirmation = "CONFIRMATION";
            break;
        case 2:
            textConfirmation = `Voulez-vous confirmer la désactivation de l'état "Démo" du compte ?`;
            titleTypeConfirmation = "CONFIRMATION";
            break;
        default:
    }

    function handleValidateBtn() {
        switch (typeConfirmation) {
            case 1: //Désactivation de l'état en anomalie lors de la modification du compte
                setAnomalie(false);
                setMotifAnnomalie("");
                if (SelectedAccount !== "") {
                    setSelectedAccount({
                        ...SelectedAccount,
                        NoteAnomalieEtat: false,
                        motifAnomalie: "",
                    });
                }
                showToast(
                    true,
                    `L'état du compte "En anomalie" a été desactivé`
                );
                break;
            case 2: //Désactivation de l'état Démo
                setDemo(false);
                setConfigurationDemo({
                    limitCollection: {
                        active: false,
                        budget: "",
                    },
                    duration: "",
                    automaticallyRenewable: false,
                    printsTicketDemo: false,
                });

                if (SelectedAccount !== "") {
                    setSelectedAccount({
                        ...SelectedAccount,
                        NoteDemoEtat: false,
                    });
                }
                showToast(
                    true,
                    `L'état du compte "Démo" a été desactivé`
                );
                break;
            default:
        }
    }

    return (
        <div>
            <StyledModal
                className="motif-modal-style"
                centered
                isOpen={isCofirmationModalOpened}
                style={{
                    marginTop: "12px",
                    maxWidth: "1004px",
                    maxHeight: "100px",
                    borderRadius: "10px",
                }}
            >
                <ModalHeader className="d-flex align-items-center">
                    <div
                        className="d-flex align-items-center justify-content-between "
                        style={{
                            height: "35px",
                            display: "grid",
                            gridTemplateColumns: "1.8fr 0.2fr",
                            gridTemplateRows: "1fr",
                            gap: "472px",
                        }}
                    >
                        <p
                            className="title-style-confirmation"
                            style={{ height: "10px" }}
                        >
                            {titleTypeConfirmation}
                        </p>
                        <StyledIconButton icon="CrossIcon">
                            <CrossIcon
                                cursor="pointer"
                                height={10}
                                width={10}
                                onClick={() => {
                                    setIsConfirmationModalOpened(
                                        !isCofirmationModalOpened
                                    );
                                }}
                            />
                        </StyledIconButton>
                    </div>
                </ModalHeader>

                <ModalBody style={{ width: "1004px" }}>
                    <StyledParagraph> {textConfirmation}</StyledParagraph>
                </ModalBody>

                <ModalFooter
                    className="d-flex justify-content-end"
                    style={{
                        background: "#FFFFFF",
                    }}
                >
                    <div
                        className="d-flex align-items-center"
                        style={{ gap: "30px" }}
                    >
                        <div className="col-4">
                            <StyledButton
                                disabled={false}
                                rounded
                                variant="light"
                                outline={true}
                                size="sm"
                                style={{
                                    background: "white",
                                    color: "black",
                                    padding: "0px",
                                    width: "150px",
                                    height: "45px",
                                }}
                                onClick={() => {
                                    setIsConfirmationModalOpened(
                                        !isCofirmationModalOpened
                                    );
                                }}
                            >
                                Annuler
                            </StyledButton>
                        </div>
                        <div className="col-4">
                            <StyledButton
                                disabled={false}
                                rounded
                                variant="primary"
                                outline={false}
                                size="sm"
                                style={{
                                    color: "white",
                                    padding: "0px",
                                    width: "150px",
                                    height: "45px",
                                }}
                                onClick={() => {
                                    handleValidateBtn();
                                    setIsConfirmationModalOpened(
                                        !isCofirmationModalOpened
                                    );
                                }}
                            >
                                Valider
                            </StyledButton>
                        </div>
                    </div>
                </ModalFooter>
            </StyledModal>
        </div>
    );
}
