import React, { useState, useEffect } from "react";
import {
    Card,
    CardBody,
    Col,
    Row,
    CardHeader,
    CardFooter,
    Container,
    Input,
} from "reactstrap";
import PageTitle from "@components/Common/PageTitle";
import PhoneInput from "react-phone-input-2";
import Breadcurmb from "@components/Common/Breadcrumb/index";
import { useTranslation } from "react-i18next";
import { StyledButton } from "@aureskonnect/react-ui";
import { IoIosCheckbox, IoIosSquareOutline } from "react-icons/io";
import TableAccounts from "@components/Table/TableAccounts";
import GreenIcon from "@assets/images/svg-icons/GreenIcon.svg";
import settingActiveIcon from "@assets/images/svg-icons/settingActiveIcon.svg";
import classnames from "classnames";

import {
    StyledSelectInput,
    CrossIcon,
    StyledLabel,
} from "@aureskonnect/react-ui";
import Labell from "@components/Labell";
import { showToast } from "@components/Common/ToastShow";

import { useSnapshot } from "valtio";
import {
    store,
    setIsHidden,
    setSelectedAccount,
    setIsAlertConsultLicencesModal,
} from "../store";
import MessageInfo from "@components/Common/MessageInfo";
import { ConfigurationDemoModal } from "./ConfigurationDemoModal";
import { MotifModal } from "@pages/Motifs/MotifLicensesModal";
import cloud_data from "@assets/images/svg-icons/cloud_data.svg";
import { AdvancedInformationModal } from "./AdvancedInformations";
import { ProfilAdministrateurModal } from "./ProfilAdministrateurModal";
import { ConfirmationModal } from "./ConfirmationModal";
import { ConsultLicencesModal } from "./ConsultLicencesModal";
import "../../index.css";
import "react-phone-input-2/lib/style.css";

export default function Gestioncomptes(props: any) {
    // localStorage.setItem(
    //     "Uid_Entitie_User",
    //     "8ecfe5ee-926b-423e-8050-c911dd81c147" // "a07b7b64-c648-443d-8d31-bccd48530bc6" Franchise Chayma 2 //  "6a09d318-1b96-4215-babf-c8ea3d99f1b5" Franchise Chayma // "c767fbb5-0e5f-405b-acf9-f8a415c3060f" foued  // "fe12aa75-f997-4a59-8afc-69a3013a52cc" saddem // "522bf1f9-2692-4637-898a-1ac6de6797c3" boutique admine 2 //"6a09d318-1b96-4215-babf-c8ea3d99f1b5" // franchise1 //"8ecfe5ee-926b-423e-8050-c911dd81c147" // entité
    //     //  "8ecfe5ee-926b-423e-8050-c911dd81c999"
    // );
    const { t } = useTranslation();
    const {
        isHidden,
        filtreTableAccount,
        SelectedAccount,
        BtnValiderIsHidden,
        isUpdateAccount,
        isAlertConsultLicencesModal,
        isVisible,
    } = useSnapshot(store);

    const [
        isConfigurationModalOpened,
        setIsConfigurationModalOpened,
    ] = React.useState<boolean>(false);
    const [
        isAdvancedInformationModalOpened,
        setIsAdvancedInformationModalOpened,
    ] = React.useState<boolean>(false);
    const [typeMotif, setTypeMotif] = React.useState<Number>(0);
    const [isMotifModalOpened, setIsMotifModalOpened] = React.useState<boolean>(
        false
    );
    const [finallyResult, setFinallyResult] = React.useState<any>(true);
    const [dflt_Label, setDflt_Label] = useState("");
    const [dflt_value, setDflt_value] = useState("");
    let noteEtatCompteAnomalie = "";
    let noteEtatCompteDemo = "";
    let motifAnomalieSystème = "";
    const [uid, setUid]: any = useState<string>(
        "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
            var r = (Math.random() * 16) | 0,
                v = c === "x" ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        })
    );
    const uidLoggedEntity = localStorage.getItem("Uid_Entitie_User");
    const [profilsParent, setProfilsParent] = useState<any>([]);
    const [profilsLiaison, setProfilsLiaison] = useState<any>([]);
    const [optionsProfil, setOptionsProfil] = useState([]);
    const [optionsErp] = useState([
        {
            label: "Erpos",
            value: "Erpos",
        },
        {
            label: "Oracle",
            value: "Oracle",
        },
        {
            label: "Sage",
            value: "Sage",
        },
        {
            label: "Odoo",
            value: "Odoo",
        },
        {
            label: "Microsoft Dynamics",
            value: "Microsoft Dynamics",
        },
        {
            label: "Dolibarr",
            value: "Dolibarr",
        },
    ]);
    const [nom, setNom] = useState("");
    const [prenom, setPrenom] = useState("");
    const [profil, setProfil] = useState("");
    const [profilName, setProfilName] = useState("");
    const [, setErp] = useState("");
    const [CrmID, setCrmID] = useState("");
    const [Franchise, setFranchise] = useState("");
    const [uidFranchise, setUidFranchise] = useState("");
    const [raisonsocial, setRaisonsocial] = useState("");
    const [nomCommercial, setNomCommercial] = useState("");
    const [portable, setPortable] = useState("");
    const [siret, setSiret] = useState("");
    const [adresse, setAdresse] = useState("");
    const [codeape, setCodeape] = useState("");
    const [adresse1, setAdresse1] = useState("");
    const [adresse2, setAdresse2] = useState("");
    const [numerotva, setNumerotva] = useState("");
    const [pays, setPays] = useState("");
    const [profilParent, setProfilParent] = useState(dflt_value);
    const [profilParentName, setProfilParentName] = useState(dflt_Label);
    const [profilliaison, setProfilliaison] = useState("");
    const [profilLiaisonName, setProfilLiaisonName] = useState("");
    const [tell, setTell] = useState("");
    const [etatcompte, setEtatcompte] = useState("Activé");
    const [emailEntity, setEmailEntity] = useState("");
    const [email, setEmail] = useState("");
    const [anomalie, setAnomalie] = useState(false);
    const [demo, setDemo] = useState(false);
    const [listeFranchise, setListeFranchise] = useState([]);
    const optionsListeFranchise: any = [];
    const [siteWeb, setSiteWeb] = useState("");
    const [phone, setPhone] = useState("");
    const [portable1, setPortable1] = useState("");
    const [configurationDemo, setConfigurationDemo] = React.useState<any>({
        limitCollection: {
            active: false,
            budget: "",
        },
        duration: "",
        automaticallyRenewable: false,
        printsTicketDemo: false,
    });
    const [motifAnnomalie, setMotifAnnomalie] = React.useState<any>("");
    const [pageTitle, setPageTitle] = useState<string>("");
    const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
    };
    const [dataIsUpdated, setDataIsUpdated] = React.useState<any>(false);
    const [checkedBoutique, setCheckedBoutique] = React.useState<any>(true);
    const [checkedGestionCompte, setCheckedGestionCompte] = React.useState<any>(
        false
    );
    const [ListePays, setListePays] = useState<any>([]);
    const [
        optionsTypeGestionnaireCompte,
        setOptionsTypeGestionnaireCompte,
    ] = useState([]);

    const [typeAccount, setTypeAccount] = useState("");
    const [
        ConfigurationBeforeUpdate,
        setConfigurationBeforeUpdate,
    ]: any = useState("");
    const [
        isConfigurationBlocageModalOpened,
        setIsConfigurationBlocageModalOpened,
    ] = React.useState<boolean>(false);
    const [
        isProfilAdministrateurModalOpened,
        setIsProfilAdministrateurModalOpened,
    ] = useState<boolean>(false);
    const [isCofirmationModalOpened, setIsConfirmationModalOpened] = useState<
        boolean
    >(false);
    const [schemaFranchise, setSchemaFranchise] = React.useState<string>("");
    const [uuidFranchise, setUuidFranchise] = React.useState<string>("");
    const [uuidStore, setUuidStore] = React.useState<any>("");
    const [idStore, setIdStore] = React.useState<any>("");
    const [franchiseName, setFranchiseName] = React.useState<string>("");
    const [StoreName, setStoreName] = React.useState<string>("");
    const [ListeSchemaFranchise, setListeSchemaFranchise] = useState<any>([]);
    //   {
    //         uid: "franchisedemo1",
    //         value: "franchise_demo_1",
    //         label: "DEMO 1",
    //     },
    //     {
    //         uid: "franchisedemo2",
    //         value: "franchise_demo_2",
    //         label: "DEMO 2",
    //     },
    const [typeConfirmation, setTypeConfirmation] = useState<number>(0);
    const [uuidtypeAccount, setUuidTypeAccount] = useState("");
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [isClick, setIsClick] = React.useState(false);
    const selectedCountryCode = localStorage.getItem("selectedCountryCode");
    function initializeData() {
        setUid(
            "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (
                c
            ) {
                var r = (Math.random() * 16) | 0,
                    v = c === "x" ? r : (r & 0x3) | 0x8;
                return v.toString(16);
            })
        );
        setCrmID("");
        setErp("");
        setNomCommercial("");
        setRaisonsocial("");
        setTypeAccount("");
        setUidFranchise("");
        setFranchise("");
        setProfilParent("");
        setProfilParentName("");
        setProfilliaison("");
        setProfilLiaisonName("");
        setAdresse("");
        setAdresse1("");
        setAdresse2("");
        setPays("");
        setTell("");
        setPhone("");
        setSiret("");
        setNumerotva("");
        setCodeape("");
        setEmail("");
        setSiteWeb("");
        setNom("");
        setPrenom("");
        setProfil("");
        setEmailEntity("");
        setPortable("");
        setPortable1("");
        setCheckedBoutique(true);
        setCheckedGestionCompte(false);
        setSchemaFranchise("");
        setUuidFranchise("");
        setUuidStore("");
        setIdStore("");
        setProfilName("");
        setStoreName("");
        setUuidTypeAccount("");
    }

    async function handleListDesignationTypeGestionnaireDeComptes() {
        try {
            await fetch(
                `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/listTypeGestionnaireDeComptes?archived=false&uuidEntity=${uidLoggedEntity}`,
                {
                    method: "get",
                    headers: { "Content-Type": "application/json" },
                }
            )
                .then((response) => response.json())
                .then((data) => {
                    let array: any = [];
                    data.data.forEach((el: any) => {
                        array.push({
                            label: el["Designation"],
                            value: el["uuid"],
                        });
                    });
                    setOptionsTypeGestionnaireCompte(array);
                });
        } catch (error) {
            console.log(error);
        }
    }

    function getProfilsNatures() {
        fetch(
            `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/getprofilsNatures?uidEntity=${uidLoggedEntity}`,
            requestOptions
        )
            .then((response: any) => response.json())
            .then((response: any) => {
                if (response.status === true) {
                    if (response.data.length > 0) {
                        setOptionsProfil(
                            response.data.map((datum: any, index: number) => {
                                return {
                                    label: datum.name,
                                    value: datum.uid,
                                };
                            })
                        );
                    }
                }
            })
            .catch((error) => {
                console.error("There was an error: getProfilsNatures!", error);
            });
    }

    function getprofilsLiaison() {
        fetch(
            `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/getAccountLists?EntityUid=${uidLoggedEntity}&AccountType=All`, //getprofilsLiaison
            requestOptions
        )
            .then((response: any) => response.json())
            .then((response: any) => {
                if (response.length > 0) {
                    setProfilsLiaison(
                        response.map((datum: any, index: number) => {
                            if (index === 0) {
                                setDflt_Label(datum.commercialname);
                                setDflt_value(datum.uid);
                            }
                            return {
                                label: datum.commercialname,
                                value: datum.uid,
                            };
                        })
                    );
                    setProfilsParent(
                        response.map((datum: any, index: number) => {
                            return {
                                label: datum.commercialname,
                                value: datum.uid,
                            };
                        })
                    );
                }
            })
            .catch((error) => {
                console.error("There was an error : getprofilsLiaison!", error);
            });
    }

    function getListePays() {
        fetch(
            `https://dev-setting-api.aureskonnect.com/api/v1/settings/pays`,
            requestOptions
        )
            .then((response: any) => response.json())
            .then((response: any) => {
                if (response.data.length > 0) {
                    setListePays(
                        response.data.map((Pays: any) => {
                            return {
                                label: Pays.Designation,
                                value: Pays.Designation,
                            };
                        })
                    );
                }
            })
            .catch((error) => {
                console.error("There was an error: getListePays!", error);
            });
    }

    function get_Franchise() {
        fetch(
            `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/getAccountLists?EntityUid=${uidLoggedEntity}&AccountType=Franchise`,
            requestOptions
        )
            .then((response: any) => response.json())
            .then((response: any) => {
                Object.keys(response).forEach((index: any) => {
                    if (typeof response[index] === "object") {
                        let selectFranchise = {
                            value: response[index].uid,
                            label: response[index].commercialname,
                        };
                        optionsListeFranchise.push(selectFranchise);
                    }
                    setListeFranchise(optionsListeFranchise);
                });
            })
            .catch((error) => {
                console.error("There was an error!", error);
            });
    }

    function getSchemaFranchise() {
        fetch(
            `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/getFranhiseSchemaBd?`,
            requestOptions
        )
            .then((response: any) => response.json())
            .then((response: any) => {
                if (response.length > 0) {
                    setListeSchemaFranchise(
                        response.map((franchise: any) => {
                            return {
                                label: franchise.NameFranchise,
                                value: franchise.schemaFranchise,
                                uid: franchise.uidFranchise,
                            };
                        })
                    );
                }
            })
            .catch((error) => {
                console.error("There was an error!", error);
            });
    }

    function get_data_clt_info(Id_client_erpos: any) {
        fetch(
            `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/erposCLient?idClient=${Id_client_erpos}`,
            requestOptions
        )
            .then((response: any) => response.json())
            .then((response: any) => {
                if (response.error === false) {
                    const data = response.data;
                    if (data.code === "0") {
                        setEmailEntity(data.message.info_client.email);
                        setNomCommercial(data.message.info_client.Nom_Enseigne);
                        setPrenom(data.message.info_client.prenom);
                        setNom(data.message.info_client.nom);
                        setRaisonsocial(data.message.info_client.Raison_social);
                        setAdresse(data.message.info_client.adresse);
                        setAdresse1(data.message.info_client.cp);
                        setAdresse2(data.message.info_client.ville);
                        setPays(data.message.info_client.pays);
                        setTell(data.message.info_client.numtel);
                        setPortable(data.message.info_client.numtel);
                        setEmail(data.message.info_client.email);
                        setSiret(data.message.info_client.siret);
                        setNumerotva(data.message.info_client.TVAIntracomm);
                    }
                }
            })
            .catch((error) => {
                console.error("There was an error!", error);
            });
    }
    // eslint-disable-next-line
    function ValidatePhoneNumber(phone: any) {
        return /^(([+]{0,1}\d{2})|\d?)[\s-]?[0-9]{2}[\s-]?[0-9]{3}[\s-]?[0-9]{4}$/gm.test(
            phone
        );
    }
    function saveHistoryChanges(
        oldData: any,
        newData: any,
        uidEntity: any,
        typeHistory: any
    ) {
        fetch(`${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/HistoryChanges`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                oldData: oldData,
                newData: newData,
                uidEntity: uidEntity,
                typeHistory: typeHistory,
            }),
        })
            .then((response) => {
                response.json().then((data) => {
                    return data.error;
                });
            })
            .catch((e: any) => {
                return false;
            });
    }
    function addAccount() {
        //controle pour vérifier l'état du compte
        if (
            siret === "" ||
            codeape === "" ||
            numerotva === "" ||
            adresse === "" ||
            adresse2 === "" || //--> ville
            adresse1 === "" || //--> code postal
            (siret === "en cours" &&
                codeape === "en cours" &&
                numerotva === "en cours")
        ) {
            setEtatcompte("Activé");
            noteEtatCompteAnomalie = "Anomalie";
            motifAnomalieSystème = "Informations obligatoires manquantes";
        } else if (anomalie === true) {
            setEtatcompte("Activé");
            noteEtatCompteAnomalie = "Anomalie";
        }
        if (demo === true) {
            setEtatcompte("Activé");
            noteEtatCompteDemo = "Demo";
        }
        let newEntity = JSON.stringify({
            uid: uid,
            name: raisonsocial,
            database_info: {
                franchise: checkedBoutique
                    ? franchiseName
                    : typeAccount === "Franchise"
                    ? raisonsocial
                    : franchiseName,
                uid_franchise:
                    typeAccount === "Franchise" ? uid : uuidFranchise,
                schema_franchise: schemaFranchise,
                uid_store: uuidStore,
                id_store: idStore,
                ETK_Type: 0,
                storeName: StoreName,
            },
            erpos_id: CrmID,
            etat: {
                etat: etatcompte,
                status: {
                    note: `${noteEtatCompteAnomalie}|${noteEtatCompteDemo}`,
                    motif: `${motifAnomalieSystème}|${motifAnnomalie}|`, //motifAnnomalie === "" ? "" : motifAnnomalie,
                    limitCollection: {
                        duration:
                            configurationDemo === "" || undefined
                                ? ""
                                : configurationDemo?.duration === "" ||
                                  undefined
                                ? ""
                                : configurationDemo?.duration,
                        active:
                            configurationDemo.active === "" || undefined
                                ? ""
                                : configurationDemo.limitCollection.active,
                        budget:
                            configurationDemo.budget === "" || undefined
                                ? ""
                                : configurationDemo.limitCollection.budget,
                        printsTicketDemo:
                            configurationDemo.printsTicketDemo === "" ||
                            undefined
                                ? ""
                                : configurationDemo.printsTicketDemo,
                        automaticallyRenewable:
                            configurationDemo.automaticallyRenewable === "" ||
                            undefined
                                ? ""
                                : configurationDemo.automaticallyRenewable,
                    },
                    settingBlocage: {
                        status: "",
                        BlocageDefinitif: "",
                        BlocagePriseCommande: "",
                        planification: "",
                    },
                },
            },
            AccountType: checkedBoutique ? "Boutique" : typeAccount,
            type: checkedBoutique ? "Boutique" : typeAccount,
            parent_profilelink: { uid: profilParent, name: profilParentName },
            childs_profilelink: [],
            ConnectedAccount:
                profilliaison === ""
                    ? []
                    : [{ uid: profilliaison, name: profilLiaisonName }],
            general_information: {
                address: adresse + "|" + adresse1 + "|" + adresse2,
                country: pays,
                Socialreason: raisonsocial,
                commercialname: nomCommercial,
                phonenumber: tell,
                email: emailEntity,
                userEmail: email,
                name: nom,
                firstname: prenom,
                siret: siret,
                codeape: codeape,
                vat: numerotva,
                siteWeb: siteWeb,
                portable1: portable,
                portable2: portable1,
                phone: phone,
            },
            parent_profilelink_allowed_access: [],
            licences: "",
            actual_ref_catalog: "",
            catalog: [],
            profiles: [{ uid: profil, name: profilName }],
            uuidType: checkedBoutique ? "uuid" : uuidtypeAccount,
        });
        //controle sur les champs du formulaire
        let error = "";
        if (nomCommercial === "") {
            error = "Le champ 'Nom Commercial' doit être rempli";
        } else if (raisonsocial === "") {
            error = "Le champ 'Raison Sociale' doit être rempli";
        } else if (checkedGestionCompte && typeAccount === "") {
            error = "Le champ 'Type Gestionnaire de comptes' doit être rempli";
        } else if (profilParent === "") {
            error =
                "Le champ 'Compte parent (gestionnaire du compte)' doit être rempli";
        } else if (pays === "") {
            error = "Le champ 'Pays' doit être rempli";
        } else if (nom === "") {
            error = "Le champ 'Nom' doit être rempli";
        } else if (prenom === "") {
            error = "Le champ 'Prénom' doit être rempli";
        } else if (profil === "") {
            error = "Le champ 'Profil' doit être rempli";
        }
        // else if(tell === "" || ValidatePhoneNumber(tell) === false) {
        //     setErrorMessage(
        //         "Veuillez vérifier votre numéro de téléphone SVP !"
        //     );
        //     error = !error;}
        else if (email === "") {
            error = "Le champ 'Adresse e-mail' doit être rempli";
        } else if (!/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9-]+.[a-z]{2,4}$/.test(email)) {
            error = "Cette adresse e-mail est invalide.";
        }
        if (error !== "") {
            showToast(false, error);
        } else {
            setFinallyResult(false);
            fetch(`${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/Addaccount`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    Uid: uid,
                    dataEntity: newEntity,
                }),
            })
                .then(async (response) => {
                    if (response.status === 200) {
                        saveHistoryChanges(
                            {
                                uid: uid,
                                type: checkedBoutique
                                    ? "Boutique"
                                    : typeAccount,
                            },
                            {
                                designation: nomCommercial,
                                uid: uid,
                                type: checkedBoutique
                                    ? "Boutique"
                                    : typeAccount,
                            },
                            uidLoggedEntity,
                            1
                        );
                        showToast(
                            true,
                            "Le compte client a été ajouté avec succès"
                        );
                        if (noteEtatCompteAnomalie === "Anomalie") {
                            showToast(
                                true,
                                `L'état du compte "En anomalie" a été activé automatiquement, des informations obligatoires manquantes.`
                            );
                        }
                        setTimeout(() => {
                            setDataIsUpdated(!dataIsUpdated);
                            initializeData();
                            setIsHidden(!isHidden);
                        }, 2000);
                    } else {
                        showToast(false, "Erreur.. Veuillez réessayer svp !");
                    }
                })
                .catch((error) => {
                    showToast(false, error);
                })
                .finally(() => {
                    setFinallyResult(true);
                });
        }
    }

    function UpdateAccount() {
        motifAnomalieSystème = "";
        if (
            SelectedAccount.siret === "" ||
            SelectedAccount.codeape === "" ||
            SelectedAccount.vat === "" ||
            SelectedAccount.Rue === "" ||
            SelectedAccount.Ville === "" ||
            SelectedAccount.CodePostal === "" ||
            (SelectedAccount.siret === "en cours" &&
                SelectedAccount.codeape === "en cours" &&
                SelectedAccount.vat === "en cours")
        ) {
            setSelectedAccount({
                ...SelectedAccount,
                Etats: "Activé",
            });
            noteEtatCompteAnomalie = "Anomalie";
            setSelectedAccount({
                ...SelectedAccount,
                motifAnomalieSysteme: "Informations obligatoires manquantes",
            });
            showToast(
                true,
                `L'état du compte "En anomalie" a été activé automatiquement, des informations obligatoires manquantes.`
            );
        } else if (
            SelectedAccount?.NoteAnomalieEtat === true &&
            SelectedAccount.motifAnomalie !== ""
        ) {
            setSelectedAccount({
                ...SelectedAccount,
                Etats: "Activé",
            });
            noteEtatCompteAnomalie = "Anomalie";

            if (SelectedAccount.motifAnomalieSysteme !== "") {
                setSelectedAccount({
                    ...SelectedAccount,
                    motifAnomalieSysteme: "",
                });
                // showToast(true, `L'état du compte "En anomalie système" a été desactivé`);
            }
        } else if (
            SelectedAccount?.NoteAnomalieEtat === true &&
            SelectedAccount.motifAnomalie === ""
        ) {
            setSelectedAccount({
                ...SelectedAccount,
                Etats: "Activé",
            });
            noteEtatCompteAnomalie = "";

            if (SelectedAccount.motifAnomalieSysteme !== "") {
                setSelectedAccount({
                    ...SelectedAccount,
                    motifAnomalieSysteme: " ",
                });
                showToast(
                    true,
                    `L'état du compte "En anomalie système" a été desactivé`
                );
            }
        }
        if (SelectedAccount?.NoteDemoEtat === true) {
            setSelectedAccount({
                ...SelectedAccount,
                Etats: "Activé",
            });
            noteEtatCompteDemo = "Demo";
        }
        let updatedEntity = JSON.stringify({
            uid: SelectedAccount.Id, //uid
            name: SelectedAccount.Socialreason,
            database_info: {
                franchise: SelectedAccount.FranchiseName,

                uid_franchise:
                    typeAccount === "Franchise"
                        ? SelectedAccount.Id
                        : SelectedAccount.UidFranchise,
                schema_franchise: SelectedAccount.SchemaFranchise,
                uid_store: SelectedAccount.UidStore,
                id_store: SelectedAccount.IdStore,
                ETK_Type: 0,
                storeName: SelectedAccount.StoreName,
            },
            erpos_id: SelectedAccount.erposId,
            etat: {
                etat: SelectedAccount.Etats,
                status: {
                    note: `${noteEtatCompteAnomalie}|${noteEtatCompteDemo}`,
                    motif: `${SelectedAccount.motifAnomalieSysteme}|${SelectedAccount.motifAnomalie}|${SelectedAccount.motifEtatCompte}`,
                    //`${motifAnomalieSystème}|${motifAnnomalie}|${SelectedAccount.motifEtatCompte}`,
                    limitCollection: {
                        duration:
                            SelectedAccount.limiteCollection.duration ===
                                undefined ||
                            SelectedAccount.limiteCollection.duration === ""
                                ? ""
                                : SelectedAccount.limiteCollection.duration,
                        active:
                            SelectedAccount.limiteCollection.active ===
                                undefined ||
                            SelectedAccount.limiteCollection.active === ""
                                ? ""
                                : SelectedAccount.limiteCollection.active,
                        budget:
                            SelectedAccount.limiteCollection.budget ===
                                undefined ||
                            SelectedAccount.limiteCollection.budget === ""
                                ? ""
                                : SelectedAccount.limiteCollection.budget,
                        printsTicketDemo:
                            SelectedAccount.limiteCollection
                                .printsTicketDemo === undefined ||
                            SelectedAccount.limiteCollection
                                .printsTicketDemo === ""
                                ? ""
                                : SelectedAccount.limiteCollection
                                      .printsTicketDemo,
                        automaticallyRenewable:
                            SelectedAccount.limiteCollection
                                .automaticallyRenewable === undefined ||
                            SelectedAccount.limiteCollection
                                .automaticallyRenewable === ""
                                ? ""
                                : SelectedAccount.limiteCollection
                                      .automaticallyRenewable,
                    },
                    settingBlocage:
                        SelectedAccount.settingBlocage === undefined ||
                        SelectedAccount.settingBlocage === ""
                            ? {}
                            : SelectedAccount.settingBlocage,
                },
            },
            AccountType: SelectedAccount.AccountType, //checkedBoutique ? "Boutique" : typeAccount,
            type: SelectedAccount.AccountType,
            parent_profilelink: {
                uid:
                    profilParent !== ""
                        ? profilParent
                        : SelectedAccount.ProfileParent,
                name:
                    profilParentName !== ""
                        ? profilParentName
                        : SelectedAccount.ProfileParentName,
            },
            uidParentProfileLinkBeforeUpdate: SelectedAccount.ProfileParent,
            childs_profilelink: SelectedAccount.childs_profilelink,
            ConnectedAccount: [
                {
                    uid:
                        profilliaison !== ""
                            ? profilliaison
                            : SelectedAccount.Profiledeliaison,
                    name:
                        profilLiaisonName !== ""
                            ? profilLiaisonName
                            : SelectedAccount.ProfiledeliaisonName,
                },
            ],
            general_information: {
                address:
                    SelectedAccount.Rue +
                    "|" +
                    SelectedAccount.CodePostal +
                    "|" +
                    SelectedAccount.Ville,
                country: SelectedAccount.Pays,
                Socialreason: SelectedAccount.Socialreason,
                commercialname: SelectedAccount.commercialname,
                phonenumber: SelectedAccount.phone,
                email: SelectedAccount.mail,
                userEmail: SelectedAccount.userEmail,
                name: SelectedAccount.Name,
                firstname: SelectedAccount.firstName,
                siret: SelectedAccount.siret,
                codeape: SelectedAccount.codeape,
                vat: SelectedAccount.vat,
                siteWeb: SelectedAccount.SiteWeb,
                portable1: SelectedAccount.Portable,
                portable2: SelectedAccount.Portable1,
                phone: SelectedAccount.phone1,
            },
            parent_profilelink_allowed_access: [],
            licences: SelectedAccount.infoLicence,
            actual_ref_catalog: "",
            catalog: [],
            profiles: [
                {
                    uid: profil === "" ? SelectedAccount.userProfil : profil,
                    name:
                        profilName === ""
                            ? SelectedAccount.userProfilName
                            : profilName,
                },
            ],
        });
        let userInformationUpdated = "false";
        if (
            SelectedAccount.userEmail !==
                SelectedAccount.userEmailBeforeUpdate ||
            SelectedAccount.userProfil !==
                SelectedAccount.userProfilBeforeUpdate ||
            SelectedAccount.Name !== SelectedAccount.NameBeforeUpdate ||
            SelectedAccount.firstName !==
                SelectedAccount.firstNameBeforeUpdate ||
            SelectedAccount.Portable !== SelectedAccount.PortableBeforeUpdate ||
            SelectedAccount.Portable1 !== SelectedAccount.Portable1BeforeUpdate
        ) {
            userInformationUpdated = "true";
        }
        //  controle sur les champs du formulaire
        // console.log("updatedEntity ", updatedEntity);
        // console.log("SelectedAccount", SelectedAccount);
        let error = "";
        if (SelectedAccount.commercialname === "") {
            error = "Le champ 'Nom Commercial' doit être rempli";
        } else if (SelectedAccount.Socialreason === "") {
            error = "Le champ 'Raison Sociale' doit être rempli";
        }
        //  else if (checkedGestionCompte && typeAccount === "") {
        //     error = "Le champ 'Type Gestionnaire de comptes' doit être rempli"; }
        else if (SelectedAccount.ProfileParent === "") {
            error =
                "Le champ 'Compte parent (gestionnaire du compte)' doit être rempli";
        } else if (SelectedAccount.Pays === "") {
            error = "Le champ 'Pays' doit être rempli";
        } else if (SelectedAccount.firstName === "") {
            error = "Le champ 'Prénom' doit être rempli";
        } else if (SelectedAccount.Name === "") {
            error = "Le champ 'Nom' doit être rempli";
        }
        // else if(tell === "" || ValidatePhoneNumber(tell) === false) {
        //     setErrorMessage(
        //         "Veuillez vérifier votre numéro de téléphone SVP !"
        //     );
        //     error = !error;}
        else if (SelectedAccount.userEmail === "") {
            error = "Le champ 'Adresse mail' doit être rempli";
        } else if (
            !/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9-]+.[a-z]{2,4}$/.test(
                SelectedAccount.userEmail
            )
        ) {
            error = "Cette adresse e-mail est invalide.";
        }
        if (error !== "") {
            console.log("updatedEntity ", error, updatedEntity);
            showToast(false, error);
        } else {
            fetch(
                `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/updateAccount`,
                {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        uidEntity: SelectedAccount.Id,
                        dataEntity: updatedEntity,
                        userInformationUpdated: userInformationUpdated,
                    }),
                }
            )
                .then(async (response) => {
                    if (response.status === 200) {
                        showToast(true, "Le compte a été modifié avec succès");
                        setTimeout(() => {
                            setIsHidden(!isHidden);
                            setDataIsUpdated(!dataIsUpdated);
                            initializeData();
                        }, 2000);
                    } else {
                        showToast(false, "Veuillez réessayer svp !");
                    }
                })
                .catch((error) => {
                    console.error(
                        "Ooops! Erreur lors de la modification du compte",
                        error
                    );
                    showToast(false, error);
                });
        }
    }

    useEffect(() => {
        get_Franchise();
        getprofilsLiaison();
        getProfilsNatures();
        getListePays();
        handleListDesignationTypeGestionnaireDeComptes();
        getSchemaFranchise();
        // eslint-disable-next-line
    }, [isHidden]);

    useEffect(() => {
        if (isProfilAdministrateurModalOpened === false) {
            getProfilsNatures();
        }
        // eslint-disable-next-line
    }, [isProfilAdministrateurModalOpened]);

    useEffect(() => {
        setAnomalie(false);
        setDemo(false);
    }, [isHidden]);

    useEffect(() => {
        if (isUpdateAccount && SelectedAccount.AccountType === "Boutique") {
            setCheckedBoutique(true);
            setCheckedGestionCompte(false);
        } else if (isUpdateAccount) {
            setCheckedGestionCompte(true);
            setCheckedBoutique(false);
            setAnomalie(SelectedAccount.NoteAnomalieEtat);
        }
        // eslint-disable-next-line
    }, [SelectedAccount]);

    useEffect(() => {
        setPageTitle(
            filtreTableAccount === "Activé"
                ? "COMPTES CLIENTS"
                : filtreTableAccount === "Archivé"
                ? "COMPTES ARCHIVÉS"
                : filtreTableAccount === "Enattente"
                ? "COMPTES EN ATTENTES"
                : filtreTableAccount === "Bloqué"
                ? "COMPTES BLOQUÉS"
                : filtreTableAccount === "Anomalie"
                ? "COMPTES EN ANOMALIE"
                : ""
        );
    }, [filtreTableAccount]);

    return (
        <React.Fragment>
            <PageTitle title={t("Overview")} />
            <Container fluid style={{ marginTop: "-15px" }}>
                <Row>
                    <Col>
                        <Breadcurmb
                            title={t(`GESTION DES COMPTES / ${pageTitle}`)}
                            breadcrumbBackgroundColor={""}
                        />
                    </Col>
                    <MessageInfo
                        message={
                            filtreTableAccount === "Activé"
                                ? "Il est essentiel de créer un compte client regroupant les informations du client qui peuvent être récupérées à partir d'un ERP. Pour chaque compte client, il est possible de gérer des licences"
                                : filtreTableAccount === "Archivé"
                                ? "Il est possible de consulter les comptes clients archivés dans cette rubrique. Leur activation est permise en déclarant un motif"
                                : filtreTableAccount === "Bloqué"
                                ? "Les comptes clients bloqués sont regroupés dans cette rubrique. Il est possible de les activer ou les archiver suite à la déclaration d'un motif"
                                : filtreTableAccount === "Anomalie"
                                ? "Les comptes en anomalie peuvent être tagués manuellement ou automatiquement, après un certain délai, si des informations obligatoires sont manquantes"
                                : ""
                        }
                    />
                </Row>

                <CardBody
                    style={{
                        padding: "1px",
                    }}
                >
                    <div
                        className="row "
                        style={{
                            display: "grid",
                            gridTemplateColumns: isHidden ? "2fr 1fr" : "auto",
                            gap: "4px",
                        }}
                    >
                        <TableAccounts
                            url={`${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/getAccounts?condition=${filtreTableAccount}&uuid_entity=${uidLoggedEntity}`} //Getcomptes
                            btnValiderhidden={BtnValiderIsHidden}
                            filtreTab={filtreTableAccount}
                            setDataIsUpdated={setDataIsUpdated}
                            dataIsUpdated={dataIsUpdated}
                        />

                        {isHidden ? (
                            <React.Fragment>
                                <Card className="border ml-2">
                                    <CardHeader
                                        className="Headercard  d-flex justify-content-between pb-2"
                                        style={{
                                            height: "11.7%",
                                            backgroundColor: "white",
                                            border:
                                                "1px solid rgba(0,0,0,.125)",
                                        }}
                                    >
                                        <div className="d-flex align-items-center justify-content-center">
                                            <h4
                                                className="m-0 pl-2"
                                                style={{
                                                    font:
                                                        "normal normal 600 20px/27px Segoe UI",
                                                }}
                                            >
                                                {SelectedAccount === ""
                                                    ? "Ajouter compte"
                                                    : filtreTableAccount ===
                                                      "Archivé"
                                                    ? "Consulter un compte"
                                                    : "Modifier compte"}
                                            </h4>
                                        </div>

                                        <div className="d-flex align-items-center ">
                                            <div className=" bd-highlight align-items-center ml-4">
                                                <CrossIcon
                                                    cursor="pointer"
                                                    height={17}
                                                    width={17}
                                                    onClick={() => {
                                                        setIsHidden(!isHidden);
                                                        initializeData();
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </CardHeader>
                                    <CardBody
                                        style={{
                                            overflowY: "hidden",
                                            maxHeight: `calc(100vh - (500px + ${
                                                isVisible ? "-71px" : "0px"
                                            }))`,
                                            background: "#FFFFFF",
                                            border:
                                                "1px solid rgba(0,0,0,.125)",
                                            marginTop: "4px",
                                        }}
                                    >
                                        <div
                                            className="py-2"
                                            style={
                                                SelectedAccount.Etats ===
                                                "Activé"
                                                    ? {
                                                          display: "grid",
                                                          gridTemplateColumns:
                                                              " 1.00fr 1.00fr 1.00fr",
                                                          gap: "40px",
                                                          placeSelf: "center",
                                                          //   borderBottom:
                                                          //       "1px solid rgba(0,0,0,.125)",
                                                      }
                                                    : {
                                                          display: "grid",
                                                          gridTemplateColumns:
                                                              " 1fr 0.75fr 0.75fr 0.002fr",
                                                          gap: "40px",
                                                          placeSelf: "center",
                                                          borderBottom:
                                                              "3px solid rgba(0,0,0,.125)",
                                                      }
                                            }
                                        >
                                            <div
                                                className="d-flex align-items-center justify-content-between"
                                                style={{ gap: "3px" }}
                                            >
                                                <span
                                                    style={{
                                                        textAlign: "left",
                                                        font:
                                                            " normal normal 600 16px/21px Segoe UI",
                                                        letterSpacing: "0px",
                                                        color: "#000000",
                                                        opacity: 1,
                                                    }}
                                                >
                                                    Activé
                                                    {/* {SelectedAccount.Etats ===
                                                        "Activé"
                                                            ? "Activé"
                                                            : "En attente d'activation"} */}
                                                </span>
                                                <img
                                                    src={
                                                        GreenIcon
                                                        // SelectedAccount.Etats ===
                                                        // "Activé"
                                                        //     ? GreenIcon
                                                        //     : YellowIcon
                                                    }
                                                    role="button"
                                                    id="EditBtton"
                                                    aria-expanded="false"
                                                    alt="edit"
                                                    onClick={() => {}}
                                                />
                                                <span className="vertical-line"></span>
                                            </div>

                                            <div className="d-flex align-items-center justify-content-between">
                                                <span
                                                    style={{
                                                        textAlign: "left",
                                                        font:
                                                            " normal normal 600 16px/21px Segoe UI",
                                                        letterSpacing: "0px",
                                                        color:
                                                            anomalie ||
                                                            SelectedAccount?.NoteAnomalieEtat ===
                                                                true
                                                                ? "#0019FE"
                                                                : "#000000",
                                                        opacity: 1,
                                                        textDecorationLine:
                                                            anomalie ||
                                                            SelectedAccount?.NoteAnomalieEtat ===
                                                                true
                                                                ? "underline"
                                                                : "None",
                                                    }}
                                                    /*  onClick={() => {
                                                        if (anomalie ||(SelectedAccount !== "" && SelectedAccount.NoteAnomalieEtat === true )) {
                                                        setTypeMotif(0);
                                                        // setMotifAnnomalie("")
                                                            setIsMotifModalOpened(
                                                            !isMotifModalOpened
                                                        );
                                                        }
                                                    }}*/
                                                >
                                                    Anomalie
                                                </span>

                                                <div
                                                    style={{
                                                        pointerEvents:
                                                            filtreTableAccount ===
                                                            "Archivé"
                                                                ? "none"
                                                                : "auto",
                                                        opacity:
                                                            filtreTableAccount ===
                                                            "Archivé"
                                                                ? "0.8"
                                                                : "",
                                                    }}
                                                    onClick={() => {
                                                        setTypeMotif(0);
                                                        if (
                                                            anomalie ||
                                                            SelectedAccount?.NoteAnomalieEtat ===
                                                                true
                                                        ) {
                                                            setTypeConfirmation(
                                                                1
                                                            );
                                                            setIsConfirmationModalOpened(
                                                                !isCofirmationModalOpened
                                                            );
                                                        } else {
                                                            setAnomalie(true);
                                                            if (
                                                                SelectedAccount !==
                                                                ""
                                                            ) {
                                                                setSelectedAccount(
                                                                    {
                                                                        ...SelectedAccount,
                                                                        NoteAnomalieEtat: true,
                                                                    }
                                                                );
                                                            }
                                                            setIsMotifModalOpened(
                                                                !isMotifModalOpened
                                                            );
                                                        }
                                                    }}
                                                >
                                                    {anomalie === true ||
                                                    SelectedAccount?.NoteAnomalieEtat ===
                                                        true ? (
                                                        <IoIosCheckbox
                                                            style={{
                                                                fill: "#E30613",
                                                                height: "25px",
                                                                width: "23px",
                                                            }}
                                                        />
                                                    ) : (
                                                        <IoIosSquareOutline
                                                            style={{
                                                                fill: "#E30613",
                                                                height: "25px",
                                                                width: "23px",
                                                            }}
                                                        />
                                                    )}
                                                </div>
                                                <span className="vertical-line"></span>
                                            </div>

                                            <div className=" align-items-center justify-content-between d-flex">
                                                <span
                                                    style={{
                                                        textAlign: "left",
                                                        font:
                                                            " normal normal 600 16px/21px Segoe UI",
                                                        letterSpacing: "0px",
                                                        color:
                                                            demo ||
                                                            SelectedAccount?.NoteDemoEtat ===
                                                                true
                                                                ? "#0019FE"
                                                                : "#000000",
                                                        opacity: 1,
                                                        textDecorationLine:
                                                            demo ||
                                                            SelectedAccount?.NoteDemoEtat ===
                                                                true
                                                                ? "underline"
                                                                : "None",
                                                    }}
                                                    onClick={() => {
                                                        if (
                                                            SelectedAccount !==
                                                                "" &&
                                                            SelectedAccount?.NoteDemoEtat ===
                                                                true
                                                        ) {
                                                            setConfigurationBeforeUpdate(
                                                                {
                                                                    limitCollection: {
                                                                        active:
                                                                            SelectedAccount
                                                                                .limiteCollection
                                                                                .active,
                                                                        budget:
                                                                            SelectedAccount
                                                                                .limiteCollection
                                                                                .budget,
                                                                    },
                                                                    duration:
                                                                        SelectedAccount
                                                                            .limiteCollection
                                                                            .duration,
                                                                    automaticallyRenewable:
                                                                        SelectedAccount
                                                                            .limiteCollection
                                                                            .automaticallyRenewable,
                                                                    printsTicketDemo:
                                                                        SelectedAccount
                                                                            .limiteCollection
                                                                            .printsTicketDemo,
                                                                }
                                                            );
                                                            setIsConfigurationModalOpened(
                                                                !isConfigurationModalOpened
                                                            );
                                                        }
                                                    }}
                                                >
                                                    Démo
                                                </span>
                                                <div
                                                    style={{
                                                        pointerEvents:
                                                            filtreTableAccount ===
                                                            "Archivé"
                                                                ? "none"
                                                                : "auto",
                                                        opacity:
                                                            filtreTableAccount ===
                                                            "Archivé"
                                                                ? "0.8"
                                                                : "",
                                                    }}
                                                    onClick={() => {
                                                        if (
                                                            demo ||
                                                            SelectedAccount.NoteDemoEtat ===
                                                                true
                                                        ) {
                                                            setTypeConfirmation(
                                                                2
                                                            );
                                                            setIsConfirmationModalOpened(
                                                                !isCofirmationModalOpened
                                                            );
                                                        } else {
                                                            setDemo(true);
                                                            if (
                                                                SelectedAccount !==
                                                                ""
                                                            ) {
                                                                setSelectedAccount(
                                                                    {
                                                                        ...SelectedAccount,
                                                                        NoteDemoEtat: true,
                                                                    }
                                                                );
                                                            }

                                                            setIsConfigurationModalOpened(
                                                                !isConfigurationModalOpened
                                                            );
                                                        }
                                                    }}
                                                >
                                                    {demo === true ||
                                                    SelectedAccount?.NoteDemoEtat ===
                                                        true ? (
                                                        <IoIosCheckbox
                                                            style={{
                                                                fill: "#363636",
                                                                height: "25px",
                                                                width: "23px",
                                                                marginRight:
                                                                    "20px",
                                                            }}
                                                        />
                                                    ) : (
                                                        <IoIosSquareOutline
                                                            style={{
                                                                fill: "#363636",
                                                                height: "25px",
                                                                width: "23px",
                                                                marginRight:
                                                                    "20px",
                                                            }}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            style={{
                                                maxHeight: "368px",
                                                overflowY: "scroll",
                                            }}
                                        >
                                            {/* Identifiant */}
                                            {/* <div className="py-2">
                                                <Labell
                                                    lab="Identifiant"
                                                    id="inputIdentifiant"
                                                    className="text-style"
                                                ></Labell>

                                                <div>
                                                    <Input
                                                        style={{
                                                            width: "98%",
                                                        }}
                                                        className="form-control"
                                                        id="inputIdentifiant"
                                                        name="Identifiant"
                                                        placeholder=""
                                                        type="text"
                                                        value={
                                                            SelectedAccount ===
                                                            ""
                                                                ? uid
                                                                : SelectedAccount.Id
                                                        }
                                                    />
                                                </div>
                                            </div> */}
                                            {/* CrmId */}
                                            <div
                                                style={{
                                                    marginRight: "10px",
                                                }}
                                            >
                                                <div
                                                    className="my-2"
                                                    style={{
                                                        display: "grid",
                                                        gridTemplateColumns:
                                                            " 1fr 1.2fr ",
                                                        gap: "10px",

                                                        placeSelf: "center",
                                                    }}
                                                >
                                                    <div
                                                        className="d-flex align-items-center "
                                                        style={{
                                                            gap: "2%",
                                                        }}
                                                    >
                                                        <Labell
                                                            lab="ERP"
                                                            id="InputErp"
                                                        ></Labell>
                                                    </div>
                                                    <Labell
                                                        lab="ID ERP"
                                                        id="InputErpId"
                                                    ></Labell>
                                                </div>

                                                <div
                                                    className="my-2"
                                                    style={{
                                                        display: "grid",
                                                        gridTemplateColumns:
                                                            " 1fr 1fr 0.2fr",
                                                        gap: "10px",
                                                        placeSelf: "center",
                                                    }}
                                                >
                                                    <StyledSelectInput
                                                        name="Erp_select"
                                                        id="Erp_select"
                                                        options={optionsErp}
                                                        isDisabled={
                                                            filtreTableAccount ===
                                                            "Archivé"
                                                                ? true
                                                                : false
                                                        }
                                                        onChange={(e: any) => {
                                                            setErp(e.value);
                                                            setCrmID("");
                                                        }}
                                                        defaultValue={
                                                            optionsErp[0]
                                                        }
                                                    />
                                                    <Input
                                                        style={{
                                                            height: "38px",
                                                            pointerEvents:
                                                                filtreTableAccount ===
                                                                "Archivé"
                                                                    ? "none"
                                                                    : "auto",
                                                            opacity:
                                                                filtreTableAccount ===
                                                                "Archivé"
                                                                    ? "0.8"
                                                                    : "",
                                                        }}
                                                        className="form-control"
                                                        id="InputCrmId"
                                                        name="CrmId"
                                                        placeholder="Saisir"
                                                        type="text"
                                                        onChange={(e) => {
                                                            setCrmID(
                                                                e.target.value
                                                            );
                                                            if (
                                                                SelectedAccount !==
                                                                ""
                                                            ) {
                                                                setSelectedAccount(
                                                                    {
                                                                        ...SelectedAccount,
                                                                        erposId:
                                                                            e
                                                                                ?.target
                                                                                .value,
                                                                    }
                                                                );
                                                            }
                                                        }}
                                                        value={
                                                            SelectedAccount ===
                                                            ""
                                                                ? CrmID
                                                                : SelectedAccount?.erposId
                                                        }
                                                    />
                                                    <img
                                                        src={cloud_data}
                                                        style={{
                                                            pointerEvents:
                                                                filtreTableAccount ===
                                                                "Archivé"
                                                                    ? "none"
                                                                    : "auto",
                                                            opacity:
                                                                filtreTableAccount ===
                                                                "Archivé"
                                                                    ? "0.8"
                                                                    : "",
                                                        }}
                                                        role="button"
                                                        id="refreshBtton"
                                                        aria-expanded="false"
                                                        alt="refresh"
                                                        onClick={() => {
                                                            if (
                                                                CrmID.length > 3
                                                            ) {
                                                                get_data_clt_info(
                                                                    CrmID
                                                                );
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            {/* nom commercial */}
                                            <div className="py-2">
                                                <Labell
                                                    lab="Nom Commercial*"
                                                    id="inputNomCommercial"
                                                ></Labell>
                                                <div>
                                                    <Input
                                                        style={{
                                                            pointerEvents:
                                                                filtreTableAccount ===
                                                                "Archivé"
                                                                    ? "none"
                                                                    : "auto",
                                                            opacity:
                                                                filtreTableAccount ===
                                                                "Archivé"
                                                                    ? "0.8"
                                                                    : "",
                                                            width: "98%",
                                                            // border: isEmptyDes
                                                            //     ? "1px solid red"
                                                            //     : "1px solid #ced4da",
                                                        }}
                                                        className="form-control"
                                                        id="inputNomCommercial"
                                                        name="NomCommercial"
                                                        placeholder="Saisir"
                                                        type="text"
                                                        onChange={(e) => {
                                                            setNomCommercial(
                                                                e.target.value
                                                            );
                                                            if (
                                                                SelectedAccount !==
                                                                ""
                                                            ) {
                                                                setSelectedAccount(
                                                                    {
                                                                        ...SelectedAccount,
                                                                        commercialname:
                                                                            e
                                                                                ?.target
                                                                                .value,
                                                                    }
                                                                );
                                                            }
                                                        }}
                                                        value={
                                                            SelectedAccount ===
                                                            ""
                                                                ? nomCommercial
                                                                : SelectedAccount?.commercialname
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            {/* raison social */}
                                            <div className="py-2">
                                                <Labell
                                                    lab="Raison Sociale*"
                                                    id="inputRaisonSociale"
                                                ></Labell>
                                                <div>
                                                    <Input
                                                        style={{
                                                            pointerEvents:
                                                                filtreTableAccount ===
                                                                "Archivé"
                                                                    ? "none"
                                                                    : "auto",
                                                            opacity:
                                                                filtreTableAccount ===
                                                                "Archivé"
                                                                    ? "0.8"
                                                                    : "",
                                                            width: "98%",
                                                            // border: isEmptyDes
                                                            //     ? "1px solid red"
                                                            //     : "1px solid #ced4da",
                                                        }}
                                                        className="form-control"
                                                        id="inputRaisonSociale"
                                                        name="RaisonSociale"
                                                        placeholder="Saisir"
                                                        type="text"
                                                        onChange={(e) => {
                                                            setRaisonsocial(
                                                                e.target.value
                                                            );
                                                            if (
                                                                SelectedAccount !==
                                                                ""
                                                            ) {
                                                                setSelectedAccount(
                                                                    {
                                                                        ...SelectedAccount,
                                                                        Socialreason:
                                                                            e
                                                                                ?.target
                                                                                .value,
                                                                    }
                                                                );
                                                            }
                                                        }}
                                                        value={
                                                            SelectedAccount ===
                                                            ""
                                                                ? raisonsocial
                                                                : SelectedAccount?.Socialreason
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            {/* Type compte */}
                                            <div className="py-2">
                                                <Labell
                                                    lab="Type du compte*"
                                                    id="inputTypeCompte"
                                                ></Labell>
                                                <div
                                                    className="my-2"
                                                    style={{
                                                        display: "grid",
                                                        gridTemplateColumns:
                                                            " 1fr 2fr",
                                                        gap: "10px",

                                                        placeSelf: "center",
                                                    }}
                                                >
                                                    <></>
                                                    <div className="d-flex flex-row">
                                                        <div
                                                            // className="p-1"
                                                            className={`${classnames(
                                                                {
                                                                    hovertexttypeCompte:
                                                                        SelectedAccount !==
                                                                            "" &&
                                                                        SelectedAccount.AccountType !==
                                                                            "Boutique" &&
                                                                        SelectedAccount
                                                                            .childs_profilelink
                                                                            .length >
                                                                            0,
                                                                }
                                                            )} p-1`}
                                                            data-hover={`La modification du type du compte "Gestionnaire de comptes" en "Boutique" est impossible si un ou plusieurs comptes y sont liés.`}
                                                        >
                                                            <input
                                                                type="radio"
                                                                disabled={
                                                                    filtreTableAccount ===
                                                                    "Archivé"
                                                                        ? true
                                                                        : SelectedAccount !==
                                                                              "" &&
                                                                          SelectedAccount.AccountType !==
                                                                              "Boutique" &&
                                                                          SelectedAccount
                                                                              .childs_profilelink
                                                                              .length >
                                                                              0
                                                                        ? true
                                                                        : false
                                                                }
                                                                checked={
                                                                    checkedBoutique
                                                                }
                                                                onChange={() => {
                                                                    setCheckedBoutique(
                                                                        true
                                                                    );
                                                                    setCheckedGestionCompte(
                                                                        false
                                                                    );

                                                                    // if (
                                                                    //     SelectedAccount !==
                                                                    //     ""
                                                                    // ) {
                                                                    //     setSelectedAccount(
                                                                    //         {
                                                                    //             ...SelectedAccount,
                                                                    //             AccountType:
                                                                    //                 "Boutique",
                                                                    //         }
                                                                    //     );
                                                                    // }
                                                                }}
                                                            ></input>
                                                        </div>
                                                        <div>
                                                            <Labell
                                                                lab="Boutique"
                                                                id="inputRadioBoutique"
                                                            ></Labell>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex flex-row">
                                                        <div className="p-1">
                                                            <input
                                                                type="radio"
                                                                disabled={
                                                                    filtreTableAccount ===
                                                                    "Archivé"
                                                                        ? true
                                                                        : false
                                                                }
                                                                checked={
                                                                    checkedGestionCompte
                                                                }
                                                                onChange={() => {
                                                                    setCheckedBoutique(
                                                                        false
                                                                    );
                                                                    setCheckedGestionCompte(
                                                                        true
                                                                    );
                                                                }}
                                                            ></input>
                                                        </div>
                                                        <div>
                                                            <Labell
                                                                lab="Gestionnaire de comptes"
                                                                id="inputGestionnaireDeComptes"
                                                            ></Labell>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="py-2"
                                                style={{
                                                    marginRight: "10px",
                                                }}
                                            >
                                                {checkedGestionCompte ? (
                                                    <Labell
                                                        lab="Type Gestionnaire de comptes*"
                                                        id="inputTypeGestCompte"
                                                    ></Labell>
                                                ) : (
                                                    <Labell
                                                        lab="Franchise"
                                                        id="inputListeFranchise"
                                                    ></Labell>
                                                )}

                                                {checkedGestionCompte ? (
                                                    <div className="css-b62m3t-container">
                                                        <StyledSelectInput
                                                            name="TypeGestCompte"
                                                            id="TypeGestCompteInput"
                                                            options={
                                                                optionsTypeGestionnaireCompte
                                                            }
                                                            isDisabled={
                                                                filtreTableAccount ===
                                                                "Archivé"
                                                                    ? true
                                                                    : false
                                                            }
                                                            onChange={(
                                                                e: any
                                                            ) => {
                                                                setTypeAccount(
                                                                    e.label
                                                                );
                                                                setUuidTypeAccount(
                                                                    e.value
                                                                );
                                                                if (
                                                                    SelectedAccount !==
                                                                    ""
                                                                ) {
                                                                    setSelectedAccount(
                                                                        {
                                                                            ...SelectedAccount,
                                                                            AccountType:
                                                                                e?.label,
                                                                            uuidAccountManagerType:
                                                                                e?.value,
                                                                        }
                                                                    );
                                                                }
                                                                if (
                                                                    e.label ===
                                                                    "Franchise"
                                                                ) {
                                                                    setUuidFranchise(
                                                                        uid
                                                                    );
                                                                }
                                                            }}
                                                            value={
                                                                SelectedAccount ===
                                                                ""
                                                                    ? typeAccount ===
                                                                      ""
                                                                        ? ""
                                                                        : {
                                                                              label: typeAccount,
                                                                              value: uuidtypeAccount,
                                                                          }
                                                                    : SelectedAccount.AccountType ===
                                                                      "Boutique"
                                                                    ? ""
                                                                    : {
                                                                          label:
                                                                              SelectedAccount.AccountType,
                                                                          value:
                                                                              SelectedAccount.uuidAccountManagerType,
                                                                      }
                                                            }
                                                            placeholder={
                                                                "Sélectionner"
                                                            }
                                                        />
                                                    </div>
                                                ) : (
                                                    <div className="css-b62m3t-container">
                                                        <StyledSelectInput
                                                            isDisabled={
                                                                filtreTableAccount ===
                                                                "Archivé"
                                                                    ? true
                                                                    : false
                                                            }
                                                            name="ListeFranchise"
                                                            id="ListeFranchiseInput"
                                                            options={
                                                                listeFranchise
                                                            }
                                                            onChange={(
                                                                e: any
                                                            ) => {
                                                                setUidFranchise(
                                                                    e.value
                                                                );
                                                                setFranchise(
                                                                    e.label
                                                                );
                                                                setFranchiseName(
                                                                    e.label
                                                                );
                                                                setUuidFranchise(
                                                                    e.value
                                                                );
                                                            }}
                                                            value={
                                                                SelectedAccount ===
                                                                ""
                                                                    ? uidFranchise ===
                                                                      ""
                                                                        ? ""
                                                                        : {
                                                                              label: Franchise,
                                                                              value: uidFranchise,
                                                                          }
                                                                    : SelectedAccount.AccountType !==
                                                                      "Boutique"
                                                                    ? ""
                                                                    : {
                                                                          label:
                                                                              SelectedAccount.Franchise,
                                                                          value:
                                                                              SelectedAccount.UidFranchise,
                                                                      }
                                                            }
                                                            placeholder={
                                                                "Sélectionner"
                                                            }
                                                        />
                                                    </div>
                                                )}
                                                {/* </div> */}
                                            </div>
                                            {/* CompteParent */}
                                            <div
                                                className="py-2"
                                                style={{
                                                    marginRight: "10px",
                                                }}
                                            >
                                                <Labell
                                                    lab="Compte parent (gestionnaire du compte)*"
                                                    id="inputCompteParent"
                                                ></Labell>
                                                <div className="css-b62m3t-container">
                                                    <StyledSelectInput
                                                        name="CompteParent"
                                                        id="CompteParentInput"
                                                        options={profilsParent}
                                                        isDisabled={
                                                            filtreTableAccount ===
                                                            "Archivé"
                                                                ? true
                                                                : false
                                                        }
                                                        onChange={(e: any) => {
                                                            setProfilParent(
                                                                e.value
                                                            );
                                                            setProfilParentName(
                                                                e.label
                                                            );
                                                            if (
                                                                SelectedAccount !==
                                                                ""
                                                            ) {
                                                                setSelectedAccount(
                                                                    {
                                                                        ...SelectedAccount,
                                                                        ProfilParent:
                                                                            e?.value,
                                                                    }
                                                                );
                                                            }
                                                        }}
                                                        defaultValue={
                                                            SelectedAccount ===
                                                            ""
                                                                ? profilParent ===
                                                                  ""
                                                                    ? ""
                                                                    : {
                                                                          label: profilParentName,
                                                                          value: profilParent,
                                                                      }
                                                                : {
                                                                      label:
                                                                          SelectedAccount.ProfileParentName,
                                                                      value:
                                                                          SelectedAccount.ProfileParent,
                                                                  }
                                                        }
                                                        placeholder={
                                                            "Sélectionner"
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            {/* CompteDeLiaison */}
                                            <div
                                                className="py-2"
                                                style={{
                                                    marginRight: "10px",
                                                }}
                                            >
                                                <Labell
                                                    lab="Compte de liaison"
                                                    id="inputProfil"
                                                ></Labell>
                                                <div className="css-b62m3t-container">
                                                    <StyledSelectInput
                                                        name="ProfilLiaison"
                                                        id="inputProfilLiaison"
                                                        options={profilsLiaison}
                                                        isDisabled={
                                                            filtreTableAccount ===
                                                            "Archivé"
                                                                ? true
                                                                : false
                                                        }
                                                        onChange={(e: any) => {
                                                            setProfilliaison(
                                                                e.value
                                                            );
                                                            setProfilLiaisonName(
                                                                e.label
                                                            );
                                                            if (
                                                                SelectedAccount !==
                                                                ""
                                                            ) {
                                                                setSelectedAccount(
                                                                    {
                                                                        ...SelectedAccount,
                                                                        Profiledeliaison:
                                                                            e?.value,
                                                                    }
                                                                );
                                                            }
                                                        }}
                                                        placeholder={
                                                            "Sélectionner"
                                                        }
                                                        defaultValue={
                                                            SelectedAccount ===
                                                            ""
                                                                ? profilliaison ===
                                                                  ""
                                                                    ? ""
                                                                    : {
                                                                          label: profilLiaisonName,
                                                                          value: profilliaison,
                                                                      }
                                                                : SelectedAccount.Profiledeliaison ===
                                                                  ""
                                                                ? ""
                                                                : {
                                                                      label:
                                                                          SelectedAccount.ProfiledeliaisonName,
                                                                      value:
                                                                          SelectedAccount.Profiledeliaison,
                                                                  }
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            {/* adresse */}
                                            <div className="py-2">
                                                <Labell
                                                    lab="Adresse**"
                                                    id="inputAdresse"
                                                ></Labell>
                                                <div>
                                                    <Input
                                                        style={{
                                                            width: "98%",
                                                            pointerEvents:
                                                                filtreTableAccount ===
                                                                "Archivé"
                                                                    ? "none"
                                                                    : "auto",
                                                            opacity:
                                                                filtreTableAccount ===
                                                                "Archivé"
                                                                    ? "0.8"
                                                                    : "",
                                                        }}
                                                        className="form-control"
                                                        id="inputAdresse"
                                                        name="Adresse"
                                                        placeholder="Saisir"
                                                        type="text"
                                                        onChange={(e) => {
                                                            setAdresse(
                                                                e.target.value
                                                            );
                                                            if (
                                                                SelectedAccount !==
                                                                ""
                                                            ) {
                                                                setSelectedAccount(
                                                                    {
                                                                        ...SelectedAccount,
                                                                        Rue:
                                                                            e
                                                                                ?.target
                                                                                .value,
                                                                    }
                                                                );
                                                            }
                                                        }}
                                                        value={
                                                            SelectedAccount ===
                                                            ""
                                                                ? adresse
                                                                : SelectedAccount?.Rue
                                                        }
                                                    />
                                                </div>
                                                <div className="d-flex flex-column my-2">
                                                    <div
                                                        className="d-flex"
                                                        style={{
                                                            gap: "95px",
                                                        }}
                                                    >
                                                        <Labell
                                                            lab="Code Postal**"
                                                            id="inputCodePostal"
                                                        ></Labell>
                                                        <Labell
                                                            lab="Ville**"
                                                            id="inputVille"
                                                        ></Labell>
                                                    </div>

                                                    <div
                                                        className="d-flex align-items-center"
                                                        style={{
                                                            gap: "20px",
                                                        }}
                                                    >
                                                        <Input
                                                            style={{
                                                                width: "35%",
                                                                pointerEvents:
                                                                    filtreTableAccount ===
                                                                    "Archivé"
                                                                        ? "none"
                                                                        : "auto",
                                                                opacity:
                                                                    filtreTableAccount ===
                                                                    "Archivé"
                                                                        ? "0.8"
                                                                        : "",
                                                            }}
                                                            className="form-control"
                                                            id="inputAdresse1"
                                                            name="Adresse1"
                                                            placeholder="Saisir"
                                                            type="text"
                                                            onChange={(e) => {
                                                                setAdresse1(
                                                                    e.target
                                                                        .value
                                                                );
                                                                if (
                                                                    SelectedAccount !==
                                                                    ""
                                                                ) {
                                                                    setSelectedAccount(
                                                                        {
                                                                            ...SelectedAccount,
                                                                            CodePostal:
                                                                                e
                                                                                    ?.target
                                                                                    .value,
                                                                        }
                                                                    );
                                                                }
                                                            }}
                                                            value={
                                                                SelectedAccount ===
                                                                ""
                                                                    ? adresse1
                                                                    : SelectedAccount?.CodePostal
                                                            }
                                                        />

                                                        <Input
                                                            style={{
                                                                width: "60%",
                                                                marginRight:
                                                                    "10px",
                                                                pointerEvents:
                                                                    filtreTableAccount ===
                                                                    "Archivé"
                                                                        ? "none"
                                                                        : "auto",
                                                                opacity:
                                                                    filtreTableAccount ===
                                                                    "Archivé"
                                                                        ? "0.8"
                                                                        : "",
                                                            }}
                                                            className="form-control"
                                                            id="inputAdresse2"
                                                            name="Adresse2"
                                                            placeholder="Saisir"
                                                            type="text"
                                                            onChange={(e) => {
                                                                setAdresse2(
                                                                    e.target
                                                                        .value
                                                                );
                                                                if (
                                                                    SelectedAccount !==
                                                                    ""
                                                                ) {
                                                                    setSelectedAccount(
                                                                        {
                                                                            ...SelectedAccount,
                                                                            Ville:
                                                                                e
                                                                                    ?.target
                                                                                    .value,
                                                                        }
                                                                    );
                                                                }
                                                            }}
                                                            value={
                                                                SelectedAccount ===
                                                                ""
                                                                    ? adresse2
                                                                    : SelectedAccount?.Ville
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Pays new */}
                                            <div
                                                className="py-2"
                                                style={{
                                                    marginRight: "10px",
                                                }}
                                            >
                                                <Labell
                                                    lab="Pays*"
                                                    id="inputPays"
                                                ></Labell>
                                                <div className="css-b62m3t-container">
                                                    <StyledSelectInput
                                                        name="Pays"
                                                        id="PaysInput"
                                                        options={ListePays}
                                                        isDisabled={
                                                            filtreTableAccount ===
                                                            "Archivé"
                                                                ? true
                                                                : false
                                                        }
                                                        onChange={(e: any) => {
                                                            setPays(e.value);
                                                            if (
                                                                SelectedAccount !==
                                                                ""
                                                            ) {
                                                                setSelectedAccount(
                                                                    {
                                                                        ...SelectedAccount,
                                                                        Pays:
                                                                            e?.value,
                                                                    }
                                                                );
                                                            }
                                                        }}
                                                        value={
                                                            SelectedAccount ===
                                                            ""
                                                                ? pays === ""
                                                                    ? ""
                                                                    : {
                                                                          label: pays,
                                                                          value: pays,
                                                                      }
                                                                : {
                                                                      label:
                                                                          SelectedAccount.Pays,
                                                                      value:
                                                                          SelectedAccount.Pays,
                                                                  }
                                                        }
                                                        placeholder={
                                                            "Sélectionner"
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            {/* tel 1-2*/}
                                            <div className="d-flex flex-column my-2">
                                                <div
                                                    className="d-flex"
                                                    style={{ gap: "137px" }}
                                                >
                                                    <Labell
                                                        lab="N° Téléphone 1"
                                                        id="inputTel"
                                                    ></Labell>
                                                    <Labell
                                                        lab="N° Téléphone 2"
                                                        id="inputTel1"
                                                    ></Labell>
                                                </div>

                                                <div
                                                    className="d-flex align-items-center justify-content-between"
                                                    style={{ gap: "20px" }}
                                                >
                                                    <div>
                                                        <PhoneInput
                                                            buttonClass="ctl_btn_firstPhoneNumber"
                                                            inputClass="ctl_inp_firstPhoneNumber"
                                                            country={
                                                                selectedCountryCode as string
                                                            }
                                                            disabled={
                                                                filtreTableAccount ===
                                                                "Archivé"
                                                                    ? true
                                                                    : false
                                                            }
                                                            value={
                                                                SelectedAccount ===
                                                                ""
                                                                    ? tell
                                                                    : SelectedAccount?.phone
                                                            }
                                                            onChange={(
                                                                e: any
                                                            ) => {
                                                                setTell(e);
                                                                if (
                                                                    SelectedAccount !==
                                                                    ""
                                                                ) {
                                                                    setSelectedAccount(
                                                                        {
                                                                            ...SelectedAccount,
                                                                            phone: e,
                                                                        }
                                                                    );
                                                                }
                                                            }}
                                                            placeholder={t(
                                                                "Saisir"
                                                            )}
                                                        />
                                                    </div>

                                                    <div
                                                        style={{
                                                            marginRight: "10px",
                                                        }}
                                                    >
                                                        <PhoneInput
                                                            buttonClass="ctl_btn_secondPhoneNumber"
                                                            inputClass="ctl_inp_secondPhoneNumber "
                                                            country={
                                                                selectedCountryCode as string
                                                            }
                                                            disabled={
                                                                filtreTableAccount ===
                                                                "Archivé"
                                                                    ? true
                                                                    : false
                                                            }
                                                            value={
                                                                SelectedAccount ===
                                                                ""
                                                                    ? phone
                                                                    : SelectedAccount?.phone1
                                                            }
                                                            onChange={(
                                                                e: any
                                                            ) => {
                                                                setPhone(e);
                                                                if (
                                                                    SelectedAccount !==
                                                                    ""
                                                                ) {
                                                                    setSelectedAccount(
                                                                        {
                                                                            ...SelectedAccount,
                                                                            phone1: e,
                                                                        }
                                                                    );
                                                                }
                                                            }}
                                                            placeholder={t(
                                                                "Saisir"
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            {/* siret */}
                                            <div className="py-2">
                                                <Labell
                                                    lab="SIRET**"
                                                    id="inputSiret"
                                                ></Labell>
                                                <div>
                                                    <Input
                                                        style={{
                                                            width: "98%",
                                                            pointerEvents:
                                                                filtreTableAccount ===
                                                                "Archivé"
                                                                    ? "none"
                                                                    : "auto",
                                                            opacity:
                                                                filtreTableAccount ===
                                                                "Archivé"
                                                                    ? "0.8"
                                                                    : "",
                                                        }}
                                                        className="form-control"
                                                        id="inputSiret"
                                                        name="Siret"
                                                        placeholder="Saisir"
                                                        type="text"
                                                        onChange={(e) => {
                                                            setSiret(
                                                                e.target.value
                                                            );
                                                            if (
                                                                SelectedAccount !==
                                                                ""
                                                            ) {
                                                                setSelectedAccount(
                                                                    {
                                                                        ...SelectedAccount,
                                                                        siret:
                                                                            e
                                                                                ?.target
                                                                                .value,
                                                                    }
                                                                );
                                                            }
                                                        }}
                                                        value={
                                                            SelectedAccount ===
                                                            ""
                                                                ? siret
                                                                : SelectedAccount?.siret
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            {/* numero tva */}
                                            <div className="py-2">
                                                <Labell
                                                    lab="Numéro TVA**"
                                                    id="inputNumeroTVA"
                                                ></Labell>
                                                <div>
                                                    <Input
                                                        style={{
                                                            width: "98%",
                                                            pointerEvents:
                                                                filtreTableAccount ===
                                                                "Archivé"
                                                                    ? "none"
                                                                    : "auto",
                                                            opacity:
                                                                filtreTableAccount ===
                                                                "Archivé"
                                                                    ? "0.8"
                                                                    : "",
                                                        }}
                                                        className="form-control"
                                                        id="inputNumeroTva"
                                                        name="NumeroTva"
                                                        placeholder="Saisir"
                                                        type="text"
                                                        onChange={(e) => {
                                                            setNumerotva(
                                                                e.target.value
                                                            );
                                                            if (
                                                                SelectedAccount !==
                                                                ""
                                                            ) {
                                                                setSelectedAccount(
                                                                    {
                                                                        ...SelectedAccount,
                                                                        vat:
                                                                            e
                                                                                ?.target
                                                                                .value,
                                                                    }
                                                                );
                                                            }
                                                        }}
                                                        value={
                                                            SelectedAccount ===
                                                            ""
                                                                ? numerotva
                                                                : SelectedAccount?.vat
                                                        }
                                                    />
                                                </div>
                                            </div>

                                            {/* code ape */}
                                            <div className="py-2">
                                                <Labell
                                                    lab="Code APE**"
                                                    id="inputCodeAPE"
                                                ></Labell>
                                                <div>
                                                    <Input
                                                        style={{
                                                            width: "98%",
                                                            pointerEvents:
                                                                filtreTableAccount ===
                                                                "Archivé"
                                                                    ? "none"
                                                                    : "auto",
                                                            opacity:
                                                                filtreTableAccount ===
                                                                "Archivé"
                                                                    ? "0.8"
                                                                    : "",
                                                        }}
                                                        className="form-control"
                                                        id="inputCodeAPE"
                                                        name="CodeAPE"
                                                        placeholder="Saisir"
                                                        type="text"
                                                        onChange={(e) => {
                                                            if (
                                                                SelectedAccount !==
                                                                ""
                                                            ) {
                                                                setSelectedAccount(
                                                                    {
                                                                        ...SelectedAccount,
                                                                        codeape:
                                                                            e
                                                                                ?.target
                                                                                .value,
                                                                    }
                                                                );
                                                            }
                                                            setCodeape(
                                                                e.target.value
                                                            );
                                                        }}
                                                        value={
                                                            SelectedAccount ===
                                                            ""
                                                                ? codeape
                                                                : SelectedAccount?.codeape
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            {/*Adresse mail*/}
                                            <div className="py-2">
                                                <Labell
                                                    lab="Adresse e-mail"
                                                    id="inputAdresseEmail"
                                                ></Labell>
                                                <div>
                                                    <Input
                                                        style={{
                                                            width: "98%",
                                                            pointerEvents:
                                                                filtreTableAccount ===
                                                                "Archivé"
                                                                    ? "none"
                                                                    : "auto",
                                                            opacity:
                                                                filtreTableAccount ===
                                                                "Archivé"
                                                                    ? "0.8"
                                                                    : "",
                                                        }}
                                                        className="form-control"
                                                        id="inputAdresseEmail"
                                                        name="AdresseEmail"
                                                        placeholder="Saisir"
                                                        type="email"
                                                        onChange={(e) => {
                                                            setEmailEntity(
                                                                e.target.value
                                                            );
                                                            if (
                                                                SelectedAccount !==
                                                                ""
                                                            ) {
                                                                setSelectedAccount(
                                                                    {
                                                                        ...SelectedAccount,
                                                                        mail:
                                                                            e
                                                                                ?.target
                                                                                .value,
                                                                    }
                                                                );
                                                            }
                                                        }}
                                                        value={
                                                            SelectedAccount ===
                                                            ""
                                                                ? emailEntity
                                                                : SelectedAccount?.mail
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="py-2">
                                                <Labell
                                                    lab="Site Web"
                                                    id="inputSiteWeb"
                                                ></Labell>
                                                <div>
                                                    <Input
                                                        style={{
                                                            width: "98%",
                                                            pointerEvents:
                                                                filtreTableAccount ===
                                                                "Archivé"
                                                                    ? "none"
                                                                    : "auto",
                                                            opacity:
                                                                filtreTableAccount ===
                                                                "Archivé"
                                                                    ? "0.8"
                                                                    : "",
                                                        }}
                                                        className="form-control"
                                                        id="inputSiteWeb"
                                                        name="Site Web"
                                                        placeholder="Saisir"
                                                        type="text"
                                                        onChange={(e) => {
                                                            setSiteWeb(
                                                                e.target.value
                                                            );
                                                            if (
                                                                SelectedAccount !==
                                                                ""
                                                            ) {
                                                                setSelectedAccount(
                                                                    {
                                                                        ...SelectedAccount,
                                                                        SiteWeb:
                                                                            e
                                                                                ?.target
                                                                                .value,
                                                                    }
                                                                );
                                                            }
                                                        }}
                                                        value={
                                                            SelectedAccount ===
                                                            ""
                                                                ? siteWeb
                                                                : SelectedAccount?.SiteWeb
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className="my-2"
                                                style={{
                                                    display: "grid",
                                                    gridTemplateColumns:
                                                        " 1fr 0.15fr",
                                                    gap: "10px",
                                                    placeSelf: "center",
                                                }}
                                            >
                                                <Labell
                                                    lab="Informations avancées"
                                                    id="inputInformationsAvances"
                                                    color=" #2c73ef"
                                                ></Labell>
                                                <img
                                                    src={settingActiveIcon}
                                                    role="button"
                                                    id="settingActiveBtton"
                                                    aria-expanded="false"
                                                    alt="settingActiveDemo"
                                                    onClick={() =>
                                                        setIsAdvancedInformationModalOpened(
                                                            !isAdvancedInformationModalOpened
                                                        )
                                                    }
                                                />
                                            </div>
                                            <hr
                                                style={{ marginRight: "12px" }}
                                            />
                                            <Labell
                                                lab="Contacts administrateur du compte"
                                                id="inputCompteAdministrateurCompte"
                                            ></Labell>
                                            {/* nom */}
                                            <div className="py-2">
                                                <Labell
                                                    lab="Nom*"
                                                    id="inputNom"
                                                ></Labell>
                                                <div>
                                                    <Input
                                                        style={{
                                                            width: "98%",
                                                            pointerEvents:
                                                                filtreTableAccount ===
                                                                "Archivé"
                                                                    ? "none"
                                                                    : "auto",
                                                            opacity:
                                                                filtreTableAccount ===
                                                                "Archivé"
                                                                    ? "0.8"
                                                                    : "",
                                                        }}
                                                        className="form-control"
                                                        id="inputNom"
                                                        name="Nom"
                                                        placeholder="Saisir"
                                                        type="text"
                                                        onChange={(e) => {
                                                            if (
                                                                SelectedAccount !==
                                                                ""
                                                            ) {
                                                                setSelectedAccount(
                                                                    {
                                                                        ...SelectedAccount,
                                                                        Name:
                                                                            e
                                                                                ?.target
                                                                                .value,
                                                                    }
                                                                );
                                                            }
                                                            setNom(
                                                                e.target.value
                                                            );
                                                        }}
                                                        value={
                                                            SelectedAccount ===
                                                            ""
                                                                ? nom
                                                                : SelectedAccount?.Name
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            {/* prenom */}
                                            <div className="py-2">
                                                <Labell
                                                    lab="Prénom*"
                                                    id="inputPrenom"
                                                ></Labell>
                                                <div>
                                                    <Input
                                                        style={{
                                                            width: "98%",
                                                            pointerEvents:
                                                                filtreTableAccount ===
                                                                "Archivé"
                                                                    ? "none"
                                                                    : "auto",
                                                            opacity:
                                                                filtreTableAccount ===
                                                                "Archivé"
                                                                    ? "0.8"
                                                                    : "",
                                                        }}
                                                        className="form-control"
                                                        id="inputPrenom"
                                                        name="Prenom"
                                                        placeholder="Saisir"
                                                        type="text"
                                                        onChange={(e) => {
                                                            if (
                                                                SelectedAccount !==
                                                                ""
                                                            ) {
                                                                setSelectedAccount(
                                                                    {
                                                                        ...SelectedAccount,
                                                                        firstName:
                                                                            e
                                                                                ?.target
                                                                                .value,
                                                                    }
                                                                );
                                                            }
                                                            setPrenom(
                                                                e.target.value
                                                            );
                                                        }}
                                                        value={
                                                            SelectedAccount ===
                                                            ""
                                                                ? prenom
                                                                : SelectedAccount?.firstName
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            {/* Profil */}
                                            <div
                                                className="py-2"
                                                style={{
                                                    marginRight: "10px",
                                                }}
                                            >
                                                <Labell
                                                    lab="Profil*"
                                                    id="inputProfil"
                                                ></Labell>
                                                <div className="css-b62m3t-container">
                                                    <StyledSelectInput
                                                        name="Profil"
                                                        id="inputProfil"
                                                        options={optionsProfil}
                                                        isDisabled={
                                                            filtreTableAccount ===
                                                            "Archivé"
                                                                ? true
                                                                : false
                                                        }
                                                        onChange={(e: any) => {
                                                            if (
                                                                e.label ===
                                                                "(Nouveau profil administrateur du compte)"
                                                            ) {
                                                                setIsProfilAdministrateurModalOpened(
                                                                    !isProfilAdministrateurModalOpened
                                                                );
                                                            } else {
                                                                setProfil(
                                                                    e.value
                                                                );
                                                                setProfilName(
                                                                    e.label
                                                                );

                                                                if (
                                                                    SelectedAccount !==
                                                                    ""
                                                                ) {
                                                                    setSelectedAccount(
                                                                        {
                                                                            ...SelectedAccount,
                                                                            Nature:
                                                                                e.value,
                                                                            userProfilName:
                                                                                e.label,
                                                                            userProfil:
                                                                                e.value,
                                                                        }
                                                                    );
                                                                }
                                                            }
                                                        }}
                                                        // defaultValue={
                                                        //     SelectedAccount ===
                                                        //     ""
                                                        //         ? profil === ""
                                                        //             ? ""
                                                        //             : {
                                                        //                   label: profilName,
                                                        //                   value: profil,
                                                        //               }
                                                        //         : {
                                                        //               label:
                                                        //                   SelectedAccount.userProfilName,
                                                        //               value:
                                                        //                   SelectedAccount.userProfil,
                                                        //           }
                                                        // }
                                                        value={
                                                            SelectedAccount ===
                                                            ""
                                                                ? profil === ""
                                                                    ? ""
                                                                    : {
                                                                          label: profilName,
                                                                          value: profil,
                                                                      }
                                                                : {
                                                                      label:
                                                                          SelectedAccount.userProfilName,
                                                                      value:
                                                                          SelectedAccount.userProfil,
                                                                  }
                                                        }
                                                        placeholder={
                                                            "Sélectionner"
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            {/* email */}
                                            <div className="py-2">
                                                <Labell
                                                    lab="Adresse e-mail*"
                                                    id="inputEmail"
                                                ></Labell>
                                                <div>
                                                    <Input
                                                        style={{
                                                            width: "98%",
                                                            pointerEvents:
                                                                filtreTableAccount ===
                                                                "Archivé"
                                                                    ? "none"
                                                                    : "auto",
                                                            opacity:
                                                                filtreTableAccount ===
                                                                "Archivé"
                                                                    ? "0.8"
                                                                    : "",
                                                        }}
                                                        className="form-control"
                                                        id="inputEmail"
                                                        name="Email"
                                                        placeholder="Saisir"
                                                        type="email"
                                                        onChange={(e) => {
                                                            setEmail(
                                                                e.target.value
                                                            );
                                                            if (
                                                                SelectedAccount !==
                                                                ""
                                                            ) {
                                                                setSelectedAccount(
                                                                    {
                                                                        ...SelectedAccount,
                                                                        userEmail:
                                                                            e
                                                                                ?.target
                                                                                .value,
                                                                    }
                                                                );
                                                            }
                                                        }}
                                                        value={
                                                            SelectedAccount ===
                                                            ""
                                                                ? email
                                                                : SelectedAccount?.userEmail
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            {/* portable 1 - 2 */}
                                            <div className="d-flex flex-column my-2">
                                                <div
                                                    className="d-flex"
                                                    style={{ gap: "136px" }}
                                                >
                                                    <Labell
                                                        lab="N° Téléphone 1"
                                                        id="inputMobile1"
                                                    ></Labell>
                                                    <Labell
                                                        lab="N° Téléphone 2"
                                                        id="inputMobile2"
                                                    ></Labell>
                                                </div>
                                                <div
                                                    className="d-flex align-items-center justify-content-between"
                                                    style={{ gap: "20px" }}
                                                >
                                                    <div>
                                                        <PhoneInput
                                                            buttonClass="ctl_btn_firstPhoneNumber"
                                                            inputClass="ctl_inp_firstPhoneNumber"
                                                            country={
                                                                selectedCountryCode as string
                                                            }
                                                            disabled={
                                                                filtreTableAccount ===
                                                                "Archivé"
                                                                    ? true
                                                                    : false
                                                            }
                                                            onChange={(
                                                                e: any
                                                            ) => {
                                                                setPortable(e);
                                                                if (
                                                                    SelectedAccount !==
                                                                    ""
                                                                ) {
                                                                    setSelectedAccount(
                                                                        {
                                                                            ...SelectedAccount,
                                                                            Portable: e,
                                                                        }
                                                                    );
                                                                }
                                                            }}
                                                            value={
                                                                SelectedAccount ===
                                                                ""
                                                                    ? portable
                                                                    : SelectedAccount?.Portable
                                                            }
                                                            placeholder={t(
                                                                "Saisir"
                                                            )}
                                                        />
                                                    </div>

                                                    <div
                                                        style={{
                                                            marginRight: "10px",
                                                        }}
                                                    >
                                                        <PhoneInput
                                                            buttonClass="ctl_btn_secondPhoneNumber"
                                                            inputClass="ctl_inp_secondPhoneNumber "
                                                            country={
                                                                selectedCountryCode as string
                                                            }
                                                            disabled={
                                                                filtreTableAccount ===
                                                                "Archivé"
                                                                    ? true
                                                                    : false
                                                            }
                                                            onChange={(
                                                                e: any
                                                            ) => {
                                                                setPortable1(e);
                                                                if (
                                                                    SelectedAccount !==
                                                                    ""
                                                                ) {
                                                                    setSelectedAccount(
                                                                        {
                                                                            ...SelectedAccount,
                                                                            Portable1: e,
                                                                        }
                                                                    );
                                                                }
                                                            }}
                                                            value={
                                                                SelectedAccount ===
                                                                ""
                                                                    ? portable1
                                                                    : SelectedAccount?.Portable1
                                                            }
                                                            placeholder={t(
                                                                "Saisir"
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="py-2">
                                                <hr
                                                    style={{
                                                        marginRight: "12px",
                                                        // width:"100%"
                                                    }}
                                                />
                                                <StyledLabel
                                                    style={{
                                                        fontSize: "12px",
                                                        marginTop: "0px",
                                                    }}
                                                >
                                                    ** Lorsque une ou plusieurs
                                                    de ces informations sont
                                                    manquantes, le compte se met
                                                    en "anomalie" automatiqe
                                                    avec le motif "infos
                                                    obligatoires manquantes".
                                                </StyledLabel>
                                                <StyledLabel
                                                    style={{ fontSize: "12px" }}
                                                >
                                                    ** Si 30 jours passent et
                                                    les infos ne sont pas
                                                    complétées, le compte sera
                                                    bloqué automatiquement.
                                                </StyledLabel>
                                            </div>
                                        </div>
                                    </CardBody>

                                    <CardFooter
                                        className="card-footer d-flex align-items-center justify-content-end"
                                        style={{
                                            background: "#FFFFFF",

                                            height: "56px",
                                        }}
                                    >
                                        <StyledButton
                                            disabled={false}
                                            rounded
                                            variant="light"
                                            outline={true}
                                            size="sm"
                                            onClick={() => {
                                                setIsHidden(!isHidden);
                                                initializeData();
                                            }}
                                        >
                                            Annuler
                                        </StyledButton>

                                        <StyledButton
                                            className="ml-2"
                                            disabled={
                                                finallyResult === true
                                                    ? filtreTableAccount ===
                                                      "Archivé"
                                                        ? true
                                                        : false
                                                    : true
                                            }
                                            rounded
                                            variant="primary"
                                            outline={false}
                                            size="sm"
                                            onClick={() => {
                                                if (SelectedAccount === "") {
                                                    addAccount();
                                                } else {
                                                    UpdateAccount();
                                                }
                                            }}
                                        >
                                            Valider
                                        </StyledButton>
                                    </CardFooter>
                                </Card>

                                <ConfigurationDemoModal
                                    isConfigurationModalOpened={
                                        isConfigurationModalOpened
                                    }
                                    setIsConfigurationModalOpened={
                                        setIsConfigurationModalOpened
                                    }
                                    setConfigurationDemo={setConfigurationDemo}
                                    ConfigurationBeforeUpdate={
                                        ConfigurationBeforeUpdate
                                    }
                                    setConfigurationBeforeUpdate={
                                        setConfigurationBeforeUpdate
                                    }
                                    setDemo={setDemo}
                                />
                                <AdvancedInformationModal
                                    isAdvancedInformationModalOpened={
                                        isAdvancedInformationModalOpened
                                    }
                                    setIsAdvancedInformationModalOpened={
                                        setIsAdvancedInformationModalOpened
                                    }
                                    schemaFranchise={schemaFranchise}
                                    uuidFranchise={uuidFranchise}
                                    uuidStore={uuidStore}
                                    idStore={idStore}
                                    setSchemaFranchise={setSchemaFranchise}
                                    setUuidFranchise={setUuidFranchise}
                                    setUuidStore={setUuidStore}
                                    setIdStore={setIdStore}
                                    ListeSchemaFranchise={ListeSchemaFranchise}
                                    setFranchiseName={setFranchiseName}
                                    franchiseName={franchiseName}
                                    setStoreName={setStoreName}
                                    StoreName={StoreName}
                                    typeAccount={typeAccount}
                                    filtreTableAccount={filtreTableAccount}
                                />
                                <ProfilAdministrateurModal
                                    isModalOpened={
                                        isProfilAdministrateurModalOpened
                                    }
                                    setIsModalOpened={
                                        setIsProfilAdministrateurModalOpened
                                    }
                                    setProfil={setProfil}
                                    setProfilName={setProfilName}
                                />
                                <ConfirmationModal
                                    isCofirmationModalOpened={
                                        isCofirmationModalOpened
                                    }
                                    setIsConfirmationModalOpened={
                                        setIsConfirmationModalOpened
                                    }
                                    setAnomalie={setAnomalie}
                                    setMotifAnnomalie={setMotifAnnomalie}
                                    typeConfirmation={typeConfirmation}
                                    setDemo={setDemo}
                                    setConfigurationDemo={setConfigurationDemo}
                                />
                            </React.Fragment>
                        ) : null}
                    </div>

                    <MotifModal
                        setMotif={() => {}}
                        isMotifModalOpened={isMotifModalOpened}
                        setIsMotifModalOpened={setIsMotifModalOpened}
                        typeMotif={typeMotif}
                        setMotifAnnomalie={setMotifAnnomalie}
                        Action="CompteAnomalie"
                        setDataIsUpdated={setDataIsUpdated}
                        dataIsUpdated={dataIsUpdated}
                        selectedRows={[]}
                        etatProfil=""
                        setIsConfigurationBlocageModalOpened={
                            setIsConfigurationBlocageModalOpened
                        }
                        isConfigurationBlocageModalOpened={
                            isConfigurationBlocageModalOpened
                        }
                        setAnomalie={setAnomalie}
                        // setDataProfilIsUpdated={setDataIsUpdated}
                    />
                    <ConsultLicencesModal
                        isAlertConsultLicencesModal={
                            isAlertConsultLicencesModal
                        }
                        setIsAlertConsultLicencesModal={
                            setIsAlertConsultLicencesModal
                        }
                        setIsClick={setIsClick}
                        isClick={false}
                    />
                </CardBody>
            </Container>
            {/* </Card> */}
        </React.Fragment>
    );
}
