import React, { useState } from "react";
import {
    StyledButton,
    StyledModal,
    CrossIcon,
    StyledIconButton,
    StyledLabel,
} from "@aureskonnect/react-ui";
import { ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import "./index.css";
import Form from "react-bootstrap/Form";
import { CFormCheck } from "@coreui/bootstrap-react";
import { MotifModal } from "@pages/Motifs/MotifLicensesModal";
import { IoMdCalendar } from "react-icons/io";
import { setConfigurationBloqueAccount } from "../../pages/store";
import "react-datepicker/dist/react-datepicker.css";

import { DateHeureCalendarModal } from "@pages/Calendar/DateHeureCalendarModal";

type ConfigurationBlocageModalType = {
    isConfigurationBlocageModalOpened: boolean;
    setIsConfigurationBlocageModalOpened: React.Dispatch<
        React.SetStateAction<boolean>
    >;
    setDataIsUpdated: any;
    dataIsUpdated: any;
};

export function ConfigurationBlocageModal({
    isConfigurationBlocageModalOpened,
    setIsConfigurationBlocageModalOpened,
    setDataIsUpdated,
    dataIsUpdated,
}: ConfigurationBlocageModalType) {
    const [blocage, setBlocage] = React.useState<boolean>(false);
    const [blocagePriseCommande, setBlocagePriseCommande] = React.useState<
        boolean
    >(false);
    const [isMotifModalOpened, setIsMotifModalOpened] = React.useState<boolean>(
        false
    );
    const [typeMotif] = React.useState<Number>(0);

    const [
        isDateHeureCalendarModalOpened,
        setIsDateHeureCalendarModalOpened,
    ] = React.useState<boolean>(false);
    const [fullDate, setFullDate] = useState<string>("");
    const [dateHeure, setDateHeure] = useState<string>("");
    const [statusBlocage, setStatusBlocage] = React.useState<string>("");
    //     false
    // );
    const emptyFields = () => {
        setBlocage(false);
        setBlocagePriseCommande(false);
        setStatusBlocage("");
    };

    function handleClickValidate() {
        let configurationBloqueAccount: any = {
            statusBlocage: statusBlocage,
            BlocageDefinitif: blocage,
            BlocagePriseCommande: blocagePriseCommande,
            planification: dateHeure, //planifyPeriod,
        };
        setConfigurationBloqueAccount(configurationBloqueAccount);
    }
    return (
        <div>
            <StyledModal
                className="configuration-modal-style"
                centered
                isOpen={isConfigurationBlocageModalOpened}
            >
                <ModalHeader className="d-flex align-items-center">
                    <div
                        className="d-flex align-items-center justify-content-between "
                        style={{
                            height: "35px",
                            display: "grid",
                            gridTemplateColumns: "1.8fr 0.2fr",
                            gridTemplateRows: "1fr",
                            gap: "365px",
                        }}
                    >
                        <p className="title-text-style">
                            PARAMÉTRAGE DE BLOCAGE
                        </p>
                        <StyledIconButton icon="CrossIcon">
                            <CrossIcon
                                cursor="pointer"
                                height={10}
                                width={10}
                                onClick={() => {
                                    setIsConfigurationBlocageModalOpened(
                                        !isConfigurationBlocageModalOpened
                                    );
                                    emptyFields();
                                }}
                            />
                        </StyledIconButton>
                    </div>
                </ModalHeader>

                <ModalBody>
                    <fieldset
                        className="border p-3 title-legend.style"
                        style={{ width: "760px" }}
                    >
                        <legend className="w-auto">
                            Remplissez les champs
                        </legend>
                        <>
                            <Form>
                                <div
                                    className="row "
                                    style={{
                                        display: "grid",
                                        gridTemplateColumns: "1fr 1fr",
                                        gridTemplateRows: "1fr",
                                        marginTop: "10px",
                                        marginLeft: "20px",
                                    }}
                                >
                                    <div
                                        style={{
                                            position: "relative",
                                        }}
                                    >
                                        <div className="text-legend-style">
                                            Blocage définitif
                                        </div>

                                        <div className="text-legend-style">
                                            Blocage prise de commande
                                        </div>
                                    </div>
                                    <div
                                        className="mb-3"
                                        style={{ marginLeft: "70%" }}
                                    >
                                        <CFormCheck
                                            type="radio"
                                            name="flexRadioDefault"
                                            id="flexRadioDefault1"
                                            label="               "
                                            onChange={() => {
                                                setBlocage(!blocage);
                                            }}
                                        />
                                        <CFormCheck
                                            type="radio"
                                            name="flexRadioDefault"
                                            id="flexRadioDefault2"
                                            label="           "
                                            onChange={() => {
                                                setBlocagePriseCommande(
                                                    !blocagePriseCommande
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                                <hr />
                                <div
                                    className="row "
                                    style={{
                                        display: "grid",
                                        gridTemplateColumns:
                                            "1fr 1fr 1.375fr 0.625fr",
                                        gridTemplateRows: "1fr",
                                        marginTop: "10px",
                                        marginLeft: "20px",
                                    }}
                                >
                                    <CFormCheck
                                        type="radio"
                                        name="flexRadioDefaultStatusBloc"
                                        id="BlocImmediat"
                                        label="Blocage Immédiat"
                                        onChange={() => {
                                            setStatusBlocage("I");
                                            setDateHeure("");
                                        }}
                                    />
                                    <CFormCheck
                                        type="radio"
                                        name="flexRadioDefaultStatusBloc"
                                        id="BlocPlanifie"
                                        label="Blocage planifié le:"
                                        onChange={() => {
                                            setStatusBlocage("P");
                                        }}
                                    />
                                    <StyledLabel>{fullDate}</StyledLabel>
                                    <IoMdCalendar
                                        style={{
                                            height: "30px",
                                            width: "30px",
                                            marginBottom: "10px",
                                            pointerEvents:
                                                statusBlocage === "P"
                                                    ? "auto"
                                                    : "none",
                                            opacity:
                                                statusBlocage === "P"
                                                    ? ""
                                                    : "0.8",
                                        }}
                                        onClick={() => {
                                            if (statusBlocage === "P") {
                                                setIsDateHeureCalendarModalOpened(
                                                    true
                                                );
                                            }
                                        }}
                                    />
                                </div>
                            </Form>
                        </>
                    </fieldset>
                </ModalBody>

                <ModalFooter
                    className="d-flex justify-content-end"
                    style={{
                        background: "#FFFFFF",
                    }}
                >
                    <div
                        className="d-flex align-items-center"
                        style={{ gap: "30px" }}
                    >
                        <div className="col-4">
                            <StyledButton
                                disabled={false}
                                rounded
                                variant="light"
                                outline={true}
                                size="sm"
                                style={{
                                    background: "white",
                                    color: "black",
                                    padding: "0px",
                                    width: "150px",
                                    height: "45px",
                                }}
                                onClick={() => {
                                    setIsConfigurationBlocageModalOpened(
                                        !isConfigurationBlocageModalOpened
                                    );
                                    emptyFields();
                                }}
                            >
                                Annuler
                            </StyledButton>
                        </div>
                        <div className="col-4">
                            <StyledButton
                                disabled={false}
                                rounded
                                variant="primary"
                                outline={false}
                                size="sm"
                                style={{
                                    color: "white",
                                    padding: "0px",
                                    width: "150px",
                                    height: "45px",
                                }}
                                onClick={() => {
                                    handleClickValidate();
                                    setIsMotifModalOpened(!isMotifModalOpened);
                                }}
                            >
                                Valider
                            </StyledButton>
                        </div>
                        <MotifModal
                            setMotif={() => {}}
                            isMotifModalOpened={isMotifModalOpened}
                            setIsMotifModalOpened={setIsMotifModalOpened}
                            typeMotif={typeMotif}
                            setMotifAnnomalie={() => {}}
                            Action={"Blocage"}
                            setDataIsUpdated={setDataIsUpdated}
                            dataIsUpdated={dataIsUpdated}
                            setIsConfigurationBlocageModalOpened={
                                setIsConfigurationBlocageModalOpened
                            }
                            isConfigurationBlocageModalOpened={
                                isConfigurationBlocageModalOpened
                            }
                            setAnomalie={() => {}}
                        />
                        <DateHeureCalendarModal
                            isDateHeureCalendarModalOpened={
                                isDateHeureCalendarModalOpened
                            }
                            setIsDateHeureCalendarModalOpened={
                                setIsDateHeureCalendarModalOpened
                            }
                            setFullDate={setFullDate}
                            setDateHeure={setDateHeure}
                        />
                    </div>
                </ModalFooter>
            </StyledModal>
        </div>
    );
}
