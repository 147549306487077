import React, { useState } from "react";

import {
    InboxIcon,
    TrashIcon,
    VerticalDotsIcon,
    EyeIcon,
    DocumentIcon,
} from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import {
    setIsAlerteTypeGestionnaireDeComptesModalOpened,
    setIsHitoryAllTypeGestionnaireDeComptes,
    store,
    setUuidTypeGestionnaireDeCompteHistory,
} from "@pages/store";
import { showToast } from "@components/Common/ToastShow";
import AlertTypeGestionnaireDeComptesModal from "./AlertTypeGestionnaireDeComptesModal";

type ProductsActionMenuType = {
    setDataIsUpdated: React.Dispatch<React.SetStateAction<number | boolean>>;
    dataIsUpdated: number | boolean;
    selectedRows?: any;
    setIsTypeGetstionnaireDeComptesState: React.Dispatch<
        React.SetStateAction<boolean>
    >;
    isTypeGetstionnaireDeComptesState: boolean;
    isHisotyTypeGestionnaireDeComptesModalOpened: boolean;
    setIsHisotyTypeGestionnaireDeComptesModalOpened: React.Dispatch<
        React.SetStateAction<boolean>
    >;
};

export default function TypeGestionnaireDeComptesActionMenu({
    setDataIsUpdated,
    dataIsUpdated,
    selectedRows,
    setIsTypeGetstionnaireDeComptesState,
    isTypeGetstionnaireDeComptesState,
    isHisotyTypeGestionnaireDeComptesModalOpened,
    setIsHisotyTypeGestionnaireDeComptesModalOpened,
}: ProductsActionMenuType) {
    const { t } = useTranslation();
    const uuidEntity = localStorage.getItem("Uid_Entitie_User");
    const [
        listSelectedtypeGestionnaireDeComptes,
        setListSelectedtypeGestionnaireDeComptes,
    ] = useState([]);
    const [
        nombreTypeGestionnaireDeCompteArchive,
        setNombreTypeGestionnaireDeCompteArchive,
    ] = useState(0);
    const [
        nombreTypeGestionnaireDeCompteActive,
        setNombreTypeGestionnaireDeCompteActive,
    ] = useState(0);

    const { isAlerteTypeGestionnaireDeComptesModalOpened } = useSnapshot(store);
    let List: any = [];
    let ListExistTypeGestionnaireDeCompte: any = [];
    let listExist = false;
    const numberArchivesTypeGestionnaireDeCompteArchive = () => {
        fetch(
            `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/getCountTypegestionnaireDeComptes?&action=true&uuidEntity=${uuidEntity}`
        )
            .then((response) => {
                response.json().then((data) => {
                    setNombreTypeGestionnaireDeCompteArchive(
                        Number(data.count)
                    );
                });
            })
            .catch((e: any) => {
                console.log({ error: e.message });
            });
    };

    const numberActivesTypeGestionnaireDeCompteArchive = () => {
        fetch(
            `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/getCountTypegestionnaireDeComptes?&action=false&uuidEntity=${uuidEntity}`
        )
            .then((response) => {
                response.json().then((data) => {
                    setNombreTypeGestionnaireDeCompteActive(Number(data.count));
                });
            })
            .catch((e: any) => {
                console.log({ error: e.message });
            });
    };

    function handleArchiveActiveTypeGestionnaireDeComptes() {
        for (let i = 0; i < selectedRows.length; i++) {
            List.push(selectedRows[i].uuid);
            ListExistTypeGestionnaireDeCompte.push(selectedRows[i].nouveau);
        }
        setListSelectedtypeGestionnaireDeComptes(List);
        if (List.length < 2) {
            showToast(
                false,
                `${t(
                    "Attention, it is a multiple action, you must select at least two elements."
                )}`
            );
        } else {
            if (ListExistTypeGestionnaireDeCompte.includes(listExist)) {
                showToast(
                    false,
                    `${t(
                        "Attention. this action is impossible on the 'Franchise' type of account manager"
                    )}`
                );
            } else {
                setIsAlerteTypeGestionnaireDeComptesModalOpened(
                    !isAlerteTypeGestionnaireDeComptesModalOpened
                );
            }
        }
    }

    function showHistory() {
        let ListHistory: any = [];
        for (let i = 0; i < selectedRows.length; i++) {
            ListHistory.push(selectedRows[i].uuid);
            ListExistTypeGestionnaireDeCompte.push(selectedRows[i].nouveau);
        }
        setUuidTypeGestionnaireDeCompteHistory(ListHistory);
        if (ListHistory.length < 2) {
            showToast(
                false,
                `${t(
                    "Attention, it is a multiple action, you must select at least two elements."
                )}`
            );
        } else {
            if (ListExistTypeGestionnaireDeCompte.includes(listExist)) {
                showToast(
                    false,
                    `${t(
                        "Attention. this action is impossible on the 'Franchise' type of account manager"
                    )}`
                );
            } else {
                setIsHitoryAllTypeGestionnaireDeComptes(true);
                setIsHisotyTypeGestionnaireDeComptesModalOpened(
                    !isHisotyTypeGestionnaireDeComptesModalOpened
                );
            }
        }
    }

    React.useEffect(() => {
        numberArchivesTypeGestionnaireDeCompteArchive();
        numberActivesTypeGestionnaireDeCompteArchive();
        // eslint-disable-next-line
    }, [selectedRows]);

    return (
        <>
            <div className="dropdown ml-2  d-none d-sm-block">
                <VerticalDotsIcon
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    cursor="pointer"
                    height={25}
                    width={25}
                />

                <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                >
                    {isTypeGetstionnaireDeComptesState ? (
                        <div
                            className="dropdown-item"
                            role="button"
                            tabIndex={0}
                            onClick={() => {
                                setIsTypeGetstionnaireDeComptesState(false);
                            }}
                            style={{
                                cursor: "pointer",
                                pointerEvents:
                                    nombreTypeGestionnaireDeCompteArchive === 0
                                        ? "none"
                                        : "auto",
                                opacity:
                                    nombreTypeGestionnaireDeCompteArchive === 0
                                        ? "0.4"
                                        : "",
                            }}
                        >
                            <InboxIcon
                                className="mr-2"
                                height={25}
                                width={25}
                            />

                            {t(
                                `${t(
                                    "Archived Account Manager Type(s)"
                                )} (${nombreTypeGestionnaireDeCompteArchive})`
                            )}
                        </div>
                    ) : (
                        <div
                            className="dropdown-item"
                            role="button"
                            tabIndex={0}
                            onClick={() => {
                                setIsTypeGetstionnaireDeComptesState(true);
                            }}
                            style={{
                                cursor: "pointer",
                            }}
                        >
                            <InboxIcon
                                className="mr-2"
                                height={25}
                                width={25}
                            />
                            {`${t(
                                "Account manager type(s) enabled"
                            )}  (${nombreTypeGestionnaireDeCompteActive})`}
                        </div>
                    )}

                    {isTypeGetstionnaireDeComptesState ? (
                        <div
                            className="dropdown-item"
                            role="button"
                            tabIndex={0}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                setIsTypeGetstionnaireDeComptesState(true);
                                handleArchiveActiveTypeGestionnaireDeComptes();
                            }}
                        >
                            <TrashIcon
                                className="mr-2"
                                height={25}
                                width={25}
                                style={{ fill: "red" }}
                            />
                            {t("Archive account manager type")}
                        </div>
                    ) : (
                        <div
                            className="dropdown-item"
                            role="button"
                            tabIndex={0}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                setIsTypeGetstionnaireDeComptesState(false);
                                handleArchiveActiveTypeGestionnaireDeComptes();
                            }}
                        >
                            <EyeIcon height={25} width={25} className="mr-2" />
                            {t("Enable Account Manager Type(s)")}
                        </div>
                    )}

                    <div
                        className="dropdown-item"
                        role="button"
                        tabIndex={0}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            showHistory();
                        }}
                    >
                        <DocumentIcon height={25} width={25} className="mr-2" />
                        {t("History")}
                    </div>
                </div>
            </div>
            <AlertTypeGestionnaireDeComptesModal
                setIsAlerteTypeGestionnaireDeComptesModalOpened={
                    setIsAlerteTypeGestionnaireDeComptesModalOpened
                }
                setDataIsUpdated={setDataIsUpdated}
                dataIsUpdated={dataIsUpdated}
                archiveActiveTypeGestionnaireDeComptes={
                    isTypeGetstionnaireDeComptesState
                }
                selectedRows={selectedRows}
                ListTypeGestionnaireDeComptes={
                    listSelectedtypeGestionnaireDeComptes
                }
                setListSelectedtypeGestionnaireDeComptes={
                    setListSelectedtypeGestionnaireDeComptes
                }
            />
        </>
    );
}
