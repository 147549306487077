import React from "react";
// import { Label } from "reactstrap";
import { StyledLabel } from "@aureskonnect/react-ui";

function Labell(props: any) {
    return (
        <div>
            <StyledLabel
                style={{
                    fontSize: "16px",
                    color: props.id === "" ? "#2B2828" : props.color,
                    textAlign: "left",
                    font: "normal normal 600 16px/21px Segoe UI",
                    opacity: 1,
                }}
                className={props.class}
            >
                {props.lab}
            </StyledLabel>
            {/* <Label
                for={props.id}
                style={{
                    fontSize: "16px",
                    color: props.id === ""?"#2B2828":props.color,
                    textAlign: "left",
                    font: "normal normal 600 16px/21px Segoe UI",
                    opacity: 1,
                }}
                className={props.class}
            >
                {props.lab}
            </Label> */}
        </div>
    );
}

export default Labell;
