import { createSlice } from "@reduxjs/toolkit";

const initialState = { value: false };
export const articleSlice = createSlice({
    name: "article",
    initialState,
    reducers: {
        selectedArticle: (state: any, action: any) => {
            state.value = action.payload;
        },
    },
});

export const { selectedArticle } = articleSlice.actions;

export default articleSlice.reducer;
