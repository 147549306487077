import { proxy, subscribe } from "valtio";
type StoreType = {
    isHidden: boolean;
    SelectedAccount: any;
    filtreTableAccount: string;
    BtnValiderIsHidden: boolean;
    selectedMotif: any;
    selectedEntity: any;
    SelectedMotifBeforeUpdate: any;
    SelectedProduct: any;
    devise: string;
    SelectedProfil: any;
    isHitoryAllMotifs: boolean;
    isHitoryAllProducts: boolean;
    isHitoryAllTypeGestionnaireDeComptes: boolean;
    isActivesMotifs: boolean;
    isActivesProducts: boolean;
    configurationBloqueAccount: any;
    SelectedProfilBeforeUpdate: any;
    SelectedPack: any;
    SelectedPackBeforeUpdate: any;
    AllPackHistory: boolean;
    isLicenceDemoConfiguration: boolean;
    isDemo: any;
    archiveActivePacks: boolean;
    alerteMessagePack: string;
    isAlerteModalOpened: boolean;
    archiveActiveProduct: boolean;
    isAlertePackModalOpened: boolean;
    AssociatedProducts: any;
    associatedDemoLicence: any;
    associatedDemoLicenceToUpdate: any;
    selectedProductsToDissociate: boolean;
    selectedModulesToDissociate: boolean;
    refHistory: any;
    refHistoryProduct: any;
    uuidTypeGestionnaireDeCompteHistory: any;
    PackUpdated: any;
    selectedPack: any;
    selectedModuleBeforeUpdate: any;
    selectedProductBeforeUpdate: any;
    selectedTypeGestionnaireDeComptesBeforeUpdate: any;
    selectedLicenceDemo: any;
    archiveActiveModule: boolean;
    isAlerteModuleModalOpened: boolean;
    isAlerteProfileModalOpened: boolean;
    isAlerteTypeGestionnaireDeComptesModalOpened: boolean;
    isAlerteMotifModal: boolean;
    isAlerteDissociateModalOpened: boolean;
    isAlertSendCertificateLicenceWithMailOpened: boolean;
    isConfigurationLicenceModalOpened: boolean;
    isCommercialLicenceModalOpened: boolean;
    isAlerteDissociateModuleModalOpened: boolean;
    selectedModule: any;
    selectedTypeGestionnaireDeComptes: any;
    listRef: any;
    listRefPack: any;
    listRefModule: any;
    isActiveModule: boolean;
    numberProductToAssociate: any;
    isAlertupdateModal: boolean;
    isAlertupdateProductModal: boolean;
    isAlertupdateTypeGestionnaireDeCompteModal: boolean;
    modif: boolean;
    modifTypeGestionnaireDeComptes: boolean;
    modifProduct: boolean;
    isAssociateProductModalOpen: boolean;
    isAssociateModuleModalOpen: boolean;
    edit: string;
    editProduct: string;
    editTypeGestionnaireDeComptes: string;
    SelectedLicensesList: any;
    selectedLicences: any;
    selectedTypeEconomicModel: any;
    selectedExistLicenceDemo: any;
    selectedModuleToUpdate: any;
    selectedData: any;
    selectedModuleProductLicences: any;
    isVisible: boolean;
    isAccessConsult: boolean;
    isAccessEdit: boolean;
    isUpdateAccount: boolean;
    editActionProfile: boolean;
    isAlertConsultLicencesModal: boolean;
    isAlertGenerateCertificateModal: boolean;
    isAlertHistoryLicencesModal: boolean;
    isHistoryLicences: boolean;
    isValidateToGenerateCertificate: boolean;
    isUpdateInfoCertificate: boolean;
    uuidAssociatedProductsToPack: any;
    isActiveLicenseAlertOpen: boolean;
    isActiveLicenseAlertPackOpen: boolean;
    isActiveLicenseAlertModuleOpen: boolean;
    selectedProductsLicence: any;
    selectedModuleProductsLicence: any;
    selectedProductsAssociatedToPack: any;
    selectedOneProductAssociatedToPack: any;
    selectedOneModulelicense: any;
    isAffected: boolean;
    isAffectedModule: boolean;
    isAffectedPack: boolean;
    isUpdateCertificate: boolean;
    isHitoryAllProfile: boolean;
    historyProfile: any;
    usedProfile: any;
    usedProfileWidhoutEntity: any;
    usedaccountManagerType: any;
    usedaccountManagerTypeWidhoutEntity: any;
    refHistoryPack: any;
    isAlertAccountManagerTypeUsed: boolean;
    isAlertProfilUsed: boolean;
    updatedAutorisation: any;
    autorisationProfileEntityConnected: any;
    KeysLicenseBeforeUpdate: any;
    moduleLicense: any;
    licenseBeforeUpdate: any;
    functionAssociatePack: Function;
    isAssociateModuleProductToPackModalOpen: boolean;
    isModifyMultipleAuthorizationModalOpen: boolean;
    licenceToDebloq: any;
    produitLicence: string;
};

export const store = proxy<StoreType>({
    isHidden: false,
    SelectedAccount: "",
    filtreTableAccount: "Activé",
    BtnValiderIsHidden: false,
    selectedMotif: "",
    selectedEntity: "",
    SelectedMotifBeforeUpdate: "",
    SelectedProduct: "",
    devise: "€",
    SelectedProfil: "",
    isHitoryAllMotifs: false,
    isHitoryAllProducts: false,
    isHitoryAllTypeGestionnaireDeComptes: false,
    isActivesMotifs: true,
    isActivesProducts: true,
    configurationBloqueAccount: "",
    SelectedProfilBeforeUpdate: "",
    SelectedPack: "",
    SelectedPackBeforeUpdate: "",
    selectedModuleBeforeUpdate: "",
    selectedProductBeforeUpdate: "",
    selectedTypeGestionnaireDeComptesBeforeUpdate: "",
    selectedLicenceDemo: "",
    AllPackHistory: false,
    isLicenceDemoConfiguration: false,
    isDemo: {},
    archiveActivePacks: false,
    archiveActiveProduct: false,
    archiveActiveModule: false,
    alerteMessagePack: "",
    isAlerteModalOpened: false,
    isAlertePackModalOpened: false,
    isAlerteModuleModalOpened: false,
    isAlerteProfileModalOpened: false,
    isAlerteTypeGestionnaireDeComptesModalOpened: false,
    isAlerteMotifModal: false,
    isAlerteDissociateModalOpened: false,
    isAlertSendCertificateLicenceWithMailOpened: false,
    isConfigurationLicenceModalOpened: false,
    isCommercialLicenceModalOpened: false,
    isAlerteDissociateModuleModalOpened: false,
    AssociatedProducts: [],
    associatedDemoLicence: {},
    associatedDemoLicenceToUpdate: {},
    selectedProductsToDissociate: false,
    selectedModulesToDissociate: false,
    refHistory: [],
    refHistoryProduct: [],
    uuidTypeGestionnaireDeCompteHistory: [],
    PackUpdated: [],
    listRef: [],
    listRefPack: [],
    listRefModule: [],
    selectedPack: "",
    selectedModule: "",
    selectedTypeGestionnaireDeComptes: "",
    isActiveModule: true,
    numberProductToAssociate: 0,
    isAlertupdateModal: false,
    isAlertupdateProductModal: false,
    isAlertupdateTypeGestionnaireDeCompteModal: false,
    isAssociateProductModalOpen: false,
    isAssociateModuleModalOpen: false,
    modif: false,
    modifTypeGestionnaireDeComptes: false,
    modifProduct: false,
    edit: "",
    editProduct: "",
    editTypeGestionnaireDeComptes: "",
    SelectedLicensesList: [],
    selectedLicences: [],
    selectedTypeEconomicModel: [],
    selectedExistLicenceDemo: [],
    selectedModuleToUpdate: [],
    selectedData: {},
    selectedModuleProductLicences: [],
    isVisible: false,
    isAccessConsult: false,
    isAccessEdit: false,
    isUpdateAccount: false,
    editActionProfile: false,
    isAlertConsultLicencesModal: false,
    isAlertGenerateCertificateModal: false,
    isAlertHistoryLicencesModal: false,
    isHistoryLicences: false,
    isValidateToGenerateCertificate: false,
    isUpdateInfoCertificate: false,
    uuidAssociatedProductsToPack: [],
    isActiveLicenseAlertOpen: false,
    isActiveLicenseAlertPackOpen: false,
    isActiveLicenseAlertModuleOpen: false,
    selectedProductsLicence: [],
    selectedModuleProductsLicence: [],
    selectedProductsAssociatedToPack: [],
    selectedOneProductAssociatedToPack: [],
    selectedOneModulelicense: [],
    isAffected: false,
    isAffectedModule: false,
    isAffectedPack: false,
    isUpdateCertificate: false,
    isHitoryAllProfile: false,
    historyProfile: [],
    usedProfile: [],
    usedProfileWidhoutEntity: [],
    usedaccountManagerType: [],
    usedaccountManagerTypeWidhoutEntity: [],
    refHistoryPack: [],
    isAlertProfilUsed: false,
    isAlertAccountManagerTypeUsed: false,
    updatedAutorisation: {},
    autorisationProfileEntityConnected: {},
    KeysLicenseBeforeUpdate: [],
    moduleLicense: [],
    licenseBeforeUpdate: {},
    functionAssociatePack: () => { },
    isAssociateModuleProductToPackModalOpen: false,
    isModifyMultipleAuthorizationModalOpen: false,
    licenceToDebloq: [],
    produitLicence: "",
});

subscribe(store, () => {
    /*localStorage.setItem("isHidden", JSON.stringify(store.isHidden));
    localStorage.setItem(
        "SelectedAccount",
        JSON.stringify(store.SelectedAccount)
    );
    localStorage.setItem(
        "filtreTableAccount",
        JSON.stringify(store.filtreTableAccount)
    );
    localStorage.setItem(
        "BtnValiderIsHidden",
        JSON.stringify(store.BtnValiderIsHidden)
    );
    localStorage.setItem("devise", JSON.stringify(store.devise));
    localStorage.setItem(
        "SelectedProfil",
        JSON.stringify(store.SelectedProfil)
    );
    localStorage.setItem("selectedMotif", JSON.stringify(store.selectedMotif));
    localStorage.setItem(
        "selectedEntity",
        JSON.stringify(store.selectedEntity)
    );
    localStorage.setItem(
        "SelectedMotifBeforeUpdate",
        JSON.stringify(store.SelectedMotifBeforeUpdate)
    );
    localStorage.setItem(
        "configurationBloqueAccount",
        JSON.stringify(store.configurationBloqueAccount)
    );
    localStorage.setItem(
        "SelectedProfilBeforeUpdate",
        JSON.stringify(store.SelectedProfilBeforeUpdate)
    );
    localStorage.setItem("SelectedPack", JSON.stringify(store.SelectedPack));
    localStorage.setItem(
        "SelectedPackBeforeUpdate",
        JSON.stringify(store.SelectedPackBeforeUpdate)
    );
    localStorage.setItem(
        "SelectedModuleBeforeUpdate",
        JSON.stringify(store.selectedModuleBeforeUpdate)
    );
    localStorage.setItem(
        "SelectedProductBeforeUpdate",
        JSON.stringify(store.selectedProductBeforeUpdate)
    );
    localStorage.setItem(
        "selectedTypeGestionnaireDeComptesBeforeUpdate",
        JSON.stringify(store.selectedTypeGestionnaireDeComptesBeforeUpdate)
    );
    localStorage.setItem(
        "selectedLicenceDemo",
        JSON.stringify(store.selectedLicenceDemo)
    );
    localStorage.setItem(
        "AllPackHistory",
        JSON.stringify(store.AllPackHistory)
    );
    localStorage.setItem(
        "isLicenceDemoConfiguration",
        JSON.stringify(store.isLicenceDemoConfiguration)
    );
    localStorage.setItem("isDemo", JSON.stringify(store.isDemo));
    localStorage.setItem(
        "archiveActivePacks",
        JSON.stringify(store.archiveActivePacks)
    );
    localStorage.setItem(
        "archiveActiveProduct",
        JSON.stringify(store.archiveActiveProduct)
    );
    localStorage.setItem(
        "archiveActiveModule",
        JSON.stringify(store.archiveActiveModule)
    );
    localStorage.setItem(
        "alerteMessagePack",
        JSON.stringify(store.alerteMessagePack)
    );
    localStorage.setItem(
        "isAlerteModalOpened",
        JSON.stringify(store.isAlerteModalOpened)
    );
    localStorage.setItem(
        "isAlertePackModalOpened",
        JSON.stringify(store.isAlertePackModalOpened)
    );
    localStorage.setItem(
        "isAlerteModuleModalOpened",
        JSON.stringify(store.isAlerteModuleModalOpened)
    );
    localStorage.setItem(
        "isAlerteProfileModalOpened",
        JSON.stringify(store.isAlerteProfileModalOpened)
    );
    localStorage.setItem(
        "isAlerteTypeGestionnaireDeComptesModalOpened",
        JSON.stringify(store.isAlerteTypeGestionnaireDeComptesModalOpened)
    );
    localStorage.setItem(
        "isAlerteMotifModal",
        JSON.stringify(store.isAlerteMotifModal)
    );
    localStorage.setItem(
        "isAlerteDissociateModalOpened",
        JSON.stringify(store.isAlerteDissociateModalOpened)
    );
    localStorage.setItem(
        "isAlertSendCertificateLicenceWithMailOpened",
        JSON.stringify(store.isAlertSendCertificateLicenceWithMailOpened)
    );
    localStorage.setItem(
        "isConfigurationLicenceModalOpened",
        JSON.stringify(store.isConfigurationLicenceModalOpened)
    );
    localStorage.setItem(
        "isCommercialLicenceModalOpened",
        JSON.stringify(store.isCommercialLicenceModalOpened)
    );
    localStorage.setItem(
        "isAlerteDissociateModuleModalOpened",
        JSON.stringify(store.isAlerteDissociateModuleModalOpened)
    );
    localStorage.setItem(
        "AssociatedProducts",
        JSON.stringify(store.AssociatedProducts)
    );
    localStorage.setItem(
        "associatedDemoLicence",
        JSON.stringify(store.associatedDemoLicence)
    );
    localStorage.setItem(
        "associatedDemoLicenceToUpdate",
        JSON.stringify(store.associatedDemoLicenceToUpdate)
    );
    localStorage.setItem(
        "selectedProductsToDissociate",
        JSON.stringify(store.selectedProductsToDissociate)
    );
    localStorage.setItem(
        "selectedModulesToDissociate",
        JSON.stringify(store.selectedModulesToDissociate)
    );
    localStorage.setItem("refHistory", JSON.stringify(store.refHistory));
    localStorage.setItem(
        "refHistoryProduct",
        JSON.stringify(store.refHistoryProduct)
    );
    localStorage.setItem(
        "uuidTypeGestionnaireDeCompteHistory",
        JSON.stringify(store.uuidTypeGestionnaireDeCompteHistory)
    );
    localStorage.setItem("PackUpdated", JSON.stringify(store.PackUpdated));
    localStorage.setItem("listRef", JSON.stringify(store.listRef));
    localStorage.setItem("listRefPack", JSON.stringify(store.listRefPack));
    localStorage.setItem("listRefModule", JSON.stringify(store.listRefModule));
    localStorage.setItem("selectedPack", JSON.stringify(store.selectedPack));
    localStorage.setItem(
        "isActiveModule",
        JSON.stringify(store.isActiveModule)
    );
    localStorage.setItem(
        "selectedModule",
        JSON.stringify(store.selectedModule)
    );
    localStorage.setItem(
        "selectedTypeGestionnaireDeComptes",
        JSON.stringify(store.selectedTypeGestionnaireDeComptes)
    );
    localStorage.setItem(
        "numberProductToAssociate",
        JSON.stringify(store.numberProductToAssociate)
    );
    localStorage.setItem(
        "isAlertupdateModal",
        JSON.stringify(store.isAlertupdateModal)
    );
    localStorage.setItem(
        "isAlertupdateProductModal",
        JSON.stringify(store.isAlertupdateProductModal)
    );
    localStorage.setItem(
        "isAlertupdateTypeGestionnaireDeCompteModal",
        JSON.stringify(store.isAlertupdateTypeGestionnaireDeCompteModal)
    );
    localStorage.setItem("modif", JSON.stringify(store.modif));
    localStorage.setItem(
        "modifTypeGestionnaireDeComptes",
        JSON.stringify(store.modifTypeGestionnaireDeComptes)
    );
    localStorage.setItem("modifProduct", JSON.stringify(store.modifProduct));
    localStorage.setItem(
        "isAssociateProductModalOpen",
        JSON.stringify(store.isAssociateProductModalOpen)
    );
    localStorage.setItem(
        "isAssociateModuleModalOpen",
        JSON.stringify(store.isAssociateModuleModalOpen)
    );
    localStorage.setItem("isVisible", JSON.stringify(store.isVisible));
    localStorage.setItem(
        "isAccessConsult",
        JSON.stringify(store.isAccessConsult)
    );
    localStorage.setItem("isAccessEdit", JSON.stringify(store.isAccessEdit));
    localStorage.setItem("edit", JSON.stringify(store.edit));
    localStorage.setItem("editProduct", JSON.stringify(store.editProduct));
    localStorage.setItem(
        "editTypeGestionnaireDeComptes",
        JSON.stringify(store.editTypeGestionnaireDeComptes)
    );
    localStorage.setItem(
        "SelectedLicensesList",
        JSON.stringify(store.SelectedLicensesList)
    );
    localStorage.setItem(
        "selectedLicences",
        JSON.stringify(store.selectedLicences)
    );
    localStorage.setItem(
        "selectedTypeEconomicModel",
        JSON.stringify(store.selectedTypeEconomicModel)
    );
    localStorage.setItem(
        "selectedExistLicenceDemo",
        JSON.stringify(store.selectedExistLicenceDemo)
    );
    localStorage.setItem(
        "selectedModuleToUpdate",
        JSON.stringify(store.selectedModuleToUpdate)
    );
    localStorage.setItem("selectedData", JSON.stringify(store.selectedData));
    localStorage.setItem(
        "selectedModuleProductLicences",
        JSON.stringify(store.selectedModuleProductLicences)
    );
    localStorage.setItem(
        "isUpdateAccount",
        JSON.stringify(store.isUpdateAccount)
    );
    localStorage.setItem(
        "editActionProfile",
        JSON.stringify(store.editActionProfile)
    );
    localStorage.setItem(
        "isAlertConsultLicencesModal",
        JSON.stringify(store.isAlertConsultLicencesModal)
    );
    localStorage.setItem(
        "isAlertGenerateCertificateModal",
        JSON.stringify(store.isAlertGenerateCertificateModal)
    );
    localStorage.setItem(
        "isAlertHistoryLicencesModal",
        JSON.stringify(store.isAlertHistoryLicencesModal)
    );
    localStorage.setItem(
        "isHistoryLicences",
        JSON.stringify(store.isHistoryLicences)
    );
    localStorage.setItem(
        "isUpdateInfoCertificate",
        JSON.stringify(store.isUpdateInfoCertificate)
    );
    localStorage.setItem(
        "selectedProductsLicence",
        JSON.stringify(store.selectedProductsLicence)
    );
    localStorage.setItem(
        "selectedModuleProductsLicence",
        JSON.stringify(store.selectedModuleProductsLicence)
    );
    localStorage.setItem(
        "selectedProductsAssociatedToPack",
        JSON.stringify(store.selectedProductsAssociatedToPack)
    );
    localStorage.setItem(
        "selectedOneModulelicense",
        JSON.stringify(store.selectedOneModulelicense)
    );
    localStorage.setItem(
        "selectedOneProductAssociatedToPack",
        JSON.stringify(store.selectedOneProductAssociatedToPack)
    );
    localStorage.setItem("isAffected", JSON.stringify(store.isAffected));
    localStorage.setItem(
        "isAffectedModule",
        JSON.stringify(store.isAffectedModule)
    );
    localStorage.setItem(
        "isAffectedPack",
        JSON.stringify(store.isAffectedPack)
    );

    localStorage.setItem(
        "uuidAssociatedProductsToPack",
        JSON.stringify(store.uuidAssociatedProductsToPack)
    );
    localStorage.setItem(
        "isActiveLicenseAlertOpen",
        JSON.stringify(store.isActiveLicenseAlertOpen)
    );
    localStorage.setItem(
        "isActiveLicenseAlertPackOpen",
        JSON.stringify(store.isActiveLicenseAlertPackOpen)
    );
    localStorage.setItem(
        "isActiveLicenseAlertModuleOpen",
        JSON.stringify(store.isActiveLicenseAlertModuleOpen)
    );
    localStorage.setItem(
        "isHitoryAllProfile",
        JSON.stringify(store.isHitoryAllProfile)
    );

    localStorage.setItem(
        "historyProfile",
        JSON.stringify(store.historyProfile)
    );
    localStorage.setItem("usedProfile", JSON.stringify(store.usedProfile));
    localStorage.setItem(
        "usedProfileWidhoutEntity",
        JSON.stringify(store.usedProfileWidhoutEntity)
    );
    localStorage.setItem(
        "usedaccountManagerType",
        JSON.stringify(store.usedaccountManagerType)
    );
    localStorage.setItem(
        "usedaccountManagerTypeWidhoutEntity",
        JSON.stringify(store.usedaccountManagerTypeWidhoutEntity)
    );
    localStorage.setItem(
        "refHistoryPack",
        JSON.stringify(store.refHistoryPack)
    );
    localStorage.setItem(
        "isAlertProfilUsed",
        JSON.stringify(store.isAlertProfilUsed)
    );
    localStorage.setItem(
        "isAlertAccountManagerTypeUsed",
        JSON.stringify(store.isAlertAccountManagerTypeUsed)
    );
    localStorage.setItem(
        "updatedAutorisation",
        JSON.stringify(store.updatedAutorisation)
    );
    localStorage.setItem(
        "autorisationProfileEntityConnected",
        JSON.stringify(store.autorisationProfileEntityConnected)
    );*/
});

export function setModif(modif: boolean) {
    store.modif = modif;
}

export function setModifTypeGestionnaireDeComptes(
    modifTypeGestionnaireDeComptes: boolean
) {
    store.modifTypeGestionnaireDeComptes = modifTypeGestionnaireDeComptes;
}

export function setModifProduct(modifProduct: boolean) {
    store.modifProduct = modifProduct;
}
export function setIsAssociateProductModalOpen(
    isAssociateProductModalOpen: boolean
) {
    store.isAssociateProductModalOpen = isAssociateProductModalOpen;
}
export function setIsAssociateModuleModalOpen(
    isAssociateModuleModalOpen: boolean
) {
    store.isAssociateModuleModalOpen = isAssociateModuleModalOpen;
}
export function setIsVisible(isVisible: boolean) {
    store.isVisible = isVisible;
}
export function setIsAccessConsult(isAccessConsult: boolean) {
    store.isAccessConsult = isAccessConsult;
}
export function setIsAccessEdit(isAccessEdit: boolean) {
    store.isAccessEdit = isAccessEdit;
}
export function setEdit(edit: string) {
    store.edit = edit;
}
export function setEditProduct(editProduct: string) {
    store.editProduct = editProduct;
}
export function setEditTypeGestionnaireDeComptes(
    editTypeGestionnaireDeComptes: string
) {
    store.editTypeGestionnaireDeComptes = editTypeGestionnaireDeComptes;
}
export function setIsHidden(isHidden: boolean) {
    store.isHidden = isHidden;
}
export function setFiltreTableAccount(filtreTableAccount: string) {
    store.filtreTableAccount = filtreTableAccount;
}
export function setSelectedAccount(SelectedAccount: string) {
    store.SelectedAccount = SelectedAccount;
}
export function setBtnValiderIsHidden(BtnValiderIsHidden: boolean) {
    store.BtnValiderIsHidden = BtnValiderIsHidden;
}
export function setSelectedMotif(selectedMotif: string) {
    store.selectedMotif = selectedMotif;
}
export function setSelectedEntity(selectedEntity: string) {
    store.selectedEntity = selectedEntity;
}
export function setSelectedMotifBeforeUpdate(
    SelectedMotifBeforeUpdate: string
) {
    store.SelectedMotifBeforeUpdate = SelectedMotifBeforeUpdate;
}
export function setSelectedProduct(SelectedProduct: string) {
    store.SelectedProduct = SelectedProduct;
}
export function setDevise(devise: string) {
    store.devise = devise;
}
export function setSelectedProfil(SelectedProfil: string) {
    store.SelectedProfil = SelectedProfil;
}
export function setIsHitoryAllMotifs(isHitoryAllMotifs: boolean) {
    store.isHitoryAllMotifs = isHitoryAllMotifs;
}
export function setIsHitoryAllProducts(isHitoryAllProducts: boolean) {
    store.isHitoryAllProducts = isHitoryAllProducts;
}
export function setIsHitoryAllTypeGestionnaireDeComptes(
    isHitoryAllTypeGestionnaireDeComptes: boolean
) {
    store.isHitoryAllTypeGestionnaireDeComptes = isHitoryAllTypeGestionnaireDeComptes;
}
export function setIsActivesMotifs(isActivesMotifs: boolean) {
    store.isActivesMotifs = isActivesMotifs;
}
export function setIsActivesProducts(isActivesProducts: boolean) {
    store.isActivesProducts = isActivesProducts;
}
export function setIsActiveModule(isActiveModule: boolean) {
    store.isActiveModule = isActiveModule;
}
export function setConfigurationBloqueAccount(
    configurationBloqueAccount: string
) {
    store.configurationBloqueAccount = configurationBloqueAccount;
}
export function setSelectedProfilBeforeUpdate(
    SelectedProfilBeforeUpdate: string
) {
    store.SelectedProfilBeforeUpdate = SelectedProfilBeforeUpdate;
}
export function setSelectedPack(SelectedPack: string) {
    store.SelectedPack = SelectedPack;
}
export function setSelectedPackBeforeUpdate(SelectedPackBeforeUpdate: string) {
    store.SelectedPackBeforeUpdate = SelectedPackBeforeUpdate;
}
export function setSelectedModuleBeforeUpdate(
    selectedModuleBeforeUpdate: string
) {
    store.selectedModuleBeforeUpdate = selectedModuleBeforeUpdate;
}
export function setSelectedProductBeforeUpdate(
    selectedProductBeforeUpdate: string
) {
    store.selectedProductBeforeUpdate = selectedProductBeforeUpdate;
}
export function setSelectedTypeGestionnaireDeComptesBeforeUpdate(
    selectedTypeGestionnaireDeComptesBeforeUpdate: string
) {
    store.selectedTypeGestionnaireDeComptesBeforeUpdate = selectedTypeGestionnaireDeComptesBeforeUpdate;
}
export function setSelectedLicenceDemo(selectedLicenceDemo: string) {
    store.selectedLicenceDemo = selectedLicenceDemo;
}
export function setAllPackHistory(AllPackHistory: boolean) {
    store.AllPackHistory = AllPackHistory;
}
export function setIsLicenceDemoConfiguration(
    isLicenceDemoConfiguration: boolean
) {
    store.isLicenceDemoConfiguration = isLicenceDemoConfiguration;
}
export function setIsDemo(isDemo: any) {
    store.isDemo = isDemo;
}
export function setArchiveActivePacks(archiveActivePacks: boolean) {
    store.archiveActivePacks = archiveActivePacks;
}
export function setArchiveActiveProduct(archiveActiveProduct: boolean) {
    store.archiveActiveProduct = archiveActiveProduct;
}
export function setArchiveActiveModule(archiveActiveModule: boolean) {
    store.archiveActiveModule = archiveActiveModule;
}
export function setAlerteMessagePack(alerteMessagePack: string) {
    store.alerteMessagePack = alerteMessagePack;
}
export function setIsAlerteModalOpened(isAlerteModalOpened: boolean) {
    store.isAlerteModalOpened = isAlerteModalOpened;
}
export function setIsAlertePackModalOpened(isAlertePackModalOpened: boolean) {
    store.isAlertePackModalOpened = isAlertePackModalOpened;
}
export function setIsAlerteModuleModalOpened(
    isAlerteModuleModalOpened: boolean
) {
    store.isAlerteModuleModalOpened = isAlerteModuleModalOpened;
}
export function setIsAlerteProfileModalOpened(
    isAlerteProfileModalOpened: boolean
) {
    store.isAlerteProfileModalOpened = isAlerteProfileModalOpened;
}
export function setIsAlerteTypeGestionnaireDeComptesModalOpened(
    isAlerteTypeGestionnaireDeComptesModalOpened: boolean
) {
    store.isAlerteTypeGestionnaireDeComptesModalOpened = isAlerteTypeGestionnaireDeComptesModalOpened;
}
export function setIsAlerteMotifModal(isAlerteMotifModal: boolean) {
    store.isAlerteMotifModal = isAlerteMotifModal;
}
export function setIsAlerteDissociateModalOpened(
    isAlerteDissociateModalOpened: boolean
) {
    store.isAlerteDissociateModalOpened = isAlerteDissociateModalOpened;
}
export function setIsAlertSendCertificateLicenceWithMailOpened(
    isAlertSendCertificateLicenceWithMailOpened: boolean
) {
    store.isAlertSendCertificateLicenceWithMailOpened = isAlertSendCertificateLicenceWithMailOpened;
}
export function setIsConfigurationLicenceModalOpened(
    isConfigurationLicenceModalOpened: boolean
) {
    store.isConfigurationLicenceModalOpened = isConfigurationLicenceModalOpened;
}
export function setIsCommercialLicenceModalOpened(
    isCommercialLicenceModalOpened: boolean
) {
    store.isCommercialLicenceModalOpened = isCommercialLicenceModalOpened;
}
export function setIsAlertupdateModal(isAlertupdateModal: boolean) {
    store.isAlertupdateModal = isAlertupdateModal;
}
export function setIsAlertupdateProductModal(
    isAlertupdateProductModal: boolean
) {
    store.isAlertupdateProductModal = isAlertupdateProductModal;
}
export function setIsAlertupdateTypeGestionnaireDeCompteModal(
    isAlertupdateTypeGestionnaireDeCompteModal: boolean
) {
    store.isAlertupdateTypeGestionnaireDeCompteModal = isAlertupdateTypeGestionnaireDeCompteModal;
}
export function setIsAlerteDissociateModuleModalOpened(
    isAlerteDissociateModuleModalOpened: boolean
) {
    store.isAlerteDissociateModuleModalOpened = isAlerteDissociateModuleModalOpened;
}
export function setAssociatedProducts(AssociatedProducts: any) {
    store.AssociatedProducts = AssociatedProducts;
}
export function setAssociatedDemoLicence(associatedDemoLicence: any) {
    store.associatedDemoLicence = associatedDemoLicence;
}
export function setAssociatedDemoLicenceToUpdate(
    associatedDemoLicenceToUpdate: any
) {
    store.associatedDemoLicenceToUpdate = associatedDemoLicenceToUpdate;
}
export function setSelectedProductsToDissociate(
    selectedProductsToDissociate: boolean
) {
    store.selectedProductsToDissociate = selectedProductsToDissociate;
}
export function setSelectedModulesToDissociate(
    selectedModulesToDissociate: boolean
) {
    store.selectedModulesToDissociate = selectedModulesToDissociate;
}
export function setRefHistory(refHistory: any) {
    store.refHistory = refHistory;
}
export function setRefHistoryProduct(refHistoryProduct: any) {
    store.refHistoryProduct = refHistoryProduct;
}
export function setUuidTypeGestionnaireDeCompteHistory(
    uuidTypeGestionnaireDeCompteHistory: any
) {
    store.uuidTypeGestionnaireDeCompteHistory = uuidTypeGestionnaireDeCompteHistory;
}
export function setPackUpdated(PackUpdated: any) {
    store.PackUpdated = PackUpdated;
}
export function setListRef(listRef: any) {
    store.listRef = listRef;
}
export function setListRefPack(listRefPack: any) {
    store.listRefPack = listRefPack;
}
export function setlistRefModule(listRefModule: any) {
    store.listRefModule = listRefModule;
}
export function setselectedPack(selectedPack: any) {
    store.selectedPack = selectedPack;
}
export function setSelectedModule(selectedModule: any) {
    store.selectedModule = selectedModule;
}
export function setSelectedTypeGestionnaireDeComptes(
    selectedTypeGestionnaireDeComptes: any
) {
    store.selectedTypeGestionnaireDeComptes = selectedTypeGestionnaireDeComptes;
}
export function setNumberProductToAssociate(numberProductToAssociate: any) {
    store.numberProductToAssociate = numberProductToAssociate;
}
export function setSelectedLicensesList(SelectedLicensesList: any) {
    store.SelectedLicensesList = SelectedLicensesList;
}
export function setSelectedLicences(selectedLicences: any) {
    store.selectedLicences = selectedLicences;
}
export function setSelectedTypeEconomicModel(selectedTypeEconomicModel: any) {
    store.selectedTypeEconomicModel = selectedTypeEconomicModel;
}
export function setSelectedExistLicenceDemo(selectedExistLicenceDemo: any) {
    store.selectedExistLicenceDemo = selectedExistLicenceDemo;
}
export function setSelectedModuleToUpdate(selectedModuleToUpdate: any) {
    store.selectedModuleToUpdate = selectedModuleToUpdate;
}
export function setSelectedData(selectedData: any) {
    store.selectedData = selectedData;
}
export function setSelectedModuleProductLicences(
    selectedModuleProductLicences: any
) {
    store.selectedModuleProductLicences = selectedModuleProductLicences;
}
export function setIsUpdateAccount(isUpdateAccount: boolean) {
    store.isUpdateAccount = isUpdateAccount;
}

export function setEditActionProfile(editActionProfile: boolean) {
    store.editActionProfile = editActionProfile;
}
export function setIsAlertConsultLicencesModal(
    isAlertConsultLicencesModal: boolean
) {
    store.isAlertConsultLicencesModal = isAlertConsultLicencesModal;
}
export function setIsAlertGenerateCertificateModal(
    isAlertGenerateCertificateModal: boolean
) {
    store.isAlertGenerateCertificateModal = isAlertGenerateCertificateModal;
}
export function setisAlertHistoryLicencesModal(
    isAlertHistoryLicencesModal: boolean
) {
    store.isAlertHistoryLicencesModal = isAlertHistoryLicencesModal;
}
export function setIsHistoryLicences(isHistoryLicences: boolean) {
    store.isHistoryLicences = isHistoryLicences;
}
export function setIsUpdateInfoCertificate(isUpdateInfoCertificate: boolean) {
    store.isUpdateInfoCertificate = isUpdateInfoCertificate;
}
export function setIsValidateToGenerateCertificate(
    isValidateToGenerateCertificate: boolean
) {
    store.isValidateToGenerateCertificate = isValidateToGenerateCertificate;
}
export function setSelectedProductsLicence(selectedProductsLicence: any) {
    store.selectedProductsLicence = selectedProductsLicence;
}
export function setSelectedModuleProductsLicence(
    selectedModuleProductsLicence: any
) {
    store.selectedModuleProductsLicence = selectedModuleProductsLicence;
}
export function setSelectedProductsAssociatedToPack(
    selectedProductsAssociatedToPack: any
) {
    store.selectedProductsAssociatedToPack = selectedProductsAssociatedToPack;
}
export function setSelectedOneModulelicense(selectedOneModulelicense: any) {
    store.selectedOneModulelicense = selectedOneModulelicense;
}
export function setSelectedOneProductAssociatedToPack(
    selectedOneProductAssociatedToPack: any
) {
    store.selectedOneProductAssociatedToPack = selectedOneProductAssociatedToPack;
}
export function setIsActiveLicenseAlertOpen(isActiveLicenseAlertOpen: boolean) {
    store.isActiveLicenseAlertOpen = isActiveLicenseAlertOpen;
}
export function setIsActiveLicenseAlertPackOpen(
    isActiveLicenseAlertPackOpen: boolean
) {
    store.isActiveLicenseAlertPackOpen = isActiveLicenseAlertPackOpen;
}
export function setIsActiveLicenseAlertModuleOpen(
    isActiveLicenseAlertModuleOpen: boolean
) {
    store.isActiveLicenseAlertModuleOpen = isActiveLicenseAlertModuleOpen;
}
export function setIsAffected(isAffected: boolean) {
    store.isAffected = isAffected;
}
export function setIsAffectedModule(isAffectedModule: boolean) {
    store.isAffectedModule = isAffectedModule;
}
export function setIsAffectedPack(isAffectedPack: boolean) {
    store.isAffectedPack = isAffectedPack;
}
export function setUuidAssociatedProductsToPack(
    uuidAssociatedProductsToPack: any
) {
    store.uuidAssociatedProductsToPack = uuidAssociatedProductsToPack;
}
export function setIsUpdateCertificate(isUpdateCertificate: boolean) {
    store.isUpdateCertificate = isUpdateCertificate;
}
export function setIsHitoryAllProfile(isHitoryAllProfile: boolean) {
    store.isHitoryAllProfile = isHitoryAllProfile;
}
export function setHistoryProfile(historyProfile: any) {
    store.historyProfile = historyProfile;
}
export function setUsedProfile(usedProfile: any) {
    store.usedProfile = usedProfile;
}
export function setUsedProfileWidhoutEntity(usedProfileWidhoutEntity: any) {
    store.usedProfileWidhoutEntity = usedProfileWidhoutEntity;
}
export function setUsedaccountManagerType(usedaccountManagerType: any) {
    store.usedaccountManagerType = usedaccountManagerType;
}
export function setUsedaccountManagerTypeWidhoutEntity(
    usedaccountManagerTypeWidhoutEntity: any
) {
    store.usedaccountManagerTypeWidhoutEntity = usedaccountManagerTypeWidhoutEntity;
}
export function setRefHistoryPack(refHistoryPack: any) {
    store.refHistoryPack = refHistoryPack;
}
export function setIsAlertProfilUsed(isAlertProfilUsed: boolean) {
    store.isAlertProfilUsed = isAlertProfilUsed;
}
export function setIsAlertAccountManagerTypeUsed(
    isAlertAccountManagerTypeUsed: boolean
) {
    store.isAlertAccountManagerTypeUsed = isAlertAccountManagerTypeUsed;
}
export function setUpdatedAutorisation(updatedAutorisation: any) {
    store.updatedAutorisation = updatedAutorisation;
}
export function setAutorisationProfileEntityConnected(
    autorisationProfileEntityConnected: any
) {
    store.autorisationProfileEntityConnected = autorisationProfileEntityConnected;
}
export function setKeysLicenseBeforeUpdate(
    KeysLicenseBeforeUpdate: any
) {
    store.KeysLicenseBeforeUpdate = KeysLicenseBeforeUpdate;
}
export function setModuleLicense(
    moduleLicense: any
) {
    store.moduleLicense = moduleLicense;
}
export function setLicenseBeforeUpdate(
    licenseBeforeUpdate: any
) {
    store.licenseBeforeUpdate = licenseBeforeUpdate;
}
export function setFunctionAssociatePack(
    functionAssociatePack: Function
) {
    store.functionAssociatePack = functionAssociatePack;
}
export function setIsAssociateModuleProductToPackModalOpen(
    isAssociateModuleProductToPackModalOpen: boolean
) {
    store.isAssociateModuleProductToPackModalOpen = isAssociateModuleProductToPackModalOpen;
}
export function setIsModifyMultipleAuthorizationModalOpen(
    isModifyMultipleAuthorizationModalOpen: boolean
) {
    store.isModifyMultipleAuthorizationModalOpen = isModifyMultipleAuthorizationModalOpen;
}
export function setLicenceToDebloq(
    licenceToDebloq: any
) {
    store.licenceToDebloq = licenceToDebloq;
}
export function setProduitLicence(
    produitLicence: any
) {
    store.produitLicence = produitLicence;
}
