import React from "react";

import { AvForm } from "availity-reactstrap-validation";
import {
    Card,
    CardBody,
    Col,
    CardFooter,
    Container,
    Row,
    CardHeader,
} from "reactstrap";
import {
    CrossIcon,
    RefreshIcon,
    StyledButton,
    StyledLabel,
    StyledTextInput,
} from "@aureskonnect/react-ui";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import {
    store,
    setSelectedModule,
    setIsAlertupdateModal,
    setModif,
    setEdit,
    setIsAssociateProductModalOpen,
    setIsAlerteDissociateModalOpened,
    setSelectedProductsLicence,
    setIsActiveLicenseAlertModuleOpen,
    setSelectedOneModulelicense,
} from "../store";
import Breadcurmb from "@components/Common/Breadcrumb/index";
import MessageInfo from "@components/Common/MessageInfo";
import { showToast } from "@components/Common/ToastShow";
import TableListModules from "./TableListModules";
import { HistoryModuleModal } from "./HistoryModuleModal";
import "./module.css";
import AlerteUpdateModale from "./AlerteUpdateModale";
import PageTitle from "@components/Common/PageTitle";
import ActiveLicenseAlertModule from "./ActiveLicenseAlertModule";

export default function Products() {
    const { t } = useTranslation();
    const uidEntity = localStorage.getItem("Uid_Entitie_User");
    const [isHiddenCard, setIsHiddenCard] = React.useState(false);
    const [isEditAction, setIsEditAction] = React.useState<boolean>(false);
    const {
        selectedModule,
        selectedModuleBeforeUpdate,
        isAlertupdateModal,
        isAssociateProductModalOpen,
        isAlerteDissociateModalOpened,
        modif,
        edit,
        refHistory,
        isVisible,
        selectedProductsLicence,
        isActiveLicenseAlertModuleOpen,
        selectedOneModulelicense,
    } = useSnapshot(store);
    const [designation, setDesignation] = React.useState("");
    const [reference, setReference] = React.useState("");
    const [descriptif, setDescriptif] = React.useState("");
    const [isModuleState, setModuleState] = React.useState<boolean>(true);
    const [selectedParentReference] = React.useState<any[]>([]);
    const [isEmptyDes, setIsEmptyDes] = React.useState<boolean>(false);
    const [isEmptyRef, setIsEmptyRef] = React.useState<boolean>(false);
    const [referencesProductsList, setReferencesProductsList] = React.useState<
        any[]
    >([]);
    const [dataIsUpdated, setDataIsUpdated] = React.useState<number | boolean>(
        false
    );
    const [
        isHisotyModuleModalOpened,
        setIsHisotyModuleModalOpened,
    ] = React.useState<boolean>(false);
    const [selectedRows, setSelectedRows] = React.useState<[]>([]);
    const [inputValue, setInputValue] = React.useState<boolean>(false);
    const [counter, setCounter] = React.useState<number>(2000);
    const [isReset, setIsReset] = React.useState<boolean>(false);
    const [isResetDes, setIsResetDes] = React.useState<boolean>(false);

    let titleBreadcurmb = "";

    if (isModuleState) {
        titleBreadcurmb = t("CONFIGURATION / MODULE(S) ACTIVÉ(S)");
    } else {
        titleBreadcurmb = t("CONFIGURATION / MODULE(S) ARCHIVÉ(S)");
    }
    let urlListModulesTable = `${
        process.env.REACT_APP_CONSOLE_ADMIN_ACCESS
    }/modules?uid=${uidEntity}&isArchivedProducts=${!isModuleState}`;

    function saveHistoryChanges(oldData: any, newData: any) {
        fetch(`${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/HistoryChanges`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                oldData: oldData,
                newData: newData,
                uidUpdatedData: uidEntity,
                typeHistory: "2",
            }),
        })
            .then((response) => {
                response.json().then((data) => {
                    setTimeout(function () {
                        setIsHiddenCard(!isHiddenCard);
                    }, 2000);
                    return data.error;
                });
            })
            .catch((e: any) => {
                return false;
            });
    }

    const handleClick = (event: any) => {
        if (isEditAction) {
            if (isReset === true || isResetDes === true) {
                setIsHiddenCard(!isHiddenCard);
            } else {
                if (
                    selectedModuleBeforeUpdate.Descriptif ===
                        selectedModule.Descriptif &&
                    selectedModuleBeforeUpdate.Module === selectedModule.Module
                ) {
                    setIsHiddenCard(!isHiddenCard);
                } else {
                    if (selectedModule.Module.trim().length === 0) {
                        showToast(
                            false,
                            `Le champ 'Désignation' doit être rempli`
                        );
                        setIsEmptyDes(true);
                    } else {
                        if (
                            selectedOneModulelicense.includes(
                                selectedModuleBeforeUpdate.Uuid
                            )
                        ) {
                            setIsActiveLicenseAlertModuleOpen(
                                !isActiveLicenseAlertModuleOpen
                            );
                        } else {
                            updateModule();
                        }
                    }
                }
            }
        } else {
            event.preventDefault();
            if (designation.trim().length === 0) {
                showToast(false, `Le champ 'Désignation' doit être rempli`);
                setIsEmptyDes(true);
            } else if (reference.trim().length === 0) {
                showToast(false, `Le champ 'Référene' doit être rempli`);
                setIsEmptyRef(true);
            } else {
                if (referencesProductsList.includes(reference)) {
                    showToast(
                        false,
                        "La réference existe déjà. Veuillez saisir une autre"
                    );
                } else {
                    AddModule();
                }
            }
        }
    };

    function AddModule() {
        if (referencesProductsList.includes(reference)) {
            showToast(
                false,
                "La réference existe déjà. Veuillez saisir une autre"
            );
        } else {
            let listParentsReferences: any = [];
            selectedParentReference.forEach((element: any) => {
                listParentsReferences.push(element.value);
            });
            let listModules: any = [];
            let newProduct: Record<string, any> = {};
            newProduct[reference] = {
                iuud: uuidv4(),
                uidEntity: uidEntity,
                name: designation,
                comment: descriptif,
                modules: listModules,
                reference: reference,
                archived: false,
                salesSupport: false,
                isPrincipalProduct: false,
            };
            fetch(`${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/addProduct`, {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    uid: reference,
                    product: JSON.stringify(newProduct),
                }),
            })
                .then((response) => {
                    response.json().then((data) => {
                        if (!data.error) {
                            showToast(
                                true,
                                `Le module ${designation} a été ajouté avec succès `
                            );
                            saveHistoryChanges({}, newProduct);
                            setTimeout(() => {
                                setIsEmptyDes(false);
                                setIsEmptyRef(false);
                                setDesignation("");
                                setReference("");
                                setDescriptif("");
                                setModif(false);
                                setEdit("");
                            }, 2000);
                            setDataIsUpdated(!dataIsUpdated);
                            setIsHiddenCard(!isHiddenCard);
                        } else {
                            showToast(
                                false,
                                "Ouups!, erreur lors de l'ajout du module"
                            );
                        }
                    });
                })
                .catch((e: any) => {
                    console.log({ error: e.message });
                });
        }
    }

    function updateModule() {
        const old_module = {
            name: selectedModuleBeforeUpdate.Module,
            comment: selectedModuleBeforeUpdate.Descriptif,
            reference: selectedModuleBeforeUpdate.Référence,
            salesSupport: selectedModuleBeforeUpdate.SalesSupport,
            isArchived: selectedModuleBeforeUpdate.isArchived,
            isPrincipalProduct: selectedModuleBeforeUpdate.isPrincipalProduct,
        };
        const new_module = {
            name: selectedModule.Module,
            comment: selectedModule.Descriptif,
            reference: selectedModule.Référence,
            salesSupport: selectedModule.SalesSupport,
            isArchived: selectedModule.isArchived,
            isPrincipalProduct: selectedModule.isPrincipalProduct,
        };
        const requestOptions: any = {
            body: JSON.stringify({
                product: new_module,
                uidEntitie: selectedModule.Référence,
            }),
            method: "PUT",
            headers: { "Content-Type": "application/json" },
        };
        fetch(
            `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/updateProduct`,
            requestOptions
        )
            .then((response) => {
                response.json().then((data) => {
                    if (!data.error) {
                        showToast(
                            true,
                            `Le module ${selectedModule.Module} a été modifié avec succès.`
                        );
                        saveHistoryChanges(old_module, new_module);
                        setTimeout(() => {
                            setDesignation("");
                            setReference("");
                            setDescriptif("");
                            setIsEmptyDes(false);
                            setIsEmptyRef(false);
                            setModif(false);
                            setEdit("");
                        }, 2000);
                        setDataIsUpdated(!dataIsUpdated);
                        setIsHiddenCard(!isHiddenCard);
                    } else {
                        showToast(
                            false,
                            `Le module ${selectedModule.Module} n'est pas modifié`
                        );
                    }
                });
            })
            .catch((e: any) => {
                console.log({ error: e.message });
            });
    }

    React.useEffect(() => {
        fetch(
            `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/referencesProductsList?uid=${uidEntity}`
        )
            .then((response) => {
                response.json().then((data) => {
                    setReferencesProductsList(data);
                });
            })
            .catch((e: any) => {
                console.log({ error: e.message });
                return referencesProductsList;
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        fetch(
            `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/isAffectedProductAccount`,
            {
                method: "get",
                headers: { "Content-Type": "application/json" },
            }
        )
            .then((response) => {
                response.json().then((data) => {
                    if (data.length > 0) {
                        let array: any = [];
                        data.forEach((el: any) => {
                            const elementUuid = el.split("|");
                            // array.push(elementUuid[1]);
                            array.push(elementUuid[0]);
                        });
                        // setSelectedOneModulelicense(array);
                        setSelectedOneModulelicense(array);
                        setSelectedProductsLicence(data);
                    }
                });
            })
            .catch((e: any) => {
                console.log({ error: e.message });
                return selectedProductsLicence;
            });
        // eslint-disable-next-line
    }, [dataIsUpdated]);
    return (
        <React.Fragment>
            <PageTitle title="MODULES" />
            <div className="">
                <Container fluid style={{ marginTop: "-15px" }}>
                    <Row>
                        <Col>
                            <Breadcurmb
                                title={titleBreadcurmb}
                                breadcrumbBackgroundColor={""}
                            />
                        </Col>
                        <MessageInfo
                            message={t(
                                "Un module est une fonctionnalité tel que vente additionnelle, offre, etc ...  pouvant être associé à un ou plusieurs produits"
                            )}
                        />
                    </Row>

                    <CardBody
                        style={{
                            padding: "1px",
                        }}
                    >
                        <div
                            className="row "
                            style={{
                                display: "grid",
                                gridTemplateColumns: isHiddenCard
                                    ? "2fr 1fr"
                                    : "auto",
                                gap: "4px",
                            }}
                        >
                            <TableListModules
                                setIsHiddenCard={setIsHiddenCard}
                                isHiddenCard={isHiddenCard}
                                urlTable={urlListModulesTable}
                                setIsEditAction={setIsEditAction}
                                isEditAction={isEditAction}
                                setDataIsUpdated={setDataIsUpdated}
                                dataIsUpdated={dataIsUpdated}
                                setSelectedRows={setSelectedRows}
                                selectedRows={selectedRows}
                                isHisotyModuleModalOpened={
                                    isHisotyModuleModalOpened
                                }
                                setIsHisotyModuleModalOpened={
                                    setIsHisotyModuleModalOpened
                                }
                                setModuleState={setModuleState}
                                isModuleState={isModuleState}
                                isAlertupdateModal={isAlertupdateModal}
                                setIsAlertupdateModal={setIsAlertupdateModal}
                                setEdit={setEdit}
                                setIsAssociateProductModalOpen={
                                    setIsAssociateProductModalOpen
                                }
                                isAssociateProductModalOpen={
                                    isAssociateProductModalOpen
                                }
                            />

                            {!isHiddenCard ? null : (
                                <Card className="border ml-2">
                                    <CardHeader
                                        className="Headercard  d-flex justify-content-between pb-2"
                                        style={{
                                            height: "13.7%",
                                            backgroundColor: "white",
                                            border:
                                                "1px solid rgba(0,0,0,.125)",
                                        }}
                                    >
                                        <div className=" d-flex align-items-center justify-content-center">
                                            <h4
                                                className="m-0 pl-2"
                                                style={{
                                                    font:
                                                        "normal normal 600 20px/27px Segoe UI",
                                                }}
                                            >
                                                {isEditAction
                                                    ? !isModuleState
                                                        ? "Consulter le module"
                                                        : "Modifier le module"
                                                    : "Ajouter un module"}
                                            </h4>
                                        </div>

                                        <div className="d-flex align-items-center ">
                                            {isModuleState ? (
                                                <RefreshIcon
                                                    cursor="pointer"
                                                    height={20}
                                                    width={20}
                                                    onClick={() => {
                                                        if (isEditAction) {
                                                            setIsReset(true);
                                                            setIsResetDes(true);
                                                        } else {
                                                            setDescriptif("");
                                                            setDesignation("");
                                                            setReference("");
                                                        }
                                                        setModif(false);
                                                    }}
                                                />
                                            ) : (
                                                <></>
                                            )}
                                            <div className=" bd-highlight align-items-center ml-4">
                                                <CrossIcon
                                                    cursor="pointer"
                                                    height={17}
                                                    width={17}
                                                    onClick={() => {
                                                        if (isEditAction) {
                                                            if (
                                                                modif === true
                                                            ) {
                                                                setIsAlertupdateModal(
                                                                    true
                                                                );
                                                                setIsHiddenCard(
                                                                    isHiddenCard
                                                                );
                                                            } else {
                                                                setIsHiddenCard(
                                                                    !isHiddenCard
                                                                );
                                                                setInputValue(
                                                                    false
                                                                );
                                                            }
                                                        } else {
                                                            if (
                                                                modif === true
                                                            ) {
                                                                setIsAlertupdateModal(
                                                                    true
                                                                );
                                                                setIsHiddenCard(
                                                                    isHiddenCard
                                                                );
                                                            } else {
                                                                setIsHiddenCard(
                                                                    !isHiddenCard
                                                                );
                                                                setDesignation(
                                                                    ""
                                                                );
                                                                setReference(
                                                                    ""
                                                                );
                                                                setDescriptif(
                                                                    ""
                                                                );
                                                                setSelectedModule(
                                                                    ""
                                                                );
                                                                setIsEmptyDes(
                                                                    false
                                                                );
                                                                setIsEmptyRef(
                                                                    false
                                                                );
                                                                setInputValue(
                                                                    false
                                                                );
                                                                setIsReset(
                                                                    false
                                                                );
                                                                setIsResetDes(
                                                                    false
                                                                );
                                                            }
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </CardHeader>
                                    <AvForm
                                        model={{
                                            form: "salesSupportForm",
                                            checkItOut: true,
                                        }}
                                        style={{
                                            height: "92%",
                                            background: "#FFFFFF",
                                            border:
                                                "1px solid rgba(0,0,0,.125)",
                                        }}
                                    >
                                        <CardBody
                                            style={{
                                                background: "#FFFFFF",
                                                marginTop: "4px",
                                                overflow: "auto",
                                                maxHeight: `calc(100vh - (500px + ${
                                                    isVisible ? "-71px" : "0px"
                                                }))`,
                                            }}
                                        >
                                            <div>
                                                <div className="d-flex flex-row justify-content-between">
                                                    <div className="justify-content-start">
                                                        <StyledLabel
                                                        // htmlFor="text"
                                                        // className="required__clz mt-3"
                                                        >
                                                            Désignation*
                                                        </StyledLabel>
                                                    </div>
                                                    {/* <div className="">
                                                        <StyledLabel
                                                            style={{
                                                                color:
                                                                    "#0019FE",
                                                                textDecoration:
                                                                    "underline",
                                                                cursor:
                                                                    "pointer",
                                                            }}
                                                        >
                                                            Avancé
                                                        </StyledLabel>
                                                    </div> */}
                                                </div>

                                                <StyledTextInput
                                                    style={{
                                                        pointerEvents: !isModuleState
                                                            ? "none"
                                                            : "auto",
                                                        opacity: !isModuleState
                                                            ? "0.9"
                                                            : "",
                                                        border: isEmptyDes
                                                            ? "1px solid red"
                                                            : "1px solid #ced4da",
                                                    }}
                                                    autoFocus={
                                                        !isModuleState
                                                            ? false
                                                            : true
                                                    }
                                                    id="example-inputddddd"
                                                    name="example-inputddddd"
                                                    placeholder="Saisir"
                                                    type="text"
                                                    onChange={(e: any) => {
                                                        setDesignation(
                                                            e.target.value.trim()
                                                        );
                                                        setIsResetDes(false);
                                                        setIsEmptyDes(false);
                                                        setIsEmptyRef(false);
                                                        if (
                                                            selectedModule !==
                                                            ""
                                                        ) {
                                                            setSelectedModule({
                                                                ...selectedModule,
                                                                Module:
                                                                    e?.target
                                                                        .value,
                                                            });
                                                            setModif(true);
                                                        }
                                                    }}
                                                    value={
                                                        selectedModule === ""
                                                            ? designation
                                                            : isResetDes
                                                            ? selectedModuleBeforeUpdate?.Module
                                                            : selectedModule?.Module
                                                    }
                                                />
                                                {isEmptyDes ? (
                                                    <div
                                                        style={{ color: "red" }}
                                                    >
                                                        Ce champ est obligatoire
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                            <AvForm>
                                                <div className="d-flex flex-row justify-content-between">
                                                    <div className="justify-content-start">
                                                        <StyledLabel
                                                        // htmlFor="text"
                                                        // className="required__clz mt-3"
                                                        >
                                                            Référence*
                                                        </StyledLabel>
                                                    </div>
                                                    {/* <div className="">
                                                        <StyledLabel
                                                            style={{
                                                                color:
                                                                    "#0019FE",
                                                                textDecoration:
                                                                    "underline",
                                                                cursor:
                                                                    "pointer",
                                                            }}
                                                        >
                                                            Avancé
                                                        </StyledLabel>
                                                    </div> */}
                                                </div>
                                                <StyledTextInput
                                                    style={{
                                                        pointerEvents: isEditAction
                                                            ? "none"
                                                            : "auto",
                                                        opacity: isEditAction
                                                            ? "0.9"
                                                            : "",
                                                        border:
                                                            isEmptyDes ===
                                                                false &&
                                                            isEmptyRef
                                                                ? "1px solid red"
                                                                : "1px solid #ced4da",
                                                    }}
                                                    id="example-inputddddd"
                                                    name="example-inputddddd"
                                                    placeholder="Saisir"
                                                    type="text"
                                                    onChange={(e: any) => {
                                                        setReference(
                                                            e.target.value.trim()
                                                        );
                                                        setIsEmptyRef(false);
                                                        if (
                                                            selectedModule !==
                                                            ""
                                                        ) {
                                                            setSelectedModule({
                                                                ...selectedModule,
                                                                Référence:
                                                                    e?.target
                                                                        .value,
                                                            });
                                                        }
                                                    }}
                                                    value={
                                                        selectedModule === ""
                                                            ? reference
                                                            : selectedModule?.Référence
                                                    }
                                                />
                                                {isEmptyDes === false &&
                                                isEmptyRef ? (
                                                    <div
                                                        style={{
                                                            color: "red",
                                                            // marginTop: "0.5rem",
                                                            fontSize: "80% ",
                                                        }}
                                                    >
                                                        Ce champ est obligatoire
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                            </AvForm>
                                            <AvForm>
                                                <StyledLabel>
                                                    Descriptif
                                                </StyledLabel>
                                                <StyledTextInput
                                                    style={{
                                                        pointerEvents: !isModuleState
                                                            ? "none"
                                                            : "auto",
                                                        opacity: !isModuleState
                                                            ? "0.9"
                                                            : "",
                                                    }}
                                                    id="example-inputddddd"
                                                    name="example-inputddddd"
                                                    placeholder="Saisir"
                                                    type="textarea"
                                                    maxlength={2000}
                                                    onChange={(e: any) => {
                                                        setDescriptif(
                                                            e.target.value
                                                        );
                                                        setIsReset(false);
                                                        setInputValue(true);
                                                        setCounter(
                                                            2000 -
                                                                e.target.value
                                                                    .length
                                                        );
                                                        if (
                                                            selectedModule !==
                                                            ""
                                                        ) {
                                                            setSelectedModule({
                                                                ...selectedModule,
                                                                Descriptif:
                                                                    e?.target
                                                                        .value,
                                                            });
                                                            setModif(true);
                                                        }
                                                    }}
                                                    value={
                                                        selectedModule === ""
                                                            ? descriptif
                                                            : isReset
                                                            ? selectedModuleBeforeUpdate?.Descriptif
                                                            : selectedModule?.Descriptif
                                                    }
                                                />
                                                <div className="d-flex justify-content-end ">
                                                    {isEditAction ? (
                                                        isReset ? (
                                                            <small>
                                                                Nombre de
                                                                caractère
                                                                maximal :
                                                                {2000 -
                                                                    selectedModuleBeforeUpdate
                                                                        .Descriptif
                                                                        .length}
                                                                /2000
                                                            </small>
                                                        ) : selectedModule
                                                              .Descriptif
                                                              .length === 0 ? (
                                                            <small>
                                                                Nombre de
                                                                caractère
                                                                maximal : 2000
                                                            </small>
                                                        ) : (
                                                            <small>
                                                                Nombre de
                                                                caractère
                                                                maximal :
                                                                {2000 -
                                                                    selectedModule
                                                                        .Descriptif
                                                                        .length}
                                                                /2000
                                                            </small>
                                                        )
                                                    ) : inputValue ? (
                                                        <small>
                                                            Nombre de caractère
                                                            maximal :{counter}
                                                            /2000
                                                        </small>
                                                    ) : (
                                                        <small>
                                                            Nombre de caractère
                                                            maximal : 2000
                                                        </small>
                                                    )}
                                                </div>
                                            </AvForm>
                                        </CardBody>
                                    </AvForm>
                                    <CardFooter
                                        className="Footercard p-0"
                                        style={{
                                            background: "#FFFFFF",
                                            marginBottom: "2%",
                                        }}
                                    >
                                        <div className="d-flex flex-wrap d-flex flex-row-reverse bd-highlight">
                                            <div className="p-2 bd-highlight">
                                                <StyledButton
                                                    disabled={
                                                        !isModuleState
                                                            ? true
                                                            : false
                                                    }
                                                    rounded
                                                    variant="primary"
                                                    outline={false}
                                                    size="sm"
                                                    style={{
                                                        color: "white",
                                                        padding: "0px",
                                                        width: "150px",
                                                        height: "45px",
                                                    }}
                                                    onClick={(e: any) => {
                                                        handleClick(e);
                                                    }}
                                                >
                                                    Valider
                                                </StyledButton>
                                            </div>
                                            <div className="p-2 bd-highlight">
                                                <StyledButton
                                                    disabled={false}
                                                    rounded
                                                    variant="light"
                                                    outline={true}
                                                    size="sm"
                                                    style={{
                                                        background: "white",
                                                        color: "black",
                                                        padding: "0px",
                                                        width: "150px",
                                                        height: "45px",
                                                    }}
                                                    onClick={() => {
                                                        if (isEditAction) {
                                                            if (
                                                                modif === true
                                                            ) {
                                                                setIsAlertupdateModal(
                                                                    true
                                                                );
                                                                setIsHiddenCard(
                                                                    isHiddenCard
                                                                );
                                                            } else {
                                                                setIsHiddenCard(
                                                                    !isHiddenCard
                                                                );
                                                                setInputValue(
                                                                    false
                                                                );
                                                            }
                                                        } else {
                                                            if (
                                                                modif === true
                                                            ) {
                                                                setIsAlertupdateModal(
                                                                    true
                                                                );
                                                                setIsHiddenCard(
                                                                    isHiddenCard
                                                                );
                                                            } else {
                                                                setInputValue(
                                                                    false
                                                                );
                                                                setDesignation(
                                                                    ""
                                                                );
                                                                setReference(
                                                                    ""
                                                                );
                                                                setDescriptif(
                                                                    ""
                                                                );
                                                                setIsEmptyDes(
                                                                    false
                                                                );
                                                                setIsEmptyRef(
                                                                    false
                                                                );
                                                                setSelectedModule(
                                                                    ""
                                                                );
                                                                setIsHiddenCard(
                                                                    !isHiddenCard
                                                                );
                                                                setIsReset(
                                                                    false
                                                                );
                                                                setIsResetDes(
                                                                    false
                                                                );
                                                            }
                                                        }
                                                    }}
                                                >
                                                    Annuler
                                                </StyledButton>
                                            </div>
                                        </div>
                                    </CardFooter>
                                </Card>
                            )}
                        </div>
                    </CardBody>
                </Container>
            </div>
            <HistoryModuleModal
                isHisotyModuleModalOpened={isHisotyModuleModalOpened}
                setIsHisotyModuleModalOpened={setIsHisotyModuleModalOpened}
                selectedRows={selectedRows}
                refHistory={refHistory}
            />
            <AlerteUpdateModale
                isAlertupdateModal={isAlertupdateModal}
                setIsAlertupdateModal={setIsAlertupdateModal}
                setIsHiddenCard={setIsHiddenCard}
                isHiddenCard={isHiddenCard}
                setModif={setModif}
                edit={edit}
                selectedRows={selectedRows}
                setIsEditAction={setIsEditAction}
                setEdit={setEdit}
                setIsAssociateProductModalOpen={setIsAssociateProductModalOpen}
                isAssociateProductModalOpen={isAssociateProductModalOpen}
                setIsAlerteDissociateModalOpened={
                    setIsAlerteDissociateModalOpened
                }
                isAlerteDissociateModalOpened={isAlerteDissociateModalOpened}
                setIsEmptyDes={setIsEmptyDes}
                setIsEmptyRef={setIsEmptyRef}
            />
            <ActiveLicenseAlertModule
                isEditAction={isEditAction}
                setIsHiddenCard={setIsHiddenCard}
                isHiddenCard={isHiddenCard}
                setIsEmptyDes={setIsEmptyDes}
                setDataIsUpdated={setDataIsUpdated}
                dataIsUpdated={dataIsUpdated}
            />
        </React.Fragment>
    );
}
