import { proxy, subscribe } from "valtio";

type StoreType = {
    collapsed: boolean;
    oneShop: boolean;
    isMobileBurgerButtonClicked: boolean;
    userID: number;
    shopID: number;
    franchiseID: string;
    isLoadingAssociateValidation: boolean;
    test: number;
    projectId: string;
    isModalConfirmationOpened: boolean;
};
let storageObject = window.localStorage.getItem("store");
const storedStateStore = JSON.parse(
    storageObject !== null ? storageObject : "{}"
);

const defaultStateStore = {
    collapsed: false,
    isMobileBurgerButtonClicked: false,
    isModalConfirmationOpened: false,
    oneShop: false,
    userID: 106,
    shopID: 6,
    franchiseID: "83bbc4350c114000b0e2d6c4ff204215",
    isLoadingAssociateValidation: false,
    test: 10,
    projectId: "",
};
const initialStateStore =
    Object.keys(storedStateStore).length ===
        Object.keys(defaultStateStore).length
        ? storedStateStore
        : defaultStateStore;
export const store = proxy<StoreType>(initialStateStore);
subscribe(store, () => {
    window.localStorage.setItem("store", JSON.stringify(store));
});
export function setCollapsed(collapsed: boolean): void {
    store.collapsed = collapsed;
}
export function setIsLoadingAssociateValidation(
    isLoadingAssociateValidation: boolean
): void {
    store.isLoadingAssociateValidation = isLoadingAssociateValidation;
}
export function setOneShop(oneShop: boolean): void {
    store.oneShop = oneShop;
    if (oneShop) {
        store.userID = 26;
        store.shopID = 3;
        store.franchiseID = "beb4bf5aaf94437a84f3c58a1d825fc7";
    } else {
        store.userID = 106;
        store.shopID = 6;
        store.franchiseID = "83bbc4350c114000b0e2d6c4ff204215";
    }
}

export function setShopID(shopID: number): void {
    store.shopID = shopID;
}
export function setProjectId(projectId: string): void {
    store.projectId = projectId;
}
export function setUserID(userID: number): void {
    store.userID = userID;
}

export function setfranchiseID(franchiseID: string): void {
    store.franchiseID = franchiseID;
}

export function setIsMobileBurgerButtonClicked(
    isMobileBurgerButtonClicked: boolean
): void {
    store.isMobileBurgerButtonClicked = isMobileBurgerButtonClicked;
}
export function setIsModalConfirmationOpened(
    isModalConfirmationOpened: boolean
): void {
    store.isModalConfirmationOpened = isModalConfirmationOpened;
}
