import React, { useState } from "react";
import {
    StyledModal,
    StyledButton,
} from "@aureskonnect/react-ui";
import { ModalBody, ModalHeader, CardFooter } from "reactstrap";
import { useSnapshot } from "valtio";
import { showToast } from "@components/Common/ToastShow";

import TableAssociateProduct from "./TableAssociateProduct";

import {
    setFunctionAssociatePack,
    setIsActiveLicenseAlertPackOpen,
    setIsAffectedPack,
    setUuidAssociatedProductsToPack,
    store,
} from "../store";
import "../Motifs/index.css";

type AssociateProductsToPackModalType = {
    isModalOpened: boolean;
    setIsModalOpened: Function;
    setDataIsUpdated: React.Dispatch<React.SetStateAction<any>>;
    dataIsUpdated: any;
};

export default function AssociateProductsToPackModal({
    isModalOpened,
    setIsModalOpened,
    setDataIsUpdated,
    dataIsUpdated,
}: AssociateProductsToPackModalType) {
    const {
        selectedPack,
        selectedProductsLicence,
        isActiveLicenseAlertPackOpen,
    } = useSnapshot(store);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [
        selectedProductsToAssociate,
        setSelectedProductsToAssociate,
    ] = useState([]);
    const [
        selectedProductsToDissociate,
        setSelectedProductsToDissociate,
    ] = useState([]);
    const [Product, setProduct] = useState([]);
    const uidEntity = localStorage.getItem("Uid_Entitie_User");

    function saveHistoryChanges(oldData: any, newData: any, type: any) {
        fetch(`${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/HistoryChanges`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                oldData: oldData,
                newData: newData,
                uidUpdatedData: uidEntity,
                typeHistory: type,
            }),
        })
            .then((response) => {
                response.json().then((data) => {
                    setTimeout(function () {}, 2000);
                    return data.error;
                });
            })
            .catch((e: any) => {
                return false;
            });
    }
    let modulePr: any = [];
    function AssociateToPack() {
        const copyPack = JSON.parse(JSON.stringify(selectedProducts));
        copyPack.forEach((elPr: any) => {
            modulePr.push(elPr.Reference);
        });
        if (Object.values(Product).length > 0) {
            Object.values(Product).forEach((elProduct: any) => {
                if (modulePr.includes(elProduct.Reference)) {
                    copyPack.forEach((elm1: any) => {
                        if (
                            elProduct.Reference === elm1.Reference &&
                            elProduct.Quantity !== 0
                        ) {
                            elm1.Quantity = elProduct.Quantity;
                        }
                    });
                } else {
                    copyPack.push(elProduct);
                }
            });
        }
        let ref: string[];
        let module: any;
        let Mod: any;
        let ProductParent: string[];

        selectedProductsToAssociate.forEach((el: any) => {
            ref = el.split("|");
            Mod = ref[0].split(",");
            ProductParent = ref[1].split(",");
            copyPack.forEach((elm: any) => {
                if (elm.Reference === ProductParent[0]) {
                    for (let i: any = 0; i < Mod.length; i++) {
                        module = {
                            name: Mod[0],
                            reference: Mod[1],
                            uuid: Mod[2],
                            comment: Mod[3],
                        };
                    }
                    elm.Module.push(module);
                }
            });
        });
        fetch(`${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/associateToPack`, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                uidPack: selectedPack.uid,
                packProducts: JSON.stringify(copyPack),
            }),
        })
            .then((response) => {
                if (response.status === 200 && response.ok === true) {
                    showToast(
                        true,
                        `L'association a été effectuée avec succès ! `
                    );
                    setSelectedProductsToAssociate([]);
                    setUuidAssociatedProductsToPack([]);
                    setSelectedProductsToDissociate([]);
                    let newProductsAssociated: any = [];
                    if (Object.values(Product).length > 0) {
                        Object.values(Product).forEach((elProduct: any) => {
                            newProductsAssociated.push(elProduct.Name);
                        });
                    }
                    let productsAdded = newProductsAssociated.join(", ");
                    saveHistoryChanges(
                        {
                            designation: selectedPack.Pack,
                            associate: false,
                            designationElement: productsAdded,
                            reference: selectedPack.Référence,
                        },
                        {
                            designation: selectedPack.Pack,
                            associate: true,
                            designationElement: productsAdded,
                            reference: selectedPack.Référence,
                        },
                        5
                    );

                    setTimeout(() => {
                        setDataIsUpdated(!dataIsUpdated);
                    }, 2000);
                    setIsModalOpened(!isModalOpened);
                } else if (response.status === 500) {
                    showToast(
                        false,
                        `Ouups! erreur lors de l'association au pack, veuillez réessayer SVP`
                    );
                }
            })
            .catch((e: any) => {
                console.log({ error: e.message });
            });
    }
    let ref: string[];
    let moduleProd: any = [];
    let ProductsToDissRef: any = [];
    let moduleProdPef: string[];
    let ProductPef: string[];
    selectedProductsToDissociate.forEach((el: any) => {
        ref = el.split("|");
        moduleProdPef = ref[0].split(",");
        ProductPef = ref[1].split(",");
        moduleProd.push(moduleProdPef[1]);
        ProductsToDissRef.push(ProductPef[0]);
    });
    function dissociationProductsPack() {
        let ModuleRef: string = "";
        if (Object.values(Product).length > 0) {
            Object.values(Product).forEach((elProduct: any) => {
                if (elProduct.Quantity === 0) {
                    fetch(
                        `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/dissociateFromPack?uidPack=${selectedPack.uid}&referenceProduit=${elProduct.Reference}&referenceModule=${ModuleRef}&type="Produit"`,
                        {
                            method: "Get",
                            headers: { "Content-Type": "application/json" },
                        }
                    )
                        .then((response) => {
                            if (
                                response.status === 200 &&
                                response.ok === true
                            ) {
                                saveHistoryChanges(
                                    {
                                        referenceElement: elProduct.Reference,
                                        designation:
                                            selectedPack.designationPack,
                                        dissociate: false,
                                        designationElement: elProduct.Produit,
                                        reference: selectedPack.referencePack,
                                        type: "Produit",
                                    },
                                    {
                                        referenceElement: elProduct.Reference,
                                        designation:
                                            selectedPack.designationPack,
                                        dissociate: true,
                                        designationElement:
                                            selectedPack.Produit,
                                        reference: selectedPack.referencePack,
                                        type: "Produit",
                                    },
                                    5
                                );
                                setSelectedProductsToAssociate([]);
                                setSelectedProductsToDissociate([]);
                                showToast(
                                    true,
                                    `Le produit a été dissocié  avec succès!`
                                );
                                setTimeout(() => {
                                    setDataIsUpdated(!dataIsUpdated);
                                }, 2000);
                            } else if (response.status === 500) {
                                showToast(
                                    false,
                                    `Ouups! erreur lors de la dissociation, veuillez réessayer SVP`
                                );
                            }
                        })
                        .catch((e: any) => {
                            console.log({ error: e.message });
                        });
                }
            });
        }
        fetch(
            `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/dissociateFromPack?uidPack=${selectedPack.uid}&referenceProduit=${ProductsToDissRef}&referenceModule=${moduleProd}&type=Module`,
            {
                method: "Get",
                headers: { "Content-Type": "application/json" },
            }
        )
            .then((response) => {
                if (response.status === 200 && response.ok === true) {
                    moduleProd.forEach((el: any) => {
                        const old_data = {
                            referenceElement: el,
                            designation: selectedPack.designationPack,
                            dissociate: false,
                            designationElement: selectedPack.Module,
                            reference: selectedPack.referencePack,
                            type: "Module",
                        };
                        const new_data = {
                            referenceElement: el,
                            designation: selectedPack.designationPack,
                            dissociate: true,
                            designationElement: selectedPack.Module,
                            reference: selectedPack.referencePack,
                            type: "Module",
                        };
                        saveHistoryChanges(old_data, new_data, 5);
                    });
                    showToast(true, `le module a été dissocié  avec succès.`);
                    setTimeout(() => {
                        setDataIsUpdated(!dataIsUpdated);
                    }, 2000);
                } else if (response.status === 500) {
                    showToast(
                        false,
                        `Ouups! erreur lors de la dissociation, veuillez réessayer SVP`
                    );
                }
            })
            .catch((e: any) => {
                console.log({ error: e.message });
            });
    }

    return (
        <React.Fragment>
            <StyledModal
                className="history-modal-style"
                isOpen={isModalOpened}
                style={{
                    marginTop: "12px",
                    maxWidth: "1350px",
                    maxHeight: "100px",
                    borderRadius: "10px",
                }}
            >
                <ModalHeader
                    className="d-flex align-items-center"
                    toggle={() => {
                        setIsModalOpened(!isModalOpened);
                        setIsAffectedPack(false);
                        setUuidAssociatedProductsToPack([]);
                    }}
                >
                    <div
                        className="d-flex align-items-center justify-content-between"
                        style={{
                            height: "35px",
                            display: "grid",
                            gridTemplateColumns: "2.8fr 0.2fr",
                            gridTemplateRows: "1fr",
                            gap: "1050px",
                        }}
                    >
                        <span
                            style={{
                                font: "bold",
                                opacity: 1,
                            }}
                        >
                            ASSOCIER DE(S) PRODUIT(S) À UN PACK:
                            {selectedPack.Pack}
                        </span>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <TableAssociateProduct
                        URL={`${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/products?uid=${uidEntity}&isArchivedProducts=false`}
                        setSelectedProducts={setSelectedProducts}
                        setSelectedProductsToAssociate={
                            setSelectedProductsToAssociate
                        }
                        setSelectedProductsToDissociate={
                            setSelectedProductsToDissociate
                        }
                        setProduct={setProduct}
                    />
                </ModalBody>
                <CardFooter
                    className="d-flex justify-content-end"
                    style={{
                        background: "#FFFFFF",
                    }}
                >
                    <div
                        className="row"
                        style={{
                            gap: "10px",
                            marginRight: "0px",
                        }}
                    >
                        <div>
                            <StyledButton
                                disabled={false}
                                rounded
                                variant="light"
                                outline={true}
                                size="sm"
                                style={{
                                    background: "white",
                                    color: "black",
                                    padding: "0px",
                                    width: "150px",
                                    height: "45px",
                                }}
                                onClick={() => {
                                    setIsAffectedPack(false);
                                    setUuidAssociatedProductsToPack([]);
                                    setIsModalOpened(!isModalOpened);
                                }}
                            >
                                Annuler
                            </StyledButton>
                        </div>
                        <div>
                            <StyledButton
                                disabled={false}
                                rounded
                                variant="primary"
                                outline={false}
                                size="sm"
                                style={{
                                    color: "white",
                                    padding: "0px",
                                    width: "150px",
                                    height: "45px",
                                }}
                                onClick={() => {
                                    if (
                                        selectedProductsToDissociate.length > 0
                                    ) {
                                        dissociationProductsPack();
                                    }
                                    if (
                                        Object.values(Product).length > 0 &&
                                        selectedProductsToAssociate.length === 0
                                    ) {
                                        Object.values(Product).forEach(
                                            (elProduct: any) => {
                                                if (elProduct.Quantity === 0) {
                                                    dissociationProductsPack();
                                                } else {
                                                    if (
                                                        selectedProductsLicence.includes(
                                                            selectedPack.uid
                                                        )
                                                    ) {
                                                        setFunctionAssociatePack(
                                                            AssociateToPack
                                                        );
                                                        setIsActiveLicenseAlertPackOpen(
                                                            !isActiveLicenseAlertPackOpen
                                                        );
                                                    } else {
                                                        AssociateToPack();
                                                    }
                                                }
                                            }
                                            // dissociationProductsPack();
                                        );
                                    }
                                    if (
                                        selectedProductsToAssociate.length > 0
                                    ) {
                                        if (
                                            selectedProductsLicence.includes(
                                                selectedPack.uid
                                            )
                                        ) {
                                            setFunctionAssociatePack(
                                                AssociateToPack
                                            );
                                            setIsActiveLicenseAlertPackOpen(
                                                !isActiveLicenseAlertPackOpen
                                            );
                                        } else {
                                            AssociateToPack();
                                        }
                                    }
                                }}
                            >
                                Valider
                            </StyledButton>
                        </div>
                    </div>
                </CardFooter>
            </StyledModal>
        </React.Fragment>
    );
}
