import React from "react";
import { AvForm } from "availity-reactstrap-validation";
import { DynamicTable } from "@maherunlocker/custom-react-table";
import {
    DisplayIcon,
    DocumentIcon,
    EyeIcon,
    PencilIcon,
    PlusIcon,
    StyledButtonWithIcon,
    StyledIconButton,
    TrashIcon,
    VerticalDotsIcon,
} from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";

import {
    setSelectedTypeGestionnaireDeComptes,
    setSelectedTypeGestionnaireDeComptesBeforeUpdate,
    setIsAlerteTypeGestionnaireDeComptesModalOpened,
    store,
    setIsHitoryAllTypeGestionnaireDeComptes,
} from "../store";

import { useSnapshot } from "valtio";
import TypeGestionnaireDeComptesActionMenu from "./TypeGestionnaireDeComptesActionMenu";
import { showToast } from "@components/Common/ToastShow";

interface customColumnProps {
    indexOFColumn: number;
    columnName: string;
    customJsx: Function;
}

type TableProps = {
    setIsHidden: React.Dispatch<React.SetStateAction<boolean>>;
    isHidden: boolean;
    urlTable: string;
    setIsEditAction: React.Dispatch<React.SetStateAction<boolean>>;
    setDataIsUpdated: React.Dispatch<React.SetStateAction<number | boolean>>;
    dataIsUpdated: number | boolean;
    setSelectedRows: React.Dispatch<React.SetStateAction<any>>;
    selectedRows?: any;
    setIsTypeGetstionnaireDeComptesState: React.Dispatch<
        React.SetStateAction<boolean>
    >;
    isTypeGetstionnaireDeComptesState: boolean;
    isEditAction: boolean;
    isHisotyTypeGestionnaireDeComptesModalOpened: boolean;
    setIsHisotyTypeGestionnaireDeComptesModalOpened: React.Dispatch<
        React.SetStateAction<boolean>
    >;
    setEditTypeGestionnaireDeComptes: Function;
    setIsAlertupdateTypeGestionnaireDeCompteModal: Function;
};

export default function TableListTypeGestionnaireDeComptes(props: TableProps) {
    const { t } = useTranslation();
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const {
        isAlerteTypeGestionnaireDeComptesModalOpened,
        isVisible,
        modifTypeGestionnaireDeComptes,
    } = useSnapshot(store);
    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 4,
            columnName: "Actions",
            customJsx: ActionsColmunCompte,
        },
    ];

    function ActionsColmunCompte({ selectedRow }: any) {
        const { modifTypeGestionnaireDeComptes } = useSnapshot(store);
        return (
            <React.Fragment>
                {props.isTypeGetstionnaireDeComptesState ? (
                    <PencilIcon
                        height={25}
                        width={25}
                        onClick={() => {
                            if (modifTypeGestionnaireDeComptes === true) {
                                props.setEditTypeGestionnaireDeComptes(
                                    "EditTypeCompte"
                                );
                                props.setIsAlertupdateTypeGestionnaireDeCompteModal(
                                    true
                                );
                                setLocalFilterActive(false);
                                props.setSelectedRows(selectedRow);
                                props.setIsEditAction(true);
                            } else {
                                props.setIsEditAction(true);
                                setLocalFilterActive(false);
                                props.setIsHidden(true);
                                setSelectedTypeGestionnaireDeComptes(
                                    selectedRow
                                );
                                setSelectedTypeGestionnaireDeComptesBeforeUpdate(
                                    selectedRow
                                );
                            }
                        }}
                        style={{
                            cursor: "pointer",
                            pointerEvents:
                                selectedRow.nouveau === false ? "none" : "auto",
                            opacity: selectedRow.nouveau === false ? "0.4" : "",
                        }}
                    />
                ) : (
                    <DisplayIcon
                        style={{ cursor: "pointer" }}
                        height={46}
                        width={46}
                        onClick={() => {
                            props.setIsEditAction(true);
                            props.setIsHidden(true);
                            setSelectedTypeGestionnaireDeComptes(selectedRow);
                            setLocalFilterActive(false);
                        }}
                    />
                )}
            </React.Fragment>
        );
    }

    function SelectAccountDropdown({ selectedRow }: any) {
        const { usedaccountManagerTypeWidhoutEntity } = useSnapshot(store);
        return (
            <div className="dropdown ml-2  d-none d-sm-block">
                <VerticalDotsIcon
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    cursor="pointer"
                    height={25}
                    width={25}
                />
                <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                    style={{ marginLeft: "-150px" }}
                >
                    {props.isTypeGetstionnaireDeComptesState ? (
                        <div
                            className="dropdown-item"
                            style={{
                                fontWeight: "bold",
                                alignItems: "center",
                                cursor: "pointer",
                                pointerEvents:
                                    selectedRow.original.nouveau === false
                                        ? "none"
                                        : "auto",
                                opacity:
                                    selectedRow.original.nouveau === false
                                        ? "0.4"
                                        : "",
                            }}
                            onClick={() => {
                                if (
                                    usedaccountManagerTypeWidhoutEntity.includes(
                                        selectedRow.original.uuid
                                    )
                                ) {
                                    showToast(
                                        false,
                                        "Attention, vous ne pouvez pas archiver un type de gestionnaire de comptes utilisé par un administrateur d'un compte client."
                                    );
                                } else {
                                    setIsAlerteTypeGestionnaireDeComptesModalOpened(
                                        !isAlerteTypeGestionnaireDeComptesModalOpened
                                    );
                                    setSelectedTypeGestionnaireDeComptes(
                                        selectedRow.original
                                    );
                                    props.setIsTypeGetstionnaireDeComptesState(
                                        true
                                    );
                                }
                            }}
                        >
                            <TrashIcon
                                height={25}
                                width={25}
                                className="mr-2"
                                style={{ fill: "red" }}
                            />
                            {t("Archive")}
                        </div>
                    ) : (
                        <div
                            className="dropdown-item"
                            style={{
                                fontWeight: "bold",
                                alignItems: "center",
                                cursor: "pointer",
                            }}
                            onClick={() => {
                                setSelectedTypeGestionnaireDeComptes(
                                    selectedRow.original
                                );
                                props.setIsTypeGetstionnaireDeComptesState(
                                    false
                                );
                                setIsAlerteTypeGestionnaireDeComptesModalOpened(
                                    !isAlerteTypeGestionnaireDeComptesModalOpened
                                );
                            }}
                        >
                            <EyeIcon height={25} width={25} className="mr-2" />
                            {t("Enable")}
                        </div>
                    )}
                    <div
                        className="dropdown-item"
                        style={{
                            fontWeight: "bold",
                            alignItems: "center",
                            cursor: "pointer",
                            pointerEvents:
                                selectedRow.original.nouveau === false
                                    ? "none"
                                    : "auto",
                            opacity:
                                selectedRow.original.nouveau === false
                                    ? "0.4"
                                    : "",
                        }}
                        onClick={() => {
                            props.setIsHisotyTypeGestionnaireDeComptesModalOpened(
                                !props.isHisotyTypeGestionnaireDeComptesModalOpened
                            );
                            setIsHitoryAllTypeGestionnaireDeComptes(false);
                            setSelectedTypeGestionnaireDeComptes(
                                selectedRow.original
                            );
                        }}
                    >
                        <DocumentIcon height={25} width={25} className="mr-2" />
                        {t("Historical")}
                    </div>
                </div>
            </div>
        );
    }

    React.useEffect(() => {
        if (filterActive) {
            props.setIsHidden(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterActive]);
    return (
        <div>
            <DynamicTable
                url={props.urlTable}
                minHeight={`calc(100vh - (500px + ${
                    isVisible ? "-71px" : "0px"
                }))`}
                maxHeight={`calc(100vh - (500px + ${
                    !isVisible ? "100px" : "0px"
                }))`}
                actionColumn={SelectAccountDropdown}
                showFilter
                canSelect
                customSelect
                canResize
                onClick={() => {
                    if (modifTypeGestionnaireDeComptes === true) {
                        props.setIsAlertupdateTypeGestionnaireDeCompteModal(
                            true
                        );
                    }
                }}
                setSelectedRows={props.setSelectedRows}
                customJsxSideFilterButton={
                    props.isHidden === true || filterActive === true ? (
                        <StyledIconButton
                            icon="PlusIcon"
                            disabled={filterActive ? true : false}
                            style={{
                                background: "#323333",
                                border: "none",
                                borderColor: "#323333",
                                borderRadius: "6px",
                                display: "flex",
                                padding: "12px 10px",
                            }}
                        >
                            <PlusIcon
                                height={20}
                                width={20}
                                style={{ fill: "#fff" }}
                            />
                        </StyledIconButton>
                    ) : (
                        <div className="d-flex align-items-center mt-2 mx-2 mb-2">
                            <AvForm
                                onSubmit={() => {
                                    setLocalFilterActive(false);
                                    props.setIsEditAction(false);
                                    setSelectedTypeGestionnaireDeComptes("");
                                    props.setIsHidden(!props.isHidden);
                                }}
                            >
                                <StyledButtonWithIcon
                                    disabled={
                                        props.isTypeGetstionnaireDeComptesState
                                            ? false
                                            : true
                                    }
                                    icon="PlusIcon"
                                    iconPosition="left"
                                    rounded
                                    variant="primary"
                                >
                                    {t("Add account manager type")}
                                </StyledButtonWithIcon>
                            </AvForm>

                            <TypeGestionnaireDeComptesActionMenu
                                setDataIsUpdated={
                                    props.setDataIsUpdated !== undefined
                                        ? props.setDataIsUpdated
                                        : () => {}
                                }
                                dataIsUpdated={props.dataIsUpdated}
                                selectedRows={props.selectedRows}
                                setIsTypeGetstionnaireDeComptesState={
                                    props.setIsTypeGetstionnaireDeComptesState
                                }
                                isTypeGetstionnaireDeComptesState={
                                    props.isTypeGetstionnaireDeComptesState
                                }
                                isHisotyTypeGestionnaireDeComptesModalOpened={
                                    props.isHisotyTypeGestionnaireDeComptesModalOpened
                                }
                                setIsHisotyTypeGestionnaireDeComptesModalOpened={
                                    props.setIsHisotyTypeGestionnaireDeComptesModalOpened
                                }
                            />
                        </div>
                    )
                }
                showGlobalFilter
                filterActive={filterActive}
                setLocalFilterActive={setLocalFilterActive}
                arrayOfCustomColumns={arrayOfCustomColumns}
                setDataIsUpdated={props.setDataIsUpdated}
                dataIsUpdated={props.dataIsUpdated}
            />
        </div>
    );
}
