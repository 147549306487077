import React from "react";
import { VerticalDotsIcon } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { setIsModifyMultipleAuthorizationModalOpen, store } from "@pages/store";
import { showToast } from "@components/Common/ToastShow";

type PropsType = {
    selectedRows: any[];
};

export function CustomSideFilter({ selectedRows }: PropsType) {
    const { t } = useTranslation();
    const { isModifyMultipleAuthorizationModalOpen } = useSnapshot(store);
    return (
        <div className="dropdown ml-2  d-none d-sm-block">
            <VerticalDotsIcon
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                cursor="pointer"
                height={25}
                width={25}
            />
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <div
                    className="dropdown-item"
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                        if (selectedRows.length < 2) {
                            showToast(
                                false,
                                `${t(
                                    "Attention, it is a multiple action, you must select at least two elements."
                                )}`
                            );
                        } else {
                            setIsModifyMultipleAuthorizationModalOpen(
                                !isModifyMultipleAuthorizationModalOpen
                            );
                        }
                    }}
                    style={{
                        cursor: "pointer",
                    }}
                >
                    {t("Group authorization management")}
                </div>
            </div>
        </div>
    );
}
