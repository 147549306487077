import { storeAuthorisation } from ".";

export function setDataProject(dataProject: dataProjectType[]): void {
    storeAuthorisation.dataProject = dataProject;
}

export function setVisibleData(id: string, Visible: boolean): void {
    const findData = storeAuthorisation.dataProject.find((element) => element.id === id);

    if (findData !== undefined) {
        const imageIndex = storeAuthorisation.dataProject.indexOf(findData);
        if (Visible === false) {

            storeAuthorisation.dataProject[imageIndex] = {
                ...storeAuthorisation.dataProject[imageIndex],
                Visible: Visible,
                Consult: false,
                Modifie: false,
                subRows: getItemSubRows(findData, Visible)
            }
        }
        else {
            storeAuthorisation.dataProject[imageIndex] = {
                ...storeAuthorisation.dataProject[imageIndex],
                Visible: Visible,
                // Consult: true,
                // Modifie: true,
                // subRows: getItemSubRows(findData, Visible)
            }
        }

        setDataProject(storeAuthorisation.dataProject)
    }


}

export function setConsultData(id: string, Consult: boolean): void {
    const findData = storeAuthorisation.dataProject.find((element) => element.id === id);

    if (findData !== undefined) {

        if (Consult === false) {
            const imageIndex = storeAuthorisation.dataProject.indexOf(findData);
            storeAuthorisation.dataProject[imageIndex] = {
                ...storeAuthorisation.dataProject[imageIndex],
                Consult: Consult,
                Modifie: false,
                subRows: getConsultSubRows(findData, Consult)
            }
        }
        else {
            const imageIndex = storeAuthorisation.dataProject.indexOf(findData);
            storeAuthorisation.dataProject[imageIndex] = {
                ...storeAuthorisation.dataProject[imageIndex],
                Consult: Consult,
                // Modifie: true,
                // subRows: getConsultSubRows(findData, Consult)
            }
        }
        setDataProject(storeAuthorisation.dataProject)
    }
    setDataProject(storeAuthorisation.dataProject)
}




export function setModifieData(id: string, Modifie: boolean): void {
    const findData = storeAuthorisation.dataProject.find((element) => element.id === id);

    if (findData !== undefined) {
        const imageIndex = storeAuthorisation.dataProject.indexOf(findData);
        storeAuthorisation.dataProject[imageIndex] = {
            ...storeAuthorisation.dataProject[imageIndex],
            Modifie: Modifie
        }
        setDataProject(storeAuthorisation.dataProject)
    }
}

export function getItemSubRows(findData: any, Visible1: boolean): any {
    let data = [...findData.subRows]
    if (findData.subRows !== undefined) {
        if (Visible1 === false) {

            data = data.map((subRow: any) => {
                return {
                    ...subRow,
                    Visible: false,
                    Consult: false,
                    Modifie: false,
                    subRows: getItemSubRows(subRow, Visible1)
                };
            })
        } else {

            data = data.map((subRow: any) => {
                return {
                    ...subRow,
                    Visible: true,
                    Consult: true,
                    Modifie: true,
                    subRows: getItemSubRows(subRow, Visible1)
                };
            })
        }
        return data
    }
    else {
        return data
    }
}

export function getConsultSubRows(findData: any, Consult: boolean): any {
    let data = [...findData.subRows]
    if (findData.subRows !== undefined) {
        if (Consult === false) {
            data = findData.subRows.map((subRow: any) => {
                return {
                    ...subRow,
                    Consult: Consult,
                    Modifie: false,
                    subRows: getConsultSubRows(subRow, Consult)
                };
            })
        } else {
            data = findData.subRows.map((subRow: any) => {
                return {
                    ...subRow,
                    Consult: Consult,
                    Modifie: true,
                    subRows: getConsultSubRows(subRow, Consult)
                };
            })
        }
        return data
    }
    else {
        return data
    }
}