import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { SettingsIcon, StyledH2 } from "@aureskonnect/react-ui";
import PeopleIcon from "@assets/images/svg-icons/PeopleIcon";
import { RootState } from "@store/.";
import { StyledSidebarMenu } from "./StyledSidebarMenu";

type SidebarProps = {
    brand: string;
};
export default function Sidebar({ brand }: SidebarProps) {
    const { t } = useTranslation();
    const collapsed = useSelector(
        (state: RootState) => state.layoutSlice.collapsed
    );

    // const redirectionLink =
    //     Number(localStorage.getItem("selectedAccounts")) > 1
    //         ? "selectaccount"
    //         : "home";

    return (
        <StyledSidebarMenu
            brand={
                <span
                    // onClick={() => navigate("/Gestioncomptes/Comptesclients")}
                    style={{ cursor: "pointer" }}
                    role="button"
                    tabIndex={0}
                >
                    {collapsed ? (
                        <StyledH2 className="navbar-brand-box text-white d-flex justify-content-center brand__clz p-2 mr-4">
                            <SettingsIcon
                                className=""
                                height={25}
                                width={25}
                                fill="white"
                            />
                        </StyledH2>
                    ) : (
                        <StyledH2 className="navbar-brand-box text-white d-flex justify-content-center brand__clz mb-0 p-2">
                            {brand}
                        </StyledH2>
                    )}
                </span>
            }
            collapsed={collapsed}
            name={collapsed ? "" : "MENU"}
            menuItems={[
                {
                    link: "/#",
                    menuItem: t("Gestion des comptes"),
                    icon: <PeopleIcon width={25} height={25} />,
                    subMenu: [
                        {
                            link: "/Gestioncomptes/Comptesclients",
                            menuItem: t("Comptes clients"),
                            filterTableAccount: "Activé",
                            isAddButtonValidate: false,
                        },
                        // {
                        //     link: "/Gestioncomptes/Demandesenattentes",
                        //     menuItem: t("Demandes en attentes"),
                        //     filterTableAccount: "Enattente",
                        //     isAddButtonValidate: true,
                        // },
                        {
                            link: "/Gestioncomptes/Comptesenanomalie",
                            menuItem: t("Comptes en anomalie"),
                            filterTableAccount: "Anomalie",
                            isAddButtonValidate: true,
                        },
                        {
                            link: "/Gestioncomptes/Comptesarchives",
                            menuItem: t("Comptes archivés"),
                            filterTableAccount: "Archivé",
                            isAddButtonValidate: true,
                        },
                        {
                            link: "/Gestioncomptes/Comptesbloques",
                            menuItem: t("Comptes bloqués"),
                            filterTableAccount: "Bloqué",
                            isAddButtonValidate: true,
                        },
                    ],
                },

                {
                    link: "/#",
                    menuItem: t("Configuration"),
                    icon: <SettingsIcon width={25} height={25} />,
                    subMenu: [
                        {
                            link: "/configurations/TypeGestionnaireDeComptes",
                            menuItem: t("Type Gestionnaire De Comptes"),
                        },
                        {
                            link: "/configurations/module",
                            menuItem: t("Module"),
                        },
                        {
                            link: "/configurations/product",
                            menuItem: t("Produit"),
                        },
                        {
                            link: "/configurations/pack",
                            menuItem: t("Pack"),
                        },
                        {
                            link: "/configurations/profile",
                            menuItem: t("Profil"),
                        },
                        {
                            link: "/configurations/motif",
                            menuItem: t("Motif"),
                        },
                    ],
                },
            ]}
        />
    );
}
