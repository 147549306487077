import React from "react";
import {
    StyledButton,
    StyledModal,
    CrossIcon,
    StyledIconButton,
} from "@aureskonnect/react-ui";
import { ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { showToast } from "@components/Common/ToastShow";
import { useSnapshot } from "valtio";
import {
    // setSelectedMotif,
    store,
    setEdit,
    setModif,
} from "@pages/store";
type ConfirmModalType = {
    isCofirmModalOpened: boolean;
    setIsConfirmModalOpened: Function;
    typeConfirmation: Number;
    titleMotif: string;
    descriptionMotif: string;
    setDataIsUpdated: Function;
    dataIsUpdated: number | boolean;
    setIsHidden: React.Dispatch<React.SetStateAction<boolean>>;
    isHidden: boolean;
};

export function ConfirmModal({
    isCofirmModalOpened,
    setIsConfirmModalOpened,
    typeConfirmation,
    titleMotif,
    descriptionMotif,
    setDataIsUpdated,
    dataIsUpdated,
    setIsHidden,
    isHidden,
}: ConfirmModalType) {
    const uidEntity = localStorage.getItem("Uid_Entitie_User");
    const { selectedMotif, SelectedMotifBeforeUpdate } = useSnapshot(store);
    const [optionsMotifsData, setoptionsMotifsData] = React.useState([]);
    const [motifValue] = React.useState<string>("");

    let typeMotif = 0;
    let textTypeConfirmation;
    let titleTypeConfirmation;

    switch (typeConfirmation) {
        case 1:
            textTypeConfirmation =
                "Voulez vous confirmer la modification apportée de la ligne sélectionnée ?";
            titleTypeConfirmation = "CONFIRMATION MODIFICATION";
            break;
        case 2:
            textTypeConfirmation =
                "Voulez vous confirmer la suppression de la ligne sélectionnée ?";
            titleTypeConfirmation = "SUPPRESSION";
            break;
        default:
    }

    function saveHistoryChanges(oldData: any, newData: any) {
        fetch(`${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/HistoryChanges`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                oldData: oldData,
                newData: newData,
                uidUpdatedData: uidEntity,
                typeHistory: "3",
            }),
        })
            .then((response) => {
                response.json().then((data) => {
                    setTimeout(function () {
                        setIsHidden(!isHidden);
                    }, 2000);
                    return data.error;
                });
            })
            .catch((e: any) => {
                return false;
            });
    }
    function handleEditMotifClick() {
        const requestOptions = {
            body: JSON.stringify({
                data: {
                    [selectedMotif.iuud]: {
                        type: 0,
                        archiver: false,
                        keyMotif: selectedMotif.iuud,
                        description: selectedMotif.Descriptif,
                        name: selectedMotif.Motif,
                    },
                },
                uidEntitie: uidEntity,
            }),
            method: "PUT",
            headers: { "Content-Type": "application/json" },
        };
        const exist = optionsMotifsData.filter(
            (word: any) => word === motifValue
        );
        if (selectedMotif.Motif === "") {
            showToast(false, "Veuillez saisir le nom du motif !");
        } else {
            // if (isReset === true || isResetDes === true) {
            //     setIsHidden(!isHidden);
            // } else {
            if (selectedMotif.Motif === SelectedMotifBeforeUpdate.Motif) {
                fetch(
                    `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/updateMotif`,
                    requestOptions
                )
                    .then((response) => {
                        response.json().then((data) => {
                            if (!data.error) {
                                showToast(
                                    true,
                                    `Le motif "${selectedMotif.Motif}" a été modifié avec succès. `
                                );
                                setDataIsUpdated(!dataIsUpdated);
                                setIsHidden(!isHidden);
                                setModif(false);
                                setEdit("");
                                const oldMotif = SelectedMotifBeforeUpdate;
                                const newMotif = {
                                    Motif: selectedMotif.Motif,
                                    Descriptif: selectedMotif.Descriptif,
                                };
                                saveHistoryChanges(oldMotif, newMotif);
                            } else {
                                showToast(
                                    false,
                                    "Ouups!, Erreur lors de la modification du Motif"
                                );
                            }
                        });
                    })
                    .catch((e: any) => {
                        console.log({ error: e.message });
                    });
            } else {
                if (exist.length < 1) {
                    fetch(
                        `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/updateMotif`,
                        requestOptions
                    )
                        .then((response) => {
                            response.json().then((data) => {
                                if (!data.error) {
                                    showToast(
                                        true,
                                        `Le motif "${selectedMotif.Motif}" a été modifié avec succès.`
                                    );
                                    setIsHidden(!isHidden);
                                    setModif(false);
                                    setEdit("");
                                    setDataIsUpdated(!dataIsUpdated);
                                    const oldMotif = SelectedMotifBeforeUpdate;
                                    const newMotif = {
                                        Motif: selectedMotif.Motif,
                                        Descriptif: selectedMotif.Descriptif,
                                    };
                                    saveHistoryChanges(oldMotif, newMotif);
                                } else {
                                    showToast(
                                        false,
                                        "Ouups!, Erreur lors de la modification du Motif"
                                    );
                                }
                            });
                        })
                        .catch((e: any) => {
                            console.log({ error: e.message });
                        });
                } else {
                    showToast(
                        false,
                        "Le motif existe déjà. Veuillez saisir un autre"
                    );
                }
            }
            // }
        }
    }
    async function handleListMotifClick() {
        try {
            await fetch(
                `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/listTitleMotifs?uidEntitie=${uidEntity}&number=${typeMotif}`,
                {
                    method: "post",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        uidEntitie: uidEntity,
                        number: JSON.stringify(typeMotif),
                    }),
                }
            )
                .then((response) => response.json())
                .then((data) => {
                    setoptionsMotifsData(data);
                });
        } catch (error) {
            console.log(error);
        }
    }
    React.useEffect(() => {
        handleListMotifClick();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataIsUpdated]);

    return (
        <div>
            <StyledModal
                className="motif-modal-style"
                centered
                isOpen={isCofirmModalOpened}
                style={{
                    marginTop: "12px",
                    maxWidth: "1004px",
                    maxHeight: "100px",
                    borderRadius: "10px",
                }}
            >
                <ModalHeader className="d-flex align-items-center">
                    <div
                        className="d-flex align-items-center justify-content-between "
                        style={{
                            height: "35px",
                            display: "grid",
                            gridTemplateColumns: "1.8fr 0.2fr",
                            gridTemplateRows: "1fr",
                            gap: "480px",
                        }}
                    >
                        <p
                            className="title-style-confirmation"
                            style={{ height: "10px" }}
                        >
                            {titleTypeConfirmation}
                        </p>
                        <StyledIconButton icon="CrossIcon">
                            <CrossIcon
                                cursor="pointer"
                                height={10}
                                width={10}
                                onClick={() => {
                                    setIsConfirmModalOpened(
                                        !isCofirmModalOpened
                                    );
                                }}
                            />
                        </StyledIconButton>
                    </div>
                </ModalHeader>

                <ModalBody style={{ width: "1004px" }}>
                    <text className="text-style-confirmation">
                        {textTypeConfirmation}
                    </text>
                </ModalBody>

                <ModalFooter
                    className="d-flex justify-content-end"
                    style={{
                        background: "#FFFFFF",
                    }}
                >
                    <div
                        className="d-flex align-items-center"
                        style={{ gap: "30px" }}
                    >
                        <div className="col-4">
                            <StyledButton
                                disabled={false}
                                rounded
                                variant="light"
                                outline={true}
                                size="sm"
                                style={{
                                    background: "white",
                                    color: "black",
                                    padding: "0px",
                                    width: "150px",
                                    height: "45px",
                                }}
                                onClick={() => {
                                    setIsConfirmModalOpened(
                                        !isCofirmModalOpened
                                    );
                                }}
                            >
                                Annuler
                            </StyledButton>
                        </div>
                        <div className="col-4">
                            <StyledButton
                                disabled={false}
                                rounded
                                variant="primary"
                                outline={false}
                                size="sm"
                                style={{
                                    color: "white",
                                    padding: "0px",
                                    width: "150px",
                                    height: "45px",
                                }}
                                onClick={() => {
                                    handleEditMotifClick();
                                    setIsConfirmModalOpened(
                                        !isCofirmModalOpened
                                    );
                                }}
                            >
                                Valider
                            </StyledButton>
                        </div>
                    </div>
                </ModalFooter>
            </StyledModal>
        </div>
    );
}
