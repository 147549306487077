import React from "react";
import { DynamicTable } from "@maherunlocker/custom-react-table";
import {
    DocumentIcon,
    PencilIcon,
    PlusIcon,
    StyledIconButton,
    TrashIcon,
    EyeIcon,
    DisplayIcon,
    StyledButtonWithIcon,
    SettingsIcon,
    VerticalDotsIcon,
} from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";

import { AvForm } from "availity-reactstrap-validation";

import {
    setSelectedProfil,
    setSelectedProfilBeforeUpdate,
    store,
    setIsAlerteProfileModalOpened,
    setEditActionProfile,
    setIsHitoryAllProducts,
} from "../store";
import "../index.css";
import MenuActionProfil from "./MenuActionProfil";
import {
    clearData,
    clearMultiCustomSelectionData,
} from "@components/Common/StyledComponent/store";
import { showToast } from "@components/Common/ToastShow";

interface customColumnProps {
    indexOFColumn: number;
    columnName: string;
    customJsx: Function;
}

type TableProps = {
    setIsHidden: React.Dispatch<React.SetStateAction<boolean>>;
    isHidden: boolean;
    urlTable: string;
    setIsEditAction: React.Dispatch<React.SetStateAction<boolean>>;
    setDataIsUpdated: React.Dispatch<React.SetStateAction<number | boolean>>;
    dataIsUpdated: number | boolean;
    setSelectedRows: React.Dispatch<React.SetStateAction<any>>;
    selectedRows: any;
    isHisotyProfilModalOpened: boolean;
    setIsHisotyProfilModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
    setEtatProfil: React.Dispatch<React.SetStateAction<boolean>>;
    etatProfil: boolean;
    isEditAction: boolean;
    toggle: () => void;
    // isAlertupdateModal: boolean;
    // setIsAlertupdateModal: Function;
    // setEdit: Function;
    // setIsAssociateProductModalOpen: Function;
    // isAssociateProductModalOpen: boolean;
};

function TableListProfil({
    setIsHidden,
    isHidden,
    urlTable,
    setIsEditAction,
    setDataIsUpdated,
    dataIsUpdated,
    setSelectedRows,
    selectedRows,
    isHisotyProfilModalOpened,
    setIsHisotyProfilModalOpened,
    setEtatProfil,
    etatProfil,
    toggle,
}: TableProps) {
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const [actionMotifModal, setActionMotifModal] = React.useState<string>("");
    const { t } = useTranslation();
    const { isVisible, isAlerteProfileModalOpened } = useSnapshot(store);

    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 2,
            columnName: `${t("Account type")}`,
            customJsx: TypeDeComptes,
        },
        {
            indexOFColumn: 5,
            columnName: `${t("Shares")}`,
            customJsx: ActionsColmunCompte,
        },
    ];

    function TypeDeComptes({ selectedRow }: any) {
        return (
            <React.Fragment>
                <div>{selectedRow.TypeProfil}</div>
            </React.Fragment>
        );
    }

    function ActionsColmunCompte({ selectedRow, isHidden }: any) {
        return (
            <div>
                <div className="w-100">
                    <div
                        className="row "
                        style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr auto",
                            gap: "20px",
                            marginLeft: "30%",
                        }}
                    >
                        <div
                            className="d-flex align-items-center "
                            style={{ cursor: "pointer" }}
                        >
                            {etatProfil ? (
                                <DisplayIcon
                                    height={46}
                                    width={46}
                                    onClick={() => {
                                        setIsEditAction(true);
                                        setIsHidden(!isHidden);
                                        setSelectedProfil(selectedRow);
                                        setSelectedProfilBeforeUpdate(
                                            selectedRow
                                        );
                                    }}
                                />
                            ) : (
                                <PencilIcon
                                    height={25}
                                    width={25}
                                    id="EditBtton"
                                    onClick={() => {
                                        setIsEditAction(true);
                                        setIsHidden(!isHidden);
                                        setSelectedProfil(selectedRow);
                                        setSelectedProfilBeforeUpdate(
                                            selectedRow
                                        );
                                        clearData();
                                        clearMultiCustomSelectionData();
                                    }}
                                    style={{
                                        pointerEvents:
                                            selectedRow.Connected === true
                                                ? "none"
                                                : "auto",
                                        opacity:
                                            selectedRow.Connected === true
                                                ? "0.4"
                                                : "",
                                    }}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function SelectAccountDropdown({ selectedRow }: any) {
        const { usedProfileWidhoutEntity } = useSnapshot(store);
        return (
            <div className="dropdown ml-2  d-none d-sm-block">
                <VerticalDotsIcon
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    cursor="pointer"
                    height={25}
                    width={25}
                />
                <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                    style={{ marginLeft: "-150px" }}
                >
                    <div
                        className="dropdown-item"
                        style={{
                            fontWeight: "bold",
                            alignItems: "center",
                            cursor: "pointer",
                            pointerEvents:
                                selectedRow.original.Connected === true
                                    ? "none"
                                    : etatProfil
                                    ? "none"
                                    : "auto",
                            opacity:
                                selectedRow.original.Connected === true
                                    ? "0.4"
                                    : etatProfil
                                    ? "0.4"
                                    : "",
                        }}
                        onClick={() => {
                            setSelectedProfil(selectedRow.original);
                            setSelectedProfilBeforeUpdate(selectedRow.original);
                            setEditActionProfile(true);
                            toggle();
                        }}
                    >
                        <SettingsIcon height={25} width={25} className="mr-2" />
                        {t("Permission settings")}
                    </div>
                    {etatProfil ? (
                        <div
                            className="dropdown-item"
                            style={{
                                fontWeight: "bold",
                                alignItems: "center",
                                cursor: "pointer",
                            }}
                            onClick={() => {
                                setActionMotifModal("Activation Profil");
                                setSelectedProfil(selectedRow.original);
                                setEtatProfil(true);
                                setIsAlerteProfileModalOpened(
                                    !isAlerteProfileModalOpened
                                );
                            }}
                        >
                            <EyeIcon height={25} width={25} className="mr-2" />
                            {t("Activate Profile")}
                        </div>
                    ) : (
                        <div
                            className="dropdown-item"
                            style={{
                                fontWeight: "bold",
                                alignItems: "center",
                                cursor: "pointer",
                                pointerEvents:
                                    selectedRow.original.Connected === true
                                        ? "none"
                                        : "auto",
                                opacity:
                                    selectedRow.original.Connected === true
                                        ? "0.4"
                                        : "",
                            }}
                            onClick={() => {
                                if (
                                    usedProfileWidhoutEntity.includes(
                                        selectedRow.original.UidProfil
                                    )
                                ) {
                                    showToast(
                                        false,
                                        "Attention, vous ne pouvez pas archiver un profil utilisé par un administrateur d'un compte client."
                                    );
                                } else {
                                    setActionMotifModal("Archivage Profil");
                                    setSelectedProfil(selectedRow.original);
                                    setEtatProfil(false);
                                    setIsAlerteProfileModalOpened(
                                        !isAlerteProfileModalOpened
                                    );
                                }
                            }}
                        >
                            <TrashIcon
                                height={25}
                                width={25}
                                className="mr-2"
                                style={{ fill: "red" }}
                            />
                            {t("Archiver profil")}
                        </div>
                    )}
                    <div
                        className="dropdown-item"
                        style={{
                            fontWeight: "bold",
                            alignItems: "center",
                            cursor: "pointer",
                            pointerEvents:
                                selectedRow.original.Connected === true
                                    ? "none"
                                    : "auto",
                            opacity:
                                selectedRow.original.Connected === true
                                    ? "0.4"
                                    : "",
                        }}
                        onClick={() => {
                            setSelectedProfil(selectedRow.original);
                            setIsHisotyProfilModalOpened(
                                !isHisotyProfilModalOpened
                            );
                            setIsHitoryAllProducts(false);
                        }}
                    >
                        <DocumentIcon height={25} width={25} className="mr-2" />
                        Historique
                    </div>
                </div>
            </div>
        );
    }

    React.useEffect(() => {
        if (filterActive) {
            setIsHidden(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterActive]);

    return (
        <div>
            <DynamicTable
                url={urlTable}
                minHeight={`calc(100vh - (500px + ${
                    isVisible ? "-71px" : "0px"
                }))`}
                maxHeight={`calc(100vh - (500px + ${
                    !isVisible ? "100px" : "0px"
                }))`}
                actionColumn={SelectAccountDropdown}
                showFilter
                customJsxSideFilterButton={
                    isHidden === true || filterActive === true ? (
                        <StyledIconButton
                            icon="PlusIcon"
                            disabled={filterActive ? true : false}
                            style={{
                                background: "#323333",
                                border: "none",
                                borderColor: "#323333",
                                borderRadius: "6px",
                                display: "flex",
                                padding: "12px 10px",
                            }}
                        >
                            <PlusIcon
                                height={20}
                                width={20}
                                style={{ fill: "#fff" }}
                            />
                        </StyledIconButton>
                    ) : (
                        <div className="d-flex align-items-center mt-2 mx-2 mb-2">
                            <AvForm
                                onSubmit={() => {
                                    setLocalFilterActive(false);
                                    setIsEditAction(false);
                                    setSelectedProfil("");
                                    setIsHidden(!isHidden);
                                }}
                            >
                                <StyledButtonWithIcon
                                    disabled={!etatProfil ? false : true}
                                    icon="PlusIcon"
                                    iconPosition="left"
                                    rounded
                                    variant="primary"
                                >
                                    Ajouter un profil
                                </StyledButtonWithIcon>
                            </AvForm>
                            <MenuActionProfil
                                setEtatProfil={setEtatProfil}
                                setDataIsUpdated={setDataIsUpdated}
                                selectedRows={selectedRows}
                                setSelectedRows={setSelectedRows}
                                etatProfil={etatProfil}
                                dataIsUpdated={dataIsUpdated}
                                isHistoryModalOpened={isHisotyProfilModalOpened}
                                setIsHistoryModalOpened={
                                    setIsHisotyProfilModalOpened
                                }
                                setIsAlerteProfileModalOpened={
                                    setIsAlerteProfileModalOpened
                                }
                                isAlerteProfileModalOpened={
                                    isAlerteProfileModalOpened
                                }
                                setActionMotifModal={setActionMotifModal}
                                actionMotifModal={actionMotifModal}
                            />
                        </div>
                    )
                }
                onClick={() => {}}
                canSelect
                canResize
                customSelect
                setSelectedRows={setSelectedRows}
                showGlobalFilter
                filterActive={filterActive}
                setLocalFilterActive={setLocalFilterActive}
                arrayOfCustomColumns={arrayOfCustomColumns}
                setDataIsUpdated={setDataIsUpdated}
                dataIsUpdated={dataIsUpdated}
            />
        </div>
    );
}

export default TableListProfil;
