import { DynamicTable } from "@maherunlocker/custom-react-table";
import React from "react";
import { useSnapshot } from "valtio";

import { store } from "../store";

type TableProductsHistoryType = {
    Reference: any;
    selectedRows: any;
    refHistoryProduct: any;
};
const Nodropdown = () => {
    return null;
};

function TableProductsHistory(props: TableProductsHistoryType) {
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const storeShot = useSnapshot(store);
    const isHitoryAllProducts = storeShot.isHitoryAllProducts;
    let url = "";
    if (isHitoryAllProducts) {
        url = `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/HistoryOneProduct?reference=${props.refHistoryProduct}`;
    } else {
        url = `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/HistoryOneProduct?reference=${props.Reference}`;
    }
    return (
        <React.Fragment>
            <DynamicTable
                url={url}
                maxHeight={"calc(100vh - 445px)"}
                minHeight={"calc(100vh - 445px)"}
                showFilter
                actionColumn={Nodropdown}
                canSort
                showGlobalFilter
                filterActive={filterActive}
                setLocalFilterActive={setLocalFilterActive}
            />
        </React.Fragment>
    );
}

export default TableProductsHistory;
