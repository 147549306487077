/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Row } from "reactstrap";
import { useTranslation } from "react-i18next";

export default function Footer(): JSX.Element {
    const { t } = useTranslation();
    return (
        <footer
            style={{
                // marginTop: "1.5rem !important",
                backgroundColor: " #ffffff",

                bottom: "0px !important",

                //position: "fixed",
                gridArea: "footer",
            }}
        >
            <Row className="d-flex col-sm pb-md-row">
                <div className="mx-3" />
                <div className="mt-4 mx-1 mb-md-4">
                    © {new Date().getFullYear()} Aureskonnect |
                </div>
                <a href="" className="mt-4 mx-1 mb-md-4">
                    <u>{t("Terms of use")}</u>
                </a>
              
                <a href="" className="mt-4 mx-1 mb-md-4">
                    <u>{t("| Privacy Policy")}</u>
                </a>
            </Row>
        </footer>
    );
}
