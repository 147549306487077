import React from "react";
import {
    StyledButton,
    StyledModal,
    CrossIcon,
    StyledIconButton,
    StyledLabel,
} from "@aureskonnect/react-ui";
import { ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { useSnapshot } from "valtio";
import { store } from "../store";
import { showToast } from "@components/Common/ToastShow";

import "../Overview/index.css";

type AlerteModaltype = {
    setIsAlerteDissociateModuleModalOpened: Function;
    setDataIsUpdated: React.Dispatch<React.SetStateAction<number | boolean>>;
    dataIsUpdated: number | boolean;
};

export default function AlerteDissociateModulesModal({
    setIsAlerteDissociateModuleModalOpened,
    setDataIsUpdated,
    dataIsUpdated,
}: AlerteModaltype) {
    const { isAlerteDissociateModuleModalOpened } = useSnapshot(store);
    const { SelectedProduct } = useSnapshot(store);

    const uidEntity = localStorage.getItem("Uid_Entitie_User");

    function saveHistoryChanges(oldData: any, newData: any) {
        fetch(`${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/HistoryChanges`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                oldData: oldData,
                newData: newData,
                uidUpdatedData: uidEntity,
                typeHistory: "2",
            }),
        })
            .then((response) => {
                response.json().then((data) => {
                    return data.error;
                });
            })
            .catch((e: any) => {
                return false;
            });
    }

    function dissociationModuleFromProducts() {
        const old_module = {
            name: SelectedProduct.parentName,
            reference: SelectedProduct.parentReference,
            modules: SelectedProduct.Module,
        };
        const new_module = {
            name: SelectedProduct.parentName,
            reference: SelectedProduct.parentReference,
            modules: SelectedProduct.Module,
            dissociate: true,
        };
        const old_product = {
            name: SelectedProduct.Module,
            reference: SelectedProduct.Référence,
            modules: SelectedProduct.parentName,
        };
        const new_product = {
            name: SelectedProduct.Module,
            reference: SelectedProduct.Référence,
            modules: SelectedProduct.parentName,
            associate: false,
        };
        fetch(
            `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/dissociateModule`,
            {
                method: "put",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    uid: SelectedProduct.parentReference,
                    referenceProduit: SelectedProduct.Référence,
                }),
            }
        )
            .then((response) => {
                if (response.status === 200 && response.ok === true) {
                    showToast(
                        true,
                        `La dissociation a été effectuée avec succès.`
                    );
                    setDataIsUpdated(!dataIsUpdated);
                    saveHistoryChanges(old_module, new_module);
                    saveHistoryChanges(old_product, new_product);
                } else if (response.status === 500) {
                    showToast(
                        false,
                        `Ouups! erreur lors de l'association du produit, veuillez réessayer SVP`
                    );
                }
            })
            .catch((e: any) => {
                console.log({ error: e.message });
            });
    }
    // function dissociationModuleFromPack() {
    //     // const old_module = {
    //     //     name: SelectedProduct.parentName,
    //     //     reference: SelectedProduct.parentReference,
    //     //     modules: SelectedProduct.Module,
    //     // };
    //     // const new_module = {
    //     //     name: SelectedProduct.parentName,
    //     //     reference: SelectedProduct.parentReference,
    //     //     modules: SelectedProduct.Module,
    //     //     dissociate: true,
    //     // };
    //     // const old_product = {
    //     //     name: SelectedProduct.Module,
    //     //     reference: SelectedProduct.Référence,
    //     //     modules: SelectedProduct.parentName,
    //     // };
    //     // const new_product = {
    //     //     name: SelectedProduct.Module,
    //     //     reference: SelectedProduct.Référence,
    //     //     modules: SelectedProduct.parentName,
    //     //     associate: false,
    //     // };
    //     fetch(
    //         `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/dissociateModuleFromPack`,
    //         {
    //             method: "put",
    //             headers: { "Content-Type": "application/json" },
    //             body: JSON.stringify({
    //                 uuidEntity: uidEntity,
    //                 uuid: SelectedProduct.parentReference,
    //                 referenceProduit: SelectedProduct.Référence,
    //                 referenceModule: SelectedProduct.Référence,
    //             }),
    //         }
    //     )
    //         .then((response) => {
    //             if (response.status === 200 && response.ok === true) {
    //                 showToast(
    //                     true,
    //                     `la dissociation du pack a été effectué avec succès.`
    //                 );
    //                 setDataIsUpdated(!dataIsUpdated);
    //                 // saveHistoryChanges(old_module, new_module);
    //                 // saveHistoryChanges(old_product, new_product);
    //             } else if (response.status === 500) {
    //                 showToast(
    //                     false,
    //                     `Ouups! erreur lors de la dissociation du pack, veuillez réessayer SVP`
    //                 );
    //             }
    //         })
    //         .catch((e: any) => {
    //             console.log({ error: e.message });
    //         });
    // }

    return (
        <div>
            <StyledModal
                className=""
                centered
                isOpen={isAlerteDissociateModuleModalOpened}
            >
                <ModalHeader className="d-flex align-items-center">
                    <div
                        className="d-flex align-items-center justify-content-between "
                        style={{
                            height: "20px",
                            width: "200px",
                            display: "grid",
                            gridTemplateColumns: "1.8fr 0.2fr",
                            gridTemplateRows: "1fr",
                            gap: "300px",
                        }}
                    >
                        <p className="title-text-style">CONFIRMATION</p>
                        <StyledIconButton icon="CrossIcon">
                            <CrossIcon
                                cursor="pointer"
                                height={10}
                                width={10}
                                onClick={() => {
                                    setIsAlerteDissociateModuleModalOpened(
                                        !isAlerteDissociateModuleModalOpened
                                    );
                                }}
                            />
                        </StyledIconButton>
                    </div>
                </ModalHeader>

                <ModalBody>
                    <div
                        style={{
                            marginLeft: "20px",
                            marginTop: "20px",
                            marginBottom: "20px",
                        }}
                    >
                        <StyledLabel>
                            Voulez-vous confirmer la dissociation du module "
                            {SelectedProduct.Module}" du produit "
                            {SelectedProduct.parentName}" ?
                        </StyledLabel>
                    </div>
                </ModalBody>
                <ModalFooter
                    className="card-footer d-flex align-items-center justify-content-end"
                    style={{
                        background: "#FFFFFF",
                        height: "fit-content",
                    }}
                >
                    <StyledButton
                        disabled={false}
                        rounded
                        variant="light"
                        outline={true}
                        size="sm"
                        style={{
                            background: "white",
                            color: "black",
                            padding: "0px",
                            width: "150px",
                            height: "45px",
                        }}
                        onClick={() => {
                            setIsAlerteDissociateModuleModalOpened(
                                !isAlerteDissociateModuleModalOpened
                            );
                        }}
                    >
                        Annuler
                    </StyledButton>
                    <StyledButton
                        className="ml-2"
                        disabled={false}
                        rounded
                        variant="primary"
                        outline={false}
                        size="sm"
                        style={{
                            color: "white",
                            padding: "0px",
                            width: "150px",
                            height: "45px",
                        }}
                        onClick={() => {
                            setIsAlerteDissociateModuleModalOpened(
                                !isAlerteDissociateModuleModalOpened
                            );
                            dissociationModuleFromProducts();
                            // dissociationModuleFromPack();
                        }}
                    >
                        Valider
                    </StyledButton>
                </ModalFooter>
            </StyledModal>
        </div>
    );
}
