import React, { useState } from "react";
import { DynamicTable } from "@maherunlocker/custom-react-table";
import {
    DisassociateIcon,
    PlusIcon,
    StyledIconButton,
    EyeIcon,
    PencilIcon,
    TrashIcon,
    DocumentIcon,
    AssociateIcon,
    DisplayIcon,
    StyledButtonWithIcon,
    VerticalDotsIcon,
} from "@aureskonnect/react-ui";
import { AvForm } from "availity-reactstrap-validation";
import {
    setAlerteMessagePack,
    setIsAlerteModalOpened,
    store,
    setSelectedPack,
    setSelectedPackBeforeUpdate,
    setAllPackHistory,
    setArchiveActivePacks,
    setIsAlertePackModalOpened,
    setAssociatedProducts,
    setPackUpdated,
    setselectedPack,
    setListRefPack,
    setIsAffectedPack,
    setUuidAssociatedProductsToPack,
    setIsAssociateModuleProductToPackModalOpen,
} from "../../pages/store";
import { useSnapshot } from "valtio";
import MenuActionPack from "../../pages/Packs/MenuActionPack";
import { showToast } from "@components/Common/ToastShow";

type TableProps = {
    URL: string;
    setIsHidden: React.Dispatch<React.SetStateAction<boolean>>;
    isHidden: boolean;
    setUpdateAction: React.Dispatch<React.SetStateAction<boolean>>;
    setDataIsUpdated: React.Dispatch<React.SetStateAction<any>>;
    dataIsUpdated: any;
    setDesignation: React.Dispatch<React.SetStateAction<any>>;
    setReference: React.Dispatch<React.SetStateAction<any>>;
    setCommentaire: React.Dispatch<React.SetStateAction<any>>;
    setIsPackState: React.Dispatch<React.SetStateAction<boolean>>;
    isPackState: boolean;
    setSelectedRows: React.Dispatch<React.SetStateAction<any>>;
    selectedRows: any;
    setIsHistoryPackModelOpen: React.Dispatch<React.SetStateAction<boolean>>;
    isHistoryPackModelOpen: boolean;
};

export default function TablePack({
    URL,
    setIsHidden,
    isHidden,
    setUpdateAction,
    setDataIsUpdated,
    dataIsUpdated,
    setDesignation,
    setReference,
    setCommentaire,
    setIsPackState,
    isPackState,
    setSelectedRows,
    selectedRows,
    isHistoryPackModelOpen,
    setIsHistoryPackModelOpen,
}: TableProps) {
    const [filterActive, setLocalFilterActive] = useState<boolean>(false);

    const { isAlerteModalOpened } = useSnapshot(store);
    interface customColumnProps {
        indexOFColumn: number;
        columnName: string;
        customJsx: Function;
    }
    const { isAlertePackModalOpened, isVisible } = useSnapshot(store);

    function EditColmunCompte({ selectedRow, isHidden }: any) {
        const {
            selectedProductsLicence,
            isAssociateModuleProductToPackModalOpen,
        } = useSnapshot(store);

        function associateToPack(Pack: any) {
            let selectedProducts: any = [];
            if (Pack.subRows.length > 0) {
                Pack.subRows.forEach((elmt: any) => {
                    let selectedProduct: any = {
                        name: elmt.Produit,
                        referenceProduct: elmt.Référence,
                        Quantity: elmt.Quantity,
                        Uuid: elmt.Uuid,
                        Comment: elmt.Descriptif,
                        modules: [],
                    };
                    if (elmt.subRows.length > 0) {
                        elmt.subRows.forEach((el: any) => {
                            selectedProduct.modules.push({
                                name: el.Module,
                                reference: el.Référence,
                                uuid: el.Uuid,
                                comment: el.Descriptif,
                            });
                        });
                    }
                    selectedProducts.push(selectedProduct);
                });
            }
            if (selectedProducts.length > 0) {
                let updatedPack: any = [];
                selectedProducts.forEach((product: any) => {
                    const productAssociated: any = {
                        Name: product.name,
                        Reference: product.referenceProduct,
                        Quantity: product.Quantity,
                        Uuid: product.Uuid,
                        Comment: product.Comment,
                        Module: [],
                    };
                    if (product.modules.length > 0) {
                        product.modules.forEach((module: any) => {
                            let modul = {
                                name: module.name,
                                reference: module.reference,
                                uuid: module.uuid,
                                comment: module.comment,
                            };
                            productAssociated.Module.push(modul);
                        });
                    }
                    updatedPack.push(productAssociated);
                });

                setPackUpdated(updatedPack);
            }

            setselectedPack(selectedRow);
            setAssociatedProducts(selectedProducts);
            setIsAssociateModuleProductToPackModalOpen(
                !isAssociateModuleProductToPackModalOpen
            );
        }
        let tableModulesRef: any = [];
        return (
            <div>
                <div className="w-100">
                    {selectedRow.isPack !== true ? (
                        <div
                            className="row hovertext"
                            data-hover={`Dissocier un produit ou un module de ce pack `}
                            style={{
                                marginLeft: "40px",
                                cursor: "pointer",
                                pointerEvents: !isPackState ? "none" : "auto",
                                opacity: !isPackState ? "0.4" : "",
                            }}
                        >
                            <DisassociateIcon
                                height={46}
                                width={46}
                                id="DeleteButton"
                                fill="red"
                                onClick={() => {
                                    if (
                                        selectedProductsLicence.includes(
                                            selectedRow.uidPack
                                        )
                                    ) {
                                        showToast(
                                            false,
                                            ` ${
                                                selectedRow.Produit === ""
                                                    ? "Attention, vous ne pouvez pas dissocier un module d'un pack ayant des licences actives."
                                                    : "Attention, vous ne pouvez pas dissocier un produit d'un pack ayant des licences actives."
                                            }`
                                        );
                                    } else {
                                        setSelectedPack(selectedRow);
                                        setIsAlertePackModalOpened(
                                            !isAlertePackModalOpened
                                        );
                                    }
                                }}
                            />
                        </div>
                    ) : (
                        <div
                            className="row "
                            style={{
                                display: "grid",
                                gridTemplateColumns: "1fr 1fr auto",
                                gap: "20px",
                                marginLeft: "30%",
                            }}
                        >
                            <div
                                className="d-flex align-items-center "
                                style={{ cursor: "pointer" }}
                            >
                                {!isPackState ? (
                                    <DisplayIcon
                                        height={46}
                                        width={46}
                                        id="EditBtton"
                                        onClick={() => {
                                            setSelectedPackBeforeUpdate(
                                                selectedRow
                                            );
                                            setSelectedPack(selectedRow);
                                            setUpdateAction(true);
                                            setIsHidden(!isHidden);
                                            setLocalFilterActive(false);
                                        }}
                                    />
                                ) : (
                                    <PencilIcon
                                        height={25}
                                        width={25}
                                        id="EditBtton"
                                        onClick={() => {
                                            setIsHidden(!isHidden);
                                            setSelectedPackBeforeUpdate(
                                                selectedRow
                                            );
                                            setSelectedPack(selectedRow);
                                            setUpdateAction(true);
                                            setLocalFilterActive(false);
                                        }}
                                    />
                                )}
                            </div>
                            <div className="d-flex align-items-center ">
                                <div
                                    className="w-100 hovertext"
                                    data-hover={`Associer de(s) produit(s) ou/et de(s) module(s) à ce pack `}
                                    style={{
                                        cursor: "pointer",
                                        pointerEvents: !isPackState
                                            ? "none"
                                            : "auto",
                                        opacity: !isPackState ? "0.4" : "",
                                    }}
                                >
                                    <AssociateIcon
                                        height={46}
                                        width={46}
                                        onClick={() => {
                                            if (
                                                selectedProductsLicence.includes(
                                                    selectedRow.uid
                                                )
                                            ) {
                                                setIsAffectedPack(true);
                                            }
                                            let arrayuuid: any = [];
                                            selectedRow.subRows.forEach(
                                                (el: any) => {
                                                    arrayuuid.push(el.Uuid);
                                                }
                                            );
                                            setUuidAssociatedProductsToPack(
                                                arrayuuid
                                            );

                                            Object.keys(
                                                selectedRow.subRows
                                            ).forEach((key) => {
                                                selectedRow.subRows[
                                                    key
                                                ].subRows.forEach((el: any) => {
                                                    tableModulesRef.push(
                                                        el.parentReference +
                                                            "|" +
                                                            el.Référence
                                                    );
                                                });
                                            });
                                            setListRefPack(tableModulesRef);
                                            associateToPack(selectedRow);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }

    function ProductColmun({ selectedRow }: any) {
        let ProductNumber;

        if (typeof selectedRow.Produit === "number") {
            if (selectedRow.Produit < 10) {
                ProductNumber = "0" + selectedRow.Produit;
            } else {
                ProductNumber = selectedRow.Produit;
            }
        }
        return (
            <React.Fragment>
                {typeof selectedRow.Produit === "number" ? (
                    <div
                        className="hovertext"
                        data-hover={`Ce pack contient  ${ProductNumber} produit(s) `}
                    >
                        {ProductNumber}
                    </div>
                ) : (
                    <div>{selectedRow.Produit}</div>
                )}
            </React.Fragment>
        );
    }

    function ModuleColmun({ selectedRow }: any) {
        let moduleNumber;

        if (typeof selectedRow.Module === "number") {
            if (selectedRow.Module < 10) {
                moduleNumber = "0" + selectedRow.Module;
            } else {
                moduleNumber = selectedRow.Module;
            }
        }
        return (
            <React.Fragment>
                {typeof selectedRow.Module === "number" ? (
                    <div
                        className="hovertext"
                        data-hover={`Ce produit contient  ${moduleNumber} module(s) `}
                    >
                        {moduleNumber}
                    </div>
                ) : (
                    <div>{selectedRow.Module}</div>
                )}
            </React.Fragment>
        );
    }

    function NombreProduitsColmun({ selectedRow }: any) {
        let ProductQuantity;
        if (typeof selectedRow.Quantity === "number") {
            if (selectedRow.Quantity < 10) {
                ProductQuantity = "0" + selectedRow.Quantity;
            } else {
                ProductQuantity = selectedRow.Quantity;
            }
        }
        return (
            <React.Fragment>
                <div>{ProductQuantity}</div>
            </React.Fragment>
        );
    }

    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 2,
            columnName: "Produit",
            customJsx: ProductColmun,
        },
        {
            indexOFColumn: 3,
            columnName: "Module",
            customJsx: ModuleColmun,
        },
        {
            indexOFColumn: 5,
            columnName: "Nombre de produits",
            customJsx: NombreProduitsColmun,
        },
        {
            indexOFColumn: 7,
            columnName: "Actions",
            customJsx: EditColmunCompte,
        },
    ];

    function SelectAccountDropdown({ selectedRow }: any) {
        const { selectedProductsLicence } = useSnapshot(store);
        return (
            <div className="w-100">
                {selectedRow.original.isPack ? (
                    <div className="dropdown ml-2  d-none d-sm-block">
                        <VerticalDotsIcon
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            cursor="pointer"
                            height={25}
                            width={25}
                        />
                        <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton"
                            style={{ marginLeft: "-150px" }}
                        >
                            {!isPackState ? (
                                <div
                                    className="dropdown-item"
                                    style={{
                                        fontWeight: "bold",
                                        alignItems: "center",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => {
                                        setAlerteMessagePack(
                                            "Voulez vous confirmer l'activation ?"
                                        );
                                        setArchiveActivePacks(false);
                                        setSelectedPack(selectedRow.original);
                                        setIsAlerteModalOpened(
                                            !isAlerteModalOpened
                                        );
                                    }}
                                >
                                    <EyeIcon
                                        className="mr-2"
                                        height={25}
                                        width={25}
                                        id="ActiveButton"
                                    />
                                    Activer
                                </div>
                            ) : (
                                <div
                                    className="dropdown-item"
                                    style={{
                                        fontWeight: "bold",
                                        alignItems: "center",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => {
                                        if (
                                            selectedProductsLicence.includes(
                                                selectedRow.original.uid
                                            )
                                        ) {
                                            showToast(
                                                false,
                                                `Attention, vous ne pouvez pas archiver un pack ayant des licences actives.`
                                            );
                                        } else {
                                            setAlerteMessagePack(
                                                "Voulez vous confirmer l'archivage ?"
                                            );
                                            setArchiveActivePacks(true);
                                            setSelectedPack(
                                                selectedRow.original
                                            );
                                            setIsAlerteModalOpened(
                                                !isAlerteModalOpened
                                            );
                                        }
                                    }}
                                >
                                    <TrashIcon
                                        className="mr-2"
                                        height={25}
                                        width={25}
                                        id="ArchiveButton"
                                        fill="red"
                                    />
                                    Archiver
                                </div>
                            )}

                            <div
                                className="dropdown-item"
                                style={{
                                    fontWeight: "bold",
                                    alignItems: "center",
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    setIsHistoryPackModelOpen(
                                        !isHistoryPackModelOpen
                                    );
                                    setAllPackHistory(false);
                                    setSelectedPack(selectedRow.original);
                                }}
                            >
                                <DocumentIcon
                                    className="mr-2"
                                    id="HistoryButton"
                                    height={25}
                                    width={25}
                                />
                                Historique
                            </div>
                        </div>
                        {/* <AlerteModal
                            isAlerteModalOpened={isAlerteModalOpened}
                            setIsAlerteModalOpened={setIsAlerteModalOpened}
                            setDataIsUpdated={setDataIsUpdated}
                            dataIsUpdated={dataIsUpdated}
                            ProductsNames={[]}
                            archiveActiveProduct={archiveActiveProduct}
                            archiveActivePacks={archiveActivePacks}
                            type={"Pack"}
                            AlerteMessage={alerteMessagePack}
                            selectedRows={selectedRows}
                        /> */}
                    </div>
                ) : (
                    <></>
                )}
            </div>
        );
    }

    React.useEffect(() => {
        if (filterActive) {
            setIsHidden(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterActive]);

    return (
        <DynamicTable
            url={URL}
            actionColumn={SelectAccountDropdown}
            customJsxSideFilterButton={
                isHidden === true || filterActive === true ? (
                    <StyledIconButton
                        icon="PlusIcon"
                        disabled={filterActive ? true : false}
                        style={{
                            background: "#323333",
                            border: "none",
                            borderColor: "#323333",
                            borderRadius: "6px",
                            display: "flex",
                            padding: "12px 10px",
                        }}
                    >
                        <PlusIcon
                            height={20}
                            width={20}
                            style={{ fill: "#fff" }}
                        />
                    </StyledIconButton>
                ) : (
                    <div className="d-flex align-items-center mt-2 mx-2 mb-2">
                        <AvForm
                            onSubmit={() => {
                                setDesignation("");
                                setCommentaire("");
                                setReference("");
                                setLocalFilterActive(false);
                                setSelectedPack("");
                                setUpdateAction(false);
                                setIsHidden(!isHidden);
                            }}
                        >
                            <StyledButtonWithIcon
                                disabled={!isPackState ? true : false}
                                icon="PlusIcon"
                                iconPosition="left"
                                rounded
                                variant="primary"
                            >
                                Ajouter un pack
                            </StyledButtonWithIcon>
                        </AvForm>

                        <MenuActionPack
                            setEtatPack={setIsPackState}
                            setDataIsUpdated={setDataIsUpdated}
                            dataIsUpdated={dataIsUpdated}
                            selectedRows={selectedRows}
                            etatPack={isPackState}
                            setSelectedRows={setSelectedRows}
                            isHistoryPackModelOpen={isHistoryPackModelOpen}
                            setIsHistoryPackModelOpen={
                                setIsHistoryPackModelOpen
                            }
                            isAlerteModalOpened={isAlerteModalOpened}
                        />
                    </div>
                )
            }
            canSelect
            customSelect
            canResize
            canExpand
            setSelectedRows={setSelectedRows}
            setDataIsUpdated={setDataIsUpdated}
            dataIsUpdated={dataIsUpdated}
            showGlobalFilter
            showFilter
            canMovedCheckboxLeftOnExpand
            filterActive={filterActive}
            setLocalFilterActive={setLocalFilterActive}
            arrayOfCustomColumns={arrayOfCustomColumns}
            minHeight={`calc(100vh - (500px + ${isVisible ? "-71px" : "0px"}))`}
            maxHeight={`calc(100vh - (500px + ${
                !isVisible ? "100px" : "0px"
            }))`}
        />
    );
}
