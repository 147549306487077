import React, { useState } from "react";
import { DynamicTable } from "@maherunlocker/custom-react-table";
import NumericInput from "react-numeric-input";
import Switch from "react-switch";
// import { replaceQuotedString } from "@components/Common/Replace";

type TableProps = {
    URL: string;
    setSelectedProducts: React.Dispatch<React.SetStateAction<any>>;
};

export default function TablePacksProducts({
    URL,
    setSelectedProducts,
}: TableProps) {
    const [filterActive, setLocalFilterActive] = useState<boolean>(false);
    const [dataIsUpdated, setDataIsUpdated] = React.useState<number | boolean>(
        false
    );
    interface customColumnProps {
        indexOFColumn: number;
        columnName: string;
        customJsx: Function;
    }

    function ModuleColmun({ selectedRow }: any) {
        let moduleNumber;

        if (typeof selectedRow.Module === "number") {
            if (selectedRow.Module < 10) {
                moduleNumber = "0" + selectedRow.Module;
            } else {
                moduleNumber = selectedRow.Module;
            }
        }
        return (
            <React.Fragment>
                {typeof selectedRow.Module === "number" ? (
                    <h6
                        className="badge__clz d-flex justify-content-center d-flex align-items-center hovertext"
                        data-hover={`Ce produit contient  ${moduleNumber} module(s) `}
                    >
                        {moduleNumber}
                    </h6>
                ) : (
                    <p>{selectedRow.Module}</p>
                )}
            </React.Fragment>
        );
    }

    let Pack: any = [];
    const selectedProducts: any = [];

    function InputNumberColmunNumberProduct({ selectedRow }: any) {
        const [
            numberProductToAssociate,
            setNumberProductToAssociate,
        ] = React.useState<number>(0);

        const [isChecked, setIsChecked] = useState(false);
        return (
            <div style={{ justifyContent: "center" }}>
                {selectedRow.Produit !== undefined &&
                selectedRow.Produit !== "" ? (
                    <NumericInput
                        style={{
                            input: {
                                width: "60px",
                                height: "30px",
                                color: "black",
                            },
                        }}
                        min={0}
                        max={100}
                        defaultValue={0}
                        value={numberProductToAssociate}
                        onChange={(selectedValue: any) => {
                            setNumberProductToAssociate(selectedValue);
                            if (selectedValue > 0) {
                                let StrgPackProduct = JSON.stringify(Pack);
                                if (
                                    StrgPackProduct.includes(
                                        selectedRow.Référence
                                    ) === false
                                ) {
                                    let productPack: any = {
                                        Name: selectedRow.Produit,
                                        Reference: selectedRow.Référence,
                                        Quantity: selectedValue,
                                        Uuid: selectedRow.Uuid,
                                        Comment: selectedRow.Descriptif,
                                        Module: [],
                                    };
                                    Pack.push(productPack);
                                } else {
                                    let updatedPack: any = [];
                                    Pack.forEach((el: any) => {
                                        if (
                                            el.Reference ===
                                            selectedRow.Référence
                                        ) {
                                            el.Quantity = selectedValue;
                                        }
                                        updatedPack.push(el);
                                    });
                                    Pack = updatedPack;
                                }
                            } else {
                                let updatedPack: any = [];
                                Pack.forEach((el: any) => {
                                    if (
                                        el.Reference !== selectedRow.Référence
                                    ) {
                                        updatedPack.push(el);
                                    }
                                });
                                Pack = updatedPack;
                            }
                            setSelectedProducts(Pack);
                        }}
                    />
                ) : (
                    <Switch
                        onChange={() => {
                            if (isChecked === false) {
                                if (
                                    selectedRow.Produit === undefined &&
                                    selectedRow.Module !== ""
                                ) {
                                    Pack.forEach((elm: any) => {
                                        if (
                                            elm.Reference ===
                                            selectedRow.parentReference
                                        ) {
                                            let elmModule = {
                                                name: selectedRow.Module,
                                                reference:
                                                    selectedRow.Référence,
                                                uuid: selectedRow.Uuid,
                                                comment: selectedRow.Descriptif,
                                            };
                                            elm.Module.push(elmModule);
                                        }
                                    });
                                } else {
                                    let StrgPackProduct = JSON.stringify(Pack);
                                    if (
                                        StrgPackProduct.includes(
                                            selectedRow.Référence
                                        ) === false
                                    ) {
                                        let productPack: any = {
                                            Name: selectedRow.Produit,
                                            Reference: selectedRow.Référence,
                                            Module: [],
                                            Uuid: selectedRow.Uuid,
                                            Comment: selectedRow.Descriptif,
                                        };
                                        selectedProducts.push(
                                            productPack.Reference
                                        );
                                        Pack.push(productPack);
                                    }
                                }
                                setSelectedProducts(Pack);
                                setNumberProductToAssociate(1);
                            } else {
                                if (
                                    selectedRow.Produit === undefined &&
                                    selectedRow.Module !== ""
                                ) {
                                    Pack.forEach((el: any) => {
                                        if (
                                            el.Reference ===
                                            selectedRow.parentReference
                                        ) {
                                            let newModules: any = [];
                                            el.Module.forEach((elm: any) => {
                                                if (
                                                    elm.reference !==
                                                    selectedRow.Référence
                                                ) {
                                                    let elmModule = {
                                                        name: elm.name,
                                                        reference:
                                                            elm.reference,
                                                        uuid: elm.uuid,
                                                        comment: elm.comment,
                                                    };
                                                    newModules.push(elmModule);
                                                }
                                            });
                                            el.Module = newModules;
                                        }
                                    });
                                } else {
                                    let updatedPack: any = [];
                                    Pack.forEach((el: any) => {
                                        if (
                                            el.Reference !==
                                            selectedRow.Référence
                                        ) {
                                            updatedPack.push(el);
                                        }
                                    });
                                    Pack = updatedPack;
                                }
                                setSelectedProducts(Pack);
                            }
                            setIsChecked(!isChecked);
                        }}
                        checked={isChecked}
                        offColor="#E30613"
                        onColor="#34C38F"
                    ></Switch>
                )}
            </div>
        );
    }

    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 2,
            columnName: "Module",
            customJsx: ModuleColmun,
        },
        {
            indexOFColumn: 5,
            columnName: "Produit(s)/Module(s)",
            customJsx: InputNumberColmunNumberProduct,
        },
    ];

    function SelectAccountDropdown({ selectedRow }: any) {
        return <div className="w-100"></div>;
    }

    return (
        <DynamicTable
            url={URL}
            maxHeight={"calc(100vh - 445px)"}
            minHeight={"calc(100vh - 445px)"}
            actionColumn={SelectAccountDropdown}
            customSelect
            canExpand
            setDataIsUpdated={setDataIsUpdated}
            dataIsUpdated={dataIsUpdated}
            showGlobalFilter
            showFilter
            filterActive={filterActive}
            setLocalFilterActive={setLocalFilterActive}
            arrayOfCustomColumns={arrayOfCustomColumns}
        />
    );
}
