import React from "react";

import { AvForm } from "availity-reactstrap-validation";
import {
    Card,
    CardBody,
    Col,
    CardFooter,
    Container,
    Row,
    CardHeader,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import {
    CrossIcon,
    RefreshIcon,
    StyledButton,
    StyledLabel,
    StyledSelectInput,
    StyledTextInput,
} from "@aureskonnect/react-ui";

import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import {
    setSelectedProduct,
    store,
    setModifProduct,
    setIsAlertupdateProductModal,
    setEditProduct,
    setIsAssociateModuleModalOpen,
    setIsAlerteDissociateModuleModalOpened,
    setSelectedProductsLicence,
    setIsActiveLicenseAlertOpen,
    setSelectedProductsAssociatedToPack,
    setSelectedOneProductAssociatedToPack,
    setSelectedModuleProductsLicence,
    setModuleLicense,
} from "../store";

// import DisplayNameModal from "@pages/AdvancedDisplayNameModal";
import { HistoryProductsModal } from "./HistoryProductsModal";
import Breadcurmb from "@components/Common/Breadcrumb/index";
import MessageInfo from "@components/Common/MessageInfo";
import { showToast } from "@components/Common/ToastShow";
import TableListProducts from "./TableListProducts";
import "./index.css";
import AlerteUpdateProductModal from "./AlerteUpdateProductModal";
import PageTitle from "@components/Common/PageTitle";
import ActiveLicenseAlert from "./ActiveLicenseAlert";
// type initialState = {
//     isArchived: boolean;
//     isHiddenCard: boolean;
//     isEditAction: boolean;
//     isSalesSupport: boolean;
//     isAffectedProduct: boolean;
//     designation: string;
//     reference: string;
//     commentaire: string;
//     typeProduct: string;
// };
export default function Products() {
    const { t } = useTranslation();
    const storeShot = useSnapshot(store);
    const {
        SelectedProduct,
        selectedProductBeforeUpdate,
        modifProduct,
        editProduct,
        isAlertupdateProductModal,
        isAlerteDissociateModuleModalOpened,
        isAssociateModuleModalOpen,
        refHistoryProduct,
        isVisible,
        selectedProductsLicence,
        isActiveLicenseAlertOpen,
        selectedProductsAssociatedToPack,
        selectedOneProductAssociatedToPack,
        selectedModuleProductsLicence,
    } = useSnapshot(store);

    // const DefaultLanguages = [
    //     { name: "Français", id: "1", value: "", isSelected: true },
    //     { name: "Anglais", id: "2", value: "", isSelected: false },
    //     { name: "Italien", id: "3", value: "", isSelected: false },
    //     { name: "Allemand", id: "4", value: "", isSelected: true },
    //     { name: "Espagnol", id: "5", value: "", isSelected: false },
    //     { name: "Portugais", id: "6", value: "", isSelected: false },
    // ];
    // const [languages, setLanguages] = React.useState<
    //     {
    //         name: string;
    //         id: string;
    //         value: string;
    //         isSelected: boolean;
    //     }[]
    // >(DefaultLanguages);

    //test
    // const initialState: initialState = {
    //     isArchived: true,
    //     isHiddenCard: false,
    //     isEditAction: false,
    //     isSalesSupport: false,
    //     isAffectedProduct: false,
    //     designation: "",
    //     reference: "",
    //     commentaire: "",
    //     typeProduct: "",
    // };
    // const [productState, setProductState] = React.useState(initialState);
    // setProductState({
    //     ...productState,
    //     isArchived: !productState.isArchived,
    // });
    const [isProductState, setProductState] = React.useState<boolean>(true);
    const uidEntity = localStorage.getItem("Uid_Entitie_User");
    const [isHiddenCard, setIsHiddenCard] = React.useState(false);
    const [isEditAction, setIsEditAction] = React.useState<boolean>(false);
    const [isSalesSupport, setIsSalesSupport] = React.useState<boolean>();
    // const [
    //     isOpenDescriptifAdvanced,
    //     setIsOpenDescriptifAdvanced,
    // ] = React.useState<boolean>(false);
    const [designation, setDesignation] = React.useState("");
    const [reference, setReference] = React.useState("");
    const [referenceProduct, setReferenceProduct] = React.useState("");
    const [commentaire, setCommentaire] = React.useState("");
    const [typeProduct, setTypeProduct] = React.useState<string>("");
    const [, setParentProduct] = React.useState<string>("");

    const [, setSelectedParentReference] = React.useState<any[]>([]);

    const [referencesProductsList, setReferencesProductsList] = React.useState<
        any[]
    >([]);

    const [productsTypesList] = React.useState<any[]>([]);
    const [, setSelectInputRef] = React.useState<any | null>(null);
    const [dataIsUpdated, setDataIsUpdated] = React.useState<number | boolean>(
        false
    );
    const [parentProductsOptions, setParentProductsOptions] = React.useState<
        any
    >([]);

    const [, setOptionsParentsProducts] = React.useState<any>([]);

    const [
        isHisotyProductModalOpened,
        setIsHisotyProductModalOpened,
    ] = React.useState<boolean>(false);
    const [selectedRows, setSelectedRows] = React.useState<[]>([]);
    const [dataList, setDataList] = React.useState<any>([]);
    const [isEmptyDes, setIsEmptyDes] = React.useState<boolean>(false);
    const [isEmptyRef, setIsEmptyRef] = React.useState<boolean>(false);
    const [isEmptyTypeProuct, setIsEmptyTypeProuct] = React.useState<boolean>(
        false
    );
    const [isReset, setIsReset] = React.useState<boolean>(false);
    const [isResetDes, setIsResetDes] = React.useState<boolean>(false);
    let titleBreadcurmb = "";

    const [inputValue, setInputValue] = React.useState<boolean>(false);
    const [counter, setCounter] = React.useState<number>(2000);
    let array: any = [];
    if (isProductState) {
        titleBreadcurmb = t("CONFIGURATION / PRODUIT(S) ACTIVÉ(S)");
    } else {
        titleBreadcurmb = t("CONFIGURATION / PRODUIT(S) ARCHIVÉ(S)");
    }
    let urlListProductsTable = `${
        process.env.REACT_APP_CONSOLE_ADMIN_ACCESS
    }/products?uid=${uidEntity}&isArchivedProducts=${!isProductState}`;

    function saveHistoryChanges(oldData: any, newData: any) {
        fetch(`${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/HistoryChanges`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                oldData: oldData,
                newData: newData,
                uidUpdatedData: uidEntity,
                typeHistory: "2",
            }),
        })
            .then((response) => {
                response.json().then((data) => {
                    setTimeout(function () {
                        setIsHiddenCard(!isHiddenCard);
                    }, 2000);
                    return data.error;
                });
            })
            .catch((e: any) => {
                return false;
            });
    }

    const onChange = (option: any) => {
        setReferenceProduct(option.value + "-");

        dataList.forEach((element: any) => {
            if (option.value === element.data.prefix) {
                setIsSalesSupport(element.data.isSalesSupport);
            }
        });

        let typeProductSelected = "";
        if (option !== null) {
            typeProductSelected = Object.values(option)[0] as string;
        }
        setTypeProduct(typeProductSelected);
        setIsReset(false);
    };

    const handleClick = (event: any) => {
        event.preventDefault();
        if (designation.trim().length === 0) {
            setIsEmptyDes(true);
            showToast(false, `Le champ 'Désignation' doit être rempli`);
        } else if (typeProduct.trim().length === 0) {
            setIsEmptyTypeProuct(true);
            showToast(false, `Le champ 'Type de produit' doit être rempli`);
        } else if (
            // reference === reference.substring(0, reference.indexOf("-") + 1)
            reference.trim().length === 0
        ) {
            setIsEmptyRef(true);
            showToast(false, `Le champ 'Référence' doit être rempli`);
        } else {
            if (referencesProductsList.includes(reference)) {
                showToast(
                    false,
                    "La réference existe déjà. Veuillez saisir une autre"
                );
            } else {
                handleAddProduct();
                setIsHiddenCard(!isHiddenCard);
            }
        }
    };

    async function handleAddProduct() {
        let listModules: any = [];
        let newProduct: Record<string, any> = {};
        newProduct[referenceProduct + reference] = {
            iuud: uuidv4(),
            uidEntity: uidEntity,
            name: designation,
            comment: commentaire,
            modules: listModules,
            reference: referenceProduct + reference,
            archived: false,
            salesSupport: isSalesSupport,
            isPrincipalProduct: true,
            typeProduct: typeProduct,
        };
        fetch(`${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/addProduct`, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                uid: referenceProduct + reference,
                product: JSON.stringify(newProduct),
            }),
        })
            .then((response) => {
                response.json().then((data) => {
                    if (!data.error) {
                        showToast(
                            true,
                            `Le produit "${designation}" a été ajouté avec succès.`
                        );
                        setDataIsUpdated(!dataIsUpdated);
                        saveHistoryChanges({}, newProduct);
                        handleEmptyVariables();
                        setModifProduct(false);
                        setIsEmptyDes(false);
                        setIsEmptyRef(false);
                        setIsEmptyTypeProuct(false);
                        setEditProduct("");
                        setIsSalesSupport(false);
                    } else {
                        showToast(
                            false,
                            "Ouups!, erreur lors de l'ajout du produit"
                        );
                    }
                });
            })
            .catch((e: any) => {
                console.log({ error: e.message });
            });
    }

    function updateProduct() {
        if (isReset === true || isResetDes === true) {
            setIsHiddenCard(!isHiddenCard);
        } else {
            if (
                selectedProductBeforeUpdate.Produit ===
                    SelectedProduct.Produit &&
                selectedProductBeforeUpdate.Descriptif ===
                    SelectedProduct.Descriptif
            ) {
                setIsHiddenCard(!isHiddenCard);
            } else {
                if (SelectedProduct.Produit.trim().length === 0) {
                    setIsEmptyDes(true);
                    showToast(false, `Le champ 'Désignation' doit être rempli`);
                } else {
                    if (
                        selectedModuleProductsLicence.includes(
                            selectedProductBeforeUpdate.Uuid
                        )
                    ) {
                        setIsActiveLicenseAlertOpen(!isActiveLicenseAlertOpen);
                    } else {
                        const old_product = {
                            name: selectedProductBeforeUpdate.Produit,
                            comment: selectedProductBeforeUpdate.Descriptif,
                            reference: selectedProductBeforeUpdate.Référence,
                            salesSupport:
                                selectedProductBeforeUpdate.SalesSupport,
                            isArchived: selectedProductBeforeUpdate.isArchived,
                            isPrincipalProduct:
                                selectedProductBeforeUpdate.isPrincipalProduct,
                        };
                        const new_product = {
                            name: SelectedProduct.Produit,
                            comment: SelectedProduct.Descriptif,
                            reference: SelectedProduct.Référence,
                            salesSupport: SelectedProduct.SalesSupport,
                            isArchived: SelectedProduct.isArchived,
                            isPrincipalProduct:
                                SelectedProduct.isPrincipalProduct,
                        };
                        const requestOptions: any = {
                            body: JSON.stringify({
                                product: new_product,
                                uidEntitie: SelectedProduct.Référence,
                            }),
                            method: "PUT",
                            headers: { "Content-Type": "application/json" },
                        };

                        fetch(
                            `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/updateProduct`,
                            requestOptions
                        )
                            .then((response) => {
                                response.json().then((data) => {
                                    if (!data.error) {
                                        showToast(
                                            true,
                                            `Le produit "${designation}" a été modifié avec succès.`
                                        );
                                        setDataIsUpdated(!dataIsUpdated);
                                        saveHistoryChanges(
                                            old_product,
                                            new_product
                                        );
                                        setIsHiddenCard(!isHiddenCard);
                                        setIsEmptyDes(false);
                                        setIsEmptyRef(false);
                                        setModifProduct(false);
                                        setIsEmptyTypeProuct(false);
                                        setEditProduct("");
                                    } else {
                                        showToast(
                                            false,
                                            `Le produit ${designation} n'est pas modifié`
                                        );
                                    }
                                });
                            })
                            .catch((e: any) => {
                                console.log({ error: e.message });
                            });
                    }
                }
            }
        }
    }

    const handleEmptyVariables = () => {
        setDesignation("");
        setReference("");
        setReferenceProduct("");
        setCommentaire("");
        setTypeProduct("");
        setParentProduct("");
        setSelectedParentReference([]);
    };

    React.useEffect(() => {
        fetch(
            `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/parentProducts?uid=${uidEntity}`
        )
            .then((response) => {
                response.json().then((data) => {
                    setParentProductsOptions([]);
                    let optionsParentsProductsData: any = [];
                    data.forEach((element: any) => {
                        if (!element.Archived) {
                            if (
                                !parentProductsOptions.includes(element.Produit)
                            ) {
                                let data: Record<string, any> = {};
                                data[element.Reference] = element.Produit;
                                optionsParentsProductsData.push({
                                    label: element.Produit,
                                    value: element.Reference,
                                });
                                parentProductsOptions.push(data);
                            }
                        }

                        setOptionsParentsProducts(optionsParentsProductsData);
                    });

                    setParentProductsOptions(parentProductsOptions);
                });
            })
            .catch((e: any) => {
                console.log({ error: e.message });
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [typeProduct]);

    React.useEffect(() => {
        fetch(
            `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/productsTypesList?uidEntity=${uidEntity}`
        )
            .then((response) => {
                response.json().then((data) => {
                    setDataList(data);
                    data.forEach((element: any) => {
                        productsTypesList.push({
                            label: element.data.name,
                            value: element.data.prefix,
                        });
                    });
                });
            })
            .catch((e: any) => {
                console.log({ error: e.message });
                return referencesProductsList;
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        fetch(
            `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/referencesProductsList?uid=${uidEntity}`
        )
            .then((response) => {
                response.json().then((data) => {
                    setReferencesProductsList(data);
                });
            })
            .catch((e: any) => {
                console.log({ error: e.message });
                return referencesProductsList;
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isHiddenCard]);

    React.useEffect(() => {
        fetch(
            `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/isAffectedProductAccount`,
            {
                method: "get",
                headers: { "Content-Type": "application/json" },
            }
        )
            .then((response) => {
                response.json().then((data) => {
                    if (data.length > 0) {
                        let array: any = [];
                        let arrayModule: any = [];
                        data.forEach((el: any) => {
                            const elementUuid = el.split("|");
                            array.push(elementUuid[0]);
                            if (elementUuid[1] !== "") {
                                arrayModule.push(elementUuid[1]);
                            }
                        });
                        setSelectedModuleProductsLicence(array);
                        setSelectedProductsLicence(data);
                        setModuleLicense(arrayModule);
                    }
                });
            })
            .catch((e: any) => {
                console.log({ error: e.message });
                return selectedProductsLicence;
            });

        fetch(
            `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/isAssociatedProductPack`,
            {
                method: "get",
                headers: { "Content-Type": "application/json" },
            }
        )
            .then((response) => {
                response.json().then((data) => {
                    if (data.length > 0) {
                        setSelectedProductsAssociatedToPack(data);
                        data.forEach((el: any) => {
                            const Uuid = el.split("|");
                            if (!array.includes(Uuid[0])) {
                                array.push(Uuid[0]);
                            }
                        });
                        setSelectedOneProductAssociatedToPack(array);
                    }
                });
            })
            .catch((e: any) => {
                console.log({ error: e.message });
                return {
                    selectedProductsAssociatedToPack,
                    selectedOneProductAssociatedToPack,
                };
            });

        // eslint-disable-next-line
    }, [dataIsUpdated]);

    return (
        <React.Fragment>
            <PageTitle title="PRODUIT" />
            <div className="">
                <Container fluid style={{ marginTop: "-15px" }}>
                    <Row>
                        <Col>
                            <Breadcurmb
                                title={titleBreadcurmb}
                                breadcrumbBackgroundColor={""}
                            />
                        </Col>
                        <MessageInfo
                            message={t(
                                "Il est indisponsable de créer et configurer des produits qui sont les différentes solutions auquelles il est possible d'associer des modules"
                            )}
                        />
                    </Row>

                    <CardBody
                        style={{
                            padding: "1px",
                        }}
                    >
                        <div
                            className="row "
                            style={{
                                display: "grid",
                                gridTemplateColumns: isHiddenCard
                                    ? "2fr 1fr"
                                    : "auto",
                                gap: "4px",
                            }}
                        >
                            <TableListProducts
                                setIsHiddenCard={setIsHiddenCard}
                                isHiddenCard={isHiddenCard}
                                urlTable={urlListProductsTable}
                                setIsEditAction={setIsEditAction}
                                setDataIsUpdated={setDataIsUpdated}
                                dataIsUpdated={dataIsUpdated}
                                setSelectedRows={setSelectedRows}
                                selectedRows={selectedRows}
                                isHisotyProductModalOpened={
                                    isHisotyProductModalOpened
                                }
                                setIsHisotyProductModalOpened={
                                    setIsHisotyProductModalOpened
                                }
                                setEditProduct={setEditProduct}
                                isProductState={isProductState}
                                setProductState={setProductState}
                                isAlertupdateProductModal={
                                    isAlertupdateProductModal
                                }
                                setIsAlertupdateProductModal={
                                    setIsAlertupdateProductModal
                                }
                                setIsAlerteDissociateModuleModalOpened={
                                    setIsAlerteDissociateModuleModalOpened
                                }
                                isAlerteDissociateModuleModalOpened={
                                    isAlerteDissociateModuleModalOpened
                                }
                                setIsAssociateModuleModalOpen={
                                    setIsAssociateModuleModalOpen
                                }
                                isAssociateModuleModalOpen={
                                    isAssociateModuleModalOpen
                                }
                            />

                            {!isHiddenCard ? null : (
                                <Card className="border ml-2">
                                    <CardHeader
                                        className="Headercard  d-flex justify-content-between pb-2"
                                        style={{
                                            height: "13.7%",
                                            backgroundColor: "white",
                                            border:
                                                "1px solid rgba(0,0,0,.125)",
                                        }}
                                    >
                                        <div className=" d-flex align-items-center justify-content-center">
                                            <h4
                                                className="m-0 pl-2"
                                                style={{
                                                    font:
                                                        "normal normal 600 20px/27px Segoe UI",
                                                }}
                                            >
                                                {isEditAction
                                                    ? !isProductState
                                                        ? "Consulter un produit"
                                                        : "Modifier un produit"
                                                    : "Ajouter un produit"}
                                            </h4>
                                        </div>

                                        <div className="d-flex align-items-center ">
                                            {isProductState ? (
                                                <RefreshIcon
                                                    cursor="pointer"
                                                    height={20}
                                                    width={20}
                                                    onClick={() => {
                                                        if (isEditAction) {
                                                            setIsReset(true);
                                                            setIsResetDes(true);
                                                        } else {
                                                            setDesignation("");
                                                            setReference("");
                                                            setReferenceProduct(
                                                                ""
                                                            );
                                                            setCommentaire("");
                                                            setTypeProduct("");
                                                            setIsEmptyDes(
                                                                false
                                                            );
                                                            setIsEmptyRef(
                                                                false
                                                            );
                                                            setIsEmptyTypeProuct(
                                                                false
                                                            );
                                                            setInputValue(
                                                                false
                                                            );
                                                            setIsSalesSupport(
                                                                false
                                                            );
                                                        }
                                                        setModifProduct(false);
                                                    }}
                                                />
                                            ) : (
                                                <></>
                                            )}
                                            <div className=" bd-highlight align-items-center ml-4">
                                                <CrossIcon
                                                    cursor="pointer"
                                                    height={17}
                                                    width={17}
                                                    onClick={() => {
                                                        if (isEditAction) {
                                                            if (modifProduct) {
                                                                setIsAlertupdateProductModal(
                                                                    true
                                                                );
                                                                setIsHiddenCard(
                                                                    isHiddenCard
                                                                );
                                                            } else {
                                                                setIsHiddenCard(
                                                                    !isHiddenCard
                                                                );
                                                                setInputValue(
                                                                    false
                                                                );
                                                            }
                                                        } else {
                                                            if (modifProduct) {
                                                                setIsHiddenCard(
                                                                    isHiddenCard
                                                                );
                                                                setIsAlertupdateProductModal(
                                                                    true
                                                                );
                                                            } else {
                                                                setIsHiddenCard(
                                                                    !isHiddenCard
                                                                );
                                                                setTypeProduct(
                                                                    ""
                                                                );
                                                                setDesignation(
                                                                    ""
                                                                );
                                                                setReference(
                                                                    ""
                                                                );
                                                                setReferenceProduct(
                                                                    ""
                                                                );
                                                                setCommentaire(
                                                                    ""
                                                                );
                                                                setIsEmptyDes(
                                                                    false
                                                                );
                                                                setIsEmptyRef(
                                                                    false
                                                                );
                                                                setIsEmptyTypeProuct(
                                                                    false
                                                                );
                                                                setInputValue(
                                                                    false
                                                                );
                                                                setIsSalesSupport(
                                                                    false
                                                                );
                                                                setIsReset(
                                                                    false
                                                                );
                                                                setIsResetDes(
                                                                    false
                                                                );
                                                            }
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </CardHeader>
                                    <AvForm
                                        model={{
                                            form: "salesSupportForm",
                                            checkItOut: true,
                                        }}
                                        style={{
                                            height: "92%",
                                            background: "#FFFFFF",
                                            border:
                                                "1px solid rgba(0,0,0,.125)",
                                        }}
                                    >
                                        <CardBody
                                            style={{
                                                background: "#FFFFFF",

                                                marginTop: "4px",
                                                overflow: "auto",
                                                maxHeight: `calc(100vh - (500px + ${
                                                    isVisible ? "-71px" : "0px"
                                                }))`,
                                            }}
                                        >
                                            <AvForm>
                                                <div className="d-flex justify-content-between">
                                                    <StyledLabel>
                                                        Désignation*
                                                    </StyledLabel>
                                                    {/* <span
                                                    style={{
                                                        color: "blue",
                                                        cursor: "pointer",
                                                        border: "transparent",
                                                        backgroundColor:
                                                            "transparent",
                                                        textAlign: "center",
                                                        textDecoration:
                                                            "underline",
                                                        font:
                                                            "normal normal 600 16px/21px Segoe UI",
                                                    }}
                                                    onClick={(): void => {
                                                        // setIsOpenDescriptifAdvanced(
                                                        //     true
                                                        // );
                                                    }}
                                                    role="button"
                                                    tabIndex={0}
                                                >
                                                    {t("Advanced")}
                                                    <DisplayNameModal
                                                        setIsOpen={
                                                            setIsOpenDescriptifAdvanced
                                                        }
                                                        isOpen={
                                                            isOpenDescriptifAdvanced
                                                        }
                                                        defaultListNameValues={
                                                            languages
                                                        }
                                                        setDefaultListNameValues={
                                                            setLanguages
                                                        }
                                                    />
                                                </span> */}
                                                </div>
                                                <div
                                                    style={{
                                                        marginBottom: "10px",
                                                        pointerEvents: !isProductState
                                                            ? "none"
                                                            : "auto",
                                                        opacity: !isProductState
                                                            ? "0.8"
                                                            : "",
                                                    }}
                                                >
                                                    <StyledTextInput
                                                        style={{
                                                            opacity: "1",
                                                            borderRadius: "4px",
                                                            border: isEmptyDes
                                                                ? "1px solid red"
                                                                : "",
                                                        }}
                                                        autoFocus={
                                                            !isProductState
                                                                ? false
                                                                : true
                                                        }
                                                        type="text"
                                                        id="designationText"
                                                        name="designationText"
                                                        className="input-style"
                                                        placeholder="Saisir"
                                                        onChange={(e: any) => {
                                                            setDesignation(
                                                                e.target.value.trim()
                                                            );
                                                            setIsResetDes(
                                                                false
                                                            );
                                                            setIsEmptyDes(
                                                                false
                                                            );
                                                            if (
                                                                SelectedProduct !==
                                                                ""
                                                            ) {
                                                                setSelectedProduct(
                                                                    {
                                                                        ...SelectedProduct,
                                                                        Produit:
                                                                            e
                                                                                ?.target
                                                                                .value,
                                                                    }
                                                                );
                                                                setModifProduct(
                                                                    true
                                                                );
                                                            }
                                                        }}
                                                        value={
                                                            SelectedProduct ===
                                                            ""
                                                                ? designation
                                                                : isResetDes
                                                                ? selectedProductBeforeUpdate?.Produit
                                                                : SelectedProduct?.Produit
                                                        }
                                                    />
                                                    {isEmptyDes ? (
                                                        <div
                                                            style={{
                                                                color: "red",
                                                            }}
                                                        >
                                                            Ce champ est
                                                            obligatoire
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                            </AvForm>
                                            <AvForm>
                                                <StyledLabel>
                                                    Type du produit*
                                                </StyledLabel>
                                                {isEditAction ? (
                                                    <div
                                                        style={{
                                                            marginBottom:
                                                                "10px",
                                                            pointerEvents:
                                                                "none",
                                                            opacity: "1",
                                                        }}
                                                    >
                                                        <StyledSelectInput
                                                            ref={(ref: any) => {
                                                                setSelectInputRef(
                                                                    ref
                                                                );
                                                            }}
                                                            menuPlacement="auto"
                                                            menuPosition="fixed"
                                                            isSearchable
                                                            name="savetypeproduitinput"
                                                            placeholder={
                                                                "Saisir"
                                                            }
                                                            value={{
                                                                label:
                                                                    SelectedProduct.Typeduproduit,
                                                                value:
                                                                    SelectedProduct.Typeduproduit,
                                                            }}
                                                        />
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <div
                                                            style={{
                                                                border: isEmptyTypeProuct
                                                                    ? "1px solid red"
                                                                    : "1px solid #f8f9fa",
                                                                borderRadius:
                                                                    "4px",
                                                            }}
                                                        >
                                                            <StyledSelectInput
                                                                menuPlacement="auto"
                                                                menuPosition="fixed"
                                                                name="savetypeproduitinput"
                                                                options={
                                                                    productsTypesList
                                                                }
                                                                placeholder={
                                                                    "Saisir"
                                                                }
                                                                onChange={(
                                                                    e: any
                                                                ) => {
                                                                    onChange(e);
                                                                    setIsEmptyTypeProuct(
                                                                        false
                                                                    );
                                                                    setIsReset(
                                                                        false
                                                                    );
                                                                }}
                                                                // value={{
                                                                //     label: typeProduct,
                                                                //     value: typeProduct,
                                                                // }}
                                                                // components={{
                                                                //     NoOptionsMessage,
                                                                // }}
                                                            />
                                                        </div>
                                                        {isEmptyTypeProuct ? (
                                                            <div
                                                                style={{
                                                                    color:
                                                                        "red",
                                                                }}
                                                            >
                                                                Ce champ est
                                                                obligatoire
                                                            </div>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>
                                                )}
                                            </AvForm>
                                            <div>
                                                {SelectedProduct.SalesSupport ||
                                                isSalesSupport ? (
                                                    <div
                                                        style={{
                                                            marginLeft: "54%",
                                                        }}
                                                    >
                                                        Ce produit est un
                                                        support de vente
                                                    </div>
                                                ) : null}
                                            </div>
                                            <AvForm>
                                                <StyledLabel>
                                                    Référence*
                                                </StyledLabel>
                                                {isEditAction ? (
                                                    <div>
                                                        <StyledTextInput
                                                            type="text"
                                                            id="referenceText"
                                                            name="referenceText"
                                                            className="input-style"
                                                            placeholder="saisir"
                                                            value={
                                                                SelectedProduct.Référence
                                                            }
                                                            style={{
                                                                pointerEvents:
                                                                    "none",
                                                                opacity: "1",
                                                                borderRadius:
                                                                    "4px",
                                                            }}
                                                        />
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <div className="input-group mb-2">
                                                            <div className="input-group-prepend">
                                                                <div
                                                                    className="input-group-text"
                                                                    style={{
                                                                        fontSize:
                                                                            "14px",
                                                                    }}
                                                                >
                                                                    {
                                                                        referenceProduct
                                                                    }
                                                                </div>
                                                            </div>
                                                            <input
                                                                style={{
                                                                    fontSize:
                                                                        "14px",
                                                                    border: isEmptyRef
                                                                        ? "1px solid red"
                                                                        : "1px solid #ced4da",
                                                                }}
                                                                type="text"
                                                                className="form-control"
                                                                id="inlineFormInputGroup"
                                                                placeholder="Saisir"
                                                                value={
                                                                    reference
                                                                }
                                                                onChange={(
                                                                    e: any
                                                                ) => {
                                                                    setReference(
                                                                        e.target.value.trim()
                                                                    );
                                                                    setIsEmptyRef(
                                                                        false
                                                                    );
                                                                    setIsReset(
                                                                        false
                                                                    );
                                                                }}
                                                            ></input>
                                                        </div>
                                                        <div>
                                                            {isEmptyRef ? (
                                                                <div
                                                                    style={{
                                                                        color:
                                                                            "red",
                                                                    }}
                                                                >
                                                                    Ce champ est
                                                                    obligatoire
                                                                </div>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                            </AvForm>
                                            <AvForm>
                                                <div className="d-flex justify-content-between">
                                                    <StyledLabel>
                                                        Descriptif
                                                    </StyledLabel>
                                                    {/* <span
                                                    style={{
                                                        color: "blue",
                                                        cursor: "pointer",
                                                        border: "transparent",
                                                        backgroundColor:
                                                            "transparent",
                                                        textAlign: "center",
                                                        textDecoration:
                                                            "underline",
                                                        font:
                                                            "normal normal 600 16px/21px Segoe UI",
                                                    }}
                                                    onClick={(): void => {
                                                        // setIsOpenDescriptifAdvanced(
                                                        //     true
                                                        // );
                                                    }}
                                                    role="button"
                                                    tabIndex={0}
                                                >
                                                    {t("Advanced")}

                                                    <DisplayNameModal
                                                        setIsOpen={
                                                            setIsOpenDescriptifAdvanced
                                                        }
                                                        isOpen={
                                                            isOpenDescriptifAdvanced
                                                        }
                                                        defaultListNameValues={
                                                            languages
                                                        }
                                                        setDefaultListNameValues={
                                                            setLanguages
                                                        }
                                                    />
                                                </span> */}
                                                </div>
                                                <div
                                                    style={{
                                                        pointerEvents: !isProductState
                                                            ? "none"
                                                            : "auto",
                                                        opacity: !isProductState
                                                            ? "0.8"
                                                            : "",
                                                    }}
                                                >
                                                    <AvForm>
                                                        <StyledTextInput
                                                            style={{
                                                                pointerEvents: !isProductState
                                                                    ? "none"
                                                                    : "auto",
                                                                opacity: !isProductState
                                                                    ? "0.9"
                                                                    : "",
                                                            }}
                                                            id="example-inputddddd"
                                                            name="example-inputddddd"
                                                            placeholder="Saisir"
                                                            type="textarea"
                                                            maxlength={2000}
                                                            onChange={(
                                                                e: any
                                                            ) => {
                                                                if (
                                                                    SelectedProduct !==
                                                                    ""
                                                                ) {
                                                                    setSelectedProduct(
                                                                        {
                                                                            ...SelectedProduct,
                                                                            Descriptif:
                                                                                e
                                                                                    ?.target
                                                                                    .value,
                                                                        }
                                                                    );
                                                                    setModifProduct(
                                                                        true
                                                                    );
                                                                    setIsReset(
                                                                        false
                                                                    );
                                                                } else {
                                                                    setCommentaire(
                                                                        e.target
                                                                            .value
                                                                    );
                                                                    setInputValue(
                                                                        true
                                                                    );
                                                                    setIsReset(
                                                                        false
                                                                    );
                                                                    setCounter(
                                                                        2000 -
                                                                            e
                                                                                .target
                                                                                .value
                                                                                .length
                                                                    );
                                                                }
                                                            }}
                                                            value={
                                                                SelectedProduct ===
                                                                ""
                                                                    ? commentaire
                                                                    : isReset
                                                                    ? selectedProductBeforeUpdate?.Descriptif
                                                                    : SelectedProduct?.Descriptif
                                                            }
                                                        />
                                                        <div className="d-flex justify-content-end ">
                                                            {isEditAction ? (
                                                                isReset ? (
                                                                    <small>
                                                                        Nombre
                                                                        de
                                                                        caractère
                                                                        maximal
                                                                        :
                                                                        {2000 -
                                                                            selectedProductBeforeUpdate
                                                                                .Descriptif
                                                                                .length}
                                                                        /2000
                                                                    </small>
                                                                ) : SelectedProduct
                                                                      .Descriptif
                                                                      .length ===
                                                                  0 ? (
                                                                    <small>
                                                                        Nombre
                                                                        de
                                                                        caractère
                                                                        maximal
                                                                        : 2000
                                                                    </small>
                                                                ) : (
                                                                    <small>
                                                                        Nombre
                                                                        de
                                                                        caractère
                                                                        maximal
                                                                        :
                                                                        {2000 -
                                                                            SelectedProduct
                                                                                .Descriptif
                                                                                .length}
                                                                        /2000
                                                                    </small>
                                                                )
                                                            ) : inputValue ? (
                                                                <small>
                                                                    Nombre de
                                                                    caractère
                                                                    maximal :
                                                                    {counter}
                                                                    /2000
                                                                </small>
                                                            ) : (
                                                                <small>
                                                                    Nombre de
                                                                    caractère
                                                                    maximal :
                                                                    2000
                                                                </small>
                                                            )}
                                                        </div>
                                                    </AvForm>
                                                </div>
                                            </AvForm>
                                        </CardBody>
                                    </AvForm>
                                    <CardFooter
                                        className="Footercard p-0"
                                        style={{
                                            background: "#FFFFFF",
                                            marginBottom: "2%",
                                        }}
                                    >
                                        <div className="d-flex flex-wrap d-flex flex-row-reverse bd-highlight">
                                            <div className="p-2 bd-highlight">
                                                <StyledButton
                                                    disabled={
                                                        isProductState
                                                            ? false
                                                            : true
                                                    }
                                                    rounded
                                                    variant="primary"
                                                    outline={false}
                                                    size="sm"
                                                    style={{
                                                        color: "white",
                                                        padding: "0px",
                                                        width: "150px",
                                                        height: "45px",
                                                    }}
                                                    onClick={(e: any) => {
                                                        if (
                                                            storeShot.SelectedProduct !==
                                                            ""
                                                        ) {
                                                            updateProduct();
                                                        } else {
                                                            handleClick(e);
                                                        }
                                                    }}
                                                >
                                                    Valider
                                                </StyledButton>
                                            </div>
                                            <div className="p-2 bd-highlight">
                                                <StyledButton
                                                    disabled={false}
                                                    rounded
                                                    variant="light"
                                                    outline={true}
                                                    size="sm"
                                                    style={{
                                                        background: "white",
                                                        color: "black",
                                                        padding: "0px",
                                                        width: "150px",
                                                        height: "45px",
                                                    }}
                                                    onClick={() => {
                                                        if (isEditAction) {
                                                            if (modifProduct) {
                                                                setIsAlertupdateProductModal(
                                                                    true
                                                                );
                                                                setIsHiddenCard(
                                                                    isHiddenCard
                                                                );
                                                            } else {
                                                                setIsHiddenCard(
                                                                    !isHiddenCard
                                                                );
                                                                setInputValue(
                                                                    false
                                                                );
                                                            }
                                                        } else {
                                                            if (modifProduct) {
                                                                setIsHiddenCard(
                                                                    isHiddenCard
                                                                );
                                                                setIsAlertupdateProductModal(
                                                                    true
                                                                );
                                                            } else {
                                                                setIsHiddenCard(
                                                                    !isHiddenCard
                                                                );
                                                                setTypeProduct(
                                                                    ""
                                                                );
                                                                setCommentaire(
                                                                    ""
                                                                );
                                                                setDesignation(
                                                                    ""
                                                                );
                                                                setReference(
                                                                    ""
                                                                );
                                                                setReferenceProduct(
                                                                    ""
                                                                );
                                                                setIsEmptyDes(
                                                                    false
                                                                );
                                                                setIsEmptyRef(
                                                                    false
                                                                );
                                                                setIsEmptyTypeProuct(
                                                                    false
                                                                );
                                                                setInputValue(
                                                                    false
                                                                );
                                                                setIsSalesSupport(
                                                                    false
                                                                );
                                                                setIsReset(
                                                                    false
                                                                );
                                                                setIsResetDes(
                                                                    false
                                                                );
                                                            }
                                                        }
                                                    }}
                                                >
                                                    Annuler
                                                </StyledButton>
                                            </div>
                                        </div>
                                    </CardFooter>
                                </Card>
                            )}
                        </div>
                    </CardBody>
                </Container>
            </div>
            <HistoryProductsModal
                isModalOpened={isHisotyProductModalOpened}
                setIsModalOpened={setIsHisotyProductModalOpened}
                selectedRows={selectedRows}
                refHistoryProduct={refHistoryProduct}
            />
            <AlerteUpdateProductModal
                isAlertupdateProductModal={isAlertupdateProductModal}
                setIsAlertupdateProductModal={setIsAlertupdateProductModal}
                setIsHiddenCard={setIsHiddenCard}
                isHiddenCard={isHiddenCard}
                setModifProduct={setModifProduct}
                setEditProduct={setEditProduct}
                editProduct={editProduct}
                selectedRows={selectedRows}
                setIsEditAction={setIsEditAction}
                setIsAlerteDissociateModuleModalOpened={
                    setIsAlerteDissociateModuleModalOpened
                }
                isAlerteDissociateModuleModalOpened={
                    isAlerteDissociateModuleModalOpened
                }
                setIsAssociateModuleModalOpen={setIsAssociateModuleModalOpen}
                isAssociateModuleModalOpen={isAssociateModuleModalOpen}
                setIsEmptyDes={setIsEmptyDes}
                setIsEmptyRef={setIsEmptyRef}
                setIsEmptyTypeProuct={setIsEmptyTypeProuct}
                setIsSalesSupport={setIsSalesSupport}
            />
            <ActiveLicenseAlert
                isEditAction={isEditAction}
                setIsHiddenCard={setIsHiddenCard}
                isHiddenCard={isHiddenCard}
                setIsEmptyDes={setIsEmptyDes}
                setDataIsUpdated={setDataIsUpdated}
                dataIsUpdated={dataIsUpdated}
            />
        </React.Fragment>
    );
}
