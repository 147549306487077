import React from "react";

import {
    StyledButton,
    StyledModal,
    CrossIcon,
    StyledIconButton,
    StyledLabel,
} from "@aureskonnect/react-ui";
import { ModalBody, ModalHeader, ModalFooter } from "reactstrap";

import { showToast } from "@components/Common/ToastShow";
import { cryptWord, decryptWord } from "..";

import "../Overview/index.css";

type AlertConfirmationAssociateModuleToProductModaltype = {
    setIsAlerteConfirmationOpened: Function;
    setIsModalOpened: Function;
    handleValidateAddModules: Function;
    isAlerteConfirmationOpened: boolean;
    listLicensesProducts: any;
    listIuudsModulesSelected: any;
    SelectedProduct: any;
};
export default function AlertConfirmationAssociateModuleToProduct({
    handleValidateAddModules,
    setIsAlerteConfirmationOpened,
    setIsModalOpened,
    isAlerteConfirmationOpened,
    listIuudsModulesSelected,
    listLicensesProducts,
    SelectedProduct,
}: AlertConfirmationAssociateModuleToProductModaltype) {
    const [finallyResult, setFinallyResult] = React.useState<any>(true);

    function handleAddNewLicense() {
        setFinallyResult(false);
        let listSelectedLicensesCertificate: any = {};
        let listKey: any = [];
        let listcryptedLicenses: any = [];

        handleValidateAddModules();
        Object.keys(listLicensesProducts).forEach((key: any) => {
            listKey.push(key);

            // *** add licenses

            const decryptLicenseInfo = JSON.parse(
                decryptWord(listLicensesProducts[key]["license"])
            );

            Object.keys(decryptLicenseInfo).forEach((element: any) => {
                const keys = element.split("|");
                if (decryptLicenseInfo[element] !== undefined) {
                    if (keys[0] === SelectedProduct.Uuid) {
                        //  data info licenses
                        if (
                            decryptLicenseInfo[element]["modules"] !== undefined
                        ) {
                            if (
                                decryptLicenseInfo[element]["modules"]
                                    .modulesUuid !== undefined
                            ) {
                                if (listIuudsModulesSelected.length > 0) {
                                    listIuudsModulesSelected.forEach(
                                        (iuudModule: any) => {
                                            if (
                                                !decryptLicenseInfo[element][
                                                    "modules"
                                                ].modulesUuid.includes(
                                                    iuudModule
                                                )
                                            ) {
                                                decryptLicenseInfo[element][
                                                    "modules"
                                                ].modulesUuid.push(iuudModule);
                                            }
                                        }
                                    );
                                }
                            }
                        }

                        // data info certif
                        let uuidPack;
                        if (keys[0] !== keys[1]) {
                            // produit et pack --> 0 : uuid produit 1: uuid pack
                            uuidPack = keys[1];
                        } else {
                            // produit sans pack --> 0 : uuid produit
                            uuidPack = "";
                        }
                        listSelectedLicensesCertificate[keys[0]] = {
                            pack: uuidPack,
                            modules: listIuudsModulesSelected,
                            blocked: decryptLicenseInfo[element].blocked,
                            actived: decryptLicenseInfo[element].actived,
                            demoConfiguration:
                                decryptLicenseInfo[element].demoConfiguration,
                            managementRules:
                                decryptLicenseInfo[element].managementRules,
                        };
                    }
                }
            });
            const cryptedLicenses = cryptWord(
                JSON.stringify(decryptLicenseInfo)
            );
            let listcryptedLicenses2 = {
                key,
                cryptedLicenses,
            };
            listcryptedLicenses.push(listcryptedLicenses2);
        });
        fetch(`${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/addLicence`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                uuid: listKey,
                dataLicence: listcryptedLicenses,
            }),
        })
            .then((response) => {
                response.json().then((data) => {
                    if (!data.error) {
                        // *** add certificate
                        const cryptedDataCertificatedLicenses = cryptWord(
                            JSON.stringify(listSelectedLicensesCertificate)
                        );
                        fetch(
                            `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/addCertificateLicenses`,
                            {
                                headers: {
                                    "Content-Type":
                                        "application/x-www-form-urlencoded",
                                },
                                body: `uuidEntity=${listKey}&dataCertificateLicenses=${cryptedDataCertificatedLicenses}`,
                                method: "POST",
                            }
                        )
                            .then((result) => result.json())
                            .then((response: any) => {
                                if (response) {
                                    // eslint-disable-next-line no-lone-blocks
                                    setIsAlerteConfirmationOpened(
                                        !isAlerteConfirmationOpened
                                    );
                                    setIsModalOpened(false);
                                    showToast(
                                        true,
                                        "Les modifications sur le certificat ont été effectuées avec succès"
                                    );
                                }
                            })
                            .catch((error: any) => {
                                console.log({
                                    errorfetch: error.message,
                                });
                                return false;
                            })
                            .finally(() => {
                                setFinallyResult(true);
                            });
                    }
                });
            })
            .catch((error: any) => {
                console.log({ errorfetch: error.message });
                return false;
            });
    }

    return (
        <div>
            <StyledModal
                className=""
                centered
                isOpen={isAlerteConfirmationOpened}
            >
                <ModalHeader className="d-flex align-items-center">
                    <div
                        className="d-flex align-items-center justify-content-between "
                        style={{
                            height: "20px",
                            width: "200px",
                            display: "grid",
                            gridTemplateColumns: "1.8fr 0.2fr",
                            gridTemplateRows: "1fr",
                            gap: "300px",
                        }}
                    >
                        <p className="title-text-style">CONFIRMATION</p>
                        <StyledIconButton icon="CrossIcon">
                            <CrossIcon
                                cursor="pointer"
                                height={10}
                                width={10}
                                onClick={() => {
                                    setIsAlerteConfirmationOpened(
                                        !isAlerteConfirmationOpened
                                    );
                                }}
                            />
                        </StyledIconButton>
                    </div>
                </ModalHeader>

                <ModalBody>
                    <div
                        style={{
                            marginLeft: "20px",
                            marginTop: "20px",
                            marginBottom: "20px",
                        }}
                    >
                        <StyledLabel>
                            Attention ce produit possède des licences actives.
                            Voulez-vous affecter ce module à tous les clients
                            qui possèdent cette licence ?
                        </StyledLabel>
                    </div>
                </ModalBody>
                <ModalFooter
                    className="card-footer d-flex align-items-center justify-content-end"
                    style={{
                        background: "#FFFFFF",
                        height: "fit-content",
                    }}
                >
                    <StyledButton
                        disabled={false}
                        rounded
                        variant="light"
                        outline={true}
                        size="sm"
                        style={{
                            background: "white",
                            color: "black",
                            padding: "0px",
                            width: "150px",
                            height: "45px",
                        }}
                        onClick={() => {
                            handleValidateAddModules();
                            setIsAlerteConfirmationOpened(
                                !isAlerteConfirmationOpened
                            );
                            setIsModalOpened(false);
                        }}
                    >
                        Annuler
                    </StyledButton>
                    <StyledButton
                        className="ml-2"
                        disabled={!finallyResult}
                        rounded
                        variant="primary"
                        outline={false}
                        size="sm"
                        style={{
                            color: "white",
                            padding: "0px",
                            width: "150px",
                            height: "45px",
                        }}
                        onClick={() => {
                            handleAddNewLicense();
                        }}
                    >
                        Valider
                    </StyledButton>
                </ModalFooter>
            </StyledModal>
        </div>
    );
}
