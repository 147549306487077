import React from "react";
import {
    StyledButton,
    StyledModal,
    CrossIcon,
    StyledIconButton,
    StyledLabel,
} from "@aureskonnect/react-ui";
import { ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { showToast } from "@components/Common/ToastShow";
import { useSnapshot } from "valtio";
import { store } from "@pages/store";
import "../Overview/index.css";

type AlerteModaltype = {
    isAlerteModalOpened: boolean;
    setIsAlerteModalOpened: Function;
    setDataIsUpdated: React.Dispatch<React.SetStateAction<number | boolean>>;
    dataIsUpdated: number | boolean;
    selectedRows: any;
    ArchiveActiveProfil: boolean;
    actionMotifModal: string;
};
export default function AlerteModalProfil({
    isAlerteModalOpened,
    setIsAlerteModalOpened,
    setDataIsUpdated,
    selectedRows,
    ArchiveActiveProfil,
    actionMotifModal,
}: AlerteModaltype) {
    const uidEntity = localStorage.getItem("Uid_Entitie_User");
    const { SelectedProfil } = useSnapshot(store);

    let profilUid: any = [];

    function ArchiverActiverProfil(etat: string) {
        selectedRows.forEach((element: any) => {
            profilUid.push(element.UidProfil);
        });
        fetch(
            `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/updateEtatProfil?uidProfil=${profilUid}&Action=${etat}&motif=undefined`,
            {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
            }
        )
            .then(async (response) => {
                if (response.status === 200) {
                    saveHistoryChanges(etat);
                    showToast(true, `Les profil ont été ${etat} avec succès`);
                    setTimeout(() => {
                        setDataIsUpdated(true);
                    }, 2000);
                } else {
                    showToast(false, "Erreur, Veuillez réessayer svp !");
                }
            })
            .catch((error) => {
                console.error("There was an error!", error);
            });
    }

    function ArchiverActiverSelectedProfil(etat: string) {
        fetch(
            `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/updateEtatSelectedProfil?uidProfil=${SelectedProfil.UidProfil}&Action=${etat}&motif=undefined`,

            {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
            }
        )
            .then(async (response) => {
                if (response.status === 200) {
                    saveHistoryChanges(etat);
                    showToast(true, `Le profil a été ${etat} avec succès`);
                    setTimeout(() => {
                        setDataIsUpdated(true);
                    }, 2000);
                } else {
                    showToast(false, "Erreur, Veuillez réessayer svp !");
                }
            })
            .catch((error) => {
                console.error("There was an error!", error);
            });
    }
    // console.log(selectedRows);
    function saveHistoryChanges(etat: string) {
        if (selectedRows.length < 1) {
            const oldData = {
                profil: SelectedProfil.Designation,
                etat: SelectedProfil.EtatProfil,
                type: SelectedProfil.Type,
                // motif: SelectedProfil.MotifProfil,
                description: SelectedProfil.Description,
                designation: SelectedProfil.Designation,
                uidProfil: SelectedProfil.UidProfil,
            };
            const newData = {
                profil: SelectedProfil.Designation,
                etat: etat,
                type: SelectedProfil.Type,
                // motif: "undefined",
                description: SelectedProfil.Description,
                designation: SelectedProfil.Designation,
                uidProfil: SelectedProfil.UidProfil,
            };
            fetch(
                `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/HistoryChanges`,
                {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        oldData: oldData,
                        newData: newData,
                        uidUpdatedData: uidEntity,
                        typeHistory: "4",
                    }),
                }
            )
                .then((response) => {
                    response.json().then((data) => {
                        return data.error;
                    });
                })
                .catch((e: any) => {
                    return false;
                });
        } else {
            selectedRows.forEach((element: any) => {
                const oldData = {
                    profil: element.Designation,
                    etat: element.EtatProfil,
                    type: element.Type,
                    motif: element.MotifProfil,
                    description: element.Description,
                    designation: element.Designation,
                    uidProfil: element.UidProfil,
                };
                const newData = {
                    profil: element.Designation,
                    etat: etat,
                    type: element.Type,
                    motif: "undefined",
                    description: element.Description,
                    designation: element.Designation,
                    uidProfil: element.UidProfil,
                };
                fetch(
                    `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/HistoryChanges`,
                    {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({
                            oldData: oldData,
                            newData: newData,
                            uidUpdatedData: uidEntity,
                            typeHistory: "4",
                        }),
                    }
                )
                    .then((response) => {
                        response.json().then((data) => {
                            return data.error;
                        });
                    })
                    .catch((e: any) => {
                        return false;
                    });
            });
        }
    }
    return (
        <div>
            <StyledModal
                className=""
                centered
                isOpen={isAlerteModalOpened}
            >
                <ModalHeader className="d-flex align-items-center">
                    <div
                        className="d-flex align-items-center justify-content-between "
                        style={{
                            height: "20px",
                            width: "200px",
                            display: "grid",
                            gridTemplateColumns: "1.8fr 0.2fr",
                            gridTemplateRows: "1fr",
                            gap: "300px",
                        }}
                    >
                        <p className="title-text-style">CONFIRMATION</p>
                        <StyledIconButton icon="CrossIcon">
                            <CrossIcon
                                cursor="pointer"
                                height={10}
                                width={10}
                                onClick={() => {
                                    setIsAlerteModalOpened(
                                        !isAlerteModalOpened
                                    );
                                }}
                            />
                        </StyledIconButton>
                    </div>
                </ModalHeader>

                <ModalBody>
                    <div
                        style={{
                            marginLeft: "50px",
                            marginTop: "20px",
                            marginBottom: "20px",
                        }}
                    >
                        <StyledLabel>
                            {!ArchiveActiveProfil
                                ? "Voulez vous confirmer l'archivage ?"
                                : "Voulez vous confirmer l'activation ?"}
                        </StyledLabel>
                    </div>
                </ModalBody>
                <ModalFooter
                    className="card-footer d-flex align-items-center justify-content-end"
                    style={{
                        background: "#FFFFFF",
                        height: "fit-content",
                    }}
                >
                    <StyledButton
                        disabled={false}
                        rounded
                        variant="light"
                        outline={true}
                        size="sm"
                        style={{
                            background: "white",
                            color: "black",
                            padding: "0px",
                            width: "150px",
                            height: "45px",
                        }}
                        onClick={() => {
                            setIsAlerteModalOpened(!isAlerteModalOpened);
                        }}
                    >
                        Annuler
                    </StyledButton>

                    <StyledButton
                        className="ml-2"
                        disabled={false}
                        rounded
                        variant="primary"
                        outline={false}
                        size="sm"
                        style={{
                            color: "white",
                            padding: "0px",
                            width: "150px",
                            height: "45px",
                        }}
                        onClick={() => {
                            if (actionMotifModal === "Activation Profil") {
                                if (selectedRows.length < 1) {
                                    ArchiverActiverSelectedProfil("Activé");
                                } else {
                                    ArchiverActiverProfil("Activé");
                                }
                            } else {
                                if (selectedRows.length < 1) {
                                    ArchiverActiverSelectedProfil("Archivé");
                                } else {
                                    ArchiverActiverProfil("Archivé");
                                }
                            }
                            setIsAlerteModalOpened(!isAlerteModalOpened);
                        }}
                    >
                        Valider
                    </StyledButton>
                </ModalFooter>
            </StyledModal>
        </div>
    );
}
