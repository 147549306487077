import { DynamicTable } from "@maherunlocker/custom-react-table";
import React from "react";
import { useSnapshot } from "valtio";
import { store } from "../store";

const Nodropdown = () => {
    return null;
};

export default function TableConsultLicences() {
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const { SelectedAccount, isHistoryLicences } = useSnapshot(store);

    let url = "";
    if (isHistoryLicences === true) {
        url = `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/consultCertificates?uuidEntity=${SelectedAccount.uuidEntity}&uuid=${SelectedAccount.Clé}`;
    } else {
        url = `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/ConsultLicences?infoLicences=${SelectedAccount.infoLicence}`;
    }

    return (
        <React.Fragment>
            <DynamicTable
                canSort
                url={url}
                maxHeight={"calc(100vh - 445px)"}
                minHeight={"calc(100vh - 445px)"}
                canExpand
                canSelect
                customSelect
                showFilter
                actionColumn={Nodropdown}
                showGlobalFilter
                filterActive={filterActive}
                setLocalFilterActive={setLocalFilterActive}
            />
        </React.Fragment>
    );
}
