import React, { useState } from "react";

import { ModalBody, ModalHeader, CardFooter } from "reactstrap";
import { useTranslation } from "react-i18next";
import { StyledModal, StyledButton, StyledLabel } from "@aureskonnect/react-ui";

import { useSnapshot } from "valtio";

import {
    setSelectedModule,
    store,
    setModifProduct,
    setIsAlertupdateProductModal,
    setEditProduct,
    setSelectedModulesToDissociate,
    setIsAssociateModuleModalOpen,
    setIsAffected,
} from "../store";

import AlertConfirmationAssociateModuleToProduct from "./AlertConfirmationAssociateModuleToProduct";
import TableAssociateModules from "./TableAssociateModules";
import { showToast } from "@components/Common/ToastShow";
import AlerteModal from "./AlerteModal";

import "../Motifs/index.css";

type AssociateModulesToProductsModalType = {
    isModalOpened: boolean;
    setIsModalOpened: Function;
    setDataIsUpdated: React.Dispatch<React.SetStateAction<any>>;
    dataIsUpdated: any;
    product: any;
};

export default function AssociateModulesToProductsModal({
    isModalOpened,
    setIsModalOpened,
    setDataIsUpdated,
    dataIsUpdated,
    product,
}: AssociateModulesToProductsModalType) {
    const uidEntity = localStorage.getItem("Uid_Entitie_User");
    const { t } = useTranslation();
    const [listModulesSelected, setListModulesSelected] = useState([]);

    const [listNameModulesSelected, setListNameModulesSelected] = useState([]);
    const [isAlerteModalOpened, setIsAlerteModalOpened] = React.useState(false);
    const [
        isAlerteConfirmationOpened,
        setIsAlerteConfirmationOpened,
    ] = React.useState(false);
    const [isUsedLicenses, setIsUsedLicenses] = React.useState(false);

    const [selectedModulesDissocié, setSelectedModulesDissocié] = useState([]);
    const [listLicensesProducts, setListLicensesProducts] = useState([]);
    const [
        selectedModulesNameDissocié,
        setSelectedModulesNameDissocié,
    ] = useState([]);
    const [isClick, setIsClick] = React.useState(false);
    const {
        SelectedProduct,
        modifProduct,
        isAlertupdateProductModal,
        editProduct,
    } = useSnapshot(store);
    const [
        listIuudsModulesSelected,
        setListIuudsModulesSelected,
    ] = React.useState([]);

    function saveHistoryChanges(oldData: any, newData: any) {
        fetch(`${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/HistoryChanges`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                oldData: oldData,
                newData: newData,
                uidUpdatedData: uidEntity,
                typeHistory: "2",
            }),
        })
            .then((response) => {
                response.json().then((data) => {
                    return data.error;
                });
            })
            .catch((e: any) => {
                return false;
            });
    }

    function dissociationModuleFromProducts() {
        setIsClick(!isClick);
        let RefProduit: any = [];
        let RefModule: any = [];
        selectedModulesDissocié.forEach((el: any) => {
            RefProduit.push(el.referenceProduit);
            RefModule.push(el.referenceModule);
        });
        const old_module = {
            name: SelectedProduct.Produit,
            comment: SelectedProduct.Descriptif,
            reference: SelectedProduct.Référence,
            salesSupport: SelectedProduct.SalesSupport,
            isArchived: SelectedProduct.isArchived,
            isPrincipalProduct: SelectedProduct.isPrincipalProduct,
            modules: SelectedProduct.ProduitModule,
        };
        const new_module = {
            name: SelectedProduct.Produit,
            comment: SelectedProduct.Descriptif,
            reference: SelectedProduct.Référence,
            salesSupport: SelectedProduct.SalesSupport,
            isArchived: SelectedProduct.isArchived,
            isPrincipalProduct: SelectedProduct.isPrincipalProduct,
            modules: selectedModulesNameDissocié,
            dissociate: true,
        };
        fetch(
            `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/dissociateModule?uid=${RefProduit[0]}&referenceProduit=${RefModule}`,
            {
                method: "put",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    uid: RefProduit[0],
                    referenceProduit: RefModule,
                }),
            }
        )
            .then((response) => {
                if (response.status === 200 && response.ok === true) {
                    for (
                        let i: any = 0;
                        i < selectedModulesNameDissocié.length;
                        i++
                    ) {
                        const old_module = {
                            name: selectedModulesNameDissocié,
                            reference: RefModule[i],
                            modules: SelectedProduct.Produit,
                        };
                        const new_module = {
                            name: selectedModulesNameDissocié,
                            reference: RefModule[i],
                            modules: SelectedProduct.Produit,
                            associate: false,
                        };
                        saveHistoryChanges(old_module, new_module);
                    }
                    showToast(
                        true,
                        `La dissociation a été effectuée avec succès.`
                    );
                    setIsModalOpened(!isModalOpened);
                    setDataIsUpdated(!dataIsUpdated);
                    setIsClick(true);
                    setModifProduct(false);
                    setEditProduct("");
                    setListModulesSelected([]);
                    setListIuudsModulesSelected([]);
                    setSelectedModulesDissocié([]);
                    setListNameModulesSelected([]);
                    setSelectedModulesNameDissocié([]);
                    setSelectedModulesToDissociate(false);
                    saveHistoryChanges(old_module, new_module);
                } else if (response.status === 500) {
                    showToast(
                        false,
                        `Ouups! erreur lors de la dissociation du produit, veuillez réessayer SVP`
                    );
                }
            })
            .catch((e: any) => {
                console.log({ error: e.message });
            });
    }

    function AssociateModulesToProduct() {
        setIsClick(!isClick);
        const old_module = {
            name: SelectedProduct.Produit,
            comment: SelectedProduct.Descriptif,
            reference: SelectedProduct.Référence,
            salesSupport: SelectedProduct.SalesSupport,
            isArchived: SelectedProduct.isArchived,
            isPrincipalProduct: SelectedProduct.isPrincipalProduct,
            modules: SelectedProduct.ProduitModule,
        };
        const new_module = {
            name: SelectedProduct.Produit,
            comment: SelectedProduct.Descriptif,
            reference: SelectedProduct.Référence,
            salesSupport: SelectedProduct.SalesSupport,
            isArchived: SelectedProduct.isArchived,
            isPrincipalProduct: SelectedProduct.isPrincipalProduct,
            modules: listNameModulesSelected,
            dissociate: false,
        };
        fetch(
            `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/associateModulesToProduct`,
            {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    referenceProduct: listModulesSelected,
                    referenceParentProduct: product.Référence,
                }),
            }
        )
            .then((response) => {
                if (response.status === 200 && response.ok === true) {
                    for (
                        let i: any = 0;
                        i < listNameModulesSelected.length;
                        i++
                    ) {
                        const old_module = {
                            name: listNameModulesSelected[i],
                            reference: listModulesSelected[i],
                            modules: product.Produit,
                        };
                        const new_module = {
                            name: listNameModulesSelected[i],
                            reference: listModulesSelected[i],
                            modules: product.Produit,
                            associate: true,
                        };
                        saveHistoryChanges(old_module, new_module);
                    }
                    showToast(
                        true,
                        `L'association a été effectuée avec succès.`
                    );
                    setDataIsUpdated(!dataIsUpdated);
                    // if (!isUsedLicenses) {
                    //     setIsModalOpened(!isModalOpened);
                    // }
                    setIsModalOpened(!isModalOpened);
                    setIsClick(true);
                    setEditProduct("");
                    setModifProduct(false);
                    setSelectedModulesToDissociate(false);
                    setListModulesSelected([]);
                    setListIuudsModulesSelected([]);
                    setSelectedModulesDissocié([]);
                    setListNameModulesSelected([]);
                    setSelectedModulesNameDissocié([]);
                    // setIsAssociateModuleModalOpen(false);
                    // if (!isUsedLicenses) {
                    setIsAssociateModuleModalOpen(false);

                    saveHistoryChanges(old_module, new_module);
                } else if (response.status === 500) {
                    showToast(
                        false,
                        `Ouups! erreur lors de l'association au produit, veuillez réessayer SVP`
                    );
                }
            })
            .catch((e: any) => {
                console.log({ error: e.message });
            });
    }

    function handleValidateAddModules() {
        if (editProduct === "") {
            setSelectedModule([]);
            setSelectedModulesDissocié([]);
            setSelectedModulesNameDissocié([]);
            setListNameModulesSelected([]);
            setIsModalOpened(!isModalOpened);
        } else {
            if (selectedModulesDissocié.length > 0) {
                if (listModulesSelected.length === 0) {
                    if (isClick === false) {
                        dissociationModuleFromProducts();
                        setListNameModulesSelected([]);
                        setSelectedModulesDissocié([]);
                    }
                } else {
                    if (isClick === false) {
                        dissociationModuleFromProducts();
                        // AssociateModulesToProduct();
                        handleAddModulesWithNewCertificate();
                        setListNameModulesSelected([]);
                        setSelectedModulesDissocié([]);
                    }
                }
            } else {
                if (listModulesSelected.length === 0) {
                    if (isClick === false) {
                        if (!isUsedLicenses) {
                            setIsModalOpened(!isModalOpened);
                        }
                        setListNameModulesSelected([]);
                        setSelectedModulesDissocié([]);
                        setListModulesSelected([]);
                        setListIuudsModulesSelected([]);
                        setSelectedModulesNameDissocié([]);
                        setEditProduct("");
                        setSelectedModulesToDissociate(false);
                        setModifProduct(false);
                    }
                } else {
                    if (isClick === false) {
                        // AssociateModulesToProduct();
                        handleAddModulesWithNewCertificate();
                        setListNameModulesSelected([]);
                        setSelectedModulesDissocié([]);
                    }
                }
            }
        }
    }

    function handleAddModulesWithNewCertificate() {
        const listUuids: any[] = [];
        listUuids.push(SelectedProduct.Uuid);

        fetch(
            `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/usedLicenses?listUuids=${listUuids}`,
            {
                headers: {
                    contentType: "application/json",
                },
            }
        )
            .then((result) => result.json())
            .then((response) => {
                if (!response.error) {
                    if (Object.keys(response.message).length > 0) {
                        setListLicensesProducts(response.message);
                        setIsUsedLicenses(true);
                        setIsAlerteConfirmationOpened(
                            !isAlerteConfirmationOpened
                        );
                    }
                } else {
                    setIsUsedLicenses(false);
                    // handleValidateAddModules();
                    AssociateModulesToProduct();
                }
            })
            .catch((e: any) => {
                console.log({ error: e.message });
            });
    }

    React.useEffect(() => {
        setModifProduct(false);
        setIsClick(false);
    }, [dataIsUpdated]);

    return (
        <React.Fragment>
            <StyledModal
                className="history-modal-style"
                isOpen={isModalOpened}
                style={{
                    marginTop: "12px",
                    maxWidth: "1350px",
                    maxHeight: "100px",
                    borderRadius: "10px",
                }}
            >
                <ModalHeader
                    className=""
                    toggle={() => {
                        if (modifProduct === true) {
                            setIsModalOpened(isModalOpened);
                            setIsAlertupdateProductModal(
                                !isAlertupdateProductModal
                            );
                        } else {
                            setIsModalOpened(!isModalOpened);
                            setModifProduct(false);
                            setEditProduct("");
                            setSelectedModule([]);
                            setSelectedModulesDissocié([]);
                            setSelectedModulesNameDissocié([]);
                            setListNameModulesSelected([]);
                            setIsAffected(false);
                        }
                    }}
                >
                    <div
                        className="d-flex align-items-center "
                        style={{
                            height: "35px",
                            display: "grid",
                            gridTemplateColumns: "2.8fr 0.2fr",
                            gridTemplateRows: "1fr",
                            gap: "1030px",
                        }}
                    >
                        <StyledLabel
                            style={{
                                width: "max-content",
                            }}
                        >
                            ASSOCIER DE(S) PRODUIT(S) À UN MODULE :{""}
                            {SelectedProduct.Produit}
                        </StyledLabel>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <TableAssociateModules
                        URL={`${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/modulesToAssociate?uid=${uidEntity}&isArchivedProducts=false`} //8ecfe5ee-926b-423e-8050-c911dd81c147"
                        setListModulesSelected={setListModulesSelected}
                        setListIuudsModulesSelected={
                            setListIuudsModulesSelected
                        }
                        setListNameModulesSelected={setListNameModulesSelected}
                        setSelectedModulesDissocié={setSelectedModulesDissocié}
                        setSelectedModulesNameDissocié={
                            setSelectedModulesNameDissocié
                        }
                        setEditProduct={setEditProduct}
                        setModifProduct={setModifProduct}
                    />
                </ModalBody>
                <CardFooter
                    className="d-flex justify-content-end"
                    style={{
                        background: "#FFFFFF",
                    }}
                >
                    <div
                        className="row"
                        style={{
                            gap: "10px",
                            marginRight: "0px",
                        }}
                    >
                        <div>
                            <StyledButton
                                disabled={false}
                                rounded
                                variant="light"
                                outline={true}
                                size="sm"
                                style={{
                                    background: "white",
                                    color: "black",
                                    padding: "0px",
                                    width: "150px",
                                    height: "45px",
                                }}
                                onClick={() => {
                                    if (modifProduct === true) {
                                        setIsModalOpened(isModalOpened);
                                        setIsAlertupdateProductModal(
                                            !isAlertupdateProductModal
                                        );
                                    } else {
                                        setEditProduct("");
                                        setSelectedModule([]);
                                        setSelectedModulesDissocié([]);
                                        setSelectedModulesNameDissocié([]);
                                        setListNameModulesSelected([]);
                                        setModifProduct(false);
                                        setIsAffected(false);
                                        setIsModalOpened(!isModalOpened);
                                    }
                                }}
                            >
                                {t("Cancel")}
                            </StyledButton>
                        </div>
                        <div>
                            <StyledButton
                                disabled={false}
                                rounded
                                variant="primary"
                                outline={false}
                                size="sm"
                                style={{
                                    color: "white",
                                    padding: "0px",
                                    width: "150px",
                                    height: "45px",
                                }}
                                onClick={() => {
                                    handleValidateAddModules();
                                }}
                            >
                                {t("Validate")}
                            </StyledButton>
                        </div>
                    </div>
                </CardFooter>
            </StyledModal>
            <AlerteModal
                isAlerteModalOpened={isAlerteModalOpened}
                setIsAlerteModalOpened={setIsAlerteModalOpened}
                setDataIsUpdated={setDataIsUpdated}
                dataIsUpdated={dataIsUpdated}
                type={"Association"}
                selectedProduct={product}
                archiveActiveProduct={false}
                selectedRows={listModulesSelected}
            />
            <AlertConfirmationAssociateModuleToProduct
                setIsAlerteConfirmationOpened={setIsAlerteConfirmationOpened}
                isAlerteConfirmationOpened={isAlerteConfirmationOpened}
                listLicensesProducts={listLicensesProducts}
                SelectedProduct={SelectedProduct}
                handleValidateAddModules={AssociateModulesToProduct}
                listIuudsModulesSelected={listIuudsModulesSelected}
                setIsModalOpened={setIsAssociateModuleModalOpen}
            />
        </React.Fragment>
    );
}
