import React, { useState } from "react";
import {
    StyledModal,
    StyledButton,
    StyledLabel,
} from "@aureskonnect/react-ui";
import { ModalBody, ModalHeader, CardFooter } from "reactstrap";

import {
    store,
    setModif,
    setEdit,
    setIsAlertupdateModal,
    setSelectedProductsToDissociate,
    setIsAffectedModule,
} from "../store";
import { useSnapshot } from "valtio";

import { showToast } from "@components/Common/ToastShow";
import TableAssociateProducts from "./TableAssociateProducts";

import { useTranslation } from "react-i18next";

import "../Motifs/index.css";
// import AlerteConfirmation from "@pages/Products/AlertConfirmationAssociateModuleToProduct";
import AlertConfirmationAssociateProductToModule from "@pages/Products/AlertConfirmationAssociateProductToModule";

type AssociateProductsToModuleModalType = {
    isModalOpened: boolean;
    setIsModalOpened: Function;
    setDataIsUpdated: React.Dispatch<React.SetStateAction<any>>;
    dataIsUpdated: any;
    selectedModule: any;
};

export default function AssociateProductsToModuleModal({
    isModalOpened,
    setIsModalOpened,
    setDataIsUpdated,
    dataIsUpdated,
    selectedModule,
}: AssociateProductsToModuleModalType) {
    const { t } = useTranslation();
    const uidEntity = localStorage.getItem("Uid_Entitie_User");

    const [selectedProducts, setSelectedProducts] = useState([]);
    const [selectedProductsIuuds, setSelectedProductsIuuds] = useState([]);
    const [selectedNameProducts, setSelectedNameProducts] = useState([]);
    const [selectedProductsDissocié, setSelectedProductsDissocié] = useState(
        []
    );
    const [
        selectedProductsNamesDissocié,
        setSelectedProductsNamesDissocié,
    ] = useState([]);
    const [isClick, setIsClick] = React.useState(false);
    const [isUsedLicenses, setIsUsedLicenses] = React.useState(false);
    const { modif, edit, isAlertupdateModal } = useSnapshot(store);
    const [listLicensesProducts, setListLicensesProducts] = useState([]);
    const [
        isAlerteConfirmationOpened,
        setIsAlerteConfirmationOpened,
    ] = React.useState(false);

    function dissociationProducts() {
        let RefProduit: any = [];
        let RefModule: any = [];
        selectedProductsDissocié.forEach((el: any) => {
            RefProduit.push(el.reference);
            RefModule.push(el.RefParent);
        });
        const old_module = {
            name: selectedModule.Module,
            comment: selectedModule.Descriptif,
            reference: selectedModule.Référence,
            salesSupport: selectedModule.SalesSupport,
            isArchived: selectedModule.isArchived,
            isPrincipalProduct: selectedModule.isPrincipalProduct,
            modules: selectedModule.ProduitModule,
        };
        const new_module = {
            name: selectedModule.Module,
            comment: selectedModule.Descriptif,
            reference: selectedModule.Référence,
            salesSupport: selectedModule.SalesSupport,
            isArchived: selectedModule.isArchived,
            isPrincipalProduct: selectedModule.isPrincipalProduct,
            modules: selectedProductsNamesDissocié,
            associate: false,
        };
        fetch(
            `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/dissociateModulefromProduct`,
            {
                method: "put",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    uid: RefModule[0],
                    referenceProduit: RefProduit,
                }),
            }
        )
            .then((response) => {
                if (response.status === 200 && response.ok === true) {
                    for (
                        let i: any = 0;
                        i < selectedProductsNamesDissocié.length;
                        i++
                    ) {
                        const old_produit = {
                            name: selectedProductsNamesDissocié[i],
                            reference: RefProduit[i],
                            modules: selectedModule.Module,
                        };
                        const new_produit = {
                            name: selectedProductsNamesDissocié[i],
                            reference: RefProduit[i],
                            modules: selectedModule.Module,
                            dissociate: true,
                        };
                        saveHistoryChanges(old_produit, new_produit);
                    }
                    showToast(
                        true,
                        `La dissociation a été effectuée avec succès.`
                    );
                    setIsClick(true);
                    setModif(false);
                    setSelectedProductsToDissociate(false);
                    setEdit("");
                    setSelectedNameProducts([]);
                    setSelectedProductsNamesDissocié([]);
                    setSelectedProducts([]);
                    setSelectedProductsIuuds([]);
                    setSelectedProductsDissocié([]);

                    setDataIsUpdated(!dataIsUpdated);
                    setIsModalOpened(!isModalOpened);

                    saveHistoryChanges(old_module, new_module);
                } else if (response.status === 500) {
                    showToast(
                        false,
                        `Ouups! erreur lors de la dissociation du module, veuillez réessayer SVP`
                    );
                }
            })
            .catch((e: any) => {
                console.log({ error: e.message });
            });
    }

    function saveHistoryChanges(oldData: any, newData: any) {
        fetch(`${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/HistoryChanges`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                oldData: oldData,
                newData: newData,
                uidUpdatedData: uidEntity,
                typeHistory: "2",
            }),
        })
            .then((response) => {
                response.json().then((data) => {
                    return data.error;
                });
            })
            .catch((e: any) => {
                return false;
            });
    }

    function associateToProduct() {
        const old_module = {
            name: selectedModule.Module,
            comment: selectedModule.Descriptif,
            reference: selectedModule.Référence,
            salesSupport: selectedModule.SalesSupport,
            isArchived: selectedModule.isArchived,
            isPrincipalProduct: selectedModule.isPrincipalProduct,
            modules: selectedModule.ProduitModule,
        };
        const new_module = {
            name: selectedModule.Module,
            comment: selectedModule.Descriptif,
            reference: selectedModule.Référence,
            salesSupport: selectedModule.SalesSupport,
            isArchived: selectedModule.isArchived,
            isPrincipalProduct: selectedModule.isPrincipalProduct,
            modules: selectedNameProducts,
            associate: true,
        };
        fetch(
            `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/associateModuleToProducts`,
            {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    reference: selectedModule.Référence,
                    products: selectedProducts,
                }),
            }
        )
            .then((response) => {
                if (response.status === 200 && response.ok === true) {
                    for (let i: any = 0; i < selectedNameProducts.length; i++) {
                        const old_produit = {
                            name: selectedNameProducts[i],
                            reference: selectedProducts[i],
                            modules: selectedModule.Module,
                        };
                        const new_produit = {
                            name: selectedNameProducts[i],
                            reference: selectedProducts[i],
                            modules: selectedModule.Module,
                            dissociate: false,
                        };
                        saveHistoryChanges(old_produit, new_produit);
                    }
                    showToast(
                        true,
                        `L'association a été effectuée avec succès.`
                    );
                    setIsClick(true);
                    setModif(false);
                    setSelectedProductsToDissociate(false);
                    setEdit("");
                    saveHistoryChanges(old_module, new_module);
                    setSelectedNameProducts([]);
                    setSelectedProductsNamesDissocié([]);
                    setSelectedProductsDissocié([]);
                    setSelectedProducts([]);
                    setSelectedProductsIuuds([]);
                    setDataIsUpdated(!dataIsUpdated);
                    if (!isUsedLicenses) {
                        setIsModalOpened(!isModalOpened);
                    }
                } else if (response.status === 500) {
                    showToast(
                        false,
                        `Ouups! erreur lors de l'association au module veuillez réessayer SVP`
                    );
                }
            })
            .catch((e: any) => {
                console.log({ error: e.message });
            });
    }

    function handleAddModulesWithNewCertificate() {
        if (selectedProductsIuuds.length > 0) {
            const listUuids: any[] = [];
            selectedProductsIuuds.forEach((uuidProduct) => {
                listUuids.push(uuidProduct);
            });

            fetch(
                `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/usedLicenses?listUuids=${listUuids}`,
                {
                    headers: {
                        contentType: "application/json",
                    },
                }
            )
                .then((result) => result.json())
                .then((response) => {
                    if (!response.error) {
                        // ce produit à des licences activés
                        // vérif si produit à licence ? oui : pop-up sera affiché else appelle this function (handleValidateAddModules)
                        // dans le pop-up ona deux cas :
                        // 1: clic sur valide : handleValidateAddModules puis ajouter module dans les licenses et generer nouveaux certifs pour tous les licenses
                        // 2: appelle juste la fonction handleValidateAddModules
                        if (Object.keys(response.message).length > 0) {
                            setListLicensesProducts(response.message);
                            setIsUsedLicenses(true);
                            setIsAlerteConfirmationOpened(
                                !isAlerteConfirmationOpened
                            );
                        }
                    } else {
                        // y'a pas des licenses
                        setIsUsedLicenses(false);
                        associateToProduct();
                    }
                })
                .catch((e: any) => {
                    console.log({ error: e.message });
                });
        } else {
            showToast(false, t("Il faut sélectionner des produit"));
        }
    }

    React.useEffect(() => {
        setModif(false);
        setIsClick(false);
    }, [dataIsUpdated]);

    return (
        <React.Fragment>
            <StyledModal
                className="history-modal-style"
                isOpen={isModalOpened}
                style={{
                    marginTop: "12px",
                    maxWidth: "1350px",
                    maxHeight: "100px",
                    borderRadius: "10px",
                }}
            >
                <ModalHeader
                    className="d-flex align-items-center"
                    toggle={() => {
                        if (modif === true) {
                            setIsAlertupdateModal(!isAlertupdateModal);
                            setIsModalOpened(isModalOpened);
                        } else {
                            setIsModalOpened(!isModalOpened);
                            setModif(false);
                            setEdit("");
                            setSelectedProducts([]);
                            setSelectedProductsIuuds([]);
                            setSelectedProductsDissocié([]);
                            setSelectedNameProducts([]);
                            setSelectedProductsNamesDissocié([]);
                            setIsAffectedModule(false);
                        }
                    }}
                >
                    <div
                        className="d-flex align-items-center justify-content-between"
                        style={{
                            height: "35px",
                            display: "grid",
                            gridTemplateColumns: "2.8fr 0.2fr",
                            gridTemplateRows: "1fr",
                            gap: "980px",
                        }}
                    >
                        <StyledLabel
                            style={{
                                width: "max-content",
                            }}
                        >
                            ASSOCIER DE(S) PRODUIT(S) À UN MODULE :{" "}
                            {selectedModule.Module}
                        </StyledLabel>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <TableAssociateProducts
                        URL={`${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/products?uid=${uidEntity}&isArchivedProducts=false`}
                        setSelectedProducts={setSelectedProducts}
                        setSelectedProductsIuuds={setSelectedProductsIuuds}
                        setSelectedProductsDissocié={
                            setSelectedProductsDissocié
                        }
                        setModif={setModif}
                        setEdit={setEdit}
                        setSelectedNameProducts={setSelectedNameProducts}
                        setSelectedProductsNamesDissocié={
                            setSelectedProductsNamesDissocié
                        }
                    />
                </ModalBody>
                <CardFooter
                    className="d-flex justify-content-end"
                    style={{
                        background: "#FFFFFF",
                    }}
                >
                    <div
                        className="row"
                        style={{
                            gap: "10px",
                            marginRight: "0px",
                        }}
                    >
                        <div>
                            <StyledButton
                                disabled={false}
                                rounded
                                variant="light"
                                outline={true}
                                size="sm"
                                style={{
                                    background: "white",
                                    color: "black",
                                    padding: "0px",
                                    width: "150px",
                                    height: "45px",
                                }}
                                onClick={() => {
                                    if (modif === true) {
                                        setIsAlertupdateModal(
                                            !isAlertupdateModal
                                        );
                                        setIsModalOpened(isModalOpened);
                                    } else {
                                        setIsModalOpened(!isModalOpened);
                                        setSelectedProducts([]);
                                        setSelectedProductsIuuds([]);
                                        setSelectedProductsDissocié([]);
                                        setModif(false);
                                        setEdit("");
                                        setIsAffectedModule(false);
                                    }
                                }}
                            >
                                Annuler
                            </StyledButton>
                        </div>
                        <div>
                            <StyledButton
                                disabled={false}
                                rounded
                                variant="primary"
                                outline={false}
                                size="sm"
                                style={{
                                    color: "white",
                                    padding: "0px",
                                    width: "150px",
                                    height: "45px",
                                }}
                                onClick={() => {
                                    if (edit === "") {
                                        setSelectedProductsNamesDissocié([]);
                                        setSelectedNameProducts([]);
                                        setSelectedProducts([]);
                                        setSelectedProductsDissocié([]);
                                        setIsModalOpened(!isModalOpened);
                                    } else {
                                        if (
                                            selectedProductsDissocié.length > 0
                                        ) {
                                            if (selectedProducts.length === 0) {
                                                if (isClick === false) {
                                                    dissociationProducts();
                                                    setSelectedProducts([]);
                                                    setSelectedProductsDissocié(
                                                        []
                                                    );
                                                }
                                            } else {
                                                if (isClick === false) {
                                                    dissociationProducts();

                                                    //associateToProduct();
                                                    // *** verify this product have a license -> yes : pop-up ;:; else : associate product to modules
                                                    handleAddModulesWithNewCertificate();
                                                    // setSelectedProducts([]);
                                                    // setSelectedProductsDissocié(
                                                    //     []
                                                    // );
                                                }
                                            }
                                        } else {
                                            if (selectedProducts.length === 0) {
                                                if (isClick === false) {
                                                    if (!isUsedLicenses) {
                                                        setIsModalOpened(
                                                            !isModalOpened
                                                        );
                                                    }
                                                    setModif(false);
                                                    setEdit("");
                                                    setSelectedProducts([]);
                                                    setSelectedProductsDissocié(
                                                        []
                                                    );
                                                    setSelectedNameProducts([]);
                                                    setSelectedProductsNamesDissocié(
                                                        []
                                                    );
                                                    setSelectedProductsToDissociate(
                                                        false
                                                    );
                                                }
                                            } else {
                                                if (isClick === false) {
                                                    // associateToProduct();
                                                    // *** verify this product have a license -> yes : pop-up ;:; else : associate product to modules
                                                    handleAddModulesWithNewCertificate();
                                                    // setSelectedProducts([]);
                                                    // setSelectedProductsDissocié(
                                                    //     []
                                                    // );
                                                }
                                            }
                                        }
                                    }
                                }}
                            >
                                Valider
                            </StyledButton>
                        </div>
                    </div>
                </CardFooter>
            </StyledModal>
            <AlertConfirmationAssociateProductToModule
                setIsAlerteConfirmationOpened={setIsAlerteConfirmationOpened}
                handleValidateAddProducts={associateToProduct}
                isAlerteConfirmationOpened={isAlerteConfirmationOpened}
                listLicensesProducts={listLicensesProducts}
                listIuudsProductSelected={selectedProductsIuuds}
                SelectedModule={selectedModule}
                setIsModalOpened={setIsModalOpened}
                // setIsCanceled={setIsCanceled}
            />
        </React.Fragment>
    );
}
