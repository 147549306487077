import React from "react";

import {
    DocumentIcon,
    EyeIcon,
    InboxIcon,
    TrashIcon,
    VerticalDotsIcon,
} from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import {
    setIsHitoryAllMotifs,
    store,
    setIsAlerteMotifModal,
} from "@pages/store";

import { showToast } from "../ToastShow";
import AlerteModalMotif from "@pages/Motifs/AlerteModalMotif";

type CommonMenuType = {
    isHisotyMotifModalOpened: boolean | undefined;
    setIsHisotyMotifModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
    setDataIsUpdated: React.Dispatch<React.SetStateAction<number | boolean>>;
    dataIsUpdated: number | boolean;
    selectedRows: any;
    setMotifState: React.Dispatch<React.SetStateAction<boolean>>;
    isMotifState: boolean;
};

export default function CommonMenu(props: CommonMenuType) {
    const { t } = useTranslation();
    const { isAlerteMotifModal } = useSnapshot(store);
    const uidEntity = localStorage.getItem("Uid_Entitie_User");
    const [nombreMotifsActive, setNombreMotifsActive] = React.useState(0);
    const [nombreMotifsArchive, setNombreMotifsArchive] = React.useState(0);

    const [listSelectedMotifs, setListSelectedMotifs] = React.useState([]);
    let SystemeMotifs: any = [];
    let listExist = false;

    const numberArchivesMotifs = () => {
        fetch(
            `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/numberActiveArchiveMotifs?uidEntitie=${uidEntity}&isActivesMotifs=false`
        )
            .then((response) => {
                response.json().then((data) => {
                    setNombreMotifsArchive(Number(data.count));
                });
            })
            .catch((e: any) => {
                console.log({ error: e.message });
            });
    };

    const numberActivesMotifs = () => {
        fetch(
            `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/numberActiveArchiveMotifs?uidEntitie=${uidEntity}&isActivesMotifs=true`
        )
            .then((response) => {
                response.json().then((data) => {
                    setNombreMotifsActive(Number(data.count));
                });
            })
            .catch((e: any) => {
                console.log({ error: e.message });
            });
    };
    function handleHistorique() {
        let listSelected: any = [];
        props.selectedRows.forEach((element: any) => {
            listSelected.push(element.Motif);
            SystemeMotifs.push(element.new);
        });

        if (listSelected.length < 2) {
            showToast(
                false,
                `${t(
                    "Attention, it is a multiple action, you must select at least two elements."
                )}`
            );
        } else {
            if (SystemeMotifs.includes(listExist)) {
                showToast(
                    false,
                    `${t(
                        "Attention. This action is not possible on system grounds."
                    )}`
                );
            } else {
                setIsHitoryAllMotifs(true);
                props.setIsHisotyMotifModalOpened(
                    !props.isHisotyMotifModalOpened
                );
            }
        }
    }

    function handleArchiveActiveMotifs() {
        let listSelected: any = [];

        props.selectedRows.forEach((element: any) => {
            SystemeMotifs.push(element.new);
            listSelected.push(element.iuud);
        });
        setListSelectedMotifs(listSelected);

        if (listSelected.length < 2) {
            showToast(
                false,
                `${t(
                    "Attention, it is a multiple action, you must select at least two elements."
                )}`
            );
        } else {
            if (SystemeMotifs.includes(listExist)) {
                showToast(
                    false,
                    `${t(
                        "Attention. This action is not possible on system grounds."
                    )}`
                );
            } else {
                setIsAlerteMotifModal(!isAlerteMotifModal);
            }
        }
    }

    React.useEffect(() => {
        numberActivesMotifs();
        numberArchivesMotifs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uidEntity, props.selectedRows]);

    return (
        <>
            <div className="dropdown ml-2  d-none d-sm-block">
                <VerticalDotsIcon
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    cursor="pointer"
                    height={25}
                    width={25}
                />

                <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                >
                    {props.isMotifState ? (
                        <div
                            className="dropdown-item"
                            style={{
                                pointerEvents:
                                    nombreMotifsArchive === 0 ? "none" : "auto",
                                opacity: nombreMotifsArchive === 0 ? "0.4" : "",
                                cursor: "pointer",
                            }}
                            role="button"
                            tabIndex={0}
                            onClick={() => {
                                props.setMotifState(false);
                            }}
                        >
                            <InboxIcon
                                height={25}
                                width={25}
                                className="mr-2"
                            />
                            {`${t("Motif archived")} (${nombreMotifsArchive})`}
                        </div>
                    ) : (
                        <div
                            className="dropdown-item"
                            role="button"
                            tabIndex={0}
                            style={{
                                cursor: "pointer",
                            }}
                            onClick={() => {
                                props.setMotifState(true);
                            }}
                        >
                            <InboxIcon
                                height={25}
                                width={25}
                                className="mr-2"
                            />
                            {`${t("Motif enabled")} (${nombreMotifsActive})`}
                        </div>
                    )}

                    {props.isMotifState ? (
                        <div
                            style={{ cursor: "pointer" }}
                            className="dropdown-item"
                            role="button"
                            tabIndex={0}
                            onClick={() => {
                                handleArchiveActiveMotifs();
                            }}
                        >
                            <TrashIcon
                                height={25}
                                width={25}
                                fill="red"
                                className="mr-2"
                            />
                            {t("Archive motif")}
                        </div>
                    ) : (
                        <div
                            style={{ cursor: "pointer" }}
                            className="dropdown-item"
                            role="button"
                            tabIndex={0}
                            onClick={() => {
                                handleArchiveActiveMotifs();
                            }}
                        >
                            <EyeIcon height={25} width={25} className="mr-2" />
                            {t("Enable motif")}
                        </div>
                    )}
                    <div
                        className="dropdown-item"
                        role="button"
                        tabIndex={0}
                        onClick={() => {
                            handleHistorique();
                        }}
                    >
                        <DocumentIcon height={25} width={25} className="mr-2" />

                        {t("Historical")}
                    </div>
                </div>

                <AlerteModalMotif
                    isAlerteModalOpened={isAlerteMotifModal}
                    setIsAlerteModalOpened={setIsAlerteMotifModal}
                    setDataIsUpdated={props.setDataIsUpdated}
                    dataIsUpdated={props.dataIsUpdated}
                    selectedRows={props.selectedRows}
                    isActiveArchiveMotif={props.isMotifState}
                    listSelectedMotifs={listSelectedMotifs}
                    setListSelectedMotifs={setListSelectedMotifs}
                />
            </div>
        </>
    );
}
