import React, { useState } from "react";
import {
    CrossIcon,
    StyledIconButton,
    StyledModal,
    StyledButton,
} from "@aureskonnect/react-ui";
import { ModalBody, ModalHeader, CardFooter } from "reactstrap";
import { showToast } from "@components/Common/ToastShow";

import TablePacksProducts from "./TablePacksProducts";

import "../Motifs/index.css";

type ProductsPacksModalType = {
    isModalOpened: boolean;
    setIsModalOpened: Function;
    newPack: any;
    setDataIsUpdated: React.Dispatch<React.SetStateAction<any>>;
    dataIsUpdated: any;
    setIsHidden: React.Dispatch<React.SetStateAction<any>>;
};

export function SelectProductsPacksModal({
    isModalOpened,
    setIsModalOpened,
    newPack,
    setDataIsUpdated,
    dataIsUpdated,
    setIsHidden,
}: ProductsPacksModalType) {
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [uidPack] = useState<string>(
        "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
            var r = (Math.random() * 16) | 0,
                v = c === "x" ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        })
    );
    const uidEntity = localStorage.getItem("Uid_Entitie_User");

    function saveHistoryChanges(oldData: any, newData: any, type: any) {
        fetch(`${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/HistoryChanges`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                oldData: oldData,
                newData: newData,
                uidUpdatedData: uidEntity,
                typeHistory: type,
            }),
        })
            .then((response) => {
                response.json().then((data) => {
                    setTimeout(function () {}, 2000);
                    return data.error;
                });
            })
            .catch((e: any) => {
                return false;
            });
    }

    function handleValidateAddPack() {
        // if (selectedProducts.length === 0) {
        //     showToast(false, `SVP veuillez Sélectionner au moins un produit`);
        // } else {
        console.log(selectedProducts);
        newPack.products = selectedProducts;
        fetch(`${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/addPack`, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                uidPack: uidPack,
                newPack: newPack,
            }),
        })
            .then((response) => {
                if (response.status === 200 && response.ok === true) {
                    let newProductsAssociated: any = [];
                    if (Object.values(selectedProducts).length > 0) {
                        Object.values(selectedProducts).forEach(
                            (elProduct: any) => {
                                newProductsAssociated.push(elProduct.Name);
                            }
                        );
                    }
                    let productsAdded = newProductsAssociated.join(", ");
                    saveHistoryChanges(
                        { reference: newPack.reference },
                        {
                            pack: newPack.pack,
                            reference: newPack.reference,
                        },
                        5
                    );
                    showToast(true, `Le pack a été  ajouté avec succès.`);
                    setIsHidden(false);
                    setSelectedProducts([]);
                    setIsModalOpened(!isModalOpened);
                    setDataIsUpdated(!dataIsUpdated);
                    saveHistoryChanges(
                        {
                            designation: newPack.pack,
                            associate: false,
                            designationElement: productsAdded,
                            reference: newPack.reference,
                        },
                        {
                            designation: newPack.pack,
                            associate: true,
                            designationElement: productsAdded,
                            reference: newPack.reference,
                        },
                        5
                    );
                } else if (response.status === 500) {
                    showToast(
                        false,
                        `Ouups! erreur lors de l'ajout du pack, veuillez réessayer SVP`
                    );
                }
            })
            .catch((e: any) => {
                console.log({ error: e.message });
            });
        // }
    }

    return (
        <React.Fragment>
            <StyledModal
                className="history-modal-style"
                isOpen={isModalOpened}
                style={{
                    marginTop: "12px",
                    maxWidth: "1350px",
                    maxHeight: "100px",
                    borderRadius: "10px",
                }}
            >
                <ModalHeader className="d-flex align-items-center">
                    <div
                        className="d-flex align-items-center justify-content-between"
                        style={{
                            height: "35px",
                            display: "grid",
                            gridTemplateColumns: "2.8fr 0.2fr",
                            gridTemplateRows: "1fr",
                            gap: "992px",
                        }}
                    >
                        <span
                            style={{
                                font: "bold",
                                opacity: 1,
                            }}
                        >
                            {newPack !== undefined
                                ? `ASSOCIER DE(S) PRODUIT(S) À UN PACK: ${newPack.pack}`
                                : null}
                        </span>

                        <StyledIconButton icon="CrossIcon">
                            <CrossIcon
                                cursor="pointer"
                                height={10}
                                width={10}
                                onClick={() => {
                                    setIsModalOpened(!isModalOpened);
                                }}
                            />
                        </StyledIconButton>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <TablePacksProducts
                        URL={`${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/products?uid=${uidEntity}&isArchivedProducts=false`}
                        setSelectedProducts={setSelectedProducts}
                    />
                </ModalBody>
                <CardFooter
                    className="d-flex justify-content-end"
                    style={{
                        background: "#FFFFFF",
                    }}
                >
                    <div
                        className="row"
                        style={{
                            gap: "10px",
                            marginRight: "0px",
                        }}
                    >
                        <div>
                            <StyledButton
                                disabled={false}
                                rounded
                                variant="light"
                                outline={true}
                                size="sm"
                                style={{
                                    background: "white",
                                    color: "black",
                                    padding: "0px",
                                    width: "150px",
                                    height: "45px",
                                }}
                                onClick={() => {
                                    setIsModalOpened(!isModalOpened);
                                }}
                            >
                                Annuler
                            </StyledButton>
                        </div>
                        <div>
                            <StyledButton
                                disabled={false}
                                rounded
                                variant="primary"
                                outline={false}
                                size="sm"
                                style={{
                                    color: "white",
                                    padding: "0px",
                                    width: "150px",
                                    height: "45px",
                                }}
                                onClick={() => {
                                    handleValidateAddPack();
                                }}
                            >
                                Valider
                            </StyledButton>
                        </div>
                    </div>
                </CardFooter>
            </StyledModal>
        </React.Fragment>
    );
}
