import React from "react";
import { StyledButton, StyledLabel } from "@aureskonnect/react-ui";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";

import {
    setAssociatedDemoLicence,
    // eslint-disable-next-line
    setIsAlertGenerateCertificateModal,
    setIsDemo,
    setSelectedEntity,
    setSelectedLicences,
    setSelectedTypeEconomicModel,
    setIsUpdateInfoCertificate,
    setIsUpdateCertificate,
    store,
} from "../../pages/store";

import { showToast } from "@components/Common/ToastShow";
import PageTitle from "@components/Common/PageTitle";
import LicensesListTable from "./LicensesListTable";
import { cryptWord } from "..";

import "../index.css";

type ListLicensesModalType = {
    statusLicense: string;
    isModalOpened: boolean;
    setIsModalOpened: Function;
    dataEntitie: any;
    updatedLicence: any;
    setDataIsUpdated: React.Dispatch<React.SetStateAction<any>>;
    dataIsUpdated: any;
    setStatusLicence: React.Dispatch<React.SetStateAction<string>>;
};

export function LicensesModal({
    statusLicense,
    isModalOpened,
    setIsModalOpened,
    dataEntitie,
    updatedLicence,
    setDataIsUpdated,
    dataIsUpdated,
    setStatusLicence,
}: ListLicensesModalType) {
    const { t } = useTranslation();
    const {
        selectedEntity,
        associatedDemoLicence,
        KeysLicenseBeforeUpdate,
        associatedDemoLicenceToUpdate,
        licenseBeforeUpdate,
        // eslint-disable-next-line
        isUpdateInfoCertificate,
        // eslint-disable-next-line
        isUpdateCertificate,
    } = useSnapshot(store);

    const [listSelectedLicenses, setlistSelectedLicenses] = React.useState<any>(
        {}
    );
    const [
        listSelectedEconomicModel,
        setlistSelectedEconomicModel,
    ] = React.useState<any>({});
    const [
        listSelectedUpdatedLicenses,
        setlistSelectedUpdatedLicenses,
    ] = React.useState<any>({});
    const [
        listSelectedLicensesToBlocked,
        setListSelectedLicensesToBlocked,
    ] = React.useState<any>({});
    let listSelectedLicensesCertificate: any = {};
    const [
        listSelectedLicensesToDeBlocked,
        setListSelectedLicensesToDeBlocked,
    ] = React.useState<any>({});
    const [
        selectedUpdatedEconomicModel,
        setSelectedUpdatedEconomicModel,
    ] = React.useState<any>({});
    const [listModule, setListModule] = React.useState<any>({});
    const [listModuleToUpdateAdd, setListModuleToUpdateAdd] = React.useState<
        any
    >([]);
    const [
        listModuleToUpdateDelete,
        setListModuleToUpdateDelete,
    ] = React.useState<any>([]);
    const [selectedRows, setSelectedRows] = React.useState<[]>([]);
    const [isClick, setIsClick] = React.useState(false);
    const uidEntity = localStorage.getItem("Uid_Entitie_User");

    let titlePage: String = "";
    // const count: any = {};

    switch (statusLicense) {
        case "add":
            titlePage = "AJOUTER LICENCE(S)";
            break;
        case "bloq":
            titlePage = "BLOQUER LICENCE(S)";
            break;
        case "debloq":
            titlePage = "DEBLOQUER LICENCE(S)";
            break;
        default:
    }

    function handleGenerateCertificateLicenses() {
        let DataLicense: any = {};
        Object.keys(listSelectedLicenses).forEach((el: any) => {
            if (listSelectedLicenses[el].actived > 0) {
                DataLicense[el] = listSelectedLicenses[el];
            }
        });
        if (
            Object.keys(updatedLicence).length === 0 &&
            statusLicense === "add"
        ) {
            updatedLicence = DataLicense;
        }

        Object.keys(DataLicense).forEach((selectedLicenses: any) => {
            const uuidsKeys = selectedLicenses.split("|");
            if (uuidsKeys.length > 0) {
                let listModulesProduct;
                if (DataLicense[selectedLicenses].modules === undefined) {
                    listModulesProduct = [];
                } else {
                    listModulesProduct = DataLicense[selectedLicenses].modules;
                }
                let typeEconomicModel: any;
                if (
                    DataLicense[selectedLicenses].managementRules[0]
                        .EconomicModel === "SAAS"
                ) {
                    typeEconomicModel = 0;
                } else {
                    typeEconomicModel = 1;
                }

                if (uuidsKeys[0] !== uuidsKeys[1]) {
                    // produit et pack --> 0 : uuid produit 1: uuid pack

                    listSelectedLicensesCertificate[uuidsKeys[0]] = {
                        pack: uuidsKeys[1],
                        modules: listModulesProduct,
                        blocked: DataLicense[selectedLicenses].blocked,
                        actived: DataLicense[selectedLicenses].actived,
                        demoConfiguration:
                            DataLicense[selectedLicenses].demoConfiguration,
                        managementRules: typeEconomicModel,
                    };
                } else {
                    // produit sans pack --> 0 : uuid produit
                    listSelectedLicensesCertificate[uuidsKeys[0]] = {
                        pack: "",
                        modules: listModulesProduct,
                        blocked: DataLicense[selectedLicenses].blocked,
                        actived: DataLicense[selectedLicenses].actived,
                        demoConfiguration:
                            DataLicense[selectedLicenses].demoConfiguration,
                        managementRules: typeEconomicModel,
                    };
                }
            }
        });

        const cryptedDataCertificatedLicenses = cryptWord(
            JSON.stringify(listSelectedLicensesCertificate)
        );

        fetch(
            `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/addCertificateLicenses`,
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: `uuidEntity=${selectedEntity.Id}&dataCertificateLicenses=${cryptedDataCertificatedLicenses}`,
                method: "POST",
            }
        )
            .then((result) => result.json())
            .then((response: any) => {
                if (response) {
                    // eslint-disable-next-line no-lone-blocks
                    showToast(
                        true,
                        "Les modifications sur le certificat ont été effectuées avec succès "
                    );
                    setIsUpdateInfoCertificate(false);
                }

                return false;
            })
            .catch((error: any) => {
                console.log({ errorfetch: error.message });
                return false;
            });
    }

    function handleAddLicences() {
        setIsClick(false);
        let arr: any = [];
        let EconomicModel: any = [];
        let copy;
        let DataLicense: any = {};
        Object.keys(listSelectedLicenses).forEach((el: any) => {
            Object.keys(listSelectedUpdatedLicenses).forEach((el1: any) => {
                if (el === el1) {
                    listSelectedLicenses[
                        el
                    ].actived = KeysLicenseBeforeUpdate.includes(el)
                        ? listSelectedLicenses[el].actived +
                          listSelectedUpdatedLicenses[el1].actived
                        : listSelectedUpdatedLicenses[el1].actived;
                }
            });
            if (
                (KeysLicenseBeforeUpdate.includes(el) &&
                    listSelectedLicenses[el].actived === 0) ||
                listSelectedLicenses[el].actived > 0
            ) {
                DataLicense[el] = listSelectedLicenses[el];
            }
        });
        Object.keys(DataLicense).forEach((el: any) => {
            const elementUuid = el.split("|");
            if (DataLicense[el].actived > 0) {
                arr.push(elementUuid[0]);
            }
        });

        const unique = Array.from(new Set(arr));

        if (statusLicense === "add") {
            if (arr.length !== unique.length) {
                showToast(
                    false,
                    "Il est impossible d'ajouter de nouvelles licences pour le même produit."
                );
            } else {
                if (
                    Object.keys(DataLicense).length > 0 ||
                    Object.keys(selectedUpdatedEconomicModel).length > 0
                ) {
                    Object.keys(DataLicense).forEach((el: any) => {
                        ////* 1er ajout modéle economique *///
                        Object.keys(listSelectedEconomicModel).forEach(
                            (el1: any) => {
                                if (el === el1) {
                                    DataLicense[el].managementRules.push(
                                        listSelectedEconomicModel[el1]
                                    );
                                }
                            }
                        );
                        ////* update nomber licences actives *///
                        // Object.keys(listSelectedUpdatedLicenses).forEach(
                        //     (el1: any) => {
                        //         if (el === el1) {
                        //             listSelectedLicenses[el].actived =
                        //                 listSelectedUpdatedLicenses[
                        //                     el1
                        //                 ].actived;
                        //         }
                        //     }
                        // );
                        ////* 1er ajout des modules pour les produits *///
                        Object.keys(listModule).forEach((el1: any) => {
                            if (el === el1) {
                                DataLicense[
                                    el
                                ].modules.modulesUuid = DataLicense[
                                    el
                                ].modules.modulesUuid.filter(
                                    (word: any) =>
                                        !listModule[el1].modulesUuid.includes(
                                            word
                                        )
                                );
                            }
                        });
                        ////* update  modéle economique*///
                        Object.keys(selectedUpdatedEconomicModel).forEach(
                            (el1: any) => {
                                if (el === el1) {
                                    DataLicense[el].managementRules[0] =
                                        selectedUpdatedEconomicModel[el1];
                                }
                            }
                        );
                        ////* Delete module to product *///
                        listModuleToUpdateDelete.forEach((el1: any) => {
                            const elementUuid = el1.split(",");
                            copy = JSON.parse(
                                JSON.stringify(
                                    DataLicense[elementUuid[0]].modules[
                                        "modulesUuid"
                                    ]
                                )
                            );
                            if (el === elementUuid[0]) {
                                DataLicense[elementUuid[0]].modules[
                                    "modulesUuid"
                                ] = copy.filter(
                                    (word: any) => word !== elementUuid[1]
                                );
                            }
                        });
                        ////* Add module to product *///
                        listModuleToUpdateAdd.forEach((el1: any) => {
                            const elementUuid = el1.split(",");
                            if (DataLicense[elementUuid[0]] !== undefined) {
                                copy = JSON.parse(
                                    JSON.stringify(
                                        DataLicense[elementUuid[0]].modules[
                                            "modulesUuid"
                                        ]
                                    )
                                );
                                if (el === elementUuid[0]) {
                                    DataLicense[elementUuid[0]].modules[
                                        "modulesUuid"
                                    ].push(elementUuid[1]);
                                }
                            }
                        });
                        ////*1er ajout licence Demo *///
                        Object.keys(associatedDemoLicence).forEach(
                            (el1: any) => {
                                if (el === el1) {
                                    DataLicense[el].demoConfiguration =
                                        associatedDemoLicence[el1];
                                }
                            }
                        );
                        ////* Modification licence demo to commerciale *///
                        Object.keys(associatedDemoLicenceToUpdate).forEach(
                            (el1: any) => {
                                if (el === el1) {
                                    DataLicense[el].demoConfiguration =
                                        associatedDemoLicenceToUpdate[el1];
                                }
                            }
                        );
                        // ////* Compte Demo*///
                        if (
                            selectedEntity.note === "|Demo" ||
                            selectedEntity.note === "Anomalie|Demo"
                        ) {
                            DataLicense[el].demoConfiguration = {
                                isActivated: true,
                                date: selectedEntity.limiteCollection.duration,
                            };
                        }
                    });
                    Object.values(DataLicense).forEach((el: any) => {
                        ////* Verifications modele economique obligatoire*///
                        if (el.managementRules.length === 0) {
                            EconomicModel.push(true);
                        }
                    });
                    if (
                        EconomicModel !== undefined &&
                        EconomicModel.length > 0
                    ) {
                        showToast(
                            false,
                            "Ajouter de nouvelles licences sans modèle économique est impossible"
                        );
                    } else {
                        const CrypteLicence = cryptWord(
                            JSON.stringify(DataLicense)
                        );
                        if (
                            JSON.stringify(DataLicense) ===
                            JSON.stringify(licenseBeforeUpdate)
                        ) {
                            setIsModalOpened(false);
                        } else {
                            fetch(
                                `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/addLicence`,
                                {
                                    method: "PUT",
                                    headers: {
                                        "Content-Type": "application/json",
                                    },
                                    body: JSON.stringify({
                                        uuid: dataEntitie.Id,
                                        dataLicence: CrypteLicence,
                                    }),
                                }
                            )
                                .then((response) => {
                                    response.json().then((data) => {
                                        if (!data.error) {
                                            showToast(
                                                true,
                                                "Une ou plusieurs licences ont été ajoutées avec succès."
                                            );
                                            handleGenerateCertificateLicenses();
                                            setlistSelectedEconomicModel("");
                                            setSelectedLicences({});
                                            setStatusLicence("");
                                            setSelectedUpdatedEconomicModel("");
                                            setDataIsUpdated(!dataIsUpdated);
                                            setSelectedEntity("");
                                            setAssociatedDemoLicence("");
                                            setIsDemo({});
                                            setIsModalOpened(false);
                                        } else {
                                            showToast(
                                                false,
                                                "Ouups!,la licence n'est pas ajoutée"
                                            );
                                        }
                                    });
                                })
                                .catch((e: any) => {
                                    console.log({ error: e.message });
                                })
                                .finally(() => {
                                    setIsClick(true);
                                });
                        }
                    }
                } else {
                    setIsModalOpened(false);
                }
            }
        } else if (statusLicense === "bloq") {
            Object.keys(updatedLicence).forEach((el: any) => {
                Object.keys(listSelectedLicensesToBlocked).forEach(
                    (el1: any) => {
                        if (el === el1) {
                            updatedLicence[el]["blocked"] =
                                updatedLicence[el]["blocked"] +
                                listSelectedLicensesToBlocked[el1]["blocked"];
                            updatedLicence[el]["actived"] =
                                updatedLicence[el]["actived"] -
                                listSelectedLicensesToBlocked[el1]["blocked"];
                        }
                    }
                );
            });

            if (
                JSON.stringify(updatedLicence) ===
                JSON.stringify(licenseBeforeUpdate)
            ) {
                setIsModalOpened(false);
            } else {
                const CrypteLicenceToBlocked = cryptWord(
                    JSON.stringify(updatedLicence)
                );

                fetch(
                    `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/addLicence`,
                    {
                        method: "PUT",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({
                            uuid: dataEntitie.Id,
                            dataLicence: CrypteLicenceToBlocked,
                        }),
                    }
                )
                    .then((response) => {
                        response.json().then((data) => {
                            if (!data.error) {
                                showToast(
                                    true,
                                    "Une ou plusieurs licences ont été bloquées avec succès "
                                );
                                handleGenerateCertificateLicenses();
                                setSelectedLicences({});
                                setStatusLicence("");
                                setDataIsUpdated(!dataIsUpdated);
                                setSelectedEntity("");
                                setIsModalOpened(false);
                            } else {
                                showToast(
                                    false,
                                    "Ouups!,la licence n'est pas ajoutée"
                                );
                            }
                        });
                    })
                    .catch((e: any) => {
                        console.log({ error: e.message });
                    })
                    .finally(() => {
                        setIsClick(true);
                    });
            }
        } else {
            const copy = JSON.parse(JSON.stringify(updatedLicence));
            Object.keys(copy).forEach((el: any) => {
                Object.keys(listSelectedLicensesToDeBlocked).forEach(
                    (el1: any) => {
                        if (el === el1) {
                            copy[el]["blocked"] =
                                copy[el]["blocked"] -
                                listSelectedLicensesToDeBlocked[el1]["blocked"];
                            copy[el]["actived"] =
                                copy[el]["actived"] +
                                listSelectedLicensesToDeBlocked[el1]["blocked"];
                        }
                    }
                );
            });

            Object.keys(listSelectedLicensesToDeBlocked).forEach((el: any) => {
                const elementUuid = el.split("|");
                if (listSelectedLicensesToDeBlocked[el].blocked > 0) {
                    arr.push(elementUuid[0]);
                }
            });
            const unique = Array.from(new Set(arr));

            if (arr.length !== unique.length) {
                showToast(
                    false,
                    "Il est impossible de débloquer des licences d'un même produit. "
                );
            } else {
                if (
                    JSON.stringify(copy) === JSON.stringify(licenseBeforeUpdate)
                ) {
                    setIsModalOpened(false);
                } else {
                    const CrypteLicenceToDeBlocked = cryptWord(
                        JSON.stringify(copy)
                    );
                    fetch(
                        `${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/addLicence`,
                        {
                            method: "PUT",
                            headers: { "Content-Type": "application/json" },
                            body: JSON.stringify({
                                uuid: dataEntitie.Id,
                                dataLicence: CrypteLicenceToDeBlocked,
                            }),
                        }
                    )
                        .then((response) => {
                            response.json().then((data) => {
                                if (!data.error) {
                                    showToast(
                                        true,
                                        "Une ou plusieurs licences ont été débloquées avec succès "
                                    );
                                    handleGenerateCertificateLicenses();
                                    setSelectedLicences({});
                                    setStatusLicence("");
                                    setlistSelectedEconomicModel("");
                                    setSelectedTypeEconomicModel("");
                                    setDataIsUpdated(!dataIsUpdated);
                                    setSelectedEntity("");
                                    setIsModalOpened(false);
                                } else {
                                    showToast(
                                        false,
                                        "Ouups!,la licence n'est pas ajoutée"
                                    );
                                }
                            });
                        })
                        .catch((e: any) => {
                            console.log({ error: e.message });
                        })
                        .finally(() => {
                            setIsClick(true);
                        });
                }
            }
        }
    }

    // React.useEffect(() => {
    //     if (isUpdateInfoCertificate && Object.keys(updatedLicence).length > 0) {
    //         handleAddLicences();
    //     }
    //     setIsUpdateCertificate(false);
    //     setIsUpdateInfoCertificate(false);
    //     setIsAlertGenerateCertificateModal(false);
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [isUpdateInfoCertificate, isUpdateCertificate]);
    // React.useEffect(() => {
    //     setIsClick(false);
    // }, [dataIsUpdated]);

    return (
        <div>
            <React.Fragment>
                <Modal
                    isOpen={isModalOpened}
                    className="modal-wrapper__clz"
                    centered
                    backdrop="static"
                    fade={false}
                    scrollable
                >
                    <ModalHeader
                        toggle={() => {
                            setlistSelectedEconomicModel("");
                            setSelectedTypeEconomicModel("");
                            setStatusLicence("");
                            setSelectedLicences({});
                            setDataIsUpdated(!dataIsUpdated);
                            setSelectedEntity("");
                            setIsUpdateCertificate(false);
                            setIsModalOpened(false);
                        }}
                    >
                        <div
                            className="d-flex align-items-center "
                            style={{
                                height: "35px",
                                display: "grid",
                                gridTemplateColumns: "2.8fr 0.2fr",
                                gridTemplateRows: "1fr",
                                gap: "1513px",
                            }}
                        >
                            <p>{titlePage}</p>
                        </div>
                    </ModalHeader>

                    <ModalBody>
                        <PageTitle title={`${titlePage}`} />

                        <div>
                            <div className="d-flex flex-row bd-highlight mb-3">
                                <div
                                    className="p-2 bd-highlight mt-1"
                                    style={{ width: "max-content" }}
                                >
                                    <span
                                        style={{
                                            textAlign: "left",
                                            font:
                                                " normal normal bold 20px/14px Segoe UI",
                                            color: "#2B2828",
                                            fontWeight: 600,
                                        }}
                                    >
                                        Société :
                                    </span>
                                </div>
                                <div className="p-2 bd-highlight">
                                    <StyledLabel
                                        style={{
                                            fontWeight: "normal",
                                            width: "max-content",
                                        }}
                                    >
                                        {selectedEntity.commercialname}
                                    </StyledLabel>
                                </div>
                                <div
                                    className="p-2 bd-highlight mt-1"
                                    style={{ width: "max-content" }}
                                >
                                    <span
                                        style={{
                                            textAlign: "left",
                                            font:
                                                " normal normal bold 20px/14px Segoe UI",
                                            color: "#2B2828",
                                            fontWeight: 600,
                                        }}
                                    >
                                        Identifiant :
                                    </span>
                                </div>
                                <div className="p-2 bd-highlight">
                                    <StyledLabel
                                        style={{
                                            fontWeight: "normal",
                                            width: "max-content",
                                        }}
                                    >
                                        {selectedEntity.erposId}
                                    </StyledLabel>
                                </div>
                            </div>
                        </div>

                        <LicensesListTable
                            url={`${process.env.REACT_APP_CONSOLE_ADMIN_ACCESS}/packsProductsModulesList?uid=${uidEntity}`}
                            dataEntitie={dataEntitie}
                            selectedRows={selectedRows}
                            setSelectedRows={setSelectedRows}
                            setlistSelectedLicenses={setlistSelectedLicenses}
                            updatedLicence={updatedLicence}
                            setlistSelectedUpdatedLicenses={
                                setlistSelectedUpdatedLicenses
                            }
                            setListModule={setListModule}
                            setlistSelectedEconomicModel={
                                setlistSelectedEconomicModel
                            }
                            statusLicense={statusLicense}
                            setListSelectedLicensesToBlocked={
                                setListSelectedLicensesToBlocked
                            }
                            setListSelectedLicensesToDeBlocked={
                                setListSelectedLicensesToDeBlocked
                            }
                            setSelectedUpdatedEconomicModel={
                                setSelectedUpdatedEconomicModel
                            }
                            setListModuleToUpdateAdd={setListModuleToUpdateAdd}
                            setListModuleToUpdateDelete={
                                setListModuleToUpdateDelete
                            }
                        />
                    </ModalBody>

                    <ModalFooter className="d-flex justify-content-end">
                        <div className="d-flex align-items-center">
                            <div>
                                <StyledButton
                                    rounded
                                    outline
                                    variant="light"
                                    onClick={() => {
                                        setlistSelectedEconomicModel("");
                                        setSelectedTypeEconomicModel("");
                                        setStatusLicence("");
                                        setSelectedLicences({});
                                        setDataIsUpdated(!dataIsUpdated);
                                        setSelectedEntity("");
                                        setIsUpdateCertificate(false);
                                        setIsModalOpened(false);
                                    }}
                                >
                                    {t("Cancel")}
                                </StyledButton>
                            </div>
                            <div className="ml-3">
                                <StyledButton
                                    rounded
                                    variant="primary"
                                    outline={false}
                                    size="sm"
                                    style={{
                                        color: "white",
                                        padding: "0px",
                                        width: "150px",
                                        height: "45px",
                                    }}
                                    onClick={() => {
                                        // if (
                                        //     Object.keys(updatedLicence)
                                        //         .length === 0
                                        // ) {
                                        if (isClick === false) {
                                            handleAddLicences();
                                        }
                                        // } else {

                                        //     if (
                                        //         isUpdateInfoCertificate === true
                                        //     ) {
                                        //         setIsAlertGenerateCertificateModal(
                                        //             true
                                        //         );
                                        //     } else {
                                        //         setIsModalOpened(false);
                                        //     }
                                        // }
                                    }}
                                >
                                    {t("Validate")}
                                </StyledButton>
                            </div>
                        </div>
                    </ModalFooter>
                </Modal>

                {/* <AlertConfirmGenerateCertificate
                    isAlertGenerateCertificateModal={
                        isAlertGenerateCertificateModal
                    }
                    setIsAlertGenerateCertificateModal={
                        setIsAlertGenerateCertificateModal
                    }
                /> */}
            </React.Fragment>
        </div>
    );
}
